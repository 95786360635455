import React, { useEffect, useState } from "react";
import MainLayout from "../../../assets/Layout/MainLayout";
import { CreateLanguageWrapper } from "./style/createLanguage";
import Switch from "react-switch";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import SearchableDropdown from "../../../assets/SharedComponents/SearchableDropdown/SearchableDropdown";

const EditLanguage = () => {

  const [searchParams, setSearchParams] = useSearchParams();

  const [languages,_languages] = useState(JSON.parse(localStorage.getItem("languages")))
  const [countries,_countries] = useState(JSON.parse(localStorage.getItem("countries")))

  const [dataLoaded,_dataLoaded] = useState(false)

  const [loading,_loading]= useState(false)
  
  const [checked,_checked]= useState(false)
  const [name,_name] = useState({ en : '', fr : ''})
  const [code,_code] = useState('')
  const [country,_country]= useState('')
  
  const { language } = useParams();

  const navigate = useNavigate();

  useEffect(()=>{
    getLanguages()
  },[])

  useEffect(()=>{
    // Removes previous key(language code) from name object and replaces it with new code
    if(dataLoaded && code !== ''){
      Object.keys(name).map((item)=>{
        let exists = languages.findIndex(o=>o.code===item)
        if(exists===-1){
          let nameObj = {...name}
          nameObj[code] = nameObj[item] 
          delete nameObj[item]
          _name(nameObj)
        }
      });
    }
  },[languages])

  const getLimitedLanguages =() =>{
    API.get(APP_URLS.ADMIN_LANGUAGES+`?limited_data=true`)
    .then(res => {
      _loading(false)
      const resp = res.data;
      if(resp.success===true){
        let languages = resp.data.items
        localStorage.setItem("languages",JSON.stringify(languages))
        setTimeout(()=>{
          navigate("/translation?"+
          (searchParams.get("per_page") !== null 
            ? "per_page=" + searchParams.get("per_page") + "&"
            : "") +
          (searchParams.get("page") !== null
            ? "page=" + searchParams.get("page")
            : "")
          )
        },500)
      }
      else{
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })
    .catch(function (error) {  
      _loading(false)
      const resp = error.response;
      let error_message = '';
      if (resp.data.data !== undefined && resp.data.data !== null) {
        {
          Object.keys(resp.data.data).map(
            (error, index) =>(error_message = resp.data.data[error][0])
          );
        }
      } else if (resp.data.data?.error !== undefined) {
          error_message =  resp.data.data.error;
      } else if(resp.data?.error!== undefined){
          error_message =  resp.data.error
      } else{
          error_message =  resp.data.message
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT
      })
    });
  }

  const getLanguages =() =>{
    API.get(APP_URLS.GET_LANGUAGE+`/${language}`)
    .then(res => {
      const resp = res.data;
      if(resp.success===true){
        setValues(resp.data.language)
      }
      else{
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })
    .catch(function (error) {  
      const resp = error.response;
      let error_message = '';
      if (resp.data.errors !== undefined) {
        {
          Object.keys(resp.data.errors).map(
            (error, index) => (error_message = resp.data.errors[error][0])
          );
        }
      } else if (resp.data.data?.error !== undefined) {
          error_message =  resp.data.data.error;
      } else if(resp.data?.error!== undefined){
          error_message =  resp.data.error
      } else{
          error_message =  resp.data.message
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT
      })
    });
    }

  const setValues = (data) =>{
    _country(data.country_id)
    _code(data.code)
    _checked(data.status===1?true:false)
    Object.keys(data.name).map((val, index) => {
      name[val] = data.name[val];
    });
    _dataLoaded(true)
  }


  const handleSubmit = ()=>{
    if(code===""||code===undefined||code===null){
      toast.error("Language code is required", {
        position: toast.POSITION.TOP_RIGHT
      })
      return ;
    }else if((name["en"]===""||name["en"]===undefined||name["en"]===null) && (name["fr"]===""||name["fr"]===undefined||name["fr"]===null )){
      toast.error("Language Title in English and French is mandatory", {
        position: toast.POSITION.TOP_RIGHT
      })
      return ;
    }
    else if(name["en"]===""||name["en"]===undefined||name["en"]===null){
      toast.error("Please provide language name in English", {
        position: toast.POSITION.TOP_RIGHT
      })
      return ;
    }
    else if(name["fr"]===""||name["fr"]===undefined||name["fr"]===null){
      toast.error("Please provide language name in French", {
        position: toast.POSITION.TOP_RIGHT
      })
      return ;
    }
    else{
      let data = {}
      data['name'] = name
      data['code'] = code
      data['status'] = checked===true?"1":"0"
      if(!loading) {
        _loading(true)
        API.post(APP_URLS.UPDATE_LANGUAGE+`/${language}?_method=patch`, data)
        .then(res => {
            const resp = res.data;
            if(resp.success===true){
              toast.success("Language has been updated successfully.", {
                position: toast.POSITION.TOP_RIGHT
              })
              getLimitedLanguages()
            }
            else{
              toast.error(resp.message, {
                position: toast.POSITION.TOP_RIGHT
              })
            }
        })
        .catch(function (error) {  
            const resp = error.response;
            _loading(false)
            let error_message = '';
            if (resp.data.data !== undefined && resp.data.data !== null) {
              {
                Object.keys(resp.data.data).map(
                  (error, index) =>(error_message = resp.data.data[error][0])
                );
              }
            } else if (resp.data.data?.error !== undefined) {
                error_message =  resp.data.data.error;
            } else if(resp.data?.error!== undefined){
                error_message =  resp.data.error
            } else{
                error_message =  resp.data.message
            }
            toast.error(error_message, {
              position: toast.POSITION.TOP_RIGHT
            })
        }); 
      }
    }
  }

  const handleTitle = (val, code) => {
    _name(prevState => {return {...prevState, [code]: val}})
  };

  const handleCode = (val) =>{
    _code(val.toLowerCase())
  }

  const handleChange = (e) => {
    _checked(e)
  };

  const handleCountries =()=>{
    //updates the code of language in language state whenever "Code" field gets updated 
    let data = [...languages]
    let langExists = data.findIndex((o)=>o.country_id === country)
    if(langExists!==-1){
      if(code!==''){
        data[langExists].code = code
      }
      _languages(data)
    }
  }

  return (
    <>
      <CreateLanguageWrapper>
        <div className="mt-2">
          <div className="backContainer ms-4" onClick={() => navigate("/translation?"+
            (searchParams.get("per_page") !== null 
              ? "per_page=" + searchParams.get("per_page") + "&"
              : "") +
            (searchParams.get("page") !== null
              ? "page=" + searchParams.get("page")
              : "")
            )}>
            <span className="backButton">x</span>
            <span className="px-3 backButton">|</span>
            <span className="backButton">Cancel</span>
          </div>
        </div>
        <div className="headerContainer">
          <h1>EDIT LANGUAGE</h1>
        </div>
        <div className="row">
          <div className="d-flex justify-content-end save-btn mt-2">
            <button className={(loading?"disabled":"")+" add-depertment"} disabled={loading} type="submit" onClick={handleSubmit}>
              {loading ? "Saving..." : "Save"}
            </button>
          </div>
          <div className="row input-fileds">
            <div className="col-md-4 mt-3">
              <label htmlFor="country_id" className="form-label fieldLabel">
                Flag
              </label>
              <input type="text"
                name="country_id"
                className="form-control disabled"
                disabled
                value={countries.find((o)=>o.id===country)?.fullname}
              />
            </div>
            <div className="col-md-4 mt-3">
              <label htmlFor="code" className="form-label fieldLabel">
                Code
              </label>
              <input
                type="text"
                id="code"
                name="code"
                disabled={country===''||code==='en'||code==='fr'}
                className={
                  (country === ""||code==='en'||code==='fr' ? "disabled" : "") +
                  " form-control"
                }
                placeholder="Language Code"
                value={code}
                onChange={(e) => handleCode(e.target.value)}
                onBlur={handleCountries}
                maxLength={2}
              />
            </div>
            <div className="col-md-4 mt-3">
              <label htmlFor="title" className="form-label fieldLabel">
                Status
              </label>
              <div>
                <Switch
                  onChange={(e) => handleChange(e)}
                  checked={checked}
                  disabled={code==='en'||code==='fr'}
                  className={(code==='en'||code==='fr' ? "disabled" : "")+" react-switch"}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  offColor="#D9D9D9"
                  onColor="#7589EC"
                />
              </div>
            </div>
          </div>
          <div className="row input-fileds">
            {languages.map((item, index) => {
              let itemCode = item.code;
              let flag = countries.find(
                (o) => o.id === item.country_id
              )?.flag_thumbnail;
              return (
                <div className="col-md-4" key={index}>
                  <div className="col-md-12 mt-3">
                    <label htmlFor="title" className="form-label">
                      <span className="countryIcon px-2">
                        <img
                          src={flag}
                          alt=""
                          className="countryflag"
                          title={item.name}
                        />
                      </span>
                      <span className="langName">{item.code}</span>
                    </label>
                    <input
                      type="text"
                      id={item.code}
                      name={item.code}
                      value={name[itemCode]}
                      disabled={code === "" || country === ""}
                      className={
                        (code === "" || country === "" ? "disabled" : "") +
                        " form-control"
                      }
                      onChange={(e, code = itemCode) => {
                        handleTitle(e.target.value, code);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </CreateLanguageWrapper>
    </>
  );
};

export default EditLanguage;
