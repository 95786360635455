import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import MainLayout from "../../../assets/Layout/MainLayout";
import { EditVehicleWrapper } from "../style/EditVehicle";

const EditVehicleFrameSizes = () => {

  const [searchParams, setSearchParams] = useSearchParams();

  const [loading,_loading] = useState(false)

  const [name,_name] = useState('')

  const { frameSizeId } = useParams()

  const navigate = useNavigate()

  useEffect(() => {
    getFrameSize();
  }, [])

  const getFrameSize = () =>{
    API.get(APP_URLS.SHOW_FRAME_SIZES + `/${frameSizeId}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          document.getElementById("name").value = resp.data.frameSize.name 
          document.getElementById("productType").value = resp.data.frameSize.product_type.item_name.en 
          _name(resp.data.frameSize.name)
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }
 

  const submitForm = () =>{
    let data = {}
    let flag = false
    data["name"] = name
    if(name===""||name===null){
      toast.error("Frame size name can't be blank", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else{
      flag = true
    }
    if(flag===true){
      if(!loading){
        _loading(true)
        API.post(APP_URLS.ADD_FRAME_SIZES + `/${frameSizeId}?_method=patch`,data)
        .then(res => {
          _loading(false)
          const resp = res.data;
          if(resp.success===true){
            toast.success("Frame Size Updated Successfully.", {
              position: toast.POSITION.TOP_RIGHT
            })
            navigate("/translation/vehicle-frame-sizes?"+                  
            (searchParams.get("product_type_id") !== null
            ? "product_type_id=" + searchParams.get("product_type_id") + "&"
            : "") +              
            (searchParams.get("name") !== null
            ? "name=" + searchParams.get("name") + "&"
            : "") +
            (searchParams.get("per_page") !== null 
            ? "per_page=" + searchParams.get("per_page") + "&"
            : "") +
            (searchParams.get("page") !== null
            ? "page=" + searchParams.get("page")
            : "")
            )
          }
          else{
            toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT
            })
          }
        })
        .catch(function (error) {  
          _loading(false)
          const resp = error.response;
          let error_message = '';
          if (resp.data.data !== undefined && resp.data.data !== null) {
            {
              Object.keys(resp.data.data).map(
                (error, index) =>(error_message = resp.data.data[error][0])
              );
            }
          } else if (resp.data.data?.error !== undefined) {
              error_message =  resp.data.data.error;
          } else if(resp.data?.error!== undefined){
              error_message =  resp.data.error
          } else{
              error_message =  resp.data.message
          }
          toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT
          })
        });
      }
    }
  }


  return (
    <>
      <EditVehicleWrapper>
        <div className='mt-2' >
          <div className="backContainer ms-4" onClick={()=>navigate("/translation/vehicle-frame-sizes?"+                  
            (searchParams.get("product_type_id") !== null
              ? "product_type_id=" + searchParams.get("product_type_id") + "&"
              : "") +              
            (searchParams.get("name") !== null
              ? "name=" + searchParams.get("name") + "&"
              : "") +
            (searchParams.get("per_page") !== null 
              ? "per_page=" + searchParams.get("per_page") + "&"
              : "") +
            (searchParams.get("page") !== null
              ? "page=" + searchParams.get("page")
              : "")
          )}>
            <span className='backButton'>x</span>
            <span className='px-3 backButton'>|</span>
            <span className='backButton'>Cancel</span>
          </div>
        </div>
        <div className="headerContainer">
          <h1>EDIT FRAME SIZES</h1>
        </div>
          <div className="d-flex justify-content-between save-btn mt-3">
            <h3 className="mb-2"></h3>
            <button className={(loading?"disabled":"")+" add-button"} onClick={submitForm} disabled={loading}>Save</button>
          </div>
          <div className="row input-fileds">
            <div className="col-md-4 d-flex flex-column">
                <label htmlFor="title" className="form-label fieldLabel">
                  Name
                </label>
                <input type="text" placeholder="Name" name="Name" id="name" className="form-control mt-2" onChange={(e)=>_name(e.target.value)}/>
            </div>
            <div className="col-md-4">
              <label htmlFor="productType" className="form-label fieldLabel product-type">
                Product Type
              </label>
              <input type="text" name="productType" id="productType" className="form-control mt-2 disabled" disabled />
            </div>
        </div>
      </EditVehicleWrapper>
    </>
  );
};

export default EditVehicleFrameSizes;
