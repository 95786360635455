import styled from "styled-components";

export const EditVehicleWrapper = styled.div`
  .input-fileds textarea {
    border: 1px solid #979797;
    border-radius: 4px;
  }
  .headerContainer {
    justify-content: center !important;
  }
  .search-absolute-result{
    position: absolute !important;
    top: 74px !important;
    width: 100% !important;
    background:white;
    padding: 0;
    border: 1px solid #cccccc;
    max-height: 150px;
    overflow-y: auto;
  }
  .search-absolute-result li {
      padding: 3px 10px;
  }
  .colorBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #979797;
    border-radius: 4px;
    padding: 0px 10px;
  }
  .colorBox input {
    border: none;
    width: 20%;
    height: 34px;
  }
  select {
    border: 1px solid #ced4da !important;
  }
  .input-fileds img {
    width: 25px;
    height: 25px;
  }
  .input-fileds button {
    border: none;
    background: transparent;
    color: #5d78ff;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
  // textarea {
  //   resize: none;
  // }
  .save-btn h4 {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
  .add-button {
    white-space: nowrap;
    float: right;
    border: none !important;
    border-radius: 5px;
    background: #fbc524 !important;
    color: #fff !important;
    font-weight: 500;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    padding: 0 20px;
    height: 35px;
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  .add-button:hover {
    background: #637df4;
    color: white;
  }
  select {
    border: 1px solid #979797;
    border-radius: 4px;
  }
  .fileInput {
    display: none;
  }
  .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 0px !important;
  }
  .MuiAutocomplete-root {
    width: 100% !important;
  }
  .MuiAutocomplete-input {
    border:none !important;
    padding:0px 0px 0px 5px !important;
  }
  @media (max-width: 767px) {
    .product-type {
      margin-top: 15px;
    }
  }
`;
