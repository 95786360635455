import styled from "styled-components";

export const BidListWrapper = styled.div`
.bidderType{
  display: block;
  text-align: left;
  color: #7c8586;
  font-size: 14px;
  line-height: 26px;  
}
.creationTime{
  color: #7c8586;
  font-size: 14px;
  line-height: 26px; 
}
.bids{
  display:flex;
}
.bids img{
  margin-left:10px
}
.head-0{
  width:35% !important;
}
.head-1{
  width:18% !important;
}
.head-2{
  width:18% !important;
}
.head-3{
  width:10% !important;
}
.auctionTable {
  width: 100%;
  margin-top: 10px;
  min-width: 1000px;
}
.manualTime {
  display: block;
  font-weight: 400;
}
.tleft {
  justify-content: start;
  padding-left: 7%;
}
.bidstarget {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 800px;
  width: 100%;
  border: 1px solid #C7C6CD;
  margin: 0 auto;
  border-radius: 5px;
  padding: 30px;
  align-items: center;
}
.dealerDetail, .productDetail {
  display: flex;
  flex-wrap: wrap;
}
.priceDetail h4 {
  font-size: 20px;
  font-weight: 400;
}
.priceDetail p {
  color: #5D78FF;
  font-size: 30px;
  line-height: 20px;
  font-weight: 600;
  display: block;
  width: 100%;
  margin-top: 10px;
}
.dealerDetail img{
  width: 35px;
  height: 35px;
}
.dealerDetail p, .productDetail p{
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.dealerDetail p b, .productDetail p b{
  font-size: 16px;
}
.productDetail img {
  width: 50px;
  height: auto !important;
  display: inline-table;
  object-fit: contain;
}
.seperator {
  width: 1px;
  height: 60px;
  display: block;
  justify-content: initial;
  background-color: #C7C6CD;
}
.tableHead th, tr.table-row td{
  width:25%
}

@media(max-width: 767px){
  .tableHead th, tr.table-row td{
    padding: 5px 15px !important;
  }
}

@media(max-width: 575px){
  .bidstarget{
    padding:20px 10px;
    display: block;
  }
  .seperator {
    display: none;
  }
  .productDetail img{
    width: 35px;
  }
  .dealerDetail, .productDetail, .priceDetail{
    width: 100%;
    justify-content: center;
  }
  .productDetail {
    margin: 10px 0;
    border-top: 1px solid #ddd;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
  }
  .priceDetail{
    text-align: center;
  }
  .priceDetail p {
    font-size: 22px;
    margin-top: 5px;
  }

}

`