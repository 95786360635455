import React from 'react'

export function SelectImageEdit({type, urlColor , _urlColor, colorImage, handleColorImage}) {
  const fileColorId = 'fileColor' + type

  return (
    <div className="formControlAction ">
        {urlColor?
        <div className="imageContainer">
            <img src={urlColor} />
            <span className="status status-end" onClick={()=>_urlColor('')}>x</span>
        </div>
        :!colorImage?
        <>
            <button className="btn-link addImage" onClick={()=>document.getElementById(fileColorId).click()}>+</button>
            <span className="btnName mt-2"> Add Icon </span>
        </>
            :<span className="btnName mt-2" onClick={()=>document.getElementById(fileColorId).click()}> {colorImage} </span>}
        <input type="file" id={fileColorId} className="fileInput" onChange={handleColorImage}/>
    </div>
  )
}

export function SelectImageCreate({type, colorImage, handleColorImage}) {
  const fileColorId = 'fileColor' + type 
  return (
      <div key={fileColorId} className="formControlAction ">
          {!colorImage?
          <>
            <button className="btn-link addImage" onClick={()=>document.getElementById(fileColorId).click()}>+</button>
            <span className="btnName mt-2"> Add Icon </span>
          </>
            :<span className="btnName mt-2 uploadedImage" onClick={()=>document.getElementById(fileColorId).click()}> {colorImage} </span>}
          <input type="file" id={fileColorId} className="fileInput" onChange={handleColorImage}/>
      </div>
  )
}


export function SelectImageHeading(){
   return <div className="col-md-12 d-flex justify-content-between">
            <label htmlFor="title" className="form-label product-type fieldLabel">
              Size : <span className="mx-2">45 x 45 - 80 x 80</span>Max : 5 MB
            </label>
          </div>
}
 
