import React, { useEffect, useState } from "react";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import { SlidesWrapper } from "../Slides/style/slide";
 
import Phone from "../../../assets/Images/icons/svgIcon/mobile-icon.svg";
import Desktop from "../../../assets/Images/icons/svgIcon/desktop-icon.svg";
import API from "../../../api/axios";
import Sidebar from "../components/sidebar";
import Settings from "./components/Settings";
import DesktopPreview from "./components/DesktopPreview";
import PhonePreview from "./components/PhonePreview";
import AddSlide from "./components/AddSlide"; 
import EditSlider from "./components/EditSlide"; 
import { ASPECT_RATIOS } from "../../../config/constant";

const Slider = () => {

  const [tabview, setTabView] = useState("desktop");
  const [view, setView] = useState("setting");

  const [addtab, _addtab] = useState(false);
  const [slideID,_slideID] = useState('')

  const [images, setImages] = useState([]);
  const [duration,_duration] = useState(3)

  // Filters
  const [status, _status] = useState("");
  const [countryFilter, _countryFilter] = useState("");
  const [countValue, _countValue] = useState('');
  const [page, setPage] = useState(1);


  useEffect(() => {
    getSliders()
  }, [])
  

  const getSliders = () => {
    API.get(APP_URLS.SHOW_HOME_SLIDES )
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          let imageArr = []
          resp.data.items.forEach(item => {
            imageArr.push(item.media[0].original_url)
          });
          setImages(imageArr)
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };


return (
  <>
    <SlidesWrapper>
      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-2 px-0">
            <div className="view-menu">
              <div className="preview-icon">
              {view === "preview"?
                <>
                  <img src={Desktop} className={tabview === "desktop" ? "active-preview" : ""} onClick={() => setTabView("desktop")} />
                  <img src={Phone} className={tabview === "phone" ? "active-preview" : ""} onClick={() => setTabView("phone")} />
                </>:
                ''
              }
              </div>
              <Sidebar route='home-slider'/>
            </div>
          </div>

          <div className="col-lg-10 px-0">
            <h1 className="">{addtab===false?"Home Slider":slideID===""?"ADD SLIDES":"EDIT SLIDE"}</h1>
            {addtab===false?
            <span className="setting-preview-option ">
              <button className={(view !== "setting" ? "btn-inactive" : "") + " btn-link"} onClick={() => setView("setting")}>
                Settings
              </button>
              <button className={(view !== "preview" ? "btn-inactive" : "") + " btn-link"} onClick={() => setView("preview")}>
                Preview
              </button>
            </span>
            :""}
            {addtab===false?
            view === "setting" ? (
              <Settings  
                _addtab={_addtab} _slideID={_slideID} 
                status={status} _status={_status}
                page={page} setPage={setPage}
                countValue={countValue} _countValue={_countValue}
                countryFilter={countryFilter} _countryFilter={_countryFilter}
              />
            ) : tabview === "desktop" ? (
              <DesktopPreview images={images} duration={duration}/>
            ) : (
              <PhonePreview images={images} duration={duration}/>
            ):
            slideID===""?
            <AddSlide _addtab={_addtab} aspectRatio = { ASPECT_RATIOS.HOME }  />:
            <EditSlider _addtab={_addtab} slideID={slideID} aspectRatio = { ASPECT_RATIOS.HOME } _slideID={_slideID}  />
            }
          </div>
        </div>
      </div>
    </SlidesWrapper>
  </>
);
};

export default Slider;
