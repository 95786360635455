import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, InputAdornment, MenuItem, Box, FormControl } from '@mui/material';
import { useFormik } from 'formik';
import styled from 'styled-components';
import MapAddress from './MapAddress';
import { toast } from 'react-toastify';
import API from '../../../../api/axios';
import { APP_URLS } from '../../../../api/url';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { editNameAndAddressSteps, verificationStatus } from '../../../../config/constant';

const EditUserDetailPopup = ({ open, setOpen, userData, userAddress, country, getUserDetails, hasCompany, isVerified, editUserDetails, profileVerificationStatus }) => {
    const { userId } = useParams();
    const [loading, _loading] = useState();
    const { first_name, last_name } = userData || {}; // Destructuring the user data
    const [formatted_address, _formatted_address] = useState(userAddress);
    const [fullName, _fullName] = useState({
        firstName: first_name,
        lastName: last_name
    })
    const [addressKeys, setAddressKeys] = useState({
        country_id: '', //required
        country_name: '',
        label_address: '',
        route: '', //required
        street_number: '', //required
        postal_code: '', //required
        sub_locality: '',
        locality: '', //required
        adm_area_l2_province: '',
        adm_area_l1_region: '',
        formatted_address: '', //required
        google_id: '',
        google_establish_name: '',
        google_place_id: '',
        google_map_url: '',
        lat: '',
        long: '',
    });

    const [countryData, setCountryData] = useState({
        countryId: country.country_id,
        countryFlag: '',
    })

    // Close the dialog
    const handleClose = () => {
        setOpen(false);
    };

    //getting address from mapAddress component
    const getAddress = (keys) => {
        let setFormattedAddress = `
        ${keys.route != undefined && keys.route != '' ? keys.route : ''} 
        ${keys.streetNumber != undefined && keys.streetNumber != '' ? keys.streetNumber + ',' : ''}
        ${keys.zip != undefined && keys.zip != '' ? keys.zip + ' ' : ''}
        ${keys.locality != undefined && keys.locality != '' ? keys.locality + ' ' : ''}`
        let setFormattedAddresstrim = setFormattedAddress.split('  ').join('');
        _formatted_address(setFormattedAddresstrim);
        setAddressKeys({
            ...addressKeys,
            country_id: keys.countryId, //required
            country_name: keys.country, //dont send to api
            route: keys.route, //required
            postal_code: keys.zip, //required
            street_number: keys.streetNumber, //dont send to api
            locality: keys.locality, //required
            adm_area_l1_region: keys.administrative_area_level_1,
            adm_area_l2_province: keys.administrative_area_level_2,
            formatted_address: setFormattedAddresstrim, //required
            google_place_id: keys.place_id,
            lat: keys.lat,
            long: keys.long,
        })
    }

    const formik = useFormik({
        initialValues: {
            firstName: fullName.firstName || '',
            lastName: fullName.lastName || '',
        },
        validationSchema: Yup.object({
            ...(editUserDetails == editNameAndAddressSteps.userName) && {
                firstName: Yup.string().required("First Name is required"),
                lastName: Yup.string().required("Last Name is required")
            }
        }),
        onSubmit: async (values) => {

            if (loading) return;
            const { firstName, lastName } = values;

            try {
                _loading(true);

                // Make API calls conditionally based on the name and address props
                if (editUserDetails == editNameAndAddressSteps.userName && (!isVerified || !hasCompany) && formik.dirty) {

                    // Name is changed and name prop is true, hit the name update API
                    await updateUserNames({ firstName, lastName });
                    getUserDetails();
                    toast.success('User name updated successfully.');
                }

                if (editUserDetails == editNameAndAddressSteps.userAddress && !isVerified) {
                    const { country_id, ...addressKeysWithoutCountryId } = addressKeys; //destructuring address keys to remove country id when country is not editable
                    const addressPayload = (isVerified || hasCompany || profileVerificationStatus != verificationStatus.Applied) ? addressKeysWithoutCountryId : addressKeys
                    // Address is changed and address prop is true, hit the address update API
                    await updateUserAddress(addressPayload);
                    getUserDetails()
                    toast.success('User address updated successfully.');
                }
                setOpen(false);
            } catch (error) {
                const errorMessage =
                    error?.response?.data?.data?.[Object.keys(error.response.data.data)[0]][0] ||
                    error?.response?.data?.message ||
                    error?.response?.data?.error ||
                    "An unexpected error occurred";

                toast.error(errorMessage, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } finally {
                _loading(false);
            }
        },
    });

    // API call to update user names
    const updateUserNames = async ({ firstName, lastName }) => {
        const data = { first_name: firstName, last_name: lastName };
        const response = await API.post(`${APP_URLS.UPDATE_USER_DETAILS}/${userId}`, data);
        return response.data;
    };

    // API call to update user address
    const updateUserAddress = async (addressKeys) => {
        const { label_address, country_name, ...restAddressKeys } = addressKeys;
        const response = await API.post(`${APP_URLS.UPDATE_USER_ADDRESS}/${userId}`, { ...restAddressKeys }); // Assuming this API endpoint
        return response.data;
    };

    //handles country onChnage functionality and empties the address field when country is changed
    const handleCountryChange = ({ id, flag }) => {
        setCountryData({
            countryId: id,
            countryFlag: flag
        })
        setAddressKeys({
            ...addressKeys,
            country_id: id

        })
        _formatted_address('')
    }

    //handle submit for userDetails popup
    const handleSubmit = () => {
        formik.handleSubmit();
    }

    return (
        <>
            <Wrapper>
                {open && <Dialog sx={{
                    '.MuiDialog-paper': {
                        minHeight: editUserDetails == editNameAndAddressSteps.userAddress ? '600px' : '300px',
                        minWidth: editUserDetails == editNameAndAddressSteps.userAddress ? '650px' : '500px'
                    }
                }}
                    open={open}
                    onClose={handleClose}
                >
                    <DialogTitle sx={{ textAlign: 'center' }}>{editUserDetails == editNameAndAddressSteps.userAddress ? "Edit Address" : "Edit User Name"}</DialogTitle>
                    <DialogContent>
                        {editUserDetails == editNameAndAddressSteps.userName && <>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <FormControl>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        label="First Name"
                                        name="firstName"
                                        value={formik.values.firstName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        fullWidth
                                        variant="outlined"
                                    />
                                    {formik.touched.firstName && formik.errors.firstName ? <div className="error">{formik.errors.firstName}</div> : null}
                                </FormControl>
                                <FormControl>
                                    <TextField
                                        margin="dense"
                                        label="Last Name"
                                        name="lastName"
                                        value={formik.values.lastName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        fullWidth
                                        variant="outlined"
                                    />
                                    {formik.touched.lastName && formik.errors.lastName ? <div className="error">{formik.errors.lastName}</div> : null}
                                </FormControl>
                            </Box>
                        </>}
                        {editUserDetails == editNameAndAddressSteps.userAddress && <MapAddress countryhandler={(selectedCountry) => handleCountryChange(selectedCountry)} default={countryData.countryId} getAddress={getAddress} formattedAddress={formatted_address} handleSubmit={handleSubmit} handleClose={handleClose} saveButton={true} disableCountry={isVerified || hasCompany || profileVerificationStatus != verificationStatus.Applied} />}
                    </DialogContent>
                    {editUserDetails == editNameAndAddressSteps.userName && <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit} color="primary">
                            Submit
                        </Button>
                    </DialogActions>}
                </Dialog>}
            </Wrapper>
        </>
    );
};
export default EditUserDetailPopup;

const Wrapper = styled.div`
'& .MuiDialog-container > .MuiPaper-root': {
    width: '500px !important', // Set your desired width
    height: '300px !important', // Set your desired height
    padding: theme.spacing(2), // Optional padding
  },
'.chekkkk > .MuiDialog-paper':{
  background-color:red !important;
}
`
