import styled from "styled-components";

export const DetailMemberWrapper = styled.div`
.headerContainer{
    justify-content: center;
}
.tableHead{
    border:none;
    border-bottom:1px solid #C7C6CD;
    border-radius:0px;
}
.table-row{
    border:none;
}
.text-sm{
    font-size:14px;
}
.text-m{
    font-size:17px;
    font-weight:600;
}
.icon{
    font-size: 8px;
}
.headerContent{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.details{
    display:flex;
    justify-content:center;
    padding:10px 0;
}
.detailsContent{
    display:flex;
    flex-direction: column;
}
.profile-flag{
    position:relative;
    display:inline-block;
  }
  .countryflag{
    position: relative;
    left: 26px;
    bottom: 15px;
  }
  .profileImage {
    height: 40px;
    width: 40px;
    background: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
  }
  .profileImage img {
    margin: 0 auto;
    display: flex;
    width: 38px;
    height: 38px;
    object-fit: cover;
  }
  .flagIcon{
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: -12px;
    right: 6px;
  }
  .summary{
    padding:10px;
    border : 1px solid #aeaeae;
    border-radius:5px;
  }
  .summaryContainer{
    width:100%;
    display:flex;
  }
  .summaryContent{
    width:25%;
    display:flex;
    flex-direction:column;
    align-items:center;
    border-left:1px solid #E9E9E9;
    border-right:1px solid #E9E9E9;
  }
  .summaryContent:nth-child(1) {
    border-left:none;
  }
  .summaryContent:nth-child(4) {
    border-right:none;
  }
  .summaryContent p{
    font-size:18px;
    font-weight:500;
    word-break: break-word;
    text-align:center;
  }
  .summaryContent span{
    margin-top:10px;
    word-break: break-word;
    text-align:center;
  }
  .tabsContainer{
    margin-top:50px;
  }
  .tabsHeader{
    display: flex;
    flex-direction : row;
    flex-wrap:wrap;
    min-height: 35px;
    justify-content:space-around;
    border-bottom : 1px solid #7c858640;
    margin-bottom:20px;
}
.defaultCursor{
    cursor :alias;
}
.tabsHeader span{
    padding:0 20px;
    cursor:pointer;
    word-break:break-word;
    text-align:left;
    font-size:18px;
    font-weight:500;
}
.tabActive{
    color : #607afc;
    border-bottom : 2px solid #607afc;
}
.tabInactive{
    color : black;
}
.count{
    display:flex;
    justify-content:center;
}
.w15{
    width:150px;
}
.w20{
    width:220px
}
.left{
    text-align:left;
}
.commissionData{
    display:flex;
    justify-content:center;

}
.commissionData div{
    display:flex;
    flex-direction:column;
    justify-content:center;
}
.commissionData div img{
    width:25px;
}
.dealer span{
    margin-left:5px;
}

// Verified ID
.carousel .slider-wrapper {
    padding: 0 !important;
}
.status{
    height: 15px;
    width: 15px
}
.reject{
    color:#FF0018;
    border: 1px solid #FF0018;
    font-weight:100;
    font-size:12px;
    background:white;
    letter-spacing:2px;
    padding: 4px 22px;
    border-radius:3px;
}
.accept{
    margin-left:10px;
    color:#5D78FF;
    border: 1px solid #5D78FF;
    font-weight:100;
    font-size:12px;
    background:white;
    letter-spacing:2px;
    padding: 4px 22px;
    border-radius:3px;
}
.accept:hover, .reject:hover {
    transition: all 0.1s ease-in-out;
    font-weight: bold;
    box-shadow: rgb(130 131 131 / 50%) 5px 2.196px 11px;
  }
.date{
    color:#a7a6a6;
    font-size:14px;
}
.card{
    margin-top:50px;
}
.card h3{
    margin-top:10px;
    text-align:center;
}
.cardHeader{
    display:flex;
    justify-content:space-between;
    border-bottom: 1px solid #7c858640;
}
.cardHeader button{
    border:none;
    background:white;
    padding:10px 20px;
    font-weight:800;
}
.save{
    color:#5D78FF;
}
.cancel{
    color:#E0E0E0;
}
.content{
    padding:10px 20px;
    min-height:220px;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
}
.cardStatus{
    display:flex;
    justify-content:space-between;
    align-items: center;
}
.cardContent{
    display:flex;
    flex-direction:column;
    max-height: 180px;
    overflow-y: auto;
    margin:10px 0;

}
.comments{
    color: #5D78FF;
    border:1px solid #5D78FF;
    border-radius:5px;
    padding:0 4px;
    margin-bottom:5px;
    word-break: break-word;
    width: fit-content;
    cursor:pointer;
}
.disabledcomment{
    opacity:50%;
    color:red !important; 
    border:1px solid red !important;
    border-radius:5px;
    padding:0 4px;
    margin-bottom:5px;
    word-break: break-word;
    width: fit-content;
    font-size: 14px;
    cursor:not-allowed !important;
}
.comments: hover{
    background-color: #5D78FF;
    color: white;
    transition: all .20s ease-in-out;
}
.cardFooter{
    border: 1px solid rgba(0,0,0,.125);;
    max-height: 85px;
    min-height: 84px;
    overflow-y: scroll;
}
.removeComment{
    height: 13px;
    width: 13px;
    border: 1px solid red;
    border-radius: 50%;
    text-align: center;
    line-height: 9px;
    color:red;
    font-size:12px;
    cursor:pointer;
}
.suggestion{
    color: #a7a6a6;
    font-size: 14px;
    font-style: italic;
}
.loading{
    position: absolute;
    left: 45%;
    top: 50%;
    z-index: -1;
}

//Behaviour
.sanctions{
    display: flex;
    flex-direction:column;
    align-items:center;
}
.actionBtn{
    padding:5px 20px;
    text-decoration:none;
}
.preview{
    display:flex;
    justify-content:space-around;
}
.preview img{
    width:max-content;
    width:90%; 
    height:70vh; 
    max-height:500px;
    object-fit:contain;
}
.carousel{
    display:flex;
    align-items:center;
}
.carousel button{
    height :30px;
    background :  #5D78FF !important;
    border-radius:50%;
}
.carousel.carousel-slider .control-arrow {
    top: calc(50% - 15px) !important;
}
.disabled{
    cursor: not-allowed !important;
    pointer-events: all !important;
  }
.showImage{ 
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.deleteBtn{
    border: none;
    background: white;
}
@media(max-width: 991px){
    .summary{
        margin-top:10px;
    }
    .count{
        margin-top:10px;
    }
}
@media(max-width: 575px){
    .tabsHeader span{
        width:100%;
        margin-top:5px;
    }
    .summaryContainer{
        display: block !important;
        text-align: center;
    }
    .summaryContent {
        width: 100%;
        border-bottom: 1px solid #dddddd;
        padding: 8px 0;
        border-right: 0;
        border-left: 0;
    }
    .summaryContent:nth-last-child(1) {
        border: none;
    }
    .summaryContent span {
        margin-top: 5px;
    }
    .tabsHeader span {
        text-align:center;
        width: 100%;
        border: 1px solid #dddddd;
        border-radius: 5px;
        padding: 8px;
    }
    .tabsHeader span.tabActive {
        color: #607afc;
        border: 1px solid #607afc;
    }
    .tabsContainer {
        margin-top: 20px;
    }
    .tabsHeader{
        border: none;
    }
}
.similiar-users > .user-cont{ 
    max-height:80vh;
    height:auto;
    overflow-y:scroll;
    border: 1px solid lightgray;
    border-top:none;
}
.user-cont::-webkit-scrollbar{
    width:0;
}
.similiar-users::-webkit-scrollbar{
    width:5px;
} 
.similiar-users .heading{
    border:1px solid lightgray;
}
.similiar-users .heading , .user-cont>a{
    width:100%;
    font-size:18px;
    font-weight:600;
    height:40px; 
    text-decoration:none;
    display:flex;
    align-items:start;
    justify-content:center;
    flex-direction:column; 
    padding:5px 10px;
}
.user-cont>a{
    font-weight:500;
}
.deletedRow{
    cursor:pointer !important;
}
.similiar-user{
    display:flex !important;
    flex-direction:row !important;
    justify-content:start !important;
    align-items:center  !important;
    gap:10px; 

    margin:10px 0;
    padding:10px;
  }
  .similiar-user  img{
    width:30px;
    height:30px;
    border-radius:50%;

  }
  .no-data{
     padding:10px;
  }
@media (max-width: 768px){ 
    .similiar-users{
        margin-top:32px;
    }
    .similiar-users > .user-cont{
        max-height:25rem;
    }
}
@media (min-width: 576px)
{
    .modal-dialog {
        width:90% !important;
        max-width: 800px !important;
    }
}
`