import React from "react";
import { NavLink } from "react-router-dom";
import { TableWrapper } from "../style/table";

const SideTranslate = () => {

  return (
    <>
      <TableWrapper>
        <div>
          <ul className="mb-2 ps-3 company">
            <li>
              <NavLink to="/vehicles-management/vehicle-brands">Brands</NavLink>
            </li>
            <li>
              <NavLink to="/vehicles-management/vehicle-models">Models</NavLink>
            </li>
            {/* <li>
              Filters and retaining filters is pending
              <NavLink to="/vehicles-management/vehicle-model-items">Model Items</NavLink>
            </li> */}
            <li>
              <NavLink to="/vehicles-management/vehicle-tire-size">Tire sizes</NavLink>
            </li>
            <li>
              <NavLink to="/vehicles-management/vehicle-tire-types">Tire types</NavLink>
            </li>
            <li>
              <NavLink to="/vehicles-management/vehicle-versions">Versions</NavLink>
            </li>
            <li>
              <NavLink to="/vehicles-management/euro-standards">Euro Standards</NavLink>
            </li>
          </ul>
        </div>
      </TableWrapper>
    </>
  );
};

export default SideTranslate;
