import styled from "styled-components";

export const PlanDescriptionWrapper = styled.div`
.headerContainer{
  justify-content:center;
}
.heading h2 {
  text-align: center;
  font-weight: 600;
  font-size: 40px;
  line-height: 20px;
  padding-bottom: 10px;
}
.heading h5 {
  text-align: center;
  color: #979797;
  font-size: 17px;
  line-height: 20px;
  font-weight: 600;
}
h5 img{
  margin-left:10px;
  cursor:pointer;
}
.edit-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  width:100%
}
.edit-btn h5 {
  text-align: center; 
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
}
.edit-btn button {
  display: flex;
  font-size:14px;
  align-items: center;
  padding: 6px 10px;
  cursor: pointer;
  border: 1px solid #979797;
  border-radius: 7px;
}
.edit-btn button img {
  margin-right: 7px;
  width: 15px;
  height: 15px;
}
.hr-line {
  width: 100%;
  border-top: 1px solid ##93939a;
  margin-top: 10px;
  margin-bottom: 5px;
}
.details-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:space-between;
  margin-top: 20px;
  max-width: 90%;
}
.details-name h5 {
  text-align: left;
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  width: 195px;
  color: #979797;
}
.details-name h6 {
  text-align: left;
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
  // width: 75px;
}
.tables{
  width:100%;
}
.auctionTable{
  margin-top:25px;
}
table, th, td {
  border-collapse: collapse;
}
.description{
  text-align: left !important;
}
.name{
  text-align: left !important;
}
thead, tr{
  border-radius: 0 !important;
  border-left: 0;
  border-right: 0;
}
tr{
  border:none;
}
.table-row:last-child{
  border:none;
}
.description{
  width:30% !important;
}
.description div{
  display:flex;
  flex-direction:row;
}
.subsription{
  width:150px;
}
.pricedesc{
  width:220px !important;
}
.pricedesc div p{
  display:block;
  font-weight:bold;
}
.priceName{
  font-weight:600 !important;
  color:#c1c0c4;
  border:none;
}
td div img{
  margin-left:10px;
  cursor:pointer;
}
.features {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.features button {
  padding: 6px 10px;
  font-size:14px;
  cursor: pointer;
  border: 1px solid #979797;
  border-radius: 7px;
}
.features h5 {
  text-align: left;
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
}
.reject{
  color:#FF0018;
  font-weight: 700;
  border: 1px solid #FF0018 !important;
  font-size:14px;
  background:white;
  letter-spacing:1px;
  border-radius:3px;
}
.accept{
  color:#5D78FF;
  font-weight: 700;
  border: 1px solid #5D78FF !important;
  font-size:14px;
  background:white;
  letter-spacing:1px;
  border-radius:3px;
}
.clipboard{
  width:200px !important;
}
.copyText{
  border: 1px solid black;
  padding:2px 5px;
  border-radius:5px;
  display: inline-block;
  width: 150px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  background: white;
}
.features {
  position: relative;
}
.featureSearch{
  position: relative
}
.featureSearch input {
  width: 100%;
  border: 0.5px solid #c7c6cd;
  border-radius: 5px;
  background-color: transparent;
  padding: 12px 0px 12px 15px;
  margin-top: 15px;
  height: 40px;
}
.searchIcon {
  height: 26px;
  width: 28px;
  padding: 4px;
  position: absolute;
  box-sizing: border-box;
  top: 65%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
.featureContainer {
  margin-top: 40px;
}
.featureName {
  border: 0.5px solid #c7c6cd;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-bottom: 10px;
}
.featureName input {
  margin-right: 15px;
}
.norecord{
  color : #f13e3ecf;
  font-style: italic;
  font-size:13px;
}
.form-control{
  max-width:400px
}
@media(max-width: 767px){
  .details-name {
    max-width: 100%;
  }
}
`;
