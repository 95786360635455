import React, { useEffect, useState } from 'react'

const VerifiedHeader = (props) => {

    const [docID,_docID] = useState('')
    const [selfie,_selfie] = useState('')

    useEffect(() => {
        let data
        if(props.headerData){
            for (let i = 0; i<props.headerData.length;i++){
                if(props.headerData[i].collection_name==='passport'){
                    data = props.headerData[i]
                    break
                }
                else if(props.headerData[i].collection_name==='driving_license_front'){
                    data = props.headerData[i]
                    break
                }
            }
            _docID(data)
            for (let i = 0; i<props.headerData.length;i++){
                if(props.headerData[i].collection_name==='selfie_with_id_document'){
                    _selfie(props.headerData[i])
                    break
                }

            }
        }
    }, [props.headerData])
    
  return (
    <div className="col-lg-6">
        <div className="container summary">
            <div className="row">
                <div className="col-sm-5 offset-sm-1 my-2 offset-2">
                    <p>
                        {props.data.email_verified_at?
                        <span className='status status-active' title='Verified'>
                            <i className="fa fa-check" aria-hidden="true"></i>
                        </span>:
                        <span className='status status-progress' title='Pending'>
                        </span>}
                        <b className='ms-4'>Email</b>
                    </p>
                </div>
                <div className="col-sm-5 offset-sm-1 my-2 offset-2">
                    <p>
                        {selfie!==''&&selfie!==undefined?
                            selfie.custom_properties.accepted_at?
                                <span className='status status-active' title='Verified'>
                                    <i className="fa fa-check" aria-hidden="true"></i>
                                </span>
                            :selfie.custom_properties.refused_at?
                                <span className='status status-end' title='Rejected'>
                                </span>
                            :
                                <span className='status status-progress' title='Pending'>
                                </span>
                        :<span className='status status-pending' title='Not Applied'>
                        </span>}
                        <b className='ms-4'>Selfie</b>
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-5 offset-sm-1 my-2 offset-2">
                    <p>
                        {docID!==''&&docID!==undefined?
                            docID.custom_properties.accepted_at?
                                <span className='status status-active' title='Verified'>
                                    <i className="fa fa-check" aria-hidden="true"></i>
                                </span>
                            :docID.custom_properties.refused_at?
                                <span className='status status-end' title='Rejected'>
                                </span>
                            :
                                <span className='status status-progress' title='Pending'>
                                </span>
                        :<span className='status status-pending' title='Not Applied'>
                        </span>}
                    <b className='ms-4'>Document ID</b>
                    </p>
                </div>
                <div className="col-sm-5 offset-sm-1 my-2 offset-2">
                {/* <p>
                    <span className='status status-progress' title='Pending'>
                        <i className="fa fa-check" aria-hidden="true"></i>
                    </span>
                    <b className='ms-4'>Phone</b>
                </p> */}
                </div>
            </div>
        </div>
    </div>
  )
}

export default VerifiedHeader