import styled from "styled-components";
export const NotificationWrapper = styled.div`
  .notificationIcon {
    cursor: pointer;
  }
  .notifications {
    max-height: calc(100vh - 82px);
    overflow: auto;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .headTitle {
    font-weight: 600;
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .add-button {
    white-space: nowrap;
    border: 1px solid #5d78ff;
    border-radius: 8px;
    background: transparent;
    color: #637df4;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    padding: 0 20px;
    height: 30px;
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  .inactivebtn:hover,.add-button:hover {
    background: #637df4;
    color: white;
  }
  .inactivebtn {
    border-radius: 8px;
    white-space: nowrap;
    border: none;
    background: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    padding: 0 20px;
    height: 30px;
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  .notification-outer {
    border-radius: 10px;
    z-index: 1;
    width: 100%;
    margin: 15px 0;
  }
  .notification-alert {
    padding: 15px;
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 15%) 0px 0px 20px;
    border-radius: 10px;
    z-index: 1;
    width: 100%;
    position: relative;
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  .notification-alert1{
    padding: 15px;
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 15%) 0px 0px 20px;
    border-radius: 10px;
    z-index: 1;
    width: 100%;
    position: relative;
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  .notification-outer:hover .notification-alert {
    margin-left: -60px;
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  .notification-outer:hover .deletebtn {
    opacity: 1;
    width: 50px;
  }
  .deletebtn {
    background-color: rgb(220, 64, 64);
    box-shadow: rgb(0 0 0 / 8%) 0px 0px 10px;
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    border: 0px;
    height: 100%;
    position: absolute;
    top: 0px;
    right: 0;
    width: 0px;
    opacity: 0;
    overflow: hidden;
    white-space: nowrap;
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    transition: opacity 0.2s ease-in-out 0s, width 0.3s ease-in-out 0s;
  }

  .profile {
    border-radius: 50px;
  }
  .notification-time {
    position: absolute;
    right: 15px;
    top: 6px;
  }
  .item-all .notification-content {
    width: calc(100% - 20px);
  }
  .item-all .notification-content:hover{
    cursor:pointer;
  }
  .item-all .notification-content .notification-content-text {
    width: 220px;
  }
  .readNotification {
    color: #938f8f;
  }
  .accept-btn {
    margin-right: 10px;
    border: 1px solid rgb(66, 183, 42);
    border-radius: 8px;
    transition: all 0.2s ease-in-out 0s;
  }
  .cancel-btn {
    border: 1px solid rgb(255, 0, 0);
    border-radius: 8px;
    transition: all 0.2s ease-in-out 0s;
  }
  .accept-btn span {
    background: rgb(66, 183, 42);
    border-radius: 100%;
    padding: 1px;
  }
  .cancel-btn span {
    background: rgb(255, 0, 0);
  }
  .status {
    height: 15px !important;
    width: 15px !important;
  }
  .statusq {
    height: 15px !important;
    width: 15px !important;
  }
  .valueBadge {
    position: absolute;
    right: 8px;
    bottom: 142px;
    border-radius: 50%;
  }
`;
