import React, { useEffect, useRef } from 'react'
import close from "../../../assets/Images/icons/Cancel.svg";

function Popup({json , setJson}) {

  const customComponentRef = useRef(null);

  useEffect(() => {
    // by clicking outside of dropdown , close that dropdown
    function handleClickOutside(e) {
      if (
        customComponentRef.current &&
        !customComponentRef.current.contains(e.target)
      ) { 
        setJson('')
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); 
  return (
    <div   className="modal fade show custom-modal modal-backdrop">
    <div className="modal">
      <div ref={customComponentRef}  className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content"> 
        <div className="modal-header">
                <h5 className="modal-title">Data:</h5>
                <img src={close} alt="" className="mx-2" role="button" onClick={()=> setJson('')}/>
            </div>
          <div className="modal-body"> 
             <pre>{JSON.stringify(json,null,2)}</pre>
          </div>  
        </div>
      </div>
    </div>
  </div>
  )
}

export default Popup