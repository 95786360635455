import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import API from '../../api/axios';
import { APP_URLS } from '../../api/url';
import { DashboardWrapper } from '../Dashboard/Dashboard/style/dashboard';
import { toast } from 'react-toastify';
import styled from '@emotion/styled';

const DownloadZipFile = () => {
    const navigate = useNavigate();
    const [downloadProgress, setDownloadProgress] = useState(0);
    const [isDownloading, setIsDownloading] = useState(false);
    const [zipError, _zipError] = useState('');

    useEffect(() => {
        const currentUrl = window.location.href; // Get the current URL

        const downloadPath = "download-invoice-zip"; // Check if the URL contains the 'download-invoice-zip' path

        const tokenStartIndex = currentUrl.indexOf(downloadPath) + downloadPath.length; // to get the starting index for token

        if (tokenStartIndex > downloadPath.length - 1) { //to check, is there a token present after that path(downloadPath)

            const tokenWithParams = currentUrl.substring(tokenStartIndex); //token with params

            API.get(`${APP_URLS.DOWNLOAD_ZIP_FILE_FROM_MAIL}${tokenWithParams}`, {
                responseType: 'blob', // To handle file download
                onDownloadProgress: progressEvent => { //to handle downloading progress
                    const totalLength = progressEvent.total;
                    if (totalLength) {
                        setIsDownloading(true)
                        const progress = Math.round((progressEvent.loaded * 100) / totalLength);
                        setDownloadProgress(progress); // Update the progress state
                    }
                }
            })
                .then(response => {
                    // Create a link element, trigger download
                    const link = document.createElement('a');
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    link.href = url;
                    link.setAttribute('download', 'invoice.zip'); // Specify the file name
                    document.body.appendChild(link);
                    link.click();
                    link.remove();

                    toast.success("Invoice zip downloaded successfully!", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                })
                .catch(async error => {
                    const resp = error.response;

                    // Check if responseType is 'blob'
                    if (resp && resp.data instanceof Blob) {
                        try {
                            const errorText = await resp.data.text();
                            const errorJson = JSON.parse(errorText);
                            const error_message = errorJson.message || 'Something went wrong';

                            _zipError(error_message)
                        } catch (e) {
                            _zipError(e)
                        }
                    } else {
                        const error_message = resp?.data?.message || 'Something went wrong';
                        _zipError(error_message)
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        navigate('/')
                    }, 2000)
                });
        }
        // Cleanup function
        return () => {
            setIsDownloading(false);
            setDownloadProgress(0);
        }
    }, [])

    return (
        <DashboardWrapper>
            <Container>
                <DownloadWrapper>
                    <Title>{zipError || 'Downloading Invoice Zip'}</Title>
                    {isDownloading && !zipError && (
                        <ProgressBarContainer>
                            <ProgressBarWrapper>
                                <ProgressBar style={{ width: `${downloadProgress}%` }} />
                            </ProgressBarWrapper>
                            <ProgressText>{downloadProgress}% Downloaded</ProgressText>
                        </ProgressBarContainer>
                    )}
                    {!isDownloading && !zipError && (
                        <Message>
                            {downloadProgress === 100 ? 'Download complete!' : 'Preparing download...'}
                        </Message>
                    )}
                </DownloadWrapper>
            </Container>
        </DashboardWrapper>
    )
}

export default DownloadZipFile

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f3f4f6;
`;

const DownloadWrapper = styled.div`
  width: 100%;
  max-width: 28rem;
  padding: 2rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
`;

const ProgressBarContainer = styled.div`
  margin-bottom: 1rem;
`;

const ProgressBarWrapper = styled.div`
  width: 100%;
  background-color: #e5e7eb;
  border-radius: 9999px;
  height: 0.625rem;
`;

const ProgressBar = styled.div`
  background-color: #607afc;
  height: 0.625rem;
  border-radius: 9999px;
  transition: width 0.3s ease-in-out;
`;

const ProgressText = styled.p`
  font-size: 0.875rem;
  color: #4b5563;
  margin-top: 0.5rem;
  text-align: center;
`;

const Message = styled.p`
  text-align: center;
  color: #4b5563;
`;