import styled from "styled-components";

export const ListWrapper = styled.div`
.headerContainer {
    justify-content: center !important;
}
.action button {
    background: transparent;
    border: none;
    cursor: pointer;
}
.viewIcon{
    height:25px;
}
.publishBox{
    // max-width:100px;
}
.publishBtn{
    background-color: #607afc;
    color: white;
    border: none;
    padding: 4px 15px;
    border-radius: 4px;
    transition: all 0.15s ease-in-out;
    font-weight: bold
}
.publishBtn:hover{
    box-shadow: rgba(0,0,0,0.2) 2px 5px 4px -1px;
}
.actionIcon img{
    width: 28px;
}
.action{
 min-width:170px
}
.pubish{
    background: transparent;
    border: none;
    cursor: pointer;
}
`;