import styled from "styled-components";

export const PlanWrapper = styled.div`
  .container {
    margin-top: 10px;
  }
  .btn {
    align-self: center;
    background: #fbc524 !important;
    border: none;
    height: 35px;
    border-radius: 5px;
    padding: 0 20px;
    color: white;
    font-weight: bold;
  }
  .productInfo-root h3 {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
  }
  .input-section {
    margin-top: 8px;
  }
  .formInput {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .form-control{
    height: 40px;
  }
  .inputTag {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #c7c6cd;
    margin-top: 10px;
    border-radius: 5px;
  }
  .inputTag input {
    margin-top: 0 !important;
    width: 75%;
    border: none !important;
  }
  .inputTag p {
    text-align: center;
    width: 25%;
    border-left: 1px solid #c7c6cd;
    height: 40px;
    line-height: 40px;
  }
  .formInput button {
    margin-top: 10px;
    width: 100px;
    background: white;
    border: 1px solid #5d78ff;
    color: #5d78ff;
    border-radius: 5px;
    height: 40px;
  }
  .form-textarea{
    display: block;
    width: 100% !important;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }

  ////Product Features Page Css

  .features {
    position: relative;
  }
  .searchFeature {
    width: 100%;
    border: 0.5px solid #c7c6cd;
    border-radius: 5px;
    background-color: transparent;
    padding: 12px 0px 12px 38px;
    margin-top: 15px;
    height: 40px;
  }
  .searchIcon {
    height: 26px;
    width: 28px;
    padding: 4px;
    position: absolute;
    box-sizing: border-box;
    top: 72%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .featureContainer {
    margin-top: 40px;
  }
  .featureName {
    border: 0.5px solid #c7c6cd;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    margin-bottom: 10px;
  }
  .featureName input {
    margin-right: 15px;
  }
  .price-section .container {
    padding-left: 0 !important;
    padding-right: 0;
  }
  .formInput {
    margin-bottom: 20px;
  }
  .loader{
    color : #818181;
    font-style: italic;
    font-size:13px;
  }
  .norecord{
    color : #f13e3ecf;
    font-style: italic;
    font-size:13px;
  }

  @media (max-width: 767px) {
    .input-section .formInput input {
      width: 100%;
    }
  }
`;
