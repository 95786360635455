import styled from "styled-components";

export const CommissionWrapper = styled.div`
  .headerContainer div,
  .headerContainer h1 {
    margin-bottom: 10px;
  }
  .searchContainer {
    display: flex;
    padding: 0 15px 0 0;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  .search {
    height: 35px;
    border: none;
    border-radius: 10px;
  }
  .btn {
    align-self: center;
    background-color: #5d78ff;
    border: none;
    height: 35px;
    border-radius: 5px;
    padding: 0 20px;
    color: white;
    font-weight: bold;
  }
  @media (max-width: 490px) {
    .add-info {
      // display: flex !important;
      align-items: center;
      justify-content: center !important;
      flex-wrap: wrap;
    }
    .select-info {
      justify-content: flex-end !important;
    }
  }
  .btn.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none; /* Ensures it is not clickable */
  }
  @media (min-width: 489px) and (max-width: 495px) {
    .actionContainer {
      margin-right: 15px;
    }
  }
`;
