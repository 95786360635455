import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import useWindowDimensions from '../../../assets/Screen Size';
import faArrow from "../../../assets/Images/icons/faChevronDown.svg"

const Sidebar = ( { initialFileName, files ,fileName,_fileName}) => {

  const { height, width } = useWindowDimensions();

  const [show,_show] = useState(false)
   
  useEffect(()=>{
    if(fileName ===initialFileName){ 
      if(files && files.length > 0) { 
        _fileName( files[0]);
      } 
   }
  },[files])
  return (
    <ul className="slide-nav">
      {width>991?
        files.map((item, index)=>{
            return <li className={fileName===item?"active-view":""} onClick={()=>_fileName(item)} key={index}>
            {item}
        </li>
        })
      :
      <>
        <li className="active-view d-flex justify-content-between" onClick={()=>_show(!show)}>
          <span>Menu</span>
          <img src={faArrow} className={show?"closeMenu":"openMenu"}/>
        </li>
        <div className={show?"show":"hide"}>
          {files.map((item, index)=>{
              return <li className={fileName===item?"active-view":""} onClick={()=>_fileName(item)} key={index}>
              {item}
            </li>
           })}
        </div>
      </>}
    </ul>
  )
}

export default Sidebar