import React, { useState, useRef, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import axios from 'axios';

const MapView = (props) => {
    const { getTargetedAddress } = props;

    // Create states for latitude and longitude
    const [lat, setLat] = useState(props.lat || 0);  // Default to 0 if no initial lat provided
    const [long, setLong] = useState(props.long || 0);  // Default to 0 if no initial long provided
    const mapRef = useRef(null);  // To store the map instance
    const markerRef = useRef(null);  // To store the marker instance

    // Function to update address based on new lat/lng
    const updateAddressFromLatLng = async (latset, longset) => {
        const geoCodeLibrary = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latset},${longset}&key=${process.env.REACT_APP_GOOGLE_KEY}`;

        try {
            const res = await axios.get(geoCodeLibrary);
            const formattedAddress = res.data.results[0]?.formatted_address || '';
            const place_id = res.data.results[0]?.place_id || '';
            const addressComponents = res.data.results[0]?.address_components || [];
            const address = {
                formatted_address: formattedAddress,
                streetNumber: addressComponents.find(c => c.types.includes('street_number'))?.long_name || '',
                route: addressComponents.find(c => c.types.includes('route'))?.long_name || '',
                locality: addressComponents.find(c => c.types.includes('locality'))?.long_name || '',
                sublocality: addressComponents.find(c => c.types.includes('sublocality'))?.long_name || '',
                zip: addressComponents.find(c => c.types.includes('postal_code'))?.long_name || '',
                country: addressComponents.find(c => c.types.includes('country'))?.long_name || '',
                administrative_area_level_1: addressComponents.find(c => c.types.includes('administrative_area_level_1'))?.long_name || '',
                administrative_area_level_2: addressComponents.find(c => c.types.includes('administrative_area_level_2'))?.long_name || '',
                lat: latset,
                long: longset,
                place_id
            };

            // Send updated address back to the parent component when selecting location on map view
            getTargetedAddress(address);
        } catch (e) {
            console.error('Error fetching geocoding data:', e);
        }
    };

    // Load map and create a marker at the center
    const loadMap = (map, maps) => {
        mapRef.current = map;

        // Handle map center changes (while dragging)
        map.addListener('center_changed', () => {
            const newCenter = map.getCenter();

            // Update the marker position to the new center
            marker.setPosition(newCenter);
        });

        // Create a marker at the initial position (center)
        const marker = new maps.Marker({
            position: { lat, lng: long },
            map,
            icon: `${process.env.REACT_APP_API_STORAGE}/siteImages/map_icon.png`,
        });
        markerRef.current = marker;

        // Handle when the map dragging ends (i.e., user moves the map)
        map.addListener('dragend', () => {
            const newCenter = map.getCenter();
            const newLat = newCenter.lat().toFixed(8);
            const newLng = newCenter.lng().toFixed(8);

            // Update the marker position to the new center
            marker.setPosition(newCenter);

            // Update the state with new coordinates
            setLat(parseFloat(newLat));
            setLong(parseFloat(newLng));

            // Fetch and update address details based on new lat/lng
            updateAddressFromLatLng(newLat, newLng);
        });
        // Set the marker position on initial load to ensure it starts at the center
        marker.setPosition({ lat, lng: long });
    };

    useEffect(() => {
        setLat(props.lat);
        setLong(props.long);
    }, [props.lat, props.long]);

    return (
        <div className="map-container" style={{ width: '100%', height: '400px' }}>
            {lat && long ? (
                <GoogleMapReact
                    // key={`${lat}-${long}`}  // Force re-render on lat/long change
                    bootstrapURLKeys={{
                        key: `${process.env.REACT_APP_GOOGLE_KEY}`,
                        libraries: ['places'],
                    }}
                    yesIWantToUseGoogleMapApiInternals
                    defaultCenter={{ lat, lng: long }}
                    defaultZoom={18}
                    onGoogleApiLoaded={({ map, maps }) => loadMap(map, maps)}
                />
            ) : null}
        </div>
    );
};

export default MapView;
