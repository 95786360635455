import React, { useEffect, useState } from 'react'
import { APP_URLS } from '../../../api/url';
import API from '../../../api/axios';
import SearchableObjDropdownWithImage from '../SearchableDropdown/SearchableObjDropdownWithImage';
import { toast } from 'react-toastify';
import { DEBOUNCE_TIME, userTypeName } from '../../../config/constant';

function BuyerCustomersFilter({ buyer, setBuyer, filters, _filters, searchSubKeyword, _searchSubKeyword, type }) {
    const [customers, _customers] = useState([])
    const [customerName, _customerName] = useState('')
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [loadingOptions, _loadingOptions] = useState(false);

    //Using isDataFetched to trigger the API call only when the data has already been fetched and a customer search using Debounce effect is being performed
    useEffect(() => {
        if (isDataFetched) {
            const timer = setTimeout(() => {
                getAllCustomers();
            }, DEBOUNCE_TIME);

            return () => clearTimeout(timer);
        }
    }, [customerName])

    const getAllCustomers = () => {
        _loadingOptions(true);
        API.get(`${APP_URLS.ALL_CUSTOMER}?keyword=${customerName}&per_page=20`)
            .then((res) => {
                const resp = res.data;
                if (res.status === 200) {
                    _customers(resp.data)
                    setIsDataFetched(true);
                    _loadingOptions(false);
                } else {
                    toast.error(resp.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch(function (error) {
                const resp = error.response;
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                    Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
                } else if (resp.data.data?.error !== undefined) {
                    error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                    error_message = resp.data.error;
                } else {
                    error_message = resp.data.message || 'An unknown error occurred';
                }
                toast.error(error_message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });
    };

    const handleDropdownClick = () => {
        if (!isDataFetched) {
            getAllCustomers();  // Fetch customers only if they haven't been fetched already
        }
    };

    return <div onClick={handleDropdownClick}>
        <SearchableObjDropdownWithImage _filters={_filters} filters={filters} name={`buyerCustomer`} className="filterBox" setChangeValue={(val) => setBuyer(val)} val={buyer} placeholder={loadingOptions ? 'Loding...' : `Select ${type}`} dropdownData={customers} func={_customerName} searchkeyword={searchSubKeyword} _searchkeyword={_searchSubKeyword} allData={true} loadingOptions={loadingOptions} />
    </div>
}

export default BuyerCustomersFilter
