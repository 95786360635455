import React, { useEffect, useState } from 'react'
import * as Constant from "../../../../../config/constant";

const ValidationHeader = (props) => {
    
    const [VAT,_VAT] = useState('')
    const [address,_address] = useState('')
    const [name,_name] = useState('')
    const [document,_document] = useState('')

    useEffect(() => {
        if(props.headerData.length>0){
            for (let i = 0;i<props.headerData.length;i++){
                if(props.headerData[i].type===0){
                    _VAT(props.headerData[i])
                    break
                }
    
            }
            for (let i = 0;i<props.headerData.length;i++){
                if(props.headerData[i].type===1){
                    _name(props.headerData[i])
                    break
                }
    
            }        
            for (let i = 0;i<props.headerData.length;i++){
                if(props.headerData[i].type===2){
                    _address(props.headerData[i])
                    break
                }
    
            }        
            for (let i = 0;i<props.headerData.length;i++){
                if(props.headerData[i].type===3){
                    _document(props.headerData[i])
                    break
                }
    
            }
        }
    }, [props.headerData])
    

  return (
    <div className="col-lg-6">
        <div className="container summary">
            <div className="row ">
                <div className="col-sm-5 offset-sm-1 my-2 offset-2">
                    <p>
                        {VAT!==''&&VAT!==undefined?
                            VAT.value.accepted_at?
                                <span className='status status-active' title='Verified'>
                                    <i className="fa fa-check" aria-hidden="true"></i>
                                </span>
                            :VAT.value.refused_at?
                                <span className='status status-end' title='Rejected'>
                                </span>
                            :
                                <span className='status status-progress' title='Pending'>
                                </span>
                        :<span className='status status-pending' title='Not Applied'>
                        </span>}
                        <b className='ms-4'>VAT</b>
                    </p>
                </div>
                <div className="col-sm-5 offset-sm-1 my-2 offset-2">
                    <p>
                        {name!==''&&name!==undefined?
                            name.value.accepted_at?
                                <span className='status status-active' title='Verified'>
                                    <i className="fa fa-check" aria-hidden="true"></i>
                                </span>
                            :name.value.refused_at?
                                <span className='status status-end' title='Rejected'>
                                </span>
                            :
                                <span className='status status-progress' title='Pending'>
                                </span>
                        :<span className='status status-pending' title='Not Applied'>
                        </span>}
                        <b className='ms-4'>Name</b>
                    </p>
                </div>
                <div className="col-sm-5 offset-sm-1 my-2 offset-2">
                    <p>
                        {address!==''&&address!==undefined?
                            address.value.accepted_at?
                                <span className='status status-active' title='Verified'>
                                    <i className="fa fa-check" aria-hidden="true"></i>
                                </span>
                            :address.value.refused_at?
                                <span className='status status-end' title='Rejected'>
                                </span>
                            :
                                <span className='status status-progress' title='Pending'>
                                </span>
                        :<span className='status status-pending' title='Not Applied'>
                        </span>}
                        <b className='ms-4'>Address</b>
                    </p>
                </div>
                <div className="col-sm-5 offset-sm-1 my-2 offset-2">
                    <p>
                        {document!==''&&document!==undefined?
                            document.value.accepted_at?
                                <span className='status status-active' title='Verified'>
                                    <i className="fa fa-check" aria-hidden="true"></i>
                                </span>
                            :document.value.refused_at?
                                <span className='status status-end' title='Rejected'>
                                </span>
                            :
                                <span className='status status-progress' title='Pending'>
                                </span>
                        :<span className='status status-pending' title='Not Applied'>
                        </span>}
                        <b className='ms-4'>Nomad Document</b>
                    </p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ValidationHeader