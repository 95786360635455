import { useFormik } from "formik";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../../../assets/Layout/MainLayout";
import { CreateCommissionWrapper } from "./style/createCommission";
import * as Yup from "yup";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import { commissionTypeOptions } from "../../../config/constant";

const CreateCommission = () => {
  const [type, _type] = useState("amount");
  const [minimumSales, _minimumSales] = useState(false);
  const [loading, _loading] = useState(false)
  const location = useLocation();
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      name: "",
      type: "amount",
      percentageOff: "",
      recurring: "",
      sales: false,
      salesAmount: "",
      maxAmount: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Commission Name is required"),
      type: Yup.string().required("Select commission discount type"),
      percentageOff: Yup.number().min(1, "Minimum Value should 1").required("Discount amount/percentage is required").when('type', {
        is: 'amount',
        then: Yup.number().integer("Decimal Values aren't allowed")
      }),
      ...((location.state.type == commissionTypeOptions["Nomad commission"]) && {
        salesAmount: Yup.number().when('sales', {
          is: true,
          then: Yup.number().required("Minimum Sales Amount is required").integer("Decimal values aren't allowed").min(1, "Minimum Value should be 1")
        }),
        recurring: Yup.string().required("Commission Recurring is required"),
      }),
      ...((location.state.type == commissionTypeOptions["Vehicle sale commission"]) && {
        maxAmount: Yup.number().required("Max amount field is required").min(1, "Max amount field must be at least € 1").max(9999, "Max amount field cannot exceed € 9999 "),
      })
    }),
    onSubmit: (values) => {
      let data = {}
      data['name'] = values.name
      data['type'] = location.state.type == commissionTypeOptions["Nomad commission"] ? commissionTypeOptions["Nomad commission"] : commissionTypeOptions["Vehicle sale commission"]
      if (type === "percentage") {
        data['percent'] = values.percentageOff
      }
      else {
        data['amount'] = values.percentageOff
      }
      if (location.state.type == commissionTypeOptions["Nomad commission"]) {
        data['recurring'] = values.recurring
        data['require_minimum_sales'] = values.sales === true ? 1 : 0
        if (minimumSales) {
          data['minimum_sales_amount'] = values.salesAmount
        }
      }else if(location.state.type == commissionTypeOptions["Vehicle sale commission"]){
        data['max_amount'] = values.maxAmount
      }
      _loading(true)
      API.post(APP_URLS.ADD_COMMISSION, data)
        .then(res => {
          _loading(false)
          const resp = res.data;
          if (resp.success === true) {
            toast.success("Commission has been created successfully.", {
              position: toast.POSITION.TOP_RIGHT
            })
            navigate("/commission")
          }
          else {
            toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT
            })
          }
        })
        .catch(function (error) {
          _loading(false)
          const resp = error.response;
          let error_message = '';
          if (resp.data.data !== undefined && resp.data.data !== null) {
            {
              Object.keys(resp.data.data).map(
                (error, index) => (error_message = resp.data.data[error][0])
              );
            }
          } else if (resp.data.data?.error !== undefined) {
            error_message = resp.data.data.error;
          } else if (resp.data?.error !== undefined) {
            error_message = resp.data.error
          } else {
            error_message = resp.data.message
          }
          toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT
          })
        });
    },
  });

  return (
    <>
      <CreateCommissionWrapper>
        <div className="auctionMainContainer">
          <div className="mt-2">
            <div className="backContainer ms-4" onClick={() => navigate("/commission")}>
              <span className="backButton">x</span>
              <span className="px-3 backButton">|</span>
              <span className="backButton">Cancel</span>
            </div>
          </div>
          <div className="headerContainer d-flex justify-content-center">
            <h1>CREATE COMMISSION</h1>
          </div>
          <form className="container" onSubmit={formik.handleSubmit}>
            <div className="actionContainer d-flex justify-content-end">
              <button className={(loading ? "disabled" : "") + " btn"} disabled={loading} type="submit">
                {loading ? "Saving..." : "Save"}
              </button>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="formInput">
                  <label htmlFor="name" className="form-label"><b>Name</b></label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Commission Name"
                    className="form-control"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.name && formik.errors.name ? <div className="error">{formik.errors.name}</div> : null}
                </div>
              </div>
              {location.state.type == commissionTypeOptions["Nomad commission"] ? <>
                <div className="col-md-4">
                  <div className="formInput">
                    <label htmlFor="recurring" className="form-label"><b>Recurring</b> </label>
                    <select name="recurring" id="recurring" value={formik.values.recurring}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="form-control">
                      <option hidden>Select</option>
                      <option value="1">Monthly</option>
                      <option value="2">Quarterly</option>
                      <option value="3">Yearly</option>
                    </select>
                    {formik.touched.recurring && formik.errors.recurring ? <div className="error">{formik.errors.recurring}</div> : null}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="row">
                    <div className="formInput ">
                      <div className="options mb-3">
                        <input type="checkbox" id="sales" name="sales"
                          value={formik.values.sales}
                          onBlur={formik.handleBlur}
                          onChange={(e) => {
                            formik.handleChange(e)
                            _minimumSales(!minimumSales)
                          }} />
                        <label htmlFor="sales" className="label ms-3"><b>Require Minimum Sales</b></label>
                      </div>
                      <div className="formInput">
                        <input
                          type="number"
                          id="salesAmount"
                          name="salesAmount"
                          disabled={!minimumSales}
                          min={1}
                          placeholder="Minimum Sales Amount"
                          className={(!minimumSales ? "disabled" : "") + " form-control"}
                          value={formik.values.salesAmount}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.salesAmount && formik.errors.salesAmount ? <div className="error">{formik.errors.salesAmount}</div> : null}
                      </div>
                    </div>
                  </div>
                </div>
              </> : null
              }
              <div className="col-md-4">
                <div className="formInput">
                  <label htmlFor="percentage" className="form-label mb-3"><b>{type === "percentage" ? "Percentage" : "Amount"}</b></label>
                  <div className="input-group">
                    <input
                      type="number"
                      id="percentage"
                      name="percentageOff"
                      placeholder="Commission Value"
                      className="form-control"
                      min={1}
                      value={formik.values.percentageOff}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <span className="input-group-text" id="off-percentage">{type === "percentage" ? "%" : "€"}</span>
                  </div>
                  {formik.touched.percentageOff && formik.errors.percentageOff ? <div className="error">{formik.errors.percentageOff}</div> : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="formInput">
                  <div className="row">
                    <label htmlFor="type" className="form-label"><b>Type</b></label>
                    <div className="d-flex flex-row mt-3">
                      <div className="d-flex align-item-center">
                        <input
                          type="radio"
                          id="percentageDiscount"
                          name="type"
                          value="percentage"
                          className="ms-1"
                          onChange={() => {
                            _type("percentage");
                            formik.setFieldValue("type", "percentage");
                          }}
                        />
                        <label htmlFor="percentageDiscount" className="ms-3">Percentage</label>
                      </div>
                      <div className="ms-5 d-flex align-item-center">
                        <input
                          type="radio"
                          id="amountDiscount"
                          name="type"
                          checked={formik.values.type === "amount" ? true : false}
                          value="amount"
                          className="ms-1"
                          onChange={() => {
                            _type("amount");
                            formik.setFieldValue("type", "amount");
                          }}
                        />
                        <label htmlFor="amountDiscount" className="ms-3">Amount</label>
                      </div>
                    </div>
                    {formik.touched.type && formik.errors.type ? <div className="error">{formik.errors.type}</div> : null}
                  </div>
                </div>
              </div>
              {location.state.type == commissionTypeOptions["Vehicle sale commission"] ? <div className="col-md-4">
                <div className="formInput">
                  <label htmlFor="maxAmount" className="form-label mb-3"><b>Max amount</b></label>
                  <div className="input-group">
                    <input
                      type="number"
                      id="maxAmount"
                      name="maxAmount"
                      placeholder="Max amount"
                      className="form-control"
                      value={formik.values.maxAmount}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <span className="input-group-text" id="off-percentage">{"€"}</span>
                  </div>
                  {formik.touched.maxAmount && formik.errors.maxAmount ? <div className="error">{formik.errors.maxAmount}</div> : null}
                </div>
              </div> : null}

            </div>
          </form>
        </div>
      </CreateCommissionWrapper>
    </>
  );
};

export default CreateCommission;
