import React, { useEffect, useState } from "react";
import MainLayout from "../../../assets/Layout/MainLayout";
import { DetailMemberWrapper } from "./Styles/detailMember";
import userImg from "../../../assets/Images/icons/profile-pic.png";
import back from "../../../assets/Images/icons/svgIcon/back-arrow.svg";
import Verified from "./Components/Verified";
import Validation from "./Components/Validation";
import Behavior from "./Components/Behavior";
import VerifiedHeader from "./Components/Headers/VerifiedHeader";
import ValidationHeader from "./Components/Headers/ValidationHeader";
import BehaviorHeader from "./Components/Headers/BehaviorHeader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import { FaLocation } from "../../../assets/Images/icon";
import SimiliarUsers from "./Components/SimiliarUsers";
import getUniqueObjects from "../../../core/utils/getUniqueObjects.js/getUniqueObjects";
import edit from "../../../assets/Images/image/edit.png";
import EditUserDetailPopup from "./Components/EditUserDetailPopup";
import { editNameAndAddressSteps, verificationStatus } from "../../../config/constant";

const DetailMember = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  
  const [VerifyId, _VerifyId] = useState("");
  
  const [permission, _permission] = useState(
    JSON.parse(localStorage.getItem("permissions"))
  );

  let usersAccess = permission.find((o) => o.slug === "users");
  let verifyUser = permission.find((o) => o.slug === "verify_user");
  let validateUser = permission.find((o) => o.slug === "verify_nomad_user");

  const [tab, setTab] = useState(query.get('censorUser')==="true"?'Behavior':
                                (query.get('tab')==="backOffice"||query.get('pendingTab')==="validation")&&validateUser?"Validation":
                                (query.get('tab')==="frontOffice"||query.get('pendingTab')==="verification")&&verifyUser?"Verified"
                                :"Verified");

  const [data, _data] = useState([]);
  const [open, setOpen] = useState(false);
  const [name, _name] = useState("");
  const [nickName, _nickName] = useState("");
  const [phone, _phone] = useState("");
  const [status, _status] = useState("");
  const [profilePic, _profilePic] = useState("");
  const [country, _country] = useState("");
  const [countryName, _countryName] = useState("");
  const [isDeleted , _isDeleted] = useState(true)
  const [headerData, _headerData] = useState(""); 
  const [editUserDetail, _editUserDetail] = useState();
  const [similiarUsers, _similiarUsers] = useState({
    data: [],
    loading: false,
    error: "",
    totalPage: 1,
    currentPage: 1,
  });

  const { userId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    if(userId !== undefined && userId !== null)
      getUserDetails();
  }, []);

  useEffect(()=>{
    // fetch similiar users only when user is not deleted 
    if(data && !isDeleted && userId !== undefined && userId !== null )
      similiarUsersList();
  },[similiarUsers.currentPage , data])

  useEffect(() => {
    if (verifyUser) {
      let Id = new URLSearchParams(search).get("verify_id");
      _VerifyId(Id);
      if (Id === "VerifyId") {
        setTab(query.get('pendingTab')==="validation"?"Validation":"Verified")
      }
    }
  }, []);


  //fetch similiar named users
  const similiarUsersList = () => {
    _similiarUsers(prev=> ({...prev , loading:true  }));  
    API.get(`${APP_URLS.LIST_USERS}/${userId}/duplicate?per_page=${25}&page=${similiarUsers.currentPage}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) { 
          if(resp.data) {
           let data = resp.data.data;
           data = getUniqueObjects([...similiarUsers.data, ...data]);
          _similiarUsers(prev=> ({...prev , data:data || [], loading:false , error:'' ,   totalPage:resp.data.last_page })); 
          }
        } else {
          _similiarUsers(prev=> ({...prev , data:[], loading:false , error:resp.message  })); 
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map(
              (error, index) => (error_message = resp.data.errors[error][0])
              );
            }
          } else if (resp.data.data?.error !== undefined) {
            error_message = resp.data.data.error;
          } else if (resp.data?.error !== undefined) {
            error_message = resp.data.error;
          } else {
            error_message = resp.data.message;
          }
        _similiarUsers(prev=> ({...prev , data:[], loading:false , error:error_message  }));  
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getUserDetails = () => {
    API.get(APP_URLS.LIST_USERS + `/${userId}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _data(resp.data.user);
          _isDeleted(()=> resp.data.user.deleted_at ? true : false)
          if (resp.data.user.first_name || resp.data.user.last_name) {
            _name(
              `${
                resp.data.user.first_name !== null
                  ? resp.data.user.first_name.charAt(0).toUpperCase() +
                    resp.data.user.first_name.slice(1)
                  : ""
              } ${
                resp.data.user.last_name !== null
                  ? resp.data.user.last_name.charAt(0).toUpperCase() +
                    resp.data.user.last_name.slice(1)
                  : ""
              }`
            );
          } else {
            _name('-');
          }
          _nickName(resp.data.user.pseudo !== null
            ? resp.data.user.pseudo.charAt(0).toUpperCase() +
                resp.data.user.pseudo.slice(1)
            : "-")
            if(resp.data.user.phone){
              _phone(`${resp.data.user.limited_address.prefix_call} ${resp.data.user.phone}`)
            }
            else{
              _phone('-')
            }
          _profilePic(
            resp.data.user.profile_picture_url !== null &&
              resp.data.user.profile_picture_url !== ""
              ? resp.data.user.profile_picture_url
              : ""
          );
          _country(resp.data.user.limited_address)
          _countryName(resp.data.user.address_principal.country.country_name)
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map(
              (error, index) => (error_message = resp.data.errors[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const setCurrentTab = (val) => {
    _headerData("");
    val === 1
      ? setTab("Verified")
      : val === 2
      ? setTab("Validation")
      : val === 3
      ? setTab("Behavior")
      : setTab("Verified");
  };

  const addressPrincipal = (addressObj) => {
    let addressString = '';
      addressString += addressObj?.route ? addressObj.route + ", " : '';
      addressString += addressObj?.street_number ? addressObj.street_number + ", " : '';
      addressString += addressObj?.sub_locality ? addressObj.sub_locality + ", " : '';
      addressString += addressObj?.locality ? addressObj.locality + ", " : '';
      addressString += addressObj?.country?.fullname?.en ? addressObj.country.fullname.en + ", " : '';
      addressString += addressObj?.postal_code ? addressObj.postal_code + ", " : ''; 
      addressString = addressString.substring(0, addressString.length - 2 ) + "."
      return addressString;
  }

  const address = (addressObj) => {
    // Get the formatted address string
    const addressString = addressPrincipal(addressObj);
  
    return (
      <span className="text-sm d-flex gap-1" title="Address">
        {/* Icon for location */}
        <FaLocation />
  
        {/* Address Text */}
        <span>
          {addressString}
  
          {/* Edit icon, shown only if the profile is not verified */}
          {data?.profile_is_verified === false && data.profile_verification_status == verificationStatus.Applied && (
            <span className="pointer disable">
              <img
                src={edit}
                alt="edit"
                onClick={() => handleEditUserDetailPopup(editNameAndAddressSteps.userAddress)}
                style={{ marginLeft: '4px', cursor: 'pointer' }}
              />
            </span>
          )}
        </span>
      </span>
    );
  };
 
  const handleNavigate = () => {
     if(VerifyId === "VerifyId"){
        let urlParamsObject = {};
        if(query.get("pendingTab") !== null) urlParamsObject['pendingTab'] = query.get("pendingTab")
        if(query.get("sort") !== null) urlParamsObject['sort'] = query.get("sort")
        if(query.get("per_page") !== null) urlParamsObject['per_page'] = query.get("per_page")
        if(query.get("page") !== null) urlParamsObject['page'] = query.get("page") 
        let urlSearch = new URLSearchParams(urlParamsObject).toString() 
        navigate('/pending-verifications?' + urlSearch)
     }
     else {
        let urlParamsObject = {};
        if(query.get("tab") !== null) urlParamsObject['tab'] = query.get("tab")
        if(query.get("status") !== null) urlParamsObject['status'] = query.get("status")
        if(query.get("profile_verification_status") !== null) urlParamsObject['profile_verification_status'] = query.get("profile_verification_status")
        if(query.get("role_id") !== null) urlParamsObject['role_id'] = query.get("role_id") 
        if(query.get("keyword") !== null) urlParamsObject['keyword'] = query.get("keyword") 
        if(query.get("sort") !== null) urlParamsObject['sort'] = query.get("sort") 
        if(query.get("profile") !== null) urlParamsObject['profile'] = query.get("profile") 
        if(query.get("accountDate") !== null) urlParamsObject['accountDate'] = query.get("accountDate") 
        if(query.get("accountDateFrom") !== null) urlParamsObject['accountDateFrom'] = query.get("accountDateFrom") 
        if(query.get("accountDateTo") !== null) urlParamsObject['accountDateTo'] = query.get("accountDateTo") 
        if(query.get("selectedCountry") !== null) urlParamsObject['selectedCountry'] = query.get("selectedCountry") 
        if(query.get("trashed") !== null) urlParamsObject['trashed'] = query.get("trashed") 
        if(query.get("per_page") !== null) urlParamsObject['per_page'] = query.get("per_page") 
        if(query.get("page") !== null) urlParamsObject['page'] = query.get("page") 
        let urlSearch = new URLSearchParams(urlParamsObject).toString()
        navigate('/users?' + urlSearch)
     }
  }    

  //Opens the edit user interface popup for edit name or address accordingly
  const handleEditUserDetailPopup = (key) => {
    setOpen(true);
    _editUserDetail(key)
  }

  // const handleCreateProNavigate = () => {
  //   navigate('/create-pro-account', { state: { user:{ userId, userName: name || 'No name', userCountryId: country.country_id, userCountryPrefix:'', userPhonePrefix: country.prefix_call, userProfilePic: profilePic }}});
  // }
  
  return (
    <DetailMemberWrapper>
      <div className="auctionMainContainer">
        <div className="row mt-4 mb-5">
          <div className="col-md-4 d-flex align-items-center">
            <img
              src={back}
              className="ms-4 backbtn"
              onClick={handleNavigate}
            />
          </div>
          <div className="col-md-4">
            <div className="headerContent">
              <h1> DETAIL MEMBER</h1>
              <span>Seller Subscription</span>
            </div>
          </div>
          {/* {data.profile_is_complete ?  */}
          {/* <div className='d-flex align-items-center justify-content-end col-md-4 mt-2 mt-lg-0'>
            <button className='btn wrap-text' onClick={handleCreateProNavigate}>Create a Pro Account</button>
          </div> */}
            {/* : null} */}
        </div>

        <div className='row row-custom'>
          <div className={`col ${isDeleted ? ' col-lg-12 colo-md-12 ' : ' col-lg-10 col-md-9 '} col-sm-12`}>
          <div className="container">
          <div className="row">
            <div className="col col-lg-3 ">
              <div className="details">
                <div className="profile-flag">
                  <div className="profileImage">
                    <img src={profilePic !== "" ? profilePic : userImg} />
                  </div>
                  {country ? <img className="countryflag" src={country.flag_thumbnail} title={country.country}/> : ""}
                </div>
                <div className="detailsContent">
                  <p title="Username" className="text-m d-flex align-items-center gap-1">
                    {name}{" "}
                    <span
                      className={
                        (data?.profile_is_verified === true
                          ? "status-active status"
                          :data?.profile_is_verified === false? "status-end status"
                          :"") + " ms-2"
                      }
                      title={
                        data?.profile_is_verified === true
                          ? "Verified"
                          : "Unverified"
                      }
                    >
                      {data?.profile_is_verified?<i className="fa fa-check icon" aria-hidden="true"></i>:
                      data?.profile_is_verified===false?<i className="fa fa-x icon" aria-hidden="true"></i>
                      :''}
                    </span>
                    {data?.profile_is_verified === false && data.profile_verification_status == verificationStatus.Applied && <span className="pointer disable" onClick={()=>handleEditUserDetailPopup(editNameAndAddressSteps.userName)}>
                        <img src={edit} alt="f" />
                    </span>}
                  </p>
                  <span className="text-sm" title="Nickname">{nickName}</span>
                  <span className="text-sm" title="Phone">{phone}</span>
                  <div className="d-flex">
                    <span>{address(data?.address_principal)}</span>
                  </div>
                </div>
              </div>
            </div>
            {VerifyId !== "VerifyId" ? (
              <>
                {tab === "Verified" ? (
                  <VerifiedHeader headerData={headerData} data={data} />
                ) : tab === "Validation" ? (
                  <ValidationHeader headerData={headerData} />
                ) : tab === "Behavior" ? (
                  <BehaviorHeader isDeleted={isDeleted} userId={userId} usersAccess={usersAccess} />
                ) : (
                  <VerifiedHeader headerData={headerData} data={data} />
                )}
              </>
            ) : (
              <>
                {query.get("pendingTab") === "verification" &&
                tab === "Verified" ? (
                  <VerifiedHeader headerData={headerData} data={data}/>
                ) : query.get("pendingTab") === "validation" &&
                  tab === "Validation" ? (
                  <ValidationHeader headerData={headerData} />
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        </div>

        <div className="container tabsContainer">
          <div className="row">
            <div className="col-lg-3 "></div>
            <div className="col-lg-6">
              <div
                className={
                  VerifyId === "VerifyId"
                    ? "tabsHeader justify-content-center"
                    : "tabsHeader"
                }
              >
                {VerifyId === "VerifyId" ? (
                  query.get("pendingTab") === "verification" &&
                  verifyUser &&
                  verifyUser?.permissions.find((o) => o.slug === "read") ? (
                    <span
                      className={
                        tab === "Verified" ? "tabActive" : "tabInactive"
                      }
                      onClick={() => setCurrentTab(1)}
                    >
                      Verified ID
                    </span>
                  ) : query.get("pendingTab") === "validation" &&
                    validateUser &&
                    validateUser?.permissions.find((o) => o.slug === "read") ? (
                    <span
                      className={
                        tab === "Validation" ? "tabActive" : "tabInactive"
                      }
                      onClick={() => setCurrentTab(2)}
                    >
                      Validation
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  <>
                    {verifyUser &&
                    verifyUser?.permissions.find((o) => o.slug === "read") ? (
                      <span
                        className={
                          tab === "Verified" ? "tabActive" : "tabInactive"
                        }
                        onClick={() => setCurrentTab(1)}
                      >
                        Verified ID
                      </span>
                    ) : (
                      ""
                    )}
                    {validateUser &&
                    validateUser?.permissions.find((o) => o.slug === "read") ? (
                      <span
                        className={
                          tab === "Validation" ? "tabActive" : "tabInactive"
                        }
                        onClick={() => setCurrentTab(2)}
                      >
                        Validation
                      </span>
                    ) : (
                      ""
                    )}
                    {usersAccess &&
                    usersAccess.permissions.find((o) => o.slug === "read") ? (
                      <span
                        className={
                          tab === "Behavior" ? "tabActive" : "tabInactive"
                        }
                        onClick={() => setCurrentTab(3)}
                      >
                        Behavior
                      </span>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {VerifyId !== "VerifyId" ? (
          <>
            {tab === "Verified" ? (
              <Verified
                setHeaderData={_headerData}
                data={data}
                name={name}
                userId={userId}
                verifyUser={verifyUser}
                getUser={getUserDetails}
              />
            ) : tab === "Validation" ? (
              <Validation
                setHeaderData={_headerData}
                userId={userId}
                validateUser={validateUser}
                country={countryName}
              />
            ) : tab === "Behavior" ? (
              <Behavior userId={userId} />
            ) : (
              <Verified
                setHeaderData={_headerData}
                data={data}
                name={name}
                userId={userId}
                verifyUser={verifyUser}
                getUser={getUserDetails}
              />
            )}
          </>
        ) : (
          <>
            {query.get("pendingTab") === "verification" &&
            tab === "Verified" ? (
              <Verified
                setHeaderData={_headerData}
                data={data}
                name={name}
                userId={userId}
                verifyUser={verifyUser}
                getUser={getUserDetails}
              />
            ) : query.get("pendingTab") === "validation" &&
              tab === "Validation" ? (
              <Validation
                setHeaderData={_headerData}
                userId={userId}
                validateUser={validateUser}
                country={countryName}
              />
            ) : (
              ""
            )}
          </>
        )}
          </div> 
          {
            !isDeleted?  
            <SimiliarUsers
            hasMore ={similiarUsers?.totalPage > similiarUsers?.currentPage}
            similiarUsers={similiarUsers} 
            _similiarUsers={_similiarUsers} 
            /> : null  
          }
        </div> 
      </div>
      {open && <EditUserDetailPopup 
          open={open} 
          setOpen={setOpen}
          userData={data}
          userAddress={addressPrincipal(data?.address_principal)}
          country={country}
          getUserDetails={getUserDetails}
          hasCompany={data?.has_company}
          isVerified={data?.profile_is_verified}
          editUserDetails={editUserDetail}
          profileVerificationStatus = {data.profile_verification_status}
        />}
    </DetailMemberWrapper>
  );
};

export default DetailMember;
