import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import styled from "styled-components";
const Div = styled.div`
  .btn:hover {
    color: #fff !important;
  }
`;

const BehaviorHeader = (props) => {

  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const permission = props.usersAccess.permissions.find((o) => o.slug === "update");
  
  const handleNavigate = () => { 
       let urlParamsObject = {};
       if(query.get("tab") !== null)  urlParamsObject['tab'] = query.get("tab")
       if(query.get("status") !== null)  urlParamsObject['status'] = query.get("status")
       if(query.get("profile_verification_status") !== null)  urlParamsObject['profile_verification_status'] = query.get("profile_verification_status")
       if(query.get("role_id") !== null)  urlParamsObject['role_id'] = query.get("role_id") 
       if(query.get("keyword") !== null)  urlParamsObject['keyword'] = query.get("keyword") 
       if(query.get("sort") !== null)  urlParamsObject['sort'] = query.get("sort") 
       if(query.get("profile") !== null)  urlParamsObject['profile'] = query.get("profile") 
       if(query.get("accountDate") !== null)  urlParamsObject['accountDate'] = query.get("accountDate") 
       if(query.get("accountDateFrom") !== null)  urlParamsObject['accountDateFrom'] = query.get("accountDateFrom") 
       if(query.get("accountDateTo") !== null)  urlParamsObject['accountDateTo'] = query.get("accountDateTo") 
       if(query.get("selectedCountry") !== null)  urlParamsObject['selectedCountry'] = query.get("selectedCountry") 
       if(query.get("trashed") !== null)  urlParamsObject['trashed'] = query.get("trashed") 
       if(query.get("per_page") !== null)  urlParamsObject['per_page'] = query.get("per_page") 
       if(query.get("page") !== null)  urlParamsObject['page'] = query.get("page") 

       let urlSearch = new URLSearchParams(urlParamsObject).toString()
       return `/user/create-censored/${props.userId}?` + urlSearch; 
 }    
 
  return (
    <>
      <div className="col-lg-6"></div>
      <div className="col-lg-3 ">
        <Div>
          {permission ? (
            <div className="details">
              <NavLink to={handleNavigate()} className="btn">
                + Add Censorship
              </NavLink>
            </div>
          ) : (
            ""
          )}
        </Div>
      </div>
    </>
  );
};

export default BehaviorHeader