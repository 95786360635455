import React, { useState } from "react";
import ReactPaginate from 'react-paginate';
import { PaginationWrapper } from "./style/PaginationWrapper";

const Pagination = (props) => {
  const [jumpPage, setJumpPage] = useState("");

  const handlePageClick = (event) => {
    props.onPageClick(event.selected);
    window.scrollTo(0, 0);
  };

  //handles jumps to pages
  const handleJumpChange = (event) => {
    setJumpPage(event.target.value);
  };

  //submit function for jumps to page
  const handleJumpSubmit = (event) => {
    event.preventDefault();
    const jumpToPage = Math.min(Math.max(parseInt(jumpPage) - 1, 0), props.totalPages - 1);
    if (!isNaN(jumpToPage)) {
      props.onPageClick(jumpToPage);
      window.scrollTo(0, 0);
    }
  };

  return (
    <PaginationWrapper>
      <nav className='Page navigation'>
        <ReactPaginate
          previousLabel="PREV"
          nextLabel="NEXT"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-link prev"
          previousLinkClassName="page-prev-link"
          nextClassName="page-link next"
          nextLinkClassName="page-next-link"
          breakLabel="..."
          breakClassName="page-item break-page"
          breakLinkClassName="page-link"
          pageCount={props.totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={2}
          containerClassName="pagination-outer"
          activeClassName="active-page"
          forcePage={props.page ? props.page - 1 : 0}  // Converts to zero-based index
          onPageChange={handlePageClick}
        />
        {props.totalPages > 5 && (
          <form onSubmit={handleJumpSubmit} className="jump-to-page-form">
            <input
              type="number"
              min={1}
              max={props.totalPages}
              value={jumpPage}
              onChange={handleJumpChange}
              className="jump-to-page-input"
              style={{ width: '70px', border: '1px solid #607afc' }}
            />
            <button type="submit" className="jump-to-page-button go-to">Go to</button>
          </form>
        )}
      </nav>
    </PaginationWrapper>
  );
};

export default Pagination;