import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  max-width: 80%;
  min-width: 50%;
  background-color: white;
  padding: 16px;
  h3 {
    margin-top: 10px;
    text-align: center;
  }
  .three-dot {
    width: 100%;
    position: static;
    height: 300px;
  }
  .tableContainer {
    width: 100%;
  }
  .modalTable {
    width: 100%;
    height: auto;
    overflow: auto;
    min-width: 800px;
    margin-top: 10px;
  }
  .auctionTable {
    width: 100%;
  }
  tbody {
    display: block;
    max-height: 50vh;
    overflow-y: auto;
    margin-top: 20px;
  }
  .tableHead {
    display: flex;
    justify-content: space-between;
    border: 1px solid rgba(0, 0, 0, 0.6);
    border-radius: 10px;
  }
  .table-row {
    display: flex;
    justify-content: space-between;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 0;
    border-radius: 10px;
    border-radius: 0;
  }
  .table-row:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .table-row:first-child {
    margin-top: 0px;
  }
  .table-row th,
  .table-row td {
    min-width: 10%;
    padding: 15px 5px;
    display: block;
    justify-content: center;
    align-self: center;
    text-align: center;
    word-break: break-word;
  }
  .w-150 {
    width: 150px;
  }
  .userType {
    display: block;
  }
  .info-wrap {
    display: flex;
    flex-wrap: wrap;
  }
  .disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  @media (max-width: 768px) {
    tbody {
      max-height: 40vh;
    }
  }
`;
