import styled from "styled-components";

export const LoginWrapper = styled.div`
a{
  text-decoration:none;
}
.header{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items:center;
  background: rgb(255, 255, 255);
  box-shadow: rgb(130 131 131 / 24%) 1px 0.196px 11px;
  z-index: 100;
  width: 100%;
  top: 0px;
  left: 0px;
}
.headContainer{
  width: 100%;
  max-width: 1300px;
  padding: 20px 15px;
}
.headContainer img{
  width: 165px;
  height:auto;
  cursor:pointer;
}
.login-img{
  max-width: 350px;
}
.login-img img{
  width:100%;
  height:auto;
}
.formContainer {
  max-width: 1300px;
  min-width: 276px;
  padding: 0px 15px;
  margin: 0px auto;
}
.form-title{
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 35px;
  letter-spacing: 1px;
  font-size: 18px;
}
input{
  min-height: 41px;
  width:100%;
  border-radius:5px;
  outline:none;
  border: 1px solid rgb(224, 224, 224);
  padding: 0px 15px;
}
input:-webkit-autofill{
  box-shadow: white 0px 0px 0px 30px inset !important;
}
.inputField{
  margin-bottom: 1.2rem;
}
.submitBtn{
  width:100%;
  border-radius 45px;
  margin-top: 60px;
  letter-spacing: 0.1em;
  padding: 12px 15px;
  background: rgb(255, 255, 255);
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
  height: auto;
}
.submitBtn:hover {
  background-color: #637df4;
  color: #ffffff;
  transition: all .35s ease-in-out;
}
.forgot {
  text-align: right;
  color: rgb(96, 122, 252);
  font-size: 15px;
  margin-top: 18px;
  margin-bottom: 40px;
}
.required {
  color: red;
  font-size: 11px;
  line-height: 14px;
  display: inline-block;
  text-align: justify;
}
footer{
  background: rgb(247, 247, 247);
  padding: 15px;
  box-shadow: rgb(0 0 0 / 24%) 0px -2px 10px;
  margin-top: 20px;
  z-index: 2;
  position: fixed;
  bottom: 0px;
  width: 100%;
}

@media(max-width: 767px){
  .formContainer {
    padding: 20px 15px 75px 20px;
    min-height: calc(100vh - 140px);
  }
  input{
    border-radius:15px;
  }
  .login-img {
    max-width: 228px;
    width: 100%;
    margin: 0 auto 35px;
  }
  .formLogin{
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
  }
}

@media(min-width: 840px) and (max-width: 900px){
    .formContainer {
      top: calc(80% - 80px) !important;
      padding-bottom: 100px !important;
    }
}
@media(min-width: 768px){
  .formContainer {
    max-width: 1300px;
    min-width: 276px;
    padding: 0px 15px;
    margin: 0px auto;
    top: calc(50% - 0px);
    left: 50%;
    transform: translate(-50%, -40%);
    position: absolute;
    width: 100%;
    padding-bottom: 75px;
  }
}

`;
