import styled from "styled-components";

export const InvoicesWrapper = styled.div`
.auctionTable {
  min-width: 1300px;
}
.nomadTable{
  min-width: 1100px !important;
}
.errorIcon{
    height: 18px;
    width: 18px;
    cursor:pointer;
    right: 7px;
    top:2px
}
  .headerContainer div,
  .headerContainer h1 {
    margin-bottom: 10px;
  }
  .searchContainer {
    display: flex;
    padding: 0 15px 0 0;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  .search {
    height: 35px;
    border: none;
    border-radius: 10px;
  }
  .table-row:nth-last-child(2) {
    border: 1px solid rgba(0, 0, 0, .1);
  }
  .summary-row{
    margin-top:50px;
    padding:10px 0;
  }
  .border-none{
    border:none
  }
  .tabBtn button {
    background: transparent;
    padding: 15px 35px;
    cursor: pointer;
    border: none;
  }
  .clearFilters{
    position:absolute;
    right:10px;
    cursor:pointer;
    top: -5px;
  }
  .clearFilters img{
    width:20px;
  }
  .clearFilters span{
    font-size:10px;
    color:#979797;
  }
  .filtersInput .tabBtn button{
    padding: 8px 0px;
    width:33.33%;
    font-size: 13px;
  }
  .light-text{
    font-size:12px;
    color:#979797
  }
  .header{
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 20px;
  }
  .open{
    transform: rotate(0deg);
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  .close{
    transform: rotate(-180deg);
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    left:0px !important;
  }
  .filterbtn{
    width:auto;
    position:absolute;
    right:10px;
    top:55px;
    cursor:pointer;
  }
  .form-label{
    font-size:14px;
  }
  .filtersInput select{
    font-size:13px;
  }
  .filtersInput input[type="text"]{
    font-size: 13px;
  }
  input[type="date"],.filtersInputGroup input[type="text"]{
    width:47%;
    font-size: 13px;
    border-radius: 5px !important;
  }
  .filtersInput .tabBtn {
    border:none;
  }
  .filtersInput .tabBtn button{
    border: 1px solid #E9E9E9;
  }
  .tabBtn button:hover {
    background: transparent;
    color: #5d78ff;
  }
  .activeTab {
    border: 1px solid #5d78ff !important;
    color: #5d78ff;
    border-radius: 5px;
  }
  .show{
    display:block;
    transition: all 0.80s ease-in-out;
  }
  .hide{
    display:none;
    transition: all 0.80s ease-in-out;
  }
  .fieldTitle{
      font-size: 12px;
      font-style: italic;
      font-weight: 200;
      color: #979797;
  }
  .time-start {
    min-width: 150px;
  }
  .active {
    background-color: red;
  }
  .avatar {
    vertical-align: middle;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .profile-flag {
    position: relative;
    display: inline-block;
  }
  .profileImage {
    height: 40px;
    width: 40px;
    background: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
  }
  .profileImage img {
    margin: 0 auto;
    display: flex;
    width: 38px;
    height: 38px;
    object-fit: cover;
  }
  .flagIcon {
    position: absolute;
    bottom: -8px;
    right: 12px;
  }
  .attachment{
    cursor:pointer;
  }
  .mw-120{
    min-width:120px;
  }
  .name-text {
    display: inline-block;
    width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden !important;
  }
  .maxw-90{
    max-width:90px;
  }
  .countryCode{
    width:90px;
    border: none;
    background: white;
    border-right: 1px solid hwb(0deg 0% 100% / 20%);
    border-radius: 10px 0px 0px 10px;
  }
  .countryCode:focus {
    outline: none;
  }
  .vatField{
    max-width:150px;
  }
  .codeFlag{
    width:25px;
    height:15px;
  }
  .searchResult li {
    cursor:pointer;
  }
  .previewTable{
    margin-top: 20px !important;
  }
  .previewTable th,.previewTable tr.table-row td {
    width:100%;
    padding: 15px 5px;
    display: block;
    justify-content: center;
    align-self: center;
    text-align: center;
    word-break: break-word;
  }
  .scrollbox{
    max-height:50vh;
    overflow-y:scroll
  }
  .errorText {
    color: red;
    font-size: 13px;
  }
  .w-45{
    width:45% !important;
  }
  .MuiAutocomplete-root {
    width: 100% !important;
  }
  .time-start svg {
    width: 10px;
    height: 15px;
    cursor: pointer;
  }
  .opacity-40 {
    opacity: 40%;
  }
  .date-range-container{
    border-radius:10px;
}
.date-range-container >div:first-child input{
    border-radius:10px 0 0px 10px;
}
.date-range-container >div:last-child input{
    border-radius:0 10px 10px 0;
}
.date-range-container input {
    background:white !important; 
    border:1px solid #ced4da;
    width:10rem;

}
.extract-report{
  font-weight: 600;
  cursor: pointer;
  text-transform: none;
  color: black; 
  border: 1px solid rgba(0,0,0,.2);
  height: 32px;
  &:hover{
      outline:none;
  }
}
  .actionBtn{
    padding: 5px 5px;
  }
  .actionBtns{
    min-width: 130px;
  }
  .actionBtns.btnActive {
  border-radius: 5px 5px 0 0;
}
.actionAuction {
  position: relative;
}
.actionAuction .DelAction {
  width: 100%;
  position: absolute;
}
.actionAuction .DelAction .actionBtn {
  width: 100%;
  height: auto;
  border-top: none;
  border-radius: 0 0 5px 5px;
}
  @media (max-width: 991px){
    .filtersInput .tabBtn {
        width: auto;
    }
  }
  @media (min-width:768px) and (max-width: 992px){
    .filterbtn {
      left: 20%;
    }
  }
  @media (min-width:992px) and (max-width: 1168px){
    .filtersInput .tabBtn button{
      font-size: 11px;
    }
  }
  @media (min-width: 993px){
    .filterbtn {
      left: 15%;
    }
  }
`;
