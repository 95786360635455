import styled from "styled-components";

export const LoginWrapper = styled.div`
a{
  text-decoration:none;
}
.header{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items:center;
  background: rgb(255, 255, 255);
  box-shadow: rgb(130 131 131 / 24%) 1px 0.196px 11px;
  z-index: 100;
  width: 100%;
  top: 0px;
  left: 0px;
}
.headContainer{
  width: 100%;
  max-width: 1300px;
  padding: 20px 15px;
}
.headContainer img{
  width: 165px;
  height:auto;
  cursor:pointer;
}
.formContainer {
  max-width: 1300px;
  min-width: 276px;
  padding: 0px 15px;
  margin: 0px auto;
}
.formContainer p{
  font-size:40px;
}
.formContainer span{
  display:block;
}
footer{
  background: rgb(247, 247, 247);
  padding: 15px;
  box-shadow: rgb(0 0 0 / 24%) 0px -2px 10px;
  margin-top: 20px;
  z-index: 2;
  position: fixed;
  bottom: 0px;
  width: 100%;
}
.please-login{
  color:#637df4;
}
.please-login:hover{
  cursor:pointer;
}
@media(max-width: 767px){
  .formContainer {
    padding: 20px 15px 75px 20px;
    min-height: calc(100vh - 140px);
  }
  input{
    border-radius:15px;
  }
  .login-img {
    max-width: 228px;
    width: 100%;
    margin: 0 auto 35px;
  }
  .formLogin{
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
  }
}

@media(min-width: 840px) and (max-width: 900px){
    .formContainer {
      top: calc(80% - 80px) !important;
      padding-bottom: 100px !important;
    }
}
@media(min-width: 768px){
  .formContainer {
    max-width: 1300px;
    min-width: 276px;
    padding: 0px 15px;
    margin: 0px auto;
    top: calc(50% - 0px);
    left: 50%;
    transform: translate(-50%, -40%);
    position: absolute;
    width: 100%;
    padding-bottom: 75px;
  }
}

`;
