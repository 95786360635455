import React, { useEffect } from "react";
import MainLayout from "../../../assets/Layout/MainLayout";
import { EditVehicleWrapper } from "../style/EditVehicle";
import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";

const EditContinent = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  
  const [fields,_fields] = useState(JSON.parse(localStorage.getItem("languages")))
  const [countries,_countries] = useState(JSON.parse(localStorage.getItem("countries")))

  const [name,_name] = useState({})
  const [code,_code] = useState('')

  const [loading,_loading] = useState(false)

  const { continentId } = useParams()

  const navigate = useNavigate()

  useEffect(() => {
    getContinent()
  }, [])
  

  const getContinent =() =>{
    API.get(APP_URLS.SHOW_CONTINENT + `/${continentId}`)
    .then(res => {
      const resp = res.data;
      if(resp.success===true){
        setValues(resp.data.item)
      }
      else{
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })
    .catch(function (error) {  
      const resp = error.response;
      let error_message = '';
      if (resp.data.data !== undefined && resp.data.data !== null) {
        {
          Object.keys(resp.data.data).map(
            (error, index) =>(error_message = resp.data.data[error][0])
          );
        }
      } else if (resp.data.data?.error !== undefined) {
          error_message =  resp.data.data.error;
      } else if(resp.data?.error!== undefined){
          error_message =  resp.data.error
      } else{
          error_message =  resp.data.message
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT
      })
    });
  }

  const setValues = (data) => {
    Object.keys(data.name).map((item)=>{
      _name((old)=> {return {...old,[item]:data.name[item]}})
    })
    _code(data.code)
  }

  const handleTitle = (val,code) =>{
    _name((old)=> {return {...old,[code]:val}})
  }


  const submitForm = () =>{
    let flag = false
    if((name["en"]===""||name["en"]===undefined||name["en"]===null) && (name["fr"]===""||name["fr"]===undefined||name["fr"]===null)){
      flag = false
      toast.error("Continent Name in English and French is mandatory", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else if(name["en"]===""||name["en"]===undefined||name["en"]===null){
      flag = false
      toast.error("Please provide Continent Name in English", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else if(name["fr"]===""||name["fr"]===undefined||name["fr"]===null){
      flag = false
      toast.error("Please provide Continent Name in French", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else{
      flag = true
    }
    if(flag===true){
      _loading(true)
      let data ={}
      data["name"] = name;
      API.post(APP_URLS.UPDATE_CONTINENT+ `/${continentId}?_method=patch`,data)
      .then(res => {
        _loading(false)
        const resp = res.data;
        if(resp.success===true){
          toast.success("Continent Updated Successfully.", {
            position: toast.POSITION.TOP_RIGHT
          })
          navigate("/translation/continents?"+
          (searchParams.get("per_page") !== null 
            ? "per_page=" + searchParams.get("per_page") + "&"
            : "") +
          (searchParams.get("page") !== null
            ? "page=" + searchParams.get("page")
            : "")
        )
        }
        else{
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT
          })
        }
      })
      .catch(function (error) {  
        _loading(false)
        const resp = error.response;
        let error_message = '';
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map(
              (error, index) =>(error_message = resp.data.data[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
            error_message =  resp.data.data.error;
        } else if(resp.data?.error!== undefined){
            error_message =  resp.data.error
        } else{
            error_message =  resp.data.message
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT
        })
      });
    }
  }
 
  return (
    <>
      <EditVehicleWrapper>
        <div className='mt-2' >
          <div className="backContainer ms-4" onClick={()=>navigate("/translation/continents?"+                  
              (searchParams.get("type") !== null
                ? "type=" + searchParams.get("type") + "&"
                : "") +
              (searchParams.get("per_page") !== null 
                ? "per_page=" + searchParams.get("per_page") + "&"
                : "") +
              (searchParams.get("page") !== null
                ? "page=" + searchParams.get("page")
                : "")
            )}>
            <span className='backButton'>x</span>
            <span className='px-3 backButton'>|</span>
            <span className='backButton'>Cancel</span>
          </div>
        </div>
        <div className="headerContainer">
          <h1>EDIT CONTINENT</h1>
        </div>
        <div className="d-flex justify-content-between save-btn mt-3">
          <p className="fieldLabel">Title</p>
          <button className={(loading?"disabled":"")+" add-button"} type="submit" onClick={submitForm} disabled={loading?true:false}>Save</button>
        </div>
        <div className="row input-fileds">
          {fields.map((item,index)=>{
            let itemCode = item.code
            let flag = countries.find(o => o.id === item.country_id)?.flag_thumbnail
            return <div className="col-md-4" key={index}>
              <div className="col-md-12 mt-3">
                <label htmlFor="title" className="form-label">
                  <span className="countryIcon px-2">
                    <img src={flag} alt="" className="countryflag" title={item.name}/>
                  </span>
                  <span className="langName">{item.code}</span>
                </label>
                <input
                  type="text"
                  id={item.code}
                  name={item.code}
                  value={name[itemCode]}
                  className="form-control"
                  onChange={(e,code = itemCode)=>{
                    handleTitle(e.target.value,code)
                  }}
                />
              </div>
          </div>
          })}
        </div>
        <div className="row mt-5 input-fileds">
          <div className="col-md-4">
            <label htmlFor="countryCode" className="form-label product-type fieldLabel">
              Country Code
              </label>
              <input type="text" placeholder="Country Code" className="form-control mt-2 disabled" value={code} disabled onChange={(e)=>_code(e.target.value)}/>
          </div>
        </div>

      </EditVehicleWrapper>
    </>
  );
};

export default EditContinent;
