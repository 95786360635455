import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import useWindowDimensions from '../../../assets/Screen Size';
import faArrow from "../../../assets/Images/icons/faChevronDown.svg"
import { SideNavWrapper } from './style';


const Sidebar = ({route}) => {

  const { height, width } = useWindowDimensions(); 
  const [show,_show] = useState(false)
  const [active , _active] = useState('');
  const location = useLocation();

  useEffect(()=>{
    const pathname = location.pathname;
    _active(pathname);
  },[location])

  return (
    <SideNavWrapper>
      <ul className="slide-nav">
        {width>991?<> 
            <NavLink to={`/slides/home-slider`}><li className={"active-view" + (active.includes('/partner-slider') ? '' :' active') }> Home Slider  </li></NavLink> 
            <NavLink to={`/slides/partner-slider`}><li className={"active-view" + (active.includes('/partner-slider') ? ' active' :'') }> Partner Slider  </li></NavLink> 
        </>
        :
        <>
          <li className="active-view d-flex justify-content-between" onClick={()=>_show(!show)}>
            <span>Menu</span>
            <img src={faArrow} className={show?"closeMenu":"openMenu"}/>
          </li>
          <div className={show?"show":"hide"}>
            <NavLink to={`/slides/home-slider`}><li className={"active-view" + (active.includes('/partner-slider') ? '' :' active') }> Home Slider  </li></NavLink> 
            <NavLink to={`/slides/partner-slider`}><li className={"active-view" + (active.includes('/partner-slider') ? ' active' :'') }> Partner Slider  </li></NavLink> 
          </div>
        </>}
      </ul>
    </SideNavWrapper>
  )
}

export default Sidebar