import React, { useEffect, useState } from "react";
import MainLayout from "../../../../assets/Layout/MainLayout";
import API from "../../../../api/axios";
import { APP_URLS } from "../../../../api/url";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CraeteVehicleWrapper } from "../../style/createVehicle";
import SearchableObjDropdown from "../../../../assets/SharedComponents/SearchableDropdown/SearchableObjDropdown";
import BrandFilter from "../../../../assets/SharedComponents/Filters/BrandFilter";



const CreateModals = () => {

  const [products, _products] = useState(JSON.parse(localStorage.getItem("productType")));
  const [productValue, _productValue] = useState("");
  const [filters, _filters] = useState(false); 

  const [loading,_loading] = useState(false)

  const [name, _name] = useState('');
  const [brandValue, _brandValue] = useState('');

  const [showData, _showData] = useState(false)

  const navigate = useNavigate() 
  const handleSubmit = () => {
    let data = {}
    data["model_name"] = name
    data["product_category_id"] = 1
    data["brand_id"] = brandValue
    let flag = false
    if ((productValue === null || productValue === "")) {
      toast.error("Please provide product type", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else if ((name === null || name === "") && (brandValue === null || brandValue === "")) {
      toast.error("Brand and Model name are mandatory", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else if ((name === null || name === "")) {
      toast.error("Please provide model name", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else if ((brandValue === null || brandValue === "")) {
      toast.error("Please provide brand name", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else {
      flag = true
    }
    if (flag === true) {
      if(!loading){
        _loading(true)
        API.post(APP_URLS.ADD_MODELS, data)
          .then(res => {
            _loading(false)
            const resp = res.data;
            if (resp.success === true) {
              toast.success("Model has been added successfully.", {
                position: toast.POSITION.TOP_RIGHT
              })
              resetForm()
              navigate("/vehicles-management/vehicle-models");
            }
            else {
              toast.error(resp.message, {
                position: toast.POSITION.TOP_RIGHT
              })
            }
          })
          .catch(function (error) {
            _loading(false)
            const resp = error.response;
            let error_message = '';
            if (resp.data.data !== undefined && resp.data.data !== null) {
              {
                Object.keys(resp.data.data).map(
                  (error, index) => (error_message = resp.data.data[error][0])
                );
              }
            } else if (resp.data.data?.error !== undefined) {
              error_message = resp.data.data.error;
            } else if (resp.data?.error !== undefined) {
              error_message = resp.data.error
            } else {
              error_message = resp.data.message
            }
            toast.error(error_message, {
              position: toast.POSITION.TOP_RIGHT
            })
          });
      }
    }
  }
 

  const resetForm = () => {
    _name('')
    _brandValue('')
  }

  const setBrandValue = (val) => {
    _brandValue(val.split("_")[0])
  }



  return (
    <>
      <CraeteVehicleWrapper>
        <div className='mt-2' >
          <div className="backContainer ms-4" onClick={() => navigate("/vehicles-management/vehicle-models")}>
            <span className='backButton'>x</span>
            <span className='px-3 backButton'>|</span>
            <span className='backButton'>Cancel</span>
          </div>
        </div>
        <div className="headerContainer">
          <h1>CREATE MODELS</h1>
        </div>
        <div className="row">
          <div className="d-flex justify-content-end save-btn mt-3">
          <button className={(loading?"disabled":"")+" add-button"} onClick={handleSubmit} disabled={loading}>Save</button>
          </div>
          <div className="row input-fileds">
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-12 mt-3">
                  <label htmlFor="name" className="form-label fieldLabel">
                    Product Type
                  </label>
                  <select
                    name="productType"
                    id="productType"
                    className="form-control mt-2"
                    onChange={(e) => {
                      _productValue(e.target.value);
                      _brandValue('');
                    }}
                  >
                    <option hidden>Select Product Type</option>
                    {products.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {item.item_name.en}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-12 mt-3">
                  <label htmlFor="productType" className="form-label fieldLabel product-type d-flex justify-content-between">
                    <span>Brand Name</span>
                  </label> 
                  <BrandFilter _filters={_filters} filters={filters} brand={brandValue} setBrand={(val)=>setBrandValue(val)} productValue={productValue} className={(productValue === ''?"disabled":"")+" form-control mt-3"}  />  
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-12 mt-3">
                  <label htmlFor="name" className="form-label fieldLabel">
                    Name
                  </label>
                  <input type="text" id="name" name="name"
                    className="form-control mt-2"
                    placeholder="Product Name" onChange={(e) => _name(e.target.value)} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </CraeteVehicleWrapper>
    </>
  );
};

export default CreateModals;
