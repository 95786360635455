import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { toast } from "react-toastify";
import API from "../../api/axios";
import { APP_URLS } from "../../api/url";
import { FAQWrapper } from "./style/faqWrapper";


const CreateFaqs = () => {

  const [languages, _languages] = useState(JSON.parse(localStorage.getItem("languages")));
  const [countries, _countries] = useState(JSON.parse(localStorage.getItem("countries")));

  const typeRef = useRef(null)
  
  const [question,_question] = useState({ en : '', fr : ''})
  const [answer,_answer] = useState({ en : '', fr : ''})
  const [type,_type] = useState('')

  const [loading,setLoading] = useState(false)

  const navigate = useNavigate();

  const handleChange = (val, code , state) => {
    state(prevState => {return {...prevState, [code]: val}})
  };

  const submitForm = () => {
    let enQuestion = ''
    let enAnswer = ''
    let frQuestion = ''
    let frAnswer = ''
    //Code for checking blank HTML
    if(question['en']!==''&&question['en']!==undefined&&question['en']!==null){
      let blocks = convertToRaw(question['en'].getCurrentContent()).blocks;
      enQuestion = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n').trim(' ');
    }
    if(answer['en']!==''&&answer['en']!==undefined&&answer['en']!==null){
      let blocks = convertToRaw(answer['en'].getCurrentContent()).blocks;
      enAnswer = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n').trim(' ');
    }
    if(question['fr']!==''&&question['fr']!==undefined&&question['fr']!==null){
      let blocks = convertToRaw(question['fr'].getCurrentContent()).blocks;
      frQuestion = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n').trim(' ');
    }
    if(answer['fr']!==''&&answer['fr']!==undefined&&answer['fr']!==null){
      let blocks = convertToRaw(answer['fr'].getCurrentContent()).blocks;
      frAnswer = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n').trim(' ');
    }
    //Validations
    if((enQuestion===''||enQuestion===null||enQuestion===undefined||enAnswer===''||enAnswer===null||enAnswer===undefined) &&
    (frQuestion===''||frQuestion===null||frQuestion===undefined||frAnswer===''||frAnswer===null||frAnswer===undefined)){
      toast.error("Question and Answer both in English and French are mandatory", {
        position: toast.POSITION.TOP_RIGHT
      })
      return ;
    }
    if (enQuestion===''||enQuestion===null||enQuestion===undefined||enAnswer===''||enAnswer===null||enAnswer===undefined){
      toast.error("Question and Answer both in English are mandatory", {
        position: toast.POSITION.TOP_RIGHT
      })
      return ;
    }else if (frQuestion===''||frQuestion===null||frQuestion===undefined||frAnswer===''||frAnswer===null||frAnswer===undefined){
      toast.error("Question and Answer both in French are mandatory", {
        position: toast.POSITION.TOP_RIGHT
      })
      return ;
    }
    else if (type===''||type===null||type===undefined){
      typeRef.current.focus()
      toast.error("Choose FAQ Type", {
        position: toast.POSITION.TOP_RIGHT
      })
      return ;
    }
    else{
      let flag  = false
      Object.keys(question).map((item,index)=>{
        let quesBlocks = convertToRaw(question[item].getCurrentContent()).blocks;
        let quesText = quesBlocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n').trim(' ');
        if(quesText!==''){
          if(answer[item]!==undefined){
            let ansBlocks = convertToRaw(answer[item].getCurrentContent()).blocks;
            let ansText = ansBlocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n').trim(' ');
            if(ansText===''){
              flag = true
              toast.error("Add answer for "+languages.find(o=>o.code===item).name, {
                position: toast.POSITION.TOP_RIGHT
              })
              return
            }
          }else{
            flag = true
            toast.error("Add answer for "+languages.find(o=>o.code===item).name, {
              position: toast.POSITION.TOP_RIGHT
            })
            return
          }
        }
      })
      Object.keys(answer).map((item,index)=>{
        let ansBlocks = convertToRaw(answer[item].getCurrentContent()).blocks;
        let ansText = ansBlocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n').trim(' ');
        if(ansText!==''){
          if(question[item]!==undefined){
            let quesBlocks = convertToRaw(question[item].getCurrentContent()).blocks;
            let quesText = quesBlocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n').trim(' ');
            if(quesText===''){
              flag = true
              toast.error("Add question for "+languages.find(o=>o.code===item).name, {
                position: toast.POSITION.TOP_RIGHT
              })
              return
            }
          }else{
            flag = true
            toast.error("Add question for "+languages.find(o=>o.code===item).name, {
              position: toast.POSITION.TOP_RIGHT
            })
            return
          }
        }
      })
      if(!flag){
        let data = {}
        data['question'] = {}
        Object.keys(question).map((item)=>{
          data.question[item] = draftToHtml(convertToRaw(question[item].getCurrentContent()))
        })
        data['answer'] = {}
        Object.keys(answer).map((item)=>{
          data.answer[item] = draftToHtml(convertToRaw(answer[item].getCurrentContent()))
        })
        data["app_type"] = type;
        API.post(APP_URLS.ADD_FAQS, data)
          .then((res) => {
            const resp = res.data;
            if (resp.success === true) {
              toast.success("FAQ Added Successfully.", {
                position: toast.POSITION.TOP_RIGHT,
              });
              navigate("/faqs");
            } else {
              toast.error(resp.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
          .catch(function (error) {
            const resp = error.response;
            let error_message = "";
            if (resp.data.data !== undefined && resp.data.data !== null) {
              {
                Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
              }
            } else if (resp.data.data?.error !== undefined) {
              error_message = resp.data.data.error;
            } else if (resp.data?.error !== undefined) {
              error_message = resp.data.error;
            } else {
              error_message = resp.data.message;
            }
            toast.error(error_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      }
    }
  };


  return (
    <FAQWrapper>
      <div className="mt-2">
        <div
          className="backContainer ms-4"
          onClick={() => navigate("/faqs")}
        >
          <span className="backButton">x</span>
          <span className="px-3 backButton">|</span>
          <span className="backButton">Cancel</span>
        </div>
      </div>
      <div className="headerContainer">
        <h1>CREATE FAQ</h1>
      </div>
      <div className="d-flex justify-content-end save-btn mt-3">
        <button className={(loading?"disabled":"")+" add-button"} onClick={submitForm} disabled={loading}>
          Save
        </button>
      </div>
      <div className="row">
        <div className="col-md-4 offset-md-2">
          <label htmlFor="startDate" className="form-label product-type fieldLabel">
            Type
          </label>
          <select name="productType" id="productType" className="form-control mt-2 mb-4" value={type}
            ref={typeRef}
            onChange={(e) => _type(e.target.value)}>
            <option hidden>Select FAQ Type</option>
            <option value="2">Front Office</option>
            <option value="3">Nomad</option>
          </select>
        </div>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="col-md-8">
          {languages.map((item, index) => {
            let itemCode = item.code;
            let flag = countries.find((o) => o.id === item.country_id)?.flag_thumbnail;
            return (
              <div className="mb-5" key={index}>
                <div className="col-md-12">
                    <label htmlFor="title" className="form-label">
                      <span className="countryIcon px-2">
                        <img src={flag} alt="" className="countryflag" title={item.name}/>
                      </span>
                      <span className="langName fieldLabel ">Question</span>
                    </label>
                    <Editor
                      wrapperClassName="demo-wrapper"
                      editorClassName="demo-editor question"
                      localization={{
                        locale: itemCode,
                      }}
                      editorState={question[itemCode]}
                      onEditorStateChange={(e, code = itemCode) => {
                        handleChange(e, code,_question);
                      }}
                    />
                </div>
                <div className="col-md-12">
                  <label htmlFor="title" className="form-label">
                    <span className="langName fieldLabel ms-2">Answer</span>
                  </label>
                  <Editor
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor answer"
                    localization={{
                      locale: itemCode,
                    }}
                    editorState={answer[itemCode]}
                    onEditorStateChange={(e, code = itemCode) => {
                      handleChange(e, code,_answer);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </FAQWrapper>
  );
};

export default CreateFaqs;
