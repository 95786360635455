import React, { useCallback, useEffect, useRef, useState } from "react";
import { SearchWrapper } from "./searchablestyle"; 
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
const SearchableDropDownWithPagination = ({
  data,
  setPage,
  search,
  setSearch,
  setSource,
  selectValue,
  setSelectValue,
  hasMore,
  loading,
  setData,
  error,
  placeholder,
}) => {
  const observer = useRef();
  const [open, setOpen] = useState(false);
  const customComponentRef = useRef(null);
  const [isSelected, setIsSelected] = useState(false);

  // set a refrence on the last element of select dropdown , when that last element comes into visibility , load next page.
  const lastItemElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setData((prev) => {
            return { ...prev, currentPage: prev.currentPage + 1 };
          });
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  function handleSearch(e) {
    setSearch(e.target.value);
    setIsSelected(false);
    setData((prev) => {
      return { ...prev, currentPage: 1 };
    });
  }

  useEffect(() => {
    // by clicking outside of dropdown , close that dropdown
    function handleClickOutside(e) {
      if (
        customComponentRef.current &&
        !customComponentRef.current.contains(e.target)
      ) {
        setOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClick = (e, item) => {
    setOpen(false);
    setIsSelected(true);
    setPage(1);
    setSelectValue(item);
    setSource(item.source);
  };

  const arrowStyle = {
    color:'gray'
  }
  return (
    <SearchWrapper>
      <div className="custom-select-wrapper"> 
      <div className="select-input-wrapper">
        <input
          placeholder={placeholder}
          type="text"
          value={isSelected ? selectValue?.name || search : search}
          onClick={() => {
            setOpen(true);
          }}
          onChange={handleSearch}
          ></input>
          { 
            open ? <ArrowDropUpIcon sx={arrowStyle} />: <ArrowDropDownIcon sx={arrowStyle}/>
          }
          </div>
        {open && (
          <div ref={customComponentRef} className="filter-values-wrapper">
            {data &&
              data.length > 0 &&
              data.map((item, index) => {
                if (data.length === index + 1) {
                  return (
                    <div
                      onClick={(e) => handleClick(e, item)}
                      ref={lastItemElementRef}
                      key={item.id}
                    >
                      {item.name}
                    </div>
                  );
                } else {
                  return (
                    <div onClick={(e) => handleClick(e, item)} key={item.id}>
                      {item.name}
                    </div>
                  );
                }
              })}
              {
                loading && <div>Loading ...</div>
              }
              {
                error && <div>Error ...</div>
              } 
          </div>
        )}
      </div>
    </SearchWrapper>
  );
};

export default SearchableDropDownWithPagination;
