import React, { useState } from 'react'
import loginImage from "../../assets/Images/image/login.png";
import logo from "../../assets/Images/icons/c2blogo.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ForgotWrapper } from "./style/forgot";
import { APP_URLS } from '../../api/url';
import API from '../../api/axios';
import { toast } from "react-toastify";
import { NavLink, useNavigate } from 'react-router-dom';
import * as Constant from "../../config/constant";

const Login = (props) => {

  const emailRegExp = /^([a-z0-9\_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,6}$/;
  const [loading, setLoading] = useState(false);

  const [emailValid,_emailValid] = useState(false)
  const [passwordValid,_passwordValid] = useState(false)
  const [message,_message] = useState('')

  const navigate = useNavigate()


  const formik = useFormik({
    initialValues: {
        email: '',
        app_type: Constant.APP_TYPE['ADMIN_PANEL']
    },
    validationSchema: Yup.object({
        email: Yup.string().matches(emailRegExp, 'The "Email" field format is invalid').required('Email is Required'),
    }),
    onSubmit: values => {
        if(!loading) {
            setLoading(true)
            API.post(APP_URLS.FORGOT_PASSWORD, values)
            .then(res => {
                const resp = res.data;
                setLoading(false)
                if(resp){
                  _message(resp.data.value)
                }
                else{
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT
                  })
                }
            })
            .catch(function (error) {  
                const resp = error.response;
                setLoading(false)
                let error_message = '';
                if (resp.data.data?.email !== undefined) {
                    {Object.keys(resp.data.data.email).map((error, index) => (
                        error_message = resp.data.data.email[error]
                    ))}
                } else if (resp.data.data?.error !== undefined) {
                    error_message =  resp.data.data.error;
                } else if(resp.data?.error!== undefined){
                    error_message =  resp.data.error
                } else{
                    error_message =  resp.data.message
                }
                _message(error_message)
            });       
        }
    },
  });

  return (
    <>
      <ForgotWrapper>
        <div className='container-fluid login-admin'>
          <div className='header'>
            <div className='headContainer'>
              <img src={logo} alt="" onClick={()=>navigate('/login')}/>
            </div>
          </div>

          <div className="formContainer container-full mt-5">
            
            <div className='col-md-8 offset-md-2'>
              <div className='row'>
              <div className="col-sm-12 col-md-6 offset-md-6 col-lg-4 offset-lg-8">
            </div>
              </div>
            
              <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-7">
                  <div className="login-img">
                    <img src={loginImage} alt="" />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-5">
                  <div className='formLogin'>
                  <div className='form-title block sm-block md-none'>FORGOT PASSWORD</div>
                    <form onSubmit={(e)=> formik.handleSubmit(e)}>
                        <div className="inputField">
                          <input
                            type="text"
                            placeholder="Email"
                            name="email"
                            value={formik.values.email}
                            onBlur={formik.handleBlur}
                            onChange={(e)=>{
                              formik.handleChange(e)
                              _message('')
                            }}
                          />
                          
                          {message?<span className='required'>
                            {message}
                          </span>:<span className='required'>
                            {formik.touched.email && formik.errors.email}
                          </span>}
                        </div>
                        <button className={(loading?"disabled":"")+" submitBtn actionBtn"} type='submit' disabled={loading}>{loading?"Sending...":"Send Email"}</button>
                    </form>
                    <div className="forgot">
                      <NavLink to="/login">Login</NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer>
            <p>All rights reserved - CarToBike.com 2022</p>
          </footer>
        </div>
      </ForgotWrapper>
    </>
  );
};

export default Login