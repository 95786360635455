import styled from "styled-components";

export const SlidesWrapper = styled.div`
.w-30{
  width:30%;
}
.countryflag{
  width: 20px;
  height: 20px;
}
.imageData{
  border-bottom:2px solid #c3c3c3;
}
.textbox{
  min-width:60%;
  max-width:60%;
  max-height: 65px;
  min-height: 75px;
  border:1px solid #c3c3c3;
  text-align:end;
  overflow-y:auto;
}
.flagbox{
  min-width:50%;
  max-width:50%;
}
.flagbox img{
  cursor:pointer;
}
.imageLink{
  max-width:60%;
  word-break: break-all;
  color: #607afc;
  cursor:pointer;
}
.ImagePreviewContent span {
  display: block;
  line-height: 20px;
}
.ImagePreviewContent {
  border-top:1px solid #c3c3c3;;
    padding: 2px 5px;
}
.addbtn{
  align-self: center;
  background-color: #607afc!important;
  border: none;
  border-radius: 5px;
  color: #fff;
  height: 35px;
  line-height: 35px;
  white-space: nowrap;
  font-size: 18px;
  font-weight: 700;
  padding: 0 20px;
}
.countryFilter{
  max-width:160px;
}
.slide-single{
  position: relative;
}
.slide-single .slide-action{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,15%);
  display:flex;
  justify-content: center;
  gap: 35px;
}
.slide-action button{
  border-radius : 50%;
  border:none;
  background:#ffffff6b;
  height:47px;
  width:47px;
}
.slide-action button span{
  background:none;
  color: black;
  font-size:11px;
}
.slide-icon{
  height:15px !important;
  padding:0px !important;
}
.noRecord{
  margin-top:15px;
  border: 1px solid rgba(0,0,0,.1);
  display:flex;
  justify-content: center
}
.noRecord span{
  padding:15px;
  font-size: 18px;
  opacity: 50%;
}
.boxDisabled input{
  cursor: not-allowed !important;
  pointer-events: all !important;
}
// .preview-desktop-container {
//   border: 1px solid #979797;
//   border-radius: 5px;
//   margin-top: 10px;
//   overflow-x: scroll;
//   overflow-y: scroll;
//   width: 100%;
//   height: 800px;
// }

// .preview-desktop-area {
//   max-width: 100%;
//   min-width: 1280px;
//   min-height: 800px;
// }
.btn-link {
  font-size: 18px;
}
.view-menu {
  max-width: 80%;
}
.setting-preview-option {
  display: flex;
  justify-content: center;
  padding: 10px;
}
.setting-preview-option button {
  margin: 10px 30px;
  padding: 0;
  font-size: 20px;
  font-weight: 600;
}
.preview-icon {
  justify-content: start;
  display: flex;
  min-height:30px;
}
.preview-icon img {
  filter: grayscale(1);
  margin-right: 45px;
  cursor: pointer;
}
.preview-icon img:hover {
  filter: grayscale(0);
}
.preview-icon .active-preview {
  filter: grayscale(0);
}
.preview-area {
  max-width: 100%;
  min-height: 800px;
  padding: 25px;
  border: 1px solid #979797;
  border-radius: 5px;
  margin-top: 10px;
}
.slide-nav {
  list-style-type: none;
  margin-top: 30px;
  padding-left: 0;
}
.slide-nav li {
  list-style-type: none;
  font-size: 16px;
  line-height: 30px;
  cursor: pointer;
  font-weight: 600;
  margin: 5px 1px;
  padding: 1px 10px;
  border-radius: 3px;
  transition: all 0.35s ease-in-out;
}
.slide-nav li:hover {
  color: #637df4;
  background: rgba(93, 120, 255, 0.07);
}
.active-view {
  color: #637df4;
  background: rgba(93, 120, 255, 0.07);
}
.active-view a{
  text-decoration: none;
}
.btn-inactive {
  color: #000000;
}
.btn-inactive:hover {
  color: #0a58ca;
}
.mobile-preview {
  padding: 25px 17px 25px 19px;
  /* overflow: hidden; */
  max-width: 390px;
  width: 100%;
  margin: 0px auto;
  background-size: cover;
  height: 845px;
  background-repeat: no-repeat;
  // overflow-y: auto;
  margin-bottom: 10px;
  margin-top: 10px;
}
.slide-outer {
  text-align: center;
  align-items: center;
  border: 1px solid #979797;
  border-radius: 6px;
  // overflow: hidden;
}
.imageValue img{
  // max-width:250px;
  // max-width: 100%;
  // max-height: 127px;
  // overflow: hidden;
}
.delete-icon {
  z-index: 999;
  position: absolute;
  right: -10px;
  top: -7px;
}
.content{
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.25px;
}
.slide-outer span {
//   color: #607afc;
//   /* position: absolute; */
//   left: 0;
//   bottom: 0;
//   background: #ffffff;
//   padding: 5px;
//   text-overflow: ellipsis;
//   white-space: nowrap;
//   overflow: hidden;
//   border-radius:0 0 10px 10px;
//   width: 100%;
}
.slider-outer .slide-outer {
  max-width: 100%;
  position: relative;
  max-width: 280px;
  width: 100%;
}
.header{
  line-height: 20px;
  letter-spacing: 0.25px;
}
.slide-outer img {
  border-radius: 5px 5px 0 0 ;
  width: 100%;
  overflow: hidden;
  height: auto;
}
.slide-outer .add-icon {
  width: 50px !important;
  height: 50px;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
}
.slide-outer .add-icon:hover {
  opacity: 0.75;
}
.slide-detail {
  list-style-type: none;
  padding: 0;
  color: #979797;
}
.slide-detail li {
  float: left;
  margin-right: 20px;
}
.slide-detail-content {
  color: #000000;
}
.imageDetails {
  max-width: 320px;
  width: 100%;
}
.slider-outer .imageDetails span {
  width: 48%;
  display: inline-flex;
  font-size: 12px;
  line-height: 17px;
  padding-left: 10px;
}
.slider-border-rm {
  border: 1px solid #c3c3c3;
}
.resetFile {
  align-self: flex-end;
  cursor: pointer;
  position: absolute;
  margin-right: 7px;
  margin-top: 0px;
}
.status {
  height: 15px !important;
  width: 15px !important;
}
@media (max-width: 991px) {
  .slider-outer {
    justify-content: center;
  }
  .view-menu {
    max-width: 100%;
  }
  .preview-icon img {
    margin-right: 15px;
    margin-left: 15px;
  }
  .preview-icon {
    justify-content: center;
    margin-bottom: 10px;
  }
}

@media (max-width: 1541px) {
  .textbox {
    min-height: 65px;
  }
}
@media (max-width: 1248px) {
  .link-section {
    width: 100%;
  }
  .col-md-4 {
      flex: 0 0 auto;
      width: 100%;
  }
  .imageBox {
    display:flex;
    justify-content: center;
  }
}
@media (min-width: 768px){
}
@media (min-width: 992px) and (max-width: 1199px) {
  .phone-preview-col {
    width: 100%;
  }
}

@media (max-width: 623px) {
  .slider-outer {
    margin-top: 10px;
  }
}
@media (max-width: 720px) {
  .imageBox {
    display:flex;
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .preview-area {
    padding: 15px;
  }
}
@media (max-width: 459px) {
  .addbtn {
    margin-top: 7px;
  }
}
@media (max-width: 340px) {
  .fw-bold {
    font-size: 15px;
  }
}
@media (min-width: 1249px) and (max-width: 1400px) {
  .slide-single .slide-action{
    gap: 30px;
  }
  .slide-action button{
    border-radius : 50%;
    border:none;
    background:#ffffff6b;
    height:40px;
    width:40px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
  }
  .slide-icon{
    height:10px !important;
    padding:0px !important;
  }
}
`;