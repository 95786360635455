import { useEffect, useState } from "react";

import React from 'react'
import API from "../../../api/axios";
import { toast } from "react-toastify";
import { APP_URLS } from "../../../api/url";

function SubjectFilter({ handleChange , value}) {
    const [subjects, _subjects] = useState([]);
    const [loading , _loading] = useState(false)

    useEffect(()=>{
      getSubjects()
    },[])
  
    const getSubjects = () =>{
      _loading(true)
      API.get(APP_URLS.LIST_SUBJECT+`?limited_data=true`)
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) { 
            _loading(false)
            _subjects(resp.data.items)
          } else {
            toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            _loading(false)
          }
        })
        .catch(function (error) {
          _loading(false)
          const resp = error.response;
          let error_message = "";
          if (resp?.data?.data) {
            {
              Object.keys(resp.data?.data).map(
                (error, index) => (error_message = resp.data.data[error][0])
                );
              }
            } else if (resp?.data?.error) {
              error_message = resp.data.error;
            } else {
            error_message = resp.data.message;
          }
          toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
 
    return (
              <div className="select-wrapper">
                <select
                  className="filterBox"
                  onChange={handleChange}
                  value={value}
                >
                    
                  <option hidden value=''>Subject</option>
                  { loading ? <span>loading...</span> : null }
                  {
                    subjects.length && subjects.map( option => {
                        return <option key={'option'+option.id} value={option.id}>{option.name}</option>
                    })
                  } 
                </select>
              </div>
    )
}

export default SubjectFilter
 
  