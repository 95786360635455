import React, { useEffect, useState } from "react";
import back from "../../../assets/Images/icons/svgIcon/back-arrow.svg";
import { BidListWrapper } from "./style/BidListWrapper";
import * as Constant from "../../../config/constant";
import timeWarn from "../../../assets/Images/icons/alert.svg";
import deleteIcon from "../../../assets/Images/icons/faTrashRed.svg";
import Pagination from "../../../assets/SharedComponents/Pagination";
import { APP_URLS } from "../../../api/url";
import API from "../../../api/axios";
import { useParams,useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import NoRecord from "../../../assets/SharedComponents/Table/NoRecord";
import RecordCount from "../../../assets/SharedComponents/RecordCount/RecordCount";
import { Tooltip } from "@mui/material";
import { FaFilterIcon } from "../../../assets/Images/icon";
import { ThreeDots } from "react-loader-spinner";
import { PusherClient } from "../../../websocket";


const BidList = () => {

  PusherClient.subscribe('bid_auctions'); 
  const channelAc = PusherClient.channel('bid_auctions'); 

  const [searchParams, setSearchParams] = useSearchParams();
  const {bidListId} = useParams();

  const [userAccess,_userAccess] = useState(JSON.parse(localStorage.getItem('permissions'))) 
  let auctionAccess = userAccess.find(o=>o.slug ==="auction_event")
 

  const [bids,_bids] = useState([])
  const [bidData,_bidData] = useState()
  const [name,_name] = useState('')
  const [country,_country] = useState('')
  const [auctionId,_auctionId] = useState('')

  const [dataDeleted,_dataDeleted] = useState(false)
  const [loaded,_loaded] = useState(false);

  const [trashed, _trashed] = useState(true);

  const [total, _total] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPage, _totalPage] = useState(0);
  const [countValue, _countValue] = useState('');

  const [filters, _filters] = useState(false);

  const [data,_data]=useState([]);

  const navigate = useNavigate() 
  
  useEffect(() => {
    _dataDeleted(false)
    getBids()
  }, [page,dataDeleted,countValue,trashed])

  useEffect(() => {
    getAuctionBidsData()
  }, [])
  
  
  const appendBid = async () => {  
    await channelAc.bind('NewBidApplied', (data) => {
       const bidItem = data?.message; 
       if(bidItem?.ad?.id == bidListId && Object.keys(bidItem).length > 0 ){  
             _bids((oldBids) => [bidItem, ...oldBids]); 
       }
    });
  }

  useEffect(() => {  
        appendBid() 
  }, [])


  const getBids = () =>{
    _loaded(true)
    API.get(APP_URLS.LIST_AUCTION_BIDLIST + `/${bidListId}?page=${page}&per_page=${countValue}&with_trashed=${trashed===true?1:0}`)
    .then(res => {
      const resp = res.data;
      if(resp.success===true){
        _bids(resp.data.data)
        _totalPage(resp.data.last_page)
        _loaded(false)
        _total(resp.data.total);
      }
      else{
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT4101
        })
        _loaded(false)
      }
    })
    .catch(function (error) {  
      _loaded(false)
      const resp = error.response;
      let error_message = '';
      if (resp.data.errors !== undefined) {
        {
          Object.keys(resp.data.errors).map(
            (error, index) => (error_message = resp.data.errors[error][0])
          );
        }
      } else if (resp.data.data?.error !== undefined) {
          error_message =  resp.data.data.error;
      } else if(resp.data?.error!== undefined){
          error_message =  resp.data.error
      } else{
          error_message =  resp.data.message
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT
      })
    });
  }

  const getAuctionBidsData = () =>{
    API.get(APP_URLS.AUCTION_BIDLIST_DETAIL + `/${bidListId}`)
    .then(res => {
      const resp = res.data;
      if(resp.success===true){
        _bidData(resp.data.item)
        _auctionId(resp.data.item.auction_id)


        if (resp.data.item.owner_vehicle.current_sub !== null) {
          _country(resp.data.item.owner_vehicle.current_sub.limited_address)
          if (resp.data.item.owner_vehicle.current_sub.first_name || resp.data.item.owner_vehicle.current_sub.last_name) {
            _name(`${resp.data.item.owner_vehicle.current_sub.first_name ? resp.data.item.owner_vehicle.current_sub.first_name : ""} ${resp.data.item.owner_vehicle.current_sub.last_name ? resp.data.item.owner_vehicle.current_sub.last_name : ""}`);
          } else {
            _name(resp.data.item.owner_vehicle.current_sub.name);
          }
        } else {
          if (resp.data.item.owner_vehicle.current_user !== null) {
            _country(resp.data.item.owner_vehicle.current_user.limited_address)
            if (resp.data.item.owner_vehicle.current_user.first_name || resp.data.item.owner_vehicle.current_user.last_name) {
              _name(`${resp.data.item.owner_vehicle.current_user.first_name ? resp.data.item.owner_vehicle.current_user.first_name : ""} ${resp.data.item.owner_vehicle.current_user.last_name ? resp.data.item.owner_vehicle.current_user.last_name : ""}`);
            } else {
              _name(resp.data.item.owner_vehicle.current_user.pseudo);
            }
          } else {
            _name('-')
          }
        }
      }
      else{
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })
    .catch(function (error) {  
      const resp = error.response;
      let error_message = '';
      if (resp.data.errors !== undefined) {
        {
          Object.keys(resp.data.errors).map(
            (error, index) => (error_message = resp.data.errors[error][0])
          );
        }
      } else if (resp.data.data?.error !== undefined) {
          error_message =  resp.data.data.error;
      } else if(resp.data?.error!== undefined){
          error_message =  resp.data.error
      } else{
          error_message =  resp.data.message
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT
      })
    });
  }

  const removeType = (index) => {
    confirmAlert({
      title: "Confirm to remove",
      message: `Are you sure you want to remove?`,
      closeOnEscape: true,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            API.delete(APP_URLS.DELETE_AUCTION_BID + `?id[1]=${index}`)
              .then((res) => {
                const resp = res.data;
                if (resp.success === true) {
                  _dataDeleted(true);
                  toast.success(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                } else {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              })
              .catch(function (error) {
                const resp = error.response;
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                  {
                    Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
                  }
                } else if (resp.data.data?.error !== undefined) {
                  error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                  error_message = resp.data.error;
                } else {
                  error_message = resp.data.message;
                }
                toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          },
        },
        {
          label: "No",
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-custom-class-name",
    });
  };

  const handleSearchChange = (searchValue) => {
    _countValue(searchValue);
    if (searchValue !== "") {
      _totalPage(0)
      setPage(1);
    }
  };

  const clearFilter = () => {
    _filters(true)
    handleSearchChange('');
    _trashed(true)
    if (countValue !== ''||trashed!==true) {
      _totalPage(0)
      setPage(1);
    }
    };
    
  return (
    <>
      <BidListWrapper>
        <div className="row mt-4 mb-5">
          <div className="col-md-4 d-flex align-items-center">
              <img src={back} className="ms-4 backbtn" onClick={()=>navigate(
                "/auction-ads/"+searchParams.get("ad_id")+"?"+
                (searchParams.get("a_country_id") !== null
                ? "a_country_id=" + searchParams.get("a_country_id") + "&"
                : "") +              
              (searchParams.get("a_seller_allowed") !== null
                ? "a_seller_allowed=" + searchParams.get("a_seller_allowed") + "&"
                : "") +
              (searchParams.get("a_display_for") !== null 
                ? "a_display_for=" + searchParams.get("a_display_for") + "&"
                : "") +
              (searchParams.get("a_product_type_id") !== null
                ? "a_product_type_id=" + searchParams.get("a_product_type_id") + "&"
                : "") +    
              (searchParams.get("a_status") !== null
                ? "a_status=" + searchParams.get("a_status") + "&"
                : "") +   
              (searchParams.get("a_sort") !== null
                ? "a_sort=" + searchParams.get("a_sort") + "&"
                : "") +                                  
              (searchParams.get("a_keyword") !== null
                ? "a_keyword=" + searchParams.get("a_keyword") + "&"
                : "") +                    
              (searchParams.get("a_per_page") !== null
                ? "a_per_page=" + searchParams.get("a_per_page") + "&"
                : "") +                    
              (searchParams.get("a_page") !== null
                ? "a_page=" + searchParams.get("a_page") + "&"
                : "") +
              (searchParams.get("auction_id") !== null
                ? "auction_id=" + searchParams.get("auction_id") + "&"
                : "") +   
              (searchParams.get("ae_country_id") !== null
              ? "ae_country_id=" + searchParams.get("ae_country_id") + "&"
              : "") +              
              (searchParams.get("ae_seller_allowed") !== null
                ? "ae_seller_allowed=" + searchParams.get("ae_seller_allowed") + "&"
                : "") +
              (searchParams.get("ae_display_for") !== null 
                ? "ae_display_for=" + searchParams.get("ae_display_for") + "&"
                : "") +
              (searchParams.get("ae_product_type_id") !== null
                ? "ae_product_type_id=" + searchParams.get("ae_product_type_id") + "&"
                : "") +                    
              (searchParams.get("ae_recurring") !== null
                ? "ae_recurring=" + searchParams.get("ae_recurring") + "&"
                : "") +  
              (searchParams.get("ae_sort") !== null
                ? "ae_sort=" + searchParams.get("ae_sort") + "&"
                : "") +                    
              (searchParams.get("ae_keyword") !== null
                ? "ae_keyword=" + searchParams.get("ae_keyword") + "&"
                : "") +                
              (searchParams.get("ae_start_day") !== null
                ? "ae_start_day=" + searchParams.get("ae_start_day") + "&"
                : "") +  
              (searchParams.get("ae_end_day") !== null
                ? "ae_end_day=" + searchParams.get("ae_end_day") + "&"
                : "") +                      
              (searchParams.get("ae_per_page") !== null
                ? "ae_per_page=" + searchParams.get("ae_per_page") + "&"
                : "") +                    
              (searchParams.get("ae_page") !== null
                ? "ae_page=" + searchParams.get("ae_page") + "&"
                : "") +
              (searchParams.get("auction_page") !== null
              ? "auction_page=" + searchParams.get("auction_page") + "&"
              : "") +
              (searchParams.get("ad_keyword") !== null
                ? "ad_keyword=" + searchParams.get("ad_keyword") + "&"
                : "") +
              (searchParams.get("ad_trashed") !== null
                ? "ad_trashed=" + searchParams.get("ad_trashed") + "&"
                : "") +                    
              (searchParams.get("ad_per_page") !== null
                ? "ad_per_page=" + searchParams.get("ad_per_page") + "&"
                : "") +
              (searchParams.get("ad_page") !== null
                ? "ad_page=" + searchParams.get("ad_page") + "&"
                : "") 
              )}/>
          </div>
          <div className="col-md-4">
              <h1>BIDS LIST</h1>
          </div>
        </div>
          <div className="container">
            <div className="bidstarget">
                <div  className="dealerDetail">
                  {country!==''?<img className="me-4 countryflag" src={country.flag_thumbnail} title={country.country}/>:null} 
                  <p>{name} 
                    <b className="d-block">
                      {bidData?.owner_vehicle.current_sub!==null?Constant.userTypeName.DEALER:bidData?.owner_vehicle.current_user!==null?"Private":""}
                    </b>
                  </p>
                </div>
                <span className="seperator"></span>
                <div  className="productDetail">
                  <p>{bidData?.owner_vehicle.title} <b className="d-block">id: {bidData?.auction_id}</b></p>
                </div>
                <span className="seperator"></span>
                <div  className="priceDetail">
                  <h4>Target Price</h4> 
                  <p>€ {bidData?.public_price}</p>
                </div>
            </div>

            <div className="d-flex justify-content-end my-2 align-items-center gap-2">
              <RecordCount
                onSearchClick={(search) => handleSearchChange(search)}
                id="pageCount"
                filters={filters}
                _filters={_filters} />
              <Tooltip title="Clear Filter" placement="bottom">
                <FaFilterIcon className="filter-icon" role="button" onClick={clearFilter} />
              </Tooltip>
            </div>
            <div className="d-flex align-items-center justify-content-between info-wrap">
              <div className="justify-content-end mt-1 me-2">
                <span className='text-start'>Total Records : <b>{total}</b></span>
              </div>
              <div className="mt-1">
                  <input type="checkbox" checked={!trashed} onChange={()=>{
                    _totalPage(0)
                    setPage(1);
                    _trashed(!trashed)
                    }}/>
                  <span className="ms-2">Hide Deleted Data</span>
              </div>
            </div>

            <div className="tableContainer">
              <table className="auctionTable">
                  <thead className="tableHead">
                      <tr className="table-row">
                        <th className="head-0">Bidder Name</th>
                        <th className="head-1">Manual Bid <span className="manualTime fieldTitle">(Datetime)</span></th>
                        <th className="head-2">Auto Bid <span className="manualTime fieldTitle">(Datetime)</span></th>
                        {auctionAccess.permissions.find((o)=>o.slug==='delete')?
                        <th className="head-3"></th>
                        :''}
                      </tr>
                  </thead>
                  {loaded ? 
                    <div className='d-flex justify-content-center three-dot'>              
                    <ThreeDots 
                      height="150" 
                      width="150" 
                      radius="9"
                      color="#637df4" 
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>:
                  <tbody>
                    {bids.length>0?bids.map((item,index)=>{
                      let name = ""
                      let flag = ""
                      let detailPageLink = ''
                      let percentage = parseFloat((item.amount/bidData?.public_price)*100)
                      if(item.sub_customer!==null){
                        name = item.sub_customer.name
                        flag = item.sub_customer.limited_address
                        detailPageLink = '/company-details/'+ item.sub_customer.id;
                      }
                      else if(item.user_customer!==null){
                        flag = item.user_customer.limited_address
                        if(item.user_customer.first_name||item.user_customer.last_name){
                          name = `${item.user_customer.first_name} ${item.user_customer.last_name}`
                        }
                        else if(item.user_customer.pseudo){
                          name = item.user_customer.pseudo
                        }
                        else{
                          name = "-"
                        }
                        detailPageLink = '/detail-member/'+ item.user_customer.id;

                      }
                      else{
                        name = "-"
                      }
                      return<tr className={(item.deleted_at ? "deletedRow" : "") + " table-row"} key={index}>
                      <td className="d-flex flex-wrap text-left tleft head-0">
                        <img className="me-4 countryflag" src={flag.flag_thumbnail} title={flag.country}/> 
                        <p>
                          {
                            detailPageLink != '' ? 
                            <a className='link'  href={detailPageLink} target="_blank"> <b>{name}</b></a> 
                            :{name}
                          }
                          <span className="bidderType">{Constant.TYPE_OF_PROFILE[item.customer_type]}</span>
                        </p>
                      </td>
                      <td className="head-1 bids">
                        {item?.auto_bid===0?
                          <>
                          <div>
                          <span className={(percentage<30?"end-status":percentage>30&&percentage<60?"progress-status":percentage>60?"active-status":"active-status")+" align-middle"}>€ {item.amount}</span><br/>
                          <span className="creationTime">{moment(item.created_at).tz("Europe/Amsterdam").format("MMMM Do, YYYY HH:mm")}</span>
                          </div>
                          {item.variation!==null&&item.variation>35?<img className="warningIcon" src={timeWarn} title="Bid exceeded 35%"/>:''}
                          </>:''}
                      </td>
                      <td className="head-2">{item?.auto_bid===1?
                        <>
                          <span className={(percentage<30?"end-status":percentage>30&&percentage<60?"progress-status":percentage>60?"active-status":"active-status")+" align-middle"}>€ {item.amount}</span><br/>
                          <span className="creationTime">{moment(item.created_at).tz("Europe/Amsterdam").format("MMMM Do, YYYY HH:mm")}</span>
                        </>
                      :''}</td>
                      {auctionAccess.permissions.find((o)=>o.slug==='delete')?
                      <td className="head-3">
                        {(item.deleted_at === '' || item.deleted_at === null) &&
                        <img className="cursor-pointer" src={deleteIcon} onClick={()=>removeType(item.id)}/>}
                      </td> 
                      :''}
                    </tr>
                    }):<NoRecord />}
                  </tbody>
                  }
              </table>
            </div> 
            {totalPage > 1 ? <Pagination totalPages={totalPage} page={page} key={totalPage} onPageClick={(page) => setPage(page + 1)} /> : ""}
          </div>
      </BidListWrapper>
    </>
  );
};

export default BidList;
