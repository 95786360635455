import React, { useEffect, useState } from "react";
import { PlanTableWrapper } from "./style/planTable";
import copy from "../../../assets/Images/icons/faLink.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import waste from "../../../assets/Images/image/Waste.png";
import MainLayout from "../../../assets/Layout/MainLayout";
import * as Constant from "../../../config/constant";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import Pagination from "../../../assets/SharedComponents/Pagination";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import NoRecord from '../../../assets/SharedComponents/Table/NoRecord'
import SearchKey from "../../../assets/SharedComponents/Search/SearchKey";
import { confirmAlert } from "react-confirm-alert";
import { Tooltip } from "@mui/material";
import { FaFilterIcon } from "../../../assets/Images/icon";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";
import RecordCount from "../../../assets/SharedComponents/RecordCount/RecordCount"
import { formatCurrency } from "../../../core/utils";

const PlanTable = () => {

  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const [searchParams, setSearchParams] = useSearchParams();

  const [data, _data] = useState([]);
  const [dataDeleted, _dataDeleted] = useState(false);
  
  const [trashed, _trashed] = useState(searchParams.get('trashed')!==null?(searchParams.get('trashed')==='false'?false:true):true);
  const [searchkeyword, _searchkeyword] = useState(query.get('keyword')!==null?query.get('keyword'):"");
  const [countValue, _countValue] = useState(query.get('per_page')!==null?query.get('per_page'):"");

  const [page, setPage] = useState(query.get('page')!==null?parseInt(query.get('page')):1);
  const [total, _total] = useState(0);
  const [totalPage, _totalPage] = useState(0);

  const [userAccess,_userAccess] = useState(JSON.parse(localStorage.getItem('permissions')))
  
  let planAccess = userAccess.find(o=>o.slug ==="plans")

  const [filters, _filters] = useState(false);
  const [loaded, _loaded] = useState(false);

  useEffect(() => {
    getPlans()
    _dataDeleted(false)
  }, [page,searchkeyword,dataDeleted,countValue,trashed])

  const getPlans =() =>{
    _loaded(true)
    API.get(APP_URLS.LIST_PLANS + `?page=${page}&per_page=${countValue}&filter[type]=${Constant.planType.RECURRING}&keyword=${searchkeyword}&with_trashed=${trashed===true?1:0}`)
    .then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        _data(resp.data.data)
        _totalPage(resp.data.last_page)
        _total(resp.data.total);
        _loaded(false);
      } else {
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        _loaded(false);
      }
    })
    .catch(function (error) {
      _loaded(false);
      const resp = error.response;
      let error_message = "";
      if (resp.data.data !== undefined && resp.data.data !== null) {
        if(resp.data.data.message){
          error_message = resp.data.data.message
        }else{
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        }
      } else if (resp.data.data?.error !== undefined) {
        error_message = resp.data.data.error;
      } else if (resp.data?.error !== undefined) {
        error_message = resp.data.error;
      } else {
        error_message = resp.data.message;
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    });
  }

  const removeType = (index) => {
    confirmAlert({
      title: "Confirm to remove",
      message: `Are you sure you want to remove?`,
      closeOnEscape: true,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            API.delete(APP_URLS.DELETE_PLAN + `/${index}`)
              .then((res) => {
                const resp = res.data;
                if (resp.success === true) {
                  _dataDeleted(true);
                  toast.success("Plan has been deleted successfully.", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                } else {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              })
              .catch(function (error) {
                const resp = error.response;
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                  if(resp.data.data.message){
                    error_message = resp.data.data.message
                  }else{
                    {
                      Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
                    }
                  }
                } else if (resp.data.data?.error !== undefined) {
                  error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                  error_message = resp.data.error;
                } else {
                  error_message = resp.data.message;
                }
                toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          },
        },
        {
          label: "No",
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-custom-class-name",
    });
  };

  const handleSearchChange = (func, searchValue) => {
    func(searchValue);
    if((func===_countValue&&searchValue!==countValue)||(func===_searchkeyword&&searchValue!==searchkeyword)){
      if (searchValue !== "") {
        _totalPage(0)
        setPage(1);
      }
    }
  };
  
  const clearFilter = () => {
    _filters(true)
    handleSearchChange(_countValue, '');
    handleSearchChange(_searchkeyword, '');
    _trashed(true);
    if (searchkeyword !== ""||countValue !== ''||trashed!==true) {
      _totalPage(0)
      setPage(1);
    }
    for(var value of query.keys()) {
      const param = searchParams.get(value);
      if (param) {
        searchParams.delete(value);
        setSearchParams(searchParams);
      }
     }
  };

  return (
    <>
      <PlanTableWrapper>
      <div className="auctionMainContainer">
        <div className="row mt-4">
          <div className="col-md-4 offset-md-4">
            <h1>PLANS</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-2">
            {planAccess?.permissions.find((o) => o.slug === "add") ?
            <div className="actionContainer">
              <NavLink  className="btn" to="/create-plan">+ Add</NavLink>
            </div>
            :''}
          </div>
          <div className="justify-content-end mb-2 d-flex gap-2 mb-2 flex-wrap align-items-center">
            <SearchKey
              onSearchClick={(search) => handleSearchChange(_searchkeyword,search)}
              val = {searchkeyword}
              id="searchData"
              filters={filters}
              _filters={_filters}
              placeholder="Search by Title"
            />
            <RecordCount
              onSearchClick={(search) => handleSearchChange(_countValue, search)}
              val = {countValue}
              id="pageCount"
              filters={filters}
              _filters={_filters} />
            <Tooltip title="Clear Filter" placement="bottom">
                <FaFilterIcon className="filter-icon" role="button" onClick={clearFilter} />
            </Tooltip>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between info-wrap">
          <div className="justify-content-end mt-1 me-2">
              <span className='text-start'>Total Records : <b>{total}</b></span>
          </div>
          <div className="mt-1">
            <input type="checkbox" checked={!trashed} onChange={()=>{
              _totalPage(0)
              setPage(1);
              _trashed(!trashed)
              }}/>
            <span className="ms-2">Hide Deleted Data</span>
          </div>
        </div>
      <div className="tableContainer">
        <table className="auctionTable">
            <thead className="tableHead">
                <tr className="table-row">
                  <th className="clipboard">
                    Plan Name<br/>
                    Stripe Product ID<br/>
                  </th>
                  <th className="clipboard">
                    Nickname<br/>
                    Stripe Price ID<br/>
                  </th>
                  <th className="w130">Number of Subscriber</th>
                  <th>
                    Price Start<br/>
                    Price Archived
                  </th>
                  <th>Price €</th>
                  <th>Status</th>
                  <th>Recurring Interval</th>
                  <th>Recurring Interval Count</th>
                  <th>Type</th>
                  <th className="w130">Number of Features</th>
                  {planAccess?.permissions.find((o) => o.slug === "delete") ?<th className="w80">Action</th>:""}
                </tr>
            </thead>
            {loaded ? 
                    <div className='d-flex justify-content-center three-dot'>              
                    <ThreeDots 
                      height="150" 
                      width="150" 
                      radius="9"
                      color="#637df4" 
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                </div>
        : 
            <tbody>
              {data.length>0?
              data.map((item,index)=>{
                return <tr className={(item.deleted_at ? "deletedRow" : "") + " table-row"} key={index}>
                <td className="clipboard">
                  {item.deleted_at===null?
                  <NavLink to={`/plan-description/${item.id}?`+(searchkeyword!==""?"keyword="+searchkeyword+"&":"")+(countValue!==""?"per_page="+countValue+"&":"")+(trashed!==true? "trashed=" + trashed + "&": "")+(page!==1?"page="+page:"")}>{item.name}</NavLink>
                  :<span className="fw-bold disabled">{item.name}</span>}
                  <div className="my-2 d-flex justify-content-center">
                    <span className="copyText">{item.stripe_product_id}</span>
                    <CopyToClipboard text={item.stripe_product_id} onCopy={() => toast("Text Copied")}>
                      <img className="ms-2" src={copy} alt=""/>
                    </CopyToClipboard>
                  </div>
                </td>
                <td className="clipboard">
                  <p  className="fw-light text-xs">{item.prices[0].nickname}</p>
                  <div className="my-2 d-flex justify-content-center">
                    <span className="copyText">{item.prices[0].stripe_price_id}</span>
                    <CopyToClipboard text={item.prices[0].stripe_price_id} onCopy={() => toast("Text Copied")}>
                      <img className="ms-2" src={copy} alt=""/>
                    </CopyToClipboard>
                  </div>
                </td>
                <td className="w130">{item.prices[0].subscription_items_count}</td>
                <td >
                  <span className="d-block">{item.create_activity_log!==null?
                  moment(item.create_activity_log.pivot.created_at).tz("Europe/Amsterdam").format(
                    "DD-MM-YYYY HH:mm"
                  )
                  :"-"}</span>
                  <span>{item.archived_at!==null?moment(item.archived_at).tz("Europe/Amsterdam").format(
                    "DD-MM-YYYY HH:mm"
                  ):"-"}</span>
                </td>
                <td>{formatCurrency(item.prices[0].unit_amount)}</td>
                <td className={(item.deleted_at ? "disabled" : "")}>
                  <span className={(item.active===1?'status-active':'status-end')+" status"} title={item.active===1?'Active':'Inactive'}></span>
                </td>
                <td>{(item?.prices[0]?.recurring_interval)?.charAt(0).toUpperCase()+item?.prices[0]?.recurring_interval?.slice(1)}</td>
                <td>{item?.prices[0]?.recurring_interval_count?item.prices[0].recurring_interval_count:'-'}</td>
                <td>{(item.prices[0]?.type)?.charAt(0).toUpperCase()+item?.prices[0]?.type?.slice(1)}</td>
                <td className="w130">{item.features_count}</td>
                {planAccess?.permissions.find((o) => o.slug === "delete") ?
                <td className="w80">
                  {(item.deleted_at===''||item.deleted_at===null)&&item.active===0?
                  <img src={waste} alt="" role="button" onClick={() => removeType(item.id)} />
                  :''}
                </td>
                :""}
              </tr>
              })         
              :
              <NoRecord />
              }

            </tbody>
      }
        </table>
      </div>
        <div>
          {totalPage > 1 ? <Pagination totalPages={totalPage} page={page} key={totalPage} onPageClick={(page) => setPage(page + 1)} /> : ""}
        </div>
      </div>
      </PlanTableWrapper>
    </>
  );
};

export default PlanTable;
