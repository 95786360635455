import React, { useEffect, useState } from "react";
import MainLayout from "../../../../assets/Layout/MainLayout";
import Switch from "react-switch";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import API from "../../../../api/axios";
import { APP_URLS } from "../../../../api/url";
import { toast } from "react-toastify";
import { EditVehicleWrapper } from "../../style/EditVehicle";
import { ProductTypes, modelType } from "../../../../config/constant";
import { handleColorImage } from "../../../../core/utils/handleImageEvents/handleColorImage";
import Toggle from "../../../Translate/components/Toggle";
import  { SelectImageEdit, SelectImageHeading } from "../../../../assets/SharedComponents/SelectImage";

const EditBrand = () => {

  const search = useLocation().search;
  const query = new URLSearchParams(search);
  
  const [colorImageWeb,_colorImageWeb] = useState('')
  const [colorImageMobile,_colorImageMobile] = useState('')

  const [colorImageValueWeb,_colorImageValueWeb] = useState('')
  const [colorImageValueMobile,_colorImageValueMobile] = useState('')
  
  const [urlColorWeb,_urlColorWeb] = useState('')
  const [urlColorMobile,_urlColorMobile] = useState('')

  const [loading,_loading] = useState(false)

  const [isPopular, _isPopular] = useState(false)
  const [isBacklink, _isBacklink] = useState(false)
  const [name, _name] = useState('');

  const [productValue, _productValue] = useState(""); 
  const [productName , _productName] = useState('');
  const { brandsId } = useParams();

  const navigate = useNavigate() 
  useEffect(()=>{
    getBrands()
  },[])

  const getBrands =() =>{
    API.get(APP_URLS.SHOW_BRANDS+`/${brandsId}`)
    .then(res => {
      const resp = res.data;
      if(resp.success===true){
        _isPopular(resp.data.brand.is_popular===1?true:false)
        _isBacklink(resp.data.brand?.back_link===1?true:false)
        _name(resp.data.brand.name)
        _productName(resp.data.brand.product_type.item_name.en)
        if(resp?.data?.brand?.media){ 
          const img1 = resp.data.brand.media[0]?.original_url;
          const img2 = resp.data.brand.media[1]?.original_url || ''
          if(resp.data.brand.media[0].custom_properties.type == 'mobile') {
            _urlColorMobile(img1)  
              _urlColorWeb(img2) 
          } else{
            _urlColorWeb(img1) 
             _urlColorMobile(img2)  
          }   
        }    
        _productValue(resp.data.brand.product_type.id)
      }
      else{
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })
    .catch(function (error) {  
      const resp = error.response;
      let error_message = '';
      if (resp.data.errors !== undefined) {
        {
          Object.keys(resp.data.errors).map(
            (error, index) => (error_message = resp.data.errors[error][0])
          );
        }
      } else if (resp.data.data?.error !== undefined) {
          error_message =  resp.data.data.error;
      } else if(resp.data?.error!== undefined){
          error_message =  resp.data.error
      } else{
          error_message =  resp.data.message
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT
      })
    });
  }

  const saveImage =(imgWeb , imgMobile,respId) =>{
    var formdata = new FormData();
    if(imgWeb) formdata.append("icon[web]",imgWeb)
    if(imgMobile) formdata.append("icon[mobile]",imgMobile)   
    formdata.append("model",modelType.brand)
    API.post(APP_URLS.ADD_ICON + `/${respId}`,formdata)
    .then(res => {
      const resp = res.data;
      if(resp.success===true){
          _colorImageWeb('')
          _colorImageValueWeb('')
          _colorImageMobile('')
          _colorImageValueMobile('')
        navigate(`/vehicles-management/vehicle-brands`);
      }
      else{
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })
    .catch(function (error) {  
      const resp = error.response;
      let error_message = '';
      if (resp.data.data !== undefined && resp.data.data !== null) {
        {
          Object.keys(resp.data.data).map(
            (error, index) =>(error_message = resp.data.data[error][0])
          );
        }
      } else if (resp.data.data?.error !== undefined) {
          error_message =  resp.data.data.error;
      } else if(resp.data?.error!== undefined){
          error_message =  resp.data.error
      } else{
          error_message =  resp.data.message
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT
      })
    });
}  
  const handleSubmit = () =>{
    let data = {}
    data["name"] = name
    data["is_popular"] = Number(isPopular)
    if(productValue == ProductTypes.car) data["back_link"] = Number(isBacklink)
    let flag = false
    if(name!==null&&name!==""){
      flag = true
    }
    if(flag===false){
      toast.error("Please provide brand name", {
        position: toast.POSITION.TOP_RIGHT
      })
    } else if(isPopular && (!(colorImageValueWeb || urlColorWeb ) || !( colorImageValueMobile || urlColorMobile ))){
      toast.error("Please provide icons", {
        position: toast.POSITION.TOP_RIGHT
      })
    } 
    else{
      if(!loading){
        _loading(true)
        API.post(APP_URLS.ADD_BRANDS+`/${brandsId}?_method=patch` , data)
        .then(res => {
            _loading(false)
            const resp = res.data;
            if(resp.success===true){
              const respId =resp.data.brand.id 
              toast.success("Brand has been updated successfully.", {
                position: toast.POSITION.TOP_RIGHT
              }) 
              if((!urlColorWeb || !urlColorMobile) && (colorImageValueWeb || colorImageValueMobile)){
                saveImage(colorImageValueWeb ,colorImageValueMobile,respId)
              } 
              navigate("/vehicles-management/vehicle-brands?"+
              (query.get("product_type_id") !== null
                ? "product_type_id=" + query.get("product_type_id") + "&"
                : "") +                    
              (query.get("keyword") !== null
                ? "keyword=" + query.get("keyword") + "&"
                : "") +
              (query.get("per_page") !== null 
                ? "per_page=" + query.get("per_page") + "&"
                : "") +
              (query.get("page") !== null
                ? "page=" + query.get("page")
                : "")
            )
            }
            else{
              toast.error(resp.message, {
                position: toast.POSITION.TOP_RIGHT
              })
            }
        })
        .catch(function (error) {  
            _loading(false)
            const resp = error.response;
            let error_message = '';
            if (resp.data.data !== undefined && resp.data.data !== null) {
              {
                Object.keys(resp.data.data).map(
                  (error, index) =>(error_message = resp.data.data[error][0])
                );
              }
            } else if (resp.data.data?.error !== undefined) {
                error_message =  resp.data.data.error;
            } else if(resp.data?.error!== undefined){
                error_message =  resp.data.error
            } else{
                error_message =  resp.data.message
            }
            toast.error(error_message, {
              position: toast.POSITION.TOP_RIGHT
            })
        });
      }
    }
  }
  

  return (
    <>
      <EditVehicleWrapper>
        <div className='mt-2' >
          <div className="backContainer ms-4" onClick={()=>navigate("/vehicles-management/vehicle-brands?"+
                (query.get("product_type_id") !== null
                  ? "product_type_id=" + query.get("product_type_id") + "&"
                  : "") +                    
                (query.get("keyword") !== null
                  ? "keyword=" + query.get("keyword") + "&"
                  : "") +
                (query.get("per_page") !== null 
                  ? "per_page=" + query.get("per_page") + "&"
                  : "") +
                (query.get("page") !== null
                  ? "page=" + query.get("page")
                  : "")
              )}>
            <span className='backButton'>x</span>
            <span className='px-3 backButton'>|</span>
            <span className='backButton'>Cancel</span>
          </div>
        </div>
        <div className="headerContainer">
          <h1>EDIT BRAND</h1>
        </div>
        <div className="row">
            <div className="d-flex justify-content-end save-btn mt-3">
            <button className={(loading?"disabled":"")+" add-button"} onClick={handleSubmit} disabled={loading}>Save</button>
            </div>

            <div className="row input-fileds align-items-center">
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label htmlFor="name" className="form-label fieldLabel">
                      Name
                    </label>
                    <input type="text" id="name" name="name" value={name}
                      className="form-control"
                      placeholder="Brand Name" onChange={(e)=>_name(e.target.value)}/>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-12 mt-3">
                  <label htmlFor="productType" className="form-label product-type fieldLabel">
                    Product Type
                  </label>
                  <input type="text" name="productType" id="productType" value={productName} className="form-control disabled" disabled />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row">
                  <Toggle  label={'Is Popular?'} checked={isPopular} handleChecked={(e) => _isPopular(e)} />   
                  { productValue == ProductTypes.car ? <Toggle  label={'Is Backlink?'} checked={isBacklink} handleChecked={(e) => _isBacklink(e)} /> : null }  
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-4">
                <div className="row">
                  <SelectImageHeading/> 
                  <div className="col-md-6 mt-2">
                    <label>For Web</label>
                    <SelectImageEdit type='web' urlColor={urlColorWeb} _urlColor={_urlColorWeb} colorImage={colorImageWeb} handleColorImage={(e)=>handleColorImage(e,_colorImageWeb,_colorImageValueWeb , 'web')} /> 
                  </div>
                  <div className="col-md-6 mt-2">
                    <label>For Mobile</label> 
                    <SelectImageEdit type='mobile' urlColor={urlColorMobile} _urlColor={_urlColorMobile} colorImage={colorImageMobile} handleColorImage={(e)=>handleColorImage(e,_colorImageMobile,_colorImageValueMobile , 'mobile')} />
                  </div>
                </div>
          </div>
          </div>
        </div>
      </EditVehicleWrapper>
    </>
  );
};

export default EditBrand;
