import styled from "styled-components";

export const CropperWrapper = styled.div`
.resetImg{
    width:25px;
    margin-right:20px;
}
@media(min-width: 796px){
    .modal-dialog {
        max-width: 750px;
        margin: 1.75rem auto;
    }
}
`;
