import React, { useEffect, useState } from 'react'
import { FaDownArrow, FaFilterIcon, FaUpArrow } from '../../../../assets/Images/icon'
import profilePic from "../../../../assets/Images/icons/profile-pic.png"
import { NavLink, useLocation, useSearchParams } from 'react-router-dom'
import { APP_URLS } from '../../../../api/url'
import { toast } from 'react-toastify'
import API from '../../../../api/axios'
import { ThreeDots } from 'react-loader-spinner'
import NoRecord from '../../../../assets/SharedComponents/Table/NoRecord'
import Pagination from '../../../../assets/SharedComponents/Pagination'
import moment from 'moment'
import RecordCount from '../../../../assets/SharedComponents/RecordCount/RecordCount'
import { Tooltip } from '@mui/material'
import * as Constant from "../../../../config/constant";
import SearchableObjDropdown from '../../../../assets/SharedComponents/SearchableDropdown/SearchableObjDropdown' 
const Validation = () => {

    const search = useLocation().search;
    const query = new URLSearchParams(search);

    const [searchParams, setSearchParams] = useSearchParams();

    const [countries, _countries] = useState(JSON.parse(localStorage.getItem('countries')));
    const [sort, _sort] = useState(query.get('sort')!==null?query.get('sort'):"-created_at");

    const [userList,_userList] = useState([])
    const [countData,_countData] = useState({})

    const [countValue, _countValue] = useState(query.get('per_page')!==null?query.get('per_page'):"");

    const [page, setPage] = useState(query.get('page')!==null?parseInt(query.get('page')):1);
    const [totalPage, _totalPage] = useState(0);
    const [total, _total] = useState(0);
    const [user, _user] = useState('') 
    const [users , _users] = useState([]); 
    const [searchKeyword , _searchkeyword] = useState('')
    const [loaded, _loaded] = useState(false);
    const [filters, _filters] = useState(false);


    useEffect(() => {
        unverifiedUsers()
    }, [page,sort,countValue, user]) 

    useEffect(()=>{ 
      const getData = setTimeout(() => {  
        getUsers();
      }, Constant.DEBOUNCE_TIME);
      return () => clearTimeout(getData); 
    },[ searchKeyword])

    useEffect(() => {
        getRecordCount()
    }, [])

    
    useEffect(()=>{
      if(searchKeyword == '') _user('')
   },[searchKeyword])
    
    const getUsers = () => {  
      API.get(APP_URLS.PENDING_VERIFICATION_USERS+ `/2?keyword=${searchKeyword}`)
      .then((res) => {
        const resp = res.data;  
        if (resp.success === true) { 
          let data = {}
          resp.data.items.forEach((element)=>{
              data[element.id] = element.name
          })
          _users(data) 
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) { 
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
    } 
    const unverifiedUsers =() =>{
        _loaded(true);
        API.get(APP_URLS.UNVALIDATED_USERS+ `?page=${page}&sort=${sort}&per_page=${countValue}&filter[user_id]=${user}`)
        .then((res) => {
          const resp = res.data;
          _loaded(false);
          if (resp.success === true) {
            _userList(resp.data.data)
            _total(resp.data.total);
            _totalPage(resp.data.last_page);
          } else {
            toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(function (error) {
            _loaded(false);
          const resp = error.response;
          let error_message = "";
          if (resp.data.data !== undefined && resp.data.data !== null) {
            {
              Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
            }
          } else if (resp.data.data?.error !== undefined) {
            error_message = resp.data.data.error;
          } else if (resp.data?.error !== undefined) {
            error_message = resp.data.error;
          } else {
            error_message = resp.data.message;
          }
          toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }

    const getRecordCount =() =>{
        API.get(APP_URLS.VALIDATION_COUNT)
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) {
            _countData(resp.data)
          } else {
            toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(function (error) {
          const resp = error.response;
          let error_message = "";
          if (resp.data.data !== undefined && resp.data.data !== null) {
            {
              Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
            }
          } else if (resp.data.data?.error !== undefined) {
            error_message = resp.data.data.error;
          } else if (resp.data?.error !== undefined) {
            error_message = resp.data.error;
          } else {
            error_message = resp.data.message;
          }
          toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }

    const handleSearchChange = (func, value) => {
      func(value);
      if (value !== "") {
        _totalPage(0)
        setPage(1);
      }
    };

    const setSort = (val) =>{
      _sort(val)
      if (sort !== val) {
        setPage(1);
        _totalPage(0)
      }
    }
  
    const clearFilter = () => {
      _filters(true)
      _user('')
      _searchkeyword('')
      _countValue('')
      _sort('-created_at')
      if (countValue !== ''||sort!=='-created_at') {
        _totalPage(0)
        setPage(1);
      }
      for(var value of query.keys()) {
        const param = searchParams.get(value);
        if (param) {
          searchParams.delete(value);
          setSearchParams(searchParams);
        }
       }
      };

  return (
            <div>
                <div className="col-md-4 offset-md-4 mb-2 pending-verification-upper">
                    <div className=" summary summaryContainer">
                        <div className="summaryContent">
                            <p>Number of pending documents</p>
                            <span className='progress-status'>{Object.keys(countData).length>0?countData['pending_count']:"-"}</span>
                        </div>
                        <div className="summaryContent">
                            <p>Number of certified users</p>
                            <span className='active-status'>{Object.keys(countData).length>0?countData['verifiedCount']:"-"}</span>
                        </div>
                        <div className="summaryContent">
                            <p>Number of rejected documents</p>
                            <span className='end-status'>{Object.keys(countData).length>0?countData['rejected_count']:"-"}</span>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-end mt-2 align-items-center gap-2">
                    <SearchableObjDropdown 
                      filters={filters} 
                      _filters={_filters} 
                      name="name" className="filterBox" 
                      setChangeValue={(val) => val?handleSearchChange(_user, val):null} 
                      placeholder="Select Name" 
                      val={user}
                      dropdownData={users}   
                      func={_searchkeyword}
                    />
                    <RecordCount
                    onSearchClick={(search) => handleSearchChange(_countValue, search)}
                    id="pageCount"
                    val = {countValue}
                    filters={filters}
                    _filters={_filters} />
                    <Tooltip title="Clear Filter" placement="bottom">
                    <FaFilterIcon className="filter-icon" role="button" onClick={clearFilter} />
                    </Tooltip>
                </div>
                <div className="d-flex align-items-center justify-content-between info-wrap">
                    <div className="justify-content-end mt-1 me-2">
                        <span className='text-start'>Total Records : <b>{total}</b></span>
                    </div>
                </div>

                <div className="tableContainer">
                    <table className="auctionTable">
                        <thead className="tableHead">
                            <tr className="table-row">
                                <th className=''>Name 
                                  <br/>
                                 Nickname
                                </th>
                                <th>Type of Verification</th>
                                <th className="time-start ">
                                    Requested at
                                    {sort === "created_at" ? (
                                        <span>
                                            <FaDownArrow className="ms-1" onClick={() => setSort("created_at")} role="button" />
                                            <FaUpArrow className="opacity-40" onClick={() => setSort("-created_at")} role="button" />
                                        </span>
                                    ) : sort === "-created_at" ? (
                                        <span>
                                            <FaDownArrow className="ms-1 opacity-40" onClick={() => setSort("created_at")} role="button" />
                                            <FaUpArrow role="button" onClick={() => setSort("-created_at")} />
                                        </span>
                                    ) : (
                                        <span>
                                            <FaDownArrow className="ms-1 opacity-40" onClick={() => setSort("created_at")} role="button" />
                                            <FaUpArrow className="opacity-40" role="button" onClick={() => setSort("-created_at")} />
                                        </span>
                                    )}
                                </th>
                            </tr>
                        </thead>
                        {loaded ? 
                        <div className='d-flex justify-content-center three-dot'>              
                            <ThreeDots
                                height="150" 
                                width="150" 
                                radius="9"
                                color="#637df4" 
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        </div>
                        :
                        <tbody>
                        {userList.length > 0 ? (
                            userList.map((item, index) => {
                                return <tr className="table-row" key={index}>
                                <td className='username'>
                                    <div className="profile-flag">
                                    <div className="profileImage">
                                        <img src={item.user?.profile_picture_url ? item.user.profile_picture_url : profilePic} />
                                    </div>
                                    {item.user.limited_address!==null ? <img className="countryflag" src={item.user.limited_address.flag_thumbnail} title={item.user.limited_address.country} /> : ""}
                                    </div>
                                    <span className='name-text'>{item.user.first_name&&item.user.last_name?`${item.user.first_name} ${item.user.last_name}`:"-"}
                                    <br/>
                                    {item?.user?.pseudo||"-"}
                                    </span>
                                </td>
                                <td>
                                    <NavLink to={`/detail-member/${item.user.id}?verify_id=VerifyId&pendingTab=validation&`+(countValue!==""?"per_page="+countValue+"&":"")+(sort!=="-created_at"?"sort="+sort+"&":"")+(page!==1?"page="+page:"")}>
                                        {item.type?Constant.NOMAD_VALIDATION_TYPE_LIST[item.type]:"Nomad Document"}
                                    </NavLink>
                                </td>
                                <td className=''>{moment(item.created_at).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm")}</td>
                            </tr>
                            }))
                            :
                            <NoRecord/>
                            }
                        </tbody>
                        }
                    </table>
                </div>
                <div>
                    {totalPage > 1 ? <Pagination totalPages={totalPage} key={totalPage} page={page} onPageClick={(page) => setPage(page + 1)} /> : ""}
                </div>
            </div>
        )
        }

export default Validation