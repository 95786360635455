import React, { useState } from 'react'
import { toast } from 'react-toastify';
import API from '../../../../api/axios';
import { APP_URLS } from '../../../../api/url';
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import moment from 'moment';

const Popup = (props) => {

  // PromoCode
  const [promoCode, _promoCode] = useState('')
  const [usageCount, _usageCount] = useState(false)
  const [usageCountValue, _usageCountValue] = useState("")
  const [expiryDate, _expiryDate] = useState(false)
  const [expiryDateValue, _expiryDateValue] = useState("")
  const [active, _active] = useState(false)

  const [loading,_loading] = useState(false)

  const savePromo = () => {
    if (usageCountValue > props.redeemVal) {
      toast.error("Promo code redeem count can not be greater than rememption limit's redeem count", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    else {
      let data = {}
      data['coupon_id'] = props.couponId
      data['code'] = promoCode
      if (usageCount === true) {
        data['max_redemptions'] = usageCountValue
      }
      if (expiryDate === true) {
        data['expires_at'] = moment(expiryDateValue).parseZone('').format("YYYY-MM-DD HH:mm:ss");
      }
      data['active'] = active
      _loading(true)
      API.post(APP_URLS.ADD_PROMO, data)
        .then((res) => {
          _loading(false)
          const resp = res.data;
          if (resp.success === true) {
            toast.success("Promo Code has been added successfully.", {
              position: toast.POSITION.TOP_RIGHT,
            });
            props.getCoupon()
            props._popup(false)
          } else {
            toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(function (error) {
          _loading(false)
          const resp = error.response;
          let error_message = "";
          if (resp.data.data !== undefined && resp.data.data !== null) {
            if(resp.data.data.message){
              error_message = resp.data.data.message
            }else{
              {
                Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
              }
            }
          } else if (resp.data.data?.error !== undefined) {
            error_message = resp.data.data.error;
          } else if (resp.data?.error !== undefined) {
            error_message = resp.data.error;
          } else {
            error_message = resp.data.message;
          }
          toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  }
  return (
    <div className="modal fade show custom-modal modal-backdrop">
      <div className="modal">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add a new promotion code</h5>
            </div>
            <div className="modal-body">
              <div className='preview'>
                <div className="col-md-12 ">
                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <label htmlFor="redemptionLimits" className="col-md-12 form-label mt-2"><b>Code</b></label>
                      <input type="text" className="form-control"
                        placeholder="FRIENDS20"
                        maxlength="10"
                        title="This code is case-insensitive and must be unique across all active promotion codes htmlFor any customer. If left blank, we will generate one automatically"
                        onChange={(e) => _promoCode(e.target.value)} />
                    </div>
                    <div className="col-md-12 form-group my-1 d-flex">
                      <input type="checkbox" className="align-self-center me-3 ms-1"
                        id="limitRangeforPromo"
                        name="limitRangeforPromo"
                        value={usageCount}
                        onChange={() => _usageCount(!usageCount)}
                      />
                      <label htmlFor="vehicle1"> Limit the number of times this code can be redeemed</label>
                    </div>
                    {usageCount === true ?
                      <div className="col-md-12 form-group my-1 d-flex align-items-center">
                        <div className="promoInput">
                          <input type="number" className="fieldBox border-right"
                            id="timesRange"
                            name="timesRange"
                            placeholder="1"
                            value={usageCountValue}
                            onChange={(e) => _usageCountValue(e.target.value)} />
                          <span className="fieldTitle mx-2">times</span>
                        </div>
                      </div>
                      : ''}
                    <div className="col-md-12 form-group my-1 d-flex">
                      <input type="checkbox" className="align-self-center me-3 ms-1"
                        id="expiryDate"
                        name="expiryDate"
                        value={expiryDate}
                        onChange={() => _expiryDate(!expiryDate)}
                      />
                      <label htmlFor="vehicle1"> Add an expiry date</label>
                    </div>
                    {expiryDate === true ?
                      <div className="col-md-12 form-group my-1 d-flex align-items-center">
                        <div className="promoInput d-flex align-items-center date-time-picker">
                          <span className="fieldTitle mx-2"> Expires at</span>
                          <Datetime
                            className="ms-1 fieldBox "
                            id="expiryDate"
                            name="expiryDate"
                            inputProps={{
                              placeholder: "Select a date and time"
                            }}
                            value={expiryDateValue}
                            isValidDate={(date)=>{
                              if(props.dateVal){
                                return date.isAfter(props.currDate)&&date.isBefore(props.dateVal)
                              }
                              else{
                                return date.isAfter(props.currDate)
                              }
                              }} 
                            onChange={(e) => _expiryDateValue(e)}
                          />
                        </div>
                      </div>
                      : ''}
                    <div className="col-md-12 form-group my-1 d-flex">
                      <input type="checkbox" className="align-self-center me-3 ms-1"
                        id="active"
                        name="active"
                        value={active}
                        onChange={() => _active(!active)}
                      />
                      <label htmlFor="limitOrdersforPromo"> Active</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end p-3">
              <button className='btn cancel-btn' onClick={() => props._popup(false)}>Cancel</button>
              <button className={(loading?"disabled":"")+" btn save-btn ms-2"} disabled={loading} onClick={() => savePromo()}>
                  {loading ? "Adding..." : "Add"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Popup