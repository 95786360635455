import styled from "styled-components";

export const LoggerWrapper = styled.div`
  .box-1{
    min-width:10%;
    font-weight:700;
  }
  .box-2{
    min-width:14%;
  }
  .box-4{
    min-width:75%;
  }
  .logText{
    width:93% !important;
    text-align:unset;
  }
  .slide-nav {
    list-style-type: none;
    margin-top: 30px;
    padding-left: 0;
    padding-right:10px;
  }
  .slide-nav li {
    list-style-type: none;
    font-size: 16px;
    line-height: 30px;
    cursor: pointer;
    font-weight: 600;
    margin: 5px 1px;
    padding: 1px 10px;
    border-radius: 3px;
    transition: all 0.35s ease-in-out;
  }
  .slide-nav li:hover {
    color: #637df4;
    background: rgba(93, 120, 255, 0.07);
  }
  .active-view {
    color: #637df4;
    background: rgba(93, 120, 255, 0.07);
  }
  .active-view a{
    text-decoration: none;
  }
  .open{
    transform: rotate(-90deg);
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  .close{
    transform: rotate(90deg);
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  .openMenu{
    transform: rotate(0deg);
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  .closeMenu{
    transform: rotate(180deg);
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  .dropbtn{
    cursor:pointer;
  }
  .dropped{
    width:100%;
    text-align:unset;
    margin-left:10px;
  }
  .btn {
    display:flex;
    align-items:center;
  }
  .btn img{
    height:22px;
  }
  .show{
    height:auto;
    opacity:100;
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  .hide{
    height:0px;
    opacity:0;
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    overflow:hidden;
  }
`;