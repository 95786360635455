import React, { useEffect, useState } from "react";
import { EditVehicleWrapper } from "../style/EditVehicle";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";

const EditCreditHistoryReasons = () => {
  const [searchParams] = useSearchParams();
  const [languages, setLanguages] = useState(JSON.parse(localStorage.getItem("languages")) || []);
  const [countries, setCountries] = useState(JSON.parse(localStorage.getItem("countries")) || []);
  const [loading, setLoading] = useState(false);
  const [names, setNames] = useState({});
  const { textId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const initializeNames = () => {
      const initialNames = languages.reduce((acc, language) => {
        acc[language.code] = "";
        return acc;
      }, {});
      setNames(initialNames);
    };

    const fetchCreditHistoryReasons = async () => {
      try {
        const res = await API.get(`${APP_URLS.SHOW_SUGGESTION_TEXT}/${textId}`);
        const resp = res.data;
        if (resp.success) {
          setNames(resp.data.suggestionText.name);
        } else {
          toast.error(resp.message, { position: toast.POSITION.TOP_RIGHT });
        }
      } catch (error) {
        const errorMessage = error.response?.data?.message || "An error occurred";
        toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT });
      }
    };

    initializeNames();
    fetchCreditHistoryReasons();
  }, [languages, textId]);

  const handleTitleChange = (value, code) => {
    setNames((prevNames) => ({ ...prevNames, [code]: value }));
  };

  const validateForm = () => {
    const enName = names["en"];
    const frName = names["fr"];
    if (!enName && !frName) {
      toast.error("Reason in English and French is mandatory", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    } else if (!enName) {
      toast.error("Please provide Reason in English", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    } else if (!frName) {
      toast.error("Please provide Reason in French", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    return true;
  };

  const submitForm = async () => {
    if (!validateForm()) return;

    setLoading(true);
    try {
      const res = await API.post(`${APP_URLS.UPDATE_SUGGESTION_TEXT}/${textId}?_method=patch`, { name: names });
      setLoading(false);
      const resp = res.data;
      if (resp.success) {
        toast.success("Reason Updated Successfully.", { position: toast.POSITION.TOP_RIGHT });
        navigate(
          `/translation/credit-history-reasons?${
            searchParams.get("name") ? `name=${searchParams.get("name")}&` : ""
          }${searchParams.get("per_page") ? `per_page=${searchParams.get("per_page")}&` : ""}${
            searchParams.get("page") ? `page=${searchParams.get("page")}` : ""
          }`
        );
      } else {
        toast.error(resp.message, { position: toast.POSITION.TOP_RIGHT });
      }
    } catch (error) {
      setLoading(false);
      const errorMessage = error.response?.data?.message || "An error occurred";
      toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT });
    }
  };

  return (
      <EditVehicleWrapper>
        <div className="mt-2">
          <div
            className="backContainer ms-4"
            onClick={() =>
              navigate(
                `/translation/credit-history-reasons?${
                  searchParams.get("name") ? `name=${searchParams.get("name")}&` : ""
                }${searchParams.get("per_page") ? `per_page=${searchParams.get("per_page")}&` : ""}${
                  searchParams.get("page") ? `page=${searchParams.get("page")}` : ""
                }`
              )
            }
          >
            <span className="backButton">x</span>
            <span className="px-3 backButton">|</span>
            <span className="backButton">Cancel</span>
          </div>
        </div>
        <div className="headerContainer">
          <h1>EDIT AD REMOVAL REASONS</h1>
        </div>
        <div className="d-flex justify-content-between save-btn mt-3">
          <p className="fieldLabel">Title</p>
          <button className={`add-button ${loading ? "disabled" : ""}`} onClick={submitForm} disabled={loading}>
            Save
          </button>
        </div>
        <div className="row input-fields">
          {languages.map((item, index) => {
            const flag = countries.find((o) => o.id === item.country_id)?.flag_thumbnail;
            return (
              <div className="col-md-4" key={index}>
                <div className="col-md-12 mt-3">
                  <label htmlFor={`title-${item.code}`} className="form-label">
                    <span className="countryIcon px-2">
                      <img src={flag} alt="" className="countryflag" title={item.name} />
                    </span>
                    <span className="langName">{item.code}</span>
                  </label>
                  <input
                    type="text"
                    id={`title-${item.code}`}
                    name={`title-${item.code}`}
                    className="form-control departmentFields"
                    value={names[item.code] || ""}
                    onChange={(e) => handleTitleChange(e.target.value, item.code)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </EditVehicleWrapper>
  );
};

export default EditCreditHistoryReasons;
