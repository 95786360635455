import styled from "styled-components";

export const ImportWrapper = styled.div`
.headerContainer {
    justify-content: center !important;
  }
  .add-depertment {
    white-space: nowrap;
    float: right;
    border: none;
    border-radius: 5px;
    background: #fbc524;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    padding: 0 20px;
    height: 35px;
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }

  .sampleFile{
    margin-top:20px;
    background: #607afc !important;
    width: 250px;
  }
`;