import React, { useEffect, useState } from "react";
import searchIcon from "../../../assets/Images/icons/faSearchLite.svg";

const SearchKey = (props) => {
  const [search, _search] = useState(props.val ? props.val : "");

  function onSearch() {
    props.onSearchClick(search);
  }

  useEffect(() => {
    if (props.filters === true) {
      _search("");
      props._filters(false);
    }
  }, [props.filters]);

  return (
    <div className={`searchBox ${props.newClass}`}>
      <input
        type={props.type ? props.type : "text"}
        placeholder={props.placeholder ? props.placeholder : "Search keyword"}
        value={search}
        onChange={(e) => {
          _search(e.target.value);
        }}
        disabled={props.disabled ?? false}
        onBlur={() => onSearch()}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            onSearch();
          }
        }}
      />
      <img src={searchIcon} alt="search" onClick={onSearch} />
    </div>
  );
};

export default SearchKey;
