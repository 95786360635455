import styled from "styled-components";

export const InviteUserWrapper = styled.div`
  .searchResult {
    top: auto !important;
    background: white;
  }
  .searchResult li {
    cursor: pointer;
  }
  .headerContainer {
    justify-content: center !important;
  }
  .checkbox-round {
    width: 0.9em;
    height: 0.9em;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #607afc;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
  }
  .required {
    color: red;
    font-size: 11px;
    line-height: 14px;
    display: inline-block;
    text-align: justify;
  }
  .countryflag {
    width: 35px !important;
    height: 35px !important;
  }

  img,
  svg {
    vertical-align: middle;
    width: 20px;
    border: 1px solid #ccc;
    border-radius: 50%;
  }
  .checkbox-round:checked {
    background-color: #607afc;
  }
  .save-btn {
    background: #fbc524 !important;
    color: white !important;
    border-radius: 5px !important;
    min-width: 130px;
    padding: 0.5rem !important;
    width: auto;
    border: none !important;
    font-weight: bold;
  }
  // .save-btn:hover {
  //   background: #7287ee;
  //   color: white !important;
  //   transition: all 0.5s ease-in-out;
  // }
  .user-invite {
    margin-top: 50px;
  }
  .invite-btn {
    margin-top: -70px !important;
  }
  .MuiAutocomplete-root {
    width:100% !important;
    padding:0px !important;
  }
  .MuiAutocomplete-input {
    padding: 0px !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border:none !important;
  }
  svg {
    border: none;
  }
  .dropdownList{
    display:flex;
    justify-content: space-between;
    padding:2px 3px;
    width:100%;
  }
  .flagField{
    height:38px
  }
  @media (max-width: 480px) {
    .btn-invite {
      text-align: center !important;
    }
  }
  @media (max-width: 370px) {
    .existing-user {
      font-size: 13px;
    }
  }

  @media (max-width: 320px) {
    .existing-user {
      font-size: 12px;
    }
  }
`;
