import React, { useEffect, useState } from "react";
import { AddSlideWrapper } from "../style/AddSlide";
import plus from "../../../../assets/Images/icons/svgIcon/plus-icon.svg";
import waste from "../../../../assets/Images/image/Waste.png";
import Popup from "./Popup"
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import moment from "moment";
import API from "../../../../api/axios";
import { APP_URLS } from "../../../../api/url";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import { calctime } from "../../../../core/utils/getAmsterdamTime/getTime";
import CropperBox from "../../../../assets/SharedComponents/ImageCropper";
import { getAmstoBrowserTime } from "../../../../core/utils/getAmstoBrowsertime/getAmstoBrowserTime";

let title = {};
let description = {};

const EditSlide = (props) => {

  const [languages, _languages] = useState(JSON.parse(localStorage.getItem("languages")));
  const [countries, _countries] = useState(JSON.parse(localStorage.getItem("countries")));

  const [loading, setLoading] = useState(false);

  const [linkToggle, _linkToggle] = useState(false)
  const [endDateToggle, _endDateToggle] = useState(false)

  const [fileUploaded, _fileUploaded] = useState(false);
  const [titleValue, _titleValue] = useState("");
  const [descValue, _descValue] = useState("");

  const [popup, _popup] = useState(false)
  const [countryArr, _countryArr] = useState([])

  const [startDate,_startDate] = useState('')
  const [endDate,_endDate] = useState('')
  
  const [newStartdate,_newStartDate] = useState('')
  const [newEndDate,_newEndDate] = useState('')

  //Image crop
  const [cropperPopup,_cropperPopup] = useState('');
  const [imageValue, _imageValue] = useState("");
  const [imgSrc, _imgSrc] =useState('') 

  const today = new Date(getAmstoBrowserTime());
  const yesterday = new Date().setDate(new Date().getDate() - 1);


  useEffect(() => {
    getSlide()
    let code = "";
    for (let i = 0; i < languages.length; i++) {
      code = languages[i].code;
      title[code] = "";
      description[code] = "";
    }
  }, [])

  const getSlide = () => {
    API.get(APP_URLS.SHOW_SLIDES + `/${props.slideID}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          setValues(resp.data.item);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp?.data?.data !== undefined) {
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        } else if (resp?.data?.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp?.data?.error !== undefined) {
          error_message = resp?.data.error;
        } else {
          error_message = resp?.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const setValues = (data) => {
    Object.keys(data.title).map((val, index) => {
      let elem = document.getElementById(val)
      if (elem !== null) {
        elem.value = data.title[val]
        title[val] = data.title[val];
      }
    })
    Object.keys(data.short_text).map((val, index) => {
      let elem = document.getElementById(val + 'desc')
      if (elem !== null) {
        elem.value = data.short_text[val]
        description[val] = data.short_text[val];
      }
    })
    _titleValue(data.title['en'])
    _descValue(data.short_text['en'])
    _fileUploaded(true);
    _imageValue({
      type: "url",
      value: data.media[0].original_url,
    })
    formik.setFieldValue('startDate', moment(new Date(data.start_date).toLocaleString('en-US', { timeZone: 'Europe/Amsterdam' })))
    _startDate(moment(data.start_date).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm:ss"))
    _newStartDate(moment(data.start_date).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm:ss"))
    if (data.end_date !== null) {
      _endDateToggle(true)
      document.getElementById("endDateCheck").checked = true
      formik.setFieldValue('endDateCheck', true)
      formik.setFieldValue('endDate', moment(new Date(data.end_date).toLocaleString('en-US', { timeZone: 'Europe/Amsterdam' })))
      _endDate(moment(data.end_date).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm:ss"))
      _newEndDate(moment(data.end_date).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm:ss"))
    }
    if (data.redirect_link !== null) {
      _linkToggle(true)
      document.getElementById("linkCheck").checked = true
      formik.setFieldValue('linkCheck', true)
      formik.setFieldValue('link', data.redirect_link)
    }
    if(data.countries!==null){
      _countryArr(data.countries)
    }
  }

  const handleTitle = (val, code) => {
    title[code] = val;
    if (code === "en") {
      _titleValue(val);
    }
  };

  const handleDesc = (val, code) => {
    description[code] = val;
    if (code === "en") {
      _descValue(val);
    }
  };


  function onSelectFile(e) {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        _imgSrc(reader.result);
      };
      reader.readAsDataURL(files[0]);
      _cropperPopup(true)
    }
    
    // Reset the file input value to allow selecting the same file again
    e.target.value = null;
  }

  const resetFile = () => {
    _fileUploaded(false);
    _imageValue("")
    let image = document.getElementById("image");
    image.src = "";
  };

  const handleClick = () => {
    props._addtab(false)
  }

  const removeType = (id) => {
    _countryArr((item) => { return item.filter((arrElem) => { return arrElem !== id }) })
  }

  const defaultValdiationSchema = {
    title: Yup.string().required(),
    description: Yup.string().required(),
    link: Yup.string().when('linkCheck', {
      is: true,
      then: Yup.string().required("Redirect Link is required")
    }),
    startDate: Yup.date()
      .required("Start Date and Time is Required")
      .when('endDateCheck', {
        is: true,
        then: Yup.date().max(Yup.ref("endDate"), "Start Date should be smaller than end date")
      }),
      endDate: Yup.date().when('endDateCheck', {
        is: true,
        then: Yup.date().required("End Date and Time is Required")
      }),
      file: "",
    }

  const startDateModifiedSchema = {
    title: Yup.string().required(),
    description: Yup.string().required(),
    link: Yup.string().when('linkCheck', {
      is: true,
      then: Yup.string().required("Redirect Link is required")
    }),
    startDate: Yup.date()
      .required("Start Date and Time is Required")
      .min(today, "Date/Time cannot be in the past").when('endDateCheck', {
        is: true,
        then: Yup.date().max(Yup.ref("endDate"), "Start Date should be smaller than end date")
      }),
      endDate: Yup.date().when('endDateCheck', {
        is: true,
        then: Yup.date().required("End Date and Time is Required")
      }),
      file: "",
    }

  const endDateModifiedSchema = {
    title: Yup.string().required(),
    description: Yup.string().required(),
    link: Yup.string().when('linkCheck', {
      is: true,
      then: Yup.string().required("Redirect Link is required")
    }),
    startDate: Yup.date()
      .required("Start Date and Time is Required").when('endDateCheck', {
        is: true,
        then: Yup.date().max(Yup.ref("endDate"), "Start Date should be smaller than end date")
      }),
      endDate: Yup.date().when('endDateCheck', {
        is: true,
        then: Yup.date().required("End Date and Time is Required").min(today, "Date/Time cannot be in the past")
      }),
      file: "",
    }

    const DateModifiedSchema = {
      title: Yup.string().required(),
      description: Yup.string().required(),
      link: Yup.string().when('linkCheck', {
        is: true,
        then: Yup.string().required("Redirect Link is required")
      }),
      startDate: Yup.date()
        .required("Start Date and Time is Required")
        .min(today, "Date/Time cannot be in the past").when('endDateCheck', {
          is: true,
          then: Yup.date().max(Yup.ref("endDate"), "Start Date should be smaller than end date")
        }),
        endDate: Yup.date().when('endDateCheck', {
          is: true,
          then: Yup.date().required("End Date and Time is Required").min(today, "Date/Time cannot be in the past")
        }),
        file: "",
      }

    const setSchema = () =>{
      if(moment(newStartdate).format("MM-DD-YYYY HH:mm:ss")!=="Invalid date"&&startDate!==moment(newStartdate).format("MM-DD-YYYY HH:mm:ss")){
        return startDateModifiedSchema
      }
      else if(moment(newEndDate).format("MM-DD-YYYY HH:mm:ss")!=="Invalid date"&&endDate!==moment(newEndDate).format("MM-DD-YYYY HH:mm:ss")){
        return endDateModifiedSchema
      }
      else if(
        moment(newStartdate).format("MM-DD-YYYY HH:mm:ss")!=="Invalid date"&&startDate!==moment(newStartdate).format("MM-DD-YYYY HH:mm:ss")&&
        moment(newEndDate).format("MM-DD-YYYY HH:mm:ss")!=="Invalid date"&&endDate!==moment(newEndDate).format("MM-DD-YYYY HH:mm:ss")
      ){
        return DateModifiedSchema
      }
      else{
        return defaultValdiationSchema
      }

    }

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      startDate: "",
      endDateCheck: false,
      endDate: "",
      linkCheck: false,
      link: "",
      file: ""
    },
    validationSchema: Yup.object(setSchema()),
    onSubmit: (values) => {
      let data = {}
      data['title'] = title
      data['short_text'] = description
      data['type'] = 0
      if (imageValue['type'] === 'base64') {
        data['image'] = imageValue.value
      }
      data['countries'] = countryArr
      if(startDate!==moment(values.startDate).format("DD-MM-YYYY HH:mm:ss")){
        data['start_date'] = moment(values.startDate).format("YYYY-MM-DD HH:mm:ss")
      }
      if (values.endDateCheck === true) {
        if(endDate!==moment(values.endDate).format("DD-MM-YYYY HH:mm:ss")){
          data['end_date'] = moment(values.endDate).format("YYYY-MM-DD HH:mm:ss")
        }
      }
      else if (values.endDateCheck === false && values.endDate !== "") {
        data['end_date'] = null
      }
      if (values.linkCheck === true) {
        data['redirect_link'] = values.link
      }
      else if (values.linkCheck === false && values.link !== "") {
        data['redirect_link'] = null
      }
      setLoading(true);
      API.post(APP_URLS.UPDATE_SLIDES + `/${props.slideID}?_method=PUT`, data)
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) {
            toast.success("Slider Updated Successfully.", {
              position: toast.POSITION.TOP_RIGHT,
            });
            props._slideID('')
            setLoading(false);
            props._addtab(false)
          } else {
            toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
          }
        })
        .catch(function (error) {
          setLoading(false);
          const resp = error.response;
          let error_message = "";
          if (resp.data.data !== undefined && resp.data.data !== null) {
            {
              Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
            }
          } else if (resp.data.data?.error !== undefined) {
            error_message = resp.data.data.error;
          } else if (resp.data?.error !== undefined) {
            error_message = resp.data.error;
          } else {
            error_message = resp.data.message;
          }
          toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    },
  });

  const submitForm = (e) => {
    let code = "";
    for (let i = 0; i < languages.length; i++) {
      code = languages[i].code;
      if (title[code] != "" && description[code] != "") {
        formik.setFieldValue("title", JSON.stringify(title));
        formik.setFieldValue("description", JSON.stringify(description));
        if (imageValue === "" || imageValue === null || imageValue === undefined) {
          toast.error("Please provide image for the slide.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        else {
          setTimeout(() => formik.handleSubmit(e), 100);
        }
        break;
      } else if (i === languages.length - 1) {
        if (imageValue === "" || imageValue === null || imageValue === undefined) {
          toast.error("Please provide image for the slide.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        else {
          formik.handleSubmit(e);
        }
      }
    }
  };
  return (
    <AddSlideWrapper>
      {popup === true ?
        <Popup _popup={_popup} countries={countries} _countryArr={_countryArr} countryArr={countryArr} />
        : ''}
      {cropperPopup === true ?
        <CropperBox aspectRatio={props.aspectRatio} _cropperPopup={_cropperPopup} cropperPopup={cropperPopup} _imgSrc={_imgSrc} imgSrc={imgSrc} _imageValue={_imageValue} _fileUploaded={_fileUploaded} fileUploaded={fileUploaded}/>
        : ''}
      <div className="preview-desktop-container">
        <div className="preview-desktop-area">
          <form className="container" onSubmit={(e) => {
            submitForm(e);
            e.preventDefault();
          }}>
            <div className="row mt-2">
              <div className="d-flex justify-content-end add-info">
                <div className="d-flex gap-2 select-info mb-2 align-items-center">
                  <button className="save-btn cancel-btn" onClick={(e) => {
                    e.preventDefault()
                    handleClick()
                    props._slideID('')
                  }}>Cancel</button>
                  <button className={(loading?"disabled":"")+" save-btn"} type="submit" disabled={loading}>{loading?'Saving...':'Save'}</button>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <p className="fieldLabel">Title</p>
            </div>
            <div className="row input-fileds">
              {languages.map((item, index) => {
                let flag = countries.find((o) => o.id === item.country_id)?.flag_thumbnail;
                let itemCode = item.code;
                return (
                  <div className="col-md-4" key={index}>
                    <div className="col-md-12 mt-3">
                      <label htmlFor="title" className="form-label">
                        <span className="countryIcon px-2">
                          <img src={flag} alt="" className="countryflag" title={item.name}/>
                        </span>
                        <span className="langName">{item.code}</span>
                      </label>
                      <input
                        type="text"
                        id={item.code}
                        name={item.code}
                        maxLength="350"
                        className="form-control departmentFields"
                        onChange={(e, code = itemCode) => {
                          handleTitle(e.target.value, code);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="d-flex justify-content-between mt-4">
              <p className="fieldLabel">Short Text</p>
            </div>
            <div className="row input-fileds">
              {languages.map((item, index) => {
                let flag = countries.find((o) => o.id === item.country_id)?.flag_thumbnail;
                let itemCode = item.code;
                return (
                  <div className="col-md-4" key={index}>
                    <div className="col-md-12 mt-3">
                      <label htmlFor="title" className="form-label">
                        <span className="countryIcon px-2">
                          <img src={flag} alt="" className="countryflag" title={item.name}/>
                        </span>
                        <span className="langName">{item.code}</span>
                      </label>
                      <input
                        type="text"
                        id={item.code + "desc"}
                        name={item.code + "desc"}
                        maxLength="255"
                        className="form-control departmentFields"
                        onChange={(e, code = itemCode) => {
                          handleDesc(e.target.value, code);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
              {formik.touched.title && formik.errors.title && formik.touched.description && formik.errors.description ? <div className="error ">Atleast one title and its description is required.</div> : null}
            </div>
            <div className="row mt-4 input-fileds">
              <div className="col-md-4 mb-3 date-time-picker">
                <label htmlFor="startDate" className="form-label product-type fieldLabel">
                  Start Date
                </label>
                <Datetime
                    className="mt-2 filed-border"
                    name="startDate"
                    id="startDate"
                    dateFormat="DD-MM-YYYY"
                    isValidDate={(date)=>{return date.isAfter(yesterday)}}
                    inputProps={{
                      readOnly:true,
                      placeholder: "Select a date and time"
                    }}
                    value={formik.values.startDate}
                    onChange={
                      (e) => {
                        formik.setFieldValue(
                          "startDate",moment(calctime(e, `+${moment().tz('Europe/Amsterdam')._offset/60}`)).toDate()
                        );
                        _newStartDate(moment(calctime(e, `+${moment().tz('Europe/Amsterdam')._offset/60}`)).toDate())
                      }
                    }
                    onBlur={formik.handleBlur}
                  />

                {formik.touched.startDate && formik.errors.startDate ? <div className="error">{formik.errors.startDate}</div> : null}
              </div>
              <div className="col-md-4 mb-3 date-time-picker">
                <div className="d-flex justify-content-between">
                  <label htmlFor="endDate" className="form-label product-type fieldLabel">
                    End Date
                  </label>
                  <label className="form-check form-switch float-right">
                    <input
                      type="checkbox"
                      name="endDateCheck"
                      id="endDateCheck"
                      className="form-check-input"
                      value={formik.values.endDateCheck}
                      onChange={(e) => {
                        formik.handleChange(e)
                        _endDateToggle(e.target.value === true || e.target.value === 'true' ? false : true)
                      }}
                      onBlur={formik.handleBlur}
                    />
                  </label>
                </div>
                <Datetime
                    className={(!endDateToggle ? "boxDisabled mt-2 filed-border" : "mt-2 filed-border")}
                    name="endDate"
                    id="endDate"
                    dateFormat="DD-MM-YYYY"
                    initialValue={formik.values.endDate}
                    value={formik.values.endDate}
                    isValidDate={(date)=>{return date.isAfter(yesterday)}}
                    inputProps={{
                      readOnly:true,
                      disabled: !endDateToggle,
                      placeholder: "Select a date and time"
                    }}
                    onChange={
                      (e) => {
                        formik.setFieldValue(
                          "endDate",moment(calctime(e, `+${moment().tz('Europe/Amsterdam')._offset/60}`)).toDate()
                        );
                        _newEndDate(moment(calctime(e, `+${moment().tz('Europe/Amsterdam')._offset/60}`)).toDate())
                      }
                    }
                    onBlur={formik.handleBlur}
                    />
                {formik.touched.endDate && formik.errors.endDate ? <div className="error">{formik.errors.endDate}</div> : null}
              </div>
              <div className="col-md-4 mb-3">
                <div className="d-flex justify-content-between">
                  <label htmlFor="link" className="form-label product-type fieldLabel">
                    Redirect Link
                  </label>
                  <label className="form-check form-switch float-right">
                    <input
                      type="checkbox"
                      name="linkCheck"
                      id="linkCheck"
                      className="form-check-input"
                      value={formik.values.linkCheck}
                      onChange={(e) => {
                        formik.handleChange(e)
                        _linkToggle(e.target.value === true || e.target.value === 'true' ? false : true)
                      }}
                      onBlur={formik.handleBlur}
                    />
                  </label>
                </div>
                <input
                  type="text"
                  name="link"
                  className={(!linkToggle ? "disabled" : "") + " form-control mt-2"}
                  disabled={!linkToggle}
                  value={formik.values.link}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur} />
                {formik.touched.link && formik.errors.link ? <div className="error">{formik.errors.link}</div> : null}
              </div>
            </div>
            <div className="row mt-4 input-fileds fileds-input">
              <div className="col-md-8 mb-3 countries">
                <div className="d-flex justify-content-between align-items-center countryheader ">
                  <span className="form-label fieldLabel ">Countries</span>
                  <button className="btn mb-2" onClick={(e) => {
                    e.preventDefault();
                    _popup(true)
                  }}>+ Add Country</button>
                </div>
                <div className="container countries-box mt-2">
                  {countryArr.length > 0 ?
                    countryArr.map((item, index) => {
                      let name = countries.find((o) => o.id === item)?.fullname;
                      return <div className="d-flex justify-content-between py-1 px-4" key={index}>
                        <span>{name}</span>
                        <img src={waste} alt="" role="button" onClick={() => removeType(item)} />
                      </div>
                    })
                    :
                    <div className="">
                      <span className="suggestion">Please add some countries...</span>
                    </div>}
                </div>
              </div>
              <div className="col-md-4 d-flex justify-content-center">
                <div className={(fileUploaded === true ? "d-none" : "") + " slide-outer d-flex justify-content-center"}>
                  <img className="add-icon" src={plus} onClick={() => document.getElementById("file").click()} />
                  <input
                    accept="image/png, image/jpeg, image/jpg"
                    multiple={false}
                    type="file"
                    id="file"
                    className="fileInput d-none"
                    onChange={(e) => {
                      onSelectFile(e);
                    }}
                  />
                </div>
                <div className={(fileUploaded === true ? "" : "d-none") + " slide-image"}>
                  <span className="resetValue status status-end" onClick={resetFile}>
                    <i className="fa fa-xmark" aria-hidden="true"></i>
                  </span>
                  <div className="d-flex justify-content-center flex-column">
                    <img src={imageValue.value} className="image" id="image" alt="search" />
                    <div className="ImagePreviewContent">
                      <span className="fw-bold header">{titleValue ? titleValue : "Title"}</span>
                      <span className="content">{descValue ? descValue : "Description"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </AddSlideWrapper>
  );
};

export default EditSlide;
