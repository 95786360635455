import React, { useEffect, useState } from 'react'
import { APP_URLS } from '../../../api/url';
import API from '../../../api/axios';
import { toast } from 'react-toastify';
import SearchableObjDropdown from '../SearchableDropdown/SearchableObjDropdown';

function BrandFilter({filters , _filters , brand , setBrand , productValue, className}) {
    const [brands, _brands] = useState([])

    useEffect(() => {
        getBrandType()
    }, [productValue]) 
  
    const getBrandType = () => {
        API.get(APP_URLS.LIST_BRANDS + `?limited_data=true${productValue ? '&filter[product_type_id]='+productValue : ''}`)
            .then((res) => {
                const resp = res.data;
                if (resp.success === true) {
                    let data = {}
                    resp.data.items.forEach((element)=>{
                        data[`${element.id}_${element.name}`] = element.name
                    })
                    _brands(data);
                } else {
                    toast.error(resp.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch(function (error) {
                const resp = error.response;
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                    Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
                } else if (resp.data.data?.error !== undefined) {
                    error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                    error_message = resp.data.error;
                } else {
                    error_message = resp.data.message;
                }
                toast.error(error_message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });
    };  
   return <SearchableObjDropdown  _filters={_filters} filters={filters} name="brand" className={className || 'filterBox brand'} setChangeValue={(val)=>setBrand(val)} val={brand} placeholder="Select Brand" dropdownData={brands} disabled={productValue === '' ? true : false}/> 

}

export default BrandFilter
