import React, { useEffect, useState } from "react";
import error from "../../../assets/Images/icons/alert-tri.svg";
import edit from "../../../assets/Images/image/edit.png";
import { TableWrapper } from "../style/table";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import Pagination from "../../../assets/SharedComponents/Pagination";
import SideTranslate from "../components/SideTranslate";
import NoRecord from "../../../assets/SharedComponents/Table/NoRecord";
import { getName } from "../../../core/utils/getName/getName";
import { Tooltip } from "@mui/material";
import { FaDownArrow, FaFilterIcon, FaUpArrow } from '../../../assets/Images/icon'
import { ThreeDots } from "react-loader-spinner";
import RecordCount from "../../../assets/SharedComponents/RecordCount/RecordCount"
import SearchKey from "../../../assets/SharedComponents/Search/SearchKey";
import StaticFilter from "../../../assets/SharedComponents/Filters/StaticFilter";

const Countries = () => {

  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const [searchParams, setSearchParams] = useSearchParams();

  const [data, _data] = useState([]);
  const [countries, setcountries] = useState(JSON.parse(localStorage.getItem("countries")));
  const [continents, setcontinents] = useState([]);
  const [languages, setlanguages] = useState(JSON.parse(localStorage.getItem("languages")));

  const [sort, _sort] = useState(query.get('sort')!==null?query.get('sort'):"-fullname->en");

  const [continent, _continent] = useState(searchParams.get('continent')!==null?parseInt(searchParams.get('continent')):"");
  const [searchkeyword, _searchkeyword] = useState(searchParams.get('name')!==null?searchParams.get('name'):"");

  const [page, setPage] = useState(searchParams.get('page')!==null?parseInt(searchParams.get('page')):1);
  const [total, _total] = useState(0);
  const [totalPage, _totalPage] = useState(0);
  const [countValue, _countValue] = useState(searchParams.get('per_page')!==null?searchParams.get('per_page'):"");

  const [filters, _filters] = useState(false);
  const [loaded, _loaded] = useState(false);

  const [userAccess,_userAccess] = useState(JSON.parse(localStorage.getItem('permissions')))

  let translationAccess = userAccess.find(o=>o.slug ==="translation")


  useEffect(() => {
    getCountries();
    getContinents();
  }, [page,countValue,continent,searchkeyword,sort]);

  const getCountries = () => {
    _loaded(true);
    API.get(APP_URLS.LIST_COUNTRIES + `?page=${page}&per_page=${countValue}&filter[fullname]=${searchkeyword}&filter[continent_id]=${continent}&sort=${sort}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _data(resp.data.data);
          _totalPage(resp.data.last_page);
          _loaded(false);
          _total(resp.data.total);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loaded(false);
        }
      })
      .catch(function (error) {
        _loaded(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getContinents = () => {
    API.get(APP_URLS.LIST_CONTINENTS+"?limited_data=true")
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          setcontinents(resp.data.items)
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleSearchChange = (func, searchValue) => {
    func(searchValue);
    if((func===_countValue&&searchValue!==countValue)||(func===_searchkeyword&&searchValue!==searchkeyword)){
      if (searchValue !== "") {
        _totalPage(0)
        setPage(1);
      }
    }
  };

  const setSort = (val) =>{
    _sort(val)
    if (sort !== val) {
      setPage(1);
      _totalPage(0)
    }
  }

  const clearFilter = () => {
    _filters(true);
    handleSearchChange(_countValue, '');
    handleSearchChange(_searchkeyword, '');
    _continent("");
    _sort('-fullname->en')
    if (continent !== "" || searchkeyword !== ""|| countValue !== ""||sort!=='-fullname->en') {
      _totalPage(0)
      setPage(1);
    }
    for(var value of query.keys()) {
      const param = searchParams.get(value);
      if (param) {
        searchParams.delete(value);
        setSearchParams(searchParams);
      }
     }
  };

  return (
    <TableWrapper>
      <div className="row">
        <div className="col-lg-2 col-md-3 col-sm-4 mt-3">
          <h4 className="translation text-center mb-3">TRANSLATION</h4>
          <SideTranslate />
        </div>
        <div className="col-lg-10 col-md-9 col-sm-8 mt-2">
          <div className="headerContainer">
            <h1>COUNTRIES</h1>
          </div>
          <div className="d-flex justify-content-end mb-2 gap-2 flex-wrap align-items-center gap-2">
            <StaticFilter placeholder='Continent' data={continents.map(item => ({ name:item.name , value : item.id}))}  handleChange={(e) =>  {
              _continent(e.target.value)
              _totalPage(0)
              setPage(1)
            }} value={continent}/>   
            <SearchKey onSearchClick={(search) => handleSearchChange(_searchkeyword,search)} 
              val={searchkeyword}
              filters={filters}
              _filters={_filters}
              />
            <RecordCount
              onSearchClick={(search) => handleSearchChange(_countValue, search)}
              id="pageCount"
              val={countValue}
              filters={filters}
              _filters={_filters} />
            <Tooltip title="Clear Filter" placement="bottom">
                <FaFilterIcon className="filter-icon" role="button" onClick={clearFilter} />
            </Tooltip>
          </div>
          <div className="d-flex align-items-center justify-content-between info-wrap">
            <div className="justify-content-end mt-1 me-2">
                <span className='text-start'>Total Records : <b>{total}</b></span>
            </div>
          </div>
          
          <div className="tableContainer">
            <table className="auctionTable">
              <thead className="tableHead">
                <tr className="table-row">
                  <th className="tableHead-1">ID</th>
                  <th className="tableHead-2">Language</th>
                  <th className="time-start ">
                    Name
                      {sort === "fullname->en" ? (
                          <span>
                              <FaDownArrow className="ms-1" onClick={() => setSort("fullname->en")} role="button" />
                              <FaUpArrow className="opacity-40" onClick={() => setSort("-fullname->en")} role="button" />
                          </span>
                      ) : sort === "-fullname->en" ? (
                          <span>
                              <FaDownArrow className="ms-1 opacity-40" onClick={() => setSort("fullname->en")} role="button" />
                              <FaUpArrow role="button" onClick={() => setSort("-fullname->en")} />
                          </span>
                      ) : (
                          <span>
                              <FaDownArrow className="ms-1 opacity-40" onClick={() => setSort("fullname->en")} role="button" />
                              <FaUpArrow className="opacity-40" role="button" onClick={() => setSort("-fullname->en")} />
                          </span>
                      )}
                  </th>
                  <th className="tableHead-3">
                    <span className="d-block">Flag</span>
                    <span>Code</span>
                  </th>
                  <th className="tableHead-6">Continent</th>
                  <th className="tableHead-6">VAT</th>
                  <th className="tableHead-4">Updated by</th>
                  <th className="tableHead-9">Action</th>
                </tr>
              </thead>
              {
            loaded ? 
            <div className='d-flex justify-content-center three-dot'>              
            <ThreeDots 
              height="150" 
              width="150" 
              radius="9"
              color="#637df4" 
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
              :
              <tbody>
                {data.length > 0 ? (
                  data.map((item, index) => {
                    let upadtedBy = getName(item.update_activity_log, item.create_activity_log);
                    return (
                      <tr className="table-row" key={index}>
                        <td className="tableHead-1">{item.id}</td>
                        <td className="tableHead-2">
                          {Object.keys(item.fullname).map((val, index) => {
                            let country_id;
                            let flag;
                            let countryValues = languages.find((o) => o.code === val);
                            if (countryValues) {
                              country_id = countryValues.country_id;
                              flag = countries.find((o) => o.id === country_id)?.flag_thumbnail;
                            }
                            return (
                              <div key={index} className="languageSelected d-flex gap-2">
                                {countryValues ? (
                                  <>
                                    <img src={flag} alt="" title={countryValues?.name}/>
                                    <span className="flagName">{val}</span>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            );
                          })}
                        </td>
                        <td className="tableHead-3">
                          {Object.keys(item.fullname).map((val, index) => {
                            let countryValues = languages.find((o) => o.code === val);
                            return item.fullname[val] !== null && item.fullname[val] !== "" ? (
                              <span key={index} className="nameTranslated">
                                {countryValues ? item.fullname[val] : ""}
                              </span>
                            ) : (
                              <span key={index} className="nameTranslated">
                                <img src={error} alt="" className="warningIcon" />
                              </span>
                            );
                          })}
                        </td>
                        <td className="tableHead-3">
                          <div className="languageSelected">
                            <img src={item.flag_thumbnail} alt="" title={item.fullname['en']}/>
                          </div>
                          <span>{item.country_name}</span>
                        </td>
                        <td className="tableHead-6">
                          {item.continent.name.en?item.continent.name.en:"-"}
                        </td>
                        <td className="tableHead-6">
                          {item.vat_rate?item.vat_rate:"-"}
                        </td>
                        <td>
                          {upadtedBy !== "" && upadtedBy !== undefined ? upadtedBy.charAt(0).toUpperCase() + upadtedBy.slice(1) : "-"}
                          </td>
                        <td className="tableHead-9">
                          {translationAccess.permissions.find((o) => o.slug === "update") ? (
                            <NavLink to={`/translation/edit-country/${item.id}?`+(countValue!==""?"per_page="+countValue+"&":"")+(continent!==""?"continent="+continent+"&":"")+(searchkeyword!==""?"name="+searchkeyword+"&":"")+(sort!=="-fullname->en"?"sort="+sort+"&":"")+(page!==1?"page="+page:"")}>
                              <img src={edit} alt="" className="mx-2" role="button" title="Edit" />
                            </NavLink>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <NoRecord />
                )}
              </tbody>
          }
            </table>
          </div>
            <div>
              {totalPage > 1 ? <Pagination totalPages={totalPage} key={totalPage} page={page} onPageClick={(page) => setPage(page + 1)} /> : ""}
            </div>
          
        </div>
      </div>
    </TableWrapper>
  );
};

export default Countries;
