import styled from "styled-components";

export const AddWrapper = styled.div`
.headerContainer {
    justify-content: center !important;
}
.add-button {
    white-space: nowrap;
    float: right;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    padding: 0px 20px;
    height: 35px;
    transition: all 0.35s ease-in-out 0s;
    border: none !important;
    background: rgb(251, 197, 36) !important;
    color: rgb(255, 255, 255) !important;
}
.demo-editor {
    height: 275px !important;
    border: 1px solid #F1F1F1 !important;
    padding: 5px !important;
    border-radius: 2px !important;
}
.demo-wrapper {
    width: 100% !important;
    display: block !important;
    margin-bottom: 25px !important;
    height: 400px !important;
}
a {
    text-decoration: none;
    color: black;
}
.startDate input{
    background-color:white;
    border: 1px solid #F1F1F1 !important;
}
`;