import styled from "styled-components";

export const CreateLanguageWrapper = styled.div`
  .headerContainer {
    justify-content: center !important;
  }
  .add-depertment {
    white-space: nowrap;
    float: right;
    border: none !important;
    border-radius: 5px;
    background: #fbc524 !important;
    color: #fff !important;
    font-weight: 500;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    padding: 0 20px;
    height: 35px;
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .MuiAutocomplete-root{
    width:100% !important;
  }
  .MuiOutlinedInput-root .MuiAutocomplete-input {
    border:none !important;
    padding:0px 0px 0px 5px !important;
  }
`;
