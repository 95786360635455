import styled from "styled-components";

export const RoleListWrapper = styled.div`
  .userDetails{
    width:75%;
  }
  .messageDetails{
    width:100%;
  }
  .w-250{
    width: 220px;
  }
  .w-150{
    width: 150px;
  }
  .name-text {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    text-align: left;
    width: 150px;
  }
  .message-text {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width:150px
  }
  .dropbtn{
    cursor:pointer;
  }
  .dropped{
    width:100%;
    text-align:unset;
    margin-left:10px;
  }
  .open{
    transform: rotate(-90deg);
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  .close{
    transform: rotate(90deg);
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  .add-info {
    flex-wrap: wrap;
  }
  .clipboard{
    width:200px !important;
  }
  .copyText{
    display: inline-block;
    width: 120px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  .extract-report{
    font-weight: 600;
    cursor: pointer;
    text-transform: none;
    color: black; 
    border: 1px solid rgba(0,0,0,.2);
    height: 32px;
    &:hover{
        outline:none;
    }
}
  @media (max-width: 475px) {
    .data-button {
      margin-right: 15px;
    }
  @media (max-width: 467px) {
    .data-button {
      margin-right: 15px;
    }
    .add-info {
     justify-content: center !important;
    }
`;
