import styled from "styled-components";

export const RoleListWrapper = styled.div`
  .action button {
    background: white;
    border: none;
    cursor: pointer;
  }
  .btn {
    color: white;
  }
  .description {
    width: 300px;
  }
  .add-info {
    flex-wrap: wrap;
  }
  @media (max-width: 475px) {
    .data-button {
      margin-right: 15px;
    }
  @media (max-width: 467px) {
    .data-button {
      margin-right: 15px;
    }
    .add-info {
     justify-content: center !important;
    }
`;
