import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import API from '../../../../api/axios';
import { APP_URLS } from '../../../../api/url';
import moment from "moment";
import * as Constant from "../../../../config/constant";


const Validation = (props) => {

    const [data, _data] = useState([]);
    const [mediaData, _mediaData] = useState([]);
    const [loading,_loading] = useState(false)
  
    const [suggestionTexts, _suggestionTexts] = useState({});
    const [suggestionLoaded, _suggestionLoaded] = useState(false);
  
    const [commentsArr,_commentsArr] = useState([])
    const [collectionName,_collectionName] = useState('')
  
    const [docType,_docType] = useState('')
    const [docName,_docName] = useState('')
  
    const [actionID,_actionID] = useState('')
  
    const permission = props.validateUser.permissions.find((o)=>o.slug==='update')

    useEffect(() => {
      getUserDetails();
      getSuggestiontext();
    }, []);

  
    const getUserDetails = () => {
      API.get(
        APP_URLS.VIEW_USER_VALIDATION_STATUS +
          `/${props.userId}/validation_status`
      )
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) {
            _data(resp.data.validation);
            _mediaData(resp.data.media)
            props.setHeaderData(resp.data.validation)
            if(resp.data.media.length>0){
              props.setHeaderData(oldData=>{
                return [...oldData,{
                  'created_at': resp.data.media[0].created_at,
                  'type':3,
                  'value':resp.data.media[0].custom_properties,
                }]
              })
            }
          } else {
            toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(function (error) {
          const resp = error.response;
          let error_message = "";
          if (resp.data.errors !== undefined) {
            {
              Object.keys(resp.data.errors).map(
                (error, index) => (error_message = resp.data.errors[error][0])
              );
            }
          } else if (resp.data.data?.error !== undefined) {
            error_message = resp.data.data.error;
          } else if (resp.data?.error !== undefined) {
            error_message = resp.data.error;
          } else {
            error_message = resp.data.message;
          }
          toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    };
  
    const getSuggestiontext = () => {
      API.get(APP_URLS.LIST_SUGGESTION_TEXT + `?limited_data=true&filter[type]=2`)
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) {
            let data ={}
            resp.data.items.forEach(element => {
              data [element.id]= element.name
            });
            _suggestionTexts(data);
            _suggestionLoaded(true)
          } else {
            toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(function (error) {
          const resp = error.response;
          let error_message = "";
          if (resp.data.data !== undefined && resp.data.data !== null) {
            {
              Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
            }
          } else if (resp.data.data?.error !== undefined) {
            error_message = resp.data.data.error;
          } else if (resp.data?.error !== undefined) {
            error_message = resp.data.error;
          } else {
            error_message = resp.data.message;
          }
          toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    };
  
    const action = (status,id,type) =>{
      let values = {}
      values['status'] = status
      values['type'] = type
      values['id'] = id
      if(status===0){
        values['refused_comment'] = commentsArr
      }
      if(!loading){
        _loading(true)
        API.post(APP_URLS.UPDATE_USER_VALIDATION_STATUS+"/update/validation_status?_method=PATCH", values)
        .then((res) => {
            const resp = res.data;
            if (resp.success === true) {
            toast.success(`Verfication ${status===1?"Accepted":"Rejected"} Successfully.`, {
                position: toast.POSITION.TOP_RIGHT,
            });
            _collectionName('')
            getUserDetails()
            _loading(false)

            } else {
            toast.error(resp.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            _loading(false)
            }
        })
        .catch(function (error) {
            const resp = error.response;
            _loading(false)
            let error_message = "";
            if (resp.data.data !== undefined && resp.data.data !== null) {
            {
                Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
            }
            } else if (resp.data.data?.error !== undefined) {
            error_message = resp.data.data.error;
            } else if (resp.data?.error !== undefined) {
            error_message = resp.data.error;
            } else {
            error_message = resp.data.message;
            }
            toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT,
            });
        });
      }
    }
  
    const addcomments = (id) =>{
      let flag = false
      for (let i=0;i<commentsArr.length;i++){
        if(commentsArr[i]===id){
          flag= true
          break;
        }
      }
      if(flag===false){
        _commentsArr((item)=>{
          return [...item,id]
        })
      }else{
        toast.error("Comment has been already added", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
  
    }
  
    const removeComment = (id) =>{
      _commentsArr((item)=>{
        return item.filter((arrItem,index)=>{
          return index !== id
        })
      })
    }


  return (
    <div className="container">
    <div className="row">
      <div className={actionID?"col-lg-7 col-md-12":"col-lg-12 col-md-12"}>
        <div className="tableContainer">
          <table className="auctionTable">
            <thead className="tableHead">
            <tr className="table-row">
                <th>DATE</th>
                <th>VALIDATION TYPE</th>
                <th className='w15'>CONTENT</th>
                <th>STATUS</th>
                <th className='w20'>COMMENT</th>
                <th>VERIFY BY</th>
            </tr>
            </thead>
            <tbody>
              {data.map((item, index) => {
                  let verifiedBy 
                  if(item.verifiedByUser!==null&&item.verifiedByUser!==undefined){
                      if(item.verifiedByUser.first_name||item.verifiedByUser.last_name){
                          verifiedBy = `${item?.verifiedByUser.first_name!==null?item?.verifiedByUser.first_name:''} ${item?.verifiedByUser.last_name!==null?item?.verifiedByUser.last_name:''}`
                      }else{
                          verifiedBy = item?.verifiedByUser.pseudo
                      }
                      verifiedBy = verifiedBy.charAt(0).toUpperCase() + verifiedBy.slice(1)
                  }
                return (
                  <tr className="table-row" key={index}>
                    <td>{moment(item.created_at).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm")}</td>
                    <td className="">
                      {Constant.NOMAD_VALIDATION_TYPE_LIST[item.type]}
                    </td>
                    <td>
                        {item.type===0?props.country+" "+item.value.value:item.value.value}
                    </td>
                    <td>
                      {item.value.accepted_at?
                      <span className="status status-active" title='Verified'>
                          <i className="fa fa-check" aria-hidden="true"></i>
                      </span>:
                      item.value.refused_at?
                      <span className="status status-end" title='Rejected'>
                      </span>:
                      <span className="status status-progress" title='Pending'>
                      </span>
                      }
                    </td>
                    <td className={(loading?"disabled":"")+" w20"}>
                      {item.value.accepted_at===null&&item.value.refused_at===null&&item.type!==0 ? (
                        <>
                          <button className={(permission===undefined||loading?"disabled":"")+" reject"} onClick={()=>{
                            _actionID(item.id)
                            _collectionName(item.type)
                            _docType(Constant.NOMAD_VALIDATION_TYPE_LIST[item.type])
                            _docName(Constant.NOMAD_VALIDATION_TYPE_LIST[item.type])
                            }}
                            disabled={permission===undefined||loading}> REJECT </button>
                          <button className={(permission===undefined||loading?"disabled":"")+" accept"} onClick={()=>{
                              _collectionName(item.type)
                              _actionID('')
                              action(1,item.id,item.type)
                              }}
                              disabled={permission===undefined||loading}> ACCEPT </button>
                        </>
                      ) : (
                        item.value.refused_comment!==null && suggestionLoaded===true ?
                        item.value.refused_comment.map((item,index)=>{
                          return <span className="d-flex justify-content-center align-items-center suggestion" key={index}>
                            {suggestionTexts[item].charAt(0).toUpperCase() + suggestionTexts[item].slice(1)}
                            </span>
                        }):''
                      )}
                    </td>
                    <td>
                      <p>
                        {verifiedBy}
                        <br />
                        <span className="date">{item.value.accepted_at!==null?moment(item.value.accepted_at).format("DD-MM-YYYY HH:mm"):item.value.refused_at!==null?moment(item.value.refused_at).format("DD-MM-YYYY HH:mm"):'-'}</span>
                      </p>
                    </td>
                  </tr>
                );
              })}
              {mediaData.length>0?
              mediaData.map((item, index) => {
                  let verifiedBy 
                  if(item.verifiedByUser!==null&&item.verifiedByUser!==undefined){
                      if(item.verifiedByUser.first_name||item.verifiedByUser.last_name){
                          verifiedBy = `${item?.verifiedByUser.first_name!==null?item?.verifiedByUser.first_name:''} ${item?.verifiedByUser.last_name!==null?item?.verifiedByUser.last_name:''}`
                      }else{
                          verifiedBy = item?.verifiedByUser.pseudo
                      }
                      verifiedBy = verifiedBy.charAt(0).toUpperCase() + verifiedBy.slice(1)
                  }
                return (
                  item.collection_name!=="driving_license_back"?
                  <tr className="table-row" key={index}>
                    <td>{moment(item.created_at).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm")}</td>
                    <td className="link" onClick={() => window.open(item.original_url, "_blank")}>
                      Nomad Document
                    </td>
                    <td>
                        {item.file_name}
                    </td>
                    <td>
                      {item.custom_properties.accepted_at?
                      <span className="status status-active" title='Verified'>
                          <i className="fa fa-check" aria-hidden="true"></i>
                      </span>:
                      item.custom_properties.refused_at?
                      <span className="status status-end" title='Rejected'>
                      </span>:
                      <span className="status status-progress" title='Pending'>
                      </span>
                      }
                    </td>
                    <td className={(loading?"disabled":"")+" w20"}>
                      {item.custom_properties.accepted_at===null&&item.custom_properties.refused_at===null ? (
                        <>
                          <button className={(permission===undefined||loading?"disabled":"")+" reject"} onClick={()=>{
                            _actionID(item.id)
                            _collectionName(3)
                            _docType("Nomad Document")
                            _docName("Nomad Document")
                            }}
                            disabled={permission===undefined||loading}> REJECT </button>
                          <button className={(permission===undefined||loading?"disabled":"")+" accept"} onClick={()=>{
                              _collectionName(3)
                              _actionID('')
                              action(1,item.id,3)
                              }}
                              disabled={permission===undefined||loading}> ACCEPT </button>
                        </>
                      ) : (
                        item.custom_properties.refused_comment!==null && suggestionLoaded===true ?
                        item.custom_properties.refused_comment.map((item,index)=>{
                          return <span className="d-flex justify-content-center align-items-center suggestion" key={index}>
                            {suggestionTexts[item].charAt(0).toUpperCase() + suggestionTexts[item].slice(1)}
                            </span>
                        }):''
                      )}
                    </td>
                    <td>
                      <p>
                        {verifiedBy}
                        <br />
                        <span className="date">{item.custom_properties.accepted_at!==null?moment(item.custom_properties.accepted_at).format("DD-MM-YYYY HH:mm"):item.custom_properties.refused_at!==null?moment(item.custom_properties.refused_at).format("DD-MM-YYYY HH:mm"):'-'}</span>
                      </p>
                    </td>
                  </tr>
                  :''
                );
              })
            :""}
            </tbody>
          </table>
        </div>
      </div>
      <div className={(actionID?"d-block":"d-none")+" col-lg-4 offset-lg-1 col-md-12"}>
        <div className="card ">
          <h3>REJECTED</h3>
          <div className="cardHeader">
            <button className="cancel" onClick={()=>{
              _actionID('')
              _collectionName('')
              _commentsArr([])
              _docName('')
              _docType('')
              }}>CANCEL</button>
            <button className={(commentsArr.length>0||loading?"":"disabled")+" save"} disabled={commentsArr.length>0||loading?false:true} onClick={()=>{
              setTimeout(()=>action(0,actionID,collectionName), 1000);
              _commentsArr([])
              _actionID('')
              _docName('')
              _docType('')
            }}>SEND</button>
          </div>
          <div className="content">
            <div className="row">
              <div className="col-4">
                <span>{docType}</span>
              </div>
              <div className="col-4 d-flex justify-content-center text-center">
                <span className="link defaultCursor">
                  {(
                    docName.split("_")[0]==="driving"?"Driving License Front and Back":
                    (docName.charAt(0).toUpperCase()+docName.slice(1)).replaceAll("_"," ")
                  )}
                </span>
              </div>
              <div className="col-4 d-flex justify-content-end">
                <span className="status status-end"></span>
              </div>
            </div>
            <div className="cardContent">
              {Object.keys(suggestionTexts).length>0?
              Object.keys(suggestionTexts).map((item,index)=>{
                  return <span className="comments" key={index} onClick={()=>addcomments(item)}>{suggestionTexts[item]}</span>
              }):<span className="disabledcomment">Please add some suggestion texts</span>}
            </div>
            <div className="cardFooter">
              {commentsArr.length>0?
              commentsArr.map((item,index)=>{
                return <div className="d-flex align-items-center ps-3 py-1" key={index}>
                  <span className="pe-2">{suggestionTexts[item]}</span>
                  <span className="removeComment" onClick={()=>removeComment(index)}>
                    x
                  </span>
                </div> 
              }):<div className="d-flex align-items-center ps-3 py-1" >
              <span className="pe-2 suggestion">Please add some comments...</span>
            </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>

  )
}

export default Validation