import React from "react";
import NoRecord from "../../../../assets/SharedComponents/Table/NoRecord";

const FeaturesTable = ( {features} ) => {
  return (
    <>
            <div className="tableContainer">
              <table className="auctionTable">
                  <thead className="tableHead">
                      <tr className="table-row">
                        <th>ID</th>
                        <th className="name">NAME</th>
                        <th className="description">DESCRIPTION</th>
                        <th>Slug</th>
                      </tr>
                  </thead>
                  <tbody>
                    {features.length>0?
                      features.map((item,index)=> {
                      return <tr className="table-row" key={index}>
                        <td>{item.id}</td>
                        <td className="name">{(item.name['en'].charAt(0).toUpperCase()+item.name['en'].slice(1)).replace("_"," ")}</td>
                        <td className="description">{item.description['en']}</td>
                        <td>{item.slug}</td>
                      </tr>
                      })
                      :
                      <NoRecord/>}
                  </tbody>
              </table>
            </div>
    </>
  );
};

export default FeaturesTable;
