import styled from "styled-components";

export const ChangePasswordWrapper = styled.div`
  .save-btn {
    background: #fbc524 !important;
    color: #fff !important;
    border-radius: 6px !important;
    min-width: 130px;
    padding: 0.5rem !important;
    width: auto;
    font-weight: bold;
    border: none;
  }
  .save-btn:hover {
    color: white !important;
    transition: all 0.5s ease-in-out;
  }
  .required {
    color: red;
    font-size: 11px;
    line-height: 14px;
    display: inline-block;
    text-align: justify;
    margin-left: 20px;
  }
`;
