import React, { useEffect, useState } from "react";
import MainLayout from "../../../assets/Layout/MainLayout";
import { CreateTaxRateWrapper } from "./style/createTaxRate";
import Switch from "react-switch";
import { useFormik } from "formik";
import * as Yup from "yup";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SearchableDropdown from "../../../assets/SharedComponents/SearchableDropdown/SearchableDropdown";


const CreateTaxRates = () => {

  const [loading,_loading]= useState(false)
  const [country,_country]= useState('')

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      inclusive: true,
      percentage: "",
      taxType: "",
      country_id: "",
      status: true,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Tax Rate Name can't be blank"),
      description: Yup.string().required("Tax Rate Description can't be blank"),
      country_id: Yup.string().required("Please select Country from dropdown"),
      taxType: Yup.string().required("Please select Tax Type"),
      inclusive: Yup.string().required("Please select Inclusive"),
      percentage: Yup.number().required("Tax percentage can't be blank").min(0,"Minimum percentage can't be less than 0").max(100,"Minimum percentage can't be more than 100"),
      status: Yup.string().required("Choose status for tax rate"),
    }),
    onSubmit: (values) => {
      const data = {}
      data['display_name'] = values.name
      data['description'] = values.description
      data['inclusive'] = values.inclusive==='true'||values.inclusive===true?true:false
      data['percentage'] = values.percentage
      data['country_id'] = values.country_id
      data['tax_type'] = values.taxType
      data['active'] = values.status==='true'||values.status===true?true:false
      if(!loading) {
          _loading(true)
            API.post(APP_URLS.CREATE_TAX_RATE, data)
            .then(res => {
                const resp = res.data;
                _loading(false)
                if(resp.success===true){
                  toast.success("Tax Rate has been added successfully.", {
                    position: toast.POSITION.TOP_RIGHT
                  })
                  formik.resetForm()
                  navigate("/tax-rates")
                }
                else{
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT
                  })
                }
            })
            .catch(function (error) {  
                const resp = error.response;
                _loading(false)
                let error_message = '';
                if (resp.data.data !== undefined && resp.data.data !== null) {
                  if(resp.data.data.message){
                    error_message = resp.data.data.message
                  }else{
                    {
                      Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
                    }
                  }
                } else if (resp.data.data?.error !== undefined) {
                    error_message =  resp.data.data.error;
                } else if(resp.data?.error!== undefined){
                    error_message =  resp.data.error
                } else{
                    error_message =  resp.data.message
                }
                toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT
                })
            });       
        }
    },
  });

  const setChangeValue = (val) =>{
    formik.setFieldValue("country_id",val)
    _country(val)
  }

  return (
    <>
      <CreateTaxRateWrapper>
        <div className="mt-2">
          <div className="backContainer ms-4" onClick={() => navigate("/tax-rates")}>
            <span className="backButton">x</span>
            <span className="px-3 backButton">|</span>
            <span className="backButton">Cancel</span>
          </div>
        </div>
        <div className="headerContainer">
          <h1>CREATE TAX RATE</h1>
        </div>
        <form className="row" onSubmit={formik.handleSubmit}>
          <div className="col-md-8 offset-md-2">
            <div className="d-flex justify-content-end save-btn mt-2">
              <button className={(loading?"disabled":"")+" add-depertment"} disabled={loading} type="submit">
                {loading ? "Saving..." : "Save"}
              </button>
            </div>

            <div className="row">
              <div className="col-md-6">
                <label htmlFor="name" className="form-label fieldLabel">
                  Display Name
                </label>
                <input type="text" id="name" name="name" className="form-control" placeholder="Display Name" value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                <div className="errorContainer">
                  {formik.touched.name && formik.errors.name ? <div className="error">{formik.errors.name}</div> : null}
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="code" className="form-label fieldLabel">
                  Description
                </label>
                <input type="text" id="description" name="description" className="form-control" placeholder="Description" value={formik.values.description} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                <div className="errorContainer">
                  {formik.touched.description && formik.errors.description ? <div className="error">{formik.errors.description}</div> : null}
                </div>
              </div>
            </div>
            
            <div className="row mt-2">
              <div className="col-md-6">
                <label htmlFor="percentage" className="form-label fieldLabel">
                  Tax Rate<span className="ms-1 percentage">(%)</span>
                </label>
                <input type="number" id="percentage" name="percentage" min={0} max={100} className="form-control" placeholder="10.00" step={".01"} value={formik.values.percentage} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                <div className="errorContainer">
                  {formik.touched.percentage && formik.errors.percentage ? <div className="error">{formik.errors.percentage}</div> : null}
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="taxType" className="form-label fieldLabel">
                  {/* Inclusive */}
                </label>
                <select name="inclusive" id="inclusive" className="form-control" value={formik.values.inclusive} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                  <option value="true">Inclusive</option>
                  <option value="false">Exclusive</option>
                </select>
                <div className="errorContainer">
                  {formik.touched.inclusive && formik.errors.inclusive ? <div className="error">{formik.errors.inclusive}</div> : null}
                </div>

              </div>
            </div>

            <div className="row mt-2">
              <div className="col-md-6">
                <label htmlFor="taxType" className="form-label fieldLabel">
                  Tax type
                </label>
                <select name="taxType" id="taxType" className="form-control select-checked" value={formik.values.taxType} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                  <option hidden>Select</option>
                  <option value="vat">VAT</option>
                  <option value="gst">GST</option>
                  <option value="hst">HST</option>
                  <option value="pst">PST</option>
                  <option value="qst">QST</option>
                  <option value="rst">RST</option>
                  <option value="jct">JCT</option>
                  <option value="sales_tax">Sales Tax</option>
                </select>
                <div className="errorContainer">
                  {formik.touched.taxType && formik.errors.taxType ? <div className="error">{formik.errors.taxType}</div> : null}
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="country_id" className="form-label fieldLabel">
                  Country
                </label>
                <SearchableDropdown name="country_id" className="form-control" setChangeValue={(val)=>val?setChangeValue(val):null} val={country} placeholder="Select Country" dropdownData={JSON.parse(localStorage.getItem("countries"))}/>
                <div className="errorContainer">
                  {formik.touched.country_id && formik.errors.country_id ? <div className="error">{formik.errors.country_id}</div> : null}
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-md-6">
                <label htmlFor="taxType" className="form-label fieldLabel">
                  Status
                </label>
                <select name="status" id="status" className="form-control" value={formik.values.status} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>
                <div className="errorContainer">
                  {formik.touched.status && formik.errors.status ? <div className="error">{formik.errors.status}</div> : null}
                </div>
              </div>
            </div>
          </div>
        </form>
      </CreateTaxRateWrapper>
    </>
  );
};

export default CreateTaxRates;
