import React, { useEffect, useState } from "react";
import error from "../../assets/Images/icons/alert-tri.svg";
import edit from "../../assets/Images/image/edit.png";
import waste from "../../assets/Images/image/Waste.png";
import { TableWrapper } from "./style/table";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import API from "../../api/axios";
import { APP_URLS } from "../../api/url";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import Pagination from "../../assets/SharedComponents/Pagination";
import NoRecord from "../../assets/SharedComponents/Table/NoRecord";
import { getName } from "../../core/utils/getName/getName";
import { Tooltip } from "@mui/material";
import { FaFilterIcon } from "../../assets/Images/icon";
import { ThreeDots } from "react-loader-spinner";
import RecordCount from "../../assets/SharedComponents/RecordCount/RecordCount";
import * as Constant from "../../config/constant";


const Faqs = () => {

  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const [searchParams, setSearchParams] = useSearchParams();

  const [data, _data] = useState([]);
  const [countries, setcountries] = useState(JSON.parse(localStorage.getItem("countries")));
  const [languages, setlanguages] = useState(JSON.parse(localStorage.getItem("languages")));
  const [dataDeleted, _dataDeleted] = useState(false);

  const [type, _type] = useState(searchParams.get('type')!==null?searchParams.get('type'):"");
  const [countValue, _countValue] = useState(searchParams.get('per_page')!==null?searchParams.get('per_page'):"");
  const [trashed, _trashed] = useState(searchParams.get('trashed')!==null?(searchParams.get('trashed')==='false'?false:true):true);

  const [page, setPage] = useState(searchParams.get('page')!==null?parseInt(searchParams.get('page')):1);
  const [total, _total] = useState(0);
  const [totalPage, _totalPage] = useState(0);

  const [filters, _filters] = useState(false);
  const [loaded, _loaded] = useState(false);

  const [userAccess,_userAccess] = useState(JSON.parse(localStorage.getItem('permissions')))
  
  let faqAccess = userAccess.find(o=>o.slug ==="faq")

  useEffect(() => {
    getBehaviortext();
    _dataDeleted(false);
  }, [dataDeleted, page,countValue,trashed,type]);

  const getBehaviortext = () => {
    _loaded(true);
    API.get(APP_URLS.LIST_FAQS + `?page=${page}&per_page=${countValue}&with_trashed=${trashed===true?1:0}&filter[app_type]=${type}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _data(resp.data.data);
          _total(resp.data.total);
          _totalPage(resp.data.last_page);
          _loaded(false);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loaded(false);
        }
      })
      .catch(function (error) {
        _loaded(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const removeType = (index) => {
    confirmAlert({
      title: "Confirm to remove",
      message: `Are you sure you want to remove?`,
      closeOnEscape: true,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            API.delete(APP_URLS.DELETE_FAQS + `/${index}`)
              .then((res) => {
                const resp = res.data;
                if (resp.success === true) {
                  _dataDeleted(true);
                  toast.success("FAQ has been deleted successfully.", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                } else {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              })
              .catch(function (error) {
                const resp = error.response;
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                  {
                    Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
                  }
                } else if (resp.data.data?.error !== undefined) {
                  error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                  error_message = resp.data.error;
                } else {
                  error_message = resp.data.message;
                }
                toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          },
        },
        {
          label: "No",
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-custom-class-name",
    });
  };

  const handleSearchChange = (searchValue) => {
    _countValue(searchValue);
    if (searchValue !== ""&&searchValue!==countValue) {
      _totalPage(0)
      setPage(1);
    }
  };

  const clearFilter = () => {
    _filters(true)
    handleSearchChange('');
    _trashed(true)
    _type('')
    if (countValue !== ''||type !== ''||trashed !== true) {
      _totalPage(0)
      setPage(1);
    }
    for(var value of query.keys()) {
      const param = searchParams.get(value);
      if (param) {
        searchParams.delete(value);
        setSearchParams(searchParams);
      }
     }
  };


  //Returns Textb from HTML
  const QuestionText = ( data ) =>{
    let span = document.createElement('span');
    span.innerHTML = data;
    return span.textContent || span.innerText;
  }

  return (
    <>
      <TableWrapper>
        <div className="row">
          <div className="col-lg-12 mt-2">
            <div className="headerContainer mb-3">
              <h1>FAQs</h1>
            </div>
            <div className="d-flex justify-content-between add-info">
              <div className="add-depertment d-flex justify-content-end mb-2">
                {faqAccess?.permissions.find((o) => o.slug === "add") ? (
                  <div>
                    <NavLink to="/create-faqs">
                      <button className="btn">+ Add</button>
                    </NavLink>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex justify-content-end mb-2 gap-2 flex-wrap align-items-center gap-2">
                <select
                  name="productType"
                  id="productType"
                  className="filterBox"
                  onChange={(e) => {
                    setPage(1);
                    _totalPage(0);
                    _type(e.target.value);
                  }}
                  value={type}
                >
                  <option hidden>Select FAQ Type</option>
                  <option value="">Select FAQ Type</option>
                  <option value="2">Front Office</option>
                  <option value="3">Nomad</option>
                </select>
                <RecordCount
                  onSearchClick={(search) => handleSearchChange(search)}
                  val={countValue}
                  id="pageCount"
                  filters={filters}
                  _filters={_filters} />
                <Tooltip title="Clear Filter" placement="bottom">
                  <FaFilterIcon className="filter-icon" role="button" onClick={clearFilter} />
                </Tooltip>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between info-wrap">
              <div className="justify-content-end mt-1 me-2">
                  <span className='text-start'>Total Records : <b>{total}</b></span>
              </div>
              <div className="mt-1">
              <input type="checkbox" checked={!trashed} onChange={()=>{
                _totalPage(0)
                setPage(1);
                _trashed(!trashed)
                }}/>
              <span className="ms-2">Hide Deleted Data</span>
            </div>
            </div>
              
                <div className="tableContainer">
                <table className="auctionTable">
                  <thead className="tableHead">
                    <tr className="table-row">
                      <th className="tableHead-1">ID</th>
                      <th className="tableHead-2">Language</th>
                      <th className="tableHead-3">Question</th>
                      <th className="tableHead-3">App Type</th>
                      <th className="tableHead-4">Updated by</th>
                      <th className="tableHead-5">Created by</th>
                      {faqAccess?.permissions.find((o) => o.slug === "update") || faqAccess?.permissions.find((o) => o.slug === "delete") ? <th className="tableHead-9">Action</th> : ""}
                    </tr>
                  </thead>
              {
              loaded ? 
                <div className='d-flex justify-content-center three-dot'>              
                    <ThreeDots 
                      height="150" 
                      width="150" 
                      radius="9"
                      color="#637df4" 
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                </div>
                : 
                  <tbody>
                    {data.length > 0 ? (
                      data.map((item, index) => {
                        let createdBy = getName(item.create_activity_log, item.update_activity_log);
                        let upadtedBy = getName(item.update_activity_log, item.create_activity_log);
                        return <tr className={(item.deleted_at ? "deletedRow" : "") + " table-row"} key={index}>
                        <td className="tableHead-1">{item.id}</td>
                        <td className="tableHead-2">
                            {Object.keys(item.question).map((val, index) => {
                                let country_id
                                let flag
                                let countryValues = languages.find((o) => o.code === val);
                                if(countryValues){
                                  country_id = countryValues.country_id
                                  flag = countries.find((o) => o.id === country_id)?.flag_thumbnail;
                                }
                                return (
                                  <div key={index} className="languageSelected d-flex gap-2">
                                    {countryValues?
                                    <>
                                      <img src={flag} alt="" title={countryValues?.name}/> 
                                      <span className="flagName">{val}</span>
                                    </>:''}
                                  </div>
                                );
                              })}
                        </td>
                        <td className="tableHead-3">
                          {Object.keys(item.question).map((val, index) => {
                              let countryValues = languages.find((o) => o.code === val);
                              return item.question[val] !== null && item.question[val] !== "" ? (
                                <span key={index} className="nameTranslated">
                                  {countryValues?(QuestionText(item.question[val])):''}
                                </span>
                              ) : (
                                <span className="nameTranslated">
                                  <img src={error} alt="" className="warningIcon" />
                                </span>
                              );
                            })}
                        </td>
                        <td>{item.app_type!==null?Constant.APP_TYPE_INVERSE[item.app_type]:"-"}</td>
                        <td>{upadtedBy !== ""&&upadtedBy !== undefined ? upadtedBy.charAt(0).toUpperCase() + upadtedBy.slice(1) : "-"}</td>
                        <td>{createdBy !== ""&&createdBy !== undefined ? createdBy.charAt(0).toUpperCase() + createdBy.slice(1) : "-"}</td>
                        {faqAccess?.permissions.find((o) => o.slug === "update") || faqAccess?.permissions.find((o) => o.slug === "delete") ? (
                        <td className="tableHead-9">
                          {(item.deleted_at === '' || item.deleted_at === null) &&
                            <>
                              {faqAccess?.permissions.find((o) => o.slug === "update") ? (
                                <NavLink to={`/edit-faqs/${item.id}?`+(type!==""?"type="+type+"&":"")+(countValue!==""?"per_page="+countValue+"&":"")+(trashed!==true? "trashed=" + trashed + "&": "")+(page!==1?"page="+page:"")}>
                                  <img src={edit} alt="" className="mx-2" role="button" />
                                </NavLink>
                              ) : (
                                ""
                              )}
                              {faqAccess?.permissions.find((o) => o.slug === "delete") ? <img src={waste} alt="" role="button" onClick={() => removeType(item.id)} /> : ""}
                            </>
                          }
                        </td>
                      ) : (
                        ""
                      )}
                      </tr>
                      })
                    ) : (
                      <NoRecord />
                    )}
                  </tbody>
              }
                </table>
                  
              </div>	
            <div>
              {totalPage > 1 ? <Pagination totalPages={totalPage} key={totalPage} page={page} onPageClick={(page) => setPage(page + 1)} /> : ""}
            </div>
          </div>
        </div>
      </TableWrapper>
    </>
  );
};

export default Faqs;
