import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ForgotPassword from "../pages/ForgotPassword";
import InvitationPage from "../pages/InvitationPage";
import Login from "../pages/LoginPage";
import ResetPassword from "../pages/ResetPassword";

const PublicRoute = (props) => {
  return (
    <>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/reset/password/:token/:details" element={<ResetPassword />} />
        <Route exact path="/admin_verify_invitation/:roleId/:token" element={<InvitationPage />} />
        <Route exact path="*" element={<Navigate replace to="/login" />} />
      </Routes>
    </>
  );
};

export default PublicRoute;
