import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import RecordCount from "../../../assets/SharedComponents/RecordCount/RecordCount";
import { ListWrapper } from "../Style/listWrapper";
import { FaFilterIcon } from "../../../assets/Images/icon";
import { Tooltip } from "@mui/material";
import { ThreeDots } from "react-loader-spinner";
import NoRecord from "../../../assets/SharedComponents/Table/NoRecord";
import Pagination from "../../../assets/SharedComponents/Pagination";
import { getName } from "../../../core/utils/getName/getName";
import edit from "../../../assets/Images/image/edit.png";
import waste from "../../../assets/Images/image/Waste.png";
import eye from "../../../assets/Images/icons/svgIcon/eye.svg";
import publish from "../../../assets/Images/icons/svgIcon/publish.svg";
import { confirmAlert } from "react-confirm-alert";
import copy from "../../../assets/Images/icons/faLink.svg";
import moment from "moment";


const NomadTerms = () => {

  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const [searchParams, setSearchParams] = useSearchParams();

  const [data,_data] = useState([])

  const [trashed, _trashed] = useState(searchParams.get('trashed')!==null?(searchParams.get('trashed')==='false'?false:true):true);

  const [page, setPage] = useState(query.get('page')!==null?parseInt(query.get('page')):1);
  const [total, _total] = useState(0);
  const [totalPage, _totalPage] = useState(0);

  const [countValue, _countValue] = useState(query.get('per_page')!==null?query.get('per_page'):"");

  const [filters, _filters] = useState(false);
  const [loading, _loading] = useState(false);

  const [userAccess, _userAccess] = useState(JSON.parse(localStorage.getItem('permissions')))

  let legalDocumentAccess = userAccess.find(o => o.slug === "legal_documents")

  useEffect(() => {
    getData()
  }, [page,countValue,trashed])

  const getData = () =>{
    _loading(true);
    API.get(APP_URLS.LIST_LEGAL_DOCUMENTS + `?type=nomad_terms&page=${page}&per_page=${countValue}&with_trashed=${trashed===true?1:0}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _data(resp.data.data);
          _totalPage(resp.data.last_page);
          _total(resp.data.total);
          _loading(false);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loading(false);
        }
      })
      .catch(function (error) {
        _loading(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map((error, index) => (error_message = resp.data.errors[error][0]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }
  const handleSearchChange = (func, searchValue) => {
    _countValue(searchValue);
    if(searchValue!==countValue&&searchValue !== ""){
        _totalPage(0)
        setPage(1);
    }
  };

  const clearFilter = () => {
    _filters(true);
    handleSearchChange(_countValue, '');
    _trashed(true)
    if (countValue !== ''||trashed!==true) {
      setPage(1)
      _totalPage(0)
    }
    for(var value of query.keys()) {
      const param = searchParams.get(value);
      if (param) {
        searchParams.delete(value);
        setSearchParams(searchParams);
      }
     }
  };

  const removeContent = (index) =>{
    confirmAlert({
      title: "Confirm to remove",
      message: `Are you sure you want to remove?`,
      closeOnEscape: true,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            API.delete(APP_URLS.DELETE_LEGAL_DOCUMENTS + `/${index}`)
              .then((res) => {
                const resp = res.data;
                if (resp.success === true) {
                  toast.success("Document has been deleted successfully.", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  getData()
                } else {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              })
              .catch(function (error) {
                const resp = error.response;
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                  {
                    Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
                  }
                } else if (resp.data.data?.error !== undefined) {
                  error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                  error_message = resp.data.error;
                } else {
                  error_message = resp.data.message;
                }
                toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          },
        },
        {
          label: "No",
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-custom-class-name",
    });
  }

  const publishContent = (index) =>{
    confirmAlert({
      title: "Confirm To Publish",
      message: `Once published you will not be able to make any changes to the document from start date or as soon as the terms are in effect.`,
      closeOnEscape: true,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            API.post(APP_URLS.PUBLISH_LEGAL_DOCUMENTS + `/${index}?_method=patch`)
              .then((res) => {
                const resp = res.data;
                if (resp.success === true) {
                  toast.success("Content has been Published successfully.", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  getData()
                } else {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              })
              .catch(function (error) {
                const resp = error.response;
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                  {
                    Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
                  }
                } else if (resp.data.data?.error !== undefined) {
                  error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                  error_message = resp.data.error;
                } else {
                  error_message = resp.data.message;
                }
                toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          },
        },
        {
          label: "No",
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-custom-class-name",
    });
  }

  const duplicateContent = (index) =>{
    confirmAlert({
      title: "Confirm To Duplicate",
      message: `Are you sure you want to duplicate this document?`,
      closeOnEscape: true,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            API.post(APP_URLS.DUPLICATE_LEGAL_DOCUMENTS + `/${index}/duplicate`)
              .then((res) => {
                const resp = res.data;
                if (resp.success === true) {
                  toast.success("Documents has been duplicated successfully.", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  getData()
                } else {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              })
              .catch(function (error) {
                const resp = error.response;
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                  {
                    Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
                  }
                } else if (resp.data.data?.error !== undefined) {
                  error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                  error_message = resp.data.error;
                } else {
                  error_message = resp.data.message;
                }
                toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          },
        },
        {
          label: "No",
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-custom-class-name",
    });
  }

  return (
    <ListWrapper>
      <div className="row">
        <div className="col-md-4 offset-md-4 ">
          <div className="headerContainer d-flex justify-content-center">
            <h1>NOMAD TERMS</h1>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="d-flex justify-content-between add-info flex-wrap">
          <div className="add-depertment mb-2">
          {legalDocumentAccess.permissions.find((o) => o.slug === "add") ? 
            <NavLink to="/legal-documents/add-nomad-terms">
              <button className="btn">+ Add</button>
            </NavLink>
            :""}
          </div>
          <div className="d-flex gap-2 mb-2 flex-wrap align-items-center">
            <RecordCount
              onSearchClick={(search) => handleSearchChange(_countValue, search)}
              id="pageCount"
              val={countValue}
              filters={filters}
              _filters={_filters} />
            <Tooltip title="Clear Filter" placement="bottom">
              <FaFilterIcon className="filter-icon" role="button" onClick={clearFilter} />
            </Tooltip>
            </div>
        </div>
        <div className="d-flex align-items-center justify-content-between info-wrap">
            <div className="justify-content-end mt-1 me-2">
                <span className='text-start'>Total Records : <b>{total}</b></span>
            </div>
            <div className="mt-1">
              <input type="checkbox" checked={!trashed} onChange={()=>{
                _totalPage(0)
                setPage(1);
                _trashed(!trashed)
                }}/>
              <span className="ms-2">Hide Deleted Data</span>
            </div>
        </div>
        <div className="tableContainer">
              <table className="auctionTable">
                <thead className="tableHead">
                  <tr className="table-row">
                    <th>ID</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Updated by</th>
                    <th>Created by</th>
                    <th>Published At</th>
                    {legalDocumentAccess.permissions.find((o) => o.slug === "update") || legalDocumentAccess.permissions.find((o) => o.slug === "delete") ? 
                    <th className="action me-3">Action</th> 
                    : ""}
                  </tr>
                </thead>
                {loading ?
                  <div className='d-flex justify-content-center three-dot'>
                    <ThreeDots
                      height="150"
                      width="150"
                      radius="9"
                      color="#637df4"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                  :
                  <tbody>
                    {data.length > 0 ? (
                      data.map((item, index) => {
                        let createdBy = getName(item.create_activity_log, item.update_activity_log);
                        let upadtedBy = getName(item.update_activity_log, item.create_activity_log);
                        return (
                          <tr className={(item.deleted_at ? "deletedRow" : "") + " table-row"} key={index}>
                            <td>{item.id}</td>
                            <td>{item.start_date!==null?moment(item.start_date).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm"):"-"}</td>
                            <td>{item.end_date!==null?moment(item.end_date).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm"):"-"}</td>
                            <td>{upadtedBy !== "" && upadtedBy !== undefined ? upadtedBy.charAt(0).toUpperCase() + upadtedBy.slice(1) : "-"}</td>
                            <td>{createdBy !== "" && createdBy !== undefined ? createdBy.charAt(0).toUpperCase() + createdBy.slice(1) : "-"}</td>
                            <td>
                              {item.published_at?
                                moment(item.published_at).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm"): 
                                legalDocumentAccess.permissions.find((o) => o.slug === "update")&&item.deleted_at===null?
                                <button className="actionIcon pubish" onClick={() => publishContent(item.id)}>
                                  <Tooltip title="Publish">
                                    <img src={publish} alt="f" />
                                  </Tooltip>
                                </button>:''
                              }
                            </td>
                            <td className="action me-3">
                              {legalDocumentAccess.permissions.find((o) => o.slug === "update")&&item.deleted_at===null&&item.published_at===null?
                              <NavLink to={`/legal-documents/edit-nomad-terms/${item.id}?`+(countValue!==""?"per_page="+countValue+"&":"")+(trashed!==true?"trashed="+trashed+"&":"")+(page!==1?"page="+page:"")}>
                                <Tooltip title="Edit">
                                  <img src={edit} alt="" className="mx-2" role="button" />
                                </Tooltip>
                              </NavLink>
                              :""}
                              {legalDocumentAccess.permissions.find((o) => o.slug === "delete")&&item.deleted_at===null&&(item.published_at===null||(item.start_date)>new Date)?
                                <button onClick={() => removeContent(item.id)}>
                                  <Tooltip title="Remove">
                                    <img src={waste} alt="f" />
                                  </Tooltip>
                                </button>
                                :""
                                }
                              {legalDocumentAccess.permissions.find((o) => o.slug === "add")&&item.deleted_at===null?
                                <button onClick={() => duplicateContent(item.id)}>
                                  <Tooltip title="Duplicate">
                                    <img src={copy} alt="f" />
                                  </Tooltip>
                                </button>
                                :""
                                }
                              <NavLink to={`/legal-documents/preview/${item.id}`}>
                                <button className="actionIcon">
                                  <Tooltip title="Preview">
                                    <img src={eye} alt="f" />
                                  </Tooltip>
                                </button>
                              </NavLink>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <NoRecord />
                    )}
                  </tbody>
                }
              </table>
            </div>
            {totalPage > 1 ? <Pagination totalPages={totalPage} key={totalPage} page={page} onPageClick={(page) => setPage(page + 1)} /> : ""}
      </div>
    </ListWrapper>
  );
};

export default NomadTerms;
