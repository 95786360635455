import React from "react";
import moment from "moment";
import profilePic from "../../..//Images/icons/profile-pic.png";
import { useNavigate } from "react-router-dom";
import { notificationType } from "../../../../config/constant";
const All = (props) => {

  const navigate = useNavigate();
  const UNSOLD_HOTDEAL_VEHICLES = 'unsold_hotdeal_vehicles'

  const navigateFunc = (notification) => {
    let url = '';

    switch (notification?.type) {
        case notificationType.IMPORTED_DEALER:
            url = '/data-provider?page=1&mapped=0';
            break;
        case notificationType.FLAGGED_RATING:
            url = '/flagged-review';
            break;
        case notificationType.PARTNER:
            url = '/partners';
            break;
        case notificationType.INVITATION:
            url = '/';
            break;
        case notificationType.CONTACT_US:
            url = '/contacts';
            break;
        case notificationType.HOTDEAL:
            if (notification?.notification_type?.title === UNSOLD_HOTDEAL_VEHICLES) {
                url = `/hotdeal-ads/${notification?.datas?.hotdeal_id}`;
            }
            break;
        default:
            break;
    }

    if (url !== '') {
        navigate(url);
        props.setState(false);
    }
};

  return (
    <div>
      <div className="item-all mx-3 my-4">
        {props.allNotification.map((notification, index) => {
          return (
            <div className="notification-outer position-relative" key={index}>
              <div className="notification-alert" onClick={() => (notification.read_at === null ? props.handleSingleRead(notification.id) : "")}>
                <span className="d-inline-block notification-time">
                  <span className="me-2">{moment(notification.created_at).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm")} </span>
                </span>
                <div className={notification.read_at !== null ? "readNotification d-flex mt-4" : "d-flex mt-4"}>
                  <img src={notification.image ? notification.image : profilePic} alt="user" width="45" height="45" className="align-self-start profile" />
                  <div onClick={() => { navigateFunc(notification) }} className="notification-content px-2">
                    <div className="notification-content-text">
                      <p className="mb-0 fw-bold">{notification?.datas?.user_name}</p>
                      <p className="mb-0">{notification.body}</p>
                    </div>
                    <div className="notification-action pt-2">
                      <div className="d-flex justify-content-end">
                        {notification.status ?
                          <span className={notification.status === "refused" ? "text-danger" : notification.status === "accepted" ? "text-success" : ""}>{notification.status.charAt(0).toUpperCase() + notification.status.slice(1)}</span>
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button className="deletebtn" onClick={() => props.handleDelete(notification.id)}>
                Delete
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default All;
