import React, { useEffect } from "react";
import MainLayout from "../../../assets/Layout/MainLayout";
import { EditVehicleWrapper } from "../style/EditVehicle";
import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";

let name = {}
let desc = {}

const EditPermission = () => {

  const [searchParams, setSearchParams] = useSearchParams();

  const [fields,_fields] = useState(JSON.parse(localStorage.getItem("languages")))
  const [countries,_countries] = useState(JSON.parse(localStorage.getItem("countries")))
  const [featureID,_featureID] = useState('')

  const { permissionId } = useParams()

  const [loading,_loading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    getPermission()
    let language= fields
    let code = ''
    for( let i=0;i<language.length;i++){
      code = language[i].code
      name[code] =''
      desc[code] =''
    }
  }, [])
  

  const getPermission =() =>{
    API.get(APP_URLS.SHOW_FEATURE_PERMISSION + `/${permissionId}`)
    .then(res => {
      const resp = res.data;
      if(resp.success===true){
        setValues(resp.data.permission)
        _featureID(resp.data.permission.feature_id)
      }
      else{
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })
    .catch(function (error) {  
      const resp = error.response;
      let error_message = '';
      if (resp.data.data !== undefined && resp.data.data !== null) {
        {
          Object.keys(resp.data.data).map(
            (error, index) =>(error_message = resp.data.data[error][0])
          );
        }
      } else if (resp.data.data?.error !== undefined) {
          error_message =  resp.data.data.error;
      } else if(resp.data?.error!== undefined){
          error_message =  resp.data.error
      } else{
          error_message =  resp.data.message
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT
      })
    });
  }

  const setValues = (data) => {
    Object.keys(data.permission_name).map((val,index)=>{
      let elem = document.getElementById(val)
      if(elem!==null){
        elem.value = data.permission_name[val]
        name[val] = data.permission_name[val];
      }
    })
    Object.keys(data.description).map((val,index)=>{
      let elem = document.getElementById(val+'desc')
      if(elem!==null){
        elem.value = data.description[val]
        desc[val] = data.description[val];
      }
    })
  }

  const handleTitle = (val,code) =>{
    name[code] = val
  }

  const handleDesc = (val,code) =>{
    desc[code] = val
  }

  const submitForm = () =>{
    let flag = false
    if((name["en"]===""||name["en"]===undefined||name["en"]===null||desc["en"]===""||desc["en"]===undefined||desc["en"]===null) && (name["fr"]===""||name["fr"]===undefined||name["fr"]===null ||desc["fr"]===""||desc["fr"]===undefined||desc["fr"]===null)){
      flag = false
      toast.error("Permission Title and Description in English and French is mandatory", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else if(name["en"]===""||name["en"]===undefined||name["en"]===null){
      flag = false
      toast.error("Please provide Permission Title in English", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else if(desc["en"]===""||desc["en"]===undefined||desc["en"]===null){
      flag = false
      toast.error("Please provide Permission Description in English", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else if(name["fr"]===""||name["fr"]===undefined||name["fr"]===null){
      flag = false
      toast.error("Please provide Permission Title in French", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else if(desc["fr"]===""||desc["fr"]===undefined||desc["fr"]===null){
      flag = false
      toast.error("Please provide Permission Description in French", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else{
      flag = true
    }
    if(flag===true){
      let data ={}
      data["permission_name"] = name;
      data["description"] = desc;
      if(!loading){
        _loading(true)
        API.post(APP_URLS.UPDATE_FEATURE_PERMISSION+ `/${permissionId}?_method=patch`,data)
        .then(res => {
          _loading(false)
          const resp = res.data;
          if(resp.success===true){
            toast.success("Feautre Updated Successfully.", {
              position: toast.POSITION.TOP_RIGHT
            })
            navigate(featureID!==""?`/translation/feature/permissions/${featureID}?`+                  
            (searchParams.get("type") !== null
              ? "type=" + searchParams.get("type") + "&"
              : "") +
            (searchParams.get("per_page") !== null 
              ? "per_page=" + searchParams.get("per_page") + "&"
              : "") +
            (searchParams.get("page") !== null
              ? "page=" + searchParams.get("page")
              : "")
          :-1);
          }
          else{
            toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT
            })
          }
        })
        .catch(function (error) {  
          _loading(false)
          const resp = error.response;
          let error_message = '';
          if (resp.data.data !== undefined && resp.data.data !== null) {
            {
              Object.keys(resp.data.data).map(
                (error, index) =>(error_message = resp.data.data[error][0])
              );
            }
          } else if (resp.data.data?.error !== undefined) {
              error_message =  resp.data.data.error;
          } else if(resp.data?.error!== undefined){
              error_message =  resp.data.error
          } else{
              error_message =  resp.data.message
          }
          toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT
          })
        });
      }
    }
  }

  return (
    <>
      <EditVehicleWrapper>
      <div className='mt-2' >
          <div className="backContainer ms-4" onClick={()=>navigate(featureID!==""?`/translation/feature/permissions/${featureID}?`+                  
            (searchParams.get("type") !== null
              ? "type=" + searchParams.get("type") + "&"
              : "") +
            (searchParams.get("per_page") !== null 
              ? "per_page=" + searchParams.get("per_page") + "&"
              : "") +
            (searchParams.get("page") !== null
              ? "page=" + searchParams.get("page")
              : "")
          :-1)}>
            <span className='backButton'>x</span>
            <span className='px-3 backButton'>|</span>
            <span className='backButton'>Cancel</span>
          </div>
        </div>
        <div className="headerContainer">
          <h1>EDIT PERMISSION</h1>
        </div>
        <div className="d-flex justify-content-between save-btn mt-3">
          <p className="fieldLabel">Title</p>
          <button className={(loading?"disabled":"")+" add-button"} onClick={submitForm} disabled={loading}>Save</button>
        </div>
        <div className="row input-fileds">
          {fields.map((item,index)=>{
            let itemCode = item.code
            let flag = countries.find(o => o.id === item.country_id)?.flag_thumbnail
            return <div className="col-md-4" key={index}>
              <div className="col-md-12 mt-3">
                <label htmlFor="title" className="form-label">
                  <span className="countryIcon px-2">
                    <img src={flag} alt="" className="countryflag" title={item.name}/>
                  </span>
                  <span className="langName">{item.code}</span>
                </label>
                <input
                  type="text"
                  id={item.code}
                  name={item.code}
                  className="form-control"
                  onChange={(e,code = itemCode)=>{
                    handleTitle(e.target.value,code)
                  }}
                />
              </div>
          </div>
          })}
        </div>
        <div className="mt-5">
          <p className="fieldLabel">Description</p>
        </div>
        <div className="row input-fileds">
          {fields.map((item,index)=>{
            let itemCode = item.code
            let flag = countries.find(o => o.id === item.country_id)?.flag_thumbnail
            return <div className="col-md-4" key={index}>
              <div className="col-md-12 mt-3">
                <label htmlFor="title" className="form-label">
                  <span className="countryIcon px-2">
                    <img src={flag} alt="" className="countryflag" title={item.name}/>
                  </span>
                  <span className="langName">{item.code}</span>
                </label>
                <textarea
                  id={item.code+"desc"}
                  name={item.code+"desc"}
                  className="form-control"
                  rows="4"
                  onChange={(e,code = itemCode)=>{
                    handleDesc(e.target.value,code)
                  }}
                />
              </div>
          </div>
          })}
        </div>
      </EditVehicleWrapper>
    </>
  );
};

export default EditPermission;
