import styled from "styled-components";

export const PhonePreviewWrapper = styled.div`
  .header-preview{
    display:flex;
    justify-content:center;
  }
  .header-preview span{
    letter-spacing: 2px;
    font-weight: 600;
  }

  .status-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .battery-indicator {
    height: 13px;
  }
  .ham-icon {
    height: 15px;
  }
  .mobile-box-2{
    // border-radius: 33px;
    overflow-y: auto;
    max-height: 665px;
  }
  .mobile-box{
    border-radius: 33px;
    overflow-y: auto;
    max-height: 738px;
  }
  .actionbar img{
    height:25px;
  }
  .actionbar span{
    font-size:13px;
  }
  .addIcon{
    background-color:#6579F4;
    border-radius:50px;
    width:70px;
    height:70px;
  }
  .addIconContainer{
    top:-29px;
  }
  .addIcon span{
    color:white;
    font-size:50px;
  }
  .mobile-box::-webkit-scrollbar {
    display: none;
  }
  .mobile-box {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .mobile-box-2::-webkit-scrollbar {
    display: none;
  }
  .mobile-box-2 {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .download-links {
    display: flex;
    justify-content: space-between;
  }
  .download-links img {
    width: 48%;
  }
  .products {
    display: flex;
    justify-content: space-between;
  }
  .product-card-box {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 15px;
  }
  .product-card-box img {
    height: 40px;
    width: auto;
    max-width: 100px;
  }
  .product-card-box span {
    letter-spacing: 2px;
    font-weight: 600;
  }
  .stepImg {
    width: 40px !important;
  }
  .container-border {
    border-radius: 10px;
    // box-shadow: 1px 6px 15px -1px rgba(0, 0, 0, 0.2);
    border 1px solid #5D78FF;
    background-color: #F8F9FF;
  }
  .searchbox{
    width:100%;
    border:none;
    font-size:12px;
    padding-left:30px;
  }
  .notice-container{
    display:flex;
    justify-content:space-between;
    background:#EEF1FF;
    border: 1px dashed #5D78FF;
    border-radius:10px
  }
  .notice-container p{
    color:#5D78FF;
    font-size:10px;
  }
  auctions-container{
    display:flex;
    flex-direction:column;
  }
  .auction-card{
    max-width: 350px;
    width: 100%;
    display: flex;
    flex-direction:column;
    justify-content:space-around;
    align-items:center;
    padding: 15px;
    box-shadow: 0px 10px 13px -8px rgb(0 0 0 / 10%);
  }
  .card-box{
    display:flex;
    flex-direction:row;
  }
  .imagebox{
    display:flex;
    flex-direction:column;
    align-items:center;
  }
  .imagebox p{
    font-size:12px;
    font-weight:700;
  }
  .content-box{
    min-width:200px;
    height:92px;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
  }
  .content-box p{
    font-size:10px;
  }
  .content-head{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
  }
  .content-head .head,.head{
    font-size:15px;
    font-weight:800;
  }
  .content-title{
    font-size:14px;
    font-weight:600;
    color: #B8B8B8;
    text-align:left;
  
  }
  .content-title span{
    color: #5D78FF;
  }
  .flag{
    height:25px;
    width:auto;
  }
  .card-foot{
    width:100%;
    display:flex;
    justify-content:space-between;
  }
  .card-foot p{
    font-size:10px;
    font-weight:800;
  }
  .card-foot p span{
    font-weight:100;
  }
  .carousel-box{
    display:flex;
    align-items:center;
  }
  .adCarouselBox{
    max-width: 350px;
  }
  .carousel-slider{
    display:flex;
    align-items:center;
  } 
  .carousel .control-dots {
    bottom: -1px;
  }
  .carousel .slider-wrapper {
    padding: 0 0 30px 0;
  }
  .carousel .slide img {
    width:auto !important;
  }
  .carousel.carousel-slider .control-arrow {
    background: #1a1a1a !important;
    height: fit-content !important;
    border-radius: 50% !important;
    top:35%;
  }
  .carousel.carousel-slider .control-arrow:hover {
    background: #9e9d9d !important;
  }
  .control-dots li {
    background: #C4C4C4 !important;
    box-shadow: 0 0 0 !important;
    width: 14px !important;
    height: 2px !important;
  }
  .control-dots li.selected {
    background: #5D78FF !important;
  }
  .carousel-img{
    max-width:100%;
  }
  .card-img{
    max-width:50%;
  }
  .auction-status-bar{
    width:100%;
  }
  .auction-status{
    height:20px;
  }
  .flag{
    height:25px;
  }
  .auction-title{
   font-size:18px;
   font-weight:700; 
  }
  .card-content{
    width:100%;
    text-align:left;
  }
  .counter-head{
    font-size:15px;
    font-weight:700;
  }
  .count{
    min-width:70px;
  }
  .count span:nth-child(1) {
    color: #5D78FF;
    font-size:20px;
  }
  .count span:nth-child(2) {
    color: #B8B8B8;
    font-size:10px;
  }
  .timer{
    min-width:50px;
  }
  .dealer-container{
  }
  .dealer-container img{
    width:100%;
    // box-shadow: 1px 6px 15px -1px rgb(0 0 0 / 20%);
    // border-radius: 18px;
  }
  .auctions-1{
    border:1px solid black;
    min-width:18px
  }
  .auctions-2{
    border:1px solid #6579F4;
    min-width:18px
  }  
  .auctions-3{
    border:1px solid #ffde00;
    min-width:18px
  }
  .productCarCard{
    max-width:290px;
    border-radius:8px;
    box-shadow: 0px 10px 13px -8px rgb(0 0 0 / 10%);
  }
  .cardImage{
    width:100%
  }
  .specifications{
    background:#F5F5F5;
    border-radius:15px;
    height:auto;
  }
  .specifications span{
    font-size:12px
  }
  .carTitle{
    font-size:16px;
    font-weight:700;
  }
  .userDetails{
    background:#F5F5F5;
    width:100%;
  }
  .flagImage{
    top: 25px;
    left: 30px;
  }
  .userImage{
    width:20%;
  }
  .userName{
    width:80%;
  }
  .userName span:nth-child(1){
    font-weight:700;
    font-size:14px;

  }
  .userName span:nth-child(2){
    font-weight:600;
    font-size:11px;
  }
  .userName span:nth-child(3){
    font-weight:400;
    font-size:14px;
    text-align:center;
    color:#aaaaaa;
  }
  .priceValue{
    width:100%;
  }
  .pricing{
    width:80%;
  }
  .stars{
    height:15px;
    top: 30px;
    left: 70px;
  }
  .rating{
    width:20%;
  }
  .starDiv{
    box-shadow: 1px 6px 15px -1px rgba(0, 0, 0, 0.2);
    border-radius:50%;
    min-height:50px;
    min-width:50px;
  }
  .sociolLogodiv{
    box-shadow: 1px 6px 15px -1px rgba(0, 0, 0, 0.2);
    border-radius:50%;
    min-height:30px;
    min-width:30px;
  }
  .socialLinks img{
    height:15px;
  }
  .footer{
    background-color: #6579F4;
  }
  .footer span{
    color: white;
    font-size: 12px;
    min-width:100px;
    font-weight:600;
  }
  .footerTitle{
    font-size:14px !important;
    font-weight:500 !important;

  }
`;
