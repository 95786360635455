import styled from "styled-components";

export const PreviewWrapper = styled.div`
  .preview {
    min-height: calc(100vh - 200px);
    max-height: calc(100vh - 200px);
    overflow-y: scroll;
    border: 1px solid #979797;
    border-radius: 5px;
    padding:0;
  }
  .header-box {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    letter-spacing: 1px;
    font-size: 18px;
  }
  .ctblogo {
    height: 40px;
  }
  .header-icon {
    height: 18px;
  }
  .search-box {
    border-radius: 15px;
    line-height: 30px;
    background: rgb(245, 245, 245);
  }
  .navigate-icon {
    height: 22px;
  }
  .header-box span {
    color: rgb(201, 196, 196);
    line-height: 10px;
  }
  .search-box input {
    background: rgb(245, 245, 245);
    border: none;
    min-width: 450px;
    font-size: 12px;
  }
  .loginBtn {
    background-color: #607afc;
    color: white;
    border: none;
    padding: 6px 20px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
      rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  }
  .footer {
    background-color: #607afc;
    display: flex;
    justify-content: space-between;
    color: white;
  }
  .content{
    min-height: calc(100vh - 310px);
    display: flex;
    justify-content: center;
    padding: 30px 0px;
  }
  p{
    margin-top:5px;
  }
`;
