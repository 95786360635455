import React, { useEffect, useState } from "react";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { CreateAuctionWrapper } from "../../Auction/CreateAuction/style/createAuction";

const CreateForums = () => {
  // Define the language codes you want to validate
  const allowedLanguages = ["en", "fr"];

  const { id } = useParams();
  const [activeLanguages, setActiveLanguages] = useState([]);
  const [countries, setCountries] = useState(
    JSON.parse(localStorage.getItem("countries") || [])
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getActiveLanguages = () => {
      API.get(APP_URLS.LANGUAGE_LIST)
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) {
            setActiveLanguages(resp.data.languages);
          } else {
            toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          const resp = error.response;
          let error_message =
            resp.data?.data?.message ||
            resp.data?.data?.error ||
            resp.data?.error ||
            resp.data?.message ||
            "An error occurred";
          toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    };
    getActiveLanguages(); //countries for countries fieldArray
  }, []);

  useEffect(() => {
    if (id && activeLanguages.length > 0) {
      fetchForumData(id); //fetch data for edit particular forum data
    }
  }, [id, activeLanguages]);

  //fetch data for edit particular forum data
  const fetchForumData = (id) => {
    API.get(`${APP_URLS.FORUM_TAGS}/${id}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          const forumData = resp.data.forumTag;

          const initialValues = {
            title: {},
            description: {}
          };

          // Populate initialValues based on activeLanguages and forumData
          activeLanguages.forEach(lang => {
            initialValues.title[lang.code] = forumData.title?.[lang.code] || "";
            initialValues.description[lang.code] = forumData.description?.[lang.code] || "";
          });

          formik.setValues(initialValues);

        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        const resp = error.response;
        let error_message =
          resp.data?.data?.message ||
          resp.data?.data?.error ||
          resp.data?.error ||
          resp.data?.message ||
          "An error occurred";

        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const validateForm = (values) => {
    const { title } = values;
    const enTitle = title["en"] && title["en"].trim();
    const frTitle = title["fr"] && title["fr"].trim();

    if (!enTitle && !frTitle) {
      toast.error("Language Title in English and French is mandatory", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }

    if (!enTitle) {
      toast.error("Please provide language name in English", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }

    if (!frTitle) {
      toast.error("Please provide language name in French", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }

    return true;
  };

  const formik = useFormik({
    initialValues: {
      title: activeLanguages.reduce((acc, lang) => {
        acc[lang.code] = "";
        return acc;
      }, {}),

      description: activeLanguages.reduce((acc, lang) => {
        acc[lang.code] = "";
        return acc;
      }, {}),
    },
    // validationSchema: Yup.object({
    //   title: Yup.object().shape(
    //     allowedLanguages.reduce((acc, lang) => {
    //       if (allowedLanguages.includes(lang)) {
    //         acc[lang] = Yup.string().required(`Title in ${lang} is required`);
    //       }
    //       return acc;
    //     }, {})
    //   ),
    // }),
    onSubmit: (values) => {
      if (!validateForm(values)) {
        return;
      }
      if (!loading) {
        setLoading(true);
        const data = {
          title: values.title,
          description: values.description,
        };

        //conditionally hitting API for create and update forum tags
        const apiCall = id
          ? API.patch(`${APP_URLS.FORUM_TAGS}/${id}`, data) // Update if ID is present
          : API.post(APP_URLS.FORUM_TAGS, data); // Create if no ID

        apiCall
          .then((res) => {
            const resp = res.data;
            setLoading(false);
            if (resp.success === true) {
              toast.success(`Tags has been ${id ? "updated" : "created"} successfully.`, {
                position: toast.POSITION.TOP_RIGHT,
              });
              navigate('/tags');
            } else {
              toast.error(resp.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
          .catch((error) => {
            const resp = error.response;
            let error_message;
            if (resp.data.data && Array.isArray(resp.data.data?.['title.en'])) {
              error_message = resp.data.data?.['title.en'].join(" ");
            } else {
              error_message =
                resp.data?.data?.message ||
                resp.data?.data?.error ||
                resp.data?.error ||
                resp.data?.message ||
                "An error occurred";
            }
            toast.error(error_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }).finally(() => {
            setLoading(false);
          });
      }
    },
  });

  return (
    <CreateAuctionWrapper>
      <div className="mt-2">
        <div className="backContainer ms-4" onClick={() => navigate(-1)}>
          <span className="backButton">x</span>
          <span className="px-3 backButton">|</span>
          <span className="backButton">Cancel</span>
        </div>
      </div>
      <form className="auctionMainContainer" onSubmit={formik.handleSubmit}>
        <div className="mb-2">
          <h1>{id ? "EDIT" : "CREATE"} TAGS</h1>
        </div>
        <div className="actionContainer d-flex justify-content-end">
          <button type="submit" className="btn save-button" disabled={loading}>
            {loading ? "Saving..." : "Save"}{" "}
          </button>
        </div>
        <div className="container">
          <div className="row">
            <label className="fieldLabel mb-2">Title</label>
            {activeLanguages.map((item, index) => {
              let flag = countries.find(
                (o) => o.id === item.country_id
              )?.flag_thumbnail;
              let itemCode = item.code;
              return (
                <div className="col-lg-3 col-md-6 auction-form" key={index}>
                  <div className="mb-3">
                    <label htmlFor={`title-${itemCode}`} className="form-label">
                      <span className="px-2">
                        <img className="countryflag" src={flag} title={item.name} />
                      </span>
                      <span className="langName">{itemCode}</span>{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control langaugeFields"
                      id={`title-${itemCode}`}
                      name={`title.${itemCode}`}
                      value={formik.values.title[itemCode] || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder={`Title in ${itemCode}`}
                    />
                    {formik.touched.title?.[itemCode] && formik.errors.title?.[itemCode] ? (
                      <div className="error">{formik.errors.title?.[itemCode]}</div>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="row">
            <label className="fieldLabel mt-3 mb-2">Description</label>
            {activeLanguages.map((item, index) => {
              let flag = countries.find(
                (o) => o.id === item.country_id
              )?.flag_thumbnail;
              let itemCode = item.code;
              return (
                <div className="col-lg-3 col-md-6 auction-form" key={index}>
                  <div className="mb-3">
                    <label htmlFor={`description-${itemCode}`} className="form-label">
                      <span className="px-2">
                        <img className="countryflag" src={flag} title={item.name} />
                      </span>
                      <span className="langName">{itemCode}</span>{" "}
                    </label>
                    <textarea
                      className="form-control langaugeFields"
                      id={`description-${itemCode}`}
                      name={`description.${itemCode}`}
                      rows="1"
                      value={formik.values.description[itemCode] || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder={`Description in ${itemCode}`}
                    ></textarea>
                    {formik.touched.description?.[itemCode] && formik.errors.description?.[itemCode] ? (
                      <div className="error">{formik.errors.description[itemCode]}</div>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </form>
    </CreateAuctionWrapper>
  );
};

export default CreateForums;
