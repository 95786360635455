import styled from "styled-components";

export const ImportCsvWrapper = styled.div`
  .tableHead {
    border: 0px solid #fff;
  }
  tbody .table-row:first-child {
    margin-top: 0px;
  }
  .auctionTable th,
  tr.table-row td {
    padding: 5px 5px;
  }

  table td {
    color: ;
  }
  table td {
    color: #979797;
  }
  .info-head {
  }
  .info-head h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #5d78ff;
  }
  .import-btn input {
    border: 1px solid #5d78ff;
    border-radius: 8px;
    color: #617af7;
    padding: 10px 20px;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
  }
`;
