import React, { useEffect, useState } from "react";
import MainLayout from "../../../../assets/Layout/MainLayout";
import Switch from "react-switch";
import API from "../../../../api/axios";
import { APP_URLS } from "../../../../api/url";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CraeteVehicleWrapper } from "../../style/createVehicle";
import { ProductTypes, modelType } from "../../../../config/constant";
import { handleColorImage } from "../../../../core/utils/handleImageEvents/handleColorImage";
import Toggle from "../../../Translate/components/Toggle";
import { SelectImageCreate, SelectImageHeading } from "../../../../assets/SharedComponents/SelectImage";


const CreateBrand = () => {

  const [products, _products] = useState(JSON.parse(localStorage.getItem("productType")));
  const [colorImageValueWeb,_colorImageValueWeb] = useState('')
  const [colorImageWeb,_colorImageWeb] = useState('')
  const [colorImageValueMobile,_colorImageValueMobile] = useState('')
  const [colorImageMobile,_colorImageMobile] = useState('')

  const [loading,_loading] = useState(false)

  const [isPopular, _isPopular] = useState(false)
  const [isBacklink, _isBacklink] = useState(false)
  const [productValue, _productValue] = useState("");
  const [name, _name] = useState('');

  const navigate = useNavigate()


  const saveImage =(imgWeb, imgMobile,respId) =>{
    var formdata = new FormData();
    if(imgWeb) formdata.append("icon[web]",imgWeb)
    if(imgMobile) formdata.append("icon[mobile]",imgMobile) 
    formdata.append("type",2)
    formdata.append("model",modelType.brand)
    API.post(APP_URLS.ADD_ICON + `/${respId}`,formdata)
    .then(res => {
      const resp = res.data;
      if(resp.success===true){
          _colorImageMobile('')
          _colorImageWeb('')
          _colorImageValueMobile('')
          _colorImageValueWeb('')
        navigate(`/vehicles-management/vehicle-brands`);
      }
      else{
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }) 
    .catch(function (error) {  
      const resp = error.response;
      let error_message = '';
      if (resp.data.data !== undefined && resp.data.data !== null) {
        {
          Object.keys(resp.data.data).map(
            (error, index) =>(error_message = resp.data.data[error][0])
          );
        }
      } else if (resp.data.data?.error !== undefined) {
          error_message =  resp.data.data.error;
      } else if(resp.data?.error!== undefined){
          error_message =  resp.data.error
      } else{
          error_message =  resp.data.message
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT
      })
    });
} 
 
  const handleSubmit = () => {
    let data = {}
    data["name"] = name
    data["product_type_id"] = productValue
    data["is_popular"] = Number(isPopular)  
    if(productValue == ProductTypes.car) data["back_link"] =  Number(isBacklink)  
    let flag = false
    if (name !== null && name !== "") {
      flag = true
    } 
    if (flag === false) {
      toast.error("Please provide brand name", {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } 
    if ((productValue === null || productValue === "")) {
      toast.error("Please provide product type", {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } 
    if(isPopular && ( !colorImageValueMobile || !colorImageValueWeb )){
      toast.error("Please provide icons", {
        position: toast.POSITION.TOP_RIGHT
      })
      return;
    }
    else {
      if(!loading){
        _loading(true)
        API.post(APP_URLS.ADD_BRANDS, data)
          .then(res => {
            _loading(false)
            const resp = res.data;
            if (resp.success === true) {
              const respId =resp.data.brand.id 
              toast.success("Brand has been added successfully.", {
                position: toast.POSITION.TOP_RIGHT
              }) 
              if(colorImageValueWeb || colorImageValueMobile)
                saveImage(colorImageValueWeb, colorImageValueMobile,respId) 
              resetForm()
              navigate("/vehicles-management/vehicle-brands");
            }
            else {
              toast.error(resp.message, {
                position: toast.POSITION.TOP_RIGHT
              })
            }
          })
          .catch(function (error) {
            _loading(false)
            const resp = error.response;
            let error_message = '';
            if (resp.data.data !== undefined && resp.data.data !== null) {
              {
                Object.keys(resp.data.data).map(
                  (error, index) => (error_message = resp.data.data[error][0])
                );
              }
            } else if (resp.data.data?.error !== undefined) {
              error_message = resp.data.data.error;
            } else if (resp.data?.error !== undefined) {
              error_message = resp.data.error
            } else {
              error_message = resp.data.message
            }
            toast.error(error_message, {
              position: toast.POSITION.TOP_RIGHT
            })
          });
      }
    }
  }

  const resetForm = () => {
    _isPopular(false)
    _isBacklink(false)
    _productValue("1")
    _name('')
    document.getElementById('name').value = ''
    document.getElementById('productType').value = "1" 
  }
  
  return (
    <>
      <CraeteVehicleWrapper>
        <div className='mt-2' >
          <div className="backContainer ms-4" onClick={() => navigate("/vehicles-management/vehicle-brands")}>
            <span className='backButton'>x</span>
            <span className='px-3 backButton'>|</span>
            <span className='backButton'>Cancel</span>
          </div>
        </div>
        <div className="headerContainer">
          <h1>CREATE BRAND</h1>
        </div>
        <div className="row">
          <div className="d-flex justify-content-end save-btn mt-3">
          <button className={(loading?"disabled":"")+" add-button"} onClick={handleSubmit} disabled={loading}>Save</button>
          </div>

          <div className="row input-fileds align-items-center">
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-12 mt-3">
                  <label htmlFor="name" className="form-label fieldLabel">
                    Name
                  </label>
                  <input type="text" id="name" name="name"
                    className="form-control"
                    placeholder="Brand Name" onChange={(e) => _name(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <label htmlFor="productType" className="form-label product-type fieldLabel">
                Product Type
              </label>
              <select name="productType" id="productType" className="form-control mt-3" onChange={(e) => _productValue(e.target.value)}>
                <option hidden>Select Product Type</option>
                {products.map((item, index) => {
                  return (
                    <option value={item.product_categories[0].pivot.product_type_id} key={index}>
                      {item.item_name.en}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-md-4">
              <div className="row">
                <Toggle  label={'Is Popular?'} checked={isPopular} handleChecked={(e) => _isPopular(e)} /> 
                {
                  ( productValue == ProductTypes.car ) ? <Toggle  label={'Is Backlink?'} checked={isBacklink} handleChecked={(e) => _isBacklink(e)} />   : null
                }   
              </div>
            </div>
             
          </div>
          <div className="row mt-5">
          <div className="col-md-4">
            <div className="row">
              <SelectImageHeading/> 
              <div className="col-md-6 mt-2">
                <label>For Web</label>
                <SelectImageCreate type='web' colorImage={colorImageWeb} handleColorImage={(e)=>handleColorImage(e,_colorImageWeb,_colorImageValueWeb  , 'web')}/>  
              </div>
              <div className="col-md-6 mt-2">
                <label>For Mobile</label>
                <SelectImageCreate type='mobile' colorImage={colorImageMobile} handleColorImage={(e)=>handleColorImage(e,_colorImageMobile,_colorImageValueMobile ,'mobile')}/> 
              </div>
            </div>
          </div>
        </div>
        </div>
      </CraeteVehicleWrapper>
    </>
  );
};

export default CreateBrand;
