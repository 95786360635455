import React, { useState } from 'react'
import arrowUp from "../../../../assets/Images/icons/faChevronUp.svg";
import { invoiceTabs } from '../../../../config/constant';

const Header = ({ tabView, setTabView, _action, action, handleDownloadInvoiceZip }) => {
    return (
        <>
            <div className="row mt-4">
                <div className="col-md-4 offset-md-4">
                    <h1>INVOICES</h1>
                </div>
                <div className="col-md-4 d-flex justify-content-end align-items-center">
                    <div className="actionAuction">
                        <div className={(action ? "btnActive" : "") + " actionBtns"}>
                            <button className="actionBtn" onClick={() => {
                                _action(!action)
                            }}>
                                Action
                                <img src={arrowUp} alt="" className={(action ? "open" : "close") + " ms-2"} />
                            </button>
                        </div>
                        <div className="DelAction">
                            {action ? (
                                <button className={(action ? "" : "") + " actionBtn"} onClick={handleDownloadInvoiceZip}>
                                    Download invoice(zip)
                                </button>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-12 d-flex justify-content-center mt-2">
                <div className="tabBtn d-flex">
                    <button className={tabView === invoiceTabs.SUBSCRIPTION ? "activeTab" : " "} onClick={() => setTabView(invoiceTabs.SUBSCRIPTION)}>
                        Subscription
                    </button>
                    <button className={tabView === invoiceTabs.CREDIT ? "activeTab" : ""} onClick={() => setTabView(invoiceTabs.CREDIT)}>
                        Credit
                    </button>
                    <button className={tabView === invoiceTabs.COMMISSION ? "activeTab" : " "} onClick={() => setTabView(invoiceTabs.COMMISSION)}>
                        Vehicle Commission
                    </button>
                    <button className={tabView === invoiceTabs.NOMAD ? "activeTab" : ""} onClick={() => setTabView(invoiceTabs.NOMAD)}>
                        Nomad
                    </button>
                </div>
            </div>
        </>
    )
}

export default Header