import React, { useEffect, useState } from "react";
import MainLayout from "../../../assets/Layout/MainLayout";
import { CraeteVehicleWrapper } from "../style/createVehicle";
import { toast } from "react-toastify";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { useNavigate } from "react-router-dom";

let name = {}

const CreateVehicleConditions = () => {

  const [languages,_languages] = useState(JSON.parse(localStorage.getItem("languages")))
  const [countries,_countries] = useState(JSON.parse(localStorage.getItem("countries")))
  const [products,_products] = useState(JSON.parse(localStorage.getItem("productType")))
  
  const [loading,_loading] = useState(false)

  const [productValue,_productValue] = useState('')

  const navigate = useNavigate()

  useEffect(() => {
    let language= languages
    let code = ''
    for( let i=0;i<language.length;i++){
      code = language[i].code
      name[code] =''
    }
  }, [])

  const handleTitle = (val,code) =>{
    name[code] = val
  }

  const resetForm = () =>{
    let inputs = document.querySelectorAll('.departmentFields');
    inputs.forEach(input => {
      input.value = '';
    });
  }

  const submitForm = () =>{
    let data = {}
    let flag = false
    data["name"] = name
    data["product_type_id"] = productValue
    if((name["en"]===""||name["en"]===undefined||name["en"]===null) && (name["fr"]===""||name["fr"]===undefined||name["fr"]===null )){
      flag = false
      toast.error("Vehicle Condition Title in English and French is mandatory", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else if(name["en"]===""||name["en"]===undefined||name["en"]===null){
      flag = false
      toast.error("Please provide Vehicle Condition Name in English", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else if(name["fr"]===""||name["fr"]===undefined||name["fr"]===null){
      flag = false
      toast.error("Please provide Vehicle Condition Name in French", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else if ((productValue === null || productValue === "")) {
      toast.error("Please provide product type", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else{
      flag = true
    }
    if(flag===true){
      if(!loading){
        _loading(true)
        API.post(APP_URLS.ADD_CONDITIONS,data)
        .then(res => {
          _loading(false)
          const resp = res.data;
          if(resp.success===true){
            toast.success("Condition Added Successfully.", {
              position: toast.POSITION.TOP_RIGHT
            })
            resetForm();
            navigate("/translation/vehicle-conditions");
          }
          else{
            toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT
            })
          }
        })
        .catch(function (error) {  
          _loading(false)
          const resp = error.response;
          let error_message = '';
          if (resp.data.data !== undefined && resp.data.data !== null) {
            {
              Object.keys(resp.data.data).map(
                (error, index) =>(error_message = resp.data.data[error][0])
              );
            }
          } else if (resp.data.data?.error !== undefined) {
              error_message =  resp.data.data.error;
          } else if(resp.data?.error!== undefined){
              error_message =  resp.data.error
          } else{
              error_message =  resp.data.message
          }
          toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT
          })
        });
      }
    }
  }

  return (
    <>
      <CraeteVehicleWrapper>
        <div className='mt-2' >
          <div className="backContainer ms-4" onClick={()=>navigate("/translation/vehicle-conditions")}>
            <span className='backButton'>x</span>
            <span className='px-3 backButton'>|</span>
            <span className='backButton'>Cancel</span>
          </div>
        </div>
        <div className="headerContainer">
          <h1>CREATE VEHICLE CONDITIONS</h1>
        </div>
        <div className="d-flex justify-content-between save-btn mt-3">
          <p className="fieldLabel">Title</p>
          <button className={(loading?"disabled":"")+" add-button"} onClick={submitForm} disabled={loading}>Save</button>
        </div>
        <div className="row input-fileds">
          {languages.map((item,index)=>{
              let itemCode = item.code
              let flag = countries.find(o => o.id === item.country_id)?.flag_thumbnail
              return <div className="col-md-4" key={index}>
                <div className="col-md-12 mt-3">
                  <label htmlFor="title" className="form-label">
                    <span className="countryIcon px-2">
                      <img src={flag} alt="" className="countryflag" title={item.name}/>
                    </span>
                    <span className="langName">{item.code}</span>
                  </label>
                  <input
                    type="text"
                    id="title"
                    name="title"
                    className="form-control departmentFields"
                    onChange={(e,code = itemCode)=>{
                      handleTitle(e.target.value,code)
                    }}
                  />
                </div>
            </div>
            })}
        </div>
        <div className="row mt-5 input-fileds">
          <div className="col-md-4">
            <div className="row">
              <div className="col-md-12">
                <label htmlFor="productType" className="form-label product-type fieldLabel">
                  Product Type
                </label>
                <select name="productType" id="productType" className="form-control mt-2"
                  onChange={(e) => _productValue(e.target.value)}>
                  <option hidden>Select Product Type</option>
                  {products.map(
                    (item, index) => {
                      return (
                        <option value={item.product_categories[0].pivot.product_type_id} key={index}>
                          {item.item_name.en}
                        </option>
                      );
                    }
                  )}
                </select>
              </div>
            </div>
          </div>
        </div>
      </CraeteVehicleWrapper>
    </>
  );
};

export default CreateVehicleConditions;
