import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'

const SearchableAutoCompleteWithChips = ({ options, onChange, chips, disabled, func, getSelectedData }) => {

  const [allOptions, setAllOptions] = useState(options);

  //updating options data on deleting chip
  useEffect(()=>{
    getSelectedData(optionsMap);
  },[chips])

  useEffect(() => {
    // Merge new options with existing options when initialOptions change
    setAllOptions(prevOptions => ({
      ...prevOptions,
      ...options
    }));
  }, [options]);
  
  // Create a map to look up the original option based on the selected value
  const optionsMap = Object.entries(allOptions).reduce((acc, [id, label]) => {
    acc[label] = id;
    return acc;
  }, {});
  
  return (
    <div>
      <Autocomplete
        multiple
        value={chips}
        disableCloseOnSelect
        id="autocomplete"
        options={Object.values(allOptions)}
        disabled={disabled}
        onChange={(event, value) => {
          // Map selected labels back to their original IDs
          const selectedOption = value.map(option => parseInt(optionsMap[option]));
          onChange(value, selectedOption); // Return array of selected IDs and selected values
        }}
        onInputChange={(x) => {
          if (func && x?.target) {
            func(x.target.value);
          }
        }}
        renderTags={() => null} // This will prevent tags from being displayed
        renderInput={(params) => (
          <TextField {...params} variant="standard" placeholder='Select the Dealer Name' />
        )}
        sx={{
          '.MuiInput-input': {
            padding: '4px !important',
          },
          '.MuiAutocomplete-inputRoot': {
            paddingRight: '0px !important'
          }
        }}
      />
    </div>
  )
}

export default SearchableAutoCompleteWithChips