import styled from "styled-components";

export const FeaturesWrapper = styled.div`
  .feature-name {
    color: #5d78ff;
  }
  .tableHead {
    flex-direction: column;
  }
  .tableHead span {
    color: #979797;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
  .block {
    color: #5d78ff;
  }
  .react-switch-handle {
    height: 18px !important;
    width: 18px !important;
  }
  .react-switch-bg {
    width: 50px !important;
    height: 21px !important;
  }
  .table {
    margin-top: 10px;
  }
  .table thead th span {
    color: #979797;
    display: block;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 13px;
  }
  .table tbody td span {
    font-size: 12px;
    font-weight: 500;
    line-height: 13px;
  }
  .w-70 {
    width: 70px;
  }
  .w-40 {
    width: 40%;
  }
  .w-50 {
    width: 50%;
  }
  .w-60 {
    width: 60%;
  }
  .w-14 {
    width: 14%;
  }
  .table > :not(caption) > * > * {
    border-bottom: 0;
  }
  .tableHead {
    border: none;
  }
  .table-row {
    border-left: 0;
    border-right: 0;
  }
  .tableHead .table-row:nth-child(1) {
    border-top: none !important;
  }
  .tableHead .table-row:nth-child(2) {
    border-bottom: 1px solid #000000;
  }
  tbody .table-row:first-child {
    margin-top: 0;
    border-top: 0;
  }
  .auctionTable th,
  tr.table-row td {
    padding: 10px 5px;
  }
  .headCol {
    justify-content: start;
  }
`;
