import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import MainLayout from "../../../assets/Layout/MainLayout";
import { RoleWrapper } from "../CreateRole/style/role";


const EditAdminRole = () => {

  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const [data,_data] = useState([])
  const [dataLaoded,_dataLaoded] = useState(false)

  const [loading,_loading] = useState(false)

  const [name,_name] = useState('')
  const [description,_description] = useState('')

  const { roleId } = useParams();

  let navigate = useNavigate() 

  useEffect(() => {
    getAdminPanelFeatures()
  }, [])

  useEffect(()=>{
    if(dataLaoded){
      getRole()
    }
  },[dataLaoded])

  const getAdminPanelFeatures =() =>{
    API.get(APP_URLS.LIST_FEATURES + `?filter[type]=1&with_permissions=true`)
    .then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        const UpdateData = resp.data.items.map((item)=> {
          item['active'] = false
          const itemsPermission = item.permissions;
            itemsPermission.map((item)=> {
                item['active'] = false
              return item
            })
          return item
        })
        _data(UpdateData);
        _dataLaoded(true)
      } else {
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    })
    .catch(function (error) {
      const resp = error.response;
      let error_message = "";
      if (resp.data.data !== undefined && resp.data.data !== null) {
        {
          Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
        }
      } else if (resp.data.data?.error !== undefined) {
        error_message = resp.data.data.error;
      } else if (resp.data?.error !== undefined) {
        error_message = resp.data.error;
      } else {
        error_message = resp.data.message;
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    });
  }

  const getRole = () =>{
    API.get(APP_URLS.VIEW_ADMIN_ROLE + `/${roleId}`)
    .then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        setValues(resp.data.item)
      } else {
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    })
    .catch(function (error) {
      const resp = error.response;
      let error_message = "";
      if (resp?.data?.data !== undefined) {
        {
          Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
        }
      } else if (resp?.data?.data?.error !== undefined) {
        error_message = resp.data.data.error;
      } else if (resp?.data?.error !== undefined) {
        error_message = resp?.data.error;
      } else {
        error_message = resp?.data.message;
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    });
  }

  const setValues=(val)=>{
    let features = []
    let flag = false
    document.getElementById('name').value = val.role_name
    _name(val.role_name)
    document.getElementById('description').value = val.description
    _description(val.description)
    let data1 = [...data]
    val.permissions.map((item)=>{
      let perId = item.id
      data1.map((item)=>{
        let featureId = item.id
        item.permissions.map((item)=>{
          if(perId === item.id){
            let featureindex = data1.findIndex(x=>x.id===featureId)
            if(features.includes(featureindex)===false){
              features.push(featureindex)
            }
            let permissionIndex = data1[featureindex].permissions.findIndex(x=>x.id===perId)
            data1[featureindex].permissions[permissionIndex].active = true
          }
        })
      })
    })
    features.map((item)=>{
      for (let i=0 ; i<data1[item].permissions.length;i++){
        if(data1[item].permissions[i].active===false){
          flag = false
          break;
        }
        else{
          flag = true
        }
      }
      if(flag === true){
        data1[item].active =  true
      } else{
        data1[item].active =  false
      }
    })
    _data(data1)
  }

  const handleSwitch = (id) =>{
    let data1 = [...data]
    if(data1.length > 0){
      let index = data1.findIndex(x => x.id === id)
      if(data1[index].active){
        data1[index].active =  false
        data1[index].permissions.map((item)=>{
          item.active=false
        })
        _data(data1)
      } 
      else{
        data1[index].active =  true
        data1[index].permissions.map((item)=>{
          item.active=true
        })
        _data(data1)
      }
    }
  }

  const handleCheck =(id,recId) =>{
    let data1 = [...data]
    if(data1.length > 0){
      let index = data1.findIndex(x => x.id === recId)
      let flag = false
      let index1 = data1[index].permissions.findIndex(x => x.id === id)
      if(data1[index].permissions[index1].active){
        if(data1[index].permissions[index1].slug==="read"){
          let addIndex = data1[index].permissions.findIndex((x) => x.slug ==="add")
          if(addIndex!==-1 && data1[index].permissions[addIndex].deleted_at===null && data1[index].permissions[addIndex].deleted_at!==undefined){
            data1[index].permissions[addIndex].active = false
          }
          let deleteIndex = data1[index].permissions.findIndex((x) => x.slug ==="delete")
          if(deleteIndex!==-1 && data1[index].permissions[deleteIndex].deleted_at===null && data1[index].permissions[deleteIndex].deleted_at!==undefined){
            data1[index].permissions[deleteIndex].active = false
          }
          let updateIndex = data1[index].permissions.findIndex((x) => x.slug ==="update")
          if(updateIndex!==-1 && data1[index].permissions[updateIndex].deleted_at===null&&data1[index].permissions[updateIndex].deleted_at!==undefined){
            data1[index].permissions[updateIndex].active = false
          }
        }
        data1[index].permissions[index1].active = false
      }else{
        if(data1[index].permissions[index1].slug==="delete"||data1[index].permissions[index1].slug==="update"||data1[index].permissions[index1].slug==="add"){
          let readData = data1[index].permissions.findIndex((x) => x.slug ==="read")
          if(readData!==-1 && data1[index].permissions[readData].deleted_at===null&&data1[index].permissions[readData].deleted_at!==undefined){
            data1[index].permissions[readData].active = true
          }
        }
        data1[index].permissions[index1].active = true
      }
      for (let i=0 ; i<data1[index].permissions.length;i++){
        if(data1[index].permissions[i].active===false){
          flag = false
          break;
        }
        else{
          flag = true
        }
      }
      if(flag === true){
        data1[index].active =  true
      } else{
        data1[index].active =  false
      }
      _data(data1)

    }
  }

  const updateRole = () =>{
    let flag = false
    if((name===''||name===undefined)&&(description===''||description===undefined)){
      toast.error("Name and Description are required for Admin Role", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    else if(name===''||name===undefined){
      toast.error("Name field can't be blank", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    else if(description===''||description===undefined){
      toast.error("Description field can't be blank", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    else{
      flag = true
    }
    if(flag===true){
      let values = {}
      let permissionData = []
      values['role_name'] = name
      values['description'] = description
      if(data.length>0){
        data.map((item)=>{
            item.permissions.map((item)=>{
            if(item.active===true){
              permissionData.push(item.id)
            }
          })
        })
      }
      if(permissionData.length<=0){
        toast.error("Please provide some permissions to role", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      else{
        values['permission_ids'] = permissionData
        _loading(true)
        API.post(APP_URLS.UPDATE_ADMIN_ROLE+`/${roleId}?_method=PATCH`, values)
        .then(res => {
            _loading(false)
            const resp = res.data;
            if(resp.success===true){
              toast.success("Role has been updated successfully.", {
                position: toast.POSITION.TOP_RIGHT
              })
              navigate("/admin-roles-management?"+
              (query.get("role_name") !== null
                ? "role_name=" + query.get("role_name") + "&"
                : "") +
              (query.get("per_page") !== null 
                ? "per_page=" + query.get("per_page") + "&"
                : "") +
              (query.get("page") !== null
                ? "page=" + query.get("page")
                : "")
            )
            }
            else{
              toast.error(resp.message, {
                position: toast.POSITION.TOP_RIGHT
              })
            }
        })
        .catch(function (error) {
            _loading(false)
            const resp = error.response;
            let error_message = '';
            if (resp.data.data !== undefined && resp.data.data !== null) {
              {
                Object.keys(resp.data.data).map(
                  (error, index) =>(error_message = resp.data.data[error][0])
                );
              }
            } else if (resp.data.data?.error !== undefined) {
                error_message =  resp.data.data.error;
            } else if(resp.data?.error!== undefined){
                error_message =  resp.data.error
            } else{
                error_message =  resp.data.message
            }
            toast.error(error_message, {
              position: toast.POSITION.TOP_RIGHT
            })
        }); 
      }
    }
  }


  return (
    <RoleWrapper>
      <div>
        <div className="mt-2">
          <div className="backContainer ms-4" onClick={() => navigate("/admin-roles-management?"+
            (query.get("role_name") !== null
              ? "role_name=" + query.get("role_name") + "&"
              : "") +
            (query.get("per_page") !== null 
              ? "per_page=" + query.get("per_page") + "&"
              : "") +
            (query.get("page") !== null
              ? "page=" + query.get("page")
              : "")
          )}>
            <span className="backButton">x</span>
            <span className="px-3 backButton">|</span>
            <span className="backButton">Cancel</span>
          </div>
        </div>
        <div>
          <h1>EDIT ADMIN ROLE</h1>
        </div>
        <div className="actionContainer d-flex justify-content-end">
          <button className={(loading?"disabled":"")+" btn"} onClick={updateRole} disabled={loading}>
            SAVE
          </button>
        </div>
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="row mb-3 pt-5">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="role" className="htmlFor=-label fieldLabel pb-1">
                    Role
                  </label>
                  <input type="text" id="name" className="form-control mt-2" onChange={(e) => _name(e.target.value)} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="role" className="htmlFor=-label fieldLabel pb-1">
                    Description
                  </label>
                  <textarea className="form-control mt-2" id="description" rows={1} onChange={(e) => _description(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mt-3">
                <div className="role-table-container py-2">
                  <table className="role-table">
                    <thead className="mb-3">
                      <tr className="header">
                        <th className="feature py-2">Feature</th>
                        <th className="toggle py-2"></th>
                        <th className="permissions py-2">Add</th>
                        <th className="permissions py-2">Read</th>
                        <th className="permissions py-2">Update</th>
                        <th className="permissions py-2">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => {
                        let add = item.permissions.find((o) => o.slug === "add");
                        let read = item.permissions.find((o) => o.slug === "read");
                        let update = item.permissions.find((o) => o.slug === "update");
                        let deleted = item.permissions.find((o) => o.slug === "delete");
                        return (
                          <tr key={index}>
                            <td className="feature">
                              <span className="ps-5">{(item.name["en"].charAt(0).toUpperCase() + item.name["en"].slice(1)).replace("_", " ")}</span>
                            </td>
                            <td className="toggle">
                              <label className="form-check form-switch float-right">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={item.active}
                                  onChange={() => {
                                    handleSwitch(item.id);
                                  }}
                                />
                                <span className="slider round"></span>
                              </label>
                            </td>
                            <td className="permissions">
                              <div className="round-check">
                                <input
                                  type="checkbox"
                                  id={"add" + item.id}
                                  className={(add === undefined || add.deleted_at !== null ? "disabledField" : "") + " checkbox-round"}
                                  checked={item.permissions.find((o) => o.slug === "add")?.active}
                                  disabled={add === undefined || add.deleted_at !== null ? true : false}
                                  onChange={() => {
                                    let val = item.permissions.find((o) => o.slug === "add");
                                    handleCheck(val?.id, item.id);
                                  }}
                                />
                                <label htmlFor={"add" + item.id}></label>
                              </div>
                            </td>
                            <td className="permissions">
                              <div className="round-check">
                                <input
                                  type="checkbox"
                                  id={"read" + item.id}
                                  className={(read === undefined || read.deleted_at !== null ? "disabledField" : "") + " checkbox-round"}
                                  checked={item.permissions.find((o) => o.slug === "read")?.active}
                                  disabled={read === undefined || read.deleted_at !== null ? true : false}
                                  onChange={() => {
                                    let val = item.permissions.find((o) => o.slug === "read");
                                    handleCheck(val?.id, item.id);
                                  }}
                                />
                                <label htmlFor={"read" + item.id}></label>
                              </div>
                            </td>
                            <td className="permissions">
                              <div className="round-check">
                                <input
                                  type="checkbox"
                                  id={"update" + item.id}
                                  className={(update === undefined || update.deleted_at !== null ? "disabledField" : "") + " checkbox-round"}
                                  checked={item.permissions.find((o) => o.slug === "update")?.active}
                                  disabled={update === undefined || update.deleted_at !== null ? true : false}
                                  onChange={() => {
                                    let val = item.permissions.find((o) => o.slug === "update");
                                    handleCheck(val?.id, item.id);
                                  }}
                                />
                                <label htmlFor={"update" + item.id}></label>
                              </div>
                            </td>
                            <td className="permissions">
                              <div className="round-check">
                                <input
                                  type="checkbox"
                                  id={"delete" + item.id}
                                  className={(deleted === undefined || deleted.deleted_at !== null ? "disabledField" : "") + " checkbox-round"}
                                  checked={item.permissions.find((o) => o.slug === "delete")?.active}
                                  disabled={deleted === undefined || deleted.deleted_at !== null ? true : false}
                                  onChange={() => {
                                    let val = item.permissions.find((o) => o.slug === "delete");
                                    handleCheck(val?.id, item.id);
                                  }}
                                />
                                <label htmlFor={"delete" + item.id}></label>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                {/* <div className="mb-3 align-items-center mt-5">
              <div className="col-md-12 text-end">
                <button type="button" className="save-btn" onClick={updateRole}>
                  SAVE
                </button>
              </div>
            </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </RoleWrapper>
  );
};

export default EditAdminRole;
