import { useEffect, useState, useRef } from 'react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import styled from 'styled-components';
import { CountriesLists } from '../../utils';
import { FaLocation } from '../../../assets/Images/icon';

export const GoogleMapApi = (props) => {
  const countryId = props.countryId;
  const mapInput = useRef(null);
  const [address, setAddress] = useState('');
  const [flag, setFlag] = useState(true);
  const [countryCode, setCountryCode] = useState('');
  const [allAddress, setAllAddress] = useState({
    stNo: '',
    road: '',
    postalCode: '',
    postal_code_suffix: '',
    locality: '',
    sublocality: '',
    country: '',
    formatted_address: '',
    lat: '',
    lng: '',
    place_id: '',
    google_establish_name: '',
    administrative_area_level_1: '',
    administrative_area_level_2: '',
  });

  useEffect(() => {
    if (props.setFormattedAddress) {
      setAddress(props.setFormattedAddress);
    }
  }, [props.setFormattedAddress])

  //fetching total countries
  const getCountry = (id) => {
    CountriesLists().then((data) => {
      data.map((country) => {
        if (country.id === id) {
          setCountryCode(country.country_name.toLowerCase());
        }
      });
    });
  };

  //searching option for country
  const searchOptions = {
    componentRestrictions: { country: countryCode },
  };

  const handleChange = (address) => {
    setAddress(address);
    props.googleMapData(allAddress); //passsing address details to parent component
  };

  // Clear address when country changes
  useEffect(() => {
    if (props.resetAddress) {
      setAddress(''); // Clear the address field
      setAllAddress({
        stNo: '',
        road: '',
        postalCode: '',
        postal_code_suffix: '',
        locality: '',
        sublocality: '',
        country: '',
        formatted_address: '',
        lat: '',
        lng: '',
        place_id: '',
        google_establish_name: '',
        administrative_area_level_1: '',
        administrative_area_level_2: '',
      }); // Reset all address fields
    }
  }, [props.resetAddress]);

  //fetching country focus input field when selecting country
  useEffect(() => {
    getCountry(countryId);
    mapInput.current.focus();
  }, [countryId]);

  useEffect(() => {
    if (flag && props.setFormattedAddress) {
      setAddress(props.setFormattedAddress);
      setFlag(false);
    }
  }, [flag, props.setFormattedAddress]);

  useEffect(() => {
    props.googleMapData(allAddress);
  }, [allAddress]);

  //getting address details on selecting address from address input field
  const handleSelect = async (address) => {
    geocodeByAddress(address)
      .then((results) => {
        setAddress(results[0].formatted_address);
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();
        const placeId = results[0].place_id;

        setAllAddress((prev) => ({
          ...prev,
          formatted_address: results[0].formatted_address,
          lat,
          lng,
          place_id: placeId,
        }));

        results[0].address_components.forEach((component) => {
          const componentType = component.types[0];
          switch (componentType) {
            case 'street_number':
              setAllAddress((prev) => ({ ...prev, stNo: component.long_name }));
              break;
            case 'route':
              setAllAddress((prev) => ({ ...prev, road: component.short_name }));
              break;
            case 'postal_code':
              setAllAddress((prev) => ({ ...prev, postalCode: component.long_name }));
              break;
            case 'postal_code_suffix':
              setAllAddress((prev) => ({ ...prev, postal_code_suffix: component.long_name }));
              break;
            case 'locality':
              setAllAddress((prev) => ({ ...prev, locality: component.long_name }));
              break;
            case 'sublocality':
              setAllAddress((prev) => ({ ...prev, sublocality: component.long_name }));
              break;
            case 'administrative_area_level_1':
              setAllAddress((prev) => ({
                ...prev,
                administrative_area_level_1: component.short_name,
              }));
              break;
            case 'administrative_area_level_2':
              setAllAddress((prev) => ({
                ...prev,
                administrative_area_level_2: component.short_name,
              }));
              break;
            case 'country':
              setAllAddress((prev) => ({ ...prev, country: component.long_name }));
              break;
            default:
              break;
          }
        });
      })
      .catch((error) => console.error('Error', error));
  };

  return (
    <Wrapper>
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className='input-map'>
            <input
              style={{
                borderTop: '1px solid #ccc',
                borderBottom: '1px solid #ccc',
                borderRight: '1px solid #ccc',
                padding: '17px',
              }}
              ref={mapInput}
              {...getInputProps({
                placeholder: 'Enter Address',
                className: 'location-search-input',
              })}
            />
            <div className='autocomplete-dropdown-container'>
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, i) => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                const style = suggestion.active
                  ? {
                    backgroundColor: '#fff',
                    cursor: 'pointer',
                    color: '#007bff',
                    padding: '8px ',
                    fontSize: '12px',
                    borderBottom: '1px solid #ddd',
                  }
                  : {
                    backgroundColor: '#ffffff',
                    cursor: 'pointer',
                    color: '#979797',
                    padding: '8px',
                    fontSize: '12px',
                    borderBottom: '1px solid #ddd',
                  };
                return (
                  <div
                    key={i}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span className='map-icon'>
                    <FaLocation />
                    </span>
                    <span className='map-icon-address'>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  .autocomplete-dropdown-container {
    position: absolute;
    background: #fff;
    width: 100%;
    left: 0;
    right: 0;
    top: 38px;
    z-index: 3;
    max-height: 200px;
    overflow-y: auto;
    .map-icon {
      margin-right: 20px;
      svg {
        width: 12px;
        height: auto;
      }
    }
    .suggestion-item--active,
    .suggestion-item {
      position: relative;
      min-height: 38px;
    }
  }
`;

export default GoogleMapApi;
