import styled from "styled-components";

export const PopupWrapper = styled.div`
.MuiOutlinedInput-root {
    padding: 0px !important; 
}
.MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 3px !important;
}
.MuiOutlinedInput-notchedOutline {
    border:none !important;
}
.MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
    right: 0px !important;
}
.custom-select-wrapper{
    position:relative;  
}
 
.custom-select-wrapper > .select-input-wrapper{
    padding: 0 10px; 
    border: 1px solid lightgray; 
}
.custom-select-wrapper input{
    height:28px; 
    border:none;
}
.custom-select-wrapper p{
    text-align:center;
    padding-top:1rem;
}
.filter-values-wrapper{  
    width:100%;  
    background:white;
    max-height:50vh;
    height:max-content; 
     border:1px solid lightgray;
     margin-top:1rem; 
    overflow-y:scroll;
}
.filter-values-wrapper > div{
    min-height:1.5rem;
    height:auto; 
    padding:10px ;
     
}
.filter-values-wrapper > div:hover{
    background-color:#0dcaf052;
}
`;
