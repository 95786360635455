import React, { useEffect, useState } from 'react'
import loginImage from "../../assets/Images/image/login.png";
import logo from "../../assets/Images/icons/c2blogo.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoginWrapper } from "./style/login";
import { APP_URLS } from '../../api/url';
import API from '../../api/axios';
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';

const InvitationPage = (props) => {

  const [loaded,_loaded]=useState(false)
  const [passwordRequired,_passwordRequired]=useState(false)
  const [message,_message] = useState('')

  const {roleId,token} = useParams()

  const search = useLocation().search;
  const status = new URLSearchParams(search).get('status');

  const navigate = useNavigate()

  useEffect(()=>{
    localStorage.clear();
    invitationAction()
  },[])

  const invitationAction = () => {
    API.post(APP_URLS.INVITE_ACTION +`/${roleId}/${token}${search}&_method=PATCH`)
    .then(res => {
        const resp = res.data;
        if(resp.success===true){
          _loaded(true)
          _passwordRequired(resp.data.password_required)
          _message(resp.message)
          toast.success(resp.message, {
            position: toast.POSITION.TOP_RIGHT
          })
        }
        else{
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT
          })
        }
    })
    .catch(function (error) {  
        const resp = error.response;
        let error_message = '';
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map(
              (error, index) =>(error_message = resp.data.data[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
            error_message =  resp.data.data.error;
        } else if(resp.data?.error!== undefined){
            error_message =  resp.data.error
        } else{
            error_message =  resp.data.message
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT
        })
    });  
  }

  return (
    <>
      <LoginWrapper>
        <div className='container-fluid login-admin'>
          <div className='header'>
            <div className='headContainer'>
              <img src={logo} alt="" onClick={()=>navigate('/login')}/>
            </div>
          </div>

          <div className="formContainer container-full mt-sm-5">
            {loaded?
            <>
            <p className='text-center'>{message}</p>
            {status==="accepted"?
            passwordRequired===true?
            <span className='text-center mt-3'>We have sent an email. Please set your password to continue</span>:
            <span className='text-center mt-3 please-login' onClick={()=>navigate('/login')}>Please login continue</span>
            :''}
            </>
            :
            <div className='d-flex justify-content-center'>              
              <ThreeDots 
                height="150" 
                width="150" 
                radius="9"
                color="#637df4" 
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
                />
            </div>}
          </div>

          <footer>
            <p>All rights reserved - CarToBike.com 2022</p>
          </footer>
        </div>
      </LoginWrapper>
    </>
  );
};

export default InvitationPage