import React, { useState } from "react";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { PlanWrapper } from "../../Plan/CreatePlan/style/plan";
import Switch from "react-switch";
import { planType } from "../../../config/constant";

const CreateCredits = () => {
    const [filtereddata, _filtereddata] = useState([])
    const [loading, _loading] = useState(false);
    const [checked, setChecked] = useState(false);
    const navigate = useNavigate()

    const handleSwitch = () => {
        setChecked(!checked);
    };

    const formik = useFormik({
        initialValues: {
            name: "",
            nickname: "",
            credit_points: null,
            amount: "",
            active: '',
            validity_in_days: '',
            is_popular: checked ? 1 : 0
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Credit plan name is required").min(2, "Credit plan name must be atleast 2 characters").max(50, "Credit plan name must be atmost 50 characters"),
            nickname: Yup.string().required("Nickname is required").min(2, "Nickname must be atleast 2 characters").max(50, "Nickname must be atmost 50 characters"),
            credit_points: Yup.number().typeError('Credit points is required').required("Credit points is required").min(1, "Credit points must be atleast 1 characters").max(5000, "The credit value cannot exceed 5000 points."),
            active: Yup.string().required("Select status value"),
            amount: Yup.number().required("Price is required").positive("Amount must be positive").min(1, "Amount must be greater than 0").max(2000, "Amount must be smaller than 2000"),
            validity_in_days: Yup.number().required("Validity period is required").positive("Validity period must be positive").min(1, "Validity period must be greater than 0").max(365, "Validity period must be smaller than 365")
        }),
        validateOnChange: true,
        onSubmit: (values) => {
            if (!loading) {
                _loading(true)
                let data = {};
                data['name'] = values.name
                data['nickname'] = values.nickname
                data['amount'] = values.amount
                data['active'] = values.active
                data['type'] = planType.ONE_TIME
                data['validity_in_days'] = values.validity_in_days
                data['is_popular'] = checked ? 1 : 0
                data['credit_points'] = values.credit_points

                API.post(APP_URLS.ADD_PLAN, data)
                    .then((res) => {
                        const resp = res.data;
                        _loading(false);
                        if (resp.success === true) {
                            toast.success(res.data.message, {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                            navigate('/credits')
                        } else {
                            toast.error(resp.message, {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        }
                    })
                    .catch(function (error) {
                        const resp = error.response;
                        _loading(false);
                        let error_message = "";
                        if (resp.data.data !== undefined && resp.data.data !== null) {
                            if (resp.data.data.message) {
                                error_message = resp.data.data.message
                            } else {
                                {
                                    Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
                                }
                            }
                        } else if (resp.data.data?.error !== undefined) {
                            error_message = resp.data.data.error;
                        } else if (resp.data?.error !== undefined) {
                            error_message = resp.data.error;
                        } else {
                            error_message = resp.data.message;
                        }
                        toast.error(error_message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    });
            }

        },
    });

    return (
        <>
            <PlanWrapper>
                <div className="mt-2">
                    <div className="backContainer ms-4" onClick={() => navigate("/credits")}>
                        <span className="backButton">x</span>
                        <span className="px-3 backButton">|</span>
                        <span className="backButton">Cancel</span>
                    </div>
                </div>
                <form className="auctionMainContainer"
                    onSubmit={(e) => {
                        formik.handleSubmit(e)
                        e.preventDefault();
                    }}>
                    <div className="headerContainer d-flex justify-content-center">
                        <h1>CREATE CREDIT PLAN</h1>
                    </div>
                    <div className="actionContainer d-flex justify-content-end">
                        <button className={(loading ? 'disabled' : '') + " btn"} type="submit" disabled={loading}>{loading ? 'Saving...' : 'Save'}</button>
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="productInfo-root">
                                    <div className="container">
                                        <div className="input-section row">
                                            <div className="formInput col-md-4">
                                                <label htmlFor="name" className="fieldLabel mb-2">
                                                    Credit Plan Name
                                                </label>
                                                <input
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    className="form-control"
                                                    value={formik.values.name}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.name && formik.errors.name ? <div className="error">{formik.errors.name}</div> : null}
                                            </div>
                                            <div className="formInput col-md-4">
                                                <label htmlFor="name" className="fieldLabel mb-2">
                                                    Nickname
                                                </label>
                                                <input
                                                    type="text"
                                                    id="nickname"
                                                    name="nickname"
                                                    className="form-control"
                                                    value={formik.values.nickname}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.nickname && formik.errors.nickname ? <div className="error">{formik.errors.nickname}</div> : null}
                                            </div>
                                            <div className="formInput col-md-4">
                                                <label htmlFor="name" className="fieldLabel mb-2">
                                                    Number of Credits
                                                </label>
                                                <input
                                                    type="number"
                                                    id="credit_points"
                                                    name="credit_points"
                                                    className="form-control"
                                                    value={formik.values.credit_points}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.credit_points && formik.errors.credit_points ? <div className="error">{formik.errors.credit_points}</div> : null}
                                            </div>


                                        </div>
                                        <div className="price-section">
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="formInput">
                                                        <label htmlFor="active" className="fieldLabel mb-2">
                                                            Status
                                                        </label>
                                                        <select name="active"
                                                            id="active"
                                                            className="form-control inputTag"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.active}
                                                            onBlur={formik.handleBlur}
                                                        >
                                                            <option hidden>Select</option>
                                                            <option value='1'>Active</option>
                                                            <option value='0'>Inactive</option>
                                                        </select>
                                                        {formik.touched.active && formik.errors.active ? <div className="error">{formik.errors.active}</div> : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="formInput">
                                                        <label htmlFor="price" className="fieldLabel mb-2">
                                                            Price
                                                        </label>
                                                        <div className="inputTag">
                                                            <input
                                                                type="number"
                                                                id="amount"
                                                                name="amount"
                                                                step=".01"
                                                                className="form-control"
                                                                min={1}
                                                                value={formik.values.amount}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                            <p>EUR</p>
                                                        </div>
                                                        {formik.touched.amount && formik.errors.amount ? <div className="error">{formik.errors.amount}</div> : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="formInput">
                                                        <label htmlFor="price" className="fieldLabel mb-2">
                                                            Validity
                                                        </label>
                                                        <div className="inputTag">
                                                            <input
                                                                type="number"
                                                                id="validity_in_days"
                                                                name="validity_in_days"
                                                                step=".01"
                                                                className="form-control"
                                                                min={1}
                                                                max={365}
                                                                value={formik.values.validity_in_days}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                            <p>Days</p>
                                                        </div>
                                                        {formik.touched.validity_in_days && formik.errors.validity_in_days ? <div className="error">{formik.errors.validity_in_days}</div> : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-2">
                                                <div className="formInput">
                                                    <label className="fieldLabel mb-2">Type</label>
                                                    <button onClick={(e) => e.preventDefault()}>One Time</button>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 inputSwitchgroup">
                                                <div className="formInput">
                                                    <label className="fieldLabel mb-2">Is Popular</label>
                                                    <Switch
                                                        name="is_popular"
                                                        onChange={handleSwitch}
                                                        checked={checked}
                                                        className="react-switch"
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                        offColor="#D9D9D9"
                                                        onColor="#7589EC"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </PlanWrapper>
        </>
    );
};

export default CreateCredits;
