import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import notification from "../../Images/icons/notification.svg";
import { NotificationWrapper } from "./Style/Notificationwrapper";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import API from "../../../api/axios";
import { onMessageListener } from "../../../firebase/firebase";
import All from "./childComponent/All";
import Invitation from "./childComponent/Invitation";
function TemporaryDrawer() {
  const [state, setState] = useState(false);
  const [active, _active] = useState("all");
  const [allNotification, setAllNotification] = useState([]);
  const [dataDeleted, _dataDeleted] = useState(false);
  const [page, _page] = useState(1);
  const [scrollFlag, _scrollFlag] = useState(true);
  const [badgeCount, setBadgeCount] = useState();
  const [userId, _userId] = useState(JSON.parse(localStorage.getItem("user")));
  const [inviteUser, setInviteUser] = useState("");
  const [links, setLinks] = useState(true);
  const [badgeCheck, setBadgeCheck] = useState(false);

  const [loading,_loading] = useState(false)

  onMessageListener()
    .then((payload, messaging) => {
      setBadgeCount(payload.data.new_notification_count);
      setBadgeCheck(true);
      setAllNotification([]);
    })
    .catch((err) => console.log("failed: ", err));

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState(open);
  };

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      if (scrollFlag) {
        let increamenValue = page + 1;
        _page(increamenValue);
        _scrollFlag(false);
      }
    } else {
      _scrollFlag(true);
    }
  };

  function getAllNotification(type, val) {
    API.get(APP_URLS.GET_ALL_NOTIFICATION + `?user_id=${userId?.id}&app_type=1&page=${page}${val ? `&type=${val}` : ""}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          setLinks(resp.data.notifications.next_page_url);
          setBadgeCount(resp.data.new_notification_count);
          if (type === "action") {
            setAllNotification(resp.data.notifications.data);
            _loading(false)
          } else {
            resp.data.notifications.data.map((item) => {
              setAllNotification((arr) => {
                return [...arr, item];
              });
            });
          }
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }

  const handleDelete = (index) => {
    if(!loading){
      _loading(true)
      API.delete(APP_URLS.DELETE_NOTIFICATION + `/${index}`)
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) {
            getAllNotification("action");
            _page(1);
          } else {
            _loading(false)
            toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(function (error) {
          _loading(false)
          const resp = error.response;
          let error_message = "";
          if (resp.data.data !== undefined && resp.data.data !== null) {
            {
              Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
            }
          } else if (resp.data.data?.error !== undefined) {
            error_message = resp.data.data.error;
          } else if (resp.data?.error !== undefined) {
            error_message = resp.data.error;
          } else {
            error_message = resp.data.message;
          }
          toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const handleSingleRead = (index) => {
    if(!loading){
      _loading(true)
      API.post(APP_URLS.MARK_AS_SINGLE_READ + `/${index}/read?_method=patch`)
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) {
            getAllNotification("action");
          } else {
            _loading(false)
            toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(function (error) {
          _loading(false)
          const resp = error.response;
          let error_message = "";
          if (resp.data.data !== undefined && resp.data.data !== null) {
            {
              Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
            }
          } else if (resp.data.data?.error !== undefined) {
            error_message = resp.data.data.error;
          } else if (resp.data?.error !== undefined) {
            error_message = resp.data.error;
          } else {
            error_message = resp.data.message;
          }
          toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const getCountNotification = () => {
    API.get(APP_URLS.NOTIFICATION_COUNT+"?app_type=1")
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          setBadgeCount(resp.data.new_notification_count);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getLastOpenAt = () => {
    API.post(APP_URLS.LAST_OPENED_NOTIFICATION + `/${userId.id}/last_opened_at?app_type=1&_method=patch`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          getCountNotification();
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  useEffect(() => {
    if (inviteUser !== "" && inviteUser !== null) {
      getAllNotification("none", inviteUser);
      _dataDeleted(false);
    } else {
      if (links !== null) {
        getCountNotification();
        getAllNotification();
        _dataDeleted(false);
      } else if (page === 1) {
        getAllNotification();
        _dataDeleted(false);
      }
    }
  }, [page, dataDeleted, inviteUser]);

  useEffect(() => {
    if (badgeCheck === true) {
      getAllNotification();
      setBadgeCheck(false);
    }
  }, [badgeCount]);

  const list = (anchor) => (
    <NotificationWrapper>
      <Box sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 400 }} role="presentation" onKeyDown={toggleDrawer(false)}>
        <div className="header my-3">
          <p className="headTitle">NOTIFICATIONS</p>
        </div>
        <div className="buttons">
          <button
            className={active === "all" ? "add-button" : "" + " inactivebtn"}
            onClick={(e) => {
              _active("all");
              setInviteUser("");
              setAllNotification([]);
              _page(1);
            }}
          >
            All
          </button>
          <button
            className={active === "invitation" ? "add-button" : "" + " inactivebtn"}
            onClick={(e) => {
              _active("invitation");
              setInviteUser("invitation");
              setAllNotification([]);
              _page(1);
            }}
          >
            Invitation
          </button>
        </div>
        <div
          className="notifications"
          onScroll={(e) => {
            if (links !== null) {
              handleScroll(e);
            }
          }}
        >
          {active === "all" ? <All setState={setState} allNotification={allNotification} handleSingleRead={handleSingleRead} handleDelete={handleDelete} /> : active === "invitation" ? <Invitation allNotification={allNotification} handleSingleRead={handleSingleRead} handleDelete={handleDelete} /> : <All />}
        </div>
      </Box>
    </NotificationWrapper>
  );

  return (
    <NotificationWrapper>
      <div className="d-flex align-items-center justify-content-center mb-2">
        {badgeCount > 0 ? <span className="badge bg-danger valueBadge">{badgeCount}</span> : ""}
        <span onClick={getLastOpenAt}>
          <img src={notification} className="notificationIcon" id="notificationIcon" onClick={toggleDrawer(true)} />
        </span>
        <Drawer anchor="right" open={state} onClose={toggleDrawer(false)}>
          {list("right")}
        </Drawer>
      </div>
    </NotificationWrapper>
  );
}
export default TemporaryDrawer;
