import React from 'react'
import time from '../../../../../assets/Images/image/time.png'
import wifi from '../../../../../assets/Images/image/wifi.png'
import network from '../../../../../assets/Images/image/network.png'
import battery from '../../../../../assets/Images/image/battery.png'

const MobileStatusBar = () => {
  return (
    <div className='status-bar px-3 py-1'>
    <img src={time} alt="" className='battery-indicator' />
    <div>
      <img src={network} alt="" className='me-1'/>
      <img src={wifi} alt="" className='me-1'/>
      <img src={battery} alt=""/>
    </div>
  </div>
  )
}

export default MobileStatusBar