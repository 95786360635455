import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import { toast } from "react-toastify";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import NoRecord from "../../../assets/SharedComponents/Table/NoRecord";

const FrontOffice = () => {

  const [data,_data] = useState([])

  useEffect(() => {
    getBackOfficeFeatures()
  }, [])
  
  const getBackOfficeFeatures =() =>{
    API.get(APP_URLS.LIST_FEATURES + `?filter[type]=3&with_permissions=true`)
    .then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        // const UpdateData = resp.data.items.map((item)=> {
        //   const itemsPermission = item.permissions;
        //     itemsPermission.map((item)=> {
        //       if(item.deleted_at != null){
        //         item['active'] = false
        //       }else{
        //         item['active'] = true
        //       }
        //       return item
        //     })
        //   return item
        // })
        // _data(UpdateData);
        _data(resp.data.items)
      } else {
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    })
    .catch(function (error) {
      const resp = error.response;
      let error_message = "";
      if (resp.data.data !== undefined && resp.data.data !== null) {
        {
          Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
        }
      } else if (resp.data.data?.error !== undefined) {
        error_message = resp.data.data.error;
      } else if (resp.data?.error !== undefined) {
        error_message = resp.data.error;
      } else {
        error_message = resp.data.message;
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    });
  }

  const UpdatePermission =(perID, status) =>{
    let data ={}
    data["status"] = status===true?1:0;
    API.post(APP_URLS.TOGGLE_PERMISSION+ `/${perID}/update?_method=patch`,data)
    .then(res => {
      const resp = res.data;
      if(resp.success===true){
        toast.success(`Permission ${status===true?"Enabled":"Disabled"} Successfully.`, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
      else{
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })
    .catch(function (error) {  
      const resp = error.response;
      let error_message = '';
      if (resp.data.data !== undefined && resp.data.data !== null) {
        {
          Object.keys(resp.data.data).map(
            (error, index) =>(error_message = resp.data.data[error][0])
          );
        }
      } else if (resp.data.data?.error !== undefined) {
          error_message =  resp.data.data.error;
      } else if(resp.data?.error!== undefined){
          error_message =  resp.data.error
      } else{
          error_message =  resp.data.message
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT
      })
    });
  }

  const showID =(val,arr)=>{
    let id = arr.find(o => o.slug === val)
    if(id){
      return id.id
    }
    else{
      return "-"
    }
  }

  const toggleswitcher = (id, recId) => {
    let data1 = [...data];
    if (data1.length > 0) {
      let index = data1.findIndex((x) => x.id === recId);
      let index1 = data1[index].permissions.findIndex((x) => x.id === id);
      if (data1[index].permissions[index1].active) {
        if(data1[index].permissions[index1].slug==="read"){
          let addIndex = data1[index].permissions.findIndex((x) => x.slug ==="add")
          if(addIndex!==-1){
            if(data1[index].permissions[addIndex].active===true){
              UpdatePermission(data1[index].permissions[addIndex].id, false);
            }
            data1[index].permissions[addIndex].active = false; 
          }
          let deleteIndex = data1[index].permissions.findIndex((x) => x.slug ==="delete")
          if(deleteIndex!==-1){
            if(data1[index].permissions[deleteIndex].active===true){
              UpdatePermission(data1[index].permissions[deleteIndex].id, false);
            }
            data1[index].permissions[deleteIndex].active = false; 
          }
          let updateIndex = data1[index].permissions.findIndex((x) => x.slug ==="update")
          if(updateIndex!==-1){
            if(data1[index].permissions[updateIndex].active===true){
              UpdatePermission(data1[index].permissions[updateIndex].id, false);
            }
            data1[index].permissions[updateIndex].active = false; 
          }
        }
        data1[index].permissions[index1].active = false;
        _data(data1);
      } else {
        if(data1[index].permissions[index1].slug==="delete"||data1[index].permissions[index1].slug==="update"||data1[index].permissions[index1].slug==="add"){
          let readData = data1[index].permissions.findIndex((x) => x.slug ==="read")
          if(readData!==-1){
            if(data1[index].permissions[readData].active===false){
              data1[index].permissions[readData].active = true; 
              UpdatePermission(data1[index].permissions[readData].id, true);
            }
          }
        }
        data1[index].permissions[index1].active = true;
        _data(data1);
      }
    }
  };

  return (
    <div className="container">
      <div className="tableContainer">
        <table className="table auctionTable">
          <thead className="tableHead">
            <tr className="table-row">
              <th colSpan={3} className="w-60"><h3>FEATURES</h3></th>
              <th colSpan={4} className="w-40"><h3>PERMISSIONS</h3></th>
            </tr>
            <tr className="table-row">
              <th className="w-10">Feature Id</th>
              <th className="w-15">FEATURE NAME</th>
              <th className="w-25">Description</th>
              <th className="text-center w-80 border-left">
                <span>Id</span>
                  ADD
                {/* <span>No</span> */}
              </th>
              <th className="text-center w-80">
                <span>Id</span>
                READ
                {/* <span>No</span> */}
              </th>
              <th className="text-center w-80">
                <span>Id</span>
                UPDATE
                {/* <span>No</span> */}
              </th>
              <th className="text-center w-80 border-right">
                <span>Id</span>
                DELETE
                {/* <span>No</span> */}
              </th>
            </tr>
          </thead>
          <tbody>
          {data.length>0?
            <tr className="table-row">
                <td>14</td>
                <td className="feature-name">Auction place</td>
                <td className="w-25">Allows bidding, see bidding prices </td>
                <td className="text-center w-80 border-left">
                  <span>103</span>
                  <span className="d-block mt-1">
                    <Switch
                      onChange={()=>{
                        // toggleswitcher(val?.id,recID)
                      }}
                      className="react-switch"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      offColor="#D9D9D9"
                      onColor="#7589EC"
                    />
                  </span>
                  {/* <span className="block">243</span> */}
                </td>
                <td className="text-center w-80">
                  <span>103</span>
                  <span className="d-block mt-1">
                    <Switch
                      onChange={()=>{
                        // toggleswitcher(val?.id,recID)
                      }}
                      className="react-switch"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      offColor="#D9D9D9"
                      onColor="#7589EC"
                    />
                  </span>
                  {/* <span className="block">243</span> */}
                </td>
                <td className="text-center w-80">
                  <span>103</span>
                  <span className="d-block mt-1">
                    <Switch
                      onChange={()=>{
                        // toggleswitcher(val?.id,recID)
                      }}
                      className="react-switch"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      offColor="#D9D9D9"
                      onColor="#7589EC"
                    />
                  </span>
                  {/* <span className="block">243</span> */}
                </td>
                <td className="text-center w-80 border-right">
                  <span>103</span>
                  <span className="d-block mt-1">
                    <Switch
                      onChange={()=>{
                        // toggleswitcher(val?.id,recID)
                      }}
                      className="react-switch"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      offColor="#D9D9D9"
                      onColor="#7589EC"
                    />
                  </span>
                  {/* <span className="block">243</span> */}
                </td>
            </tr>:
            <NoRecord />
          }
          </tbody>
        </table>      
      </div> 
    </div>
  );
};

export default FrontOffice;
