import React, { useEffect, useState } from 'react'
import MainLayout from '../../../assets/Layout/MainLayout';
import { CreateCensuredWrapper } from './Style/createCensuredWrapper';
import defaultprofile from "../../../assets/Images/icons/profile-pic.png"
import API from '../../../api/axios';
import { APP_URLS } from '../../../api/url';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { userTypeName } from '../../../config/constant';

let modules_disabled = []

const CreateCompanyCensured = () => {

  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const [appVal,setAppVal] = useState("2")
  const [duration,_duration] = useState('')
  const [commentsArr,_commentsArr] = useState([])

  const [loading,_loading] = useState(false)

  const [keyword,_keyword] = useState("")
  const [suggestionTexts, _suggestionTexts] = useState({});
  const [suggestionLoaded, _suggestionLoaded] = useState(false);

  const [name,_name] = useState('')
  const [status,_status] = useState('')
  const [profilePic,_profilePic] = useState('')
  const [country,_country] = useState('')


  const [ads, _ads] = useState(false);
  const [bids, _bids] = useState(false);


  useEffect(() => {
    getCompanyDetails()
    modules_disabled = []
  }, [])

  useEffect(()=>{
    if(keyword.length>1){
      getSuggestiontext()
    }
  },[keyword])

  const { companyId } = useParams()

  const navigate = useNavigate()
  
  const getSuggestiontext = () => {
    API.get(APP_URLS.LIST_SUGGESTION_TEXT + `?filter[name]=${keyword}&limited_data=true&filter[type]=3`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          let data = {}
          resp.data.items.forEach((element)=>{
            data[`${element.id}_${element.name}`] = element.name
          })
          _suggestionTexts(data);
          _suggestionLoaded(true)
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getCompanyDetails = () =>{
    API.get(APP_URLS.VIEW_SUBSIDIARY + `/${companyId}`)
    .then(res => {
      const resp = res.data;
      if(resp.success===true){
        _name(resp.data.subsidiary.name?resp.data.subsidiary.name.charAt(0).toUpperCase() + resp.data.subsidiary.name.slice(1):"")
        _status(resp.data.subsidiary.active)
        _profilePic(resp.data.subsidiary.logo_url!==null&&resp.data.subsidiary.logo_url!==''?resp.data.subsidiary.logo_url:'')
        _country(resp.data.subsidiary.limited_address)
      }
      else{
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })
    .catch(function (error) {  
      const resp = error.response;
      let error_message = '';
      if (resp.data.errors !== undefined) {
        {
          Object.keys(resp.data.errors).map(
            (error, index) => (error_message = resp.data.errors[error][0])
          );
        }
      } else if (resp.data.data?.error !== undefined) {
          error_message =  resp.data.data.error;
      } else if(resp.data?.error!== undefined){
          error_message =  resp.data.error
      } else{
          error_message =  resp.data.message
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT
      })
    });
  }

  const addcomments = (id) =>{
    let flag = false
    for (let i=0;i<commentsArr.length;i++){
      if(commentsArr[i].id===id){
        flag= true
        break;
      }
    }
    if(flag===false){
      _commentsArr((item)=>{
        return [...item,{
          'id':id,
          'comment':suggestionTexts[id]
        }]
      })
    }else{
      toast.error("Comment has been already added", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

  }

  const removeComment = (id) =>{
    _commentsArr((item)=>{
      return item.filter((arrItem,index)=>{
        return index !== id
      })
    })
  }

  const handleCheck = (val) =>{
    let search = modules_disabled.findIndex(x=>x===val)
    if(val===1){
      if(ads===false&&search===-1){
          modules_disabled.push(val)
      }
      else{
        modules_disabled.splice(search, 1)
      }
    } 
    else if(val===2){
      if(bids===false&&search===-1){
          modules_disabled.push(val)
      }
      else{
        modules_disabled.splice(search, 1)
      }
    } 
  }

  const save = () =>{
    if(modules_disabled.length<1){
      toast.error("Please select modules for disabling access", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else if(commentsArr.length<1){
      toast.error("Please add some comments", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else if(duration===''||duration===undefined){
      toast.error("Please select duration for disabling access")
    }
    else{
      confirmAlert({
        title: "Confirm Action",
        message: `Are you sure you want to censor this ${userTypeName.DEALER} ?`,
        closeOnEscape: true,
        buttons: [
          {
            label: "Yes",
            onClick: () => {
                let newArr = []
                for(let i =0; i<commentsArr.length;i++){
                  newArr.push(parseInt(commentsArr[i].id))
                }
                let data = {}
                data['app_type']= appVal
                data['modules_disabled']= modules_disabled
                data['duration']= duration
                data['reason_ids']= newArr
                data['subsidiary_id']= companyId
                _loading(true)
                API.post(APP_URLS.CENSOR_SUBSIDIARY, data)
                .then(res => {
                    const resp = res.data;
                    _loading(false)
                    if(resp.success===true){
                      toast.success("Censorship has been created successfully.", {
                        position: toast.POSITION.TOP_RIGHT
                      })
                      navigate(`/company-details/${companyId}?`+
                          (query.get("status") !== null
                            ? "status=" + query.get("status") + "&"
                            : "") +
                          (query.get("country_id") !== null
                            ? "country_id=" +
                              query.get("country_id") +"&"
                            : "") +
                          (query.get("sort") !== null
                            ? "sort=" + query.get("sort") + "&"
                            : "") +
                          (query.get("start_plan") !== null
                            ? "start_plan=" + query.get("start_plan") + "&"
                            : "") +
                          (query.get("end_plan") !== null
                            ? "end_plan=" + query.get("end_plan") + "&"
                            : "") +
                          (query.get("name") !== null
                            ? "name=" + query.get("name") + "&"
                            : "") +
                          (query.get("vat") !== null
                            ? "vat=" + query.get("vat") + "&"
                            : "") +
                          (query.get("country_code") !== null
                            ? "country_code=" + query.get("country_code") + "&"
                            : "") +
                          (query.get("trashed") !== null
                            ? "trashed=" + query.get("trashed") + "&"
                            : "") +
                          (query.get("per_page") !== null 
                            ? "per_page=" + query.get("per_page") + "&"
                            : "") +
                          (query.get("page") !== null
                            ? "page=" + query.get("page")
                            : "")
                        )
                    }
                    else{
                      toast.error(resp.message, {
                        position: toast.POSITION.TOP_RIGHT
                      })
                    }
                })
                .catch(function (error) {  
                    const resp = error.response;
                    _loading(false)
                    let error_message = '';
                    if (resp.data.data !== undefined && resp.data.data !== null) {
                      {
                        Object.keys(resp.data.data).map(
                          (error, index) =>(error_message = resp.data.data[error][0])
                        );
                      }
                    } else if (resp.data.data?.error !== undefined) {
                        error_message =  resp.data.data.error;
                    } else if(resp.data?.error!== undefined){
                        error_message =  resp.data.error
                    } else{
                        error_message =  resp.data.message
                    }
                    toast.error(error_message, {
                      position: toast.POSITION.TOP_RIGHT
                    })
                  }); 
                },
              },
              {
                label: "No",
              },
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            overlayClassName: "overlay-custom-class-name",
          });
          }
  }

  const changeTarget =()=>{
    if(commentsArr.length <= 0){
      document.getElementById("commentInput").focus()
    }
  }



    return (
      <CreateCensuredWrapper>
        <div className="auctionMainContainer">
          <div className="mt-2">
            <div className="backContainer ms-4" onClick={() => navigate(`/company-details/${companyId}?`+
              (query.get("status") !== null
                ? "status=" + query.get("status") + "&"
                : "") +
              (query.get("country_id") !== null
                ? "country_id=" +
                  query.get("country_id") +"&"
                : "") +
              (query.get("sort") !== null
                ? "sort=" + query.get("sort") + "&"
                : "") +
              (query.get("start_plan") !== null
                ? "start_plan=" + query.get("start_plan") + "&"
                : "") +
              (query.get("end_plan") !== null
                ? "end_plan=" + query.get("end_plan") + "&"
                : "") +
              (query.get("name") !== null
                ? "name=" + query.get("name") + "&"
                : "") +
              (query.get("vat") !== null
                ? "vat=" + query.get("vat") + "&"
                : "") +
              (query.get("country_code") !== null
                ? "country_code=" + query.get("country_code") + "&"
                : "") +
              (query.get("trashed") !== null
                ? "trashed=" + query.get("trashed") + "&"
                : "") +
              (query.get("per_page") !== null 
                ? "per_page=" + query.get("per_page") + "&"
                : "") +
              (query.get("page") !== null
                ? "page=" + query.get("page")
                : "")
            )}>
              <span className="backButton">x</span>
              <span className="px-3 backButton">|</span>
              <span className="backButton">Cancel</span>
            </div>
          </div>

          <div className="row mt-3 px-2">
            <div className="col-md-4 offset-md-4">
              <h1>{`CENSOR ${userTypeName.DEALER_CAPITAL}`}</h1>
            </div>
          </div>

          <div className="row mb-2 mt-3 px-2">
            <div className="col-md-6 d-flex mb-3">
              <div className="details">
                <div className="profile-flag">
                  <div className="profileImage">
                    <img src={profilePic ? profilePic : defaultprofile} />
                  </div>
                  {country ? <img className="flagIcon" src={country.flag_thumbnail} title={country.country}/> : ""}
                </div>
                <div className="detailsContent">
                  <span> {name}</span>
                  {status===1?<span className="active-status"> Active</span>:<span className="end-status"> Inactive</span>}
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-end  mb-3">
              <button className={(loading?"disabled":"")+" btn"} onClick={save} disabled={loading}>
                SEND
              </button>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="input-section">
                  <div className="row formInput">
                    <div className="col-md-6 form-group">
                      <label htmlFor="app" className="form-label">
                        <b>App</b>
                      </label>
                      <select
                        name="app"
                        id="app"
                        className="form-control"
                        onChange={(e) => {
                          modules_disabled.length = 0;
                          setAppVal(e.target.value);
                        }}
                      >
                        <option value="2">Front Office</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="formInput">
                        <div className="container">
                          <div className="row">
                            <div className="col-md-6">
                              <label htmlFor="" className="label">
                                <b className="labelVal">Disable</b>
                              </label>
                                  <div className="checkboxOptions">
                                    <div className="options">
                                      <input
                                        type="checkbox"
                                        id="ads"
                                        name="ads"
                                        checked={ads}
                                        onChange={() => {
                                          _ads(!ads);
                                          handleCheck(1);
                                        }}
                                      />
                                      <label htmlFor="ads"> Ads</label>
                                    </div>
                                  </div>
                                  <div className="checkboxOptions">
                                    <div className="options">
                                      <input
                                        type="checkbox"
                                        id="bids"
                                        name="bids"
                                        checked={bids}
                                        onChange={() => {
                                          _bids(!bids);
                                          handleCheck(2);
                                        }}
                                      />
                                      <label htmlFor="bids"> Bids</label>
                                    </div>
                                  </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-6 mb-5">
                      <label htmlFor="" className="label d-block">
                        <b>Duration</b>
                      </label>
                      <select
                        name="disableBids"
                        id="disableBids"
                        className="disableBids"
                        onChange={(e) => {
                          _duration(e.target.value);
                        }}
                      >
                        <option hidden>None</option>
                        <option value="4">1 Day</option>
                        <option value="5">3 Days</option>
                        <option value="0">1 Week</option>
                        <option value="1">2 Weeks</option>
                        <option value="2">1 Month</option>
                        <option value="3">3 Months</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 offset-lg-2">
                <div className="commentBox p-1" onClick={changeTarget}>
                  {commentsArr.length > 0 ? (
                    commentsArr.map((item, index) => {
                      return (
                        <div className="d-flex align-items-center ps-3 py-1" key={index}>
                          <span className="pe-2">{item.comment}</span>
                          <span className="removeComment" onClick={() => removeComment(index)}>
                            x
                          </span>
                        </div>
                      );
                    })
                  ) : (
                    <div className="d-flex align-items-center ps-3 py-1">
                      <span className="pe-2 suggestion">Please search and add some comments from the field below...</span>
                    </div>
                  )}
                </div>
                <div className="my-4">
                  <input type="text" id="commentInput" placeholder="Enter keywords to search for comments" className="searchKeyword" onChange={(e) => _keyword(e.target.value)} />
                </div>
                {suggestionLoaded ? (
                  <div className="keywords p-1">
                    {Object.keys(suggestionTexts).length > 0 ? (
                      Object.keys(suggestionTexts).map((item, index) => {
                        return (
                          <span className="comments my-2 px-2" key={index} onClick={() => addcomments(item)}>
                            {suggestionTexts[item]}
                          </span>
                        );
                      })
                    ) : (
                      <span className="disabledcomment">No Comment found.</span>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </CreateCensuredWrapper>
    );
}

export default CreateCompanyCensured