import React from "react";
import { useState } from "react";
import MainLayout from "../../../assets/Layout/MainLayout";
import EmailUser from "./Email user";
import ExistingUser from "./Existing user";
import { InviteUserWrapper } from "./style/inviteUser";
import activeTab from "../../../assets/Images/icons/svgIcon/activeTab.svg";
import notActiveTab from "../../../assets/Images/icons/svgIcon/notActiveTab.svg";
import { useNavigate } from "react-router-dom";

const InviteUser = () => {

  const [tabView, setTabView] = useState("existingUser");

  const navigate = useNavigate()

  return (
    <InviteUserWrapper>
      <div>
        <div className="mt-2">
          <div className="backContainer ms-4" onClick={() => navigate("/users?tab=adminPanel")}>
            <span className="backButton">x</span>
            <span className="px-3 backButton">|</span>
            <span className="backButton">Cancel</span>
          </div>
        </div>
        <div className="headerContainer">
          <h1>INVITE ADMIN</h1>
        </div>
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8 user-invite">
            <div className="d-flex align-items-center gap-2 mt-3">
              <div className="d-flex align-items-center">
                <p className={tabView === "existingUser" ? "activeTabTranslation" : ""} onClick={() => setTabView("existingUser")}>
                  {tabView === "existingUser" ? <img src={activeTab} alt="" role="button" /> : <img src={notActiveTab} alt="" role="button" />}
                </p>
                <span className="ps-2 existing-user">Invite existing user</span>
              </div>
              <div className="d-flex align-items-center">
                <p className={tabView === "emailUser" ? "activeTabTranslation" : ""} onClick={() => setTabView("emailUser")}>
                  {tabView === "emailUser" ? <img src={activeTab} alt="" role="button" /> : <img src={notActiveTab} alt="" role="button" />}
                </p>
                <span className="ps-2 existing-user">Invite using email</span>
              </div>
            </div>
            <div>
              {tabView === "existingUser" ? <ExistingUser /> : ""}
              {tabView === "emailUser" ? <EmailUser /> : ""}
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </InviteUserWrapper>
  );
};

export default InviteUser;
