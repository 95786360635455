import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"
import close from "../../../../assets/Images/icons/Cancel.svg";
import { verificationIdsCollectionName } from '../../../../config/constant';


const Popup = (props) => { 
  const [showImage,_showImage] = useState([])

  useEffect(() => {
    _showImage([])
    let data
    if(props.popup){
        data = props.data.find(o => o.id === props.popup)
        _showImage((arr)=>[...arr,data])
        showImageOfDocuments(data) 
    }
  }, [props.popup])

  const openImage =(val) =>{
    window.open(val, "_blank");
  }

  const showImageOfDocuments = (data) => { 
    const firstCollection = data.collection_name;
    let secondCollection = '' 
    if(firstCollection == verificationIdsCollectionName.drivingLicenseFront) secondCollection = verificationIdsCollectionName.drivingLicenseBack
    else if(firstCollection == verificationIdsCollectionName.drivingLicenseBack) secondCollection = verificationIdsCollectionName.drivingLicenseFront
    else if(firstCollection == verificationIdsCollectionName.nationalIdFront) secondCollection = verificationIdsCollectionName.nationalIdBack
    else if(firstCollection == verificationIdsCollectionName.nationalIdBack) secondCollection = verificationIdsCollectionName.nationalIdFront

    if(data.custom_properties.refused_at!==null){
      const newVal = props.data.find(o => o.custom_properties.refused_at === data.custom_properties.refused_at && o.collection_name === secondCollection )
      if(newVal!==undefined){
        _showImage((arr)=>[...arr,newVal])
      }
    }
    if(data.custom_properties.accepted_at!==null){
      const newVal = props.data.find(o => o.custom_properties.accepted_at === data.custom_properties.accepted_at && o.collection_name === secondCollection )
      if(newVal!==undefined){
        _showImage((arr)=>[...arr,newVal])
      }
    }
    else{
      const newVal = props.data.find(o => o.collection_name === secondCollection && o.custom_properties.refused_at === null)
      if(newVal!==undefined){
        _showImage((arr)=>[...arr,newVal])
      }
    } 
  }

  const getCollectionName = (collectionName) => {
    if(collectionName == verificationIdsCollectionName.drivingLicenseBack ){
      return "Driving License Back"   
    } 
    if(collectionName == verificationIdsCollectionName.drivingLicenseFront){
       return "Driving License Front"
    } 
    if(collectionName == verificationIdsCollectionName.nationalIdBack){
       return "National Id Back" 
    } 
    if(collectionName == verificationIdsCollectionName.nationalIdFront){
      return "National Id Front"
    }  
    return collectionName.split("_")[0].charAt(0).toUpperCase() + collectionName.split("_")[0].slice(1)
}
  
  return (
    <div className={(props.popup?"modal fade show":"modal fade")+ " custom-modal modal-backdrop"}> 
        <div className="modal">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">ID Preview</h5>
                <img src={close} alt="" className="mx-2" role="button" onClick={()=>props._popup('')}/>
            </div>
            <div className="modal-body">
              <div className='preview' key={showImage}>
              <Carousel
                showThumbs={false} 
                showIndicators={false}
                showArrows={true} 
                showStatus={false}
                >
              {showImage.map((item,index)=>{
                  return  <div>
                    <span className='suggestion'> {getCollectionName(item?.collection_name || '')}</span>
                    <div key={index} onClick={()=>openImage(item?.original_url)} className="showImage mt-3">
                      <span className='suggestion loading'> loading...</span>
                      <img src ={item?.original_url} key={index} />
                    </div>
                  </div>
              })}
                </Carousel>
              </div>
            </div>
            </div>
        </div>
        </div>
    </div>
  )
}

export default Popup