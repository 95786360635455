import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import API from "../../../../api/axios";
import { APP_URLS } from "../../../../api/url";
import moment from "moment";
import Popup from "./Popup";
import { ThreeDots } from "react-loader-spinner";
import { verificationIdTypes, verificationIdsCollectionName } from "../../../../config/constant";

const Verified = (props) => {

  const [data, _data] = useState([]);
  const [loading,_loading] = useState(false)

  const [suggestionTexts, _suggestionTexts] = useState({});
  const [suggestionLoaded, _suggestionLoaded] = useState(false);

  const [commentsArr,_commentsArr] = useState([])
  const [collectionName,_collectionName] = useState('')

  const [popup,_popup] = useState('')

  const [docType,_docType] = useState('')
  const [docName,_docName] = useState('')

  const [actionID,_actionID] = useState('')
  

  const permission = props.verifyUser.permissions.find((o)=>o.slug==='update')

  useEffect(() => {
    getUserDetails();
    getSuggestiontext();
  }, []);

  const getUserDetails = () => {
    API.get(
      APP_URLS.VIEW_USER_VERIFICATION_STATUS +
        `/${props.userId}/verification_status`
    )
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _data(resp.data.items);
          props.setHeaderData(resp.data.items)
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map(
              (error, index) => (error_message = resp.data.errors[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getSuggestiontext = () => {
    API.get(APP_URLS.LIST_SUGGESTION_TEXT + `?limited_data=true&filter[type]=0&with_trashed=0`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          let data ={}
          resp.data.items.forEach(element => {
            data [element.id]= element.name
          });
          _suggestionTexts(data);
          _suggestionLoaded(true)
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const action = (status,media,name) =>{
    let values = {}
    let mediaId;
    let media_id = [media] 
    if (name=== verificationIdsCollectionName.passport){
        values['type'] = verificationIdTypes.passport
    }else if(name === verificationIdsCollectionName.drivingLicenseFront || name === verificationIdsCollectionName.drivingLicenseBack){
        values['type'] = verificationIdTypes.drivingLicense
        if(name ===verificationIdsCollectionName.drivingLicenseFront ){
            mediaId = data.find(o => o.collection_name === verificationIdsCollectionName.drivingLicenseBack && o.custom_properties.refused_at === null)?.id
            if(mediaId!==undefined){
              media_id.push(mediaId)
            }
        }else{
            mediaId = data.find(o => o.collection_name === verificationIdsCollectionName.drivingLicenseFront  && o.custom_properties.refused_at === null)?.id
            if(mediaId!==undefined){
              media_id.push(mediaId)
            }
        }
    }else if(name === verificationIdsCollectionName.nationalIdFront || name === verificationIdsCollectionName.nationalIdBack){
        values['type'] = verificationIdTypes.nationalId
        if(name === verificationIdsCollectionName.nationalIdFront){
            mediaId = data.find(o => o.collection_name === verificationIdsCollectionName.nationalIdBack && o.custom_properties.refused_at === null)?.id
            if(mediaId!==undefined){
              media_id.push(mediaId)
            }
        }else{
            mediaId = data.find(o => o.collection_name === verificationIdsCollectionName.nationalIdFront && o.custom_properties.refused_at === null)?.id
            if(mediaId!==undefined){
              media_id.push(mediaId)
            }
        }
    }
    else if(name === verificationIdsCollectionName.selfieWithId){
        values['type'] = verificationIdTypes.selfie
    }
    if(status===0){
      values['refused_comment'] = commentsArr
    }
    values['status'] = status
    values['media_id'] = media_id
    let flag = false
    if(((name===verificationIdsCollectionName.drivingLicenseFront || name===verificationIdsCollectionName.drivingLicenseBack || name===verificationIdsCollectionName.nationalIdFront || name===verificationIdsCollectionName.nationalIdBack) && values['status'] === 1)){
      if(values['media_id'].length<2){
        flag = true
      }
    } 
    if(flag===false){
      _loading(true)
      if(!loading){
        API.post(APP_URLS.UPDATE_USER_VERIFICATION_STATUS+"/update/verification_status?_method=PATCH", values)
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) {
            toast.success(`Verfication ID ${status===1?"Accepted":"Rejected"} Successfully.`, {
              position: toast.POSITION.TOP_RIGHT,
            });
            _collectionName('')
            getUserDetails()
            props.getUser()
          _loading(false)
    
          } else {
            toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          _loading(false)
          }
        })
        .catch(function (error) {
          const resp = error.response;
          _loading(false)
          let error_message = "";
          if (resp.data.data !== undefined && resp.data.data !== null) {
            {
              Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
            }
          } else if (resp.data.data?.error !== undefined) {
            error_message = resp.data.data.error;
          } else if (resp.data?.error !== undefined) {
            error_message = resp.data.error;
          } else {
            error_message = resp.data.message;
          }
          toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
      }
    }
    else{
      toast.error("Incomplete Documents", {
            position: toast.POSITION.TOP_RIGHT,
          });
    }
  }

  const addcomments = (id) =>{
    let flag = false
    for (let i=0;i<commentsArr.length;i++){
      if(commentsArr[i]===id){
        flag= true
        break;
      }
    }
    if(flag===false){
      _commentsArr((item)=>{
        return [...item,id]
      })
    }else{
      toast.error("Comment has been already added", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

  }

  const removeComment = (id) =>{
    _commentsArr((item)=>{
      return item.filter((arrItem,index)=>{
        return index !== id
      })
    })
  }



  const validationType = (validation_type_name) =>{
     if(validation_type_name == verificationIdsCollectionName.drivingLicenseBack || validation_type_name == verificationIdsCollectionName.drivingLicenseFront ) return 'Driving License'
     if(validation_type_name == verificationIdsCollectionName.nationalIdFront || validation_type_name == verificationIdsCollectionName.nationalIdBack) return "National Id"
     return validation_type_name.split("_")[0].charAt(0).toUpperCase() + validation_type_name.split("_")[0].slice(1)
  }
  const contentType = (validation_type_name) =>{
    if(validation_type_name == verificationIdsCollectionName.drivingLicenseBack || validation_type_name == verificationIdsCollectionName.drivingLicenseFront ) return 'Driving License Front and Back'
    if(validation_type_name == verificationIdsCollectionName.nationalIdFront || validation_type_name == verificationIdsCollectionName.nationalIdBack) return "National Id Front and Back" 
    return (validation_type_name.charAt(0).toUpperCase() + validation_type_name.slice(1)).split('_').join(' ');
 }
 const docNameValue = () => { 
  if(docName == verificationIdsCollectionName.drivingLicenseBack || docName == verificationIdsCollectionName.drivingLicenseFront ) return "Driving License Front and Back"
  if(docName == verificationIdsCollectionName.nationalIdFront || docName == verificationIdsCollectionName.nationalIdBack) return "National Id Front and Back"
  return (docName.charAt(0).toUpperCase()+docName.slice(1)).replaceAll("_"," ")
 }
  return (
    <div className="container">
       {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <ThreeDots 
              height="80" 
              width="80" 
              radius="9"
              color="#637df4" 
              ariaLabel="three-dots-loading" 
              visible={true} 
            />
          </div>
        ) : (
        <>
          <Popup popup={popup} _popup={_popup} data={data}/>
          <div className="row">
            <div className={actionID?"col-lg-7 col-md-12":"col-lg-12 col-md-12"}>
              <div className="tableContainer">
                <table className="auctionTable">
                  <thead className="tableHead">
                    <tr className="table-row">
                      <th>DATE</th>
                      <th>VALIDATION TYPE</th>
                      <th>CONTENT</th>
                      <th>STATUS</th>
                      <th className="w20">COMMENT</th>
                      <th>VERIFY BY</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="table-row">
                      <td>{moment(props.data.created_at).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm")}</td>
                      <td>Email</td>
                      <td>{props.data.email}</td>
                      <td>
                        {props.data.email_verified_at ? (
                          <span className="status status-active" title="Verified">
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </span>
                        ) : (
                          <span className="status status-progress" title="Pending"></span>
                        )}
                      </td>
                      <td className="w20"></td>
                      <td>
                        <p>
                          <span className="date">
                            {props.data.email_verified_at!==null&&props.data.email_verified_at!==undefined?moment(props.data.email_verified_at).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm"):"-"}
                          </span>
                        </p>
                      </td>
                    </tr>
                    {data.map((item, index) => {
                        let verifiedBy 
                        if(item.verifiedByUser!==null&&item.verifiedByUser!==undefined){
                            if(item.verifiedByUser.first_name||item.verifiedByUser.last_name){
                                verifiedBy = `${item?.verifiedByUser.first_name!==null?item?.verifiedByUser.first_name:''} ${item?.verifiedByUser.last_name!==null?item?.verifiedByUser.last_name:''}`
                            }else{
                                verifiedBy = item?.verifiedByUser.pseudo
                            }
                            verifiedBy = verifiedBy.charAt(0).toUpperCase() + verifiedBy.slice(1)
                        }
                        const isDeleted = item.deleted_at; 
                      return (
                        item.collection_name !== verificationIdsCollectionName.drivingLicenseBack && item.collection_name !== verificationIdsCollectionName.nationalIdBack ?
                        <tr className={"table-row" + (isDeleted ? ' deletedRow ' :'')} key={'documents'+item.id}>
                          <td>{moment(item.created_at).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm")}</td>
                          <td className="link" onClick={()=>_popup(item.id)}>
                            {validationType(item.collection_name)} 
                          </td>
                          <td>
                            {contentType(item.collection_name)}
                          </td>
                          <td>
                            {item.custom_properties.accepted_at?
                          <span className="status status-active" title='Verified'>
                              <i className="fa fa-check" aria-hidden="true"></i>
                          </span>:
                          item.custom_properties.refused_at?
                          <span className="status status-end" title='Rejected'>
                          </span>:
                          <span className="status status-progress" title='Pending'>
                          </span>
                            }
                          </td>
                          <td className={(loading?"disabled":"")+" w20"}>
                            {item.custom_properties.accepted_at===null&&item.custom_properties.refused_at===null && !isDeleted  ? (
                              <>
                                <button className={(permission===undefined||loading?"disabled":"")+" reject"} onClick={()=>{
                                  _actionID(item.id)
                                  _collectionName(item.collection_name)
                                  _docType(item.collection_name.split("_")[0]==="driving"?"Driving License":item.collection_name.split("_")[0].charAt(0).toUpperCase() + item.collection_name.split("_")[0].slice(1))
                                  _docName(item.collection_name)
                                  }}
                                  disabled={permission===undefined||loading}> REJECT </button>
                                <button className={(permission===undefined||loading?"disabled":"")+" accept"} onClick={()=>{
                                    _collectionName(item.collection_name)
                                    _actionID('')
                                    action(1,item.id,item.collection_name)
                                    }}
                                    disabled={permission===undefined||loading}> ACCEPT </button>
                              </>
                            ) : (
                              item.custom_properties.refused_comment!==null && suggestionLoaded===true ?
                              item.custom_properties.refused_comment.map((item,index)=>{
                                return <span className="d-flex justify-content-center align-items-center suggestion" key={index}>
                                  {suggestionTexts[item]?.charAt(0).toUpperCase() + suggestionTexts[item]?.slice(1)}
                                  </span>
                              }):'-'
                            )}
                          </td>
                          <td>
                            <p>
                              {/* {verifiedBy.charAt(0).toUpperCase() + verifiedBy.slice(1)} */}
                              {verifiedBy}
                              <br />
                              <span className="date">{item.custom_properties.accepted_at!==null?moment(item.custom_properties.accepted_at).format("DD-MM-YYYY HH:mm"):item.custom_properties.refused_at!==null?moment(item.custom_properties.refused_at).format("DD-MM-YYYY HH:mm"):'-'}</span>
                            </p>
                          </td>
                        </tr>
                        :''
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className={(actionID?"d-block":"d-none")+" col-lg-4 offset-lg-1 col-md-12"}>
              <div className="card ">
                <h3>REJECTED</h3>
                <div className="cardHeader">
                  <button className="cancel" onClick={()=>{
                    _actionID('')
                    _collectionName('')
                    _commentsArr([])
                    _docName('')
                    _docType('')
                    }}>CANCEL</button>
                  <button className={(commentsArr.length>0||loading?"":"disabled")+" save"} disabled={commentsArr.length>0?false:true||loading} onClick={()=>{
                    action(0,actionID,collectionName)
                    _commentsArr([])
                    _actionID('')
                    _docName('')
                    _docType('')
                  }}>SEND</button>
                </div>
                <div className="content">
                  <div className="row">
                    <div className="col-4">
                      <span>{docType}</span>
                    </div>
                    <div className="col-4 d-flex justify-content-center text-center">
                      <span className="link defaultCursor">
                        {docNameValue()}
                      </span>
                    </div>
                    <div className="col-4 d-flex justify-content-end">
                      <span className="status status-end"></span>
                    </div>
                  </div>
                  <div className="cardContent">
                    {Object.keys(suggestionTexts).length>0?
                    Object.keys(suggestionTexts).map((item,index)=>{
                        return <span className="comments" key={index} onClick={()=>addcomments(item)}>{suggestionTexts[item]}</span>
                    }):<span className="disabledcomment">Please add some suggestion texts</span>}
                  </div>
                  <div className="cardFooter">
                    {commentsArr.length>0?
                    commentsArr.map((item,index)=>{
                      return <div className="d-flex align-items-center ps-3 py-1" key={index}>
                        <span className="pe-2">{suggestionTexts[item]}</span>
                        <span className="removeComment" onClick={()=>removeComment(index)}>
                          x
                        </span>
                      </div> 
                    }):<div className="d-flex align-items-center ps-3 py-1" >
                    <span className="pe-2 suggestion">Please add some comments...</span>
                  </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Verified;
