import React from 'react'

const NoRecord = () => {
  return (
    <tr className="table-row defaultRecord">
        <td>
        <span>No Record Found</span>
        </td>
    </tr>
  )
}

export default NoRecord