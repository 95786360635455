import styled from "styled-components";

export const ForumFlaggedWrapper = styled.div`
  .headerContainer div,
  .headerContainer h1 {
    margin-bottom: 10px;
  }
  .reviewTable th,
  tr.table-row td {
    width: 10%;
    padding: 15px 5px;
    display: block;
    justify-content: center;
    align-self: center;
    text-align: center;
    word-break: break-word;
  }
  .reviewTable th.longText,
  tr.table-row td.longText {
    width: 30%;
  }
  .reviewTable th,
  tr.table-row td {
    width: 12%;
  }

  .info-wrap {
    flex-wrap: wrap;
  }
  .search {
    height: 35px;
    border: none;
    border-radius: 10px;
  }
  .btn {
    align-self: center;
    background-color: #607afc;
    border: none;
    height: 35px;
    border-radius: 5px;
    padding: 0 20px;
    color: white;
    font-weight: bold;
  }
  .action {
    display: flex;
    align-items: center;
  }
  .action button {
    border: none;
    background: transparent;
  }
  .form-check {
    min-height: auto !important;
  }
  .form-check-input {
    cursor: pointer !important;
  }
  .form-check-input:checked {
    background-color: #5d78ff;
    border-color: #5d78ff;
  }
  .select-info {
    flex-wrap: wrap;
  }
  .select-info select {
    max-width: 130px;
  }
  .time-start {
    min-width: 125px;
  }
  .time-start svg {
    width: 10px;
    height: 15px;
    cursor: pointer;
  }
  .filterBox {
    max-height: 20vh;
  }
  .filterBox > option {
    width: 10rem;
  }
  .rating-stars > img {
    width: 24px;
  }
  .opacity-40 {
    opacity: 40%;
  }
  .three-dot {
    align-items: center;
    height: 75vh;
  } 
  .deletedRow {
    background: rgba(239, 71, 71, 0.1) !important;
    cursor: not-allowed;
  }
  .no-action-row {
    background: #ffed4980 !important;
    cursor: not-allowed;
  }
  .show-options {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }
  .select-wrapper {
    border: 1px solid lightgray;
    border-radius: 13px;
    padding: 0 5px;
  }
  .select-wrapper > select {
    border: none;
  }
  .show-button {
    padding: 0; 
  }
  @media (max-width: 1226px) {
    .info-wrap {
      justify-content: space-between !important;
    }
  }
  @media (max-width: 485px) {
    .info-wrap {
      justify-content: center !important;
    }
    .rating-stars > img {
      width: 18px;
    }
  }
  @media (max-width: 768px) {
    .info-wrap {
      justify-content: space-between !important; 
    }
    .rating-stars > img {
      width: 22px; 
    }
  }
  .no-action {
    width: 20px;
    margin-left: 1rem;
  }
  .actions{
    cursor:pointer;
  }
  .actionBtn{
    display:flex;
    justify-content:center;
    align-items:center;
    word-break:initial;
  }
    .message-text {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    max-width:250px;
    width:100%;
  }
  .dropbtn{
    cursor:pointer;
  }
  .dropped{
    width:100% !important;
    text-align:unset !important;
    margin-left:10px;
  }
  .open{
    transform: rotate(-90deg);
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  .close{
    transform: rotate(90deg);
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
    .closeA{
    transform: rotate(0deg);
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  .openA{
    transform: rotate(180deg);
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  .actionPopup{
    position: absolute;
    top: 40px;
    z-index: 99;
  }
`;
