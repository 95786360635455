import React, { useEffect, useState } from "react";
import error from "../../../../assets/Images/icons/alert-tri.svg";
import "react-confirm-alert/src/react-confirm-alert.css";
import edit from "../../../../assets/Images/image/edit.png";
import { NavLink, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { APP_URLS } from "../../../../api/url";
import API from "../../../../api/axios";
import { toast } from "react-toastify";
import Pagination from "../../../../assets/SharedComponents/Pagination";
import SideTranslate from "../../components/SideTranslate";
import { TableWrapper } from "../../style/table";
import NoRecord from "../../../../assets/SharedComponents/Table/NoRecord";
import { getName } from "../../../../core/utils/getName/getName";
import { ThreeDots } from "react-loader-spinner";
import RecordCount from "../../../../assets/SharedComponents/RecordCount/RecordCount";
import { Tooltip } from "@mui/material";
import { FaFilterIcon } from "../../../../assets/Images/icon";

const ProductType = () => {

  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const [searchParams, setSearchParams] = useSearchParams();

  const [data, _data] = useState([]);
  const [languages, setlanguages] = useState(JSON.parse(localStorage.getItem("languages")));
  const [countries, setcountries] = useState(JSON.parse(localStorage.getItem("countries")));
  const [dataDeleted, _dataDeleted] = useState(false);

  const [countValue, _countValue] = useState(searchParams.get('per_page')!==null?searchParams.get('per_page'):"");

  const [filters, _filters] = useState(false);

  const [page, setPage] = useState(searchParams.get('page')!==null?parseInt(searchParams.get('page')):1);
  const [total, _total] = useState(0);
  const [totalPage, _totalPage] = useState(0);

  const [loading, _loading] = useState(false);

  const [userAccess,_userAccess] = useState(JSON.parse(localStorage.getItem('permissions')))
  
  let translationAccess = userAccess.find(o=>o.slug ==="translation")

  let navigate = useNavigate();

  useEffect(() => {
    getLanguages();
    _dataDeleted(false);
  }, [dataDeleted, page,countValue]);

  const getLanguages = () => {
    _loading(true);
    API.get(APP_URLS.LIST_PRODUCT_TYPES + `?page=${page}&per_page=${countValue}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _data(resp.data.data);
          _totalPage(resp.data.last_page);
          _total(resp.data.total);
          _loading(false);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loading(false);
        }
      })
      .catch(function (error) {
        _loading(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map((error, index) => (error_message = resp.data.errors[error][0]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleSearchChange = (searchValue) => {
    _countValue(searchValue);
    if (searchValue !== ""&&searchValue!==countValue) {
      _totalPage(0)
      setPage(1);
    }
  };

  const clearFilter = () => {
    _filters(true)
    handleSearchChange('');
    if (countValue !== '') {
      _totalPage(0)
      setPage(1);
    }
    for(var value of query.keys()) {
      const param = searchParams.get(value);
      if (param) {
        searchParams.delete(value);
        setSearchParams(searchParams);
      }
     }
  };

  return (
    <>
      <TableWrapper>
        <div className="row">
          <div className="col-lg-2 col-md-3 col-sm-4 mt-3">
            <h4 className="translation text-center mb-3">TRANSLATION</h4>
            <SideTranslate />
          </div>
          <div className="col-lg-10 col-md-9 col-sm-8 mt-2">
            <div className="headerContainer mb-3">
              <h1>PRODUCT TYPE</h1>
            </div>
            <div className="row">
              <div className="d-flex justify-content-between add-info flex-wrap">
                <div className="add-depertment d-flex justify-content-end mb-2">
                  {translationAccess.permissions.find((o) => o.slug === "add") ? (
                    <div>
                      <NavLink to="/translation/create-product-types">
                        <button className="btn">+ Add</button>
                      </NavLink>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="d-flex justify-content-end mb-2 gap-2 flex-wrap align-items-center gap-2">
                  <RecordCount
                    onSearchClick={(search) => handleSearchChange(search)}
                    val={countValue}
                    id="pageCount"
                    filters={filters}
                    _filters={_filters} />
                  <Tooltip title="Clear Filter" placement="bottom">
                    <FaFilterIcon className="filter-icon" role="button" onClick={clearFilter} />
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between info-wrap">
                <div className="justify-content-end mt-1 me-2">
                    <span className='text-start'>Total Records : <b>{total}</b></span>
                </div>
            </div>
              <div className="tableContainer">
                <table className="auctionTable">
                  <thead className="tableHead">
                    <tr className="table-row">
                      <th>ID</th>
                      <th className="tableHead-2">Language</th>
                      <th className="tableHead-3">Name</th>
                      <th>Status</th>
                      <th>Updated by</th>
                      <th>Created by</th>
                      <th>Web icon</th>
                      <th>Mobile icon</th>
                      {translationAccess.permissions.find((o) => o.slug === "update") ? <th>Action</th> : ""}
                    </tr>
                  </thead>
            {loading ? 		
              <div className='d-flex justify-content-center three-dot'>              
                <ThreeDots 
                  height="150" 
                  width="150" 
                  radius="9"
                  color="#637df4" 
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
              :
              <tbody>
                    {data.length > 0 ? (
                      data.map((item, index) => {
                        let createdBy = getName(item.create_activity_log, item.update_activity_log);
                        let upadtedBy = getName(item.update_activity_log, item.create_activity_log);
                        let webIcon = null
                        let mobileIcon = null
                        if(item?.media?.length){ 
                          const img1 = item.media[0].original_url;
                          const img2 = item.media[1]?.original_url || ''
                          if(item.media[0].custom_properties.type == 'mobile') {
                            mobileIcon = img1
                            webIcon = img2
                          } else{
                            webIcon = img1
                            mobileIcon = img2 
                          }   
                        } 
                        return (
                          <tr className="table-row" key={index}>
                            <td>{item.id}</td>
                            <td className="tableHead-2">
                              {Object.keys(item?.item_name).map((val, index) => {
                                let country_id;
                                let flag;
                                let countryValues = languages.find((o) => o.code === val);
                                if (countryValues) {
                                  country_id = countryValues.country_id;
                                  flag = countries.find((o) => o.id === country_id)?.flag_thumbnail;
                                }
                                return (
                                  <div key={index} className="languageSelected d-flex gap-2">
                                    {countryValues ? (
                                      <>
                                        <img src={flag} alt="" title={countryValues?.name}/>
                                        <span className="flagName">{val}</span>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                );
                              })}
                            </td>
                            <td className="tableHead-3">
                              {Object.keys(item.item_name).map((val, index) => {
                                let countryValues = languages.find((o) => o.code === val);
                                return item.item_name[val] !== null && item.item_name[val] !== "" ? (
                                  <span key={index} className="nameTranslated">
                                    {countryValues ? item.item_name[val] : ""}
                                  </span>
                                ) : (
                                  <span className="nameTranslated">
                                    <img src={error} alt="" className="warningIcon" />
                                  </span>
                                );
                              })}
                            </td>
                            <td>{item.active === 1 ? <span className="status status-active" title="Active"></span> : <span className="status status-end" title="Inactive"></span>}</td>
                            <td>{upadtedBy !== "" && upadtedBy !== undefined ? upadtedBy.charAt(0).toUpperCase() + upadtedBy.slice(1) : "-"}</td>
                            <td>{createdBy !== "" && createdBy !== undefined ? createdBy.charAt(0).toUpperCase() + createdBy.slice(1) : "-"}</td>
                            <td> {webIcon ? <img src={webIcon} alt="" /> : "-"} </td>
                            <td> {mobileIcon ? <img src={mobileIcon} alt="" /> : "-"} </td>
                            {translationAccess.permissions.find((o) => o.slug === "update") ? (
                              <td className="action">
                                <button className=" me-2 edit-background" onClick={() => navigate(`/translation/edit-product-types/${item.id}?`+(countValue!==""?"per_page="+countValue+"&":"")+(page!==1?"page="+page:""))}>
                                  <img src={edit} alt="f" />
                                </button>
                              </td>
                            ) : (
                              ""
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <NoRecord />
                    )}
              </tbody>
            }
                </table>
              </div>
              {totalPage > 1 ? <Pagination totalPages={totalPage} page={page} key={totalPage} onPageClick={(page) => setPage(page + 1)} /> : ""}
          </div>
        </div>
      </TableWrapper>
    </>
  );
};

export default ProductType;
