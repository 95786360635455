import React, { useEffect, useState } from "react";
import { CraeteVehicleWrapper } from "../style/createVehicle";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { suggestionText } from "../../../config/constant";

const CreateCreditHistoryReasons = () => {
  const [languages, setLanguages] = useState(JSON.parse(localStorage.getItem("languages")) || []);
  const [countries, setCountries] = useState(JSON.parse(localStorage.getItem("countries")) || []);
  const [loading, setLoading] = useState(false);
  const [names, setNames] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const initialNames = languages.reduce((acc, language) => {
      acc[language.code] = "";
      return acc;
    }, {});
    setNames(initialNames);
  }, [languages]);

  const handleTitleChange = (value, code) => {
    setNames((prevNames) => ({ ...prevNames, [code]: value }));
  };

  const resetForm = () => {
    document.querySelectorAll(".departmentFields").forEach((input) => {
      input.value = "";
    });
  };

  const validateForm = () => {
    const enName = names["en"];
    const frName = names["fr"];
    if (!enName && !frName) {
      toast.error("Reason in English and French is mandatory", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    } else if (!enName) {
      toast.error("Please provide Reason in English", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    } else if (!frName) {
      toast.error("Please provide Reason in French", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    return true;
  };

  const submitForm = async () => {
    if (!validateForm()) return;

    const data = { name: names, type: suggestionText.ADD_CREDIT };
    setLoading(true);

    try {
      const res = await API.post(APP_URLS.ADD_SUGGESTION_TEXT, data);
      setLoading(false);
      if (res.data.success) {
        toast.success("Reason Added Successfully.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        navigate("/translation/credit-history-reasons");
        resetForm();
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      setLoading(false);
      const errorMessage = error.response?.data?.message || "An error occurred";
      toast.error(errorMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <>
      <CraeteVehicleWrapper>
        <div className="mt-2">
          <div className="backContainer ms-4" onClick={() => navigate("/translation/credit-history-reasons")}>
            <span className="backButton">x</span>
            <span className="px-3 backButton">|</span>
            <span className="backButton">Cancel</span>
          </div>
        </div>
        <div className="headerContainer">
          <h1>CREDIT HISTORY REASONS</h1>
        </div>
        <div className="d-flex justify-content-between save-btn mt-3">
          <p className="fieldLabel">Title</p>
          <button className={`add-button ${loading ? "disabled" : ""}`} onClick={submitForm} disabled={loading}>
            Save
          </button>
        </div>
        <div className="row input-fields">
          {languages.map((item, index) => {
            const flag = countries.find((o) => o.id === item.country_id)?.flag_thumbnail;
            return (
              <div className="col-md-4" key={index}>
                <div className="col-md-12 mt-3">
                  <label htmlFor={`title-${item.code}`} className="form-label">
                    <span className="countryIcon px-2">
                      <img src={flag} alt="" className="countryflag" title={item.name} />
                    </span>
                    <span className="langName">{item.code}</span>
                  </label>
                  <input
                    type="text"
                    id={`title-${item.code}`}
                    name="title"
                    className="form-control departmentFields"
                    onChange={(e) => handleTitleChange(e.target.value, item.code)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </CraeteVehicleWrapper>
    </>
  );
};

export default CreateCreditHistoryReasons;
