import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import API from '../../../api/axios'
import { APP_URLS } from '../../../api/url'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Radio, RadioGroup, FormControlLabel, FormControl, Divider, InputAdornment, TextField, Typography, Checkbox } from '@mui/material'
import { approvalStatus, penaltyText, penaltyType, refundOption, refundStatus } from '../../../config/constant'

const ApprovalPopup = (props) => {
    const [loading, _loading] = useState(false);
    const [penalty, setPenalty] = useState(penaltyType.WITHOUT_PENALTY); // Default penalty is 2 (without penalty)
    const [refund, setRefund] = useState(refundOption.FULL_REFUND); // Default penalty is 2 (without penalty)
    const [buyerRefund, _buyerRefund] = useState(true);
    const [rejectReason, _rejectReason] = useState('');
    const [isTouched, setIsTouched] = useState(false);
    const [confirmPopup, setConfirmPopup] = useState(false); // State for confirmation popup
    const [confirmationMessage, _confirmationMessage] = useState(''); // State for confirmation message
    const [rejectReasonError, setRejectReasonError] = useState(false); // State to show reject reason error message
    const [customRefund, setCustomRefund] = useState(''); //Custom refund amount
    const [customRefundError, setCustomRefundError] = useState(false); // State to show custom refund error message

    // Set initial value for customRefund when the component mounts
    useEffect(() => {
        setCustomRefund(props.initialRefundAmount || '');
    }, [props.initialRefundAmount]);

    //hanldes penalty input
    const handlePenaltyChange = (event) => {
        setPenalty(parseInt(event.target.value));
    };

    //hanldes refund input
    const handleRefundChange = (event) => {
        setRefund(parseInt(event.target.value));
    };

    useEffect(() => {
        if (!props.popup) {
            // Reset state when the component mounts
            setIsTouched(false);
            _rejectReason('');
            setRejectReasonError(false);
            setRefund(refundOption.FULL_REFUND);
            setCustomRefund(props.initialRefundAmount || '');
        }
    }, [props.popup]);

    useEffect(() => {
        let message = `Are you sure to approve cancel reservation ${penaltyText[penalty]}${buyerRefund ? ' and initiate refund for buyer' : ''}?`;
        _confirmationMessage(message)
    }, [confirmPopup])

    //open confirmation dialog if all conditions met
    const handleOpenConfirmPopup = () => {
        // Validate custom refund amount
        if (buyerRefund && refund == refundOption.CUSTOME_REFUND && customRefundError) {
            return;
        }

        //when rejecting
        if (props.isReject && !rejectReason) {
            setRejectReasonError(true);
            return;
        } else {
            //when approving
            setConfirmPopup(true);
        }
    }

    //Submit approval reqest
    const handleSubmit = async () => {
        const payload = {
            status: props.isReject ? approvalStatus.REJECT : approvalStatus.ACCEPT,
            //when approve
            ...(!props.isReject ? {
                refund_status: buyerRefund ? refundStatus.APPLIED : refundStatus.NOT_APPLIED,//1 or 2
                penalty: penalty,
                //when initiating refund for buyer and amount is customizable
                ...(buyerRefund && {
                    ...(refund == refundOption.CUSTOME_REFUND && {
                        refund_amount: customRefund,
                    }), 
                    refund_type: refund,
                })
            } : {
                //when reject
                reason: rejectReason,
            })
        };
        try {
            _loading(true);
            const res = await API.post(`${APP_URLS.HOTDEAL_CANCEL_RESERVATION}/${props.requestId}/action?_method=patch`, payload);
            if (res.status === 200) {
                props._popup(false);
                setConfirmPopup(false);
                props._approved(prev => !prev);
                toast.success('Request approved successfully.', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } catch (error) {
            const resp = error.response;
            let errorMessage = '';

            if (resp?.data?.data) {
                // Extract the first key from the errors object and its associated message
                const firstKey = Object.keys(resp.data.data)[0];
                if (firstKey) {
                    errorMessage = resp.data.data?.[firstKey][0]; // Get the first error message for that key
                }
            } else if (resp?.data?.message) {
                // If there is a general message available
                errorMessage = resp.data.message;
            } else {
                // Fallback to a generic error message if no specific message is found
                errorMessage = 'An error occurred';
            }
            // Show the error message in a toast notification
            toast.error(errorMessage, {
                position: toast.POSITION.TOP_RIGHT
            });
        } finally {
            _loading(false);
        }
    };

    //handles reject reason onChnage for reject reason field and truncate texts to 500 if text's length is greater than 500
    const handlesRejectReasonChange = (reasonText) => {
        const reasonSubstring = reasonText.substring(0, 500);
        _rejectReason(reasonSubstring);
        if (reasonSubstring) setRejectReasonError(false);
    }

    // Handles custom refund onChange and sets error if invalid
    const handleCustomRefundChange = (value) => {
        const parsedValue = parseFloat(value);
        if (parsedValue < 1 || parsedValue > props.initialRefundAmount) {
            setCustomRefundError(true);
        } else {
            setCustomRefundError(false);
        }
        setCustomRefund(value);
    };

    return (
        <>
            <Dialog open={props.popup && !confirmPopup} onClose={() => props._popup(false)} sx={{
                '.MuiPaper-root': {
                    width: '450px',
                }
            }}>
                <DialogTitle textAlign={'center'}>
                    <h5 className="modal-title">{`${props.isReject ? "Rejection Reason" : "Cancel Reservation Approval"}`}</h5>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    {!props.isReject ? <div>
                        <b>Action for Seller</b>
                        <FormControl component="fieldset" sx={{ width: '100%', mb: 1 }}>
                            <RadioGroup
                                aria-label="penalty"
                                name="penalty"
                                value={penalty}
                                onChange={handlePenaltyChange}
                            >
                                <div className='d-flex justify-content-start align-items-center'>
                                    <FormControlLabel value={penaltyType.WITH_PENALTY} control={<Radio />} label="With Penalty" />
                                    <FormControlLabel value={penaltyType.WITHOUT_PENALTY} control={<Radio />} label="Without Penalty" />
                                </div>
                            </RadioGroup>
                        </FormControl>
                        <div>
                            <b>Action for Buyer</b>
                            <div className="checkboxM">
                                <FormControlLabel
                                    control={<Checkbox checked={buyerRefund} onChange={() => _buyerRefund(!buyerRefund)} />}
                                    label="Initiate refund for buyer"
                                />
                            </div>
                        </div>
                        {buyerRefund && (
                            <FormControl component="fieldset" sx={{ width: '100%' }}>
                                <RadioGroup
                                    aria-label="refund"
                                    name="refund"
                                    value={refund}
                                    onChange={handleRefundChange}
                                >
                                    <div className='d-flex justify-content-start align-items-center'>
                                        <FormControlLabel value={refundOption.FULL_REFUND} control={<Radio />} label="Full refund" />
                                        <FormControlLabel value={refundOption.CUSTOME_REFUND} control={<Radio />} label="Custom" />
                                    </div>
                                </RadioGroup>
                            </FormControl>
                        )}
                        {(buyerRefund && refund == refundOption.CUSTOME_REFUND) && (<FormControl fullWidth sx={{ mt: 1 }}>
                            <TextField
                                id="outlined-multiline-flexible"
                                name='custom-refund'
                                value={customRefund}
                                label="Custom refund"
                                variant="outlined"
                                min={1}
                                max={customRefund}
                                onChange={(e) => handleCustomRefundChange(e.target.value)}
                            />
                            {customRefundError && (
                                <Typography color={'red'}>{`Custom refund amount should not exceed ${props.initialRefundAmount}`}</Typography>
                            )}
                        </FormControl>
                        )}
                    </div> :
                        <FormControl fullWidth sx={{
                            'legend': {
                                display: 'none',
                            },
                            '.numberLimit': {
                                position: 'absolute',
                                bottom: '10px',
                                right: '4px',
                            }
                        }}>
                            <TextField
                                id="outlined-multiline-flexible"
                                name='reject-reason'
                                value={rejectReason}
                                multiline
                                minRows={1}
                                maxRows={3}
                                variant="outlined"
                                onChange={(e) => handlesRejectReasonChange(e.target.value)}
                                onBlur={() => setIsTouched(true)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end" className='numberLimit'>{`${rejectReason ? rejectReason.length : '0'}/500`}</InputAdornment>
                                }}
                            />
                            {(isTouched && !rejectReason) || rejectReasonError ? <Typography color={'red'}>Reason field is required</Typography> : null}
                        </FormControl>}
                </DialogContent>
                <DialogActions sx={{ padding: '8px 24px' }}>
                    <Button onClick={() => props._popup(false)} className='text-secondary border border-secondary rounded-3'>
                        Cancel
                    </Button>
                    <Button onClick={() => handleOpenConfirmPopup()} className='btn' variant="contained" disabled={loading}>
                        {loading ? (props.isReject ? "Rejecting..." : "Approving...") : (props.isReject ? "Reject" : "Approve")}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* confirmation popup */}
            <Dialog open={confirmPopup} onClose={() => setConfirmPopup(false)} sx={{
                '.MuiPaper-root': {
                    width: '450px'
                },
                'text-white': {
                    color: 'white'
                }
            }}>
                <DialogTitle textAlign={'center'}>
                    <h5 className="modal-title">Confirm Action</h5>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <p className='mb-3'>{props.isReject ? "Are you sure you want to reject the request?" : confirmationMessage}</p>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setConfirmPopup(false)
                            props._popup(true);
                        }}
                        className='text-secondary border border-secondary rounded-3'>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} className='btn text-white' variant="contained" disabled={loading}>
                        {loading ? "Processing..." : "Confirm"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ApprovalPopup;
