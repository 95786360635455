import React, { Fragment, useEffect, useRef, useState } from "react";
import MainLayout from "../../../assets/Layout/MainLayout";
import { CraeteVehicleWrapper } from "../style/createVehicle";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import { data } from "./data";
import { useNavigate } from "react-router-dom";
import SearchableObjDropdown from "../../../assets/SharedComponents/SearchableDropdown/SearchableObjDropdown";
import SearchableDropdown from "../../../assets/SharedComponents/SearchableDropdown/SearchableDropdown";
import SearchableDropdownTyre from "../../../assets/SharedComponents/SearchableDropdown/SearchableDropdownTyre";
import BrandFilter from "../../../assets/SharedComponents/Filters/BrandFilter";
import ModelFilter from "../../../assets/SharedComponents/Filters/ModelFilter";


const CreateVersionCar = () => {

  const [name,_name] = useState({})

  const [loading,_loading] = useState(false)

  const [fieldData, _fieldData] = useState(data);
  const [error, setError] = useState("");
  
  
  // Dropdown Data
  const [products, _products] = useState(JSON.parse(localStorage.getItem("productType")));
  const [country] = useState(JSON.parse(localStorage.getItem("countries")));
  const [fuelItemData, setFuelItemData] = useState("");
  const [drivingWheelItemData, setDrivingWheelItemData] = useState("");
  const [bodyWheelItemData, setBodyWheelItemData] = useState(""); 
  const [gearBoxData, setGearBoxData] = useState("");
  const [tireSizeData, setTireSizeData] = useState("");
  const [brandType, setBrandType] = useState("");
  const [euroStandard, setEuroStandard] = useState("");
  
  //Field Values
  const [countryId, setCountryId] = useState("");
  const [brandValue, _brandValue] = useState('');
  const [modelValue, _modelValue] = useState('');
  const [euroStandardId, setEuroStandardId] = useState("");
  const [bodyVehicleId, setBodyVehicleId] = useState("");
  const [fuelId, setFuelId] = useState("");
  const [gearboxId, setGearboxId] = useState("");
  const [backTireSizeId, setBackTireSizeId] = useState("");
  const [frontTireSizeId, setFrontTireSizeId] = useState("");
  const [drivingWheelId, setDrivingWheelId] = useState("");
  const [productValue, _productValue] = useState("");
  const [carDifferntialLock, setCarDifferntialLock] = useState("");
  const [spareWheel, setSpareWheel] = useState("");
  
  // Filter states 
  const [modelName, _modelName] = useState('');

  const navigate = useNavigate();

  const submitForm = () => {
    let data = {};
    data = name;
    data["country_id"] = countryId;
    data["model_id"] = modelValue;
    data["body_vehicle_id"] = bodyVehicleId;
    data["fuel_id"] = fuelId;
    data["gearbox_id"] = gearboxId;
    data["back_tire_size_id"] = backTireSizeId;
    data["front_tire_size_id"] = frontTireSizeId;
    data["driving_wheel_id"] = drivingWheelId;
    data["product_type_id"] = productValue;
    data["spare_wheel"] = spareWheel;
    data["car_differential_lock"] = carDifferntialLock;
    data["euro_standard_id"] = euroStandardId;
    let flag = false;
    if (productValue === "" || productValue === undefined || productValue === null) {
      flag = false;
      toast.error("Please provide product type", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (brandValue === "" || brandValue === undefined || brandValue === null) {
      flag = false;
      toast.error("Please provide Vehicle brand", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    else if (modelValue === "" || modelValue === undefined || modelValue === null) {
      flag = false;
      toast.error("Please provide Vehicle model", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (name["version_name"] === "" || name["version_name"] === undefined || name["version_name"] === null) {
      flag = false;
      toast.error("Version name is mandatory", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (name["year"] === "" || name["year"] === undefined || name["year"] === null) {
      flag = false;
      toast.error("Version year is mandatory ", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }else if(Number(name["year"])<1901||Number(name["year"])>new Date().getFullYear()){
      flag = false;
      toast.error("Version year should be between 1901 and "+new Date().getFullYear(), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }else if (countryId === "" || countryId === undefined || countryId === null) {
      flag = false;
      toast.error("Please provide country name", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (name["front_tire_size_id"] === "" || name["front_tire_size_id"] === undefined || name["front_tire_size_id"] === null) {
      flag = false;
      toast.error("Please provide front tire size", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (name["back_tire_size_id"] === "" || name["back_tire_size_id"] === undefined || name["back_tire_size_id"] === null) {
      flag = false;
      toast.error("Please provide back tire size", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (name["fuel_id"] === "" || name["fuel_id"] === undefined || name["fuel_id"] === null) {
      flag = false;
      toast.error("Please provide fuel type", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (name["gearbox_id"] === "" || name["gearbox_id"] === undefined || name["gearbox_id"] === null) {
      flag = false;
      toast.error("Please provide gearbox type", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (name["driving_wheel_id"] === "" || name["driving_wheel_id"] === undefined || name["driving_wheel_id"] === null) {
      flag = false;
      toast.error("Please provide driving wheel type", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (name["body_vehicle_id"] === "" || name["body_vehicle_id"] === undefined || name["body_vehicle_id"] === null) {
      flag = false;
      toast.error("Please provide body vehicle", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      flag = true;
    }
    if (flag === true) {
      if(!loading){
        _loading(true)
        API.post(APP_URLS.ADD_VERSION_CARS, data)
          .then((res) => {
            _loading(false)
            const resp = res.data;
            if (resp.success === true) {
              toast.success("Version Vehicle Added Successfully.", {
                position: toast.POSITION.TOP_RIGHT,
              });
              resetForm();
              navigate("/vehicles-management/vehicle-versions");
            } else {
              toast.error(resp.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
          .catch(function (error) {
            _loading(false)
            const resp = error.response;
            let error_message = "";
            if (resp.data.data !== undefined && resp.data.data !== null) {
              {
                Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
              }
            } else if (resp.data.data?.error !== undefined) {
              error_message = resp.data.data.error;
            } else if (resp.data?.error !== undefined) {
              error_message = resp.data.error;
            } else {
              error_message = resp.data.message;
            }
            setError(error.response.data.data);
            toast.error(error_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      }
    }
  };

  useEffect(() => {
    let values = [...fieldData];
    if (Object.keys(error).length > 0) {
      Object.keys(error).map((item) => {
        let index = data.findIndex((x) => x.code === item);
        values[index]["error"] = error[item];
      });
    }
    _fieldData(values);

    return () => {
      if (Object.keys(error).length > 0) {
        Object.keys(error).map((item) => {
          let index = data.findIndex((x) => x.code === item);
          values[index]["error"] = "";
        });
      }
      _fieldData(values);
    }
  }, [error]);

  

  const handleTitle = (val, code) => {
    _name(prev=>{return {...prev,[code]:code==='year'?val.substr(0,4):val}})
  };

  const resetForm = () => {
    let inputs = document.querySelectorAll(".departmentFields");
    inputs.forEach((input) => {
      input.value = "";
    });
  };

  const getFuelId = () => {
    API.get(APP_URLS.FUEL_ID + `?filter[product_type_id]=${productValue}&limited_data=true`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          let data = {}
          resp.data.items.forEach((element)=>{
              data[`${element.id}_${element.name}`] = element.name
          })
          setFuelItemData(data);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getDrivingWheelId = () => {
    API.get(APP_URLS.DRIVING_WHEEL_ID + `?filter[product_type_id]=${productValue}&limited_data=true`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          let data = {}
          resp.data.items.forEach((element)=>{
              data[`${element.id}_${element.name}`] = element.name
          })
          setDrivingWheelItemData(data);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getBodyWheelId = () => {
    API.get(APP_URLS.BODY_WHEEL_ID + `?filter[product_type_id]=${productValue}&limited_data=true`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          let data = {}
          resp.data.items.forEach((element)=>{
              data[`${element.id}_${element.name}`] = element.name
          })
          setBodyWheelItemData(data);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
 
  const getGearBoxId = () => {
    API.get(APP_URLS.GEAR_BOX_ITEM_ID + `?filter[product_type_id]=${productValue}&limited_data=true`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          let data = {}
          resp.data.items.forEach((element)=>{
              data[`${element.id}_${element.name}`] = element.name
          })
          setGearBoxData(data);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getTireSizeId = () => {
    API.get(APP_URLS.FRONT_TIRE_ITEM_ID + `?filter[product_type_id]=${productValue}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          setTireSizeData(resp.data.items);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
 
  const getEuroStandard = () => {
    API.get(APP_URLS.EURO_STANDARD + `?limited_data=true`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          let data = {}
          resp.data.items.forEach((element)=>{
              data[`${element.id}_${element.name}`] = element.name
          })
          setEuroStandard(data);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  useEffect(() => {
    getEuroStandard();
  }, []);
  
  useEffect(() => {
    if(productValue){
      getFuelId();
      getDrivingWheelId();
      getBodyWheelId();
      getGearBoxId();
      getTireSizeId();
    }
  }, [productValue]); 
 
  const setBrandValue = (val) => {
    _brandValue(val.split('_')[0])
  }
  const setModelValue = (val) => {
    _modelValue(val.split('_')[0])
  }
  const setChangeValue = (func, item) => {
    func(item.split('_')[0]);
  }
  const setobjValue = (func, item) => {
    func(item);
  }


  const resetStates = () =>{ 
    _modelName("");
    _brandValue("");
    _modelValue("");
    setBodyVehicleId("");
    setFuelId("");
    setGearboxId("");
    setBackTireSizeId("");
    setFrontTireSizeId("");
    setDrivingWheelId("");    
  }

  return (
    <>
      <CraeteVehicleWrapper>
        <div className="mt-2">
          <div className="backContainer ms-4" onClick={() => navigate("/vehicles-management/vehicle-versions")}>
            <span className="backButton">x</span>
            <span className="px-3 backButton">|</span>
            <span className="backButton">Cancel</span>
          </div>
        </div>
        <div className="headerContainer">
          <h1>CREATE VERSIONS</h1>
        </div>
        <div className="d-flex justify-content-between save-btn mt-3">
          <h3 className="mb-2 fieldLabel">Language</h3>
          <button className={(loading?"disabled":"")+" add-button"} onClick={submitForm} disabled={loading}>Save</button>
        </div>
        <div className="row input-fileds">
          <div className="col-md-4 mt-3">
            <div className="row">
              <div className="col-md-12">
                <label htmlFor="productType" className="form-label">
                  Product Type
                </label>
                <select
                  name="productType"
                  id="productType"
                  className="form-control"
                  onChange={(e) => {
                    _productValue(e.target.value);
                    resetStates()
                  }}
                >
                  <option hidden>Select Product Type</option>
                  {products.map((item, index) => {
                    return (
                      <option value={item.id} key={index}>
                        {item.item_name.en}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="col-md-12 mt-3 position-relative">
              <label htmlFor="productType" className="form-label fieldLabel product-type d-flex justify-content-between">
                <span>Brand Name</span>
              </label>
              <BrandFilter brand={brandValue} setBrand={(val)=>setBrandValue(val)} productValue={productValue} className={(productValue === ''?"disabled":"")+" form-control"}  />   
            </div>
          </div>
          <div className="col-md-4">
            <div className="col-md-12 mt-3 position-relative">
              <label htmlFor="productType" className="form-label fieldLabel product-type d-flex justify-content-between">
                <span>Model Name</span>
              </label>
              <ModelFilter brandID={brandValue} className={(productValue === ''||brandValue===""?"disabled":"")+" form-control"}  modelID={modelValue} setChangeValue={(val)=>setModelValue(val)} productValue={productValue}/>  
            </div>
          </div>
          {
            brandValue === "" || modelValue === "" || productValue === "" ?
              null
              :
              <Fragment>
                {fieldData.map((item, index) => {
                  let itemCode = item.code;
                  return (
                    <div className="col-md-4" key={index}>
                      <div className="col-md-12 mt-3">
                        <label htmlFor="title" className="form-label">
                          <span className="langName">{item.label}</span>
                        </label>
                        <input
                          type={item.type}
                          id={item.label}
                          name={item.label}
                          value={name[itemCode]}
                          className="form-control departmentFields"
                          onChange={(e, code = itemCode) => {
                            handleTitle(e.target.value, code);
                          }}
                        />
                        <span style={{ color: "red" }}>{item.error}</span>
                      </div>
                    </div>
                  );
                })}
              </Fragment>
          }
          {brandValue === "" || modelValue === "" || productValue === "" ?
            null
            :
            <div className="col-md-4">
              <div className="col-md-12 mt-3">
                <label htmlFor="title" className="form-label">
                  <span className="langName">Spare Wheel</span>
                </label>
                <select name="spare_wheel" id="spare_wheel" className="form-control" onChange={(e) => setSpareWheel(e.target.value)}>
                  <option hidden>Select</option>
                  <option value={0}>False</option>
                  <option value={1}>True</option>
                </select>
              </div>
            </div>
          }
          {
            brandValue === "" || modelValue === "" || productValue === "" ?
              null
              :
              <div className="col-md-4">
                <div className="col-md-12 mt-3">
                  <label htmlFor="title" className="form-label">
                    <span className="langName">Car Differential Lock</span>
                  </label>
                  <select name="car_differential_lock" id="car_differential_lock" className="form-control" onChange={(e) => setCarDifferntialLock(e.target.value)}>
                    <option hidden>Select</option>
                    <option value={1}>True</option>
                    <option value={0}>False</option>
                  </select>
                </div>
              </div>
          }
          {
            brandValue === "" || modelValue === "" || productValue === "" ?
              null
              :
              <div className="col-md-4">
                <div className="col-md-12 mt-3">
                  <label htmlFor="title" className="form-label">
                    <span className="langName">Country</span>
                  </label>
                  <SearchableDropdown name="country_id" id="country_id" className="form-control" setChangeValue={(val) => val?setobjValue(setCountryId, val):null} val={countryId} placeholder="Select Country" dropdownData={country} />
                </div>
              </div>
          }
          {
            brandValue === "" || modelValue === "" || productValue === "" ?
              null
              :
              <div className="col-md-4">
                <div className="col-md-12 mt-3">
                  <label htmlFor="title" className="form-label">
                    <span className="langName">Front Tire Size (W/H/D)</span>
                  </label>
                  <SearchableDropdownTyre name="front_tire_size_id" id="front_tire_size_id" className="form-control" setChangeValue={(val) => val?setobjValue(setFrontTireSizeId, val):null} placeholder="Select Front Tire Size (W/H/D)" dropdownData={tireSizeData} />
                </div>
              </div>
          }
          {
            brandValue === "" || modelValue === "" || productValue === "" ?
              null
              :
              <div className="col-md-4">
                <div className="col-md-12 mt-3">
                  <label htmlFor="title" className="form-label">
                    <span className="langName">Back Tire Size (W/H/D)</span>
                  </label>
                  <SearchableDropdownTyre name="back_tire_size_id" id="back_tire_size_id" className="form-control" setChangeValue={(val) => val?setobjValue(setBackTireSizeId, val):null} placeholder="Select Back Tire Size (W/H/D)" dropdownData={tireSizeData} />
                </div>
              </div>
          }
          {
            brandValue === "" || modelValue === "" || productValue === "" ?
              null
              :
              <div className="col-md-4">
                <div className="col-md-12 mt-3">
                  <label htmlFor="title" className="form-label">
                    <span className="langName">Fuel</span>
                  </label>
                  <SearchableObjDropdown name="fuel_id" id="fuel_id" className="form-control" setChangeValue={(val) => val?setChangeValue(setFuelId, val):null} placeholder="Select Fuel" dropdownData={fuelItemData} />
                </div>
              </div>
          }
          {
            brandValue === "" || modelValue === "" || productValue === "" ?
              null
              :
              <div className="col-md-4">
                <div className="col-md-12 mt-3">
                  <label htmlFor="title" className="form-label">
                    <span className="langName">Gearbox</span>
                  </label>
                  <SearchableObjDropdown name="gearbox_id" className="form-control" setChangeValue={(val) => val?setChangeValue(setGearboxId, val):null} placeholder="Select Gearbox" dropdownData={gearBoxData} />
                </div>
              </div>
          }
          {
            brandValue === "" || modelValue === "" || productValue === "" ?
              null
              :
              <div className="col-md-4">
                <div className="col-md-12 mt-3">
                  <label htmlFor="title" className="form-label">
                    <span className="langName">Driving Wheel</span>
                  </label>
                  <SearchableObjDropdown name="driving_wheel_id" className="form-control" setChangeValue={(val) => val?setChangeValue(setDrivingWheelId, val):null} placeholder="Select Driving Wheel" dropdownData={drivingWheelItemData} />
                </div>
              </div>
          }
          {
            brandValue === "" || modelValue === "" || productValue === "" ?
              null
              :
              <div className="col-md-4">
                <div className="col-md-12 mt-3">
                  <label htmlFor="title" className="form-label">
                    <span className="langName">Body Vehicle</span>
                  </label>
                  <SearchableObjDropdown name="body_vehicle_id" className="form-control" setChangeValue={(val) => val?setChangeValue(setBodyVehicleId, val):null} placeholder="Select Body Vehicle" dropdownData={bodyWheelItemData} />
                </div>
              </div>
          }

          {
            brandValue === "" || modelValue === "" || productValue === "" ?
              null
              :
              <div className="col-md-4">
                <div className="col-md-12 mt-3">
                  <label htmlFor="title" className="form-label">
                    <span className="langName">Euro Standard</span>
                  </label>
                  <SearchableObjDropdown name="euro_standard_id" className="form-control" setChangeValue={(val) => val?setChangeValue(setEuroStandardId, val):null} placeholder="Select Euro Standard" dropdownData={euroStandard} />
                </div>
              </div>
          }
        </div>
      </CraeteVehicleWrapper>
    </>
  );
};;;;;

export default CreateVersionCar;
