import styled from "styled-components";

export const AuctionWrapper = styled.div`
  .headerContainer div,
  .headerContainer h1 {
    margin-bottom: 10px;
  }
  .searchContainer {
    display: flex;
    padding: 0 15px 0 0;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  .info-wrap {
    flex-wrap: wrap;
  }
  .search {
    height: 35px;
    border: none;
    border-radius: 10px;
  }
  .btn {
    align-self: center;
    background-color: #607afc;
    border: none;
    height: 35px;
    border-radius: 5px;
    padding: 0 20px;
    color: white;
    font-weight: bold;
  }
  .auctionEventImage {
    width: 90px;
    height: auto;
  }
  .action {
    display: flex;
    align-items: center;
  }
  .action button {
    border: none;
    background: transparent;
  }
  .form-check {
    min-height: auto !important;
  }
  .form-check-input {
    cursor: pointer !important;
  }
  .form-check-input:checked {
    background-color: #5d78ff;
    border-color: #5d78ff;
  }
  .select-info {
    flex-wrap: wrap;
  }
  .select-info select {
    max-width: 130px;
  }
  .time-start {
    min-width: 125px;
  }
  .time-start svg {
    width: 10px;
    height: 15px;
    cursor: pointer;
  }
  .opacity-40 {
    opacity: 40%;
  }
  .three-dot{
    align-items: center;
    height: 75vh;
  }
  @media (max-width: 1226px) {
    .info-wrap {
      justify-content: center !important;
    }
  }
  @media (max-width: 485px) {
    .info-wrap {
      justify-content: center !important;
    }
  }
  @media (max-width: 485px) {
    .info-wrap {
      justify-content: center !important;
    }
  }
`;