import styled from "styled-components";

export const DepositHistoryWrapper = styled.div`
  .text-decor {
    text-decoration: none;
  }
  .success-color {
    color: green;
  }
  .time-start {
    min-width: 165px;
  }
  .auctionTable {
    min-width: 600px;
  }
  .time-start svg {
    width: 10px;
    height: 15px;
    cursor: pointer;
  }
  .extract-report {
    font-weight: 600;
    cursor: pointer;
    text-transform: none;
    color: black;
    border: 1px solid rgba(0, 0, 0, 0.2);
    height: 32px;
    &:hover {
      outline: none;
    }
  }
  .date-range-container {
    border-radius: 10px;
  }
  .date-range-container > div:first-child input {
    border-radius: 10px 0 0px 10px;
  }
  .date-range-container > div:last-child input {
    border-radius: 0 10px 10px 0;
  }
  .date-range-container input {
    background: white !important;
    border: 1px solid #ced4da;
    width: 10rem;
  }
  .extract-report {
    font-weight: 600;
    cursor: pointer;
    text-transform: none;
    color: black;
    border: 1px solid rgba(0, 0, 0, 0.2);
    height: 32px;
    &:hover {
      outline: none;
    }
  }
  .link {
    font-weight: bold;
  }
  .w-150 {
    min-width: 150px;
  }
  .accept {
    color: #5d78ff;
    border: 1px solid #5d78ff;
    font-weight: 100;
    font-size: 12px;
    background: white;
    -webkit-letter-spacing: 2px;
    -moz-letter-spacing: 2px;
    -ms-letter-spacing: 2px;
    letter-spacing: 2px;
    padding: 4px 22px;
    border-radius: 3px;
  }
  .accept:hover {
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
    font-weight: bold;
    box-shadow: rgb(130 131 131 / 50%) 5px 2.196px 11px;
  }
`;
