import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import API from "../../../../api/axios";
import { APP_URLS } from "../../../../api/url";
import { ThreeDots } from "react-loader-spinner";
import { SlidesWrapper } from "../../Slides/style/slide";
import moment from "moment";
import { Tooltip } from "@mui/material";
import { FaFilterIcon } from "../../../../assets/Images/icon";
import Pagination from "../../../../assets/SharedComponents/Pagination";
import cross from "../../../../assets/Images/icons/cross.svg";
import disable from "../../../../assets/Images/icons/disable.svg";
import edit from "../../../../assets/Images/icons/edit.svg";
import check from "../../../../assets/Images/icons/check.svg";
import { confirmAlert } from "react-confirm-alert";
import SearchableDropdown from "../../../../assets/SharedComponents/SearchableDropdown/SearchableDropdown";
import RecordCount from "../../../../assets/SharedComponents/RecordCount/RecordCount";
import { ADD_SLIDER_TYPE } from "../../../../config/constant";
import CountryFilter from "../../../../assets/SharedComponents/Filters/CountryFilter";


const Settings = ({_addtab,_slideID,page,setPage,status,_status,countValue,_countValue,countryFilter,_countryFilter}) => {
   const [data, _data] = useState([]);  
  const [loaded, _loaded] = useState(false);
  const [total, _total] = useState(0);
  const [totalPage, _totalPage] = useState(0);
  const [filters, _filters] = useState(false);

  const [userAccess,_userAccess] = useState(JSON.parse(localStorage.getItem('permissions')))
  const [languages, _languages] = useState(JSON.parse(localStorage.getItem("languages")));
  const [countries, _countries] = useState(JSON.parse(localStorage.getItem("countries")));
  
  let slideAccess = userAccess.find(o=>o.slug ==="slide_management")

  useEffect(() => {
    getSliders();
  }, [status,page,countryFilter,countValue]);

  const getSliders = () => {
    _loaded(true);
    API.get(APP_URLS.GET_SLIDES + `?page=${page}&per_page=${countValue}&filter[type]=${ADD_SLIDER_TYPE.HOME}&filter[status]=${status}&filter[country_id]=${countryFilter}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _data(resp.data.data)
          _total(resp.data.total);
          _totalPage(resp.data.last_page);
        _loaded(false);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loaded(false);
        }
      })
      .catch(function (error) {
        _loaded(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const removeType = (index) => {
    confirmAlert({
      title: "Confirm to remove",
      message: `Are you sure you want to remove?`,
      closeOnEscape: true,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            API.delete(APP_URLS.DELETE_SLIDES + `/${index}`)
              .then((res) => {
                const resp = res.data;
                if (resp.success === true) {
                  toast.success("Slide has been deleted successfully.", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  getSliders();
                } else {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              })
              .catch(function (error) {
                const resp = error.response;
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                  {
                    Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
                  }
                } else if (resp.data.data?.error !== undefined) {
                  error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                  error_message = resp.data.error;
                } else {
                  error_message = resp.data.message;
                }
                toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          },
        },
        {
          label: "No",
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-custom-class-name",
    });
  };

  const disableSlider = (index,status) => {
    confirmAlert({
      title: `Confirm to ${status===1?"disable":"enable"}`,
      message: `Are you sure you want to ${status===1?"disable":"enable"} this?`,
      closeOnEscape: true,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            let data= {}
            data['status'] = status===1?0:1
            API.post(APP_URLS.TOGGLE_SLIDES + `/status/${index}?_method=PATCH`,data)
              .then((res) => {
                const resp = res.data;
                if (resp.success === true) {
                  toast.success("Slide has been updated successfully.", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  getSliders();
                } else {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              })
              .catch(function (error) {
                const resp = error.response;
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                  {
                    Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
                  }
                } else if (resp.data.data?.error !== undefined) {
                  error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                  error_message = resp.data.error;
                } else {
                  error_message = resp.data.message;
                }
                toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          },
        },
        {
          label: "No",
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-custom-class-name",
    });
  };

  const setChangeValue = (val) =>{
    _countryFilter(val)
    if (countryFilter !== "") {
      _totalPage(0)
      setPage(1);
    }
  }

  const handleSearchChange = (searchValue) => {
    _countValue(searchValue);
    if (searchValue !== "") {
      _totalPage(0)
      setPage(1);
    }
  };


  const clearFilter = () => {
    _filters(true);
    _status('')
    _countryFilter('')
    handleSearchChange('');
    if(countValue !== ''){
      _totalPage(0)
      setPage(1);
    }
  };

  return (
    <SlidesWrapper>
    <div className="preview-desktop-container">
      <div className="preview-desktop-area">
      <div className="row p-2">
        <div className="d-flex justify-content-between add-info flex-wrap">
          <div className="add-depertment d-flex justify-content-end mb-2">
            {slideAccess?.permissions.find((o) => o.slug === "delete")||slideAccess?.permissions.find((o) => o.slug === "update") ?
            <button className="addbtn ms-4" onClick={()=>_addtab(true)}>+ ADD</button>
            :''}
          </div>
          <div className="d-flex gap-2 select-info mb-2 align-items-center justify-content-end flex-wrap">
            <CountryFilter value={countryFilter} handleChange={setChangeValue}/>   
            <select name="status" id="status"  className="filterBox" value={status} onChange={(e) => {
              _status(e.target.value)
              _totalPage(0)
              setPage(1)
              }}>
              <option value="">Select status</option>
              <option value="1">Active</option>
              <option value="0">Inactive</option>
            </select>
            <RecordCount
              onSearchClick={(search) => handleSearchChange(search)}
              id="pageCount"
              val={countValue}
              filters={filters}
              _filters={_filters} />
            <Tooltip title="Clear Filter" placement="bottom">
              <FaFilterIcon className="filter-icon" role="button" onClick={clearFilter} />
            </Tooltip>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between info-wrap">
            <div className="justify-content-end mt-1 me-2 ms-2">
                <span className='text-start'>Total Records : <b>{total}</b></span>
            </div>
        </div>
        {
          loaded ? 
            <div className='d-flex justify-content-center three-dot'>              
                <ThreeDots 
                  height="150" 
                  width="150" 
                  radius="9"
                  color="#637df4" 
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
            </div>
            :
            <div className="col-md-12">
            <div className="slider-outer">
              {data.length>0?
                data.map((item, index) => {
                  return <div className="row d-flex justify-content-between imageData p-2 mt-2" key={index}>
                    <div className="col-md-4 col-lg-3 imageBox mb-2" >
                      <div className="slide-outer slider-border-rm">
                        <div className="d-flex justify-content-center flex-column">
                          <div className="slide-single">
                            <img src={item.media[0].original_url} className="image" id="image" alt="search" />
                            <div className="slide-action">
                              <button onClick={()=>removeType(item.id)} disabled={slideAccess?.permissions.find((o) => o.slug === "delete")?false:true} className={slideAccess?.permissions.find((o) => o.slug === "delete")?"":"disabled"}>
                                <img src={cross} className="slide-icon" id="image" alt="search" />
                                <span>Delete</span>
                              </button>
                              <button onClick={()=>disableSlider(item.id,item.status)} disabled={slideAccess?.permissions.find((o) => o.slug === "update")?false:true} className={slideAccess?.permissions.find((o) => o.slug === "update")?"":"disabled"}>
                                {item.status===1?
                                <>
                                  <img src={disable} className="slide-icon" id="image" alt="search" />
                                  <span>Disable</span>
                                </>
                                :
                                <>
                                  <img src={check} className="slide-icon" id="image" alt="search" />
                                  <span>Enable</span>
                                </>
                                }
                              </button>
                              <button disabled={slideAccess?.permissions.find((o) => o.slug === "update")?false:true} className={slideAccess?.permissions.find((o) => o.slug === "update")?"":"disabled"}
                                onClick={()=>{
                                _slideID(item.id)
                                _addtab(true)
                              }} >
                                <img src={edit} className="slide-icon" id="image" alt="search" />
                                <span>Edit</span>
                              </button>
                            </div>

                          </div> 
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4 link-section">
                      <div className="d-flex justify-content-between mb-3">
                        <span className="fw-bold">Start Published at:</span>
                        <span>{moment(item.start_date).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm")}</span>
                      </div>
                      <div className="d-flex justify-content-between mb-3">
                        <span className="fw-bold">End Published at:</span>
                        <span>{item.end_date?moment(item.end_date).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm"):"-"}</span>
                      </div>                          
                      {/* <div className="d-flex justify-content-between mb-3">
                        <span className="fw-bold">Display Time(s):</span>
                        <span>-</span>
                      </div>                           */}
                      <div className="d-flex justify-content-between mb-3">
                        <span className="fw-bold">Number of View:</span>
                        <span>-</span>
                      </div>                          
                      <div className="d-flex justify-content-between mb-3">
                        <span className="fw-bold">Number of Clicks:</span>
                        <span>-</span>
                      </div>
                      <div className="d-flex justify-content-between mb-3">
                        <span className="fw-bold">Allowed Countries:</span>
                        <div className="d-flex flex-row flex-wrap flagbox px-1 justify-content-end">
                          {item.countries!==null?
                          item.countries.map((item,index)=>{
                              let flag;
                              let name;
                              let countryValues = countries.find((o) => o.id === item);
                              if (countryValues) {
                                flag = countries.find((o) => o.id === item)?.flag_thumbnail;
                                name = countries.find((o) => o.id === item)?.fullname;
                              }
                              return <img className="ms-1 mb-1 countryflag" src={flag} key={index} title={name}/>
                            })
                          :"All"}
                        </div>
                      </div>                           
                    </div>
                    <div className="col-md-4 col-lg-4 link-section" >
                      <div className="d-flex justify-content-between mb-3">
                        <span className="fw-bold">Link:</span>
                        {item.redirect_link!==""&&item.redirect_link!==null?
                        <span className="imageLink" onClick={()=>window.open(item.redirect_link, '_blank').focus()}>
                          {item.redirect_link}
                        </span>:
                        <span>-</span>
                        }
                      </div>            
                    </div>
                  </div>
                })
              :<div className="noRecord">
                  <span>No record</span>
                </div>}
            </div>
            <div>
          </div>
        </div>
        } 
        {totalPage > 1 ? 
          <Pagination 
            totalPages={totalPage} 
            key={totalPage} 
            page={page}
            onPageClick={(page) => setPage(page + 1)} 
            /> 
        : ""}  
      </div>
      </div>
      </div>
    </SlidesWrapper>
  )
}

export default Settings;