import styled from "styled-components";

export const DataProviderWrapper = styled.div`
  .headerContainer div,
  .headerContainer h1 {
    margin-bottom: 10px;
  }
  .reviewTable th,
  tr.table-row td {
    width: 10%;
    padding: 15px 5px;
    display: block;
    justify-content: center;
    align-self: center;
    text-align: center;
    word-break: break-word;
  }
  .reviewTable th.longText,
  tr.table-row td.longText {
    width: 20%;
    text-align: start;
  }
  .reviewTable th,
  tr.table-row td {
    width: 12%;
  }

  .info-wrap {
    flex-wrap: wrap;
  }
  .search {
    height: 35px;
    border: none;
    border-radius: 10px;
  }
  .btn {
    align-self: center;
    background-color: #607afc;
    border: none;
    height: 35px;
    border-radius: 5px;
    padding: 0 20px;
    color: white;
    font-weight: bold;
  }
  .action-wrap {
    width: 30% !important;
    text-align: center;
  }
  .action {
    display: flex;
    align-items: center;
  }
  .action button {
    border: none;
    background: transparent;
  }

  .form-check {
    min-height: auto !important;
  }
  .form-check-input {
    cursor: pointer !important;
  }
  .form-check-input:checked {
    background-color: #5d78ff;
    border-color: #5d78ff;
  }
  .select-info {
    flex-wrap: wrap;
  }
  .select-info select {
    max-width: 130px;
  }
  .time-start {
    min-width: 125px;
  }
  .time-start svg {
    width: 10px;
    height: 15px;
    cursor: pointer;
  }
  .filterBox {
    max-height: 20vh;
  }
  .filterBox > option {
    width: 10rem;
  }
  .rating-stars > img {
    width: 24px;
  }
  .opacity-40 {
    opacity: 40%;
  }
  .three-dot {
    align-items: center;
    height: 75vh;
  }
  .deletedRow {
    background: rgba(239, 71, 71, 0.1) !important;
    cursor: not-allowed;
  }
  .no-action-row {
    background: #ffed4980 !important;
    cursor: not-allowed;
  }
  .show-options {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }
  .select-wrapper {
    border: 1px solid lightgray;
    border-radius: 13px;
    padding: 0 5px;
  }
  .select-wrapper > select {
    border: none;
  }
  .show-button {
    padding: 0;
  }
  svg {
    margin-left: 5px;
  }
  td.link {
    display: flex !important;
    justify-content: center;
  }
  .link b {
    display: flex;
    width: max-content;
  }
  .link b svg,
  .popup-item svg {
    width: 16px;
    height: 16px;
    stroke: blue;
    fill: blue;
  }
  .add-disable {
    pointer-events: none;
  }
  .advertiser-form {
    display: flex;
    flex-wrap: wrap;
  }
  .advertiser-form input,
  .advertiser-form div,
  .select-wrap,
  .select-wrap div,
  .advertiser-form > div > div {
    max-width: 400px !important;
    min-width: 300px !important;
    width: 400px !important;
    height: 40px !important;
    display: flex;
    align-items: center;
    max-height: 50px !important;
  }

  .action-btns {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .action-btns button {
    text-transform: none;
    font-weight: 500;
  }

  .advertiser-form label {
    top: -7px;
  }
  .navigatingLink {
    background-color: #1976d2;
    color: white;
    min-width: min-content;
    padding: 6px 16px;
    cursor: pointer;
    text-decoration: none;
    border-radius: 4px;
  }

  @media (max-width: 1226px) {
    .info-wrap {
      justify-content: space-between !important;
    }
  }
  @media (max-width: 485px) {
    .info-wrap {
      justify-content: center !important;
    }
    .rating-stars > img {
      width: 18px;
    }
  }
  .error-link {
    margin-top: 1rem;
    height: 2rem;
    font-weight: 500;
    text-transform: none;
  }
  @media (max-width: 768px) {
    .info-wrap {
      justify-content: space-between !important;
    }
    .rating-stars > img {
      width: 22px;
    }
  }
  .no-action {
    width: 20px;
    margin-left: 1rem;
  }
`;
