import React, { useState } from 'react'
import clear from "../../../../assets/Images/icons/svgIcon/Cleartask.svg"
import SearchableDropdown from '../../../../assets/SharedComponents/SearchableDropdown/SearchableDropdown';
import SearchableObjDropdown from '../../../../assets/SharedComponents/SearchableDropdown/SearchableObjDropdown';
import CountryFilter from '../../../../assets/SharedComponents/Filters/CountryFilter';

const FiltersNomad = ({nomads,taxRates,nomadName,_nomadName,commissionVAT,_commissionVAT,country,_country,startDate,_startDate,endDate,_endDate,setPage,_totalPage}) => {
  
  const day = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toJSON().slice(0, 10);

  const setChangeValue = (func,item) => {
    func(item);
    _totalPage(0)
    setPage(1);
  }

  const clearAll = () =>{
    _nomadName('')
    _commissionVAT('')
    _country('')
    _startDate('')
    _endDate('')
    if (nomadName !== "" || commissionVAT!=="" || country!=="" || startDate==="" || endDate==="") {
      _totalPage(0)
      setPage(1);
    }
  }

  return (
    <div>
      <div className='header position-relative mt-4 mb-5'>
        <span>FILTER</span>
        <div className='clearFilters d-flex flex-column align-items-center' onClick={clearAll}>
          <img src={clear} alt="clear"/>
          <span>Clear All</span>
        </div>
      </div>
      <div className='filtersInput mt-4'>
        <label htmlFor="nomadname" className='form-label'><b>Nomad Name</b></label>
        <SearchableObjDropdown name="nomads" className="form-control" id="nomads" val={nomadName} setChangeValue={(val)=>val?setChangeValue(_nomadName,val):null} placeholder="Select Nomad" dropdownData={nomads}/>
      </div>
      <div className='filtersInput mt-4'>
        <label htmlFor="country" className='form-label'><b>Country</b></label>
        <CountryFilter value={country} handleChange={(val) => setChangeValue(_country,val)}/>    
      </div>
      <div className='filtersInput mt-4'>
      <label htmlFor="invoicePlanDate" className='form-label'><b>Invoice Plan Date</b></label>
      <div className='d-flex filtersInputGroup justify-content-between'>
        <input type="text" className="filterBox date" id="start_date" placeholder="Start Date" 
          onFocus={()=>document.getElementById('start_date').type="date"} 
          onBlur={()=>startDate===''?document.getElementById('start_date').type="text":""}
          value={startDate}
          max={endDate?endDate:day}
          onChange={(e)=>_startDate(e.target.value)}/>
        <input type="text" className="filterBox date" id="end_date" placeholder="End Date" 
          onFocus={()=>document.getElementById('end_date').type="date"} 
          onBlur={()=>endDate===''?document.getElementById('end_date').type="text":""}
          value={endDate} 
          max={day}
          onChange={(e)=>_endDate(e.target.value)}/>
      </div>
      </div>
      <div className='filtersInput mt-4'>
      <label htmlFor="commissionVAT" className='form-label'><b>Commission VAT</b></label>
        <div className="tabBtn d-flex flex-wrap">
          <button className={commissionVAT === "" ? "activeTab" : ""} onClick={()=>_commissionVAT("")}>
            All
          </button>
          {Object.keys(taxRates).map((item,index)=>{
            return  <button key={item} className={commissionVAT === item ? "activeTab" : " "} onClick={()=>_commissionVAT(item)}>
            {taxRates[item]} %
          </button>
          })}
        </div>
      </div>

    </div>
  )
}

export default FiltersNomad