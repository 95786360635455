import React, { useEffect, useState } from "react";
import MainLayout from "../../../../assets/Layout/MainLayout";
import API from "../../../../api/axios";
import { APP_URLS } from "../../../../api/url";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CraeteVehicleWrapper } from "../../style/createVehicle";
import SearchableObjDropdown from "../../../../assets/SharedComponents/SearchableDropdown/SearchableObjDropdown";
import BrandFilter from "../../../../assets/SharedComponents/Filters/BrandFilter";
import ModelFilter from "../../../../assets/SharedComponents/Filters/ModelFilter";



const CreateModalsItems = () => {

  const [products, _products] = useState(JSON.parse(localStorage.getItem("productType")));
  const [productValue, _productValue] = useState(""); 
  const [name, _name] = useState('');
  const [brandValue, _brandValue] = useState('');
  const [modelValue, _modelValue] = useState('');

  const navigate = useNavigate() 
 

  const handleSubmit = () => {
    let data = {}
    data["name"] = name
    data["model_car_id"] = modelValue
    let flag = false
    if ((name === null || name === "") && (modelValue === null || modelValue === "")) {
      toast.error("Brand and Model name are mandatory", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else if ((modelValue === null || modelValue === "")) {
      toast.error("Please provide model name", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else if ((name === null || name === "")) {
      toast.error("Please provide model item name", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else {
      flag = true
    }
    if (flag === true) {
      API.post(APP_URLS.ADD_MODEL_ITEMS, data)
        .then(res => {
          const resp = res.data;
          if (resp.success === true) {
            toast.success("Model Item has been added successfully.", {
              position: toast.POSITION.TOP_RIGHT
            })
            resetForm()
            navigate("/vehicles-management/vehicle-model-items");
          }
          else {
            toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT
            })
          }
        })
        .catch(function (error) {
          const resp = error.response;
          let error_message = '';
          if (resp.data.data !== undefined && resp.data.data !== null) {
            {
              Object.keys(resp.data.data).map(
                (error, index) => (error_message = resp.data.data[error][0])
              );
            }
          } else if (resp.data.data?.error !== undefined) {
            error_message = resp.data.data.error;
          } else if (resp.data?.error !== undefined) {
            error_message = resp.data.error
          } else {
            error_message = resp.data.message
          }
          toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT
          })
        });
    }
  }

 
  const resetForm = () => {
    _name('')
    _brandValue('')
  }

  const setBrandValue = (val) => {
    _brandValue(val.split('_')[0])
  }

  const setModelValue = (val) => {
    _modelValue(val.split('_')[0])
  }



  return (
    <>
      <CraeteVehicleWrapper>
        <div className='mt-2' >
          <div className="backContainer ms-4" onClick={() => navigate("/vehicles-management/vehicle-model-items")}>
            <span className='backButton'>x</span>
            <span className='px-3 backButton'>|</span>
            <span className='backButton'>Cancel</span>
          </div>
        </div>
        <div className="headerContainer">
          <h1>CREATE MODEL ITEMS</h1>
        </div>
        <div className="row">
          <div className="d-flex justify-content-end save-btn mt-3">
            <button className="add-button" onClick={handleSubmit}>Save</button>
          </div>
          <div className="row input-fileds">
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-12 mt-3">
                  <label htmlFor="name" className="form-label fieldLabel">
                    Product Type
                  </label>
                  <select
                    name="productType"
                    id="productType"
                    className="form-control mt-2"
                    onChange={(e) => {
                      _productValue(e.target.value);
                      _brandValue('');
                      _modelValue('')
                    }}
                  >
                    <option hidden>Select Product Type</option>
                    {products.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {item.item_name.en}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-4">
                <div className="col-md-12 mt-3 position-relative">
                  <label htmlFor="productType" className="form-label fieldLabel product-type d-flex justify-content-between">
                    <span>Brand</span>
                  </label>
                  <BrandFilter   brand={brandValue} setBrand={(val)=>setBrandValue(val)} productValue={productValue} className={(productValue === ''?"disabled":"")+" form-control mt-3"} />   
                </div>
            </div>
            <div className="col-md-4">
                <div className="col-md-12 mt-3 position-relative">
                  <label htmlFor="productType" className="form-label fieldLabel product-type d-flex justify-content-between">
                    <span>Model</span>
                  </label>
                  <ModelFilter brandID={brandValue} modelID={modelValue} setChangeValue={(val)=>setModelValue(val)} productValue={productValue} className={(productValue === ''||brandValue===""?"disabled":"")+" form-control mt-3"}  />     
                </div>
            </div>
          </div>
          <div className="row input-fileds">
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-12 mt-3 position-relative">
                  <label htmlFor="name" className="form-label fieldLabel">
                    Model Item Name
                  </label>
                  <input type="text" id="name" name="name"
                    className="form-control mt-2"
                    placeholder="Model Item Name" onChange={(e) => _name(e.target.value)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </CraeteVehicleWrapper>
    </>
  );
};

export default CreateModalsItems;
