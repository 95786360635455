import styled from "styled-components";

export const DesktopPreviewWrapper = styled.div`
  .preview-desktop-container {
    border: 1px solid #979797;
    border-radius: 5px;
    margin-top: 10px;
    overflow-x: scroll;
    overflow-y: scroll;
    width: 100%;
    height: 800px;
  }

  .preview-desktop-area {
    max-width: 100%;
    min-width: 1280px;
    min-height: 800px;
  }
  .preview-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .ctblogo{
    height:40px;
  }
  .preview-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .header-box {
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    font-size: 18px;
  }
  .header-box p{
    font-size:10px;
    color: #c9c4c4;
  }
  .header-box span{
    color: #c9c4c4;
    line-height:10px;
  }
  .userFlag{
    left: 40px;
    bottom: -10px;
    height: 30px;
  }
  .uname{
    font-size:15px !important;
    color:black !important;
  }
  .uprofile{
    color:#384386 !important;
    font-size:12px !important;
  }
  .search-box {
    // border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    line-height: 30px;
    background: #f5f5f5;
  }
  .search-box input {
    background: #f5f5f5;
    border: none;
    min-width: 450px;
    font-size: 12px;
  }
  .border-right {
    height:100%;
  }
  .header-icon{
    height:18px;
  }
  .navigate-icon{
    height:22px;
  }
  .bell-icon{
    height:35px;
  }
  .product-container {
    display: flex;
    background-repeat: round;
    background-origin: border-box;
  }
  .product-card {
    max-width: 250px;
    width: 220px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 15px;
  }
  .product-card img {
    height: 80px;
    max-width: 150px;
  }
  .product-card span {
    letter-spacing: 2px;
    font-weight: 600;
  }
  .container-border {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 1px 6px 15px -1px rgba(0, 0, 0, 0.2);
  }
  .download-button {
    height: auto !important;
    cursor:pointer;
  }
  .download-title {
    letter-spacing: 0px !important;
    color: rgba(0, 0, 0, 0.2);
  }
  .shadow-box{
    background:#EAEAEA;
    width:100%;
    min-height:25px;
    height:auto;
    clip-path: ellipse(29% 6px at 52% 50%);
  }
  .product-card-title{
    background:#DCE1F4;
    width:100%;
  }
  .product-card-title span{
    text-align:center
  }
  .auctions-1{
    border:1px solid black;
    min-width:25px;
    min-height:5px;
    background:black;
  }
  .auctions-2{
    border:1px solid #6579F4;
    min-width:25px;
    min-height:5px;
    background:#6579F4;
  }  
  .auctions-3{
    border:1px solid #ffde00;
    min-width:25px;
    min-height:5px;
    background:#ffde00;
  }
  .auctions-container{

  }
  .auctions-container .head{
    font-weight:600;
    font-size:22px;
  }
  .tabs{
    border-radius:50px;
    background:#EAEAEA;
  }
  .tabs div{
    border-radius:50px;
    font-weight:600;
    min-width:130px;
    text-align:center;
    cursor:pointer;
  }
  .tabs .active{
    background:#617AF7;
    color:white;
    font-weight:700;
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 3px 9px #617af7
  }
  .carousel-navigator {
    cursor: pointer;
    height: 80px;
    width: 80px;
  }
  .carousel-images {
    width: 40%;
    height: auto;
  }
  .carousel-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }
  .carousel-box img {
    width: 48%;
  }
  .carousel-container {
    width: 100%;
  }
  .img-box {
    display: flex;
    flex-direction:column;
    position:relative;
    justify-content: center;
    align-items: center;
    max-height: 300px;
    width: auto;
  }
  .carousel-img {
    width: 95%;
    object-fit: cover;
    border-radius:15px;
  }
  .img-box span{
    color:white;
    position:absolute;
    font-size:40px;
    font-weight:800;
    right:35px;
    top:15px;
  }
  .auction-card{
    border-radius:30px;
    box-shadow: 1px 6px 15px -1px rgba(0, 0, 0, 0.2);

  }
  .progress-status{
    color:#fbc623;
  }
  .react-multiple-carousel__arrow--left {
    left: 2px !important;
  }
  .react-multiple-carousel__arrow--right {
    right: 2px !important;
  }
  .react-multiple-carousel__arrow {
    position: absolute;
    outline: 0;
    transition: all .5s;
    border-radius: 35px;
    z-index: 1000;
    border: 0;
    background: white;
    min-width: 43px;
    min-height: 43px;
    opacity: 1;
    cursor: pointer;
    box-shadow: 1px 6px 15px -1px rgba(0, 0, 0, 0.2);
  }
  .react-multiple-carousel__arrow::before {
    color: #000;
  }
  .card-img{
    max-width:80%;
  }
  .auction-status-bar{
    width:100%;
  }
  .auction-status{
    height:20px;
  }
  .flag{
    height:35px;
  }
  .auction-title{
   font-size:15px;
   font-weight:700; 
  }
  .card-content{
    width:100%
  }
  .card-content p{
    // font-size:12px;
  }
  .content-title{
    font-size:14px;
    font-weight:600;
    color: #B8B8B8;
  
  }
  .content-title span{
    color: #5D78FF;
  }
  .counter-head{
    font-size:15px;
    font-weight:700;
  }
  .count{
    min-width:70px;
  }
  .count span:nth-child(1) {
    color: #5D78FF;
    font-size:18px;
  }
  .count span:nth-child(2) {
    color: #B8B8B8;
    font-size:10px;
  }
  .timerContainer{
    font-size:13px;
  }
  .timer{
    min-width:30px;
  }
  .productCarConatiner{
    width:100%
  }
  .productCarCard{
    max-width:290px;
    border-radius:8px;
    box-shadow: 0px 10px 13px -8px rgb(0 0 0 / 10%);
  }
  .cardImage{
    width:100%
  }
  .specifications{
    background:#F5F5F5;
    border-radius:15px;
    height:auto;
  }
  .specifications span{
    font-size:12px
  }
  .carTitle{
    font-size:16px;
    font-weight:700;
  }
  .userDetails{
    background:#F5F5F5;
    width:100%;
  }
  .flagImage{
    top: 25px;
    left: 30px;
  }
  .userImage{
    width:20%;
  }
  .userName{
    width:80%;
  }
  .userName span:nth-child(1){
    font-weight:700;
    font-size:14px;

  }
  .userName span:nth-child(2){
    font-weight:600;
    font-size:11px;
  }
  .userName span:nth-child(3){
    font-weight:400;
    font-size:14px;
    text-align:center;
    color:#aaaaaa;
  }
  .priceValue{
    width:100%;
  }
  .pricing{
    width:80%;
  }
  .stars{
    height:15px;
    top: 30px;
    left: 70px;
  }
  .rating{
    width:20%;
  }
  .value{
    font-size:28px !important;
    font-weight:800 !important;
  }
  .starDiv{
    box-shadow: 1px 6px 15px -1px rgba(0, 0, 0, 0.2);
    border-radius:50%;
    min-height:50px;
    min-width:50px;
  }
  .paginationImage{
    height:60px;
  }
  .socialLinks img{
    height:20px;
  }
  .footer{
    background-color: #607afc;
    color: white;
  }
  .footer span{
    font-size:12px;
  }
`;
