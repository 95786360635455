import React, { useEffect, useState } from "react";
import { EditVehicleWrapper } from "../style/EditVehicle";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";

let name = {};

const EditForumBehaviorText = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [languages, _languages] = useState(() => JSON.parse(localStorage.getItem("languages")) || []);
    const [countries, _countries] = useState(() => JSON.parse(localStorage.getItem("countries")) || []);
    const [loading, _loading] = useState(false);
    const { textId } = useParams();
    const navigate = useNavigate();

    //Fetching details of suggestion texts based on their type.
    useEffect(() => {
        getSuggestionTypeDetails();
    
        languages.forEach(language => {
            name[language.code] = "";
        });
    }, []);
    
    //Adjusting the state for editing suggestion texts.
    const setValues = (suggestionTexts) => {
        Object.keys(suggestionTexts).map((suggestionName) => {
            let elem = document.getElementById(suggestionName)
            if (elem) {
                elem.value = suggestionTexts[suggestionName]
                name[suggestionName] = suggestionTexts[suggestionName];
            }
        });
    };

    //Retrieve details for suggestion types.
    const getSuggestionTypeDetails = () => {
        API.get(APP_URLS.SHOW_SUGGESTION_TEXT + `/${textId}`)
            .then((res) => {
                const resp = res.data;
                if (resp.success === true) {
                    setValues(resp.data.suggestionText.name);
                } else {
                    toast.error(resp.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch(function (error) {
                const resp = error.response;
                let error_message = "";
                if (resp?.data?.data !== undefined) {
                    {
                        Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
                    }
                } else if (resp?.data?.data?.error !== undefined) {
                    error_message = resp.data.data.error;
                } else if (resp?.data?.error !== undefined) {
                    error_message = resp?.data.error;
                } else {
                    error_message = resp?.data.message;
                }
                toast.error(error_message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });
    };

    //Manage title.
    const handleTitle = (val, code) => {
        name[code] = val;
    };

    //Manage parameters related to forum behavior.
    const handleForumBehaviourParams = () => {
        const searchParamsArray = [
            searchParams.get("name") ? `name=${searchParams.get("name")}` : '',
            searchParams.get("per_page") ? `per_page=${searchParams.get("per_page")}` : '',
            searchParams.get("page") ? `page=${searchParams.get("page")}` : '',
        ].filter(Boolean).join("&");
        return searchParamsArray
    }

    // Validate input fields
    const submitForm = () => {
        if (!name["en"] && !name["fr"]) {
            toast.error("Behavior Text Title in English and French is mandatory", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        if (!name["en"]) {
            toast.error("Please provide Behavior Text in English", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        if (!name["fr"]) {
            toast.error("Please provide Behavior Text in French", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        if (!loading) {
            _loading(true);
            API.post(`${APP_URLS.UPDATE_SUGGESTION_TEXT}/${textId}?_method=patch`, { name })
                .then((res) => {
                    _loading(false);
                    const resp = res.data;
                    if (resp.success) {
                        toast.success("Behavior Text Updated Successfully.", {
                            position: toast.POSITION.TOP_RIGHT,
                        });

                        const params = handleForumBehaviourParams();
                        navigate(`/translation/forum-behavior-text?${params}`);
                    } else {
                        toast.error(resp.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                })
                .catch((error) => {
                    _loading(false);
                    const resp = error.response;
                    const errorMessage = resp.data?.data?.[Object.keys(resp.data.data)[0]]?.[0]
                        || resp.data?.data?.error
                        || resp.data?.error
                        || resp.data?.message
                        || 'An error occurred';

                    toast.error(errorMessage, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                });
        }
    };

    return (
        <>
            <EditVehicleWrapper>
                <div className='mt-2' >
                    <div className="backContainer ms-4" onClick={() => navigate(`/translation/forum-behavior-text?${handleForumBehaviourParams()}`)}>
                        <span className='backButton'>x</span>
                        <span className='px-3 backButton'>|</span>
                        <span className='backButton'>Cancel</span>
                    </div>
                </div>
                <div className="headerContainer">
                    <h1>EDIT FORUM BEHAVIOR</h1>
                </div>
                <div className="d-flex justify-content-between save-btn mt-3">
                    <p className="fieldLabel">Title</p>
                    <button className={(loading ? "disabled" : "") + " add-button"} onClick={submitForm} disabled={loading}>Save</button>
                </div>
                <div className="row input-fileds">
                    {languages.map((language) => {
                        let languageCode = language.code;
                        let flag = countries.find((o) => o.id === language.country_id)?.flag_thumbnail;
                        return (
                            <div className="col-md-4" key={language.id}>
                                <div className="col-md-12 mt-3">
                                    <label htmlFor="title" className="form-label">
                                        <span className="countryIcon px-2">
                                            <img src={flag} alt="" className="countryflag" title={language.name} />
                                        </span>
                                        <span className="langName">{language.code}</span>
                                    </label>
                                    <input
                                        type="text"
                                        id={languageCode}
                                        name={languageCode}
                                        className="form-control departmentFields"
                                        onChange={(e, code = languageCode) => {
                                            handleTitle(e.target.value, code);
                                        }}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </EditVehicleWrapper>
        </>
    );
};

export default EditForumBehaviorText;
