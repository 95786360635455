import React, { useState } from "react";
import { SortableHandle } from "react-sortable-hoc";
import styled from "styled-components";
import error from "../../../../assets/Images/icons/alert-tri.svg";
import handler from "../../../../assets/Images/icons/faDotsVertical.svg";
import edit from "../../../../assets/Images/image/edit.png";
import { NavLink } from "react-router-dom";

const TrWrapper = styled.tr`
  cursor: default;

  .firstElement {
    display: flex;
    flex-direction: row;
  }

  &.helperContainerClass {
    width: auto;
    border: 1px solid #efefef;
    box-shadow: 0 9px 13px -2px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 3px;

    &:active {
      cursor: grabbing;
    }
  }

  &.helperContainerClass .tableHead-2{
    min-width:85px
  }
  &.helperContainerClass .tableHead-1 .draggable{
    position:relative;
    width:30px;
  }

  &.helperContainerClass .tableHead-2 .languageSelected{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 12px 0;
  }

  &.helperContainerClass .tableHead-2 .languageSelected img{
    width: 22px;
    height: 22px;
    border-radius: 50%;
  }

  &.helperContainerClass .tableHead-3{
    min-width: 150px;
  }

  &.helperContainerClass .tableHead-3 .nameTranslated{
    display: block;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    padding: 12px 0;
  }
`;

const Handle = styled.div`
  cursor: grab;
  position: absolute;
  left: -15px;
`;

const RowHandler = SortableHandle(() => <Handle className="handle">
    <img src={handler} />
    <img src={handler} className="ms-1"/>
</Handle>);

const TableRow = ({ upadtedBy, rowData }) => {

    const [countries, setcountries] = useState(JSON.parse(localStorage.getItem("countries")));
    const [languages, setlanguages] = useState(JSON.parse(localStorage.getItem("languages")));

    const [userAccess,_userAccess] = useState(JSON.parse(localStorage.getItem('permissions')))

    let translationAccess = userAccess.find(o=>o.slug ==="translation")

  return (
    <TrWrapper className="table-row">
        <td className="tableHead-1 d-flex justify-content-center">
            <div className="draggable">
                {translationAccess.permissions.find((o) => o.slug === "update") ? 
                  <RowHandler />
                :""}
                <span>
                    {rowData.id}
                </span>
            </div>
        </td>
        <td className="tableHead-2">
            {Object.keys(rowData.name).map((val, index) => {
            let country_id;
            let flag;
            let countryValues = languages.find((o) => o.code === val);
            if (countryValues) {
                country_id = countryValues.country_id;
                flag = countries.find((o) => o.id === country_id)?.flag_thumbnail;
            }
            return (
                <div key={index} className="languageSelected d-flex gap-2">
                {countryValues ? (
                    <>
                    <img src={flag} alt="" title={countryValues?.name}/>
                    <span className="flagName">{val}</span>
                    </>
                ) : (
                    ""
                )}
                </div>
            );
            })}
        </td>
        <td className="tableHead-3">
            {Object.keys(rowData.name).map((val, index) => {
            let countryValues = languages.find((o) => o.code === val);
            return rowData.name[val] !== null && rowData.name[val] !== "" ? (
                <span key={index} className="nameTranslated">
                {countryValues ? rowData.name[val] : ""}
                </span>
            ) : (
                <span key={index} className="nameTranslated">
                <img src={error} alt="" className="warningIcon" />
                </span>
            );
            })}
        </td>
        <td className="tableHead-3">
            {rowData.code}
        </td>
        <td className="tableHead-3">
            {rowData.order}
        </td>
        <td>
            {upadtedBy !== "" && upadtedBy !== undefined ? upadtedBy.charAt(0).toUpperCase() + upadtedBy.slice(1) : "-"}
        </td>
        {translationAccess.permissions.find((o) => o.slug === "update") ? (
          <td className="tableHead-9">
                  <NavLink to={`/translation/edit-continent/${rowData.id}`}>
                      <img src={edit} alt="" className="mx-2" role="button" title="Edit" />
                  </NavLink>
          </td>
            ) : (
            ""
        )}
    </TrWrapper>
  );
};

export default TableRow;
