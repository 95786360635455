import React from "react";
import MainLayout from "../../../assets/Layout/MainLayout";
import { ProspectDealersWrapper } from "./style/prospectDealers";
import search from "../../../assets/Images/icons/faSearchLite.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import copy from "../../../assets/Images/icons/faLink.svg";
import edit from "../../../assets/Images/icons/faEdit.svg";
import deleteIcon from "../../../assets/Images/icons/faDelete.svg";

const ProspectDealers = () => {
  return (
    <>
      <MainLayout>
        <ProspectDealersWrapper>
          <div>
            <div className="headerContainer">
              <div className="searchContainer">
                <input type="text" className="search" />
                <img src={search} alt="search" />
              </div>
              <h1>PROSPECT DEALERS</h1>
              <div className="actionContainer">
                <h5 className="d-flex gap-2 text-center">
                  <div>
                    <img src={deleteIcon} alt="" />
                    <br />
                    <span>Delete</span>
                  </div>
                  <br />
                  <span>
                    <img src={edit} alt="edit" />
                    <br />
                    <span>Edit</span>
                  </span>
                </h5>
              </div>
            </div>
            <div className="tableContainer">
              <table className="auctionTable">
                <thead className="tableHead">
                  <tr className="table-row">
                    <th id="dealer-table0">NAME</th>
                    <th id="dealer-table1">ADDRESS</th>
                    <th id="dealer-table2">
                      LAT
                      <br /> LONG
                    </th>
                    <th id="dealer-table3">VAT</th>
                    <th id="dealer-table4">PHONE</th>
                    <th id="dealer-table5">EMAIL</th>
                    <th id="dealer-table6">MANAGED BY</th>
                    <th id="dealer-table7">PRODUCT CATEGORY</th>
                    <th id="dealer-table8">CREATED BY</th>
                    <th id="dealer-table9">CREATED AT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="table-row">
                    <td id="dealer-table0">Mercedes</td>
                    <td id="dealer-table1">STREET, NUMBER ZIP LOCALITY</td>
                    <td id="dealer-table2">
                      <div className="prod-id">
                        <span>
                          12343223244323                          
                        </span>
                        <CopyToClipboard text="copy">
                            <img
                              src={copy}
                              alt=""
                              style={{
                                cursor: "pointer",
                                marginLeft: "10px",
                              }}
                              onClick={() => toast("Copied")}
                            />
                          </CopyToClipboard>
                      </div>
                      <div className="prod-id">
                        <span>
                          12343223244323                          
                        </span>
                        <CopyToClipboard text="copy">
                            <img
                              src={copy}
                              alt=""
                              style={{
                                cursor: "pointer",
                                marginLeft: "10px",
                              }}
                              onClick={() => toast("Copied")}
                            />
                          </CopyToClipboard>
                      </div>
                    </td>
                    <td id="dealer-table3">
                      <div className="prod-id">
                        <span className="me-1">BE</span>                                            
                        <span>
                          343223244323                        
                        </span>
                          <CopyToClipboard text="copy">
                            <img
                              src={copy}
                              alt=""
                              style={{
                                cursor: "pointer",
                                marginLeft: "10px",
                              }}
                              onClick={() => toast("Copied")}
                            />
                          </CopyToClipboard>
                        </div>
                    </td>
                    <td id="dealer-table4">+343223244323</td>
                    <td id="dealer-table5">
                      <div className="prod-id">
                        <span>
                          INFO@AZERT.CO                          
                        </span>
                          <CopyToClipboard text="copy">
                            <img
                              src={copy}
                              alt=""
                              style={{
                                cursor: "pointer",
                                marginLeft: "10px",
                              }}
                              onClick={() => toast("Copied")}
                            />
                          </CopyToClipboard>
                      </div>                      
                    </td>
                    <td id="dealer-table6">USER_1111</td>
                    <td id="dealer-table7">VEHICLE CAR</td>
                    <td id="dealer-table8">CARTOBIKE</td>
                    <td id="dealer-table9">12-05-22 15:31</td>
                  </tr>
                  <tr className="table-row">
                    <td id="dealer-table0">Mercedes</td>
                    <td id="dealer-table1">STREET, NUMBER ZIP LOCALITY</td>
                    <td id="dealer-table2">
                      <div className="prod-id">
                        <span>
                          12343223244323                          
                        </span>
                        <CopyToClipboard text="copy">
                            <img
                              src={copy}
                              alt=""
                              style={{
                                cursor: "pointer",
                                marginLeft: "10px",
                              }}
                              onClick={() => toast("Copied")}
                            />
                          </CopyToClipboard>
                      </div>
                      <div className="prod-id">
                        <span>
                          12343223244323                          
                        </span>
                        <CopyToClipboard text="copy">
                            <img
                              src={copy}
                              alt=""
                              style={{
                                cursor: "pointer",
                                marginLeft: "10px",
                              }}
                              onClick={() => toast("Copied")}
                            />
                          </CopyToClipboard>
                      </div>
                    </td>
                    <td id="dealer-table3">
                      <div className="prod-id">
                        <span className="me-1">BE</span>                                            
                        <span>
                          343223244323                        
                        </span>
                          <CopyToClipboard text="copy">
                            <img
                              src={copy}
                              alt=""
                              style={{
                                cursor: "pointer",
                                marginLeft: "10px",
                              }}
                              onClick={() => toast("Copied")}
                            />
                          </CopyToClipboard>
                        </div>
                    </td>
                    <td id="dealer-table4">+343223244323</td>
                    <td id="dealer-table5">
                      <div className="prod-id">
                        <span>
                          INFO@AZERT.CO                          
                        </span>
                          <CopyToClipboard text="copy">
                            <img
                              src={copy}
                              alt=""
                              style={{
                                cursor: "pointer",
                                marginLeft: "10px",
                              }}
                              onClick={() => toast("Copied")}
                            />
                          </CopyToClipboard>
                      </div>                      
                    </td>
                    <td id="dealer-table6">USER_1111</td>
                    <td id="dealer-table7">VEHICLE CAR</td>
                    <td id="dealer-table8">CARTOBIKE</td>
                    <td id="dealer-table9">12-05-22 15:31</td>
                  </tr>
                  <tr className="table-row">
                    <td id="dealer-table0">Mercedes</td>
                    <td id="dealer-table1">STREET, NUMBER ZIP LOCALITY</td>
                    <td id="dealer-table2">
                      <div className="prod-id">
                        <span>
                          12343223244323                          
                        </span>
                        <CopyToClipboard text="copy">
                            <img
                              src={copy}
                              alt=""
                              style={{
                                cursor: "pointer",
                                marginLeft: "10px",
                              }}
                              onClick={() => toast("Copied")}
                            />
                          </CopyToClipboard>
                      </div>
                      <div className="prod-id">
                        <span>
                          12343223244323                          
                        </span>
                        <CopyToClipboard text="copy">
                            <img
                              src={copy}
                              alt=""
                              style={{
                                cursor: "pointer",
                                marginLeft: "10px",
                              }}
                              onClick={() => toast("Copied")}
                            />
                          </CopyToClipboard>
                      </div>
                    </td>
                    <td id="dealer-table3">
                      <div className="prod-id">
                        <span className="me-1">BE</span>                                            
                        <span>
                          343223244323                        
                        </span>
                          <CopyToClipboard text="copy">
                            <img
                              src={copy}
                              alt=""
                              style={{
                                cursor: "pointer",
                                marginLeft: "10px",
                              }}
                              onClick={() => toast("Copied")}
                            />
                          </CopyToClipboard>
                        </div>
                    </td>
                    <td id="dealer-table4">+343223244323</td>
                    <td id="dealer-table5">
                      <div className="prod-id">
                        <span>
                          INFO@AZERT.CO                          
                        </span>
                          <CopyToClipboard text="copy">
                            <img
                              src={copy}
                              alt=""
                              style={{
                                cursor: "pointer",
                                marginLeft: "10px",
                              }}
                              onClick={() => toast("Copied")}
                            />
                          </CopyToClipboard>
                      </div>                      
                    </td>
                    <td id="dealer-table6">USER_1111</td>
                    <td id="dealer-table7">VEHICLE CAR</td>
                    <td id="dealer-table8">CARTOBIKE</td>
                    <td id="dealer-table9">12-05-22 15:31</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </ProspectDealersWrapper>
      </MainLayout>
    </>
  );
};

export default ProspectDealers;
