import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import copy from "../../../assets/Images/icons/faLink.svg";
import edit from "../../../assets/Images/icons/faEdit.svg";
import MainLayout from "../../../assets/Layout/MainLayout";
import back from "../../../assets/Images/icons/svgIcon/back-arrow.svg";
import { PlanDescriptionWrapper } from "./style/planDescription";
import FeaturesTable from "./Components/FeaturesTable";
import searchIcon from "../../../assets/Images/icons/faSearchLite.svg";
import PriceTable from "./Components/PriceTable";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { planType } from "../../../config/constant";

let featureArr = []
let data = []

const PlanDescription = () => {

  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const [plan,_plan]= useState()
  const [features,_features]= useState([])
  const [prices,_prices]= useState([])

  const [editfeature,_editfeature] = useState(false)
  const [editdetails,_editdetails] = useState(false)

  const [featureData,_featureData] = useState([])
  const [filtereddata,_filtereddata] = useState([])
  const [loaded,_loaded] = useState(false)

  const [loading,_loading] = useState(false)

  // Field Values
  const [name,_name] = useState('')
  const [nickname,_nickname] = useState('')
  const [description,_description] = useState('')
  const [status,_status] = useState(true)

  const [selectAll,_selectAll] = useState(false)

  const [feature,_feature] = useState('')

  const [reset,_reset] = useState(false)

  const [userAccess,_userAccess] = useState(JSON.parse(localStorage.getItem('permissions')))
  
  let planAccess = userAccess.find(o=>o.slug ==="plans")

  const { planId } =useParams()

  const navigate = useNavigate()


  useEffect(() => {
    getPlans()
  }, [])

  
  useEffect(()=>{
    if(featureData.length>0){
      for(let i=0; i < featureArr.length ; i++){
        filtereddata.find(o=>o.id===featureArr[i]).selected = true
      }
    }
    _reset(false)
    },[featureData,reset])

  useEffect(()=>{
    if(filtereddata.length>0){
      let flag = true
      for(let i = 0 ; i < filtereddata.length ; i++){
        if(filtereddata[i].selected===false){
          flag=false
          break
        }
      }
      if(flag===true){
        _selectAll(true)
      }else{
        _selectAll(false)
      }
    }
    },[filtereddata])  

  const setFeatureId = (val) =>{
    featureArr.length = 0
    for(let i = 0;i<val.length;i++){
      featureArr.push(val[i].id)
    }
  }

  const setFieldValues = (values) =>{
    _name(values.name)
    _description(values.description)
    _status(values.active)
    _nickname(values.prices[0].nickname)
  }
  
  const getPlans =() =>{
    API.get(APP_URLS.VIEW_PLAN + `/${planId}`)
    .then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        _plan(resp.data.item)
        setFieldValues(resp.data.item)
        _features(resp.data.item.features)
        _prices(resp.data.item.prices)
        setFeatureId(resp.data.item.features)
        getFrontOfficeFeatures()
      } else {
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    })
    .catch(function (error) {
      const resp = error.response;
      let error_message = "";
      if (resp.data.data !== undefined && resp.data.data !== null) {
        if(resp.data.data.message){
          error_message = resp.data.data.message
        }else{
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        }
      } else if (resp.data.data?.error !== undefined) {
        error_message = resp.data.data.error;
      } else if (resp.data?.error !== undefined) {
        error_message = resp.data.error;
      } else {
        error_message = resp.data.message;
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    });
  }

  const getFrontOfficeFeatures =() =>{
    API.get(APP_URLS.LIST_FEATURES + `?filter[type]=2&with_permissions=true`)
    .then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        const UpdateData = resp.data.items.map((item)=> {
          item['selected'] = false
          return item
        })
        data = JSON.stringify(UpdateData);
        _featureData(UpdateData);
        _filtereddata(UpdateData)
        _loaded(true)
      } else {
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    })
    .catch(function (error) {
      const resp = error.response;
      let error_message = "";
      if (resp.data.data !== undefined && resp.data.data !== null) {
        if(resp.data.data.message){
          error_message = resp.data.data.message
        }else{
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        }
      } else if (resp.data.data?.error !== undefined) {
        error_message = resp.data.data.error;
      } else if (resp.data?.error !== undefined) {
        error_message = resp.data.error;
      } else {
        error_message = resp.data.message;
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    });
  }

  const saveFeatures = () =>{
    let arr = []
    filtereddata.forEach((item)=>{
      if(item.selected===true){
        arr.push(item.id)
      }
    })
    if(arr.length>0){
      let data ={}
      data['feature_id'] = arr
      _loading(true)
      API.post(APP_URLS.ADD_FEATURES_TO_PLAN+`/${planId}/features?_method=PATCH`, data)
      .then((res) => {
        _loading(false)
        const resp = res.data;
        if (resp.success === true) {
          _editfeature(false)
          getPlans()
          toast.success("Features have been updated successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        _loading(false)
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          if(resp.data.data.message){
            error_message = resp.data.data.message
          }else{
            {
              Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
            }
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
    }
    else{
      toast.error("Please select some features for the plan first.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  const handlefeatureChange =(data) =>{
    if(feature.length>0){
      let newData = [...featureData]
      let val = newData.filter(newData=>newData.name['en'].includes(data))
      _filtereddata(val)
    }
    else{
      _filtereddata(featureData)
    }
  }

  const setChecked = (id) =>{
    let newdata = [...filtereddata]
    let value = newdata.find(o=>o.id===id)
    if(value.selected===false){
      value.selected=true
    }
    else{
      value.selected=false
    }
    _filtereddata(newdata)
  }

  const handleAllselected =()=>{
    if(!selectAll){
      filtereddata.forEach(item=>{
        item.selected=true
      })
    }
    else{
      filtereddata.forEach(item=>item.selected=false)
    }
  }

  const handleSave =()=>{
    let data ={}
    data['name'] = name
    data['description'] = description
    data['nickname'] = nickname
    data['active'] = status
    data['type'] = planType.RECURRING
    if(name===''){
      toast.error("Plan name can't be blank", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if(nickname===''){
      toast.error("Plan nickname can't be blank", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else{
      _loading(true)
      API.post(APP_URLS.UPDATE_PLAN+`/${planId}?_method=PATCH`, data)
      .then((res) => {
        _loading(false)
        const resp = res.data;
        if (resp.success === true) {
          toast.success("Plan details have been updated successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          _editdetails(false)
          getPlans()
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        _loading(false)
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          if(resp.data.data.message){
            error_message = resp.data.data.message
          }else{
            {
              Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
            }
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
    }
  }


  return (
    <>
      <PlanDescriptionWrapper>
        <div className="auctionMainContainer">
          <div className="row mt-4">
            <div className="col-md-4 d-flex align-items-center">
                <img src={back} className="ms-4 backbtn" onClick={()=>navigate("/plans?"+
                  (query.get("keyword") !== null
                    ? "keyword=" + query.get("keyword") + "&"
                    : "") +
                  (query.get("trashed") !== null
                    ? "trashed=" + query.get("trashed") + "&"
                    : "") +
                  (query.get("per_page") !== null 
                    ? "per_page=" + query.get("per_page") + "&"
                    : "") +
                  (query.get("page") !== null
                    ? "page=" + query.get("page")
                    : "")
                )}/>
            </div>
            <div className="col-md-4">
              <div className="heading">
                    <h1>PLAN</h1>
                    <h5 className="mt-2">
                      {plan?.stripe_product_id}
                      <CopyToClipboard text={plan?.stripe_product_id}>
                        <img src={copy} onClick={() => toast("Text Copied")}/>
                      </CopyToClipboard>
                    </h5>
              </div>
            </div>
          </div>                
          <div className="edit-btn">
                <h5>Details</h5>
                {planAccess?.permissions.find((o) => o.slug === "update") ?
                  plan?.deleted_at===""||plan?.deleted_at===null?
                    editdetails===false?
                    <button className="btn-link" onClick={()=>_editdetails(true)}><img src={edit} alt="" /> Edit </button>
                    :
                    <>
                    <div className="d-flex">
                      <button className={(loading?"disabled":"")+" accept"} onClick={handleSave} disabled={loading}>Save</button>
                      <button className={(loading?"disabled":"")+" reject ms-2"} onClick={()=>{
                        _editdetails(false)
                        setFieldValues(plan)
                        }}>Cancel</button>
                    </div>
                    </>
                  :''
                :''}

          </div>
          <hr className="hr-line" />

          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="details">
                  <div className="details-name">
                    <h5>Name</h5>
                    {editdetails?
                    <input type="text" className="form-control" value={name} onChange={(e)=>_name(e.target.value)}/>:
                    <h6>{plan?.name}</h6>}
                  </div>
                  <div className="details-name">
                    <h5>Description</h5>
                    {editdetails?
                    <textarea rows='4' className="form-control" value={description} onChange={(e)=>_description(e.target.value)}/>:
                    <h6>{plan?.description}</h6>}
                  </div>
                  <div className="details-name">
                    <h5>Status</h5>
                    {editdetails?
                      <select name="status" id="status" className="form-control" value={status} onChange={(e)=>_status(e.target.value)} >
                        <option value='1'>Active</option>
                        <option value='0'>Inactive</option>
                      </select>:
                    <h6>{plan?.active===1?'Active':'Inactive'}</h6>}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="details-name">
                  <h5>Nickname</h5>
                  {editdetails?
                  <input type="text" className="form-control" value={nickname} onChange={(e)=>_nickname(e.target.value)}/>:
                  <h6>{plan?.prices[0].nickname}</h6>}
                </div>
                <div className="details-name">
                    <h5>Created</h5>
                    <h6>{moment(plan?.updated_at).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm")}</h6>
                  </div>
              </div>
            </div>
          </div>

          <div className="tables">
            <div>
              <div className="features">
                <h5>Pricing</h5>
              </div>
              <PriceTable prices={prices}/>
            </div>
            <div>
              <div className="features">
                <h5>Features</h5>
                {
                  planAccess?.permissions.find((o) => o.slug === "update") ?
                    plan?.deleted_at===""||plan?.deleted_at===null?
                      editfeature===false?
                      <button className="btn-link" onClick={()=>_editfeature(true)}>+ Add Feature</button>
                      :
                      <>
                      <div className="d-flex">
                        <button className={(loading?"disabled":"")+" accept"} onClick={saveFeatures} disabled={loading}>Save</button>
                        <button className={(loading?"disabled":"")+" reject ms-2"}
                          onClick={()=>{
                            _editfeature(false)
                            _filtereddata(JSON.parse(data))
                            // setFeatureId(features)
                            _reset(true)
                          }}>Cancel</button>
                      </div>
                      </>
                    :''
                  :''
                }
              </div>
              {editfeature===false?
              <FeaturesTable features={features}/>
              :
              <div className="container">
                <div className="row">
                  <div className="col-md-6 offset-md-3">
                    <div className="d-flex justify-content-end">
                      <span>Select All <input type="checkbox" className="ms-3" 
                        checked={selectAll} 
                        onChange={()=>{
                          _selectAll(!selectAll)
                          handleAllselected()
                        }}/></span>           
                    </div>
                    <div className="featureSearch d-flex flex-column align-items-center">
                      <input type="text" onChange={(e)=>{
                        _feature(e.target.value)
                        handlefeatureChange(e.target.value);
                      }} 
                        placeholder="Search Feature..."
                        onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          handlefeatureChange(feature);
                          e.preventDefault()
                        }
                      }}/>
                      <img src={searchIcon} alt="" className="searchIcon" onClick={()=>handlefeatureChange(feature)}/>
                    </div>
                    <div className="featureContainer">
                      {loaded?
                        filtereddata.length>0?
                          filtereddata.map((item,index)=>{
                            return <div className="featureName" key={index} title={item.description['en']}>
                            <p className="ms-4">{(item.name['en'].charAt(0).toUpperCase()+item.name['en'].slice(1)).replace("_"," ")}</p>
                            <input className="" type="checkbox" checked={item.selected} 
                              onChange={()=>{
                                setChecked(item.id)
                              }}/>
                          </div>
                          }):
                          <div className="featureName" >
                            <p className="ms-4 norecord">No record found..</p>
                          </div>
                        :<div className="featureName" >
                          <p className="ms-4 loader">Loading..</p>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div> 
              }
            </div>
          </div>
        </div>
      </PlanDescriptionWrapper>
    </>
  );
};

export default PlanDescription;
