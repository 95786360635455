import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import API from "../../api/axios";
import { APP_URLS } from "../../api/url";
import { toast } from "react-toastify";
import RecordCount from "../../assets/SharedComponents/RecordCount/RecordCount";
import { Tooltip } from "@mui/material";
import { FaFilterIcon, FaUpArrow } from "../../assets/Images/icon";
import waste from "../../assets/Images/image/Waste.png";
import noActionImage from "../../assets/Images/image/noAction.png";
import { ThreeDots } from "react-loader-spinner";
import NoRecord from "../../assets/SharedComponents/Table/NoRecord";
import Pagination from "../../assets/SharedComponents/Pagination";
import { confirmAlert } from "react-confirm-alert";
import getUniqueObjects from "../../core/utils/getUniqueObjects.js/getUniqueObjects";
import moment from "moment";
import { DEBOUNCE_TIME, forumFlagModelType, forumFlagModelTypeOptions } from "../../config/constant";
import { redirect } from "../../core/utils/redirect/redirect";
import StaticFilter from "../../assets/SharedComponents/Filters/StaticFilter";
import faHide from "../../assets/Images/icons/svgIcon/FaHide.svg"
import UserFilter from "../../assets/SharedComponents/Filters/UserFilter";
import { getOverflowState } from "../../core/utils";
import arrowUp from "../../assets/Images/icons/faChevronUp.svg";
import DropdownActions from "./DropdownActions";
import { ForumFlaggedWrapper } from "./style/ForumFlagWrapper";

const modelTypeList = Object.keys(forumFlagModelTypeOptions).map((key) => ({ name: key, value: forumFlagModelTypeOptions[key] }))

const ForumFlagComments = () => {
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchkeyword, _searchkeyword] = useState("");
    const [countValue, _countValue] = useState("");
    const [page, setPage] = useState(1);
    const [flaggedBy, setFlaggedBy] = useState(null);
    const [loaded, _loaded] = useState(false);
    const [total, _total] = useState(0);
    const [totalPage, _totalPage] = useState(0);
    const [data, _data] = useState([]);
    const [noAction, _noAction] = useState(false);
    const [action, _action] = useState(false);
    const [filters, _filters] = useState(false);
    const [flaggedSearch, seFlaggedSearch] = useState(null);
    const [modelType, _modelType] = useState('');
    const [show, _show] = useState('');
    const [showReason, _showReason] = useState('')
    const [showQuestion, _showQuestion] = useState('');
    const [trashed, _trashed] = useState(searchParams.get('trashed') !== null ? (searchParams.get('trashed') === 'false' ? false : true) : true);
    const [overflowStates, setOverflowStates] = useState({});
    const refs = useRef({});
    const [selectedId, setSelectedId] = useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [trigger, setTrigger] = useState(false);
    const [flaggedByList, setFlaggedByList] = useState({
        data: [],
        loading: false,
        error: "",
        totalPage: 1,
        currentPage: 1,
    });

    const triggerUnflagOrDelete = () => setTrigger(prev => !prev);

    const searchParamsValues = useMemo(() => {
        const searchkeyword = searchParams.get("a_keyword") || "";
        const countValue = searchParams.get("a_per_page") || "";
        const page =
            searchParams.get("a_page") !== null
                ? parseInt(searchParams.get("a_page"))
                : 1;
        const flaggedBy = searchParams.get("flaggedBy") || null;
        return {
            searchkeyword,
            countValue,
            page,
            flaggedBy,
        };
    }, [searchParams]);

    useEffect(() => {
        _searchkeyword(searchParamsValues.searchkeyword);
        _countValue(searchParamsValues.countValue);
        setPage(searchParamsValues.page);
        setFlaggedBy(searchParamsValues.flaggedBy);
    }, [searchParamsValues]);


    useEffect(() => {
        getForumFlaggedList();
    }, [page, countValue, flaggedBy, noAction, modelType, trashed, trigger]);

    useEffect(() => {
        const getData = setTimeout(() => {
            if (flaggedSearch !== null)
                getFalggedByDropdownList(true);
        }, DEBOUNCE_TIME);
        return () => clearTimeout(getData);
    }, [flaggedSearch, flaggedByList?.currentPage]);

    //
    const getForumFlaggedList = () => {
        _loaded(true);
        let urlParams = {
            'page': page,
            'per_page': countValue,
            'filter[user_id]': flaggedBy || '',
            'filter[model_type]': modelType,
            'with_trashed': trashed === true ? 1 : 0
        };
        let restUrl = new URLSearchParams(urlParams);
        API.get(
            APP_URLS.FORUM_FLAGS + `?${restUrl}`
        )
            .then((res) => {
                const resp = res.data;
                if (resp.success) {
                    _data(resp.data.data);
                    _totalPage(resp.data.last_page);
                    _total(resp.data.total);
                    _loaded(false);
                } else {
                    toast.error(resp.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    _loaded(false);
                }
            })
            .catch(function (error) {
                _loaded(false);
                const resp = error.response;
                let error_message = "";
                if (resp.data.errors !== undefined) {
                    {
                        Object.keys(resp.data.errors).map(
                            (error, index) => (error_message = resp.data.errors[error][0])
                        );
                    }
                } else if (resp.data.data?.error !== undefined) {
                    error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                    error_message = resp.data.error;
                } else {
                    error_message = resp.data.message || 'An Unknown Error occured';
                }
                toast.error(error_message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });
    };

    // get list of reporters (Name of the persons or subsidiaries who has flagged a review.)
    const getFalggedByDropdownList = (isSearching = false) => {
        setFlaggedByList({ ...flaggedByList, loading: true });
        API.get(APP_URLS.LIST_USERS + `/list/all?keyword=${flaggedSearch || ''}`)
            .then((res) => {
                const resp = res.data;
                if (resp.success === true) {
                    const data = resp.data.items;
                    let newData = data;
                    if (!isSearching)
                        newData = getUniqueObjects([...flaggedByList.data, ...data]);
                    setFlaggedByList({
                        ...flaggedByList,
                        data: newData,
                        currentPage: resp.data.current_page,
                        error: "",
                        totalPage: resp.data.last_page,
                        loading: false,
                    });
                } else {
                    setFlaggedByList({
                        ...flaggedByList,
                        loading: false,
                        error: resp.message,
                    });
                    toast.error(resp.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch(function (error) {
                const resp = error.response;
                let error_message = "";
                if (resp.data.errors !== undefined) {
                    {
                        Object.keys(resp.data.errors).map(
                            (error, index) => (error_message = resp.data.errors[error][0])
                        );
                    }
                } else if (resp.data.data?.error !== undefined) {
                    error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                    error_message = resp.data.error;
                } else {
                    error_message = resp.data.message;
                }
                setFlaggedByList({
                    ...flaggedByList,
                    loading: false,
                    error: error_message,
                });
                toast.error(error_message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });
    };

    // handle search and per page data changes
    const handleSearchChange = (func, searchValue) => {
        func(searchValue);
        if (
            (func === _countValue && searchValue !== countValue) ||
            (func === _searchkeyword && searchValue !== searchkeyword)
        ) {
            if (searchValue !== "") {
                _totalPage(0);
                setPage(1);
            }
        }
    };

    //remove filters
    const clearFilter = () => {
        _filters(true);
        _trashed(true);
        setFlaggedBy(() => { });
        seFlaggedSearch("");
        _modelType("");
        if (countValue !== "" || flaggedBy !== "" || flaggedBy !== "" || trashed !== true) {
            _totalPage(0);
            setPage(1);
        }
        for (var value of query.keys()) {
            const param = searchParams.get(value);
            if (param) {
                searchParams.delete(value);
                setSearchParams(searchParams);
            }
        }
    };

    const handleUnFlag = (id, modelType) => {
        setSelectedId("");
        confirmAlert({
            title: "Confirm to Unflag",
            message: `Are you sure you want to unflag?`,
            closeOnEscape: true,
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        API.delete(APP_URLS.FORUM_FLAGS + `/${id}`)
                            .then((res) => {
                                const resp = res.data;
                                if (resp.success === true) {
                                    triggerUnflagOrDelete();
                                    toast.success(
                                        `Flagged ${modelType == forumFlagModelType.QUESTION ? "question" : "answer"} has been unflagged successfully.`,
                                        {
                                            position: toast.POSITION.TOP_RIGHT,
                                        }
                                    );
                                } else {
                                    toast.error(resp.message, {
                                        position: toast.POSITION.TOP_RIGHT,
                                    });
                                }
                            })
                            .catch(function (error) {
                                const resp = error.response;
                                let error_message = "";
                                if (resp.data.data !== undefined && resp.data.data !== null) {
                                    {
                                        Object.keys(resp.data.data).map(
                                            (error, index) =>
                                                (error_message = resp.data.data[error][0])
                                        );
                                    }
                                } else if (resp.data.data?.error !== undefined) {
                                    error_message = resp.data.data.error;
                                } else if (resp.data?.error !== undefined) {
                                    error_message = resp.data.error;
                                } else {
                                    error_message = resp.data.message;
                                }
                                toast.error(error_message, {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                            });
                    },
                },
                {
                    label: "No",
                },
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            overlayClassName: "overlay-custom-class-name",
        });
    };

    const handleDeleteQuestion = (id) => {
        setSelectedId("");
        confirmAlert({
            title: "Confirm to Delete",
            message: `Are you sure you want to delete?`,
            closeOnEscape: true,
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        API.delete(APP_URLS.FORUM_FLAGS_QUESTIONS + `/${id}`)
                            .then((res) => {
                                const resp = res.data;
                                if (resp.success === true) {
                                    triggerUnflagOrDelete();
                                    toast.success(
                                        "Flagged question has been deleted successfully.",
                                        {
                                            position: toast.POSITION.TOP_RIGHT,
                                        }
                                    );
                                } else {
                                    toast.error(resp.message, {
                                        position: toast.POSITION.TOP_RIGHT,
                                    });
                                }
                            })
                            .catch(function (error) {
                                const resp = error.response;
                                let error_message = "";
                                if (resp.data.data !== undefined && resp.data.data !== null) {
                                    {
                                        Object.keys(resp.data.data).map(
                                            (error, index) =>
                                                (error_message = resp.data.data[error][0])
                                        );
                                    }
                                } else if (resp.data.data?.error !== undefined) {
                                    error_message = resp.data.data.error;
                                } else if (resp.data?.error !== undefined) {
                                    error_message = resp.data.error;
                                } else {
                                    error_message = resp.data.message;
                                }
                                toast.error(error_message, {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                            });
                    },
                },
                {
                    label: "No",
                },
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            overlayClassName: "overlay-custom-class-name",
        });
    };

    //handle delete answer api
    const handleDeleteAnswer = (id) => {
        setSelectedId("");
        confirmAlert({
            title: "Confirm to Delete",
            message: `Are you sure you want to delete?`,
            closeOnEscape: true,
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        API.delete(APP_URLS.FORUM_FLAGS_ANSWERS + `/${id}`)
                            .then((res) => {
                                const resp = res.data;
                                if (resp.success === true) {
                                    triggerUnflagOrDelete();
                                    toast.success(
                                        "Flagged answer has been deleted successfully.",
                                        {
                                            position: toast.POSITION.TOP_RIGHT,
                                        }
                                    );
                                } else {
                                    toast.error(resp.message, {
                                        position: toast.POSITION.TOP_RIGHT,
                                    });
                                }
                            })
                            .catch(function (error) {
                                const resp = error.response;
                                let error_message = "";
                                if (resp.data.data !== undefined && resp.data.data !== null) {
                                    {
                                        Object.keys(resp.data.data).map(
                                            (error, index) =>
                                                (error_message = resp.data.data[error][0])
                                        );
                                    }
                                } else if (resp.data.data?.error !== undefined) {
                                    error_message = resp.data.data.error;
                                } else if (resp.data?.error !== undefined) {
                                    error_message = resp.data.error;
                                } else {
                                    error_message = resp.data.message;
                                }
                                toast.error(error_message, {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                            });
                    },
                },
                {
                    label: "No",
                },
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            overlayClassName: "overlay-custom-class-name",
        });
    };

    const handleNoAction = (id) => {
        confirmAlert({
            title: "Confirm to take no action",
            message: `Are you sure you want to take no action?`,
            closeOnEscape: true,
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        API.post(
                            APP_URLS.Delete_Flagged_REVIEW + `/${id}/no_action?_method=patch`
                        )
                            .then((res) => {
                                const resp = res.data;
                                if (resp.success === true) {
                                    _noAction(true);
                                    toast.success("Reviewed successfully.", {
                                        position: toast.POSITION.TOP_RIGHT,
                                    });
                                } else {
                                    toast.error(resp.message, {
                                        position: toast.POSITION.TOP_RIGHT,
                                    });
                                }
                            })
                            .catch(function (error) {
                                const resp = error.response;
                                let error_message = "";
                                if (resp.data.data !== undefined && resp.data.data !== null) {
                                    {
                                        Object.keys(resp.data.data).map(
                                            (error, index) =>
                                                (error_message = resp.data.data[error][0])
                                        );
                                    }
                                } else if (resp.data.data?.error !== undefined) {
                                    error_message = resp.data.data.error;
                                } else if (resp.data?.error !== undefined) {
                                    error_message = resp.data.error;
                                } else {
                                    error_message = resp.data.message;
                                }
                                toast.error(error_message, {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                            });
                    },
                },
                {
                    label: "No",
                },
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            overlayClassName: "overlay-custom-class-name",
        });
    };

    //for showing answer
    const handleShowAnswer = (id) => {
        if (show) {
            _showReason('');
            _showQuestion('');
            _show(id === show ? '' : id);
        } else {
            _showReason('');
            _showQuestion('');
            _show(id);
        }
    }

    //for showing flag reason
    const handleShowFlagReason = (id) => {
        if (showReason) {
            _show('');
            _showQuestion('');
            _showReason(id === showReason ? '' : id);
        } else {
            _show('');
            _showQuestion('');
            _showReason(id);

        }
    }

    //for showing question
    const handleShowQuestion = (id) => {
        if (showQuestion) {
            _showReason('');
            _show('');
            _showQuestion(id === showQuestion ? '' : id);
        } else {
            _showReason('');
            _show('');
            _showQuestion(id);
        }
    }

    //Actions for unflagg and delete question
    const handleActions = (event, ID) => {
        if (selectedId === ID) {
            // Close the currently open dropdown
            setAnchorEl(null);
            setSelectedId(null);
        } else {
            // Open the clicked dropdown
            setAnchorEl(event.currentTarget);
            setSelectedId(ID);
        }
    }

    //getting total overflow and ellipses status after the data is rendered.
    useEffect(() => {
        const totalEllipsisData = getOverflowState(refs.current)
        setOverflowStates(totalEllipsisData);
    }, [data]);

    //set user id state and empties the sub id
    const setUserValue = (updateState, item) => {
        updateState(item);
        _totalPage(0)
        setPage(1);
    }

    //handles action popup
    const handleClose = () => {
        setSelectedId("");
        setAnchorEl(null);
    };

    return (
        <ForumFlaggedWrapper>
            <div className="auctionMainContainer">
                <div className="row mt-4">
                    <div className="col-md-4 offset-md-4 mb-2">
                        <h1>FLAGGED FORUMS</h1>
                    </div>
                </div>
                <div className="justify-content-end mb-2 d-flex gap-2 mb-2 select-info align-items-center">
                    <UserFilter _filters={_filters} filters={filters} setUser={(val) => setUserValue(setFlaggedBy, val)} user={flaggedBy} searchkeyword={flaggedSearch} _searchkeyword={seFlaggedSearch} />
                    <StaticFilter placeholder='Model Type' data={modelTypeList} handleChange={(e) => {
                        _modelType(e.target.value)
                        _totalPage(0)
                        setPage(1);
                    }} value={modelType} />
                    <div className="select-wrapper">
                        <RecordCount
                            onSearchClick={(search) => handleSearchChange(_countValue, search)}
                            id="pageCount"
                            val={countValue}
                            filters={filters}
                            _filters={_filters}
                        />
                    </div>
                    <Tooltip title="Clear Filter" placement="bottom">
                        <FaFilterIcon
                            className="filter-icon"
                            role="button"
                            onClick={clearFilter}
                        />
                    </Tooltip>
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-between info-wrap">
                <div className="justify-content-end mt-2 me-2">
                    <span className="text-start">
                        Total Records : <b>{total}</b>
                    </span>
                </div>
                <div className="mt-1">
                    <input type="checkbox" checked={!trashed} onChange={() => {
                        _totalPage(0)
                        setPage(1);
                        _trashed(!trashed)
                    }} />
                    <span className="ms-2">Hide unflagged data</span>
                </div>
            </div>
            <div className="tableContainer">
                <table className="auctionTable reviewTable">
                    <thead className="tableHead">
                        <tr className="table-row">
                            <th className="time-start"> Flagged By </th>
                            <th className="longText">Question</th>
                            <th className="longText">Answer</th>
                            <th className="longText">Flag Reason</th>
                            <th className="time-start">
                                Flagged At
                            </th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    {loaded ? (
                        <div className="d-flex justify-content-center three-dot">
                            <ThreeDots
                                height="150"
                                width="150"
                                radius="9"
                                color="#637df4"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        </div>
                    ) : (
                        <tbody>
                            {data.length > 0 ? (
                                data.map((flaggedBy) => {
                                    const isDeleted = flaggedBy?.deleted_at
                                    const isNoActionDone = flaggedBy?.deleted_at && (!flaggedBy.ratings?.deleted_at);
                                    const questionTitle = (flaggedBy.model_type == forumFlagModelType.QUESTION ? flaggedBy.flagged?.title : flaggedBy.flagged?.question?.title)
                                    const questionSlug = (flaggedBy.model_type == forumFlagModelType.QUESTION ? flaggedBy.flagged?.title_slug : flaggedBy.flagged?.question?.title_slug)
                                    const answerKey = (flaggedBy.model_type == forumFlagModelType.ANSWER ? flaggedBy.flagged?.description : flaggedBy.flagged?.question?.title)
                                    const answerKeyWithoutTags = answerKey?.replace(/<\/?[^>]+(>|$)/g, "");
                                    const questionId = (flaggedBy.model_type == forumFlagModelType.QUESTION) ? flaggedBy?.flagged?.id : ((flaggedBy.model_type == forumFlagModelType.ANSWER) ? flaggedBy?.flagged?.question_id : '')
                                    const answerId = (flaggedBy.model_type == forumFlagModelType.ANSWER) ? flaggedBy?.flagged?.id : ''
                                    const reason = flaggedBy?.reason
                                    return (
                                        <>
                                            <tr
                                                className={
                                                    // (isDeleted ? "deletedRow " : isNoActionDone ? 'no-action-row ' : '') + "table-row"
                                                    (isDeleted ? "deletedRow " : '') + "table-row"
                                                }
                                                key={flaggedBy.id}
                                            >
                                                <td
                                                    className="link"
                                                    onClick={() => {
                                                        redirect(flaggedBy);
                                                    }}
                                                >
                                                    <span title={flaggedBy.user_name}>
                                                        <b>
                                                            {flaggedBy.user_name || '-'}
                                                        </b>
                                                    </span>
                                                </td>
                                                <td className="longText">
                                                    {/* {questionTitle ? (
                                                        <a href={`${process.env.REACT_APP_CTB_FORUM_URL}/fr/questions/${questionId}/${questionSlug}`} target="_blank" className="link">
                                                            {questionTitle}
                                                        </a>
                                                    ) : (
                                                        '-'
                                                    )} */}

                                                    {questionTitle ?
                                                        <div className="d-flex flex-row align-items-center justify-content-center">
                                                            <a href={`${process.env.REACT_APP_CTB_FORUM_URL}/fr/questions/${questionId}/${questionSlug}`} target="_blank" className="link message-text"
                                                                ref={(el) => (refs.current[`question-${flaggedBy.id}`] = el)}
                                                                title={questionTitle}>{questionTitle}
                                                            </a>
                                                            {overflowStates[`question-${flaggedBy.id}`] ? <img src={faHide} alt="show" className={(showQuestion == flaggedBy?.id ? "close" : "open") + " dropbtn ms-2"} onClick={() => handleShowQuestion(flaggedBy?.id)} /> : null}
                                                        </div>
                                                        : "-"}
                                                </td>
                                                <td className="longText">
                                                    {answerKeyWithoutTags ?
                                                        <div className="d-flex flex-row align-items-center justify-content-center">
                                                            <span
                                                                className="message-text"
                                                                ref={(el) => (refs.current[`answer-${flaggedBy.id}`] = el)}
                                                                title={answerKeyWithoutTags}>{answerKeyWithoutTags}
                                                            </span>
                                                            {overflowStates[`answer-${flaggedBy.id}`] ? <img src={faHide} alt="show" className={(show == flaggedBy?.id ? "close" : "open") + " dropbtn ms-2"} onClick={() => handleShowAnswer(flaggedBy?.id)} /> : null}
                                                        </div>
                                                        : "-"}
                                                </td>
                                                <td className="longText">
                                                    {reason ?
                                                        <div className="d-flex flex-row align-items-center justify-content-center">
                                                            <span className="message-text" ref={(el) => (refs.current[`reason-${flaggedBy.id}`] = el)} title={reason}>{reason || '-'}</span>
                                                            {overflowStates[`reason-${flaggedBy.id}`] ? <img src={faHide} alt="show" className={(showReason == flaggedBy?.id ? "close" : "open") + " dropbtn ms-2"} onClick={() => handleShowFlagReason(flaggedBy?.id)} /> : null}
                                                        </div>
                                                        : "-"}
                                                </td>
                                                <td><span className="block">{flaggedBy?.created_at ? moment(flaggedBy?.created_at).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm") : '-'}</span></td>
                                                <td className="d-flex justify-content-center align-items-center flex-column p-1" style={{ position: 'relative' }}>
                                                    {!isDeleted ? <button aria-describedby={id} className="actionBtn" onClick={(e) => handleActions(e, flaggedBy.id)}>
                                                        Action
                                                        <img src={arrowUp} alt="" className={((flaggedBy.id === selectedId) ? "closeA" : "openA") + " ms-2"} />
                                                    </button> : null}
                                                    <div className="actionPopup">
                                                        <DropdownActions
                                                            id={flaggedBy.id}
                                                            open={selectedId === flaggedBy.id}
                                                            anchorEl={anchorEl}
                                                            onUnFlag={() => handleUnFlag(flaggedBy.id, flaggedBy.model_type)}
                                                            onDeleteQuestion={() => handleDeleteQuestion(questionId)}
                                                            onDeleteAnswer={() => handleDeleteAnswer(answerId)}
                                                            handleClose={handleClose}
                                                            questionTitle={questionTitle}
                                                            answerKeyWithoutTags={answerKeyWithoutTags}
                                                            modelType={flaggedBy.model_type}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            {show === flaggedBy.id ?
                                                <tr className="table-row">
                                                    <td className='dropped'>
                                                        {answerKeyWithoutTags}
                                                    </td>
                                                </tr>
                                                : ""}
                                            {showReason === flaggedBy.id ?
                                                <tr className="table-row">
                                                    <td className='dropped'>
                                                        {reason}
                                                    </td>
                                                </tr>
                                                : ""}
                                            {showQuestion === flaggedBy.id ?
                                                <tr className="table-row">
                                                    <td className='dropped'>
                                                        {questionTitle}
                                                    </td>
                                                </tr>
                                                : ""}
                                        </>
                                    );
                                })
                            ) : (
                                <NoRecord />
                            )}
                        </tbody>
                    )}
                </table>
            </div>
            <div>
                {totalPage > 1 ? (
                    <Pagination
                        totalPages={totalPage}
                        page={page}
                        key={totalPage}
                        onPageClick={(page) => setPage(page + 1)}
                    />
                ) : (
                    ""
                )}
            </div>
        </ForumFlaggedWrapper>
    );
};

export default ForumFlagComments;
