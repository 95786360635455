import React, { useEffect, useRef, useState } from "react";
import { AddSlideWrapper } from "../../Slides/style/AddSlide";
import plus from "../../../../assets/Images/icons/svgIcon/plus-icon.svg";
import waste from "../../../../assets/Images/image/Waste.png";
import "react-image-crop/dist/ReactCrop.css";
import Popup from "../../Slides/components/Popup"
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import moment from "moment";
import API from "../../../../api/axios";
import { APP_URLS } from "../../../../api/url";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import { calctime } from "../../../../core/utils/getAmsterdamTime/getTime";
import 'moment/locale/en-gb'
import CropperBox from "../../../../assets/SharedComponents/ImageCropper";
import { getAmstoBrowserTime } from "../../../../core/utils/getAmstoBrowsertime/getAmstoBrowserTime";
import { ADD_SLIDER_TYPE } from "../../../../config/constant";
 

const AddSlide = (props) => {

  const [languages, _languages] = useState(JSON.parse(localStorage.getItem("languages")));
  const [countries, _countries] = useState(JSON.parse(localStorage.getItem("countries")));

  const [loading, setLoading] = useState(false);

  const [fileUploaded, _fileUploaded] = useState(false); 
  
  const [popup, _popup] = useState(false)
  const [countryArr, _countryArr] = useState([])
  
  const [linkToggle,_linkToggle] = useState(false)
  const [endDateToggle,_endDateToggle] = useState(false)
  
  //Image crop
  const [cropperPopup,_cropperPopup] = useState('');
  const [imageValue, _imageValue] = useState("");
  const [imgSrc, _imgSrc] =useState('') 

  const today = new Date(getAmstoBrowserTime());
  const yesterday = new Date().setDate(new Date().getDate() - 1); 
  
  const handleClick = () => {
    props._addtab(false)
  }
  
  const removeType = (id) => {
    _countryArr((item) => { return item.filter((arrElem) => { return arrElem !== id }) })
  }
  
  function onSelectFile(e) {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        _imgSrc(reader.result);
      };
      reader.readAsDataURL(files[0]);
      _cropperPopup(true);
    }
  
    // Reset the file input value to allow selecting the same file again
    e.target.value = null;
  }
  
  const resetFile = () => {
    _fileUploaded(false);
    _imageValue("")
    let image = document.getElementById("image");
    image.src = "";
  };

  const formik = useFormik({
    initialValues: { 
      startDate: moment(calctime(new Date().toDateString(), `+${moment().tz('Europe/Amsterdam')._offset/60}`)).add(1, 'h').toDate(),
      endDateCheck: false,
      endDate: "",
      linkCheck: false,
      link: "",
      file: ""
    },
    validationSchema: Yup.object({ 
      link: Yup.string().when('linkCheck', {
        is: true,
        then: Yup.string().required("Redirect Link is required")
      }),
      startDate: Yup.date()
        .required("Start Date and Time is Required")
        .min(today, "Date/Time cannot be in the past").when('endDateCheck', {
          is: true,
          then: Yup.date().max(Yup.ref("endDate"), "Start Date should be smaller than end date")
        }),
      endDate: Yup.date().when('endDateCheck', {
        is: true,
        then: Yup.date().required("End Date and Time is Required").min(today, "Date/Time cannot be in the past")
      }),
      file: "",
    }),
    onSubmit: (values) => {
      let data = {} 
      data['type'] = ADD_SLIDER_TYPE.HOME
      data['image'] = imageValue.value
      data['countries'] = countryArr
      data['start_date'] = moment(values.startDate).format("YYYY-MM-DD HH:mm:ss")
      if(values.endDateCheck===true){
        data['end_date'] = moment(values.endDate).format("YYYY-MM-DD HH:mm:ss")
      }
      if(values.linkCheck===true){
        data['redirect_link'] = values.link
      }
      setLoading(true);
      API.post(APP_URLS.ADD_SLIDES, data)
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) {
              toast.success("Slider Images Added Successfully.", {
                position: toast.POSITION.TOP_RIGHT,
              });
            setLoading(false);
            props._addtab(false)
          } else {
            toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
          }
        })
        .catch(function (error) {
          setLoading(false);
          const resp = error.response;
          let error_message = "";
          if (resp.data.data !== undefined && resp.data.data !== null) {
            {
              Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
            }
          } else if (resp.data.data?.error !== undefined) {
            error_message = resp.data.data.error;
          } else if (resp.data?.error !== undefined) {
            error_message = resp.data.error;
          } else {
            error_message = resp.data.message;
          }
          toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    },
  });


  const submitForm = (e) => { 
      if (imageValue === "" || imageValue === null || imageValue === undefined) {
        toast.error("Please provide image for the slide.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      else {
        setTimeout(() => formik.handleSubmit(e), 100);
      }  
  };


  return (
    <AddSlideWrapper>
      {popup === true ?
        <Popup _popup={_popup} countries={countries} _countryArr={_countryArr} countryArr={countryArr} />
        : ''}
      {cropperPopup === true ?
        <CropperBox aspectRatio={props.aspectRatio } _cropperPopup={_cropperPopup} cropperPopup={cropperPopup} _imgSrc={_imgSrc} imgSrc={imgSrc} _imageValue={_imageValue} _fileUploaded={_fileUploaded} fileUploaded={fileUploaded}/>
        : ''}
      <div className="preview-desktop-container">
        <div className="preview-desktop-area">
            <form className="container" onSubmit={(e) => {
              submitForm(e);
              e.preventDefault();
            }}>
              <div className="row mt-2">
                <div className="d-flex justify-content-end add-info">
                  <div className="d-flex gap-2 select-info mb-2 align-items-center">
                    <button className="save-btn cancel-btn" onClick={(e) => {
                      e.preventDefault()
                      handleClick()
                    }}>Cancel</button>
                    <button className={(loading?"disabled":"")+" save-btn"} type="submit" disabled={loading}>{loading?'Saving...':'Save'}</button>
                  </div>
                </div>
              </div> 
              <div className="row mt-4 input-fileds">
                <div className="col-md-4 mb-3 date-time-picker">
                    <label htmlFor="startDate" className="form-label product-type fieldLabel">
                      Start Date
                    </label>
                <Datetime
                    className="mt-2 filed-border"
                    name="startDate"
                    id="startDate"
                    dateFormat="DD-MM-YYYY"
                    inputProps={{
                      placeholder: "Select a date and time",
                      readOnly: true
                    }}
                    timeFormat={true}
                    isValidDate={(date)=>{return date.isAfter(yesterday)}}
                    value={formik.values.startDate}
                    onChange={
                      (e) => {
                        formik.setFieldValue(
                          "startDate",moment(calctime(e, `+${moment().tz('Europe/Amsterdam')._offset/60}`)).toDate()
                        );
                      }
                    }
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.startDate && formik.errors.startDate ? <div className="error">{formik.errors.startDate}</div> : null}
                </div>
                <div className="col-md-4 mb-3 date-time-picker">
                  <div className="d-flex justify-content-between">
                    <label htmlFor="endDate" className="form-label product-type fieldLabel">
                      End Date
                    </label>
                    <label className="form-check form-switch float-right">
                    <input
                      type="checkbox"
                      name="endDateCheck"
                      className="form-check-input"
                      value={formik.values.endDateCheck}
                      onChange={(e)=>{
                        formik.handleChange(e)
                        _endDateToggle(e.target.value===true||e.target.value==='true'?false:true)
                      }}
                      onBlur={formik.handleBlur}
                    />
                    </label>
                </div>
                <Datetime
                  name="endDate"
                  id="endDate"
                  dateFormat="DD-MM-YYYY"
                  className={(!endDateToggle ?"boxDisabled mt-2 filed-border":"mt-2 filed-border")+" "}
                  value={formik.values.endDate}
                  inputProps={{
                    readOnly: true,
                    disabled: !endDateToggle,
                    placeholder: "Select a date and time"
                  }}
                  isValidDate={(date)=>{return date.isAfter(yesterday)}}
                  onChange={
                    (e) => {
                      formik.setFieldValue(
                        "endDate",moment(calctime(e, `+${moment().tz('Europe/Amsterdam')._offset/60}`)).toDate()
                      );
                    }
                  }
                  onBlur={formik.handleBlur}
                  />
                  {formik.touched.endDate && formik.errors.endDate ? <div className="error">{formik.errors.endDate}</div> : null}
              </div>
                <div className="col-md-4 mb-3">
                  <div className="d-flex justify-content-between">
                    <label htmlFor="link" className="form-label product-type fieldLabel">
                      Redirect Link
                    </label>
                    <label className="form-check form-switch float-right">
                    <input
                      type="checkbox"
                      name="linkCheck"
                      className="form-check-input"
                      value={formik.values.linkCheck}
                      onChange={(e)=>{
                        formik.handleChange(e)
                        _linkToggle(e.target.value===true||e.target.value==='true'?false:true)
                      }}
                      onBlur={formik.handleBlur}
                    />
                    </label>
                  </div>
                  <input
                    type="text"
                    name="link"
                    className={(!linkToggle?"disabled":"")+" form-control mt-2"}
                    disabled={!linkToggle}
                    value={formik.values.link}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur} />
                  {formik.touched.link && formik.errors.link ? <div className="error">{formik.errors.link}</div> : null}
                </div>
              </div>
              <div className="row mt-4 input-fileds fileds-input">
                <div className="col-md-8 mb-3 countries">
                  <div className="d-flex justify-content-between align-items-center countryheader ">
                    <span className="form-label fieldLabel ">Countries</span>
                    <button className="btn mb-2" onClick={(e) => {
                      e.preventDefault();
                      _popup(true)
                    }}>+ Add Country</button>
                  </div>
                  <div className="container countries-box mt-2">
                    {countryArr.length > 0 ?
                      countryArr.map((item, index) => {
                        let name = countries.find((o) => o.id === item)?.fullname;
                        return <div className="d-flex justify-content-between py-1 px-4" key={index}>
                          <span>{name}</span>
                          <img src={waste} alt="" role="button" onClick={() => removeType(item)} />
                        </div>
                      })
                      :
                      <div className="">
                        <span className="suggestion">Please add some countries...</span>
                      </div>}
                  </div>
                </div>
                <div className="col-md-4 d-flex justify-content-center">
                  <div className={(fileUploaded === true ? "d-none" : "") + " slide-outer d-flex justify-content-center"}>
                    <img className="add-icon" src={plus} onClick={() => document.getElementById("file").click()} />
                    <input
                      accept="image/png, image/jpeg, image/jpg, image/webp"
                      multiple={false}
                      type="file"
                      id="file"
                      className="fileInput d-none"
                      onChange={(e) => {
                        onSelectFile(e);
                      }}
                    />
                  </div>
                  <div className={(fileUploaded === true ? "" : "d-none") + " slide-image"}>
                    <span className="resetValue status status-end" onClick={resetFile}>
                      <i className="fa fa-xmark" aria-hidden="true"></i>
                    </span>
                    <div className="d-flex justify-content-center flex-column">
                      <img src={imageValue.value} className="image" id="image" alt="search" />
                    </div>
                  </div>
                </div>
              </div>
            </form>
        </div>
      </div>
    </AddSlideWrapper>
  );
};

export default AddSlide;
