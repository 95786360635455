import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import API from "../../../../api/axios";
import { APP_URLS } from "../../../../api/url";
import { InviteUserWrapper } from "../style/inviteUser";
import { FastField, useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

const EmailUser = () => {

  const emailRegExp = /^([a-z0-9\_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,6}$/;

  const [data,_data] = useState({});
  const [countries,_countries] = useState(JSON.parse(localStorage.getItem('countries')));

  const [loading,_loading] = useState(false)

  const navigate = useNavigate()

  useEffect(()=>{
    getAdminRoles()
  },[])

  const getAdminRoles = () => {
    API.get(APP_URLS.LIST_ADMIN_ROLES + '?limited_data=true')
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          let val = {}
          resp.data.items.forEach(element => {
            val[`${element.id}_${element.role_name}`] = element.role_name
          });
          _data(val)
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined&&resp.data.data !== null) {
          {
            Object.keys(resp.data?.data).map(
              (error, index) => (error_message = resp.data.data[error][0])
              );
            }
          } else if (resp.data.data?.error !== undefined) {
            error_message = resp.data.data.error;
          } else if (resp.data?.error !== undefined) {
            error_message = resp.data.error;
          } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const formik = useFormik({
    initialValues: {
        email: '',
        firstName: '',
        lastName: '',
        country: '',
        role: '',
    },
    validationSchema: Yup.object({
        email: Yup.string().matches(emailRegExp, 'The "Email" format is invalid').required('Email is Required'),
        firstName: Yup.string().required('First name is Required'),
        lastName: Yup.string().required('Last Name is Required'),
        country: Yup.string().required('Country is Required'),
        role: Yup.string().required('Role is Required'),
    }),
    onSubmit: values => {
      _loading(true)
      let data = {}
      data['user_type'] = 1
      data['first_name'] = values.firstName
      data['last_name'] = values.lastName
      data['email'] = values.email
      data['country_id'] = values.country
      data['role_id'] = values.role.split("_")[0]
      API.post(APP_URLS.INVITE_ADMIN, data)
      .then(res => {
        _loading(false)
          const resp = res.data;
          if(resp.success===true){
            toast.success("Admin invitation has been sent successfully.", {
              position: toast.POSITION.TOP_RIGHT
            })
            formik.resetForm();
            navigate('/users')
          }
          else{
            toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT
            })
          }
      })
      .catch(function (error) {  
          _loading(false)
          const resp = error.response;
          let error_message = '';
          if (resp.data.data !== undefined && resp.data.data !== null) {
            {
              Object.keys(resp.data.data).map(
                (error, index) =>(error_message = resp.data.data[error][0])
              );
            }
          } else if (resp.data.data?.error !== undefined) {
              error_message =  resp.data.data.error;
          } else if(resp.data?.error!== undefined){
              error_message =  resp.data.error
          } else{
              error_message =  resp.data.message
          }
          toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT
          })
      });  
    },
  });


  return (
    <InviteUserWrapper>
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-3 align-items-center invite-btn">
          <div className="col-md-12 text-end btn-invite">
            <button type="submit" className={(loading ? "disabled" : "") + " save-btn"} disabled={loading}>
              {loading ? "Inviting..." : "Invite"}
            </button>
          </div>
        </div>
        <div className="row pt-5">
          <div className="col-md-6">
            <div>
              <label htmlFor="title" className="form-label fieldLabel pt-2">
                <span className="langName">First Name</span>
              </label>
              <input type="text" name="firstName" className="form-control mt-2  departmentFields" value={formik.values.firstName} onBlur={formik.handleBlur} onChange={formik.handleChange} />
              <span className="required">{formik.touched.firstName && formik.errors.firstName}</span>
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <label htmlFor="title" className="form-label fieldLabel pt-2">
                <span className="langName">Last Name</span>
              </label>
              <input type="text" name="lastName" className="form-control mt-2  departmentFields" value={formik.values.lastName} onBlur={formik.handleBlur} onChange={formik.handleChange} />
              <span className="required">{formik.touched.lastName && formik.errors.lastName}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div>
              <label htmlFor="title" className="form-label fieldLabel pt-2">
                <span className="langName">Email</span>
              </label>
              <input type="text" name="email" className="form-control mt-2  departmentFields" value={formik.values.email} onBlur={formik.handleBlur} onChange={formik.handleChange} />
              <span className="required">{formik.touched.email && formik.errors.email}</span>
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <label htmlFor="title" className="form-label fieldLabel pt-2">
                <span className="langName">Country</span>
              </label>
              <select name="country" id="country_id" className="form-control mt-2 " value={formik.values.country} onBlur={formik.handleBlur} onChange={formik.handleChange}>
                <option hidden>Select</option>
                {countries.map((item, index) => {
                  return (
                    <option value={item.id} key={index}>
                      {item.fullname}
                    </option>
                  );
                })}
              </select>
              <span className="required">{formik.touched.country && formik.errors.country}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div>
              <label htmlFor="title" className="form-label fieldLabel pt-2">
                <span className="langName">Role</span>
              </label>
              <select name="role" id="role_id" className="form-control mt-2 " value={formik.values.role} onBlur={formik.handleBlur} onChange={formik.handleChange}>
                <option hidden>Select</option>
                {Object.keys(data).map((item, index) => {
                  return (
                    <option value={item} key={index}>
                      {(data[item].charAt(0).toUpperCase() + data[item].slice(1)).replace("_", " ")}
                    </option>
                  );
                })}
              </select>
              <span className="required">{formik.touched.role && formik.errors.role}</span>
            </div>
          </div>
        </div>
      </form>
    </InviteUserWrapper>
  );
};

export default EmailUser;
