import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import API from "../../../../api/axios";
import { APP_URLS } from "../../../../api/url";
import { InviteUserWrapper } from "../style/inviteUser";
import profilePic from "../../../../assets/Images/icons/profile-pic.png"
import { Autocomplete, TextField } from "@mui/material";


const ExistingUser = () => {

  const [user, _user] = useState("");
  const [userData, _userData] = useState([]);
  const [showData, _showData] = useState(false);

  const [loading,_loading] = useState(false)

  const [userName, _userName] = useState("");
  const [role, _role] = useState("");

  const [data,_data] = useState({});

  const navigate = useNavigate()

  const setUserValue = (item) => {
    document.getElementById("subsidiary").value = item;
  };

  useEffect(()=>{
    getAdminRoles()
  },[])

  useEffect(() => {
    if (user.length > 1) {
      getActiveUserList();
    }
    else if(user.length <=1 ){
      _userData([])
    }
  }, [user]);

  const getActiveUserList = () => {
    API.get(
      APP_URLS.LIST_NON_MEMBER_USERS + `?keyword=${user}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _userData([])
          resp.data.data.forEach(element => {
            let name;
            if ((element.first_name !== null && element.first_name !== undefined) || (element.last_name !== null && element.last_name !== undefined)) {
              name = `${element.first_name !== null ? element.first_name : ""} ${element.last_name !== null ? element.last_name : ""}`;
            } else {
              name = element.pseudo;
            }
            _userData((olddata) => [
              ...olddata,
              {
                label: name?name:'-',
                value: element.id,
                image: element.profile_picture_url ? element.profile_picture_url : profilePic
              },
            ]);
          });
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data?.email !== undefined) {
          {
            Object.keys(resp.data.data.email).map((error, index) => (error_message = resp.data.data.email[error]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getAdminRoles = () => {
    API.get(APP_URLS.LIST_ADMIN_ROLES + '?limited_data=true')
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          let val = {}
          resp.data.items.forEach(element => {
            val[`${element.id}_${element.role_name}`] = element.role_name
          });
          _data(val)
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined&&resp.data.data !== null) {
          {
            Object.keys(resp.data?.data).map(
              (error, index) => (error_message = resp.data.data[error][0])
              );
            }
          } else if (resp.data.data?.error !== undefined) {
            error_message = resp.data.data.error;
          } else if (resp.data?.error !== undefined) {
            error_message = resp.data.error;
          } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const inviteUser = () =>{
    _loading(true)
    if((userName===''||userName==='null'||userName===undefined)&&(role===''||role==='null'||role===undefined)){
      toast.error("Please select a user and their role for invitation.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    else if(userName===''||userName==='null'||userName===undefined){
      toast.error("Please select user to invite.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } 
    else if(userName===''||userName==='null'||userName===undefined){
      toast.error("Please Select a role for selected user.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    else{
      let data = {}
      data['user_type'] = 2
      data['user_id'] = userName
      data['role_id'] = role.split("_")[0]
      API.post(APP_URLS.INVITE_ADMIN, data)
      .then(res => {
          const resp = res.data;
          _loading(false)
          if(resp.success===true){
            toast.success("Admin invitation has been sent successfully", {
              position: toast.POSITION.TOP_RIGHT
            })
            navigate('/users?tab=adminPanel')
          }
          else{
            toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT
            })
          }
      })
      .catch(function (error) {  
          _loading(false)
          const resp = error.response;
          let error_message = '';
          if (resp.data.data !== undefined && resp.data.data !== null) {
            {
              Object.keys(resp.data.data).map(
                (error, index) =>(error_message = resp.data.data[error][0])
              );
            }
          } else if (resp.data.data?.error !== undefined) {
              error_message =  resp.data.data.error;
          } else if(resp.data?.error!== undefined){
              error_message =  resp.data.error
          } else{
              error_message =  resp.data.message
          }
          toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT
          })
      });  
    }
  }

  return (
    <InviteUserWrapper>
      <div>
        <div className="mb-3 align-items-center invite-btn">
          <div className="col-md-12 text-end btn-invite">
            <button type="button" className={(loading ? "disabled" : "") + " save-btn"} onClick={inviteUser} disabled={loading}>
              {loading ? "Inviting..." : "Invite"}
            </button>
          </div>
        </div>
        <div className="row pt-5">
          <div className="col-md-6 mb-3">
            <div className="position-relative">
              <label htmlFor="title" className="form-label fieldLabel pt-2">
                <span className="langName">Select the User to invite</span>
              </label>
              <Autocomplete
                disableClearable
                className="form-control mt-2 flagField"
                options={userData}
                sx={{ width: 250 }}
                freeSolo
                forcePopupIcon={true}
                onChange={(event, newValue) => {
                  _userName(newValue.value);
                }}
                renderOption={(props, option, { selected }) => {
                  return (
                    <li {...props}>
                      <div className="dropdownList">
                        <img className="countryflag text-end me-2" src={option.image} />
                        <span>{option.label} </span>
                      </div>
                    </li>
                  );
              }}
                renderInput={(params) => <TextField {...params} placeholder='Select' onChange={(e)=>_user(e.target.value)}/>}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <label htmlFor="title" className="form-label fieldLabel pt-2">
                <span className="langName">Role</span>
              </label>
              <select name="country_id" id="country_id" className="form-control mt-2" onChange={(e) => _role(e.target.value)}>
                <option hidden>Select</option>
                {Object.keys(data).map((item, index) => {
                  return (
                    <option value={item} key={index}>
                      {(data[item].charAt(0).toUpperCase() + data[item].slice(1)).replace("_", " ")}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
      </div>
    </InviteUserWrapper>
  );
};

export default ExistingUser;
