import styled from "styled-components";

export const LanguageWrapper = styled.div`
  .headerContainer {
    justify-content: center !important;
  }
  .btn:hover {
    color: #fff;
  }
  .languageSelected {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 12px 0;
  }
  .languageSelected img {
    width: 22px;
    height: 22px;
    border-radius: 50%;
}
  .nameTranslated {
    display: block;
    padding: 12px 0px;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
  .translation {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }

  //------table------
  .edit-background {
    background-color: transparent !important;
  }
  .table {
    margin-top: 35px;
    min-width: 1000px;
    width: 100%;
  }
  .tableHead {
    border-radius: 0px !important;
    border-left: none;
    border-right: none;
  }
  .tableHead tr {
    border: none;
  }
  tbody tr {
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  tbody .table-row:first-child {
    margin-top: 0;
  }
  td,
  th {
    text-align: center;
    padding: 15px 5px;
    word-break: break-word;
  }
  .status {
    height: 18px;
    width: 18px;
  }
  .action button {
    background: white;
    border: none;
    cursor: pointer;
  }
`;
