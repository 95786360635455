import React from 'react'
import SearchableDropdown from '../SearchableDropdown/SearchableDropdown'

function CountryFilter({value , handleChange}) {
  return <SearchableDropdown  
            name="country_id" 
            className="filterBox" 
            setChangeValue={(val)=>handleChange(val)}
            placeholder="Select Country" 
            val={value}
            dropdownData={JSON.parse(localStorage.getItem("countries"))}
         />
}

export default CountryFilter
