import moment from 'moment';
import React from 'react'
import close from "../../../../assets/Images/icons/Cancel.svg";
import NoRecord from '../../../../assets/SharedComponents/Table/NoRecord';


const Popup = (props) => {

  return (
    <div className={(props.popup?"modal fade show":"modal fade")+ " custom-modal modal-backdrop"}> 
        <div className="modal">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">Error Logs</h5>
                <img src={close} alt="" className="mx-2" role="button" onClick={()=>props._popup('')}/>
            </div>
            <div className="modal-body">
              <div className='preview scrollbox'>
                <table className='previewTable w-100'>
                  <thead className='thead'>
                    <tr className='table-row'>
                      <th className='w-45'>Date</th>
                      <th>Error</th>
                    </tr>
                  </thead>
                  <tbody>
                  {props.popup.length > 0 ? (
                    props.popup.map((item, index) => {
                          return <tr className='table-row' key={index}>
                            <td className='w-45'>{moment(item.time).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm")}</td>
                            <td className='errorText'>{item.error}</td>
                          </tr>
                    })):
                    <NoRecord />
                    }
                  </tbody>
                </table>
              </div>
            </div>
            </div>
        </div>
        </div>
    </div>
  )
}

export default Popup