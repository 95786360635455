import React, { useEffect, useState } from "react";
import frame from "../../../../assets/Images/image/mobile-frame.png";
import { PhonePreviewWrapper } from "../style/phonePreview";
import MobileHeader from "./childComponents/mobileHeader";
import MobileStatusBar from "./childComponents/mobileStatusBar";
import appstore from "../../../../assets/Images/image/appstore.png";
import playstore from "../../../../assets/Images/image/playstore.png";
import ProductCard from "./childComponents/productCard";
import car from "../../../../assets/Images/image/car.svg";
import bike from "../../../../assets/Images/image/bike.svg";
import step from "../../../../assets/Images/image/step.svg";
import auction from "../../../../assets/Images/image/auctions.svg";
import home from "../../../../assets/Images/image/Vector.svg";
import profile from "../../../../assets/Images/image/profile.svg";
import card from "../../../../assets/Images/image/slider.svg";
import status1 from "../../../../assets/Images/image/status1.png";
import dealer from "../../../../assets/Images/image/dealers.png";
import { Carousel } from "react-responsive-carousel";
import MobileAuctionCard from "./childComponents/mobileAuctionCard";
import CarCard from "./childComponents/carCard";
import facebook from "../../../../assets/Images/image/facebook.svg";
import snapchat from "../../../../assets/Images/image/snapchat.svg";
import music from "../../../../assets/Images/image/music.svg";
import twitter from "../../../../assets/Images/image/twitter.svg";
import instagram from "../../../../assets/Images/image/instagram.svg";

const PhonePreview = (props) => {

  return (
    <PhonePreviewWrapper>
      <div className="preview-area">
        <div className="container px-0">
          <div className="row">
            <div className="col-lg-6 phone-preview-col">
              <div className="header-preview">
                <span>WEB APP</span>
              </div>
              <div className="mobile-preview my-4" style={{ backgroundImage: `url(${frame})` }}>
                <MobileStatusBar />
                <MobileHeader title={true} />
                <div className="mobile-box">
                  <div className="download-links px-3 mt-2">
                    <img src={appstore} />
                    <img src={playstore} />
                  </div>
                  <div className="products px-3 mt-4">
                    <ProductCard source={car} title="CARS" />
                    <ProductCard source={bike} title="BIKES" />
                  </div>
                  <div className="products px-3 mt-4">
                    <ProductCard source={step} type="step" title="STEPS" />
                    <ProductCard source={auction} title="AUCTIONS" />
                  </div>
                  <div className="carousel-box px-3 mt-4">
                    <Carousel
                      showThumbs={false}
                      showArrows={false}
                      showStatus={false}
                      autoPlay={true}
                      infiniteLoop={true}
                      stopOnHover={true}
                      swipeable={true}
                      interval={props.duration * 1000}
                      transitionTime={500}
                      swipeScrollTolerance={5}
                    >
                      {props.images &&
                        props.images?.map((item, index) => (
                          <div key={index}>
                            <div className="img-box">
                              <img className="carousel-img" src={item} />
                            </div>
                          </div>
                        ))}
                    </Carousel>
                  </div>
                  <div className="d-flex flex-column align-items-center auctions-container px-3">
                    <span className="head">AUCTIONS</span>
                    <div className="d-flex my-2">
                      <div className="auctions-1"></div>
                      <div className="auctions-2"></div>
                      <div className="auctions-3"></div>
                    </div>
                    <div className="adCarouselBox px-3 mt-4">
                    <Carousel
                      showThumbs={false}
                      showArrows={true}
                      showStatus={false}
                      autoPlay={true}
                      infiniteLoop={true}
                      stopOnHover={true}
                      swipeable={true}
                      interval={props.duration * 1000}
                      transitionTime={500}
                      swipeScrollTolerance={5}
                    >
                    {[<MobileAuctionCard auctionImg={card} status={status1}/>,
                      <MobileAuctionCard auctionImg={card} status={status1}/>,
                      <MobileAuctionCard auctionImg={card} status={status1}/>].map((item,index)=>(
                        <div key={index}>
                        {item}
                      </div>
                    ))}
                    </Carousel>
                  </div>
                  </div>
                  <div className="d-flex flex-column align-items-center auctions-container px-3 mt-4">
                    <span className="head">NEW PRODUCT</span>
                    <div className="d-flex my-2">
                      <div className="auctions-1"></div>
                      <div className="auctions-2"></div>
                      <div className="auctions-3"></div>
                    </div>
                    <CarCard />
                    <CarCard />
                  </div>
                  <div className="socialLinks d-flex justify-content-center w-100 mt-5">
                    <div className="sociolLogodiv d-flex align-items-center justify-content-center mx-2">
                      <img src={facebook} alt="" className="paginationImage"/>
                    </div>
                    <div className="sociolLogodiv d-flex align-items-center justify-content-center mx-2">
                      <img src={instagram} alt="" className="paginationImage"/>
                    </div>
                    <div className="sociolLogodiv d-flex align-items-center justify-content-center mx-2">
                      <img src={music} alt="" className="paginationImage"/>
                    </div>
                    <div className="sociolLogodiv d-flex align-items-center justify-content-center mx-2">
                      <img src={twitter} alt="" className="paginationImage"/>
                    </div>
                    <div className="sociolLogodiv d-flex align-items-center justify-content-center mx-2">
                      <img src={snapchat} alt="" className="paginationImage"/>
                    </div>
                  </div>
                  <div className="footer d-flex flex-column align-items-center mt-4">
                    <span className="footerTitle my-2">Copyright 2022</span>
                    <div className="d-flex flex-row align-items-center justify-content-center flex-wrap mb-2">
                      <span>Terms</span>
                      <span>Privacy Policy</span>
                      <span>Data Protection</span>
                      <span>Legal Notice</span>
                      <span>Legal Documents</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 phone-preview-col">
              <div className="header-preview">
                <span>MOBILE APP</span>
              </div>
              <div className="mobile-preview my-4" style={{ backgroundImage: `url(${frame})` }}>
                <MobileStatusBar />
                <MobileHeader />
                <div className="mobile-box-2 pb-3">
                  <div className="px-3 mt-2">
                    {/* <input type="text" className="searchbox container-border" placeholder="Find your items" /> */}
                    {/* <div className="mt-2 notice-container p-1">
                      <img className="mx-1" src={tooltip} alt="" />
                      <p>Please complete your profile to access more features</p>
                      <img className="mx-1" src={redirect} alt="" />
                    </div> */}
                  </div>
                  <div className="products px-3 mt-4">
                    <ProductCard source={car} title="CARS" />
                    <ProductCard source={bike} title="BIKES" />
                  </div>
                  <div className="products px-3 mt-4">
                    <ProductCard source={step} type="step" title="STEPS" />
                    <ProductCard source={auction} title="AUCTIONS" />
                  </div>
                  <div className="dealer-container px-3 mt-4">
                    <img src={dealer} alt="" />
                  </div>
                  <div className="carousel-box px-3 mt-2">
                    <Carousel
                      showThumbs={false}
                      showStatus={false}
                      autoPlay={true}
                      showArrows={false}
                      infiniteLoop={true}
                      stopOnHover={true}
                      swipeable={true}
                      interval={props.duration * 1000}
                      transitionTime={500}
                      swipeScrollTolerance={5}
                    >
                      {props.images &&
                        props.images?.map((item, index) => (
                          <div key={index}>
                            <div className="img-box">
                              <img className="carousel-img" src={item} />
                            </div>
                          </div>
                        ))}
                    </Carousel>
                  </div>
                  <div className="d-flex flex-column align-items-center auctions-container px-3">
                    <span className="head">AUCTIONS</span>
                    <div className="d-flex my-2">
                      <div className="auctions-1"></div>
                      <div className="auctions-2"></div>
                      <div className="auctions-3"></div>
                    </div>
                    <div className="adCarouselBox px-3 mt-4">
                    <Carousel
                      showThumbs={false}
                      showStatus={false}
                      showArrows={true}
                      autoPlay={true}
                      infiniteLoop={true}
                      stopOnHover={true}
                      swipeable={true}
                      interval={props.duration * 1000}
                      transitionTime={500}
                      swipeScrollTolerance={5}
                    >
                    {[<MobileAuctionCard auctionImg={card} status={status1}/>,
                      <MobileAuctionCard auctionImg={card} status={status1}/>,
                      <MobileAuctionCard auctionImg={card} status={status1}/>].map((item,index)=>(
                        <div key={index}>
                        {item}
                      </div>
                    ))}
                    </Carousel>
                  </div>
                  </div>
                </div>
                  <div className="d-flex justify-content-around actionbar py-3 position-relative">
                    <div className="d-flex flex-column align-items-center pe-5">
                      <img className="" src={home} /> 
                      <span>Home</span>
                    </div>
                    <div className="d-flex flex-column align-items-center position-absolute addIconContainer">
                      <div className="addIcon d-flex justify-content-center align-items-center">
                        <span>+</span>
                      </div>
                      <span>Add Vehicle</span>
                    </div>
                    <div className="d-flex flex-column align-items-center ps-5">
                      <img src={profile} /> 
                      <span>My Profile</span>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PhonePreviewWrapper>
  );
};

export default PhonePreview;
