import React, { useState } from "react";
import logo from "../../../../assets/Images/image/ctbIcon.png";
import bg from "../../../../assets/Images/image/bgImage.png";
import hamburger from '../../../../assets/Images/image/hamburger.svg'
import car from "../../../../assets/Images/image/car.svg";
import bike from "../../../../assets/Images/image/bike.svg";
import step from "../../../../assets/Images/image/step.svg";
import shop from "../../../../assets/Images/image/shop.svg";
import bell from "../../../../assets/Images/image/bell2.svg";
import auctionIcon from "../../../../assets/Images/image/auctionIcon.svg";
import profile from "../../../../assets/Images/image/profile.png"
import country from "../../../../assets/Images/icons/country.png"
import arrowDown from "../../../../assets/Images/icons/faChevronDown.svg";
import auction from "../../../../assets/Images/image/auctions.svg";
import auction1 from "../../../../assets/Images/image/auction1.svg";
import auction2 from "../../../../assets/Images/image/auction2.svg";
import auction3 from "../../../../assets/Images/image/auction3.svg";
import status1 from "../../../../assets/Images/image/status1.svg";
import status2 from "../../../../assets/Images/image/status2.png";
import appstore from "../../../../assets/Images/image/appstore.png";
import playstore from "../../../../assets/Images/image/playstore.png";
import playstoreqr from "../../../../assets/Images/image/playstoreQR.png";
import appstoreqr from "../../../../assets/Images/image/appstoreQR.png";
import page from "../../../../assets/Images/image/pagination.svg";
import { DesktopPreviewWrapper } from "../../Slides/style/desktopPreview";
import AppDownloadCard from "../../Slides/components/childComponents/appDownloadCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ProductCardDesktop from "../../Slides/components/childComponents/productCardDesktop";
import DesktopAuctionCard from "../../Slides/components/childComponents/desktopAuctionCard";
import CarCard from "../../Slides/components/childComponents/carCard";
import facebook from "../../../../assets/Images/image/facebook.svg";
import snapchat from "../../../../assets/Images/image/snapchat.svg";
import music from "../../../../assets/Images/image/music.svg";
import twitter from "../../../../assets/Images/image/twitter.svg";
import instagram from "../../../../assets/Images/image/instagram.svg";

const DesktopPreview = (props) => {

  const [active,_active] = useState('all')

  const responsive = {
    desktop: {
      breakpoint: { max: 5000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <DesktopPreviewWrapper>
      <div className="preview-desktop-container">
        <div className="preview-desktop-area">
          <div className="preview-header py-2 mx-4 px-4">
          <div className='header-box'>
            <img src={logo} alt="" className="ctblogo"/>
            <img className='mx-3 header-icon' src={hamburger} alt=""/>
            <div className='search-box ms-5 px-3'>
              <img className="navigate-icon ms-2 me-4" src={shop} alt=""/>
              <span className="">|</span>
              <input type="text" placeholder="Find your dealer"/>
            </div>
          </div>
          <div className='header-box'>
            <div className="d-flex flex-column align-items-center mx-3">
              <img className='bell-icon' src={bell} alt=""/>
            </div>
            <div className="position-relative">
              <img className='mx-3' src={profile} alt=""/>
              <img className='userFlag position-absolute' src={country} alt=""/>
            </div>
            <div className="d-flex flex-column">
              <p className="uname">Steve Smith</p>
              <p className="uprofile">Switch Profile</p>
            </div>
            <span className="border-right ms-3"></span>
            <img className='ms-3 flag' src={country} alt=""/>
            <img  src={arrowDown} alt=""/>
          </div>
          </div>
          <div className="mb-4 px-5 product-container d-flex flex-column justify-content-center align-items-center" style={{ backgroundImage: `url(${bg})` }}>
            <div className="d-flex align-items-center mt-5">
              <AppDownloadCard qr={appstoreqr} image={appstore} title="Iphone" url="https://apps.apple.com/us/app/cartobike-com/id1608618088"/>
              <AppDownloadCard qr={playstoreqr} image={playstore} title="Android" url="https://play.google.com/store/apps/details?id=com.cartobikeapp"/>
            </div>
            <div className="product-card-container d-flex flex-row my-4">
              <ProductCardDesktop image={car} title="Car"/>
              <ProductCardDesktop image={bike} title="Bike"/>
              <ProductCardDesktop image={step} title="Step"/>
              <ProductCardDesktop image={auction} title="Auction"/>
            </div>
          </div>
          <div className="px-5 carousel-container">
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={props.duration * 1000}
              keyBoardControl={true}
              transitionDuration={props.duration*1000}
              pauseOnHover
              containerClass="carousel-container"
              removeArrowOnDeviceType={["desktop","tablet", "mobile"]}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
              {props.images &&
                props.images?.map((item, index) => (
                
                  <div key={index} >
                    <div className="img-box">
                      <img className="carousel-img" src={item} />
                      <span>{index<10?"0"+index:index}</span>
                    </div>

                  </div>
                ))}
            </Carousel>
          </div>
          <div className="d-flex flex-column align-items-center auctions-container px-3 mt-4">
            <span className="head mt-2">AUCTIONS</span>
            <div className="d-flex my-2">
              <div className="auctions-1"></div>
              <div className="auctions-2"></div>
              <div className="auctions-3"></div>
            </div>
            <div className="carousel-container">
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={false}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={props.duration * 1000}
                keyBoardControl={true}
                transitionDuration={props.duration*1000}
                pauseOnHover
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
              >
                {
                  [<DesktopAuctionCard auctionImg={auction1} status={status2} class="active-status" desc="Started"/>,
                    <DesktopAuctionCard auctionImg={auction2} status={status1} class="progress-status" desc="Starts soon"/>,
                    <DesktopAuctionCard auctionImg={auction3} status={status1} class="progress-status" desc="Starts soon"/>
                  ].map((item, index) => (
                    <div key={index}>
                      {item}
                    </div>
                  ))}
              </Carousel>
          </div>
          </div>
          <div className="d-flex flex-column align-items-center auctions-container px-3 mt-4">
            <span className="head mt-2">NEW PRODUCT</span>
            <div className="d-flex my-2">
              <div className="auctions-1"></div>
              <div className="auctions-2"></div>
              <div className="auctions-3"></div>
            </div>
            <div className="tabs d-flex mt-4 mb-2">
              <div className={(active==="all"?"active":"")+" p-3"} onClick={()=>_active("all")}>
                <span>ALL</span>
              </div>
              <div className={(active==="top20"?"active":"")+" p-3"} onClick={()=>_active("top20")}>
                <span>TOP 20</span> 
              </div>
              <div className={(active==="best"?"active":"")+" p-3"} onClick={()=>_active("best")}>
                <span>LATEST ADS</span>
              </div>
              <div className={(active==="popular"?"active":"")+" p-3"} onClick={()=>_active("popular")}>
                <span>MOST POPULAR</span>
              </div>
            </div>
            <div className="mt-3 d-flex p-2 productCarConatiner justify-content-between flex-wrap">
              <CarCard />
              <CarCard />
              <CarCard />
              <CarCard />
              <CarCard />
              <CarCard />
              <CarCard />
              <CarCard />
              <div className="d-flex justify-content-center w-100 mt-4">
                    <img src={page} alt="" className="paginationImage"/>
              </div>
              <div className="socialLinks d-flex justify-content-center w-100 mt-5">
                <div className="starDiv d-flex align-items-center justify-content-center mx-2">
                  <img src={facebook} alt="" className="paginationImage"/>
                </div>
                <div className="starDiv d-flex align-items-center justify-content-center mx-2">
                  <img src={instagram} alt="" className="paginationImage"/>
                </div>
                <div className="starDiv d-flex align-items-center justify-content-center mx-2">
                  <img src={music} alt="" className="paginationImage"/>
                </div>
                <div className="starDiv d-flex align-items-center justify-content-center mx-2">
                  <img src={twitter} alt="" className="paginationImage"/>
                </div>
                <div className="starDiv d-flex align-items-center justify-content-center mx-2">
                  <img src={snapchat} alt="" className="paginationImage"/>
                </div>
              </div>
            </div>
          </div>
          <div className="footer d-flex p-3 mt-5">
            <div className="w-50">
              <span>Copyright 2022</span>
            </div>
            <div className="w-50 d-flex justify-content-end">
              <span className="mx-3">Terms</span>
              <span className="mx-3">Privacy Policy</span>
              <span className="mx-3">Data Protection</span>
              <span className="mx-3">Legal notice</span>
              <span className="mx-3">Legal documents</span>
            </div>
          </div>
        </div>    
      </div>
    </DesktopPreviewWrapper>
  );
};

export default DesktopPreview;
