import { toast } from "react-toastify"
import { supportedExtensionForIcons } from "../../../config/constant"


 
// this function is used to upload images or icons for diffrent vehicle info categories like ( brand , body , interior , equipments e.t.c )
export function handleColorImage(e,handleColorImage,handleColorImageValue , deviceType = 'web'){
    const img = new Image() 
    img.src = window.URL.createObjectURL(e.target.files[0])
    const type = e.target.files[0].name.split('.').pop()
    img.onload = () => {
      if((img.width<45||img.width>80)||(img.height<45||img.height>80)){
        toast.error("Required dimensions are between 45 x 45 - 80 x 80", {
          position: toast.POSITION.TOP_RIGHT
        })
        return;
      } 
      if(deviceType == 'mobile' && (!supportedExtensionForIcons.mobile.includes(type))){ 
        toast.error(`Image should be in webp or png format.`, {
          position: toast.POSITION.TOP_RIGHT
        })
        return; 
      } 

      if(deviceType == 'web' && (!supportedExtensionForIcons.web.includes(type))){ 
        toast.error(`Image should be in svg format.`, {
          position: toast.POSITION.TOP_RIGHT
        })
        return; 
      } 
      
      const inputId = 'fileColor' + deviceType 
      const imagedata = document.getElementById(inputId).files[0] 
      handleColorImage(imagedata.name)
      handleColorImageValue(imagedata)
       
    }
  }