import React, { useEffect, useState } from "react";
import error from "../../../assets/Images/icons/alert-tri.svg";
import edit from "../../../assets/Images/image/edit.png";
import waste from "../../../assets/Images/image/Waste.png";
import { TableWrapper } from "../style/table";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import Pagination from "../../../assets/SharedComponents/Pagination";
import SideTranslate from "../components/SideTranslate";
import NoRecord from "../../../assets/SharedComponents/Table/NoRecord";
import SearchKey from "../../../assets/SharedComponents/Search/SearchKey";
import { getName } from "../../../core/utils/getName/getName";
import { Tooltip } from "@mui/material";
import { FaFilterIcon } from "../../../assets/Images/icon";
import { ThreeDots } from "react-loader-spinner";
import RecordCount from "../../../assets/SharedComponents/RecordCount/RecordCount";
import { suggestionText } from "../../../config/constant";
import { generateQueryString } from "../../../core/utils";

const ForumBehaviorText = () => {

  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const [searchParams, setSearchParams] = useSearchParams();

  const [data, _data] = useState([]);
  const [countries, setcountries] = useState(() => JSON.parse(localStorage.getItem("countries")) || []);
  const [languages, setlanguages] = useState(() => JSON.parse(localStorage.getItem("languages")) || []);
  const [dataDeleted, _dataDeleted] = useState(false);

  const [searchkeyword, _searchkeyword] = useState(searchParams.get('name') !== null ? searchParams.get('name') : "");
  const [countValue, _countValue] = useState(searchParams.get('per_page') !== null ? searchParams.get('per_page') : "");

  const [page, setPage] = useState(searchParams.get('page') !== null ? parseInt(searchParams.get('page')) : 1);
  const [total, _total] = useState(0);
  const [totalPage, _totalPage] = useState(0);

  const [filters, _filters] = useState(false);
  const [loaded, _loaded] = useState(false);

  const [userAccess, _userAccess] = useState(JSON.parse(localStorage.getItem('permissions')))

  let translationAccess = userAccess.find(o => o.slug === "translation")

  useEffect(() => {
    getBehaviortext(); //Retrieve behavior text for forum
    _dataDeleted(false);
  }, [dataDeleted, page, searchkeyword, countValue]);

  //Retrieve behavior text for forum
  const getBehaviortext = () => {
    _loaded(true);
    API.get(APP_URLS.LIST_SUGGESTION_TEXT + `?page=${page}&per_page=${countValue}&filter[name]=${searchkeyword}&filter[type]=${suggestionText.FORUM_CENSORSHIP}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _data(resp.data.data);
          _total(resp.data.total);
          _totalPage(resp.data.last_page);
          _loaded(false);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loaded(false);
        }
      })
      .catch(function (error) {
        _loaded(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  //deleting forum behaviour text for forum
  const removeType = (index) => {
    confirmAlert({
      title: "Confirm to remove",
      message: `Are you sure you want to remove?`,
      closeOnEscape: true,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            API.delete(APP_URLS.DELETE_SUGGESTION_TEXT + `/${index}`)
              .then((res) => {
                const resp = res.data;
                if (resp.success === true) {
                  _dataDeleted(true);
                  toast.success("Behavior Text has been deleted successfully.", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                } else {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              })
              .catch(function (error) {
                const resp = error.response;
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                  {
                    Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
                  }
                } else if (resp.data.data?.error !== undefined) {
                  error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                  error_message = resp.data.error;
                } else {
                  error_message = resp.data.message;
                }
                toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          },
        },
        {
          label: "No",
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-custom-class-name",
    });
  };

  //Manage search change.
  const handleSearchChange = (func, searchValue) => {
    func(searchValue);
    if ((func === _countValue && searchValue !== countValue) || (func === _searchkeyword && searchValue !== searchkeyword)) {
      if (searchValue !== "") {
        _totalPage(0)
        setPage(1);
      }
    }
  };

  //Reset filters
  const clearFilter = () => {
    _filters(true)
    handleSearchChange(_countValue, '');
    handleSearchChange(_searchkeyword, '');
    if (countValue !== '' || searchkeyword !== "") {
      _totalPage(0)
      setPage(1);
    }
    for (var value of query.keys()) {
      const param = searchParams.get(value);
      if (param) {
        searchParams.delete(value);
        setSearchParams(searchParams);
      }
    }
  };

  //Generating edit forum URL
  const getEditForumUrl = (itemId) => {
    const queryString = generateQueryString({
      name: searchkeyword,
      per_page: countValue,
      page: page
    });
    return `/translation/edit-forum-behavior-text/${itemId}?${queryString}`;
  };

  return (
    <>
      <TableWrapper>
        <div className="row">
          <div className="col-lg-2 col-md-3 col-sm-4 mt-3">
            <h4 className="translation text-center mb-3">TRANSLATION</h4>
            <SideTranslate />
          </div>
          <div className="col-lg-10 col-md-9 col-sm-8 mt-2">
            <div className="headerContainer mb-3">
              <h1>FORUM BEHAVIOR</h1>
            </div>
            <div className="d-flex justify-content-between add-info">
              <div className="add-depertment d-flex justify-content-end mb-2">
                {translationAccess.permissions.find((o) => o.slug === "add") ? (
                  <div>
                    <NavLink to="/translation/create-forum-behavior-text">
                      <button className="btn">+ Add</button>
                    </NavLink>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex justify-content-end mb-2 gap-2 select-info align-items-center gap-2">
                <SearchKey onSearchClick={(search) => handleSearchChange(_searchkeyword, search)}
                  val={searchkeyword}
                  filters={filters}
                  _filters={_filters}
                />
                <RecordCount
                  onSearchClick={(search) => handleSearchChange(_countValue, search)}
                  val={countValue}
                  id="pageCount"
                  filters={filters}
                  _filters={_filters} />
                <Tooltip title="Clear Filter" placement="bottom">
                  <FaFilterIcon className="filter-icon" role="button" onClick={clearFilter} />
                </Tooltip>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between info-wrap">
              <div className="justify-content-end mt-1 me-2">
                <span className='text-start'>Total Records : <b>{total}</b></span>
              </div>
            </div>
            <div className="tableContainer">
              <table className="auctionTable">
                <thead className="tableHead">
                  <tr className="table-row">
                    <th className="tableHead-1">ID</th>
                    <th className="tableHead-2">Language</th>
                    <th className="tableHead-3">Name</th>
                    <th className="tableHead-4">Updated by</th>
                    <th className="tableHead-5">Created by</th>
                    {translationAccess.permissions.find((o) => o.slug === "update") || translationAccess.permissions.find((o) => o.slug === "delete") ? <th className="tableHead-9">Action</th> : ""}
                  </tr>
                </thead>
                {
                  loaded ?
                    <div className='d-flex justify-content-center three-dot'>
                      <ThreeDots
                        height="150"
                        width="150"
                        radius="9"
                        color="#637df4"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                    :
                    <tbody>
                      {data.length > 0 ? (
                        data.map((forumBehaviour, index) => {
                          let createdBy = getName(forumBehaviour.create_activity_log, forumBehaviour.update_activity_log);
                          let upadtedBy = getName(forumBehaviour.update_activity_log, forumBehaviour.create_activity_log);
                          return (
                            <tr className="table-row" key={forumBehaviour.id}>
                              <td className="tableHead-1">{forumBehaviour.id}</td>
                              <td className="tableHead-2">
                                {Object.keys(forumBehaviour.name).map((name) => {
                                  let country_id;
                                  let flag;
                                  let countryValues = languages.find((o) => o.code === name);
                                  if (countryValues) {
                                    country_id = countryValues.country_id;
                                    flag = countries.find((o) => o.id === country_id)?.flag_thumbnail;
                                  }
                                  return (
                                    <div key={name.id} className="languageSelected d-flex gap-2">
                                      {countryValues ? (
                                        <>
                                          <img src={flag} alt="" title={countryValues?.name} />
                                          <span className="flagName">{name}</span>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  );
                                })}
                              </td>
                              <td className="tableHead-3">
                                {Object.keys(forumBehaviour.name).map((nameText) => {
                                  let countryValues = languages.find((o) => o.code === nameText);
                                  return forumBehaviour.name[nameText] !== null && forumBehaviour.name[nameText] !== "" ? (
                                    <span key={nameText.id} className="nameTranslated">
                                      {countryValues ? forumBehaviour.name[nameText] : ""}
                                    </span>
                                  ) : (
                                    <span key={nameText.id} className="nameTranslated">
                                      <img src={error} alt="" className="warningIcon" />
                                    </span>
                                  );
                                })}
                              </td>
                              <td>{upadtedBy !== "" && upadtedBy !== undefined ? upadtedBy.charAt(0).toUpperCase() + upadtedBy.slice(1) : "-"}</td>
                              <td>{createdBy !== "" && createdBy !== undefined ? createdBy.charAt(0).toUpperCase() + createdBy.slice(1) : "-"}</td>
                              {translationAccess.permissions.find((o) => o.slug === "update") || translationAccess.permissions.find((o) => o.slug === "delete") ? (
                                <td className="tableHead-9">
                                  {translationAccess.permissions.find((o) => o.slug === "update") ? (
                                    <NavLink to={getEditForumUrl(forumBehaviour.id)}>
                                      <img src={edit} alt="" className="mx-2" role="button" />
                                    </NavLink>
                                  ) : (
                                    ""
                                  )}
                                  {translationAccess.permissions.find((o) => o.slug === "delete") ? <img src={waste} alt="" role="button" onClick={() => removeType(forumBehaviour.id)} /> : ""}
                                </td>
                              ) : (
                                ""
                              )}
                            </tr>
                          );
                        })
                      ) : (
                        <NoRecord />
                      )}
                    </tbody>
                }
              </table>
            </div>
            <div>
              {totalPage > 1 ? <Pagination totalPages={totalPage} key={totalPage} page={page} onPageClick={(page) => setPage(page + 1)} /> : ""}
            </div>
          </div>
        </div>
      </TableWrapper>
    </>
  );
};

export default ForumBehaviorText;
