import styled from "styled-components";

export const ProspectDealersWrapper = styled.div`
  .headerContainer div,
  .headerContainer h1 {
    margin-bottom: 10px;
  }
  .searchContainer {
    display: flex;
    padding: 0 15px 0 0;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  .search {
    height: 35px;
    border: none;
    border-radius: 10px;
  }

  #dealer-table0 {
    min-width: 5%;
  }
  #dealer-table1 {
    min-width: 9%;
  }
  #dealer-table2 {
    min-width: 150px;
  }
  #dealer-table3 {
    min-width: 140px;
  }
  #dealer-table4 {
    min-width: 120px;
  }
  #dealer-table5 {
    min-width: 150px;
  }
  #dealer-table6 {
    min-width: 8%;
  }
  #dealer-table7 {
    min-width: 8%;
  }
  #dealer-table8 {
    min-width: 8%;
  }
  #dealer-table9 {
    min-width: 8%;
  } 
`;
