import React, { useEffect, useMemo, useState } from "react";
import { NavLink, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { RoleListWrapper } from "../Partners/style/role";
import copy from "../../assets/Images/icons/faLink.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import API from "../../api/axios";
import { APP_URLS } from "../../api/url";
import { toast } from "react-toastify";
import NoRecord from "../../assets/SharedComponents/Table/NoRecord";
import Pagination from "../../assets/SharedComponents/Pagination";
import SearchKey from "../../assets/SharedComponents/Search/SearchKey";
import { Button, Tooltip } from "@mui/material";
import { FaFilterIcon } from "../../assets/Images/icon";
import { ThreeDots } from "react-loader-spinner";
import RecordCount from "../../assets/SharedComponents/RecordCount/RecordCount";
import SearchableDropdown from "../../assets/SharedComponents/SearchableDropdown/SearchableDropdown";
import faHide from "../../assets/Images/icons/svgIcon/FaHide.svg"
import moment from "moment";
import { userTypeName, userTypes } from "../../config/constant"; 
import SubjectFilter from "../../assets/SharedComponents/Filters/SubjectFilter";
import StaticFilter from "../../assets/SharedComponents/Filters/StaticFilter";
import CountryFilter from "../../assets/SharedComponents/Filters/CountryFilter";
import download from "downloadjs";

const userTypesData =  Object.keys(userTypes).map(key => ({ name: key , value: userTypes[key]}))

const ContactUs = () => {

  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const [searchParams, setSearchParams] = useSearchParams();

  const [countries,_countries] = useState( () => JSON.parse(localStorage.getItem('countries')))

  const [data, _data] = useState([]); 
  
  const [page, setPage] = useState(1);
  const [total, _total] = useState(0);
  const [totalPage, _totalPage] = useState(0); 
  
  const [country,_country] = useState('')
  const [searchkeyword, _searchkeyword] = useState("");
  const [countValue, _countValue] = useState("");
  const [userType , _userType] = useState('')
  const [subjectId , _subjectId] = useState('')
  const [dataDeleted, _dataDeleted] = useState(false); 
  const [filters, _filters] = useState(false);
  const [ loaded, _loaded] = useState(false);
  
  const [show,_show] = useState(false)

  const setChangeValue = (set, item) => { 
    set(item);
    _totalPage(0)
    setPage(1);
  }

  const handleDrop =(index) =>{
    if(index == show) _show('-1')
    else _show(index) 
  }

  useEffect(()=>{
    _show('')
    getContacts()
    _dataDeleted(false)
  },[searchkeyword,page,dataDeleted,countValue,country, subjectId, userType])
 
  const getContacts = () => {
    _loaded(true)
    API.get(APP_URLS.LIST_CONTACT  +`?page=${page}&per_page=${countValue}&keyword=${searchkeyword}&filter[country_id]=${country}&filter[type]=${userType}&filter[subject_id]=${subjectId}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _data(resp.data.data)
          _total(resp.data.total);
          _totalPage(resp.data.last_page)
          _loaded(false)
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loaded(false)
        }
      })
      .catch(function (error) {
        _loaded(false)
        const resp = error.response;
        let error_message = "";
        if (resp?.data?.data) {
          {
            Object.keys(resp.data?.data).map(
              (error, index) => (error_message = resp.data.data[error][0])
              );
            }
          } else if (resp?.data?.error ) {
            error_message = resp.data.error;
          } else {
          error_message = resp.data.message || 'An Unknown error occurred';
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleSearchChange = (func, searchValue) => {
    func(searchValue);
    if(((func===_countValue&&searchValue!==countValue)||(func===_searchkeyword&&searchValue!==searchkeyword))&&searchValue !== ""){
        _totalPage(0)
        setPage(1);
    }
  };

  const clearFilter = () => {
    _filters(true)
    handleSearchChange(_countValue, '');
    handleSearchChange(_searchkeyword, '');
    _country('')
    _userType('')
    _subjectId('')
    if(countValue !== '' ||searchkeyword!==''||country!==''){
      _totalPage(0)
      setPage(1);
    }
    for(var value of query.keys()) {
      const param = searchParams.get(value);
      if (param) {
        searchParams.delete(value);
        setSearchParams(searchParams);
      }
     }
  };
 
  const handleUserTypeChange = (type) => {
    _totalPage(0);
    setPage(1);
    _userType(type);
  }; 

  const handleExtractReport = () => {
      API.get(`${APP_URLS.LIST_CONTACT}/download_contacts_report?page=${page}&per_page=${countValue}&keyword=${searchkeyword}&filter[country_id]=${country}&filter[type]=${userType}&filter[subject_id]=${subjectId}`, {responseType: 'blob'})
      .then(res => { 
       const resp = res.data;
       if(resp!==null){
         toast.success("Downloading. Please wait", {
             position: toast.POSITION.TOP_RIGHT
           })
         const content = res.headers['content-type'];
         const currentDate = moment().format('YYYY-MM-DD HH-mm-ss');
         const fileName = `${currentDate}_contacts_report.xlsx`
         download(resp, fileName, content)
     }
     else{
       toast.error(resp.message, {
         position: toast.POSITION.TOP_RIGHT
       })
     }
     })
     .catch(function (error) {  
       const resp = error.response;
       let error_message = '';
       if (resp.data.errors !== undefined) {
         {
           Object.keys(resp.data.errors).map(
             (error, index) => (error_message = resp.data.errors[error][0])
           );
         }
       } else if (resp.data.data?.error !== undefined) {
           error_message =  resp.data.data.error;
       } else if(resp.data?.error!== undefined){
           error_message =  resp.data.error
       } else{
           error_message =  resp.data.message || 'An Unknown error occurred'
       }
       toast.error(error_message, {
         position: toast.POSITION.TOP_RIGHT
       })
     });
   } 

  return (
    <RoleListWrapper>
      <div className="auctionMainContainer">
        <div className="row mt-4">
          <div className="col-md-4 offset-md-4">
            <h1 className="text-center">Contact Us</h1>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between add-info">
          <div className="d-flex justify-content-end mb-2 data-button">
          </div>
          <div className="justify-content-end mb-2 d-flex gap-2 mb-2 flex-wrap align-items-center">
            <CountryFilter value={country} handleChange={(val)=>setChangeValue(_country, val)}/>  
            <SubjectFilter value={subjectId} handleChange={(e) => {
                  _totalPage(0);
                   setPage(1);
                  _subjectId(e.target.value);
                }} 
            /> 
            <SearchKey
              onSearchClick={(search) => handleSearchChange(_searchkeyword,search)}
              id="searchData"
              filters={filters}
              val = {searchkeyword}
              _filters={_filters}
              placeholder="Search by Email"
            />
            <StaticFilter placeholder='Select type' data={userTypesData}  handleChange={(e) => {handleUserTypeChange(e.target.value)}} value={userType}/> 
            <RecordCount
              onSearchClick={(search) => handleSearchChange(_countValue, search)}
              val = {countValue}
              id="pageCount"
              filters={filters}
              _filters={_filters} />
            <Button onClick={handleExtractReport} className='extract-report' variant="outlined">Extract report</Button>  
            <Tooltip title="Clear Filter" placement="bottom">
                <FaFilterIcon className="filter-icon" role="button" onClick={clearFilter} />
            </Tooltip>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between info-wrap">
            <div className="justify-content-end mt-1 me-2">
                <span className='text-start'>Total Records : <b>{total}</b></span>
            </div>
        </div>
            <div className="tableContainer">
            <table className="auctionTable">
              <thead className="tableHead">
                <tr className="table-row">
                  <th>ID</th>
                  <th className="clipboard">
                    <span className="d-block">{userTypeName.PRIVATE}/{userTypeName.DEALER}</span> 
                    <span>Email</span>
                  </th>
                  <th>First Name </th>
                  <th>Last Name </th>
                  <th>{`${userTypeName.DEALER} Name`}</th> 
                  <th className="w-150">Subject </th>
                  <th className="w-150">
                    <span className="d-block">Country</span>
                    <span>Phone</span>
                  </th> 
                  <th>Created At</th>
                  <th className="w-250">Message</th>
                </tr>
              </thead>
          {
            loaded ? 
            <div className='d-flex justify-content-center three-dot'>              
            <ThreeDots 
              height="150" 
              width="150" 
              radius="9"
              color="#637df4" 
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
          :
              <tbody>
                {data.length > 0 ? (
                  data.map((item, index) => {
                    const countryVal = countries.find((o)=>o.id===item.country_id) 
                    const isCompany = item.type == userTypes.Company
                    return (
                      <>
                      <tr className="table-row" key={'contact'+item.id}>
                        <td>{item.id}</td>
                        <td className="clipboard">
                          <p className="copyText" title={userTypes[Number(item.type)]}>{isCompany ? userTypeName.DEALER : userTypeName.PRIVATE}</p>
                          <div className="my-2 d-flex justify-content-center">
                            <span className="copyText" title={item?.email || ""}>{item?.email || ""}</span>
                            <CopyToClipboard text={item.email} onCopy={() => toast("Text Copied")}>
                                <img className="ms-2 cursor-pointer" src={copy} alt=""/>
                              </CopyToClipboard>
                          </div>
                        </td>
                        <td><span>{item.first_name || "-"}</span></td>
                        <td><span>{item.last_name || "-"}</span></td>
                        <td><span>{item.company_name || "-"}</span></td> 
                        <td>{item?.subject?.name?.en || '-'}</td>
                        <td className="w-150">
                          <span className="d-block">
                            {
                              countryVal ? <>
                                 <img src={countryVal.flag_thumbnail} alt="" title={countryVal.fullname} className="countryflag"/>
                                 <span>{countryVal.prefix_call}</span> 
                              </> : null
                            } 
                            {
                              item.phone ? <> <br/><span> {item.phone}</span> </>: '-'
                            } 
                          </span>
                        </td>
                        <td>
                          {item.created_at !== null?
                            moment(new Date(item.created_at)).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm")
                          :"-"}
                        </td> 
                        <td className="w-250">
                          {item.message?
                          <div className="d-flex flex-row align-items-center">
                            <div className="userDetails">
                              <span className="message-text" title={item.message}>{item?.message}</span>
                            </div>
                            <div>
                              <img src={faHide} alt="show" className={(show === index ? "close":"open")+" dropbtn ms-2"} onClick={()=>handleDrop(index)}/>
                            </div>
                          </div>
                          :"-"}
                        </td>
                      </tr>
                      {show === index?
                        <tr className="table-row">
                          <td className='dropped'>
                            {item.message}
                          </td>
                        </tr>
                        :""}
                      </>
                    );
                  })
                ) : (
                  <NoRecord />
                )}
              </tbody>
          }
            </table>
          </div>
            <div>
              {totalPage > 1 ? <Pagination totalPages={totalPage} key={totalPage} page={page} onPageClick={(page) => setPage(page + 1)} /> : ""}
            </div>
      </div>
    </RoleListWrapper>
  );
};

export default ContactUs;
