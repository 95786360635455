import React, { useEffect, useState } from "react";
import MainLayout from "../../../assets/Layout/MainLayout";
import searchIcon from "../../../assets/Images/icons/faSearchLite.svg";
import { PlanWrapper } from "./style/plan";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { planType } from "../../../config/constant";

let featureArr = []

const Plan = () => {

  const [data,_data] = useState([])
  const [filtereddata,_filtereddata] = useState([])
  const [loaded,_loaded] = useState(false)

  const [feature,_feature] = useState('')

  const [interval,_interval] = useState(false)
  const [loading,_loading] = useState(false)

  const [selectAll,_selectAll] = useState(false)


  const navigate = useNavigate()

  useEffect(() => {
    getFrontOfficeFeatures()
  }, [])

  const getFrontOfficeFeatures =() =>{
    API.get(APP_URLS.LIST_FEATURES + `?filter[type]=2&with_permissions=true`)
    .then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        const UpdateData = resp.data.items.map((item)=> {
          item['selected'] = false
          return item
        })
        _data(UpdateData);
        _filtereddata(UpdateData)
        _loaded(true)
      } else {
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    })
    .catch(function (error) {
      const resp = error.response;
      let error_message = "";
      if (resp.data.data !== undefined && resp.data.data !== null) {
        if(resp.data.data.message){
          error_message = resp.data.data.message
        }else{
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        }
      } else if (resp.data.data?.error !== undefined) {
        error_message = resp.data.data.error;
      } else if (resp.data?.error !== undefined) {
        error_message = resp.data.error;
      } else {
        error_message = resp.data.message;
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    });
  }

  const handlefeatureChange =(value) =>{
    if(feature.length>0){
      let newData = [...data]
      let val = newData.filter(newData=>newData.name['en'].includes(value))
      _filtereddata(val)
    }
    else{
      _filtereddata(data)
    }
  }

  const setChecked = (id) =>{
    let newdata = [...filtereddata]
    let value = newdata.find(o=>o.id===id)
    if(value.selected===false){
      value.selected=true
    }
    else{
      value.selected=false
    }
    _filtereddata(newdata)
  }
  
  const handleAllselected =()=>{
    if(!selectAll){
      filtereddata.forEach(item=>{
        item.selected=true
      })
    }
    else{
      filtereddata.forEach(item=>item.selected=false)
    }
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      nickname: "",
      amount: "",
      interval: "",
      interval_count: '1',
      active: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Plan name is required").min(2, "Plan name must be atleast 2 characters").max(100, "Plan name must be atmost 100 characters"),
      nickname: Yup.string().required("Nickname is required").min(2, "Nickname must be atleast 2 characters").max(100, "Nickname must be atmost 100 characters"),
      amount: Yup.number().required("Price is required").positive("Amount must be positive").min(1,"Amount must be greater than 0").max(5000, "Amount must be smaller than 5000"),
      interval: Yup.string().required("Recurring Interval is required"),
      active: Yup.string().required("Choose Status for plan"),
    }),
    onSubmit: (values) => {
      if(!loading){
        _loading(true)
        let data = {};
        data['name']= values.name
        data['nickname']= values.nickname
        data['amount']= values.amount
        data['description']= values.description
        data['interval']= values.interval
        data['active']= values.active
        data['type'] = planType.RECURRING
        if(interval){
          data['interval_count']=values.interval_count
        }
        API.post(APP_URLS.ADD_PLAN, data)
        .then((res) => {
          const resp = res.data;
          _loading(false);
          if (resp.success === true) {
            let respID = resp.data.item.id;
            saveFeatures(respID)
            toast.success("Plan has been added successfully.", {
              position: toast.POSITION.TOP_RIGHT,
            });
            navigate('/plans')
          } else {
            toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(function (error) {
          const resp = error.response;
          _loading(false);
          let error_message = "";
          if (resp.data.data !== undefined && resp.data.data !== null) {
            if(resp.data.data.message){
              error_message = resp.data.data.message
            }else{
              {
                Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
              }
            }
          } else if (resp.data.data?.error !== undefined) {
            error_message = resp.data.data.error;
          } else if (resp.data?.error !== undefined) {
            error_message = resp.data.error;
          } else {
            error_message = resp.data.message;
          }
          toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
      }

    },
  });

  const saveFeatures = (id) =>{
    let data ={}
    data['feature_id'] = featureArr
    API.post(APP_URLS.ADD_FEATURES_TO_PLAN+`/${id}/features?_method=PATCH`, data)
    .then((res) => {
      const resp = res.data;
      _loading(false);
      if (resp.success === true) {
      } else {
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    })
    .catch(function (error) {
      const resp = error.response;
      _loading(false);
      let error_message = "";
      if (resp.data.data !== undefined && resp.data.data !== null) {
        {
          Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
        }
      } else if (resp.data.data?.error !== undefined) {
        error_message = resp.data.data.error;
      } else if (resp.data?.error !== undefined) {
        error_message = resp.data.error;
      } else {
        error_message = resp.data.message;
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    });
  }

  const submitForm = (e) => {
    let arr = []
    filtereddata.forEach((item)=>{
      if(item.selected===true){
        arr.push(item.id)
      }
    })
    if(arr.length>0){
      featureArr = arr
      formik.handleSubmit(e);
    }
    else{
      toast.error("Please select some features for the plan first.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };


  return (
    <>
      <PlanWrapper>
      <div className="mt-2">
          <div className="backContainer ms-4" onClick={() => navigate("/plans")}>
            <span className="backButton">x</span>
            <span className="px-3 backButton">|</span>
            <span className="backButton">Cancel</span>
          </div>
        </div>
        <form className="auctionMainContainer" 
          onSubmit={(e) => {
              submitForm(e);
              e.preventDefault();
          }}>
          <div className="headerContainer d-flex justify-content-center">
            <h1>CREATE PLAN</h1>
          </div>
          <div className="actionContainer d-flex justify-content-end">
            <button className={(loading?'disabled':'')+" btn"} type="submit" disabled={loading}>{loading?'Saving...':'Save'}</button>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-6">
              <div className="productInfo-root">
                <div className="container">
                  <div className="input-section row">
                    <div className="formInput col-md-6">
                      <label htmlFor="name" className="fieldLabel mb-2">
                        Plan Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.name && formik.errors.name ? <div className="error">{formik.errors.name}</div> : null}
                    </div>
                    <div className="formInput col-md-6">
                        <label htmlFor="name" className="fieldLabel mb-2">
                          Nickname
                        </label>
                        <input
                          type="text"
                          id="nickname"
                          name="nickname"
                          className="form-control"
                          value={formik.values.nickname}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.nickname && formik.errors.nickname ? <div className="error">{formik.errors.nickname}</div> : null}
                      </div>
                  </div>
                  <div className="input-section row">
                    <div className="formInput col-md-12">
                      <label htmlFor="description" className="fieldLabel mb-2">
                        Description
                      </label>
                      <textarea
                        rows="4"
                        id="description"
                        name="description"
                        className="form-textarea"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {/* {formik.touched.description && formik.errors.description ? <div className="error">{formik.errors.description}</div> : null} */}
                    </div>
                  </div>
                  <div className="price-section">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="formInput">
                          <label htmlFor="price" className="fieldLabel mb-2">
                            Price
                          </label>
                          <div className="inputTag">
                            <input
                              type="number"
                              id="amount"
                              name="amount"
                              step=".01"
                              className="form-control"
                              min={1}
                              value={formik.values.amount}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <p>EUR</p>
                          </div>
                          {formik.touched.amount && formik.errors.amount ? <div className="error">{formik.errors.amount}</div> : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formInput">
                          <label className="fieldLabel mb-2">Type</label>
                          <button onClick={(e)=>e.preventDefault()}>Recurring</button>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="formInput">
                          <label htmlFor="recurringInterval" className="fieldLabel mb-2">
                            Recurring Interval
                          </label>
                          <select name="interval" 
                            id="recurringInterval" 
                            className="form-control" 
                            onChange={e=>{
                              if(e.target.value==='month'){
                                _interval(true)
                              }
                              else{
                                _interval(false)
                                formik.setFieldValue("interval_count",'1')
                              }
                              formik.handleChange(e)
                              }}
                            value={formik.values.interval}
                            onBlur={formik.handleBlur}
                            >
                            <option hidden>Select</option>
                            <option value="month">Monthly</option>
                            <option value="year">Yearly</option>
                          </select>
                          {formik.touched.interval && formik.errors.interval ? <div className="error">{formik.errors.interval}</div> : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formInput">
                          <label htmlFor="recurringIntervalCount" className="fieldLabel mb-2">
                            Recurring Interval Count
                          </label>
                          <select name="interval_count" 
                            id="interval_count" 
                            className="form-control"
                            value={formik.values.interval_count}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            disabled={!interval}>
                            {Array(1,2,3,4,5,6,7,8,9,10,11,12).map((item,index)=>{
                            return <option value={item} key={index}>{item}</option>
                            })}
                          </select>
                          {formik.touched.interval_count && formik.errors.interval_count ? <div className="error">{formik.errors.interval_count}</div> : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="formInput">
                          <label htmlFor="active" className="fieldLabel mb-2">
                            Status
                          </label>
                          <select name="active" 
                            id="active" 
                            className="form-control" 
                            onChange={formik.handleChange}
                            value={formik.values.active}
                            onBlur={formik.handleBlur}
                            >
                            <option hidden>Select</option>
                            <option value='1'>Active</option>
                            <option value='0'>Inactive</option>
                          </select>
                          {formik.touched.active && formik.errors.active ? <div className="error">{formik.errors.active}</div> : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div className="col-lg-6">
              <div className="features">
                <div className="d-flex justify-content-between">
                  <span className="fieldLabel">Features</span>
                  <span>Select All <input type="checkbox" className="ms-3" 
                    checked={selectAll} 
                    onChange={()=>{
                      _selectAll(!selectAll)
                      handleAllselected()
                    }}/></span>           
                </div>
                <input type="text" onChange={(e)=>{
                  _feature(e.target.value)
                  handlefeatureChange(e.target.value)
                }} 
                  className = "searchFeature"
                  placeholder="Search Feature..."
                  onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handlefeatureChange(feature);
                    e.preventDefault()
                  }
                }}/>
                <img src={searchIcon} alt="" className="searchIcon" onClick={()=>handlefeatureChange(feature)}/>
              </div>
              <div className="featureContainer">
                {loaded?
                  filtereddata.length>0?
                    filtereddata.map((item,index)=>{
                      return <div className="featureName" key={index} title={item.description['en']}>
                      <p className="ms-4">{(item.name['en'].charAt(0).toUpperCase()+item.name['en'].slice(1)).replace("_"," ")}</p>
                      <input type="checkbox" checked={item.selected} 
                        onChange={()=>{
                          setChecked(item.id)
                        }}/>
                    </div>
                    }):
                    <div className="featureName" >
                      <p className="ms-4 norecord">No record found..</p>
                    </div>
                  :<div className="featureName" >
                    <p className="ms-4 loader">Loading..</p>
                  </div>
                }
              </div>
              </div>
            </div>
          </div>
        </form>
      </PlanWrapper>
    </>
  );
};

export default Plan;
