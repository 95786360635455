import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import Switch from "react-switch";
import { toast } from "react-toastify";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import NoRecord from "../../../assets/SharedComponents/Table/NoRecord";

const FrontOffice = (props) => {
  const [data, _data] = useState([]);
  const [loaded, _loaded] = useState(false);
  const permission = props.featuresAccess.permissions.find(
    (o) => o.slug === "update"
  );

  useEffect(() => {
    getFrontOfficeFeatures();
  }, []);

  const UpdatePermission = (perID, status) => {
    let data = {};
    data["status"] = status === true ? 1 : 0;
    API.post(
      APP_URLS.TOGGLE_PERMISSION + `/${perID}/update?_method=patch`,
      data
    )
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          toast.success(
            `Permission ${
              status === true ? "Enabled" : "Disabled"
            } Successfully.`,
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map(
              (error, index) => (error_message = resp.data.data[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getFrontOfficeFeatures = () => {
    _loaded(true);
    API.get(APP_URLS.LIST_FEATURES + `?filter[type]=2&with_permissions=true`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          const UpdateData = resp.data.items.map((item) => {
            const itemsPermission = item.permissions;
            itemsPermission.map((item) => {
              if (item.deleted_at != null) {
                item["active"] = false;
              } else {
                item["active"] = true;
              }
              return item;
            });
            return item;
          });
          _data(UpdateData);
          _loaded(false);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loaded(false);
        }
      })
      .catch(function (error) {
        _loaded(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map(
              (error, index) => (error_message = resp.data.data[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const showID = (val, arr) => {
    let id = arr.find((o) => o.slug === val);
    if (id) {
      return id.id;
    } else {
      return "-";
    }
  };

  const toggleswitcher = (id, recId) => {
    let data1 = [...data];
    if (data1.length > 0) {
      let index = data1.findIndex((x) => x.id === recId);
      let index1 = data1[index].permissions.findIndex((x) => x.id === id);
      if (data1[index].permissions[index1].active) {
        if(data1[index].permissions[index1].slug==="read"){
          let addIndex = data1[index].permissions.findIndex((x) => x.slug ==="add")
          if(addIndex!==-1){
            if(data1[index].permissions[addIndex].active===true){
              UpdatePermission(data1[index].permissions[addIndex].id, false);
            }
            data1[index].permissions[addIndex].active = false; 
          }
          let deleteIndex = data1[index].permissions.findIndex((x) => x.slug ==="delete")
          if(deleteIndex!==-1){
            if(data1[index].permissions[deleteIndex].active===true){
              UpdatePermission(data1[index].permissions[deleteIndex].id, false);
            }
            data1[index].permissions[deleteIndex].active = false; 
          }
          let updateIndex = data1[index].permissions.findIndex((x) => x.slug ==="update")
          if(updateIndex!==-1){
            if(data1[index].permissions[updateIndex].active===true){
              UpdatePermission(data1[index].permissions[updateIndex].id, false);
            }
            data1[index].permissions[updateIndex].active = false; 
          }
        }
        data1[index].permissions[index1].active = false;
        _data(data1);
      } else {
        if(data1[index].permissions[index1].slug==="delete"||data1[index].permissions[index1].slug==="update"||data1[index].permissions[index1].slug==="add"){
          let readData = data1[index].permissions.findIndex((x) => x.slug ==="read")
          if(readData!==-1){
            if(data1[index].permissions[readData].active===false){
              data1[index].permissions[readData].active = true; 
              UpdatePermission(data1[index].permissions[readData].id, true);
            }
          }
        }
        data1[index].permissions[index1].active = true;
        _data(data1);
      }
    }
  };

  return (
    <div className="container">
      <div className="d-flex align-items-center justify-content-between info-wrap">
        <div className="justify-content-end mt-1 me-2">
          <span className='text-start'>Total Records : <b>{data?.length}</b></span>
        </div>
      </div>
      <div className="tableContainer">
        <table className="table auctionTable">
          <thead className="tableHead">
            <tr className="table-row">
              <th colSpan={3} className="w-50">
                <h3>FEATURES</h3>
              </th>
              <th colSpan={4}>
                <h3>PERMISSIONS</h3>
              </th>
              <th colSpan={2} className="w-14">
                <h3>PLANS</h3>
              </th>
            </tr>
            <tr className="table-row">
              <th className="w-10">Feature Id</th>
              <th className="w-15 text-start">FEATURE NAME</th>
              <th className="w-25 text-start">Description</th>
              <th className="text-center w-80 border-left">
                <span>Id</span>
                ADD
                {/* <span>No</span> */}
              </th>
              <th className="text-center w-80">
                <span>Id</span>
                READ
                {/* <span>No</span> */}
              </th>
              <th className="text-center w-80">
                <span>Id</span>
                UPDATE
                {/* <span>No</span> */}
              </th>
              <th className="text-center w-80 border-right">
                <span>Id</span>
                DELETE
                {/* <span>No</span> */}
              </th>
              <th className="w-15 ps-5 text-start">No</th>
            </tr>
          </thead>
          {loaded ? (
            <div className="d-flex justify-content-center three-dot">
              <ThreeDots
                height="150"
                width="150"
                radius="9"
                color="#637df4"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          ) : (
            <tbody>
              {data.length > 0 ? (
                data.map((item, index) => {
                  let recID = item.id;
                  return (
                    <tr className="table-row" key={index}>
                      <td>{item.id}</td>
                      <td className="feature-name text-start">
                        {(
                          item.name["en"].charAt(0).toUpperCase() +
                          item.name["en"].slice(1)
                        ).replace("_", " ")}
                      </td>
                      <td className="w-25 text-start">
                        {item.description["en"]}
                      </td>
                      <td className="text-center border-left w-80">
                        <span>{showID("add", item.permissions)}</span>
                        <span className="d-block mt-1">
                          <Switch
                            disabled={
                              item.permissions.find((o) => o.slug === "add")
                                ?.deleted_at === undefined
                                ? true
                                : false || permission === undefined
                            }
                            onChange={() => {
                              let val = item.permissions.find(
                                (o) => o.slug === "add"
                              );
                              toggleswitcher(val?.id, recID);
                              UpdatePermission(val?.id, val?.active);
                            }}
                            checked={
                              item.permissions.find((o) => o.slug === "add")?.active !== undefined ?item.permissions.find((o) => o.slug === "add").active:false
                            }
                            className="react-switch"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            offColor="#D9D9D9"
                            onColor="#7589EC"
                          />
                        </span>
                        {/* <span className="block">243</span> */}
                      </td>
                      <td className="text-center w-80">
                        <span>{showID("read", item.permissions)}</span>
                        <span className="d-block mt-1">
                          <Switch
                            disabled={
                              item.permissions.find((o) => o.slug === "read")
                                ?.deleted_at === undefined
                                ? true
                                : false || permission === undefined
                            }
                            onChange={() => {
                              let val = item.permissions.find(
                                (o) => o.slug === "read"
                              );
                              toggleswitcher(val?.id, recID);
                              UpdatePermission(val?.id, val?.active);
                            }}
                            checked={
                              item.permissions.find((o) => o.slug === "read")?.active !== undefined ?item.permissions.find((o) => o.slug === "read").active:false
                            }
                            className="react-switch"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            offColor="#D9D9D9"
                            onColor="#7589EC"
                          />
                        </span>
                        {/* <span className="block">243</span> */}
                      </td>
                      <td className="text-center w-80">
                        <span>{showID("update", item.permissions)}</span>
                        <span className="d-block mt-1">
                          <Switch
                            disabled={
                              item.permissions.find((o) => o.slug === "update")
                                ?.deleted_at === undefined
                                ? true
                                : false || permission === undefined
                            }
                            onChange={() => {
                              let val = item.permissions.find(
                                (o) => o.slug === "update"
                              );
                              toggleswitcher(val?.id, recID);
                              UpdatePermission(val?.id, val?.active);
                            }}
                            checked={
                              item.permissions.find((o) => o.slug === "update")?.active !== undefined ?item.permissions.find((o) => o.slug === "update").active:false
                            }
                            className="react-switch"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            offColor="#D9D9D9"
                            onColor="#7589EC"
                          />
                        </span>
                        {/* <span className="block">243</span> */}
                      </td>
                      <td className="text-center border-right w-80">
                        <span>{showID("delete", item.permissions)}</span>
                        <span className="d-block mt-1">
                          <Switch
                            disabled={
                              item.permissions.find((o) => o.slug === "delete")
                                ?.deleted_at === undefined
                                ? true
                                : false || permission === undefined
                            }
                            onChange={() => {
                              let val = item.permissions.find(
                                (o) => o.slug === "delete"
                              );
                              toggleswitcher(val?.id, recID);
                              UpdatePermission(val?.id, val?.active);
                            }}
                            checked={
                              item.permissions.find((o) => o.slug === "delete")?.active !== undefined ?item.permissions.find((o) => o.slug === "delete").active:false
                            }
                            className="react-switch"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            offColor="#D9D9D9"
                            onColor="#7589EC"
                          />
                        </span>
                        {/* <span className="block">243</span> */}
                      </td>
                      <td className="ps-5 text-start">{item.plans_count}</td>
                    </tr>
                  );
                })
              ) : (
                <NoRecord />
              )}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default FrontOffice;
