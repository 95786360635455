import React from "react";
import { DataValidateWrapper } from "./style/dataValidate";
import MainLayout from "../../../assets/Layout/MainLayout";
import search from "../../../assets/Images/icons/faSearchLite.svg";
import { toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import copy from "../../../assets/Images/icons/faLink.svg";
const DataValidate = () => {
  return (
    <>
      <MainLayout>
        <DataValidateWrapper>
          <div>
            <div className="headerContainer">
              <div className="searchContainer">
                <input type="text" className="search" />
                <img src={search} alt="search" />
              </div>
              <h1>PROSPECT DEALERS TO VALIDATE</h1>
              <div className="actionContainer">
                <button className="btn">CANCEL</button>
                <button className="actionBtn">SAVE</button>
              </div>
            </div>
            <div className="tableContainer">
              <table className="auctionTable">
                <thead className="tableHead">
                  <tr className="table-row">
                    <th>NAME</th>
                    <th>ADDRESS</th>
                    <th>
                      LAT
                      <br /> LONG
                    </th>
                    <th>VAT</th>
                    <th>PHONE1</th>
                    <th>PHONE2</th>
                    <th>EMAIL</th>
                    <th>WEB</th>
                    <th>PRODUCT CATEGORY ID</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="table-row">
                    <td id="dealer-table0">Mercedes</td>
                    <td id="dealer-table1">STREET, NUMBER ZIP LOCALITY</td>
                    <td id="dealer-table2">
                      <div class="prod-id">
                        <span>
                          12343223244323                          
                        </span>
                        <CopyToClipboard text="copy">
                            <img
                              src={copy}
                              alt=""
                              style={{
                                cursor: "pointer",
                                marginLeft: "10px",
                              }}
                              onClick={() => toast("Copied")}
                            />
                          </CopyToClipboard>
                      </div>
                      <div class="prod-id">
                        <span>
                          12343223244323                          
                        </span>
                          <CopyToClipboard text="copy">
                            <img
                              src={copy}
                              alt=""
                              style={{
                                cursor: "pointer",
                                marginLeft: "10px",
                              }}
                              onClick={() => toast("Copied")}
                            />
                          </CopyToClipboard>
                      </div>
                    </td>
                    <td id="dealer-table3">
                      <div class="prod-id">
                        <span class="me-1">BE</span>
                        <span>
                          343223244323                      
                        </span>
                        <CopyToClipboard text="copy">
                            <img
                              src={copy}
                              alt=""
                              style={{
                                cursor: "pointer",
                                marginLeft: "10px",
                              }}
                              onClick={() => toast("Copied")}
                            />
                          </CopyToClipboard>
                        </div>                      
                    </td>
                    <td id="dealer-table4">+343223244323</td>
                    <td id="dealer-table4">+343223200000</td>
                    <td id="dealer-table5">
                      <div class="prod-id">
                        <span>
                          INFO@AZERT.CO                        
                        </span>
                        <CopyToClipboard text="copy">
                          <img
                            src={copy}
                            alt=""
                            style={{
                              cursor: "pointer",
                              marginLeft: "10px",
                            }}
                            onClick={() => toast("Copied")}
                          />
                        </CopyToClipboard>
                      </div>                      
                    </td>
                    <td id="dealer-table6">
                      <div class="prod-id">
                        <span>
                          https://www.abx.com                          
                        </span>
                        <CopyToClipboard text="copy">
                          <img
                            src={copy}
                            alt=""
                            style={{
                              cursor: "pointer",
                              marginLeft: "10px",
                            }}
                            onClick={() => toast("Copied")}
                          />
                        </CopyToClipboard>
                      </div>                    
                    </td>
                    <td id="dealer-table7">1</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </DataValidateWrapper>
      </MainLayout>
    </>
  );
};

export default DataValidate;
