import { Box, Button, IconButton, TextField } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components' 
import API from '../../../api/axios';
import { APP_URLS } from '../../../api/url';
import { toast } from 'react-toastify'; 
import excelfile from './example.csv'
import { useNavigate } from 'react-router-dom';
import { PROVIDERS } from '../../../config/constant';
import moment from 'moment';
import { calctime } from '../../../core/utils/getAmsterdamTime/getTime';
import back from "../../../assets/Images/icons/svgIcon/back-arrow.svg";

function Volkswagen() { 

  const ref = useRef() 
  const observer = useRef();
  const navigate = useNavigate()
  const [auctionId , _auctionId] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [isOpen , _isOpen ] = useState(false)
  const [auctionSearch , _auctionSearch] = useState('')
  const [isSearching  , _isSearching ] = useState(false)
  const [auctions ,_auctions] = useState({
    data: [],
    loading: false,
    error: "",
    totalPage: 1,
    currentPage: 1,
  })
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) { 
        _isOpen(false);
      }
    };

    // Attach the event listener to the document
    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);


  useEffect(()=>{
    getAuctionList();
  },[auctions.currentPage])

  useEffect(()=>{
    let timer = null; 
    if(auctionSearch.length > 0){
      timer = setTimeout(()=>{
        getAuctionList();
      },500) 
    }

    return ()=>clearTimeout(timer)
  },[auctionSearch])


  const getAuctionList = () => {
    API.get(
      `${APP_URLS.AUCTION_LIST}?per_page=${50}&page=${
         auctions.currentPage 
      }&keyword=${auctionSearch || ""}`
    )
      .then((res) => {
        const resp = res.data;
        if (resp.success) {
          let data = resp.data.data; 
          _auctions({
            ...auctions,
            data: data,
            currentPage: resp.data.current_page,
            error: "",
            totalPage: resp.data.last_page,
            loading: false,
          }); 
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map(
              (error, index) => (error_message = resp.data.errors[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        _auctions({
          ...auctions,
          loading: false,
          error: error_message,
        });
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleSubmit = ( ) => {
    const formData = new FormData();
    if(!Object.keys(auctionId)?.length || !selectedFile ){
      toast.error('Please select both values.', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
     }

    formData.append("file", selectedFile);
    formData.append("auction_id", auctionId.id);
    API.post(APP_URLS.UPLOAD_FILE, formData)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) { 
          _isSearching(false);
          _auctionId({});
          setSelectedFile(null); 
          toast.success('File uploaded added successfully', {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate(`/data-provider?mapped=0&provider_id=${PROVIDERS.find(item => item.name == 'Volkswagen').value}`)
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map(
              (error, index) => (error_message = resp.data.data[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  // if the length of a word is more than 30 than add a space between that.
  function insertSpaceAfter30(inputString) {
    let words = inputString.split(' ');

    for (let i = 0; i < words.length; i++) {
        if (words[i].length > 30) {
            words[i] = words[i].replace(/(.{30})/g, "$1 ");
        }
    }

    return words.join(' ');
} 
  return (
    <VolkswagenWrapper >
        <div className="row my-4 top-header">
              <div className="col-md-4 col-sm-4 col-xs-4 go-back  d-flex align-items-center">
                  <img src={back} className="backbtn"  onClick={()=>{ navigate(-1) }} />
              </div>
              <div className="col-md-4 col-sm-4 col-xs-4">
                <h1> Volkswagen</h1>
              </div> 
        </div>  
         <Box className='upload-wrapper d-flex gap-3 flex-wrap' > 
          <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="file"
                id="file-input"
                style={{ display: 'none' }}
                onChange={handleFileChange}
                />
              <label className='file-text' htmlFor="file-input">
                <TextField
                  fullWidth
                  variant="outlined"
                  label={selectedFile ? selectedFile.name : 'Select a file'}
                  InputLabelProps={{
                    shrink: false, // Disable the floating label effect on focus
                  }} 
                  InputProps={{
                    readOnly: true, 
                    endAdornment: ( 
                      <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      >
                          {selectedFile ? 'Change' : 'Browse'}
                        </Button> 
                    ),
                  }}
                  />
              </label>
            </div>
          <div ref={ref} className='select-wrapper' > 
            <input className='inputfield'  placeholder={'Select auction'} value={isSearching ? auctionSearch : auctionId?.auction_event?.title?.en ||''} onClick={()=>_isOpen(true)} onChange={(e)=>{_auctionSearch(e.target.value.trim()) ; _auctions(prev=>({...prev , currentPage : 1}));_isSearching (true) }} />
            { 
              isOpen ? 
              <div className='option-wrapper'>
               {
                 auctions.data?.length > 0 && auctions.data.map((item, index) => { 
                   return (
                     <div
                     key={item.id}
                     className='pointer'
                        onClick={()=>{_auctionId(item) ; _isOpen(false); _isSearching(false)}} 
                        key={item.id}
                      >
                        <h2> {insertSpaceAfter30(item.auction_event.title.en)}</h2>
                        <p>  Start time : { item?.start ? moment(calctime(item.start, `+${moment().tz('Europe/Amsterdam')._offset/60}`)).format('DD-MM-YYYY HH:mm') :'' } </p> 
                        <p>  End time : {item?.end ? moment(calctime(item.end, `+${moment().tz('Europe/Amsterdam')._offset/60}`)).format('DD-MM-YYYY HH:mm') : '' } </p> 
                      </div>
                    ); 
                  })
               }
              </div> : null
            }   
          </div> 
          <Button onClick= {handleSubmit} variant='contained'> Upload +  </Button>
         </Box>
         <Box className='excel-file'>
          <a href={excelfile}>Download sample file</a>
         </Box>
    </VolkswagenWrapper>
  )
}

const VolkswagenWrapper = styled.div`
& > .excel-file {
  a{ 
    font-size:18px;
    border:1px solid #666;
    color:black;
    border-radius:5px;
    text-decoration:none;
    padding:10px; 
  }
  margin-top:2rem;
}
.file-text{
  width:20rem;
}
.MuiOutlinedInput-root{
  height:50px;
}
.MuiFormControl-root > label{
  max-width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div{
  gap:10px;
}
.select-wrapper{
  width:20rem;
  position:relative;
  .inputfield {
    height: 50px;
    width:100%;
    display: flex;
    border:1px solid rgba(0, 0, 0, 0.23);
    border-radius:5px;
    justify-content: start;
    padding:0 10px; 
    align-items: center;
    font-size: 18px;
  }
}
 .option-wrapper{ 
  max-height:70vh;
  position:absolute;
  z-index:10;
  background:white;
  padding:0 10px;
  width:20rem;
  overflow-y:scroll;
  &>div{ 
    border:none;
    display:flex;
    flex-direction:column;
    border:1px solid rgba(0, 0, 0, 0.23);
    justify-content:start;
    padding:5px;
    align-items:start;  
    height:max-content;
    margin:15px 0;
    gap:0;
    &>h2{
      font-size:18px;
      border:none;
      margin-bottom:5px;
    }
    p{
      font-size:13px;
    }
  }
 }
 button{
  height:40px;
  margin:5px;
 }
 @media (max-width : 425px ){
   div{
    width:100%;
   }
 }
`

export default Volkswagen