import styled from "styled-components";

export const UsersWrapper = styled.div`
  .link {
    display: inline-block;
    min-width: 100px;
    max-width: 100px;
    height: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
  }
  .auctionTable {
    min-width: 1300px;
    margin-top: 20px !important;
  }
  .previewTable{
    margin-top: 20px !important;
  }
  .previewTable th,.previewTable tr.table-row td {
    width:100%;
    padding: 15px 5px;
    display: block;
    justify-content: center;
    align-self: center;
    text-align: center;
    word-break: break-word;
  }
  .scrollbox{
    max-height:50vh;
    overflow-y:scroll
  }
  .link{
    max-width:100%
  }
  .w200 {
    width: 220px;
  }
  .w240 {
    width: 240px;
  }
  .w150 {
    width: 150px;
  }
  .w24 {
    width: 240px;
  }
  .w24 {
    width: 240px;
  }
  .searchContainer {
    height: 40px;
    display: flex;
    padding: 0 15px 0 0;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  .searchContainer img {
    cursor: pointer;
    width: 20px;
  }
  .search {
    height: 35px;
    border: none;
    border-radius: 10px;
  }
  .tabBtn button {
    background: transparent;
    padding: 15px 35px;
    cursor: pointer;
    border: none;
  }
  .tabBtn {
    border: 1px solid #e9e9e9;
    border-radius: 6px;
  }
  .deletedRow {
    background: rgba(239, 71, 71, 0.1) !important;
    cursor: default !important;
  }
  .tabBtn button:hover {
    background: transparent;
    color: #5d78ff;
  }

  .activeTab {
    border: 1px solid #5d78ff !important;
    color: #5d78ff;
    border-radius: 5px;
  }
  .profile-flag {
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }
  .profileImage {
    height: 40px;
    width: 40px;
    background: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
  }
  .profileImage img {
    margin: 0 auto;
    display: flex;
    width: 38px;
    height: 38px;
    object-fit: cover;
  }
  .countryflag {
    position: absolute;
    bottom: -12px;
    right: 6px;
  }
  .invitation {
    display: flex;
    justify-content: flex-end;
  }
  .actionBtn {
    height: 25px;
    padding: 0 10px;
    margin-left: 5px;
    font-weight: 400;
    font-size: 15px;
  }
  select {
    height: 26px;
    border: 1px solid #c7c6cd;
    outline: none;
    border-radius: 5px;
    background: white;
  }
  .btn:hover {
    color: white !important;
  }
  .invite-admin {
    flex-wrap: wrap;
  }
  .action {
    background: white;
    border: none;
  }
  .reject {
    width:107px;
    color: #ff0018;
    border: 1px solid #ff0018;
    font-weight: 200;
    font-size: 12px;
    background: white;
    letter-spacing: 2px;
    padding: 4px 22px;
    border-radius: 3px;
  }
  .accept{
    width:107px;
    margin-left:10px;
    color:#5D78FF;
    border: 1px solid #5D78FF;
    font-weight:200;
    font-size:12px;
    background:white;
    letter-spacing:2px;
    padding: 4px 22px;
    border-radius:3px;
  }
  .revoked{
    width:107px;
    // margin-left:2px;
    background: red;
    color:white;
    border: 1px solid #ff0018;
    font-weight:200;
    font-size:12px;
    letter-spacing:2px;
    padding: 4px 22px;
    border-radius:3px;
  }
  .accept:hover, .reject:hover {
    transition: all 0.1s ease-in-out;
    font-weight: bold;
    box-shadow: rgb(130 131 131 / 50%) 5px 2.196px 11px;
  }
  .time-start svg {
    width: 10px;
    height: 15px;
    cursor: pointer;
  }
  .time-start {
    min-width: 120px;
  }
  .opacity-40 {
    opacity: 40%;
  }
  .w-30{
    min-width:30px;
  }
  .select-info {
    flex-wrap: wrap;
  }
  .userDetails{
    width:60%;
  }
  .backOfficelink{
    text-decoration: none;
  }
  .stripeID{
    display: inline-block;
    width: 170px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    text-align: left;
  }
  .name-text {
    display: inline-block;
    width: 150px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    text-align: left;
  }
  .width-100{
    width: 100px
  }
  .mw110{
    max-width: 100px
  }
  .fieldTitle {
    font-size: 12px;
    font-style: italic;
    font-weight: 200;
    color: rgb(151, 151, 151);
  }
  .MuiAutocomplete-root{
    min-width:250px !important;
    max-width:260px !important;
  }
  .MuiInputBase-formControl{
    padding: 0px 65px 0px 0px !important;
  }
  .MuiOutlinedInput-root {
    padding: 0px !important; 
  }
  .MuiOutlinedInput-root .MuiAutocomplete-input {
      padding: 3px !important;
  }
  .MuiOutlinedInput-notchedOutline {
      border:none !important;
  }
  .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
      right: 0px !important;
  }
  .MuiChip-root{
    display:none !important;
  }
  @media (max-width: 700px) {
    .invitation {
      margin-right: 7px;
    }
  }
  @media (max-width: 699px) {
    .invite-admin {
      justify-content: center !important;
    }
  }
  @media (max-width: 475px) {
    .btn {
      margin-right: 7px !important;
    }
  }
`;
