import React, { useCallback, useEffect, useRef, useState } from 'react'  
import { PopupWrapper } from './PopupWrapper';
import { confirmAlert } from 'react-confirm-alert';
import { APP_URLS } from '../../../api/url';
import { toast } from 'react-toastify';
import API from '../../../api/axios'; 
import {FaCompany, FaFluentIcon, FaUser} from '../../../assets/Images/icon'
export default function Popup({_popup, _isAssigned ,dealerWithActionType, data ,setPage ,setData,  error , loading , hasMore , search , setSearch}) {
       
    const observer = useRef(); 
    const customComponentRef = useRef(null); 
    // set a refrence on the last element of select dropdown , when that last element comes into visibility , load next page.
    const lastItemElementRef = useCallback(
      (node) => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting && hasMore) {
            setData((prev) => {
              return { ...prev, currentPage: prev.currentPage + 1 };
            });
          }
        });
        if (node) observer.current.observe(node);
      },
      [loading, hasMore]
    );
  
    function handleSearch(e) {
      setSearch(e.target.value); 
      setData((prev) => {
        return { ...prev, currentPage: 1 };
      });
    }
  
    useEffect(() => {
      // by clicking outside of dropdown , close that dropdown
      function handleClickOutside(e) {
        if (
          customComponentRef.current &&
          !customComponentRef.current.contains(e.target)
        ) { 
          _popup(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
  
  
    const getURL = () =>{
        if(dealerWithActionType.type === 're_assign'){
            return APP_URLS.ASSIGN + `/${dealerWithActionType.id}/?_method=patch` 
          }

        return APP_URLS.ASSIGN + `/${dealerWithActionType.id}/assign` 
    }
    const handleClick = (e, item) => {
        _popup(false)
        confirmAlert({
            title: "Confirm to take Assign Action",
            message: `Are you sure you want to take this action?`,
            closeOnEscape: true,
            buttons: [
              {
                label: "Yes",
                onClick: () => {
                    const formData = new FormData();
                    formData.append('id', item.id);
                    formData.append('source', item.source);
                    let url = getURL();
                    API.post(
                        url, formData
                    )
                    .then((res) => {
                      const resp = res.data;
                      _isAssigned(prev => !prev)
                      if (resp.success === true) { 
                        toast.success(resp.message, {
                          position: toast.POSITION.TOP_RIGHT,
                        });
                      } else {
                        toast.error(resp.message, {
                          position: toast.POSITION.TOP_RIGHT,
                        }); 
                      }
                    })
                    .catch(function (error) { 
                      const resp = error.response;
                      let error_message = "";
                      if (resp.data.data !== undefined && resp.data.data !== null) {
                        {
                          Object.keys(resp.data.data).map(
                            (error, index) =>
                              (error_message = resp.data.data[error][0])
                          );
                        }
                      } else if (resp.data.data?.error !== undefined) {
                        error_message = resp.data.data.error;
                      } else if (resp.data?.error !== undefined) {
                        error_message = resp.data.error;
                      } else {
                        error_message = resp.data.message;
                      }
                      toast.error(error_message, {
                        position: toast.POSITION.TOP_RIGHT,
                      });
                    });
                    

                },
              },
              {
                label: "No",
              },
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            overlayClassName: "overlay-custom-class-name",
          });
    };
  
    const arrowStyle = {
      color:'gray'
    } 

  const showBadge = (source) => {
      if(source === 'subsidiary') return <FaCompany /> 
      return <FaUser/>
  }
  return (
    <PopupWrapper>
    <div   className="modal fade show custom-modal modal-backdrop">
    <div className="modal">
      <div ref={customComponentRef}  className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Select :</h5>
          </div>
          <div className="modal-body">
   
          <div className="custom-select-wrapper"> 
      <div className="select-input-wrapper">
        <input
          placeholder={'Search By Name' }
          type="text"
          value={  search ||''} 
          onChange={handleSearch}
          ></input>  
          </div> 
          {
            data &&  data.length > 0 ?  
              <div className="filter-values-wrapper">
            { data.map((item, index) => {
                if (data.length === index + 1) {
                  return (
                    <div
                      className='popup-item'
                      onClick={(e) => handleClick(e, item)}
                      ref={lastItemElementRef}
                      key={item.id}
                    >
                    {item.name}  {showBadge(item.source)}
                    </div>
                  );
                } else {
                  return (
                    <div className='popup-item' onClick={(e) => handleClick(e, item)} key={item.id}>
                      {item.name} {showBadge(item.source)}
                    </div>
                  );
                }
              })
             
            }
              {
                loading && <div>Loading ...</div>
              }
              {
                error && <div>Error ...</div>
              } 
          </div> 
        :
        <p>{search !== '' ? 'No Data Found, Please Clear Search' :'No Data Found'} </p>  
        }
      </div>
          </div>
          <div className="d-flex justify-content-end p-3">
          <button
                className={(loading?"disabled":"")+" btn cancel-btn btn-border"}
                onClick={() => _popup(false)}
                disabled={loading}
              >
                Cancel
              </button>
              
          </div>
        </div>
      </div>
    </div>
  </div>
  </PopupWrapper>
  )
}