import moment from "moment";

export const getAmstoBrowserTime = () =>{
    let todayDate = new Date()

    //Current date timestamp
    let date = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate()).getTime()
    //Current Time of Amsterdam
    let time = moment().tz('Europe/Amsterdam').format('HH:mm:ss')
    let timevalues = time.split(':')
    let seconds = Number(timevalues[0])*60*60 + Number(timevalues[1])*60 + Number(timevalues[2])
    //Adding amsterdam time to current date
    return new Date(new Date(date).setSeconds(new Date(date).getSeconds() + seconds))
  }