import React from 'react'
import { APP_URLS } from '../../../api/url';
import { Button } from '@mui/material';
import API from '../../../api/axios';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';

function Unassign({id,_isAssigned}) {
 
    const handleClick = () => { 
        confirmAlert({
            title: "Confirm to take Un-assign Action",
            message: `Are you sure you want to take this action?`,
            closeOnEscape: true,
            buttons: [
              {
                label: "Yes",
                onClick: () => { 
                    let url = `${APP_URLS.ASSIGN}/${id}/unassign`;
                    API.post( url )
                    .then((res) => {
                      const resp = res.data;
                      _isAssigned(prev => !prev)
                      if (resp.success === true) { 
                        toast.success(resp.message, {
                          position: toast.POSITION.TOP_RIGHT,
                        });
                      } else {
                        toast.error(resp.message, {
                          position: toast.POSITION.TOP_RIGHT,
                        }); 
                      }
                    })
                    .catch(function (error) { 
                      const resp = error.response;
                      let error_message = "";
                      if (resp?.data?.data) { 
                          Object.keys(resp.data.data).map(
                            (error, index) =>
                              (error_message = resp.data.data[error][0])
                          ); 
                      } else if (resp?.data?.data?.error) {
                        error_message = resp.data.data.error;
                      } else if (resp?.data?.error) {
                        error_message = resp.data.error;
                      } else {
                        error_message = resp.data.message;
                      }
                      toast.error(error_message, {
                        position: toast.POSITION.TOP_RIGHT,
                      });
                    }); 

                },
              },
              {
                label: "No",
              },
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            overlayClassName: "overlay-custom-class-name",
          });
    };
  return <Button
            onClick={handleClick}
            variant="contained"
            className='bg-danger'

            >
            Un-assign
        </Button>
}

export default Unassign
