import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { TableWrapper } from "../style/table";
import { userTypeName } from "../../../config/constant";

const SideTranslate = () => {
  const [menuOpened, _menuOpened] = useState("Languages");
  const [currMenu, _currMenu] = useState("");

  let navigate = useNavigate();

  useEffect(() => {
    _currMenu(localStorage.getItem("Translation"));
  }, [menuOpened]);

  const handleMenu = (val) => {
    if(currMenu!==val){
      _currMenu(localStorage.setItem("Translation", val));
      _menuOpened(val);
    }
  };

  return (
    <>
      <TableWrapper>
        <div>
          <ul className="sidebar-section ps-0">
            <li>
              <h5
                className={currMenu === "Languages" ? "activeMenu" : ""}
                onClick={() => {
                  _menuOpened("Languages");
                  navigate("/translation");
                  handleMenu("Languages");
                }}
              >
                Languages
              </h5>
            </li>
            <li>
              <h5
                className={currMenu === "Location" ? "activeMenu" : ""}
                onClick={() => {
                  handleMenu("Location");
                  _menuOpened("Location");
                }}
                role="button"
              >
                Location
              </h5>
              <ul className={currMenu === "Location" ? "mb-2 ps-3 company" : "mt-2 close"}>
                <li>
                  <NavLink to="/translation/countries">Countries</NavLink>
                </li>
                <li>
                  <NavLink to="/translation/continents">Continents</NavLink>
                </li>
              </ul>
            </li>
            <li>
              <h5
                className={currMenu === "Company" ? "activeMenu" : ""}
                onClick={() => {
                  handleMenu("Company");
                  _menuOpened("Company");
                }}
                role="button"
              >
                {userTypeName.DEALER}
              </h5>
              <ul className={currMenu === "Company" ? "mb-2 ps-3 company" : "mt-2 close"}>
                <li>
                  <NavLink to="/translation/department">Department</NavLink>
                </li>
                <li>
                  <NavLink to="/translation/feature">Feature</NavLink>
                </li>
                <li>
                  <NavLink to="/translation/vehicle-product-category">Product Category</NavLink>
                </li>
                <li>
                  <NavLink to="/translation/services">Services</NavLink>
                </li>
                <li>
                  <NavLink to="/translation/status">Status</NavLink>
                </li>
              </ul>
            </li>
            <li>
              <h5
                className={currMenu === "Partners" ? "activeMenu" : ""}
                onClick={() => {
                  handleMenu("Partners");
                  _menuOpened("Partners");
                }}
                role="button"
              >
                Partners
              </h5>
              <ul className={currMenu === "Partners" ? "mb-2 ps-3 company" : "mt-2 close"}>
                <li>
                  <NavLink to="/translation/partner-options">Partner Options</NavLink>
                </li>
              </ul>
            </li>
            <li> 
               <NavLink to="/translation/contact-options">Contact Options</NavLink> 
            </li>
            <li></li>
            <li>
              
              <h5
                className={currMenu === "Vehicle" ? "activeMenu" : ""}
                onClick={() => {
                  handleMenu("Vehicle");
                  _menuOpened("Vehicle");
                }}
                role="button"
              >
                Vehicle
              </h5>
              <ul className={currMenu === "Vehicle" ? "mb-2 ps-3 company" : "mt-2 close"}>
                <li>
                  <NavLink to="/translation/vehicle-battery-position">Battery position</NavLink>
                </li>
                {/* <li>
                  <NavLink to="/translation/vehicle-battery-types">Battery type</NavLink>
                </li> */}

                <li>
                  <NavLink to="/translation/vehicle-body">Body</NavLink>
                </li>
                <li>
                  <NavLink to="/translation/vehicle-brakes">Brakes</NavLink>
                </li>
                <li>
                  <NavLink to="/translation/vehicle-color">Color</NavLink>
                </li>
                <li>
                  <NavLink to="/translation/vehicle-conditions">Conditions</NavLink>
                </li>
                <li>
                  <NavLink to="/translation/vehicle-driving-wheel">Driving wheels</NavLink>
                </li>
                <li>
                  <NavLink to="/translation/vehicle-drive-types">Drive types</NavLink>
                </li>
                <li>
                  <NavLink to="/translation/vehicle-engine">Engines</NavLink>
                </li>
                <li>
                  <NavLink to="/translation/vehicle-engine-position">Engine position</NavLink>
                </li>
                <li>
                  <NavLink to="/translation/vehicle-equipments">Equipments</NavLink>
                </li>
                <li>
                  <NavLink to="/translation/vehicle-equipments-category">Equipments category</NavLink>
                </li>
                <li>
                  <NavLink to="/translation/vehicle-expertise">Expertise</NavLink>
                </li>
                <li>
                  <NavLink to="/translation/vehicle-frame-sizes">Frame sizes</NavLink>
                </li>
                <li>
                  <NavLink to="/translation/vehicle-fuel">Fuel</NavLink>
                </li>
                <li>
                  <NavLink to="/translation/vehicle-gearbox">Gear box</NavLink>
                </li>
                <li>
                  <NavLink to="/translation/vehicle-interiors">Interior coverage</NavLink>
                </li>
                <li>
                  <NavLink to="/translation/vehicle-materials">Materials</NavLink>
                </li>
                <li>
                  <NavLink to="/translation/vehicle-product-types">Product Type</NavLink>
                </li>
                <li>
                  <NavLink to="/translation/vehicle-sex">Sex</NavLink>
                </li>
                <li>
                  <NavLink to="/translation/vehicle-suspensions">Suspensions</NavLink>
                </li>
                <li>
                  <NavLink to="/translation/vehicle-target-audiences">Target audiences</NavLink>
                </li>
              </ul>
            </li>
            <li>
              <h5
                className={currMenu === "Validation" ? "activeMenu" : ""}
                onClick={() => {
                  handleMenu("Validation");
                  _menuOpened("Validation");
                }}
                role="button"
              >
                Validation Text
              </h5>
              <ul className={currMenu === "Validation" ? "mb-2 ps-3 company" : "mt-2 close"}>
                <li>
                  <NavLink to="/translation/front-office-text">Front Office</NavLink>
                </li>
                <li>
                  <NavLink to="/translation/back-office-text">Nomad</NavLink>
                </li>
              </ul>
            </li>
            <li>
              <h5
                className={currMenu === "Censorship" ? "activeMenu" : ""}
                onClick={() => {
                  handleMenu("Censorship");
                  _menuOpened("Censorship");
                }}
                role="button"
              >
                Censorship Text
              </h5>
              <ul className={currMenu === "Censorship" ? "mb-2 ps-3 company" : "mt-2 close"}>
                <li>
                  <NavLink to="/translation/user-behavior-text">User Behavior</NavLink>
                </li>
                <li>
                  <NavLink to="/translation/subsidiary-behavior-text">{`${userTypeName.DEALER} Behavior`}</NavLink>
                </li>
                <li>
                  <NavLink to="/translation/forum-behavior-text">{`Forum Behavior`}</NavLink>
                </li>
              </ul>
            </li>
            <li>
              <h5
                className={currMenu === "Ads" ? "activeMenu" : ""}
                onClick={() => {
                  handleMenu("Ads");
                  _menuOpened("Ads");
                }}
                role="button"
              >
                Ads Removal
              </h5>
              <ul className={currMenu === "Ads" ? "mb-2 ps-3 company" : "mt-2 close"}>
                <li>
                  <NavLink to="/translation/ad-removal-reasons">Ad Removal Reasons</NavLink>
                </li>
              </ul>
            </li>
            <li>
              <h5
                className={currMenu === "CreditHistory" ? "activeMenu" : ""}
                onClick={() => {
                  handleMenu("CreditHistory");
                  _menuOpened("CreditHistory");
                }}
                role="button"
              >
                Credit History Reasons
              </h5>
              <ul className={currMenu === "CreditHistory" ? "mb-2 ps-3 company" : "mt-2 close"}>
                <li>
                  <NavLink to="/translation/credit-history-reasons">Credit History Reasons</NavLink>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </TableWrapper>
    </>
  );
};

export default SideTranslate;
