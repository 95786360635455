import { Button, Tooltip } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { ThreeDots } from 'react-loader-spinner';
import SearchKey from '../../../../assets/SharedComponents/Search/SearchKey';
import attach from "../../../../assets/Images/icons/svgIcon/Attach.svg";
import flag1 from "../../../../assets/Images/icons/svgIcon/flag1.svg";
import avatar1 from "../../../../assets/Images/icons/profile-pic.png";
import { FaFilterIcon } from "../../../../assets/Images/icon";
import Header from './Header';
import FiltersSubscription from './filtersSubscription';
import faHide from "../../../../assets/Images/icons/svgIcon/FaHide.svg"
import API from "../../../../api/axios";
import { APP_URLS } from '../../../../api/url';
import { toast } from 'react-toastify';
import RecordCount from '../../../../assets/SharedComponents/RecordCount/RecordCount';
import NoRecord from '../../../../assets/SharedComponents/Table/NoRecord';
import Pagination from '../../../../assets/SharedComponents/Pagination';
import moment from 'moment';
import * as Constant from "../../../../config/constant";
import download from 'downloadjs';
import searchIcon from "../../../../assets/Images/icons/faSearchLite.svg";
import { getSum } from '../../../../core/utils/getSum/getSum';
import StaticFilter from '../../../../assets/SharedComponents/Filters/StaticFilter';
import { constructQueryStringFromIds, formatCurrency } from '../../../../core/utils';

const invoiceStatusOptions = {
    "Paid": "paid",
    "Open": "open",
    "Void": "void"
};
const invoiceStatusOptionsList =  Object.keys(invoiceStatusOptions).map((key)=> ({ name: key  , value : invoiceStatusOptions[key] }))

const SubscriptionTable = ({nomads, taxRates, tabView, setTabView, showFilters,_showFilters, _action, action}) => {
    const [countries,_countries] = useState(JSON.parse(localStorage.getItem("countries")));
    const [filteredCountries,_filteredCountries] = useState(JSON.parse(localStorage.getItem("countries")));

    const [data,_data] = useState([])
    const [calData,_calData] = useState('')
    const [count,_count] = useState(0)
    const [searchkeyword, _searchkeyword] = useState("");
    const [searchInvoicekeyword, _searchInvoicekeyword] = useState("");
    const [status, _status] = useState("");
    const [trashed, _trashed] = useState(true);

    const [showData, _showData] = useState(false)
    const [countryCode, _countryCode] = useState("");
    const [vat, _vat] = useState("");
  
    const [filters, _filters] = useState(false);
    const [loaded, _loaded] = useState(false);
  
    const [page, setPage] = useState(1);
    const [totalPage, _totalPage] = useState(0);
    const [countValue, _countValue] = useState('');

    //SideBarFilters
    const [nomadName, _nomadName] = useState("");
    const [country, _country] = useState("");
    const [startDate,_startDate] = useState('')
    const [endDate,_endDate] = useState('')
    const [commissionVAT, _commissionVAT] = useState("")
    const [planInvoiceVAT, _planInvoiceVAT] = useState("")
    const [salesCommission, _salesCommission] = useState("")
    const [stripeFeeInvoice, _stripeFeeInvoice] = useState("")
    const isInitialMount = useRef(true);
    const [selectedIds, setSelectedIds] = useState([]);
    const [allInvoices, _allInvoices] = useState(false);

    useEffect(() => {
        if (isInitialMount.current) {
            getSubscriptions();
            isInitialMount.current = false;
        } else if (vat === "" && countryCode === "") {
            getSubscriptions();
        }
    }, [page, countValue, searchkeyword, status, nomadName, startDate, endDate, country, commissionVAT, planInvoiceVAT, salesCommission, stripeFeeInvoice, searchInvoicekeyword, trashed, countryCode, vat]);

    function getRestUrl() {
        let urlParams = {};

        urlParams['profile'] = Constant.userTypes.Dealer;
        urlParams['filter[type]'] = Constant.PAYMENT_TYPE.SUBSCRIPTION;
        if (page) urlParams['page'] = page;
        if (countValue) urlParams['per_page'] = countValue;
        if (searchkeyword) urlParams['keyword'] = searchkeyword;
        if (country) urlParams['filter[country_id]'] = country;
        if (status) urlParams['filter[status]'] = status;
        if (nomadName) urlParams['filter[nomad_user_id]'] = nomadName;
        if (startDate) urlParams['filter[subscription_invoice_plan_start_date]'] = startDate;
        if (endDate) urlParams['filter[subscription_invoice_plan_end_date]'] = endDate;
        if (commissionVAT) urlParams['filter[commission_vat_id]'] = commissionVAT;
        if (planInvoiceVAT) urlParams['filter[plan_invoice_vat_id]'] = planInvoiceVAT;
        if (salesCommission) urlParams['filter[sales_with_commission]'] = salesCommission;
        if (stripeFeeInvoice) urlParams['filter[stripe_fee_invoice]'] = stripeFeeInvoice;
        if (countryCode) urlParams['prefix_vat'] = countryCode;
        if (vat) urlParams['filter[vat_number]'] = vat;
        if (searchInvoicekeyword) urlParams['filter[stripe_invoice_number]'] = searchInvoicekeyword;
        if (trashed !== undefined) urlParams['with_trashed'] = trashed ? 1 : 0;

        const restUrl = new URLSearchParams(urlParams);
        return restUrl.toString();
    }

    const getSubscriptions = () => {
        const apiParams = getRestUrl();
        _loaded(true)
        API.get(`${APP_URLS.LIST_SUBSCRIPTION_INVOICE}?${apiParams}`)
            .then(res => {
                const resp = res.data;
                if (resp.success === true) {
                    _data(resp.data.subscriptionInvoices.data)
                    _totalPage(resp.data.subscriptionInvoices.last_page)
                    _calData(resp.data.totalAmount)
                    _count(resp.data.subscriptionInvoices.total)
                    _loaded(false)
                }
                else {
                    toast.error(resp.message, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    _loaded(false)
                }
            })
            .catch(function (error) {
                _loaded(false)
                const resp = error.response;
                let error_message = '';
                if (resp.data.errors !== undefined) {
                    {
                        Object.keys(resp.data.errors).map(
                            (error, index) => (error_message = resp.data.errors[error][0])
                        );
                    }
                } else if (resp.data.data?.error !== undefined) {
                    error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                    error_message = resp.data.error
                } else {
                    error_message = resp.data.message
                }
                toast.error(error_message, {
                    position: toast.POSITION.TOP_RIGHT
                })
            });
    }

    const getAttachment = (url) =>{
        API.get(url,{responseType: 'blob'}) 
        .then(res => {
            const resp = res.data;
            if(resp!==null){
                toast.success("Downloading. Please wait", {
                    position: toast.POSITION.TOP_RIGHT
                  })
                const content = res.headers['content-type'];
                download(resp, `invoice.pdf`, content)
            }
            else{
              toast.error(resp.message, {
                position: toast.POSITION.TOP_RIGHT
              })
            }
          })
        .catch(function (error) {  
          const resp = error.response;
          if(error.response){
            toast.error("Something went wrong", {
                position: toast.POSITION.TOP_RIGHT
            })
          }
        });
    }

    const filterData = (value) => {
        let search = value.toUpperCase()
        if (value.length > 0) {
          let newData = [...countries]
          let val = newData.filter(o => (o.country_name).includes(search))
          _filteredCountries(val)
        }
        else {
          _filteredCountries(countries)
        }
      }

      const setValue = (val) => {
        document.getElementById('countryCode').value = val.country_name
        _countryCode(val.country_name)
        setTimeout(()=>document.getElementById("vatField").focus(),200)
      }
  
    const handleSearchChange = (func, searchValue) => {
        func(searchValue);
        if (searchValue !== "") {
            _totalPage(0)
            setPage(1);
        }
      };
  
    const clearFilter = () => {
      _filters(true)
      _status('')
      _vat('')
      _trashed(true)
      document.getElementById('countryCode').value = ''
      _countryCode('')
      handleSearchChange(_countValue, '');
      handleSearchChange(_searchkeyword, '');
      handleSearchChange(_searchInvoicekeyword, '');
      if (searchkeyword !== ""||status !== ""||countryCode !== ""||vat !== ""||searchInvoicekeyword !== ""||trashed!==true) {
        _totalPage(0)
        setPage(1);
      }
    };

    //to download excel extract report for this page
    const handleExtractReport = () => {
        const apiurl = getRestUrl()
        API.get(`${APP_URLS.LIST_SUBSCRIPTION_INVOICE}/download_excel_file?${apiurl}`, { responseType: 'blob' })
            .then(res => {
                const resp = res.data;
                if (resp !== null) {
                    toast.success("Downloading. Please wait", {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    const content = res.headers['content-type'];
                    const currentDate = moment().format('YYYY-MM-DD HH-mm-ss');
                    const fileName = `${currentDate}_subscription_invoices_report.xlsx`
                    download(resp, fileName, content)
                }
                else {
                    toast.error(resp.message, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
            })
            .catch(function (error) {
                const resp = error.response;
                let error_message = '';
                if (resp.data.errors !== undefined) {
                    {
                        Object.keys(resp.data.errors).map(
                            (error, index) => (error_message = resp.data.errors[error][0])
                        );
                    }
                } else if (resp.data.data?.error !== undefined) {
                    error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                    error_message = resp.data.error
                } else {
                    error_message = resp.data.message
                }
                toast.error(error_message, {
                    position: toast.POSITION.TOP_RIGHT
                })
            });
    }

    //to download zip file for invoices
    const handleDownloadInvoiceZip = () => {
        if (selectedIds.length === 0) {
            toast.error("Please select at least one invoice to download.", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }
    
        //constructing params for multiple invoices based on id's
        const selectedIdsParams = constructQueryStringFromIds(selectedIds, 'id');
    
        API.get(`${APP_URLS.LIST_SUBSCRIPTION_INVOICE}/download_zip_file?type=${Constant.PAYMENT_TYPE.SUBSCRIPTION}&${selectedIdsParams}`)
            .then(res => {
                const resp = res.data;
                if (resp.success) {
                    toast.success(resp.message, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
                else {
                    toast.error(resp.message, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
            })
            .catch(function (error) {
                const resp = error.response;
                let error_message = '';
                if (resp.data.errors !== undefined) {
                    {
                        Object.keys(resp.data.errors).map(
                            (error, index) => (error_message = resp.data.errors[error][0])
                        );
                    }
                } else if (resp.data.data?.error !== undefined) {
                    error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                    error_message = resp.data.error
                } else {
                    error_message = resp.data.message || "Something went wrong"
                }
                toast.error(error_message, {
                    position: toast.POSITION.TOP_RIGHT
                })
            });
    }

    //handles particular selecting checkbox for invoice download in zip format
    const handleCheckboxChange = (id) => {
        setSelectedIds(prevIds => 
        prevIds.includes(id) 
            ? prevIds.filter(item => item !== id) 
            : [...prevIds, id]
        );
    };

    //handles selecting all checkbox for invoice download in zip format
    const handleSelectAll = () => {
        if (allInvoices) {
            setSelectedIds([]);
        } else {
        setSelectedIds(data.map(item => item.id));
        }
        _allInvoices(!allInvoices);
    };

  return (
    <div className="auctionMainContainer">
        <div className="row mt-4 position-relative">
            <img src={faHide} alt="filters" className={(showFilters?"open":"close")+" filterbtn"} onClick={()=>_showFilters(!showFilters)}/>
            <div className={(showFilters===true?"show":"hide") + " col-md-3 col-lg-2 mb-5"}>
                <FiltersSubscription 
                    nomads={nomads} taxRates={taxRates}
                    nomadName={nomadName} _nomadName={_nomadName}
                    country={country} _country={_country}
                    startDate={startDate} _startDate={_startDate}
                    endDate={endDate} _endDate={_endDate}
                    commissionVAT={commissionVAT} _commissionVAT={_commissionVAT}
                    planInvoiceVAT={planInvoiceVAT} _planInvoiceVAT={_planInvoiceVAT}
                    salesCommission={salesCommission} _salesCommission={_salesCommission}
                    stripeFeeInvoice={stripeFeeInvoice} _stripeFeeInvoice={_stripeFeeInvoice}
                    setPage={setPage} _totalPage={_totalPage}
                />
            </div>
            <div className={showFilters===true?"col-md-9 col-lg-10":"col-md-12 col-lg-12"}>
                <Header tabView={tabView} setTabView={setTabView} action={action} _action={_action} handleDownloadInvoiceZip={handleDownloadInvoiceZip} />
                <div className="col-md-12 mt-2">
                    <div className="d-flex align-items-center justify-content-end flex-wrap">
                        <div className="justify-content-end mb-2 d-flex gap-2 mb-2 flex-wrap align-items-center">
                            <div className="searchBox">
                                <div className="position-relative">
                                <input
                                    type="text"
                                    id="countryCode"
                                    className='countryCode'
                                    placeholder='Prefix'
                                    onChange={(e) => filterData(e.target.value)}
                                    onFocus={() => _showData(true)}
                                    onBlur={() => setTimeout(() => _showData(false), 200)}
                                />
                                {showData ?
                                    <ul className="searchResult">
                                    {filteredCountries.map((item, index) => {
                                        return <li className='d-flex justify-content-between align-items-center' onClick={() => setValue(item)} key={index} title={item.fullname}>
                                            <span>{item.country_name}</span>
                                            <img className="codeFlag" src={item.flag_thumbnail}/>
                                        </li>
                                    })}
                                    </ul>
                                : ''}
                                </div>
                                <input
                                    type="text"
                                    placeholder="Enter VAT Number"
                                    className={(countryCode!==""?"":"disabled")+' vatField'}
                                    disabled={!countryCode}
                                    id='vatField'
                                    value={vat}
                                    onChange={(e) => {
                                        _vat(e.target.value);
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            _totalPage(0)
                                            setPage(1)
                                            getSubscriptions()
                                        }
                                    }}
                                    onBlur={()=>{
                                        _totalPage(0)
                                        setPage(1)
                                        getSubscriptions()
                                    }}
                                />
                                <img src={searchIcon} alt="search" onClick={()=>getSubscriptions()}/>
                            </div>
                            <StaticFilter placeholder='Invoice Status' data={invoiceStatusOptionsList}  handleChange={(e) => {
                                _status(e.target.value)
                                _totalPage(0)
                                setPage(1);
                            }} value={status}/>  
                            <SearchKey
                                onSearchClick={(search) => handleSearchChange(_searchInvoicekeyword,search)}
                                id="searchData"
                                filters={filters}
                                _filters={_filters}
                                placeholder="Search by Invoice Number"
                            />
                            <SearchKey
                                onSearchClick={(search) => handleSearchChange(_searchkeyword,search)}
                                id="searchData"
                                filters={filters}
                                _filters={_filters}
                                placeholder={`Search by ${Constant.userTypeName.DEALER} Name`}
                            />
                            <RecordCount
                                onSearchClick={(search) => handleSearchChange(_countValue, search)}
                                id="pageCount"
                                filters={filters}
                                _filters={_filters} 
                            />
                            <Button onClick={handleExtractReport} className='extract-report ml-2' variant="outlined" >Extract report</Button>
                            <div className='d-flex align-items-center'>
                                <Tooltip title="Clear Filter" placement="bottom">
                                <FaFilterIcon className="filter-icon" role="button" onClick={clearFilter} />
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between info-wrap">
                        <div className="justify-content-end mt-1 me-2">
                            <span className='text-start'>Total Records : <b>{count}</b></span>
                        </div>
                        <div className='d-flex gap-3'>
                            {action && <div className="mt-1">
                                <input type="checkbox" 
                                    checked={allInvoices}
                                    indeterminate={selectedIds.length > 0 && selectedIds.length < data.length}
                                    onChange={handleSelectAll}
                                />
                                <span className="ms-2">Select all invoices</span>
                            </div>}
                            <div className="mt-1">
                                <input type="checkbox" checked={!trashed} onChange={()=>{
                                    _totalPage(0)
                                    setPage(1);
                                    _trashed(!trashed)
                                    }}/>
                                <span className="ms-2">Hide Deleted Data</span>
                            </div>
                        </div>
                    </div>
                    <div className="tableContainer">
                        <table className="auctionTable">
                            <thead className="tableHead">
                                        <tr className="table-row">
                                        <th className='maxw-90'>
                                            ID<br/>Country
                                        </th>
                                        <th className="time-start name-text">
                                            Name <br /> VAT Number <br /> Invoice Number
                                        </th>
                                        <th>Subscription Status<br/>Invoice Status</th>
                                        <th className='time-start'>
                                            Plan Name<br/>
                                            <span className='fieldTitle'>(Amount/Interval)</span><br/>
                                        </th>
                                        <th>
                                            <span className='fieldTitle'>(Invoice)</span><br/>
                                            Amount <br />
                                            <span className='fieldTitle'>(Before Discount)</span><br />
                                            Date 
                                        </th>
                                        <th>Discount Amount</th>
                                        <th>
                                            <span className='fieldTitle'>(Invoice)</span><br/>
                                            Amount <br/>
                                            <span className='fieldTitle'>(After Discount)</span><br />
                                            VAT %
                                        </th>
                                        <th className='w200'>Nomad</th>
                                        <th className='time-start'>
                                            <span className='fieldTitle'>(Commission)</span><br/>
                                            VAT %<br />
                                            Amount <br />
                                            Status
                                        </th>
                                        <th>Stripe Fee</th>
                                        <th>Balance</th>
                                        </tr>
                            </thead>
                            {loaded ? 
                            <div className='d-flex justify-content-center three-dot'>              
                            <ThreeDots 
                                height="150" 
                                width="150" 
                                radius="9"
                                color="#637df4" 
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                            </div>
                            :
                            <tbody>
                            {data.length > 0 ?
                            data.map((item)=>{
                                let name = ""
                                let url = ""
                                let flagUrl = ""
                                if(item.nomad_sale!==null){
                                    url = item.nomad_sale.user.profile_picture_url
                                    flagUrl = item.nomad_sale.user?.limited_address?.flag_thumbnail
                                    if (item.nomad_sale.user.first_name || item.nomad_sale.user.last_name) {
                                        name = `${item.nomad_sale.user.first_name !== null ? item.nomad_sale.user.first_name : ""} ${item.nomad_sale.user.last_name !== null ? item.nomad_sale.user.last_name : ''}`
                                    }
                                    else {
                                        name = item.nomad_sale.user.pseudo
                                    }
                                }
                                return <tr className={(item.deleted_at ? "deletedRow" : "") + " table-row"} key={item.id}>
                                <td className='maxw-90'>
                                    <span className='d-block'>{item.id} </span>
                                    <span>{item.subsidiary.limited_address!==null?<img src={item.subsidiary.limited_address.flag_thumbnail} alt="" className='countryflag' title={item.subsidiary.limited_address.country}/>:""}</span>
                                </td>
                                <td className='time-start name-text'>
                                    <span className='d-block' title={item.subsidiary.name.charAt(0).toUpperCase() + item.subsidiary.name.slice(1)}>{item.subsidiary.name.charAt(0).toUpperCase() + item.subsidiary.name.slice(1)}</span>
                                    <span className='d-block'>{item.subsidiary.parent_company!==null? item.subsidiary.parent_company.prefix_vat+" "+ item.subsidiary.parent_company.vat_number:""}</span>
                                    <span>{item.number!==null? item.number:""}</span>
                                </td>
                                <td >
                                    <span className={Constant.SUBSIDIARY[item.subscriptions?.stripe_status]}>{item.subscriptions?.stripe_status ? item.subscriptions.stripe_status.replace("_", " ").charAt(0).toUpperCase() + item.subscriptions.stripe_status.replace("_", " ").slice(1) : "-"}</span>
                                    <br/>
                                    <span className={Constant.INVOICE_STATUS[item.status]} >{item.status.charAt(0).toUpperCase() + item.status.slice(1)}</span>
                                    </td>
                                <td className='time-start'>
                                    {item.latest_invoice_item!==null?
                                    <>
                                        <span className='d-block'>{item.latest_invoice_item.price!==null&&item.latest_invoice_item.price.plan.name}</span>
                                        <span>{item.latest_invoice_item.price!==null? formatCurrency(item.latest_invoice_item.price.unit_amount)+"/"+item.latest_invoice_item.price.recurring_interval:"-"}</span>
                                    </>:"-"}
                                </td>
                                <td >
                                    <div className="d-flex justify-content-evenly">
                                        <div className='mw-120'>
                                            <span className="block" title='Amount Without VAT'>{formatCurrency(item.subtotal)}</span>
                                            <span className="d-block text-nowrap">{moment(item.invoice_date).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm")}</span>
                                        </div>
                                    </div>
                                </td>
                                <td>{item.discount_amount?<span className="end-status">{formatCurrency(item.discount_amount)}</span>:"-"}</td>
                                <td >
                                    <div className="d-flex justify-content-evenly">
                                        {action ? <input type='checkbox'
                                            checked={selectedIds.includes(item.id)}
                                            onChange={() => handleCheckboxChange(item.id)}
                                          /> : null}
                                        <Tooltip title="Subscription Invoice" placement="right-end">
                                            <img className={item.subscriptipn_invoice_url!==null?"attachment":"disabled"} src={attach} alt="" onClick={()=>getAttachment(item.subscriptipn_invoice_url)}/>
                                        </Tooltip>
                                        <div className='mw-120'>
                                            <span className="block" title='Amount Without VAT'>{formatCurrency(item.amountAfterDiscount)}</span>
                                            <p className="d-block">{item.subscription_invoice_vat} % 
                                                <span className='light-text'>({item.tax!==null?formatCurrency(item.tax):"-"})</span>
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td className="w200 d-flex align-items-center">
                                    {item.nomad_sale!==null?
                                    <div className="profile-flag" title={name?name:"Unavailable"}>
                                        <div className="profileImage">
                                            <img src={url?url:avatar1} />
                                        </div>
                                        {flagUrl!==""&&flagUrl!==undefined?<img className="countryflag flagIcon" src={flagUrl} />:""}
                                    </div>
                                    :"-"}
                                </td>
                                <td className="time-start">
                                    {item.nomad_sale!==null?
                                    <div className="d-flex justify-content-evenly">
                                        <Tooltip title="Commission Invoice" placement="right-end">
                                            <img className={item.nomad_invoice_url!==null?"attachment":"disabled"} src={attach} alt="" onClick={()=>item.nomad_invoice_url!==null?getAttachment(item.nomad_invoice_url):null}/>
                                        </Tooltip>  
                                        <div className='mw-120'>
                                            {item.nomad_sale.tax_rate!==null?
                                            <p className="d-block">
                                                {item.nomad_sale.tax_rate.percentage!==null?item.nomad_sale.tax_rate.percentage:"-"} % 
                                                <span className='light-text'>({item.nomad_sale.tax_amount!==null?formatCurrency(item.nomad_sale.tax_amount):"-"})</span>
                                            </p>:<p className="d-block">-</p>}
                                            <span className="block end-status">{item.nomad_sale.commission_amount!==null?formatCurrency(item.nomad_sale.commission_amount):"-"}</span>
                                            <span className={(item.commission_status==="paid"?"active-status":"progress-status")+" d-block"}>{item.commission_status?item.commission_status:"-"}</span>
                                        </div>
                                    </div>
                                    :"-"}
                                </td>
                                <td>
                                    <span className="end-status">{formatCurrency(item.stripe_fee)}</span>
                                </td>
                                <td className="balance active-status">{formatCurrency(item.balance)}</td>
                            </tr>
                            })
                            : (
                                <NoRecord />
                            )} 
                                        <tr className="table-row summary-row">
                                            <td className='maxw-90'></td>
                                            <td className="time-start name-text"></td>
                                            <td></td>
                                            <td></td>
                                            <td className='time-start'>
                                            <b>{calData!==""?formatCurrency(calData.totalAmount):"-"}</b>
                                            </td>
                                            <td><b>{calData!==""?formatCurrency(calData.totalDiscountAmount):"-"}</b></td>
                                            <td>
                                                <div className='d-flex flex-column'>
                                                    <b title='Total Subscription Amount'>{calData!==""?formatCurrency(calData.totalAmountAfterDiscount):"-"}</b>
                                                    <b title='Total Subscription VAT'>{calData!==""?formatCurrency(calData.totalSubscriptionVatAmount):"-"}</b>
                                                    <hr />
                                                    <span className='fieldTitle' title='Total Subscription Amount with VAT'>{calData!==""?"€ " +getSum(calData.totalAmountAfterDiscount,calData.totalSubscriptionVatAmount):"-"}</span>
                                                </div>
                                            </td>
                                            <td></td>
                                            <td>
                                                <div className='d-flex flex-column'>
                                                    <b title='Total Commission Amount'>{calData!==""?formatCurrency(calData.totalCommissionAmount):"-"}</b>
                                                    <b title='Total Commission VAT'>{calData!==""?formatCurrency(calData.totalCommissionVatAmount):"-"}</b>
                                                    <hr/>
                                                    <span className='fieldTitle' title='Total Commission Amount with VAT'>{calData!==""?"€ " +getSum(calData.totalCommissionAmount,calData.totalCommissionVatAmount):"-"}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <b>{calData!==""?formatCurrency(calData.totalStripeFee):"-"}</b>
                                            </td>
                                            <td>
                                                <div className='d-flex flex-column'>
                                                    <b title='Total Balance Amount'>{calData!==""?formatCurrency(calData.totalBalance):"-"}</b>
                                                    <b title='Total Balance VAT'>{calData!==""?formatCurrency(calData.totalVatBalance):"-"}</b>
                                                    <hr/>
                                                    <span className='fieldTitle' title='Total Balance Amount with VAT'>{calData!==""?"€ " +getSum(calData.totalBalance,calData.totalVatBalance):"-"}</span>
                                                </div>
                                            </td>
                                        </tr>  
                            </tbody>
                            }
                        </table>
                    </div>
                    <div>
                        {totalPage > 1 ? (
                        <Pagination
                            totalPages={totalPage}
                            page={page}
                            onPageClick={(page) => {
                            setPage(page + 1);
                            }}
                            key={totalPage}
                        />
                        ) : (
                        ""
                        )}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SubscriptionTable