import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SearchWrapper } from "./searchablestyle";

const SearchableDropdownTyre = (props) => {

    const [filteredData, _filteredData] = useState([]);
    const [value,_value] = useState('')


    useEffect(() => {
        _filteredData([])
        if(props.dropdownData){
            props.dropdownData.forEach((element) => {
                _filteredData((olddata) => [
                  ...olddata,
                  {
                    label: `${element.width?element.width:'-'}/${element.height?element.height:"-"}/${element.diameter?element.diameter:'-'}`,
                    value: element.id,
                  },
                ]);
              });
        }
      }, [props.dropdownData]);


    useEffect(() => {
      if (props.val&&props.dropdownData.length>0) {
        props.setChangeValue(props.val);
        let val = props.dropdownData.find((o) => o.id == props.val)
        _value(`${val.width?val.width:'-'}/${val.height?val.height:'-'}/${val.diameter?val.diameter:"-"}`)
      }
      else{
        _value('')
      }
    }, [props.val,props.dropdownData]);
  
    return (
      <SearchWrapper>
        <Autocomplete
          disableClearable
          className={props.className}
          options={filteredData}
          sx={{ width: 250 }}
          value={value}
          freeSolo
          forcePopupIcon={true}
          renderOption={(props, option) => (
            <li {...props} key={option.value}>
              {option.label}
            </li>
          )}
          onChange={(event, newValue) => {
            props.setChangeValue(newValue.value);
          }}
          renderInput={(params) => <TextField {...params} placeholder={props.placeholder} id={props.id?props.id:'searchableField'}/>}
        />
      </SearchWrapper>
    );
  };
  

export default SearchableDropdownTyre;
