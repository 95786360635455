import React, { useEffect, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { toast } from 'react-toastify'
import API from '../../../../api/axios'
import { APP_URLS } from '../../../../api/url'

const Popup = (props) => {

  const [reasonList,_reasonList] = useState({})
  const [commentsArr,_commentsArr] = useState([])

  const [loading,_loading] = useState(false)

  const [query,_query] = useState('')

  useEffect(()=>{
    getReasons()
  },[query])

  const getReasons = () => {
    API.get(APP_URLS.LIST_SUGGESTION_TEXT + `?filter[name]=${query}&filter[type]=4&limited_data=true`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          let data = {}
          resp.data.items.forEach((element)=>{
            data[`${element.id}_${element.name}`] = element.name
          })
          _reasonList(data);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const addcomments = (id) =>{
    let flag = false
    for (let i=0;i<commentsArr.length;i++){
      if(commentsArr[i]===id){
        flag= true
        break;
      }
    }
    if(commentsArr.length<3){
      if(flag===false){
        _commentsArr((item)=>{
          return [...item,id]
        })
      } else{
        toast.error("Reasons has been already added", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }else{
      toast.error("Maximum reasons already added", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  const removeComment = (id) =>{
    _commentsArr((item)=>{
      return item.filter((arrItem,index)=>{
        return index !== id
      })
    })
  }

  const handleDelete = () =>{
    let arr = []
    props.data.forEach(element => {
      if(element.checked===true&&element.deleted_at===null){
        arr.push(element.id)
      }
    });
    if(commentsArr.length<1){
      toast.error("Add some reasons for deletion", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    else if (arr.length < 0) {
      toast.error("If you want to delete, select some ads first", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else{
      let str = "";
      for (let i = 0; i < arr.length; i++) {
        str += `id[${i}]=` + arr[i] + "&";
      }
      for (let i = 0; i < commentsArr.length; i++) {
        str += `delete_reason_ids[${i}]=` + commentsArr[i].split("_")[0] + "&";
      }
      _loading(true)
        API.delete(APP_URLS.DELETE_AUCTION_ADS + `?${str}`)
          .then((res) => {
            const resp = res.data;
            if (resp.success === true) {
              props._dataDeleted(true);
              props._popup(false)
              arr.splice(0, arr.length)
              toast.success("Ads has been deleted successfully.", {
                position: toast.POSITION.TOP_RIGHT,
              });
              _loading(false)
            } else {
              _loading(false)
              toast.error(resp.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
          .catch(function (error) {
            _loading(false)
            const resp = error.response;
            let error_message = "";
            if (resp.data.data !== undefined && resp.data.data !== null) {
              {
                Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
              }
            } else if (resp.data.data?.error !== undefined) {
              error_message = resp.data.data.error;
            } else if (resp.data?.error !== undefined) {
              error_message = resp.data.error;
            } else {
              error_message = resp.data.message;
            }
            toast.error(error_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
    }
  }
   
  return (
    <div className="modal fade show custom-modal modal-backdrop">
      <div className="modal">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Choose Reasons :</h5>
            </div>
            <div className="modal-body">
              <div className="preview">
                <div className='p-2 mt-2 mx-4'>
                  <input
                    type="text"
                    className="form-control"
                    placeholder='Search Reason...'
                    onChange={(e) => _query(e.target.value)}
                  />
                </div>
                <div className="countriesList p-2 my-2 mx-4">
                  <div className="cardContent">
                    {Object.keys(reasonList).length > 0 ? (
                      Object.keys(reasonList).map((item, index) => {
                        return (
                          <span
                            className="comments"
                            key={index}
                            onClick={() => addcomments(item)}
                          >
                            {reasonList[item]}
                          </span>
                        );
                      })
                    ) : (
                      <span className="disabledcomment">
                        No Reasons Found
                      </span>
                    )}
                  </div>
                  <div className="cardFooter mt-2">
                    {commentsArr.length > 0 ? (
                      commentsArr.map((item, index) => {
                        return (
                          <div
                            className="d-flex align-items-center ps-3 py-1"
                            key={index}
                          >
                            <span className="pe-2">{reasonList[item]}</span>
                            <span
                              className="removeComment"
                              onClick={() => removeComment(index)}
                            >
                              x
                            </span>
                          </div>
                        );
                      })
                    ) : (
                      <div className="d-flex align-items-center ps-3 py-1">
                        <span className="pe-2 suggestion">
                          Please add some comments...
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end p-3">
              <button
                className={(loading?"disabled":"")+" btn cancel-btn btn-border"}
                onClick={() => props._popup(false)}
                disabled={loading}
              >
                Cancel
              </button>
              <button className={(loading?"disabled":"")+" btn ms-3 deleteBtn"} disabled={loading} onClick={() => handleDelete()}>
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Popup