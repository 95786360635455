import styled from "styled-components";

export const GeneralSettingsWrapper = styled.div`
.active-tab {
    background-color: #0a58ca !important;
  }
.save-btn {
  background: #fbc524;
  border-style: none;
  font-weight: 800;
  color: #fff;
}
.fieldText h5{
  font-size:16px;
}
`;