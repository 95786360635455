import styled from "styled-components";

export const CreateCommissionWrapper = styled.div`
  .headerContainer {
    margin-bottom: 30px;
  }
  .formInput {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .btn {
    background-color: #f5a623 !important;
  }
  input[type="text"] {
    margin-top: 10px;
    border: 1px solid #c7c6cd;
    border-radius: 5px;
    height: 40px;
  }

  input[type="checkbox"] {
    margin-left: 5px;
    transform: scale(1.5);
  }
  select {
    margin-top: 10px;

  }
`;
