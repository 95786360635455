import React, { useState } from "react";
import MainLayout from "../../assets/Layout/MainLayout";
import { ChangePasswordWrapper } from "./style/ChangePasswordWrapper";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { APP_URLS } from "../../api/url";
import API from "../../api/axios";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const passwordRegExp = /^((?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{8,})$/;
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      currPassword: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: Yup.object({
      currPassword: Yup.string().required("Current Password is Required"),
      password: Yup.string()
        .required("Password is Required")
        .matches(
          passwordRegExp,
          "Password must be consist of one uppercase, one lowercase, one number and minimum length at least 8 characters."
        ),
      password_confirmation: Yup.string()
        .required("Confirm Password is Required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      let data = {};
      data['password']= values.currPassword
      data['new_password']= values.password
      data['new_password_confirmation']= values.password_confirmation
      API.post(APP_URLS.UPDATE_PASSWORD, data)
      .then(res => {
          const resp = res.data;
          setLoading(false)
          if(resp){
          toast.success("Password has been updated successfuly. Please login to continue.", {
              position: toast.POSITION.TOP_RIGHT
          })
          localStorage.clear();
          window.location.reload()
          }
          else{
          toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT
          })
          }
      })
      .catch(function (error) {
          const resp = error.response;
          setLoading(false)
          let error_message = '';
          if (resp.data.data?.password !== undefined) {
              {Object.keys(resp.data.data.password).map((error, index) => (
                  error_message = resp.data.data.password[error]
              ))}
          } else if (resp.data.data?.new_password !== undefined) {
            {Object.keys(resp.data.data.new_password).map((error, index) => (
                error_message = resp.data.data.new_password[error]
            ))} 
          } else if (resp.data.data?.error !== undefined) {
               error_message =  resp.data.data.error;
          } else if(resp.data?.error!== undefined){
              error_message =  resp.data.error
          } else{
              error_message =  resp.data.message
          }
          toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT
          })
      });
    },
  });

  return (
    <ChangePasswordWrapper>
    <div className='mt-2' >
          <div className="backContainer ms-4" onClick={()=>navigate(-1)}>
            <span className='backButton'>x</span>
            <span className='px-3 backButton'>|</span>
            <span className='backButton'>Cancel</span>
          </div>
        </div>
      <div className="row">
        <div className="col-md-4 offset-md-4 ">
          <div className="headerContainer d-flex justify-content-center">
            <h1>CHANGE PASSWORD</h1>
          </div>
        </div>
      </div>
      <form className="row mt-5" onSubmit={formik.handleSubmit}>
        <div className="col-md-6 offset-md-3">
          <div className="row mb-4">
            <div className="col-md-12">
              <label
                htmlFor="productType"
                className="form-label product-type fieldLabel"
              >
                Current Password
              </label>
              <input
                type="password"
                className="form-control departmentFields"
                placeholder="Current Password"
                name="currPassword"
                value={formik.values.currPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <span className="required">
                {formik.touched.currPassword && formik.errors.currPassword}
              </span>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-md-12">
              <label
                htmlFor="productType"
                className="form-label product-type fieldLabel"
              >
                New Password
              </label>
              <input
                type="password"
                className="form-control departmentFields"
                placeholder="Password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <span className="required">
                {formik.touched.password && formik.errors.password}
              </span>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-md-12">
              <label
                htmlFor="productType"
                className="form-label product-type fieldLabel"
              >
                Confirm Password
              </label>
              <input
                type="password"
                className="form-control departmentFields"
                placeholder="Confirm Password"
                name="password_confirmation"
                value={formik.values.password_confirmation}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <span className="required">
                {formik.touched.password_confirmation &&
                  formik.errors.password_confirmation}
              </span>
            </div>
          </div>
          <div className="row my-5 d-flex justify-content-end">
            <button type="submit" className="save-btn">
              {loading?'SAVING...':'SAVE'}
            </button>
          </div>
        </div>
      </form>
    </ChangePasswordWrapper>
  );
};

export default ChangePassword;
