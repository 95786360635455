import React from "react";
import MainLayout from "../../../assets/Layout/MainLayout";
import { EditVehicleWrapper } from "../style/EditVehicle";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import Switch from "react-switch";
import { userTypeName, userTypes } from "../../../config/constant";

const titleLimit = 191; 
const EditContactOptions = () => {

  const [searchParams, setSearchParams] = useSearchParams();

  const { contactID } = useParams();
  const [languages, _languages] = useState(() => JSON.parse(localStorage.getItem("languages")));
  const [countries, _countries] = useState(() => JSON.parse(localStorage.getItem("countries")));

  const [loading,_loading] = useState(false)

  const [name,_name] = useState({ en : '', fr : ''})
  const [checked,_checked]= useState(false)
  const [type,_type]= useState('')

  const navigate = useNavigate()

  useEffect(() => {
    getContactOptions();
  }, []);

  const setValues = (data) => {
    Object.keys(data).map((item)=>{
      _name((old)=> {return {...old,[item]:data[item]}})
    })
  };

  const handleChange = (e) => {
    _checked(e)
  }

  const getContactOptions = () => {
    API.get(APP_URLS.CONTACT_OPTIONS + `/${contactID}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          setValues(resp.data.item.name);
          _checked(resp.data.item.active===1?true:false)
          _type(resp.data.item.type)
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp?.data?.data) {
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        } else if (resp?.data?.error) {
          error_message = resp.data.error;
        } else {
          error_message = resp?.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleTitle = (val, code) => {
    _name(prevState => {return {...prevState, [code]: val}})
  };

  const submitForm = () => {
    let data = {}
    data["name"] = name
    data["active"] = Number(checked)
    let flag = false;
    if((name["en"]===""||name["en"]===undefined||name["en"]===null) && (name["fr"]===""||name["fr"]===undefined||name["fr"]===null )){
      flag = false
      toast.error("Contact Option Title in English and French is mandatory", {
        position: toast.POSITION.TOP_RIGHT
      })
    } 
    else{
      flag = true
    }
    for (let lang in name) {
      let value = name[lang];
      if ( value.length > titleLimit) {
        toast.error(`Title(${lang}) must be less than ${titleLimit} characters`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return; // Stops further execution immediately
      }
    }
    if(flag===true){
      if(!loading){
        _loading(true)
        API.post(APP_URLS.CONTACT_OPTIONS + `/${contactID}?_method=patch`, data)
        .then((res) => {
          _loading(false)
          const resp = res.data;
          if (resp.success === true) {
            toast.success("Contact Option Updated Successfully.", {
              position: toast.POSITION.TOP_RIGHT,
            });
            navigate("/translation/contact-options")
              } else {
                toast.error(resp.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            })
            .catch(function (error) {
              _loading(false)
              const resp = error.response;
              let error_message = "";
              if (resp?.data?.data) {
                {
                  Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
                }
              } else if (resp?.data?.error) {
                error_message = resp.data.error;
              } else {
                error_message = resp.data.message;
              }
              toast.error(error_message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            });
       }
    }
  };

  return (
    <>
      <EditVehicleWrapper>
        <div className='mt-2' >
          <div className="backContainer ms-4" onClick={()=>navigate("/translation/contact-options")}>
            <span className='backButton'>x</span>
            <span className='px-3 backButton'>|</span>
            <span className='backButton'>Cancel</span>
          </div>
        </div>
        <div className="headerContainer">
          <h1>EDIT CONTACT OPTION</h1>
        </div>
        <div className="d-flex justify-content-between save-btn mt-3">
          <p className="fieldLabel">Title</p>
          <button className={(loading?"disabled":"")+" add-button"} onClick={submitForm} disabled={loading}>Save</button>
        </div>
        <div className="row input-fileds">
          {languages.map((item, index) => {
            const itemCode = item.code;
            const flag = countries.find((o) => o.id === item.country_id)?.flag_thumbnail;
            return (
              <div className="col-md-4" key={'edit-contact' + item.id}>
                <div className="col-md-12 mt-3">
                  <label htmlFor="title" className="form-label">
                    <span className="countryIcon px-2">
                      <img src={flag} alt="" className="countryflag" title={item.name}/>
                    </span>
                    <span className="langName">{item.code}</span>
                  </label>
                  <input
                    type="text"
                    id={itemCode}
                    name={itemCode}
                    value={name[itemCode]}
                    className="form-control departmentFields"
                    onChange={(e, code = itemCode) => {
                      handleTitle(e.target.value, code);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className="row mt-5 input-fileds">
          <div className="col-md-4">
            <div className="row">
              <div className="col-md-12">
                <label htmlFor="productType" className="form-label product-type fieldLabel">
                  Type
                </label>
                <select name="productType" id="productType" className="form-control mt-2 disabled" value={type} onChange={(e) => _type(e.target.value)} disabled>
                  <option hidden>Select Type</option>
                  <option value={userTypes.Company}>{userTypeName.DEALER}</option>
                  <option value={userTypes.Private}>{userTypeName.PRIVATE}</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-4">
              <div className="row">
                <div className="col-md-12">
                  <label htmlFor="title" className="form-label fieldLabel">
                    Is Active?
                  </label>
                  <div>
                    <Switch
                      onChange={(e) => handleChange(e)}
                      checked={checked}
                      className="react-switch mt-3"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      offColor="#D9D9D9"
                      onColor="#7589EC"
                    />
                  </div>
                </div>
              </div>
            </div>
        </div>
      </EditVehicleWrapper>
    </>
  );
};

export default EditContactOptions;
