import React from "react";
import { ImportCsvWrapper } from "./style/importCsv";
import MainLayout from "../../../assets/Layout/MainLayout";

const ImportCsv = () => {
  return (
    <>
      <MainLayout>
        <ImportCsvWrapper>
          <div>
            <div className="text-center pt-5">
              <h1>IMPORT DATA CSV FOR PROSPECT</h1>
              <div className="info-head mt-5">
                <h4>
                  Your file should be in this order and without title with a
                  semicolon separation
                </h4>
              </div>
            </div>
            <div className="tableContainer">
              <table className="auctionTable">
                <thead className="tableHead">
                  <tr className="table-row">
                    <th>NAME</th>
                    <th>ADDRESS</th>
                    <th>LAT</th>
                    <th>LONG</th>
                    <th>Country</th>
                    <th style={{ minWidth: "130px" }}>VAT_NUMBER</th>
                    <th>PHONE1</th>
                    <th>PHONE2</th>
                    <th>EMAIL</th>
                    <th>WEB</th>
                    <th style={{ minWidth: "130px" }}>PRODUCT CATEGORY ID</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="table-row">
                    <td>string</td>
                    <td>ADDRESS</td>
                    <td>LAT</td>
                    <td>LONG</td>
                    <td style={{ minWidth: "130px" }}>BE / FR / ..</td>
                    <td>string</td>
                    <td>+3247788</td>
                    <td>+33455565</td>
                    <td>EMAIL</td>
                    <td>string</td>
                    <td style={{ minWidth: "130px" }}>int</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center mt-5 import-btn">
              {/* <button>import</button> */}
              <input type="button" value="import"></input>
            </div>
          </div>
        </ImportCsvWrapper>
      </MainLayout>
    </>
  );
};

export default ImportCsv;
