import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { ThreeDots } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import API from '../../../../../api/axios';
import { APP_URLS } from '../../../../../api/url';
import close from "../../../../../assets/Images/icons/Cancel.svg";
import NoRecord from '../../../../../assets/SharedComponents/Table/NoRecord';


const Popup = (props) => {

  const [data,_data] =useState([])
  const [loaded,_loaded] = useState(false)

  useEffect(()=>{
    if(props.popup){
      getHistory()
    }
  },[props.popup])
  const getHistory =() =>{
    _loaded(true)
    API.get(APP_URLS.ACCESS_HISTORY+props.popup+"/admin_user_history")
    .then(res => {
      _loaded(false)
      const resp = res.data;
      if(resp.success===true){
        _data(resp.data.items)
      }
      else{
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })
    .catch(function (error) {  
      _loaded(false)
      const resp = error.response;
      let error_message = '';
      if (resp.data.errors !== undefined) {
        {
          Object.keys(resp.data.errors).map(
            (error, index) => (error_message = resp.data.errors[error][0])
          );
        }
      } else if (resp.data.data?.error !== undefined) {
          error_message =  resp.data.data.error;
      } else if(resp.data?.error!== undefined){
          error_message =  resp.data.error
      } else{
          error_message =  resp.data.message
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT
      })
    });
  }

  
  return (
    <div className={(props.popup?"modal fade show":"modal fade")+ " custom-modal modal-backdrop"}> 
        <div className="modal">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">Access History</h5>
                <img src={close} alt="" className="mx-2" role="button" onClick={()=>props._popup('')}/>
            </div>
            <div className="modal-body">
              <div className='preview scrollbox'>
                {loaded?
                <div className='d-flex justify-content-center three-dots'>              
                  <ThreeDots 
                    height="150" 
                    width="150" 
                    radius="9"
                    color="#637df4" 
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
                :
                <table className='previewTable w-100'>
                  <thead className='thead'>
                    <tr className='table-row'>
                      <th>Role Name</th>
                      <th>Invitation Status</th>
                      <th>
                        <span className='d-block'>Created At</span>
                        <span>Revoked At</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                  {data.length > 0 ? (
                    data.map((item, index) => {
                          return <tr className='table-row' key={index}>
                            <td>{(item.role.role_name.charAt(0).toUpperCase() + item.role.role_name.slice(1)).replace("_", " ")}</td>
                            <td className={item.status.name.en === "accepted" ? "active-status" : item.status.name.en === "in_progress" ? "progress-status" : "end-status"}>{(item.status.name.en.charAt(0).toUpperCase() + item.status.name.en.slice(1)).replace("_", " ")}</td>
                            <td>
                              <span className='d-block'>{item.created_at?moment(item.created_at).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm"):"-"}</span>
                              <span>{item.deleted_at?moment(item.deleted_at).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm"):"-"}</span>
                            </td>
                          </tr>
                    })):
                    <NoRecord />
                    }
                  </tbody>
                </table>
                }
              </div>
            </div>
            </div>
        </div>
        </div>
    </div>
  )
}

export default Popup