import React, { Fragment, useRef, useState } from "react";
import MainLayout from "../../../assets/Layout/MainLayout";
import { EditVehicleWrapper } from "../style/EditVehicle";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { data2 } from "./data2";
import SearchableDropdown from "../../../assets/SharedComponents/SearchableDropdown/SearchableDropdown";
import SearchableDropdownTyre from "../../../assets/SharedComponents/SearchableDropdown/SearchableDropdownTyre";
import SearchableObjDropdown from "../../../assets/SharedComponents/SearchableDropdown/SearchableObjDropdown";
import * as Constant from "../../../config/constant";
import { ThreeDots } from "react-loader-spinner";

const EditVersionCar = () => {

  const [name,_name] = useState({})

  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const [loading,_loading] = useState(false)

  const [fieldData, _fieldData] = useState(data2);
  const { versionCarId } = useParams();

  const [countryId, setCountryId] = useState("");
  const [modelId, setModelId] = useState("");
  const [productId, _productId] = useState("");
  const [brandId, setBrandId] = useState("");
  const [country] = useState(JSON.parse(localStorage.getItem("countries")));
  const [fuelItemId, setFuelItemId] = useState("");
  const [drivingWheelItemId, setDrivingWheelItemId] = useState("");
  const [bodyWheelItemId, setBodyWheelItemId] = useState("");
  const [modelCarId, setModelCarId] = useState("");
  const [gearBoxId, setGearBoxId] = useState("");
  const [tireSizeId, setTireSizeId] = useState([]);
  const [bodyVehicleId, setBodyVehicleId] = useState("");
  const [fuelId, setFuelId] = useState("");
  const [gearboxId, setGearboxId] = useState("");
  const [euroStandardId, setEuroStandardId] = useState("");
  const [euroStandard, setEuroStandard] = useState("");
  const [backTireSizeId, setBackTireSizeId] = useState("");
  const [frontTireSizeId, setFrontTireSizeId] = useState("");
  const [drivingWheelId, setDrivingWheelId] = useState("");
  const [spareWheel, setSpareWheel] = useState("");
  const [carDifferntialLock, setCarDifferntialLock] = useState("");
  const [brandType, setBrandType] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    getFuelId();
    getBodyWheelId();
    getGearBoxId();
    getEuroStandard();
    getDrivingWheelId();
  }, []);

  useEffect(()=>{
    if(brandId && productId) getModelCarId(); 
  },[brandId, productId])
  useEffect(() => {
    getVersionCars();
    if(productId!==''&&brandId!==''){
      getTireSizeId();
      getBrandType();
    }
  }, [productId, brandId]);

  const setValues = (data) => {
    setCountryId(data.country_id!==null?data.country_id:'');
    setChangeValue(setFuelId,data.fuel_id!==null?`${data.fuel_id}_id`:'')
    setChangeValue(setGearboxId,data.gearbox_id!==null?`${data.gearbox_id}_id`:'')
    setChangeValue(setDrivingWheelId,data.driving_wheel_id!==null?`${data.driving_wheel_id}_id`:'')
    setChangeValue(setBodyVehicleId,data.body_vehicle_id!==null?`${data.body_vehicle_id}_id`:'')
    setChangeValue(setEuroStandardId,data.euro_standard_id!==null?`${data.euro_standard_id}_id`:'')
    setCarDifferntialLock(data.car_differential_lock);
    setSpareWheel(data.spare_wheel);
    document.getElementById("productType").value = Constant.PRODUCT_TYPES[data.product_type_id];
    _productId(data.product_type_id);
    document.getElementById("model_id").value = data.model_id;
    setModelId(data.model_id);
    setFrontTireSizeId(data.front_tire_size_id);
    setBackTireSizeId(data.back_tire_size_id);
    Object.keys(data).map((val, index) => {
      if (val !== "id" && val !== "created_at" && val !== "updated_at" && val !== "deleted_at" && val !== "body_vehicle_id" && val !== "euro_standard_id") {
        let elem = document.getElementById(val)
        if(elem!==null){
          _name(prev=>{return {...prev,[val]:data[val]}})
        }
      }
    });
  };

  const getVersionCars = () => {
    API.get(APP_URLS.SHOW_VERSION_CARS + `/${versionCarId}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          setTimeout(() => {
            document.getElementById("brand_id").value = resp.data.versionCar.model.brand_id;
            setBrandId(resp.data.versionCar.model.brand_id);
            _productId(resp.data.versionCar.product_type_id);
            setValues(resp.data.versionCar);
          }, 500);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp?.data?.data !== undefined) {
          Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
        } else if (resp?.data?.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp?.data?.error !== undefined) {
          error_message = resp?.data.error;
        } else {
          error_message = resp?.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  useEffect(() => {
    let values = [...fieldData];
    if (Object.keys(error).length > 0) {
      Object.keys(error).map((item) => {
        let index = data2.findIndex((x) => x.code === item);
        values[index]["error"] = error[item];
      });
    }
    _fieldData(values);

    return () => {
      if (Object.keys(error).length > 0) {
        Object.keys(error).map((item) => {
          let index = data2.findIndex((x) => x.code === item);
          values[index]["error"] = "";
        });
      }
      _fieldData(values);
    }
  }, [error]);

  const handleTitle = (val, code) => {
    _name(prev=>{return {...prev,[code]:code==='year'?val.substr(0,4):val}})
  };

  const submitForm = () => {
    let data = {};
    data = name;
    data["country_id"] = countryId;
    data["body_vehicle_id"] = bodyVehicleId.split('_')[0];
    data["fuel_id"] = fuelId.split('_')[0];
    data["gearbox_id"] = gearboxId.split('_')[0];
    data["back_tire_size_id"] = backTireSizeId;
    data["front_tire_size_id"] = frontTireSizeId;
    data["driving_wheel_id"] = drivingWheelId.split('_')[0];
    data["spare_wheel"] = spareWheel;
    data["car_differential_lock"] = carDifferntialLock;
    data["euro_standard_id"] = euroStandardId.split('_')[0];
    let flag = false;
    if (name["version_name"] === "" || name["version_name"] === undefined || name["version_name"] === null) {
      flag = false;
      toast.error("Car Version is mandatory", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (name["year"] === "" || name["year"] === undefined || name["year"] === null) {
      flag = false;
      toast.error("Car version  year is mandatory ", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }else if (countryId === "" || countryId === undefined || countryId === null) {
      flag = false;
      toast.error("Please provide country name", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (name["front_tire_size_id"] === "" || name["front_tire_size_id"] === undefined || name["front_tire_size_id"] === null) {
      flag = false;
      toast.error("Please provide front tire size", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (name["back_tire_size_id"] === "" || name["back_tire_size_id"] === undefined || name["back_tire_size_id"] === null) {
      flag = false;
      toast.error("Please provide back tire size", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (name["fuel_id"] === "" || name["fuel_id"] === undefined || name["fuel_id"] === null) {
      flag = false;
      toast.error("Please provide fuel type", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (name["gearbox_id"] === "" || name["gearbox_id"] === undefined || name["gearbox_id"] === null) {
      flag = false;
      toast.error("Please provide gearbox type", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (name["driving_wheel_id"] === "" || name["driving_wheel_id"] === undefined || name["driving_wheel_id"] === null) {
      flag = false;
      toast.error("Please provide driving wheel type", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (name["body_vehicle_id"] === "" || name["body_vehicle_id"] === undefined || name["body_vehicle_id"] === null) {
      flag = false;
      toast.error("Please provide body vehicle", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      flag = true;
    }
    if (flag === true) {
      if(!loading){
        _loading(true)
        API.post(APP_URLS.UPDATE_VERSION_CARS + `/${versionCarId}?_method=patch`, data)
          .then((res) => {
            _loading(false)
            const resp = res.data;
            if (resp.success === true) {
              toast.success("Version Car Updated Successfully.", {
                position: toast.POSITION.TOP_RIGHT,
              });
              navigate("/vehicles-management/vehicle-versions?"+
              (query.get("product_type_id") !== null
                ? "product_type_id=" + query.get("product_type_id") + "&"
                : "") +                    
              (query.get("version_name") !== null
                ? "version_name=" + query.get("version_name") + "&"
                : "") +
              (query.get("year") !== null
              ? "year=" + query.get("year") + "&"
              : "") +
              (query.get("brand_id") !== null
                ? "brand_id=" + query.get("brand_id") + "&"
                : "") +                
              (query.get("model_id") !== null
                ? "model_id=" + query.get("model_id") + "&"
                : "") +
              (query.get("trashed") !== null
                ? "trashed=" + query.get("trashed") + "&"
                : "") +
              (query.get("per_page") !== null 
                ? "per_page=" + query.get("per_page") + "&"
                : "") +
              (query.get("page") !== null
                ? "page=" + query.get("page")
                : "")
            )
            } else {
              toast.error(resp.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
          .catch(function (error) {
            _loading(false)
            setError(error.response.data.data);
            const resp = error.response;
            let error_message = "";
            if (resp.data.data !== undefined && resp.data.data !== null) {
              Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
            } else if (resp.data.data?.error !== undefined) {
              error_message = resp.data.data.error;
            } else if (resp.data?.error !== undefined) {
              error_message = resp.data.error;
            } else {
              error_message = resp.data.message;
            }
            toast.error(error_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      }
    }
  };

  const getFuelId = () => {
    API.get(APP_URLS.FUEL_ID + `?limited_data=true`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          let data = {}
          resp.data.items.forEach((element)=>{
              data[`${element.id}_id`] = element.name
          })
          setFuelItemId(data);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getDrivingWheelId = () => {
    API.get(APP_URLS.DRIVING_WHEEL_ID + `?limited_data=true`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          let data = {}
          resp.data.items.forEach((element)=>{
              data[`${element.id}_id`] = element.name
          })
          setDrivingWheelItemId(data);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getBodyWheelId = () => {
    API.get(APP_URLS.BODY_WHEEL_ID + `?limited_data=true`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          let data = {}
          resp.data.items.forEach((element)=>{
              data[`${element.id}_id`] = element.name
          })
          setBodyWheelItemId(data);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getModelCarId = () => {
    API.get(APP_URLS.MODEL_CAR_ITEM_ID + `?filter[brand_id]=${brandId}&limited_data=true${productId ? '&filter[product_type_id]='+productId : ''}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          let data = {}
          resp.data.items.forEach((element)=>{
              data[element.id] = element.model_name
          })
          setModelCarId(data);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getGearBoxId = () => {
    API.get(APP_URLS.GEAR_BOX_ITEM_ID + `?limited_data=true`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          let data = {}
          resp.data.items.forEach((element)=>{
              data[`${element.id}_id`] = element.name
          })
          setGearBoxId(data);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getTireSizeId = () => {
    API.get(APP_URLS.FRONT_TIRE_ITEM_ID + `?filter[product_type_id]=${productId}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          setTireSizeId(resp.data.items);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getBrandType = () => {
    API.get(APP_URLS.LIST_BRANDS + `?filter[product_type_id]=${productId}&limited_data=true`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          let data = {}
          resp.data.items.forEach((element)=>{
              data[`${element.id}`] = element.name
          })
          setBrandType(data);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getEuroStandard = () => {
    API.get(APP_URLS.EURO_STANDARD + `?limited_data=true`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          let data = {}
          resp.data.items.forEach((element)=>{
              data[`${element.id}_id`] = element.name
          })
          setEuroStandard(data);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const setChangeValue = (func, item) => {
    func(item);
  }

  const setobjValue = (func, item) => {
    func(item);
  }
  
  return (
    <>
      <EditVehicleWrapper>
        <div className="mt-2">
          <div className="backContainer ms-4" onClick={() => navigate("/vehicles-management/vehicle-versions?"+
              (query.get("product_type_id") !== null
                ? "product_type_id=" + query.get("product_type_id") + "&"
                : "") +                    
              (query.get("version_name") !== null
                ? "version_name=" + query.get("version_name") + "&"
                : "") +
              (query.get("year") !== null
              ? "year=" + query.get("year") + "&"
              : "") +
              (query.get("brand_id") !== null
                ? "brand_id=" + query.get("brand_id") + "&"
                : "") +                
              (query.get("model_id") !== null
                ? "model_id=" + query.get("model_id") + "&"
                : "") +
              (query.get("trashed") !== null
                ? "trashed=" + query.get("trashed") + "&"
                : "") +
              (query.get("per_page") !== null 
                ? "per_page=" + query.get("per_page") + "&"
                : "") +
              (query.get("page") !== null
                ? "page=" + query.get("page")
                : "")
            )}>
            <span className="backButton">x</span>
            <span className="px-3 backButton">|</span>
            <span className="backButton">Cancel</span>
          </div>
        </div>
        <div className="headerContainer">
          <h1>EDIT VERSIONS</h1>
        </div>
        <div className="d-flex justify-content-between save-btn mt-3">
          <h3 className="mb-2 fieldLabel">Language</h3>
          <button className={(loading?"disabled":"")+" add-button"} onClick={submitForm} disabled={loading}>Save</button>
        </div>
        <div className="row input-fileds">
          <div className="col-md-4 mt-3">
            <div className="row">
              <div className="col-md-12">
                <label htmlFor="productType" className="form-label">
                  <span>Product Type</span>
                </label>
                <input type="text" name="productType" id="productType" className="form-control disabled" disabled />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="col-md-12 mt-3">
              <label htmlFor="title" className="form-label">
                <span className="langName">Brand Name</span>
              </label>
              <select name="brand_id" id="brand_id" className="form-control" onChange={(e) => setBrandId(e.target.value)} disabled>
                <option hidden>Select</option>
                {Object.keys(brandType).map((item, index) => {
                  return (
                    <option value={item} key={index}>
                      {brandType[item]}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-md-4">
            <div className="col-md-12 mt-3">
              <label htmlFor="title" className="form-label">
                <span className="langName">Model Name</span>
              </label>
              <select name="model_id" id="model_id" className="form-control" onChange={(e) => setModelId(e.target.value)} disabled>
                {Object.keys(modelCarId).map((item, index) => {
                  return (
                    <option value={item} key={index}>
                      {modelCarId[item]}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <Fragment>
            {fieldData.map((item, index) => {
              let itemCode = item.code;
              return (
                <div className="col-md-4" key={index}>
                  <div className="col-md-12 mt-3">
                    <label htmlFor="title" className="form-label">
                      <span className="langName">{item.label}</span>
                    </label>
                    <input
                      name={item.label}
                      type={item.type}
                      id={itemCode}
                      value={name[itemCode]}
                      className="form-control departmentFields"
                      onChange={(e, code = itemCode) => {
                        handleTitle(e.target.value, code);
                      }}
                      disabled={item.disabled}
                      hidden={item.hidden}
                    />
                    <span style={{ color: "red" }}>{item.error}</span>
                  </div>
                </div>
              );
            })}
          </Fragment>
          <div className="col-md-4">
            <div className="col-md-12 mt-3">
              <label htmlFor="title" className="form-label">
                <span className="langName">Spare Wheel</span>
              </label>
              <select name="spare_wheel" id="spare_wheel" className="form-control" value={spareWheel} onChange={(e) => setSpareWheel(e.target.value)}>
                <option hidden>Select</option>
                <option value={0}>False</option>
                <option value={1}>True</option>
              </select>
            </div>
          </div>
          <div className="col-md-4">
            <div className="col-md-12 mt-3">
              <label htmlFor="title" className="form-label">
                <span className="langName">Car Differential Lock</span>
              </label>
              <select name="car_differential_lock" id="car_differential_lock" className="form-control" value={carDifferntialLock} onChange={(e) => setCarDifferntialLock(e.target.value)}>
                <option hidden>Select</option>
                <option value={1}>True</option>
                <option value={0}>False</option>
              </select>
            </div>
          </div>
          <div className="col-md-4">
            <div className="col-md-12 mt-3">
              <label htmlFor="title" className="form-label">
                <span className="langName">Country</span>
              </label>
              <SearchableDropdown name="country_id" id="country_id" className="form-control" setChangeValue={(val) => val?setobjValue(setCountryId, val):null} val={countryId} placeholder="Select Country" dropdownData={country} />
            </div>
          </div>
          <div className="col-md-4">
            <div className="col-md-12 mt-3">
              <label htmlFor="title" className="form-label">
                <span className="langName">Front Tire Size (W/H/D)</span>
              </label>
              <SearchableDropdownTyre name="front_tire_size_id" id="front_tire_size_id" className="form-control" val={frontTireSizeId} setChangeValue={(val) => val?setobjValue(setFrontTireSizeId, val):null} placeholder="Select Front Tire Size (W/H/D)" dropdownData={tireSizeId} />
            </div>
          </div>
          <div className="col-md-4">
            <div className="col-md-12 mt-3">
              <label htmlFor="title" className="form-label">
                <span className="langName">Back Tire Size (W/H/D)</span>
              </label>
              <SearchableDropdownTyre name="back_tire_size_id" id="back_tire_size_id" className="form-control" val={backTireSizeId} setChangeValue={(val) => val?setobjValue(setBackTireSizeId, val):null} placeholder="Select Back Tire Size (W/H/D)" dropdownData={tireSizeId} />
            </div>
          </div>
          <div className="col-md-4">
            <div className="col-md-12 mt-3">
              <label htmlFor="title" className="form-label">
                <span className="langName">Fuel</span>
              </label>
              <SearchableObjDropdown name="fuel_id" id="fuel_id" className="form-control" val={fuelId} setChangeValue={(val) => val?setChangeValue(setFuelId, val):null} placeholder="Select Fuel" dropdownData={fuelItemId} />
            </div>
          </div>
          <div className="col-md-4">
            <div className="col-md-12 mt-3">
              <label htmlFor="title" className="form-label">
                <span className="langName">Gearbox</span>
              </label>
              <SearchableObjDropdown name="gearbox_id" id="gearbox_id" className="form-control" val={gearboxId} setChangeValue={(val) => val?setChangeValue(setGearboxId, val):null} placeholder="Select Gearbox" dropdownData={gearBoxId} />
            </div>
          </div>
          <div className="col-md-4">
            <div className="col-md-12 mt-3">
              <label htmlFor="title" className="form-label">
                <span className="langName">Driving Wheel</span>
              </label>
              <SearchableObjDropdown name="driving_wheel_id" id="driving_wheel_id" className="form-control" val={drivingWheelId} setChangeValue={(val) => val?setChangeValue(setDrivingWheelId, val):null} placeholder="Select Driving Wheel" dropdownData={drivingWheelItemId} />
            </div>
          </div>
          <div className="col-md-4">
            <div className="col-md-12 mt-3">
              <label htmlFor="title" className="form-label">
                <span className="langName">Body Vehicle</span>
              </label>
              <SearchableObjDropdown name="body_vehicle_id" id="body_vehicle_id" className="form-control" val={bodyVehicleId} setChangeValue={(val) => val?setChangeValue(setBodyVehicleId, val):null} placeholder="Select Body Vehicle" dropdownData={bodyWheelItemId} />
            </div>
          </div>
          <div className="col-md-4">
            <div className="col-md-12 mt-3">
              <label htmlFor="title" className="form-label">
                <span className="langName">Euro Standard</span>
              </label>
              <SearchableObjDropdown name="euro_standard_id" id="euro_standard_id" className="form-control" val={euroStandardId} setChangeValue={(val) => val?setChangeValue(setEuroStandardId, val):null} placeholder="Select Euro Standard" dropdownData={euroStandard} />
            </div>
          </div>
        </div>
      </EditVehicleWrapper>
    </>
  );
};

export default EditVersionCar;
