import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ProviderErrorWrapper } from "./style/ErrorWrapper";
import API from "../../api/axios";
import { APP_URLS } from "../../api/url";
import { toast } from "react-toastify";
import RecordCount from "../../assets/SharedComponents/RecordCount/RecordCount";
import { Button, Tooltip } from "@mui/material";
import { FaCompany, FaFilterIcon, FaUser } from "../../assets/Images/icon";
import { ThreeDots } from "react-loader-spinner";
import NoRecord from "../../assets/SharedComponents/Table/NoRecord";
import Pagination from "../../assets/SharedComponents/Pagination";
import back from "../../assets/Images/icons/svgIcon/back-arrow.svg";

import {
  DATA_PROVIDER_ERROR_FILTER,
  PROVIDERS,
  userTypeName,
} from "../../config/constant";
import { redirect } from "../../core/utils/redirect/redirect";
import ExpandableText from "../FlaggedReview/Components/ExpandableText";
import { confirmAlert } from "react-confirm-alert";
import Popup from "./components/Popup";
import StaticFilter from "../../assets/SharedComponents/Filters/StaticFilter";

const providerErrorStatusList = Object.keys(DATA_PROVIDER_ERROR_FILTER).map(
  (key) => ({ name: key, value: DATA_PROVIDER_ERROR_FILTER[key] })
);

const DataProvidersErrors = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchkeyword, _searchkeyword] = useState("");
  const [countValue, _countValue] = useState("");

  const [page, setPage] = useState(1);
  const [loaded, _loaded] = useState(false);
  const [total, _total] = useState(0);
  const [totalPage, _totalPage] = useState(0);
  const navigate = useNavigate();
  const [providerId, setProviderId] = useState(1);

  const [data, _data] = useState([]);
  const [filters, _filters] = useState(false);
  const [sort, _sort] = useState("-created_at");
  const [status, setStatus] = useState(DATA_PROVIDER_ERROR_FILTER.Error);
  const [json, setJson] = useState("");
  const searchParamsValues = useMemo(() => {
    const searchkeyword = searchParams.get("a_keyword") || "";
    const countValue = searchParams.get("count") || "";
    const page =
      searchParams.get("page") !== null
        ? parseInt(searchParams.get("page"))
        : 1;
    const status =
      searchParams.get("status") || DATA_PROVIDER_ERROR_FILTER.Error;
    return {
      searchkeyword,
      countValue,
      page,
      status,
    };
  }, [searchParams]);

  useEffect(() => {
    _searchkeyword(searchParamsValues.searchkeyword);
    _countValue(searchParamsValues.countValue);
    setPage(searchParamsValues.page);
    setStatus(searchParamsValues.status);
  }, [searchParamsValues]);

  useEffect(() => {
    getProvidersData();
  }, [page, countValue, status, sort, providerId]);

  const getProvidersData = () => {
    _loaded(true);
    let urlParams = {
      page: page,
      per_page: countValue,
      "filter[status]": status || "",
      "filter[provider_id]": providerId,
    };
    let restUrl = new URLSearchParams(urlParams);
    API.get(APP_URLS.PROVIDERS_ERRORS + `?${restUrl}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success) {
          _data(resp.data.data);
          _totalPage(resp.data.last_page);
          _total(resp.data.total);
          _loaded(false);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loaded(false);
        }
      })
      .catch(function (error) {
        _loaded(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map(
              (error, index) => (error_message = resp.data.errors[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  // handle search and per page data changes
  const handleSearchChange = (func, searchValue) => {
    func(searchValue);
    if (
      (func === _countValue && searchValue !== countValue) ||
      (func === _searchkeyword && searchValue !== searchkeyword)
    ) {
      if (searchValue !== "") {
        _totalPage(0);
        setPage(1);
      }
    }
  };

  const clearFilter = () => {
    _filters(true);
    _sort("-created_at");
    setStatus("");
    if (countValue !== "") {
      _totalPage(0);
      setPage(1);
    }
    for (var value of query.keys()) {
      const param = searchParams.get(value);
      if (param) {
        searchParams.delete(value);
        setSearchParams(searchParams);
      }
    }
  };

  const handleMapChange = (type) => {
    _totalPage(0);
    setPage(1);
    setStatus(type);
  };

  const handleRetry = (item) => {
    confirmAlert({
      title: "Confirm to take this Action",
      message: `Are you sure you want to take this action?`,
      closeOnEscape: true,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            API.post(
              `${APP_URLS.PROVIDERS_ERRORS}/${item.id}/status?_method=patch`
            )
              .then((res) => {
                const resp = res.data;
                if (resp.success === true) {
                  toast.success(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                } else {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              })
              .catch(function (error) {
                const resp = error.response;
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                  {
                    Object.keys(resp.data.data).map(
                      (error, index) =>
                        (error_message = resp.data.data[error][0])
                    );
                  }
                } else if (resp.data.data?.error !== undefined) {
                  error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                  error_message = resp.data.error;
                } else {
                  error_message = resp.data.message;
                }
                toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          },
        },
        {
          label: "No",
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-custom-class-name",
    });
  };

  const handleProviderChange = (id) => {
    _totalPage(0);
    setPage(1);
    setProviderId(id);
  };
  return (
    <ProviderErrorWrapper>
      <div className="auctionMainContainer">
        <div className="row mt-4">
          <div className="col-md-4 d-flex align-items-center">
            <img
              src={back}
              className="ms-4 backbtn"
              onClick={() =>
                navigate(
                  "/data-provider?" +
                    (searchParams.get("page") !== null
                      ? "page=" + searchParams.get("page") + "&"
                      : "") +
                    (searchParams.get("count") !== null
                      ? "count=" + searchParams.get("count") + "&"
                      : "") +
                    (searchParams.get("mapped") !== null
                      ? "mapped=" + searchParams.get("mapped") + "&"
                      : "") +
                    (searchParams.get("provider_id") !== null
                      ? "provider_id=" + searchParams.get("provider_id")
                      : "")
                )
              }
            />
          </div>
          <div className="col-md-4 ">
            <h1>Data Providers Errors</h1>
          </div>
        </div>
        <div className="justify-content-end mb-2 d-flex gap-2 mb-2 select-info align-items-center">
          <StaticFilter
            data={providerErrorStatusList}
            handleChange={(e) => {
              handleMapChange(e.target.value);
            }}
            value={status}
          />
          <StaticFilter
            data={PROVIDERS}
            handleChange={(e) => {
              handleProviderChange(e.target.value);
            }}
            value={providerId}
          />
          <div className="select-wrapper">
            <RecordCount
              onSearchClick={(search) =>
                handleSearchChange(_countValue, search)
              }
              id="pageCount"
              val={countValue}
              filters={filters}
              _filters={_filters}
            />
          </div>
          <Tooltip title="Clear Filter" placement="bottom">
            <FaFilterIcon
              className="filter-icon"
              role="button"
              onClick={clearFilter}
            />
          </Tooltip>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between info-wrap">
        <div className="justify-content-end mt-2 me-2">
          <span className="text-start">
            Total Records : <b>{total}</b>
          </span>
        </div>
      </div>
      <div className="tableContainer">
        <table className="auctionTable reviewTable">
          <thead className="tableHead">
            <tr className="table-row">
              <th className="time-start"> FileName</th>
              <th>Provider</th>
              <th className="longText">Error</th>
              <th className="time-start"> {`User/${userTypeName.DEALER}`} </th>
              <th className="time-start"> Vehicle Cipher </th>
              {status == DATA_PROVIDER_ERROR_FILTER.Error && (
                <th className="action-btns">Action</th>
              )}
            </tr>
          </thead>
          {loaded ? (
            <div className="d-flex justify-content-center three-dot">
              <ThreeDots
                height="150"
                width="150"
                radius="9"
                color="#637df4"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          ) : (
            <tbody>
              {data.length > 0 ? (
                data.map((item) => {
                  return (
                    <tr className={"table-row"} key={item.id}>
                      <td>{item.imported_dealer.filename}</td>
                      <td>
                        <span>
                          {
                            PROVIDERS.find(
                              (i) =>
                                i.value == item?.imported_dealer?.provider_id
                            ).name
                          }
                        </span>
                      </td>
                      <td className="longText">
                        <ExpandableText text={item.error || "-"} />
                      </td>
                      <td
                        onClick={() => {
                          console.log(item?.imported_dealer);
                          redirect(item?.imported_dealer);
                        }}
                        className=" link "
                      >
                        <b>
                          {item.imported_dealer?.user_id ? (
                            <>
                              {item.imported_dealer.user.name}
                              <FaUser />
                            </>
                          ) : (
                            <>
                              {item?.imported_dealer?.subsidiary?.slug}{" "}
                              <FaCompany />
                            </>
                          )}
                        </b>
                      </td>
                      <td>{item?.vehicle_cipher || ""}</td>

                      {item.status === DATA_PROVIDER_ERROR_FILTER.Error && (
                        <td className="action-btns">
                          <Button
                            onClick={() => {
                              handleRetry(item);
                            }}
                            variant="contained"
                            sx={{ textTransform: "none", fontWeight: "600" }}
                          >
                            Retry
                          </Button>
                          {item?.json_data && (
                            <Button
                              onClick={() => {
                                setJson(item.json_data);
                              }}
                              variant="contained"
                              sx={{ textTransform: "none", fontWeight: "600" }}
                            >
                              Show Data
                            </Button>
                          )}
                        </td>
                      )}
                    </tr>
                  );
                })
              ) : (
                <NoRecord />
              )}
            </tbody>
          )}
        </table>
      </div>
      <div>
        {totalPage > 1 ? (
          <Pagination
            totalPages={totalPage}
            page={page}
            key={totalPage}
            onPageClick={(page) => setPage(page + 1)}
          />
        ) : (
          ""
        )}
      </div>
      {json !== "" && <Popup setJson={setJson} json={json} />}
    </ProviderErrorWrapper>
  );
};

export default DataProvidersErrors;
