import { Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ThreeDots } from 'react-loader-spinner'
import { FaFilterIcon } from '../../assets/Images/icon'
import MainLayout from '../../assets/Layout/MainLayout'
import RecordCount from '../../assets/SharedComponents/RecordCount/RecordCount'
import SearchKey from '../../assets/SharedComponents/Search/SearchKey'
import { LoggerWrapper } from './style/logger'
import Pagination from "../../assets/SharedComponents/Pagination/index"
import Sidebar from './components/sidebar'
import API from '../../api/axios'
import { APP_URLS } from '../../api/url'
import { toast } from 'react-toastify'
import moment from 'moment'
import faHide from "../../assets/Images/icons/svgIcon/FaHide.svg"
import faDownload from "../../assets/Images/icons/svgIcon/download.svg"
import download from 'downloadjs';
import NoRecord from '../../assets/SharedComponents/Table/NoRecord'

const Logger = () => {
  
  const initialFileName = 'laravel.log'
  
  const [loaded,_loaded] =useState(false)
  const [show,_show] = useState(false) 
  const [files,_files] = useState([])
  const [fileName,_fileName] = useState(initialFileName)

  const [logs,_logs] = useState([])

  useEffect(()=>{
    getLogs()
  },[fileName])

  const getLogs =() =>{
    _loaded(true)
    API.get(APP_URLS.VIEW_ADMIN_LOGS + `?log=${fileName}`)
    .then(res => {
      _loaded(false)
      const resp = res.data;
      if(resp.success===true){
        _files(resp.data.files)
        _logs(resp.data.logs)
      }
      else{
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })
    .catch(function (error) {  
      _loaded(false)
      const resp = error.response;
      let error_message = '';
      if (resp.data.errors !== undefined) {
        {
          Object.keys(resp.data.errors).map(
            (error, index) => (error_message = resp.data.errors[error][0])
          );
        }
      } else if (resp.data.data?.error !== undefined) {
          error_message =  resp.data.data.error;
      } else if(resp.data?.error!== undefined){
          error_message =  resp.data.error
      } else{
          error_message =  resp.data.message
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT
      })
    });
  }

  const downloadLog = () =>{
    API.get(APP_URLS.DOWNLOAD_ADMIN_LOGS+`/${fileName}/download`) 
    .then(res => {
        const resp = res.data;
        if(resp!==null){
            const content = res.headers['content-type'];
            download(resp, `${fileName}.txt`, content)
        }
        else{
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT
          })
        }
      })
    .catch(function (error) {  
      const resp = error.response;
      if(error.response){
        toast.error("Something went wrong", {
            position: toast.POSITION.TOP_RIGHT
        })
      }
    });
  }

  const handleDrop =(index) =>{
    if(show!==""){
      if(index!==show){
        _show(index)
      }
      else{
        _show('')
      }
    }else{
      _show(index)
    }
  }

  return (
      <LoggerWrapper>
      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-2 px-0">
            <div className="view-menu">
              <Sidebar initialFileName ={initialFileName} files={files} fileName={fileName} _fileName={_fileName}/>
            </div>
          </div>

          <div className="col-lg-10 px-0">
            <h1 className="">SERVER LOGS</h1>
            <div className="d-flex justify-content-between add-info flex-wrap">
              <div className="add-depertment d-flex justify-content-end mb-2">
              </div>
              <div className="d-flex justify-content-end mb-2 gap-2 flex-wrap align-items-center gap-2">
                  <button className="btn" onClick={downloadLog}>
                    Download
                    <img src={faDownload} className="ms-1"/>
                  </button>
              </div>
            </div>
            <div className="tableContainer">
            <table className="auctionTable">
              <thead className="tableHead">
                <tr className="table-row">
                    <th className='box-1'>Level</th>
                    <th className='box-2'>Time</th>
                    <th className='box-4'>Description</th>
                </tr>
              </thead>
              {
                loaded ?
                  <div className='d-flex justify-content-center three-dot'>
                    <ThreeDots
                      height="150"
                      width="150"
                      radius="9"
                      color="#637df4"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                  :
                  <tbody>
                    {logs&&logs.length>0&&logs[0].date!==1?
                    logs.map((item,index)=>{
                      const myArray = item.stack.split("#");
                      return <>
                      <tr className="table-row" key={index}>
                        <td className={`text-${item.level_class} box-1`} >{(item.level).charAt(0).toUpperCase() + item.level.slice(1)}</td>
                        <td className='box-2'>{moment(item.date).format('DD-MM-YYYY HH:mm')}</td>
                        <td className='box-4'>
                          {item.text!==""?
                            <>
                              <div className='d-flex flex-row'>
                                <span className='logText' onClick={()=>_show(true)} id={`text${index}`}>{item.text}</span>
                                {item.text&&item.stack&&item.stack!==""?
                                <>
                                <img src={faHide} alt="show" className={(show===index?"close":"open")+" dropbtn ms-2"} onClick={()=>handleDrop(index)}/>
                                </>
                                :""}
                              </div>
                            </>
                          :"-"}
                        </td>
                    </tr>
                    {show===index?
                    <tr className="table-row">
                      <td className='dropped'>
                        {item.stack!==""?
                        myArray.map((item,index)=>{
                          return <span key={index} className="d-block">
                            {index!==0?
                            "#"+item:
                            item}
                            </span>
                        }):""
                      }
                      </td>
                    </tr>
                    :""}
                    </>
                    }):
                    <NoRecord />
                  }
                  </tbody>
              }
            </table>
            </div>
          </div>
        </div>
      </div>

      </LoggerWrapper>
  )
}

export default Logger