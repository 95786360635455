import React, { useEffect, useState } from "react";
import MainLayout from "../../../assets/Layout/MainLayout";
import { InvoicesWrapper } from "./style/invoices";
import Nomadtable from "./Components/Nomadtable";
import SubscriptionTable from "./Components/Subscriptiontable";
import { APP_URLS } from "../../../api/url";
import API from "../../../api/axios";
import { toast } from "react-toastify";
import CreditTable from "./Components/CreditTable";
import CommissionTable from "./Components/CommissionTable";
import { invoiceTabs } from "../../../config/constant";

const Invoices = () => {

  const [tabView, setTabView] = useState(invoiceTabs.SUBSCRIPTION);
  const [showFilters,_showFilters] = useState(true)

  const [nomads,_nomads] = useState([])
  const [taxRates,_taxRates] = useState([])
  const [action, _action] = useState(false);

  useEffect(() => {
    getNomads()
    getTaxRates()
  }, [])
  

  const getNomads = () =>{
    API.get(APP_URLS.LIST_NOMADS)
    .then(res => {
      const resp = res.data;
      if(resp.success===true){
        _nomads(resp.data.items)
      }
      else{
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })
    .catch(function (error) {  
      const resp = error.response;
      let error_message = '';
      if (resp.data.errors !== undefined) {
        {
          Object.keys(resp.data.errors).map(
            (error, index) => (error_message = resp.data.errors[error][0])
          );
        }
      } else if (resp.data.data?.error !== undefined) {
          error_message =  resp.data.data.error;
      } else if(resp.data?.error!== undefined){
          error_message =  resp.data.error
      } else{
          error_message =  resp.data.message
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT
      })
    });
  }

  const getTaxRates = () =>{
    API.get(APP_URLS.LIST_NOMADS_TAXRATES)
    .then(res => {
      const resp = res.data;
      if(resp.success===true){
        _taxRates(resp.data.items)
      }
      else{
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })
    .catch(function (error) {  
      const resp = error.response;
      let error_message = '';
      if (resp.data.errors !== undefined) {
        {
          Object.keys(resp.data.errors).map(
            (error, index) => (error_message = resp.data.errors[error][0])
          );
        }
      } else if (resp.data.data?.error !== undefined) {
          error_message =  resp.data.data.error;
      } else if(resp.data?.error!== undefined){
          error_message =  resp.data.error
      } else{
          error_message =  resp.data.message
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT
      })
    });
  }

  return (
    <InvoicesWrapper>
      {
        tabView === invoiceTabs.NOMAD ? <Nomadtable tabView={tabView} setTabView={setTabView} showFilters={showFilters} _showFilters={_showFilters}  nomads={nomads} taxRates={taxRates} action={action} _action={_action} /> : 
        tabView === invoiceTabs.SUBSCRIPTION ? <SubscriptionTable tabView={tabView} setTabView={setTabView} showFilters={showFilters} _showFilters={_showFilters} nomads={nomads} taxRates={taxRates} action={action} _action={_action} />: 
        tabView === invoiceTabs.CREDIT ? <CreditTable tabView={tabView} setTabView={setTabView} showFilters={showFilters} _showFilters={_showFilters} nomads={nomads} taxRates={taxRates} action={action} _action={_action} />: 
        tabView === invoiceTabs.COMMISSION ? <CommissionTable tabView={tabView} setTabView={setTabView} showFilters={showFilters} _showFilters={_showFilters} nomads={nomads} taxRates={taxRates} action={action} _action={_action} />: 
        <Nomadtable tabView={tabView} setTabView={setTabView} showFilters={showFilters} _showFilters={_showFilters}/>
      }
    </InvoicesWrapper>
  );
};

export default Invoices;
