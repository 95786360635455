import { Autocomplete, Chip, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SearchWrapper } from "./searchablestyle";

const SearchableObjDropdown = (props) => {
  const [filteredData, _filteredData] = useState([]);
  const [value,_value] = useState('')

  useEffect(() => {
    _filteredData([])
    if(props.dropdownData&&(props.disabled!==true||props.closed===true)){
      Object.keys(props.dropdownData).forEach((element) => {
        _filteredData((olddata) => [
          ...olddata,
          {
            label: props.dropdownData[element],
            value: element,
          },
        ]);
      });
    }
  }, [props.dropdownData]);

  useEffect(() => {
    if (props.val&&props.dropdownData) {
      props.setChangeValue(props.val);
      _value(props.dropdownData[props.val]?props.dropdownData[props.val]:"")
    }
    else{
      _value('')
    }
  }, [props.val,props.dropdownData]);

  //to update setChangeValue state dropdowns
  const handlesetChangeValue = (event, newValue) => {
      props.setChangeValue(newValue.value)
  }

  return (
    <SearchWrapper>
      <Autocomplete
        key={props.filters}
        disableClearable
        disabled={props.disabled}
        className={props.className}
        options={filteredData}
        sx={{ width: 250 }} 
        freeSolo
        onInputChange={(x) => {
          if (props.func && x?.target) { 
            props.func(x.target.value);
          }
        }}
        renderOption={(props, option) => (
          <li {...props} key={option.value}>
            {option.label}
          </li>
        )}
        forcePopupIcon={true}
        onChange={(event, newValue) => {
          handlesetChangeValue(event, newValue)
        }}
        renderInput={(params) => <TextField {...params} placeholder={props.placeholder} id={props.id?props.id:'searchableField'} onChange={(e)=>{
          if(e.target.value == ''){
            props.setChangeValue('');
          }
        }}/>}
      />
    </SearchWrapper>
  );
};

export default SearchableObjDropdown;
