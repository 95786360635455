  
function StaticFilter({placeholder, data , handleChange , value}){
  return (
            <div className="select-wrapper">
              <select
                className="filterBox"
                onChange={handleChange}
                value={value}
              >
               { placeholder &&  <option hidden value=''>{placeholder}</option> }
                {
                    data.length > 0 && data.map( option => {
                        return <option key={'option'+option.name} value={option.value}>{option.name}</option>
                    })
                } 
              </select>
            </div>
  )
}
export default StaticFilter
