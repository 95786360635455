import styled from "styled-components";

export const CreateCensuredWrapper = styled.div`
  .headerContainer {
    justify-content: space-between;
  }
  .cancelBtn {
    color: #c7c6cd;
  }
  .details {
    display: flex;
    justify-content: center;
    padding: 10px 0;
  }
  .detailsContent {
    display: flex;
    flex-direction: column;
  }
  .profile-flag {
    position: relative;
    display: inline-block;
  }
  .profileImage {
    height: 40px;
    width: 40px;
    background: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
  }
  .profileImage img {
    margin: 0 auto;
    display: flex;
    width: 38px;
    height: 38px;
    object-fit: cover;
  }
  .flagIcon {
    position: absolute;
    width: 25px;
    height: 25px;
    bottom: -12px;
    right: 6px;
    border-radius: 50px;
  }
  .send {
    font-weight: 700;
    color: #5d78ff;
  }
  .input-section {
    margin-top: 8px;
  }
  .formInput {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  input[type="checkbox"] {
    margin-top: 0px !important;
    margin-left: 5px;
    transform: scale(1.5);
  }
  .app {
    width: 50%;
    margin-top: 10px;
    border: 1px solid #c7c6cd;
    border-radius: 5px;
    height: 40px;
    background: white;
    outline: none;
  }
  .disableBids {
    margin-top: 15px;
    border: 1px solid #c7c6cd;
    border-radius: 5px;
    background: white;
    outline: none;
    width: 100px;
  }
  .options label {
    margin-left: 20px;
  }
  .checkboxOptions {
    margin-top: 10px;
  }
  .checkboxOptions .options {
    margin-top: 15px;
  }
  .options {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .labelVal {
    margin-left: -25px;
  }
  .commentBox {
    min-width: 100%;
    border: 1px solid #ced4da;
    border-radius: 5px;
    height: 120px;
    overflow-y: scroll;
  }
  .removeComment {
    height: 13px;
    width: 13px;
    border: 1px solid red;
    border-radius: 50%;
    text-align: center;
    line-height: 9px;
    color: red;
    font-size: 12px;
    cursor: pointer;
  }
  .disabledcomment {
    opacity: 50%;
    color: red !important;
    border: 1px solid red !important;
    border-radius: 5px;
    padding: 0 4px;
    margin-bottom: 5px;
    word-break: break-word;
    width: fit-content;
    font-size: 14px;
    cursor: not-allowed !important;
  }
  .suggestion {
    color: #a7a6a6;
    font-size: 14px;
    font-style: italic;
  }
  .searchKeyword {
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 5px;
    height: 40px;
  }
  .keywords {
    display: flex;
    flex-direction: column;
    max-height: 200px;
    overflow-y: scroll;
    width: 100%;
  }
  .comments {
    width: fit-content;
    border: 1px solid #5d78ff;
    color: #5d78ff;
    border-radius: 5px;
    line-height: 26px;
    font-size: 14px;
    cursor: pointer;
  }
  .comments:hover {
    background: #5d78ff;
    color: white;
    transition: all 0.25s ease-in-out;
  }
  .labelContainer {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }
  .labelContainer label {
    margin-left: 0px;
  }
  .info {
    color: #979797;
  }
  .btn {
    background: #fbc524 !important;
  }
  @media (max-width: 484px) {
    .labelVal {
      margin-left: 0;
    }
  }
`;