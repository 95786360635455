import styled from "styled-components";

export const VerifyIdListWrapper = styled.div`
.headerContainer div,
.headerContainer h1 {
  margin-bottom: 10px;
}
.searchContainer {
  display: flex;
  padding: 0 15px 0 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.info-wrap {
  flex-wrap: wrap;
  // align-self: center;
  // align-items: center !important;
  // justify-content: center;
}
.search {
  height: 35px;
  border: none;
  border-radius: 10px;
}
.btn {
  align-self: center;
  background-color: #607afc;
  border: none;
  height: 35px;
  border-radius: 5px;
  padding: 0 20px;
  color: white;
  font-weight: bold;
}
.auctionEventImage {
  width: 90px;
  height: auto;
}
.action {
  display: flex;
  align-items: center;
}
.action button {
  border: none;
  background: transparent;
}
.form-check {
  min-height: auto !important;
}
.form-check-input {
  cursor: pointer !important;
}
.form-check-input:checked {
  background-color: #5d78ff;
  border-color: #5d78ff;
}
.select-info {
  flex-wrap: wrap;
}
.select-info select {
  max-width: 130px;
}
.time-start {
  min-width: 165px;
}
.time-start svg {
  width: 10px;
  height: 15px;
  cursor: pointer;
}
.opacity-40 {
  opacity: 40%;
}
.three-dot{
  align-items: center;
  height: 75vh;
}
a {
  color: #0d6efd;
  text-decoration: none;
}
.summary{
  padding:10px;
  border : 1px solid #aeaeae;
  border-radius:5px;
}
.summaryContainer{
  width:100%;
  display:flex;
}
.summaryContent{
  width:33%;
  display:flex;
  flex-direction:column;
  align-items:center;
  border-left:1px solid #E9E9E9;
  border-right:1px solid #E9E9E9;
}
.summaryContent:nth-child(1) {
  border-left:none;
}
.summaryContent:nth-child(3) {
  border-right:none;
}
.summaryContent p{
  font-size:18px;
  font-weight:500;
  word-break: break-word;
  text-align:center;
}
.summaryContent span{
  margin-top:10px;
  word-break: break-word;
  text-align:center;
}
.profile-flag {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.profileImage {
  height: 40px;
  width: 40px;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
}
.profileImage img {
  margin: 0 auto;
  display: flex;
  width: 38px;
  height: 38px;
  object-fit: cover;
}
.countryflag {
  position: absolute;
  bottom: -12px;
  right: 6px;
}
.name-text {
  display: inline-block;
  width: 150px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  text-align: left;
}
td,th{
  width:30% !important;
}
td.username{
  display:flex; 
}
.pending-verification-upper{
  width:100% !important;
  display:flex;
  justify-content:center;
  margin:0 0 0 0 !important;
}
 
.summaryContainer{
  width:80%;
}
@media(max-width: 991px){
  .summary{
      margin-top:10px;
  }
}

@media(max-width: 668px){
  .summaryContainer{
      display: block !important;
      text-align: center;
  }
  .summaryContent {
      width: 100%;
      border-bottom: 1px solid #dddddd;
      padding: 2px 0;
      border-right: 0;
      border-left: 0;
  }
  .summaryContent:nth-last-child(1) {
      border: none;
  }
  .summaryContent span {
      margin-top: 5px;
      font-size: 14px;

  }
  .summaryContent p {
    font-size: 14px;
}

}
`