import React from 'react'
import StaticFilter from './StaticFilter'

function ProductTypeFilter({handleChange , value}) {
  const productTypelist =  JSON.parse(localStorage.getItem("productType") || "[]")  
  const optionData = productTypelist.map(item => ({ name:item.item_name.en , value : item.product_categories[0].pivot.product_type_id}))
  return <StaticFilter data={optionData} value={value} handleChange={handleChange} placeholder="Product Type"  />
}

export default ProductTypeFilter
