import React from 'react'
import { toast } from 'react-toastify';
import API from '../../../api/axios';
import { APP_URLS } from '../../../api/url';
import close from "../../../assets/Images/icons/Cancel.svg";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputAdornment, TextField } from '@mui/material';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';


const EditCreditPricePopup = (props) => {

  //destructuring editData from props
  const { editdata: { adId = '', ctbPrice, credit, discountedPrice, actualPrice } = {} } = props;

  //validations
  const validationSchema = Yup.object().shape({
    credit: Yup.string().required('Credit is required'),
    cartobike_price: Yup.number()
      .required("Cartobike price is required")
      .min(1, "The minimum price for a Catobike is € 1.")
      .max(parseInt(actualPrice) - 1, `The maximum price for a Catobike should be € ${parseInt(actualPrice) - 1}.`),
  });

  //handles form submission
  const handleSubmit = async (values, { setSubmitting }) => {
    const data = {
      credit: values.credit,
      cartobike_price: values.cartobike_price
    };

    try {
      const res = await API.post(`${APP_URLS.HOTDEAL_ADS}/${adId}/update?_method=patch`, data);
      if (res.status === 200) {
        toast.success('Credit updated successfully.', {
          position: toast.POSITION.TOP_RIGHT,
        });
        props._creditEdited(prevState => !prevState);
        props._popup(false);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      const resp = error.response;
      let errorMessage = resp?.data?.message || 'An error occurred';
      if (resp?.data?.data) {
        errorMessage = resp.data.data.message || Object.values(resp.data.data)[0][0];
      }
      toast.error(errorMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog open={props.popup} onClose={() => props._popup(false)} sx={{
      '.MuiPaper-root': {
        width: '450px',
      }
    }}>
      <DialogTitle textAlign={'center'}>
        <div className="modal-header">
          <h5 className="modal-title">Edit price</h5>
          <img src={close} alt="" className="mx-2" role="button" onClick={() => props._popup('')} />
        </div>
      </DialogTitle>
      <Formik
        initialValues={{ credit: credit, cartobike_price: ctbPrice || discountedPrice }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue, values, errors }) => (
          <Form>
            <DialogContent>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <FormControl>
                  <Field
                    as={StyledTextField}
                    type="number"
                    label={`Credit`}
                    name="credit"
                    fullWidth
                  />
                  <ErrorMessage name="credit" component="div" style={{ color: 'red' }} />
                </FormControl>
                <FormControl>
                  <Field
                    as={StyledTextField}
                    type="number"
                    label={`Catobike price (Excluding VAT)`}
                    name="cartobike_price"
                    fullWidth
                    InputProps={{
                      startAdornment: <InputAdornment position="start">€</InputAdornment>,
                    }}
                  />
                  <ErrorMessage name="cartobike_price" component="div" style={{ color: 'red' }} />
                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => props._popup(false)} className='text-secondary border border-secondary rounded-3'>
                Cancel
              </Button>
              <Button type="submit" className='btn' variant="contained" color="primary" disabled={isSubmitting}>
                Submit
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default EditCreditPricePopup

// Styled component to remove number input spinners (type = number)
const StyledTextField = styled(TextField)`
  & input[type=number] {
    -moz-appearance: textfield;
  }
  & input[type=number]::-webkit-outer-spin-button,
  & input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;