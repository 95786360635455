import { FaDownArrow, FaUpArrow } from "../../Images/icon";

const Sorting = ({sortType, sort, handleSort}) => {
    const sortPositive = sortType; 
    const sortNegative = '-'+sortType;
    if(sort == sortPositive){
      return <span className='m-w-max'>
                 <FaDownArrow className="ms-1" role="button" />
                 <FaUpArrow className="opacity-40" onClick={() =>handleSort(sortNegative)} role="button" />
             </span> 
    }
    if(sort == sortNegative){
      return <span className='m-w-max'>
                 <FaDownArrow className="opacity-40 ms-1" onClick={() =>handleSort(sortPositive)} role="button" />
                 <FaUpArrow  role="button" />
             </span> 
    }
    return <span className='m-w-max'>
               <FaDownArrow className="opacity-40 ms-1" onClick={() =>handleSort(sortPositive)} role="button" />
               <FaUpArrow className="opacity-40" onClick={() =>handleSort(sortNegative)}  role="button" />
           </span> 
}

export default Sorting