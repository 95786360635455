import React, { useEffect, useState } from "react";
import { CraeteVehicleWrapper } from "../style/createVehicle";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { suggestionText } from "../../../config/constant";

let name = {};

const CreateForumBehaviourText = () => {

    const [languages, _languages] = useState(() => JSON.parse(localStorage.getItem("languages")) || []);
    const [countries, _countries] = useState(() => JSON.parse(localStorage.getItem("countries")) || []);
    const [loading, _loading] = useState(false);
    const navigate = useNavigate();

    //submit forum behaviour details
    const submitForm = () => {
        let data = {};
        let errorMessage = '';

        // Validate the input fields
        if (!name["en"] && !name["fr"]) {
            errorMessage = "Behaviour Text Title in English and French is mandatory";
        } else if (!name["en"]) {
            errorMessage = "Please provide Behaviour Text in English";
        } else if (!name["fr"]) {
            errorMessage = "Please provide Behaviour Text in French";
        }

        if (errorMessage) {
            toast.error(errorMessage, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        // Prepare data object
        data["name"] = name;
        data["type"] = suggestionText.FORUM_CENSORSHIP;

        // Make API call if not loading
        if (!loading) {
            _loading(true);
            API.post(APP_URLS.ADD_SUGGESTION_TEXT, data)
                .then((res) => {
                    _loading(false);
                    const resp = res.data;
                    if (resp.success) {
                        toast.success("Behaviour text Added Successfully.", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        navigate("/translation/forum-behavior-text");
                        resetForm();
                    } else {
                        toast.error(resp.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                })
                .catch((error) => {
                    _loading(false);
                    const resp = error.response;
                    let errorMessage = resp.data?.data?.[Object.keys(resp.data.data)[0]]?.[0]
                        || resp.data?.data?.error
                        || resp.data?.error
                        || resp.data?.message
                        || 'An error occurred';

                    toast.error(errorMessage, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                });
        }
    };

    useEffect(() => {
        languages.forEach(language => {
            const languageCode = language.code
            name[languageCode] = "";
        });
    }, [languages]);
    
    //generated title for each field
    const handleTitle = (val, code) => {
        name[code] = val;
    };

    const resetForm = () => {
        let inputs = document.querySelectorAll(".departmentFields");
        inputs.forEach((input) => {
            input.value = "";
        });
    };

    return (
        <>
            <CraeteVehicleWrapper>
                <div className='mt-2' >
                    <div className="backContainer ms-4" onClick={() => navigate("/translation/forum-behavior-text")}>
                        <span className='backButton'>x</span>
                        <span className='px-3 backButton'>|</span>
                        <span className='backButton'>Cancel</span>
                    </div>
                </div>
                <div className="headerContainer">
                    <h1>CREATE FORUM BEHAVIOR</h1>
                </div>
                <div className="d-flex justify-content-between save-btn mt-3">
                    <p className="fieldLabel">Title</p>
                    <button className={(loading ? "disabled" : "") + " add-button"} onClick={submitForm} disabled={loading}>Save</button>
                </div>
                <div className="row input-fileds">
                    {languages.map((language) => {
                        let itemCode = language.code;
                        let flag = countries.find((o) => o.id === language.country_id)?.flag_thumbnail;
                        return (
                            <div className="col-md-4" key={language.id}>
                                <div className="col-md-12 mt-3">
                                    <label htmlFor="title" className="form-label">
                                        <span className="countryIcon px-2">
                                            <img src={flag} alt="" className="countryflag" title={language.name} />
                                        </span>
                                        <span className="langName">{language.code}</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="title"
                                        name="title"
                                        className="form-control departmentFields"
                                        onChange={(e, code = itemCode) => {
                                            handleTitle(e.target.value, code);
                                        }}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </CraeteVehicleWrapper>
        </>
    );
};

export default CreateForumBehaviourText;
