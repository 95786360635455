import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AddWrapper } from "../Style/addWrapper";
import { convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import Datetime from "react-datetime";
import { calctime } from "../../../core/utils/getAmsterdamTime/getTime";
import moment from "moment";
import { toast } from "react-toastify";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";


const AddLegalDocuments = () => {

  const [languages, _languages] = useState(JSON.parse(localStorage.getItem("languages")));
  const [countries, _countries] = useState(JSON.parse(localStorage.getItem("countries")));
  const [startDate,_startDate] = useState('')
  const [values,_values] = useState({})

  const [loading,setLoading] = useState(false)

  const navigate = useNavigate();

  const yesterday = new Date().setDate(new Date().getDate() - 1);

  const handleTitle = (val, code) => {
    _values({
      ...values,
      [code] : val
    })
  };

  const submitForm = () => {
    let enValues = ''
    let frValues = ''
    if(values['en']!==''&&values['en']!==undefined&&values['en']!==null){
      const blocks = convertToRaw(values['en'].getCurrentContent()).blocks;
      enValues = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n').trim(' ');
    }
    if(values['fr']!==''&&values['fr']!==undefined&&values['fr']!==null){
      const blocks = convertToRaw(values['fr'].getCurrentContent()).blocks;
      frValues = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n').trim(' ');
    }
    if(startDate===''||startDate===undefined||startDate===null){
      toast.error("Start Date is required", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    else if((enValues===''||enValues===undefined||enValues===null)&&(frValues===''||frValues===undefined||frValues===null)){
      toast.error("Content in English and French is required", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    else if(enValues===''||enValues===undefined||enValues===null){
      toast.error("Content in English is required", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    else if(frValues===''||frValues===undefined||frValues===null){
      toast.error("Content in French is required", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    else{
      let data = {}
      data['type'] = 'legal_documents'
      data['start_date'] = startDate
      data['content'] = {}
      Object.keys(values).map((item)=>{
        data.content[item] = draftToHtml(convertToRaw(values[item].getCurrentContent()))
      })
      setLoading(true);
      API.post(APP_URLS.ADD_LEGAL_DOCUMENTS, data)
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) {
              toast.success("Legal Document Content Added Successfully.", {
                position: toast.POSITION.TOP_RIGHT,
              });
              setLoading(false);
              navigate("/legal-documents/legal-documents")
          } else {
            toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
          }
        })
        .catch(function (error) {
          setLoading(false);
          const resp = error.response;
          let error_message = "";
          if (resp.data.data !== undefined && resp.data.data !== null) {
            {
              Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
            }
          } else if (resp.data.data?.error !== undefined) {
            error_message = resp.data.data.error;
          } else if (resp.data?.error !== undefined) {
            error_message = resp.data.error;
          } else {
            error_message = resp.data.message;
          }
          toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };


  return (
    <AddWrapper>
      <div className="mt-2">
        <div
          className="backContainer ms-4"
          onClick={() => navigate("/legal-documents/legal-documents")}
        >
          <span className="backButton">x</span>
          <span className="px-3 backButton">|</span>
          <span className="backButton">Cancel</span>
        </div>
      </div>
      <div className="headerContainer">
        <h1>CREATE LEGAL DOCUMENTS</h1>
      </div>
      <div className="d-flex justify-content-end save-btn mt-3">
        <button className={(loading?"disabled":"")+" add-button"} onClick={submitForm} disabled={loading}>
          Save
        </button>
      </div>
      <div className="row">
        <div className="col-md-4 offset-md-2">
          <label htmlFor="startDate" className="form-label product-type fieldLabel">
            Start Date
          </label>
          <input type='date'
            className="form-control mt-2 mb-4"
            name="startDate"
            id="startDate"
            value={startDate}
            min={new Date().toISOString().split('T')[0]}
            onChange={(e)=>_startDate(e.target.value)}
            />
        </div>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="col-md-8">
          {languages.map((item, index) => {
            let itemCode = item.code;
            let flag = countries.find((o) => o.id === item.country_id)?.flag_thumbnail;
            return (
              <div className="col-md-12" key={index}>
                  <label htmlFor="title" className="form-label">
                    <span className="countryIcon px-2">
                      <img src={flag} alt="" className="countryflag" title={item.name}/>
                    </span>
                    <span className="langName">{item.code.toUpperCase()}</span>
                  </label>
                  <Editor
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    localization={{
                      locale: itemCode,
                    }}
                    editorState={values[itemCode]}
                    onEditorStateChange={(e, code = itemCode) => {
                      handleTitle(e, code);
                    }}
                  />
              </div>
            );
          })}
        </div>
      </div>
    </AddWrapper>
  );
};

export default AddLegalDocuments;
