import styled from "styled-components";

export const ClassicAdsListWrapper = styled.div`
.headerContainer div,
.headerContainer h1 {
  margin-bottom: 10px;
}
.searchContainer {
  display: flex;
  padding: 0 15px 0 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.auctionTable{
  min-width: 1250px;
}
.info-wrap {
  flex-wrap: wrap;
}
.search {
  height: 35px;
  border: none;
  border-radius: 10px;
}
.btn {
  align-self: center;
  background-color: #607afc;
  border: none;
  height: 35px;
  border-radius: 5px;
  padding: 0 20px;
  color: white;
  font-weight: bold;
}
.auctionEventImage {
  height: auto;
  width: 60px;
}
.action {
  display: flex;
  align-items: center;
}
.action button {
  border: none;
  background: transparent;
}
.open{
  transform: rotate(0deg);
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.close{
  transform: rotate(-180deg);
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  left:0px !important;
}
.form-check {
  min-height: auto !important;
}
.form-check-input {
  cursor: pointer !important;
}
.form-check-input:checked {
  background-color: #5d78ff;
  border-color: #5d78ff;
}
.select-info {
  flex-wrap: wrap;
}
.select-info select {
  max-width: 130px;
}
.time-start {
  min-width: 165px;
}
.time-start svg {
  width: 10px;
  height: 15px;
  cursor: pointer;
}
.opacity-40 {
  opacity: 40%;
}
.three-dot{
  align-items: center;
  height: 75vh;
}
.filter{
  color: gray;
  cursor:pointer;
}
.w-150{
  width:150px;
}
.w-90{
  width:90px;
}
.btn{
  height: 35px;
}
.actionBtns.btnActive {
  border-radius: 5px 5px 0 0;
}
.actionAuction {
  position: relative;
}
.actionAuction .DelAction {
  width: 100%;
  position: absolute;
}
.actionAuction .DelAction .actionBtn {
  width: 100%;
  border-top: none;
  border-radius: 0 0 5px 5px;
}
.filterBtn{
  font-weight:600;
}
.fieldTitle {
  font-size: 12px;
  font-style: italic;
  font-weight: 200;
  color: #979797;
}
.cardContent{
  display:flex;
  flex-direction:column;
  max-height: 200px;
  overflow-y: auto;
}
.comments{
  color: #5D78FF;
  border:1px solid #5D78FF;
  border-radius:5px;
  padding:0 4px;
  margin-bottom:5px;
  word-break: break-word;
  width: fit-content;
  cursor:pointer;
}
.disabledcomment{
  opacity:50%;
  color:red !important; 
  border:1px solid red !important;
  border-radius:5px;
  padding:0 4px;
  margin-bottom:5px;
  word-break: break-word;
  width: fit-content;
  font-size: 14px;
  cursor:not-allowed !important;
}
.comments: hover{
  background-color: #5D78FF;
  color: white;
  transition: all .20s ease-in-out;
}
.cardFooter{
  border: 1px solid rgba(0,0,0,.125);;
  max-height: 85px;
  min-height: 84px;
  overflow-y: scroll;
}
.removeComment{
  height: 13px;
  width: 13px;
  border: 1px solid red;
  border-radius: 50%;
  text-align: center;
  line-height: 9px;
  color:red;
  font-size:12px;
  cursor:pointer;
}
span.offline{
  background:red;
}
span.online{
  background:green;
}
.suggestion{
  color: #a7a6a6;
  font-size: 14px;
  font-style: italic;
}
.deleteBtn{
  background-color:rgb(255, 0, 24) !important;
}
.extract-report{
  font-weight: 600;
  cursor: pointer;
  text-transform: none;
  color: black; 
  border: 1px solid rgba(0,0,0,.2);
  height: 32px;
  &:hover{
      outline:none;
  }
}
@media (max-width: 1226px) {
  .info-wrap {
    justify-content: center !important;
  }
}
@media (max-width: 485px) {
  .info-wrap {
    justify-content: center !important;
  }
}
@media (max-width: 485px) {
  .info-wrap {
    justify-content: center !important;
  }
}
`