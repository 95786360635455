import React, { useEffect, useMemo, useState } from "react";
import { NavLink, useLocation, useParams, useSearchParams } from "react-router-dom";
import Pagination from "../../../assets/SharedComponents/Pagination";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import * as Constant from "../../../config/constant";
import NoRecord from "../../../assets/SharedComponents/Table/NoRecord";
import SearchKey from "../../../assets/SharedComponents/Search/SearchKey";
import { FaFilterIcon } from "../../../assets/Images/icon";
import { Tooltip } from "@mui/material";
import { ThreeDots } from 'react-loader-spinner';
import RecordCount from "../../../assets/SharedComponents/RecordCount/RecordCount";
import Sorting from "../../../assets/SharedComponents/Sorting/Sorting";
import StaticFilter from "../../../assets/SharedComponents/Filters/StaticFilter";
import ProductTypeFilter from "../../../assets/SharedComponents/Filters/ProductTypeFilter";
import CountryFilter from "../../../assets/SharedComponents/Filters/CountryFilter";
import { AuctionWrapper } from "../../Auction/Auctions/style/auction";

const userTypesList = Object.keys(Constant.auctionUserTypes).map((key) => ({ name: key, value: Constant.auctionUserTypes[key] }))
const auctionStatusList = Object.keys(Constant.auctionStatus).map((key) => ({ name: key, value: Constant.auctionStatus[key] }))

const AllHotdeals = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const [searchParams, setSearchParams] = useSearchParams();
  const [countries, setcountries] = useState(() => JSON.parse(localStorage.getItem("countries")) || []);
  const [userAccess, _userAccess] = useState(() => JSON.parse(localStorage.getItem("permissions")) || []);
  const hotdealsAccess = userAccess.find((o) => o.slug === "hotdeal_event");
  const [searchkeyword, _searchkeyword] = useState(searchParams.get('a_keyword') !== null ? searchParams.get('a_keyword') : "");
  const [countValue, _countValue] = useState(searchParams.get('a_per_page') !== null ? searchParams.get('a_per_page') : "");
  const [loaded, _loaded] = useState(false);
  const [page, setPage] = useState(searchParams.get('a_page') !== null ? parseInt(searchParams.get('a_page')) : 1);
  const [total, _total] = useState(0);
  const [totalPage, _totalPage] = useState(0);
  const [data, _data] = useState([]);
  const [country, setCountry] = useState(searchParams.get('a_country_id') !== null ? searchParams.get('a_country_id') : '');
  const [product, setProduct] = useState(searchParams.get('a_product_type_id') !== null ? searchParams.get('a_product_type_id') : "");
  const [buyer, setBuyer] = useState(searchParams.get('a_display_for') !== null ? searchParams.get('a_display_for') : "");
  const [seller, setSeller] = useState(searchParams.get('a_seller_allowed') !== null ? searchParams.get('a_seller_allowed') : "");
  const [status, setStatus] = useState(searchParams.get('a_status') !== null ? searchParams.get('a_status') : "");
  const [sort, _sort] = useState(searchParams.get('a_sort') !== null ? searchParams.get('a_sort') : "hotdeal_events.title->en");
  const [filters, _filters] = useState(false);

  useEffect(() => {
    getAuctionEvents();
  }, [page, searchkeyword, countValue, country, product, buyer, seller, sort, status]);

  const setChangeValue = (item) => {
    setCountry(item);
    _totalPage(0)
    setPage(1);
  }

  const getproduct = (item) => {
    setProduct(item);
    _totalPage(0)
    setPage(1);
  }

  const getbuyer = (item) => {
    setBuyer(item);
    _totalPage(0)
    setPage(1);
  }

  const getStatus = (item) => {
    setStatus(item);
    _totalPage(0)
    setPage(1);
  }

  const getseller = (item) => {
    setSeller(item);
    _totalPage(0)
    setPage(1);
  }

  const setsorting = (val) => {
    _sort(val);
    if (sort !== val) {
      setPage(1);
      _totalPage(0)
    }
  }


  //getting auction events
  const getAuctionEvents = () => {
    _loaded(true);
    API.get(APP_URLS.HOTDEALS + `?page=${page}&per_page=${countValue}&keyword=${searchkeyword}&sort=${sort}&filter[country_id]=${country}&filter[product_type_id]=${product}&filter[seller_allowed]=${seller}&filter[display_for]=${buyer}&filter[status]=${status}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _data(resp.data.data);
          _totalPage(resp.data.last_page);
          _total(resp.data.total);
          _loaded(false);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loaded(false);
        }
      })
      .catch(function (error) {
        _loaded(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map((error, index) => (error_message = resp.data.errors[error][0]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleSearchChange = (func, searchValue) => {
    func(searchValue);
    if ((func === _countValue && searchValue !== countValue) || (func === _searchkeyword && searchValue !== searchkeyword)) {
      if (searchValue !== "") {
        _totalPage(0)
        setPage(1);
      }
    }
  };

  const handleSwitch = (id) => {
    const newData = [...data];
    let val = newData.find((o) => o.id === id);
    if (val.active === 1) {
      val.active = 0;
    } else {
      val.active = 1;
    }
    _data(newData);
  };

  const disableAuction = (id, val) => {
    let data = {};
    data["active"] = val;
    API.post(APP_URLS.HOTDEALS + `/${id}/update_status?_method=patch`, data)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          toast.success(`Auction has been ${val === 1 ? "enabled" : "disabled"} successfully.`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          getAuctionEvents();
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  //removing all filters
  const clearFilter = () => {
    _filters(true)
    handleSearchChange(_countValue, '');
    handleSearchChange(_searchkeyword, '');
    setCountry("");
    setProduct("");
    setBuyer("");
    setSeller("");
    setStatus("")
    _sort('hotdeal_events.title->en')
    if (searchkeyword !== "" || sort !== "hotdeal_events.title->en" || countValue !== "" || country !== "" || product !== "" || buyer !== "" || seller !== "" || status !== "") {
      _totalPage(0)
      setPage(1);
    }
    for (var value of query.keys()) {
      const param = searchParams.get(value);
      if (param) {
        searchParams.delete(value);
        setSearchParams(searchParams);
      }
    }
  };

  return (
    <>
      <AuctionWrapper>
        <div className="auctionMainContainer">
          <div className="row mt-4">
            <div className="col-md-4 offset-md-4 mb-2">
              <h1>HOTDEALS</h1>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between info-wrap">
            <div className="justify-content-center mb-2 me-2">
              {hotdealsAccess?.permissions?.find((o) => o.slug === "add") ? (
                <div className="actionContainer">
                  <NavLink className="btn" to="/create-hotdeal">
                    + Add
                  </NavLink>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="justify-content-end mb-2 d-flex gap-2 mb-2 select-info align-items-center">
              <CountryFilter value={country} handleChange={setChangeValue} />
              <StaticFilter placeholder='Status' data={auctionStatusList} handleChange={(e) => { getStatus(e.target.value) }} value={status} />
              <StaticFilter placeholder='Seller' data={userTypesList} handleChange={(e) => { getseller(e.target.value) }} value={seller} />
              <StaticFilter placeholder='Buyer' data={userTypesList} handleChange={(e) => { getbuyer(e.target.value) }} value={buyer} />
              <ProductTypeFilter handleChange={(e) => getproduct(e.target.value)} value={product} />
              <SearchKey
                onSearchClick={(search) => handleSearchChange(_searchkeyword, search)}
                id="searchData"
                placeholder="Search by Title"
                val={searchkeyword}
                filters={filters}
                _filters={_filters} />
              <RecordCount
                onSearchClick={(search) => handleSearchChange(_countValue, search)}
                id="pageCount"
                val={countValue}
                filters={filters}
                _filters={_filters} />
              <Tooltip title="Clear Filter" placement="bottom">
                <FaFilterIcon className="filter-icon" role="button" onClick={clearFilter} />
              </Tooltip>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between info-wrap">
            <div className="justify-content-end mt-2 me-2">
              <span className='text-start'>Total Records : <b>{total}</b></span>
            </div>
          </div>

          <div className="tableContainer">
            <table className="auctionTable">
              <thead className="tableHead">
                <tr className="table-row">
                  <th className="time-start">
                    Hotdeal Title
                    <Sorting sortType='hotdeal_events.title->en' sort={sort} handleSort={setsorting} />
                  </th>
                  <th>Status</th>
                  <th>Product Type</th>
                  <th className="time-start">
                    Start Time
                    <Sorting sortType='start' sort={sort} handleSort={setsorting} />
                    <br /> End Time
                    <Sorting sortType='end' sort={sort} handleSort={setsorting} />
                  </th>
                  <th>Country</th>
                  <th>Seller</th>
                  <th>Buyer</th>
                  <th>Credit required</th>
                  <th className="time-start">
                    Number of Ads
                    <Sorting sortType='ad_cars_count' sort={sort} handleSort={setsorting} />
                  </th>
                  {hotdealsAccess?.permissions?.find((o) => o.slug === "delete") ?
                    <th>Action</th>
                    : ""}
                </tr>
              </thead>
              {loaded ?
                <div className='d-flex justify-content-center three-dot'>
                  <ThreeDots
                    height="150"
                    width="150"
                    radius="9"
                    color="#637df4"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
                :
                <tbody>
                  {data.length > 0 ? (
                    data.map((item, index) => {
                      const flag = countries.find((o) => o.id === item.hotdeal_event.country_id)
                      return (
                        <tr className="table-row" key={index}>
                          <td>
                            <span title={item.hotdeal_event.description['en']}>{item.hotdeal_event.title['en']}</span>
                          </td>
                          <td>
                            <span className={Constant.AUCTION_STATUS[item.status]}>{item.status ? item.status.replace("_", " ").charAt(0).toUpperCase() + item.status.replace("_", " ").slice(1) : "-"}</span>
                          </td>
                          <td>{item.hotdeal_event.product_category_type.product_type !== null ? item.hotdeal_event.product_category_type.product_type.item_name.en : "-"}</td>
                          <td className="time-start">
                            <span className="d-block">{moment(item.start).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm")}</span>
                            <span className="block">{moment(item.end).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm")}</span>
                          </td>
                          <td>
                            <img src={flag.flag_thumbnail} alt="" className="countryflag" title={flag.fullname} />
                          </td>
                          <td>{Constant.TYPE_OF_PROFILE[item.hotdeal_event.seller_allowed]}</td>
                          <td>{Constant.TYPE_OF_PROFILE[item.hotdeal_event.display_for]}</td>
                          <td>{item?.hotdeal_event?.credit || '-'}</td>
                          <td>
                            <NavLink to={`/hotdeal-ads/${item.id}?`
                              + (country !== "" ? "a_country_id=" + country + "&" : "")
                              + (seller !== "" ? "a_seller_allowed=" + seller + "&" : "")
                              + (buyer !== "" ? "a_display_for=" + buyer + "&" : "")
                              + (product !== "" ? "a_product_type_id=" + product + "&" : "")
                              + (status !== "" ? "a_status=" + status + "&" : "")
                              + (sort !== "title->en" ? "a_sort=" + sort + "&" : "")
                              + (searchkeyword !== "" ? "a_keyword=" + searchkeyword + "&" : "")
                              + (countValue !== "" ? "a_per_page=" + countValue + "&" : "")
                              + (page !== 1 ? "a_page=" + page + "&" : "")}
                              className="link">
                              {item.ad_cars_count}
                            </NavLink>
                          </td>
                          {hotdealsAccess?.permissions?.find((o) => o.slug === "delete") ? (
                            <td className="action">
                              <label className="form-check form-switch">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={item.active}
                                  title={item.active ? "Disable" : "Enable"}
                                  onChange={() => {
                                    handleSwitch(item.id);
                                    disableAuction(item.id, item.active);
                                  }}
                                />
                                <span className="slider round"></span>
                              </label>
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>
                      );
                    })
                  ) : (
                    <NoRecord />
                  )}
                </tbody>
              }
            </table>
          </div>
          <div>
            {totalPage > 1 ? <Pagination totalPages={totalPage} page={page} key={totalPage} onPageClick={(page) => setPage(page + 1)} /> : ""}
          </div>
        </div>
      </AuctionWrapper>
    </>
  );
};

export default AllHotdeals;
