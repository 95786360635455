import moment from 'moment';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import close from "../../../assets/Images/icons/Cancel.svg";
import NoRecord from '../../../assets/SharedComponents/Table/NoRecord';
import { formatCurrency } from '../../../core/utils';

const Popup = (props) => {

  const [data,_data] = useState([]) 
  
  useEffect(() => {
    _data(props.popup)
  }, [props.popup])
  

  return (
    <div className={(props.popup?"modal fade show":"modal fade")+ " custom-modal modal-backdrop"}> 
        <div className="modal">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">Subscription Plan History</h5>
                <img src={close} alt="" className="mx-2" role="button" onClick={()=>props._popup('')}/>
            </div>
            <div className="modal-body">
              <div className='preview scrollbox'>
                <table className='previewTable w-100'>
                  <thead className='thead'>
                    <tr className='table-row'>
                      <th>Plan Name</th>
                      <th>Amount</th>
                      <th>
                        <span className='d-block'>Created At</span>
                        <span>Deleted At</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                  {data.length > 0 ? (
                    data.map((item, index) => {
                          return <tr className='table-row' key={index}>
                            <td>{item.price.plan.name}</td>
                            <td>{formatCurrency(item.price.unit_amount)+" "+item.price.recurring_interval+"ly"}</td>
                            <td>
                              <span className='d-block'>{item.created_at?moment(item.created_at).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm"):"-"}</span>
                              <span>{item.deleted_at?moment(item.deleted_at).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm"):"-"}</span>
                            </td>
                          </tr>
                    })):
                    <NoRecord />
                    }
                  </tbody>
                </table>
              </div>
            </div>
            </div>
        </div>
        </div>
    </div>
  )
}

export default Popup