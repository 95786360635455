import React, { useEffect, useState } from 'react'
import loginImage from "../../assets/Images/image/login.png";
import logo from "../../assets/Images/icons/c2blogo.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoginWrapper } from "./style/login";
import { APP_URLS } from '../../api/url';
import API from '../../api/axios';
import { toast } from "react-toastify";
import { NavLink, useNavigate } from 'react-router-dom';
import * as Constant from "../../config/constant";
import { fetchToken } from '../../firebase/firebase';

const Login = (props) => {

  const emailRegExp = /^([a-z0-9\_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,6}$/;
  const [loading, setLoading] = useState(false);

  const [emailValid,_emailValid] = useState(false)
  const [passwordValid,_passwordValid] = useState(false)
  const [message,_message] = useState('')

  const navigate = useNavigate()

   
  const getUserPermissions = (subsidiary) =>{
    API.get(APP_URLS.USER_PERMISSION + `/${subsidiary}/permission`)
    .then(res => {
      const resp = res.data;
      if(resp.success===true){
        localStorage.setItem("permissions",JSON.stringify(resp.data.items.filter(o=>o.type!==2)))
        window.location.reload();
      }
      else{
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })
    .catch(function (error) {  
      const resp = error.response;
      let error_message = '';
      if (resp?.data?.data?.email !== undefined) {
          {Object.keys(resp.data.data.email).map((error, index) => (
              error_message = resp.data.data.email[error]
          ))}
      } else if (resp.data.data?.error !== undefined) {
          error_message =  resp.data.data.error;
      } else if(resp.data?.error!== undefined){
          error_message =  resp.data.error
      } else{
          error_message =  resp.data.message
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT
      })
    });
  }
  

  const formik = useFormik({
    initialValues: {
        email: '',
        password: '',
        app_type: Constant.APP_TYPE['ADMIN_PANEL']
    },
    validationSchema: Yup.object({
        email: Yup.string().matches(emailRegExp, 'The "Email" field format is invalid').required('Email is Required'),
        password: Yup.string().required('Password is Required'),
    }),
    onSubmit: values => {
        if(!loading) {
          values['device_id'] = JSON.parse(localStorage.getItem("deviceToken"));
          values['device_type'] = "web";
            setLoading(true)
            API.post(APP_URLS.LOGIN, values)
            .then(res => {
                const resp = res.data;
                setLoading(false)
                if (resp) {
                  localStorage.setItem("token", resp.access_token);
                  localStorage.setItem("access_type", resp.token_type);
                  localStorage.setItem("subsidiary_id", resp.subsidiary_id);
                  localStorage.setItem("user", JSON.stringify(resp.user));
                  getUserPermissions(resp.subsidiary_id)
                  // props.getToken(resp.access_token)
                } else {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
            })
            .catch(function (error) {  
                const resp = error.response;
                setLoading(false)
                let error_message = '';
                if (resp.data.data?.email !== undefined) {
                    {Object.keys(resp.data.data.email).map((error, index) => (
                        error_message = resp.data.data.email[error]
                    ))}
                    _emailValid(true)
                } else if (resp.data.data?.error !== undefined) {
                    error_message =  resp.data.data.error;
                } else if(resp.data?.error!== undefined){
                    error_message =  resp.data.error
                } else{
                    error_message =  resp.data.message
                    _passwordValid(true)
                }
                _message(error_message)
            });       
        }
    },
  });

  return (
    <>
      <LoginWrapper>
        <div className='container-fluid login-admin'>
          <div className='header'>
            <div className='headContainer'>
              <img src={logo} alt="" onClick={()=>navigate('/login')}/>
            </div>
          </div>

          <div className="formContainer container-full mt-sm-5">
            
            <div className='col-md-8 offset-md-2'>
              <div className='row'>
                <div className="col-sm-12 col-md-6 offset-md-6 col-lg-4 offset-lg-8">
            </div>
              </div>
            
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-7">
                  <div className="login-img">
                    <img src={loginImage} alt="" />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-5">
                  <div className='formLogin'>
                  <div className='form-title block sm-block md-none'>LOGIN</div>
                    <form onSubmit={(e)=> formik.handleSubmit(e)}>
                        <div className="inputField">
                          <input
                            type="text"
                            placeholder="Email"
                            name="email"
                            value={formik.values.email}
                            onBlur={formik.handleBlur}
                            onChange={(e)=>{
                              formik.handleChange(e)
                              _emailValid(false)
                            }}
                          />
                          
                          {emailValid?<span className='required'>
                            {message}
                          </span>:<span className='required'>
                            {formik.touched.email && formik.errors.email}
                          </span>}
                        </div>
                        <div className="">
                          <input
                            type="password"
                            placeholder="Password"
                            name="password"
                            value={formik.values.password}
                            onBlur={formik.handleBlur}
                            onChange={(e)=>{
                              formik.handleChange(e)
                              _passwordValid(false)
                            }}
                          />
                          {passwordValid?
                          <span className='required'>
                            {message}
                          </span>:
                           <span className='required'>
                            {formik.touched.password && formik.errors.password}
                          </span>}
                        </div>
                        <button className="submitBtn actionBtn" type='submit' disabled={loading}>{loading?"Logging In...":"Login"}</button>
                    </form>
                    <div className="forgot">
                      <NavLink to="/forgot-password">Forgot Password?</NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer>
            <p>All rights reserved - CarToBike.com 2022</p>
          </footer>
        </div>
      </LoginWrapper>
    </>
  );
};

export default Login