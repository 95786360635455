import styled from "styled-components";

export const CompanyDetailsWrapper = styled.div`
  
  .headerContainer h1 {
    margin-bottom: 10px;
  }
  .leftSpace {
    margin-left: 3px;
  }
  .headerContainer{ 
    display:flex;
    flex-direction:column-reverse;
  }
  .addressContainer{
     width:100%;  
     border:1px solid lightgray; 
     margin-top:1rem;
     padding:10px 0;
  }
  .addressContainer > div {
     width:100%;
     display:flex;
     flex-direction:row; 
     heigth:auto;
     margin-bottom:0;
     padding:5px;
  }
  .address-item{
    width:50%;
    text-align:start;
  }
  .searchContainer {
    display: flex;
    padding: 0 15px 0 0;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  .search {
    height: 35px;
    border: none;
    border-radius: 10px;
  }

  .history h4 {
    font-weight: 500;
    font-size: 25px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
  .history input {
    border: 1px solid #979797;
    border-radius: 6px;
    padding: 5px;
  }
  .history p {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
  }
  .cancelBtn {
    border: 1px solid #5d78ff;
    border-radius: 6px;
    padding: 6px 20px;
  }
  .actionContainer h5 {
    color: #979797;
  }
  .button-save {
    background: #fbc524 !important;
  }
  .btn:hover {
    color: #fff !important;
  }
  #time-start {
    min-width: 120px;
  }
  .companies-count {
    flex-wrap: wrap;
  }
  .button-cancel {
    background: red !important;
  }
  .sanctions{
    display: flex;
    flex-direction:column;
    align-items:center;
  }
  .suggestion{
    color: #a7a6a6;
    font-size: 14px;
    font-style: italic;
  }
  .w20{
    width:220px
  }
  .status {
    height: 15px;
    width: 15px;
  }
  .dropmenu{
    cursor:pointer;
    border: 2px solid rgb(99, 125, 244);
    border-radius: 25px;
    padding: 9px 6px;
  }
  .open{
    transform: rotate(-90deg);
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  .close{
    transform: rotate(90deg);
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  .openMenu{
    transform: rotate(0deg);
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  .closeMenu{
    transform: rotate(180deg);
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  .showTable{
    height:auto;
    opacity:100;
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  .hideTable{
    height:0px;
    opacity:0;
    overflow:hidden;
  }
  .hideTable tr{
    height:0px;
  }
  .subsTable th, .subsTable td{
    width: 15%;
  }
  .clipboard{
    width:200px !important;
  }
  .clipboard img{
    cursor:pointer;
  }
  .moreIcon{
    cursor:pointer;
  }
  .previewTable{
    // margin-top: 20px !important;
  }
  .previewTable th,.previewTable tr.table-row td {
    width:100%;
    padding: 15px 5px;
    display: block;
    justify-content: center;
    align-self: center;
    text-align: center;
    word-break: break-word;
  }
  .deleteBtn{
    border: none;
    background: white;
}
@media (max-width : 1024px ){
  .headerContainer{
    display:flex !important;
  }
}
  
  @media (max-width: 767px) {
    .auction-form .form-group {
      margin-bottom: 0;
    } 
    .addressContainer >div{
      flex-direction:column;
      gap:10px;
    }
    .address-item{
      width:100%;
    }
  }
  @media (min-width: 365px) and (max-width: 380px) {
    .actionContainer {
      margin-right: 8px;
    }
  }
  @media (max-width: 378px) {
    .companies-count {
      justify-content: center !important;
    }
  }
`;
