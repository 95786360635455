import React, { forwardRef, useEffect, useState, useRef } from 'react';
import { APP_URLS } from '../../../api/url';
import { toast } from 'react-toastify';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Box,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Avatar,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Autocomplete,
    Divider,
    InputAdornment,
    IconButton
} from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import API from '../../../api/axios';
import { auctionUserTypes, creditTypes, suggestionText, userConfig } from '../../../config/constant';
import { useLocation, useParams } from 'react-router-dom';
import { FaCompany, FaCompanyIcon, FaUser } from '../../../assets/Images/icon';

const DEBOUNCE_TIME = 200
const addCreditTo = {
    CUSTOMERS: 'Customer',
    USERS: 'All users',
    DEALERS: 'All dealers'
}
const allCustomerType = {
    USERS: 'private',
    DEALERS: 'dealer'
}
const AddCreditPopup = forwardRef((props, ref) => {
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [customerDetails, setCustomerDetails] = useState(null);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [reasons, setReasons] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const { creditId } = useParams();
    const location = useLocation();
    const listInnerRef = useRef();
    const [selectedType, setSelectedType] = useState(addCreditTo.CUSTOMERS);

    //current selected customer tab
    const handleClick = (type) => {
        setSelectedType(type);
    };

    useEffect(() => {
        setSelectedType(addCreditTo.CUSTOMERS);
    }, [props.open])

    useEffect(() => {
        if (props.open) {
            //to fetch the reasons list
            const getBehaviortext = async () => {
                try {
                    const res = await API.get(`${APP_URLS.LIST_SUGGESTION_TEXT}?filter[name]=${''}&filter[type]=${suggestionText.ADD_CREDIT}`);
                    const resp = res.data;
                    if (resp.success === true) {
                        setReasons(resp.data.data);
                    } else {
                        toast.error(resp.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                } catch (error) {
                    const resp = error.response;
                    let errorMessage = "";

                    if (resp?.data?.data) {
                        if (resp.data.data.message) {
                            errorMessage = resp.data.data.message;
                        } else {
                            Object.keys(resp.data.data).forEach((key) => {
                                errorMessage = resp.data.data[key][0];
                            });
                        }
                    } else if (resp?.data?.error) {
                        errorMessage = resp.data.error;
                    } else if (resp?.error) {
                        errorMessage = resp.error;
                    } else {
                        errorMessage = resp?.message || 'An unknown error occurred';
                    }

                    toast.error(errorMessage, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            };
            getBehaviortext();
            setSelectedCustomer(creditId);//setting selected customer id
            // Reset customers and pagination when dialog is opened
            setCustomers([]); // Clear the customer list
            setPage(1); // Reset to the first page when keyword changes
            setHasMore(true);
            getAllCustomers(1, true); // Fetch the first page of results
        }
    }, [props.open]);

    useEffect(() => {
        let timer = null;
        if (searchKeyword !== undefined && searchKeyword !== null) {
            timer = setTimeout(() => {
                setPage(1); // Reset to the first page when keyword changes
                setCustomers([]); // Clear the customer list
                setHasMore(true); // Reset the hasMore flag
                getAllCustomers(1, true); // Fetch the first page of results
            }, DEBOUNCE_TIME);
        }
        return () => clearTimeout(timer);
    }, [searchKeyword, !props.hideCustomer]);

    const getAllCustomers = async (page = 1, reset = false) => {
        try {
            const res = await API.get(`${APP_URLS.ALL_CUSTOMER}?keyword=${searchKeyword}&page=${page}&per_page=20`);
            const resp = res.data;
            if (res.status === 200) {
                if (reset) {
                    setCustomers(resp.data);
                } else {
                    setCustomers(prevCustomers => [...prevCustomers, ...resp.data]);
                }
                setHasMore(resp.data.length > 0);
            } else {
                toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } catch (error) {
            const resp = error.response;
            let errorMessage = resp?.data?.message || 'An error occurred';
            if (resp?.data?.data) {
                errorMessage = resp.data.data.message || Object.values(resp.data.data)[0][0];
            }
            toast.error(errorMessage, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };


    //handles customer field
    const handleCustomerChange = (event) => {
        const selectedCustomerId = event.target.value;
        const customer = customers.find(c => c.id === selectedCustomerId);
        setSelectedCustomer(customer.id);
        setCustomerDetails(customer);
    };

    //validations
    const validationSchema = Yup.object().shape({
        ...(selectedType === addCreditTo.CUSTOMERS) && {
            customer_id: Yup.string().when([], {
                is: () => !props.hideCustomer,
                then: Yup.string().required('Customer is required'),
                otherwise: Yup.string().notRequired()
            })
        },
        amount: Yup.number().required('Amount is required')
            .min(1, 'Amount must be at least 1')
            .max(2000, 'Amount cannot exceed 2000'),
        validity: Yup.number().required('Validity is required')
            .min(1, 'Validity must be at least 1')
            .max(365, 'Validity cannot exceed 365'),
        reason_id: Yup.string().required('Reason is required')
    });

    //handles form submission
    const handleSubmit = async (values) => {
        setButtonDisabled(true); // Disable the button
        setTimeout(() => {
            setButtonDisabled(false); // Re-enable the button after 2 seconds
        }, 2000);
        const source = customerDetails?.source;
        const data = {
            type: creditTypes.ADDED_BY_ADMIN,
            amount: values.amount,
            validity: values.validity,
            reason_id: values.reason_id,
        };
        if (selectedType === addCreditTo.CUSTOMERS) {
            if (source) {
                data[userConfig[source]?.field] = source === 'user' || source === 'subsidiary' ? selectedCustomer : null
            } else {
                if (location.state.profileType == auctionUserTypes.Dealer) {
                    data['subsidiary_id'] = creditId
                } else {
                    data['user_id'] = creditId
                }
            }
        } else if (selectedType === addCreditTo.USERS) {
            data['all'] = allCustomerType.USERS;
        } else if (selectedType === addCreditTo.DEALERS) {
            data['all'] = allCustomerType.DEALERS;
        }
        try {
            const res = await API.post(`${APP_URLS.ADD_CREDIT_HISTORY}`, data);
            if (res.status === 200) {
                props._showPopup(false);
                if (selectedType === addCreditTo.CUSTOMERS) {
                    toast.success('Credit added successfully.', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else toast.success(res.data.message)
                props._creditAddedSuccess(prev => !prev);
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } catch (error) {
            const resp = error.response;
            let errorMessage = resp?.data?.message || 'An error occurred';
            if (resp?.data?.data) {
                errorMessage = resp.data.data.message || Object.values(resp.data.data)[0][0];
            }
            toast.error(errorMessage, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    //showing user and company icons according to source
    const showBadge = (source) => {
        if (source === 'subsidiary') return <FaCompanyIcon />
        return <FaUser />
    }

    //handles infinite scroll pagination
    const handleScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if (((scrollTop + clientHeight) >= (scrollHeight - 5)) && hasMore) {
                setPage(prevPage => prevPage + 1);
                getAllCustomers(page + 1);
            }
        }
    };
    return (
        <Dialog open={props.open} onClose={() => props._showPopup(false)} ref={ref} sx={{
            '.MuiPaper-root': {
                width: '500px',
            },
            '.button-container': {
                display: 'flex',
                justifyContent: 'space-between'
            },
            '.not-selected': {
                flex: 1,
                margin: '0 4px',
                textAlign: 'center',
                backgroundColor: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid lightgrey',
                borderRadius: '5px',
                cursor: 'pointer',
            },
            '.button-container .btn': {
                flex: 1,
                margin: '0 4px',
                textAlign: 'center',
                backgroundColor: 'white',
            },
            '.button-container .not-selected:hover': {
                borderColor: '#607afc '
            },
        }}>
            <DialogTitle textAlign={'center'}>
                {`${props.popupName === 'add' ? 'Add Credit' : 'Remove Credit'}`}
            </DialogTitle>
            <Formik
                initialValues={{ customer_id: '', amount: '', reason_id: '', validity: '' }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, setFieldValue, values, errors }) => (
                    <Form>
                        <DialogContent>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                {!props.hideCustomer ? (
                                    <>
                                        <Box className="button-container">
                                            {Object.values(addCreditTo).map((btnType) => (
                                                <span className={`${selectedType === btnType ? 'btn' : 'not-selected'}`} onClick={() => handleClick(btnType)}>{btnType}</span>
                                            ))}
                                        </Box>
                                        {selectedType === addCreditTo.CUSTOMERS && <FormControl fullWidth>
                                            <Autocomplete
                                                options={customers}
                                                getOptionLabel={(option) => option.name || ''}
                                                filterOptions={(options, state) => options}
                                                value={customers.find(c => c.id === values.customer_id) || null}
                                                freeSolo
                                                onClose={() => {
                                                    setSearchKeyword(''); // Clear the searchKeyword state when Autocomplete closes
                                                }}
                                                onInputChange={(x) => {
                                                    if (x?.target) {
                                                        setSearchKeyword(x.target.value);
                                                    } else {
                                                        setSearchKeyword('');
                                                    }
                                                }}
                                                onChange={(event, newValue) => {
                                                    setFieldValue('customer_id', newValue ? newValue.id : '');
                                                    handleCustomerChange({ target: { value: newValue ? newValue.id : '' } });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Customer"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                )}
                                                renderOption={(props, option) => (
                                                    <li {...props} key={option.id}>
                                                        <ListItem>
                                                            <ListItemAvatar sx={{
                                                                position: 'relative',
                                                                display: 'inline-block',
                                                                verticalAlign: 'middle',
                                                                '.countryflag': {
                                                                    position: 'absolute',
                                                                    bottom: '-2px',
                                                                    right: '12px',
                                                                    height: '18px',
                                                                    width: '18px',
                                                                }
                                                            }}>
                                                                <Avatar src={option.image_url} />
                                                                <img className="countryflag" src={option.customer_limited_address.flag_thumbnail} title={"bel"} />
                                                            </ListItemAvatar>
                                                            <ListItemText primary={option.name} />
                                                            <span style={{ height: '1rem', width: '1rem' }}>
                                                                {showBadge(option.source || null)}
                                                            </span>
                                                        </ListItem>
                                                    </li>
                                                )}
                                                ListboxProps={{
                                                    onScroll: handleScroll,
                                                    ref: listInnerRef
                                                }}
                                            />
                                            <ErrorMessage name="customer_id" component="div" style={{ color: 'red' }} />
                                        </FormControl>}
                                        {selectedType === addCreditTo.USERS && <Field
                                            as={TextField}
                                            type="text"
                                            value={addCreditTo.USERS}
                                            disabled
                                            fullWidth
                                            sx={{
                                                '& legend': {
                                                    display: 'none',
                                                }
                                            }}
                                        />}
                                        {selectedType === addCreditTo.DEALERS && <Field
                                            as={TextField}
                                            type="text"
                                            value={addCreditTo.DEALERS}
                                            disabled
                                            fullWidth
                                            sx={{
                                                '& legend': {
                                                    display: 'none',
                                                }
                                            }}
                                        />}
                                    </>
                                ) : null}
                                <FormControl>
                                    <Field
                                        as={TextField}
                                        type="number"
                                        label={`Credit amount to be ${props.popupName === 'add' ? 'added' : 'removed'}`}
                                        name="amount"
                                        fullWidth
                                    />
                                    <ErrorMessage name="amount" component="div" style={{ color: 'red' }} />
                                </FormControl>
                                <FormControl>
                                    <Field
                                        as={TextField}
                                        type="number"
                                        label={`Validity`}
                                        name="validity"
                                        fullWidth
                                        inputProps={{ min: 1, max: 365 }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">Days</InputAdornment>,
                                        }}
                                    />
                                    <ErrorMessage name="validity" component="div" style={{ color: 'red' }} />
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel>Reason</InputLabel>
                                    <Select
                                        value={values.reason_id}
                                        onChange={(e) => setFieldValue('reason_id', e.target.value)}
                                        label="Reason"
                                    >
                                        {reasons.map((reason) => (
                                            <MenuItem key={reason.id} value={reason.id}>
                                                {reason.name.en}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <ErrorMessage name="reason_id" component="div" style={{ color: 'red' }} />
                                </FormControl>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => props._showPopup(false)} className='text-secondary border border-secondary rounded-3'>
                                Cancel
                            </Button>
                            <Button type="submit" className='btn' variant="contained" color="primary" disabled={buttonDisabled}>
                                Submit
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
});

export default AddCreditPopup;
