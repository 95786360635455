import React, { useState } from "react";
import { Link } from "react-router-dom";
import MainLayout from "../../../assets/Layout/MainLayout";
import AdminPanel from "./AdminPanel";
import BackOffice from "./BackOffice";
import FrontOffice from "./FrontOffice";
import { FeaturesWrapper } from "./style/features";
const Features = () => {
  const [tabview, setTabView] = useState("frontOffice");
  const [userAccess, _userAccess] = useState(JSON.parse(localStorage.getItem("permissions")));

  let featuresAccess = userAccess.find((o) => o.slug === "features_management");

  return (
    <>
      <FeaturesWrapper>
        <div className="auctionMainContainer">
          <div className="mt-4">
            <h1>FEATURES</h1>
          </div>
          <div className="d-flex justify-content-center mt-2">
            <div className="tabBtn d-flex">
              <button className={tabview === "frontOffice" ? "activeTab" : ""} onClick={() => setTabView("frontOffice")}>
                Front office
              </button>
              {/* <button className={tabview === "backOffice" ? "activeTab" : " "} onClick={() => setTabView("backOffice")}>
                Nomad
              </button> */}

              <button className={tabview === "adminPanel" ? "activeTab" : " "} onClick={() => setTabView("adminPanel")}>
                Admin panel
              </button>
            </div>
          </div>
          <div>
            <div className="head">
              <div>
                {tabview === "frontOffice" ? <FrontOffice featuresAccess={featuresAccess} /> : ""}
                {tabview === "backOffice" ? <BackOffice featuresAccess={featuresAccess} /> : ""}
                {tabview === "adminPanel" ? <AdminPanel featuresAccess={featuresAccess} /> : ""}
              </div>
            </div>
          </div>
        </div>
      </FeaturesWrapper>
    </>
  );
};

export default Features;
