import styled from "styled-components";

export const SideNavWrapper = styled.div`

.open{
    transform: rotate(-90deg);
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  .close{
    transform: rotate(90deg);
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  .openMenu{
    transform: rotate(0deg);
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  .closeMenu{
    transform: rotate(180deg);
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  .show{
    display:block;
    transition: all 0.80s ease-in-out;
  }
  .hide{
    display:none;
    transition: all 0.80s ease-in-out;
  }
  .slide-nav a{
    text-decoration:none;
  }
  .active-view {
    background:transparent;
  }
  .active-view.active{
    background : rgba(93,120,255,0.07);
  }
`;