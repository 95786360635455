export const getName = (fname,lname) =>{
    let name
    if (fname !== null && fname !== "" && fname !== undefined) {
        if (fname.first_name !== null || fname.last_name !== null) {
            name = `${fname.first_name !== null ? fname.first_name : ""} ${
            fname.last_name !== null ? fname.last_name : ""
          }`;
        } else {
            name = fname.pseudo;
        }
      } else {
        
        if (lname !== null && lname !== undefined && lname !== '') {
          if (lname.first_name !== null || lname.last_name !== null) {
            name = `${lname.first_name !== null ? lname.first_name : ""} ${
              lname.last_name !== null ? lname.last_name : ""
            }`;
          } else {
            name = lname.pseudo;
          }
        }
      }
      return name
}