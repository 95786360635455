import React, { useEffect, useState } from "react";
import MainLayout from "../../../assets/Layout/MainLayout";
import { EditVehicleWrapper } from "../style/EditVehicle";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { handleColorImage } from "../../../core/utils/handleImageEvents/handleColorImage";  
import { ProductTypes } from "../../../config/constant";
import Toggle from "../components/Toggle";
import { SelectImageEdit, SelectImageHeading } from "../../../assets/SharedComponents/SelectImage";

let name = {}

const EditVehicleBody = () => {

  const [searchParams, setSearchParams] = useSearchParams();

  const [languages,_languages] = useState(JSON.parse(localStorage.getItem("languages")))
  const [countries,_countries] = useState(JSON.parse(localStorage.getItem("countries")))

  const [loading,_loading] = useState(false)

  const [colorImage,_colorImage] = useState('')
  const [colorImageValue,_colorImageValue] = useState('')

  const [urlColor,_urlColor] = useState('')

  const { bodyId } = useParams(); 
  const [isBacklink, _isBacklink] = useState(false)
  const [productType , _productType] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    getBodyDetails()
    let language= languages
    let code = ''
    for( let i=0;i<language.length;i++){
      code = language[i].code
      name[code] =''
    }
  }, [])
 
  const getBodyDetails = () => {
    API.get(APP_URLS.SHOW_BODY + `/${bodyId}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          document.getElementById("productType").value = resp.data.bodyVehicle.product_type.item_name.en;
          setValues(resp.data.bodyVehicle.name);
          _urlColor(resp.data.bodyVehicle.media[0].original_url)
          _isBacklink(resp.data.bodyVehicle?.back_link===1?true:false) 
          _productType(resp.data.bodyVehicle.product_type.id)
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp?.data?.data !== undefined) {
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        } else if (resp?.data?.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp?.data?.error !== undefined) {
          error_message = resp?.data.error;
        } else {
          error_message = resp?.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const setValues = (data) => {
    Object.keys(data).map((val, index) => {
      let elem = document.getElementById(val)
      if(elem!==null){
        elem.value = data[val]
        name[val] = data[val];
      }
    });
  };

 


  const saveImage =(value,respId) =>{
    var formdata = new FormData();
    formdata.append("icon",value)
    formdata.append("type",2)
    formdata.append("model",1)
    API.post(APP_URLS.ADD_ICON + `/${respId}`,formdata)
    .then(res => {
      const resp = res.data;
      if(resp.success===true){
          _colorImage('')
          _colorImageValue('')
          navigate(`/translation/vehicle-body?`+                  
              (searchParams.get("product_type_id") !== null
                ? "product_type_id=" + searchParams.get("product_type_id") + "&"
                : "") +              
              (searchParams.get("name") !== null
                ? "name=" + searchParams.get("name") + "&"
                : "") +
              (searchParams.get("per_page") !== null 
                ? "per_page=" + searchParams.get("per_page") + "&"
                : "") +
              (searchParams.get("page") !== null
                ? "page=" + searchParams.get("page")
                : "")
            )
      }
      else{
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })
    .catch(function (error) {  
      const resp = error.response;
      const error_message = '';
      if (resp.data.data !== undefined && resp.data.data !== null) {
        {
          Object.keys(resp.data.data).map(
            (error, index) =>(error_message = resp.data.data[error][0])
          );
        }
      } else if (resp.data.data?.error !== undefined) {
          error_message =  resp.data.data.error;
      } else if(resp.data?.error!== undefined){
          error_message =  resp.data.error
      } else{
          error_message =  resp.data.message
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT
      })
    });
}

  const handleTitle = (val,code) =>{
    name[code] = val
  }

  const submitForm = () =>{
    let data = {}
    let flag = false
    data["name"] = name
    if(productType == ProductTypes.car) data['back_link'] =  Number(isBacklink)
    if((name["en"]===""||name["en"]===undefined||name["en"]===null) && (name["fr"]===""||name["fr"]===undefined||name["fr"]===null )){
      flag = false
      toast.error("Body Title in English and French is mandatory", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else if(name["en"]===""||name["en"]===undefined||name["en"]===null){
      flag = false
      toast.error("Please provide Body in English", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else if(name["fr"]===""||name["fr"]===undefined||name["fr"]===null){
      flag = false
      toast.error("Please provide Body in French", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else if((colorImageValue===null||colorImageValue===undefined||colorImageValue==="")&&(urlColor===""||urlColor===null)){
      toast.error("Please provide Icon", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else{
      flag = true
    }
    if(flag===true){
      if(!loading){
        _loading(true)
        API.post(APP_URLS.UPDATE_BODY + `/${bodyId}?_method=patch`,data)
        .then(res => {
          _loading(false)
          const resp = res.data;
          if(resp.success===true){
            toast.success("Vehicle Body Updated Successfully.", {
              position: toast.POSITION.TOP_RIGHT
            })
            if(urlColor===""||urlColor===null){
              saveImage(colorImageValue,bodyId)
            }else{
              navigate(`/translation/vehicle-body?`+                  
              (searchParams.get("product_type_id") !== null
              ? "product_type_id=" + searchParams.get("product_type_id") + "&"
              : "") +              
              (searchParams.get("name") !== null
              ? "name=" + searchParams.get("name") + "&"
              : "") +
              (searchParams.get("per_page") !== null 
              ? "per_page=" + searchParams.get("per_page") + "&"
              : "") +
              (searchParams.get("page") !== null
              ? "page=" + searchParams.get("page")
              : "")
              )
            }
          }
          else{
            toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT
            })
          }
        })
        .catch(function (error) {  
            _loading(false)
            const resp = error.response;
            let error_message = '';
            if (resp.data.data !== undefined && resp.data.data !== null) {
              {
                Object.keys(resp.data.data).map(
                  (error, index) =>(error_message = resp.data.data[error][0])
                );
              }
            } else if (resp.data.data?.error !== undefined) {
                error_message =  resp.data.data.error;
            } else if(resp.data?.error!== undefined){
                error_message =  resp.data.error
            } else{
                error_message =  resp.data.message
            }
            toast.error(error_message, {
              position: toast.POSITION.TOP_RIGHT
            })
          });
      }
    }
  } 
  return (
    <>
      <EditVehicleWrapper>
        <div className='mt-2' >
          <div className="backContainer ms-4" onClick={()=>navigate("/translation/vehicle-body?"+                  
            (searchParams.get("product_type_id") !== null
              ? "product_type_id=" + searchParams.get("product_type_id") + "&"
              : "") +              
            (searchParams.get("name") !== null
              ? "name=" + searchParams.get("name") + "&"
              : "") +
            (searchParams.get("per_page") !== null 
              ? "per_page=" + searchParams.get("per_page") + "&"
              : "") +
            (searchParams.get("page") !== null
              ? "page=" + searchParams.get("page")
              : "")
          )}>
            <span className='backButton'>x</span>
            <span className='px-3 backButton'>|</span>
            <span className='backButton'>Cancel</span>
          </div>
        </div>
        <div className="headerContainer">
          <h1>EDIT BODY</h1>
        </div>
        <div className="d-flex justify-content-between save-btn mt-3">
          <p className="fieldLabel">Title</p>
          <button className={(loading?"disabled":"")+" add-button"} onClick={submitForm} disabled={loading}>Save</button>
        </div>
        <div className="row input-fileds">
          {languages.map((item,index)=>{
              let itemCode = item.code
              let flag = countries.find(o => o.id === item.country_id)?.flag_thumbnail
              return <div className="col-md-4" key={index}>
                <div className="col-md-12 mt-3">
                  <label htmlFor="title" className="form-label">
                    <span className="countryIcon px-2">
                      <img src={flag} alt="" className="countryflag" title={item.name}/>
                    </span>
                    <span className="langName">{item.code}</span>
                  </label>
                  <input
                    type="text"
                    id={itemCode}
                    name="title"
                    className="form-control departmentFields"
                    onChange={(e,code = itemCode)=>{
                      handleTitle(e.target.value,code)
                    }}
                  />
                </div>
            </div>
            })}
        </div>
        <div className="row mt-5 input-fileds align-items-end">        
          <div className="col-md-4">
            <div className="row">
              <div className="col-md-12">
                <label htmlFor="productType" className="form-label product-type fieldLabel">
                  Product Type
                </label>
                <input type="text" name="productType" id="productType" className="form-control mt-3 disabled" disabled />
              </div>
            </div>
          </div>
          {
            ( productType == ProductTypes.car ) ? <Toggle  label={'Is Backlink?'} checked={isBacklink} handleChecked={(e) => _isBacklink(e)} />   : null
          }  
        </div>
        <div className="row mt-5">
          <div className="col-md-4">
            <div className="row">
              <SelectImageHeading/>  
              <div className="col-md-6 mt-2">
                <SelectImageEdit type='web' urlColor={urlColor} _urlColor={_urlColor} colorImage={colorImage} handleColorImage={(e)=>handleColorImage(e,_colorImage,_colorImageValue, 'web')} /> 
              </div>
            </div>
          </div>
        </div>
      </EditVehicleWrapper>
    </>
  );
};

export default EditVehicleBody;
