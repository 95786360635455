import React, { useEffect,  useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataProviderWrapper } from "./style/DataProviderWrapper";
import API from "../../api/axios";
import { APP_URLS } from "../../api/url";
import { toast } from "react-toastify"; 
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { 
  FaFilterIcon, 
} from "../../assets/Images/icon"; 
import waste from "../../assets/Images/image/Waste.png";
import { ThreeDots } from "react-loader-spinner";
import NoRecord from "../../assets/SharedComponents/Table/NoRecord"; 
import back from "../../assets/Images/icons/svgIcon/back-arrow.svg";

import { 
  ADVERTISERS_PROVIDERS,
} from "../../config/constant"; 
import { confirmAlert } from "react-confirm-alert"; 
import Pagination from "../../assets/SharedComponents/Pagination";
import RecordCount from "../../assets/SharedComponents/RecordCount/RecordCount";

const DataProvider = () => { 
  const [countValue, _countValue] = useState(""); 
  const [loaded, _loaded] = useState(false); 
  const [advertiserId, _advertiserId] = useState(""); 
  const [providerId, _providerId] = useState("");
  const [data, _data] = useState([]); 
  const [isAdding, _isAdding] = useState(false);
  const [deleted, _deleted] = useState(false);
  const [added, _added] = useState(false);
  const [filterProviderId, _filterProviderId] = useState('')
  const [filters, _filters] = useState(false);
  const [trashed , _trashed] = useState(true)
  const [pageData, _pageData] = useState({
    totalPage:0,
    total:0,
    page:1,
    per_page:''
  })
  const navigate = useNavigate()

  useEffect(() => {
    getAdvertisersData();
  }, [ countValue,filterProviderId, trashed,  deleted, added, pageData.page, pageData.per_page]);

  const getAdvertisersData = () => {
    _loaded(true);
    let urlParams = { 
      with_trashed:trashed ? 1 : 0,
      page: pageData.page,
      per_page: pageData.per_page,
    };
    if (filterProviderId != "") {
      urlParams["filter[provider_id]"] = filterProviderId;
    }
    let restUrl = new URLSearchParams(urlParams);
    API.get(APP_URLS.ADVERTISERS_DATA + `?${restUrl}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success) {
          _data(resp.data.data);  
          _loaded(false);
          _pageData({
            ...pageData,
            totalPage:resp.data.last_page,
            total:resp.data.total,
          })
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loaded(false);
        }
      })
      .catch(function (error) {
        _loaded(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map(
              (error, index) => (error_message = resp.data.errors[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  const clearFilter = () => { 
    _filters(true);
    _trashed(true)
    _filterProviderId('') 
      _pageData({
        ...pageData,
        totalPage:0,
        setPage:1,
        per_page:10,
      })
  };

  const handleSubmit = () => {
    const formData = new FormData();
    const alphanumericRegex = /^[a-zA-Z0-9]+$/;
    if(advertiserId == '' ){
      toast.error("Advertiser ID field is required.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (!alphanumericRegex.test(advertiserId)) {
      toast.error("Please use only letters and numbers.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    formData.append("provider_id", providerId);
    formData.append("advertiser_id", advertiserId);
    API.post(APP_URLS.ADVERTISERS_DATA, formData)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) { 
          _isAdding(false);
          _providerId("");
          _advertiserId("");
          _added(prev => !prev);
          toast.success('Record added successfully', {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map(
              (error, index) => (error_message = resp.data.data[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

   // handle per page data changes
   const handleSearchChange = (searchValue) => {
      if (searchValue !== "") {
        _pageData({
          ...pageData,
          totalPage:0,
          setPage:1,
          per_page: searchValue,
        })
    }
  };

  const handleCancel = () => {
    _isAdding(false);
    _providerId("");
    _advertiserId("");
  };

  const handleDelete = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: `Are you sure you want to delete?`,
      closeOnEscape: true,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            API.delete(APP_URLS.ADVERTISERS_DATA + `/${id}`)
              .then((res) => {
                const resp = res.data;
                if (resp.success === true) {
                  _deleted(prev=> !prev);
                  toast.success("Deleted successfully.", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                } else {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              })
              .catch(function (error) {
                const resp = error.response;
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                  {
                    Object.keys(resp.data.data).map(
                      (error, index) =>
                        (error_message = resp.data.data[error][0])
                    );
                  }
                } else if (resp.data.data?.error !== undefined) {
                  error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                  error_message = resp.data.error;
                } else {
                  error_message = resp.data.message;
                }
                toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          },
        },
        {
          label: "No",
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-custom-class-name",
    });
  };
  return (
    <DataProviderWrapper>
      <div className="auctionMainContainer">
      <div className="row mt-4 top-header">
            <div className="col-md-4 col-sm-4 col-xs-4 go-back  d-flex align-items-center">
                <img src={back} className="backbtn"  onClick={()=>{ navigate(-1) }} />
            </div>
             <div className="col-md-4 col-sm-4 col-xs-4">
              <h1> Advertisers</h1>
            </div> 
        </div> 
        <div className="justify-content-between mb-2 d-flex gap-2 mb-2 select-info align-items-center">
          <Button
            className={isAdding ? " add-disable opacity-40 " : ""}
            variant="contained"
            onClick={() => _isAdding(true)}
          >
            Add + 
          </Button>
          <div className="justify-content-end mb-2 d-flex gap-2 mb-2 select-info align-items-center">
            <div className="select-wrapper">
              <select
                className="filterBox"
                onChange={(e) => {
                  _filterProviderId(e.target.value);
                }}
                value={filterProviderId}
              >
                <option value={""}>All</option>
                {ADVERTISERS_PROVIDERS.map((item) => {
                  return <option value={item.id}>{item.name}</option>;
                })}
              </select>
            </div> 
            <RecordCount
              onSearchClick={(search) => handleSearchChange(search)}
              id="pageCount"
              val={pageData.per_page}
              filters={filters}
              _filters={_filters} 
            />
            <Tooltip title="Clear Filter" placement="bottom">
              <FaFilterIcon
                className="filter-icon"
                role="button"
                onClick={clearFilter}
              />
            </Tooltip>
          </div>
        </div>
        {isAdding ? (
          <div className="d-flex gap-3 mt-3 advertiser-form ">
            <TextField
              id="outlined-basic"
              type="text"
              label="Advertiser ID"
              variant="outlined"
              className="form-control "
              onChange={(e) => _advertiserId(e.target.value)}
            />
            <FormControl className="select-wrap">
              <InputLabel id="demo-simple-select-label">
                Select Provider
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={providerId}
                label="Select Provider"
                onChange={(event) => _providerId(event.target.value)}
              >
                {ADVERTISERS_PROVIDERS.map((item) => {
                  return <MenuItem value={item.id}>{item.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <Button variant="contained" onClick={() => handleSubmit()}> 
              Submit
            </Button>
            <Button variant="outlined" onClick={() => handleCancel()}> 
              Cancel
            </Button>
          </div>
        ) : null}
      </div>
      <div className="d-flex align-items-center justify-content-between info-wrap">
        <div className="justify-content-end mt-2 me-2">
          <span className="text-start">
            Total Records : <b>{ pageData.total ||'0' }</b>
          </span>

        </div>
            <div className="mt-1">
              <input type="checkbox" checked={!trashed} onChange={()=>{ 
                _trashed(!trashed)
                }}/>
              <span className="ms-2">Hide Deleted Data</span>
            </div>
      </div>
      <div className="tableContainer">
        <table className="auctionTable reviewTable">
          <thead className="tableHead">
            <tr className="table-row">
              <th className="time-start"> Advertiser ID</th>
              <th>Provider</th>
              <th>Action</th>
            </tr>
          </thead>
          {loaded ? (
            <div className="d-flex justify-content-center three-dot">
              <ThreeDots
                height="150"
                width="150"
                radius="9"
                color="#637df4"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          ) : (
            <tbody>
              {data && data.length > 0 ? (
                data.map((item) => {
                  const isDeleted = item?.deleted_at;
                  return (
                    <tr
                      className={
                        (isDeleted ? "deletedRow " : " ") + "table-row"
                      }
                      key={item.id}
                    >
                      <td>{item?.advertiser_id || ""}</td>
                      <td>
                        <span>
                          {
                            ADVERTISERS_PROVIDERS.find(
                              (i) => i.id == item?.provider_id
                            )?.name
                          }
                        </span>
                      </td>
                      <td>
                        {!isDeleted ? (
                          <Button
                            onClick={() => {
                              handleDelete(item.id);
                            }}
                          >
                            <img src={waste} alt="f" />
                          </Button>
                        ) : (
                          "Deleted"
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <NoRecord />
              )}
            </tbody>
          )}
        </table>
      </div> 
      <div>
        {pageData.totalPage > 1 ? (
          <Pagination
            totalPages={pageData.totalPage}
            page={pageData.page}
            key={pageData.totalPage}
            onPageClick={(page) => _pageData({ ...pageData, page: page + 1 })}
          />
        ) : (
          ""
        )}
      </div>
    </DataProviderWrapper>
  );
};

export default DataProvider;
