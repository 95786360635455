import React, { useEffect, useState } from "react";
import error from "../../../assets/Images/icons/alert-tri.svg";
import feature from "../../../assets/Images/icons/faDoc2.svg";
import edit from "../../../assets/Images/image/edit.png";
import { TableWrapper } from "../style/table";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import Pagination from "../../../assets/SharedComponents/Pagination";
import SideTranslate from "../components/SideTranslate";
import MainLayout from "../../../assets/Layout/MainLayout";
import NoRecord from "../../../assets/SharedComponents/Table/NoRecord";
import { getName } from "../../../core/utils/getName/getName";
import { Tooltip } from "@mui/material";
import { FaFilterIcon } from "../../../assets/Images/icon";
import { ThreeDots } from "react-loader-spinner";
import RecordCount from "../../../assets/SharedComponents/RecordCount/RecordCount"


const type=[
  {
    type:'1',
    name:'Admin Panel',
  },
  {
    type:'2',
    name:'Front Office',
  },
  {
    type:'3',
    name:'Nomad',
  }
]

const Feature = () => {

  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const [searchParams, setSearchParams] = useSearchParams();

  const [features, _features] = useState([]);
  const [countries, setcountries] = useState(JSON.parse(localStorage.getItem("countries")));
  const [languages, setlanguages] = useState(JSON.parse(localStorage.getItem("languages")));

  const [page, setPage] = useState(searchParams.get('page')!==null?parseInt(searchParams.get('page')):1);
  const [total, _total] = useState(0);
  const [totalPage, _totalPage] = useState(0);
  const [countValue, _countValue] = useState(searchParams.get('per_page')!==null?searchParams.get('per_page'):"");

  const [featureType, _featureType] = useState(searchParams.get('type')!==null?searchParams.get('type'):"");

  const [filters, _filters] = useState(false);
  const [loaded, _loaded] = useState(false);

  const [userAccess,_userAccess] = useState(JSON.parse(localStorage.getItem('permissions')))

  let translationAccess = userAccess.find(o=>o.slug ==="translation")


  useEffect(() => {
    getFeatures();
  }, [page,featureType,countValue]);

  const getFeatures = () => {
    _loaded(true);
    API.get(APP_URLS.LIST_FEATURES + `?page=${page}&per_page=${countValue}&filter[type]=${featureType}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _features(resp.data.data);
          _totalPage(resp.data.last_page);
          _loaded(false);
          _total(resp.data.total);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loaded(false);
        }
      })
      .catch(function (error) {
        _loaded(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleSearchChange = (searchValue) => {
    _countValue(searchValue);
    if (searchValue !== ""&&searchValue!==countValue) {
      _totalPage(0)
      setPage(1);
    }
  };

  const clearFilter = () => {
    _filters(true)
    _featureType("");
    handleSearchChange('')
    if (featureType !== ""||countValue!=="") {
      _totalPage(0)
      setPage(1);
    }
    for(var value of query.keys()) {
      const param = searchParams.get(value);
      if (param) {
        searchParams.delete(value);
        setSearchParams(searchParams);
      }
     }
  };
  return (
    <TableWrapper>
      <div className="row">
        <div className="col-lg-2 col-md-3 col-sm-4 mt-3">
          <h4 className="translation text-center mb-3">TRANSLATION</h4>
          <SideTranslate />
        </div>
        <div className="col-lg-10 col-md-9 col-sm-8 mt-2">
          <div className="headerContainer">
            <h1>FEATURE</h1>
          </div>
          <div className="d-flex justify-content-end mb-2 gap-2 flex-wrap align-items-center gap-2">
            <select
              name="productType"
              id="productType"
              className="filterBox"
              onChange={(e) => {
                setPage(1);
                _totalPage(0);
                _featureType(e.target.value);
              }}
              value={featureType}
            >
              <option value="">Product Type</option>
              {type.map((item, index) => {
                return (
                  <option value={item.type} key={index}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            <RecordCount
              onSearchClick={(search) => handleSearchChange(search)}
              id="pageCount"
              val={countValue}
              filters={filters}
              _filters={_filters} />
            <Tooltip title="Clear Filter" placement="bottom">
                <FaFilterIcon className="filter-icon" role="button" onClick={clearFilter} />
            </Tooltip>
          </div>
          <div className="d-flex align-items-center justify-content-between info-wrap">
            <div className="justify-content-end mt-1 me-2">
                <span className='text-start'>Total Records : <b>{total}</b></span>
            </div>
          </div>
          
          <div className="tableContainer">
            <table className="auctionTable">
              <thead className="tableHead">
                <tr className="table-row">
                  <th className="tableHead-1">ID</th>
                  <th className="tableHead-2">Language</th>
                  <th className="tableHead-3">Name</th>
                  <th className="tableHead-3">Description</th>
                  <th className="tableHead-4">Updated by</th>
                  <th className="tableHead-5">Created by</th>
                  <th className="tableHead-6">Slug</th>
                  <th className="tableHead-9">Action</th>
                </tr>
              </thead>
              {
            loaded ? 
            <div className='d-flex justify-content-center three-dot'>              
            <ThreeDots 
              height="150" 
              width="150" 
              radius="9"
              color="#637df4" 
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
              :
              <tbody>
                {features.length > 0 ? (
                  features.map((item, index) => {
                    let createdBy = getName(item.create_activity_log, item.update_activity_log);
                    let upadtedBy = getName(item.update_activity_log, item.create_activity_log);
                    return (
                      <tr className="table-row" key={index}>
                        <td className="tableHead-1">{item.id}</td>
                        <td className="tableHead-2">
                          {Object.keys(item.name).map((val, index) => {
                            let country_id;
                            let flag;
                            let countryValues = languages.find((o) => o.code === val);
                            if (countryValues) {
                              country_id = countryValues.country_id;
                              flag = countries.find((o) => o.id === country_id)?.flag_thumbnail;
                            }
                            return (
                              <div key={index} className="languageSelected d-flex gap-2">
                                {countryValues ? (
                                  <>
                                    <img src={flag} alt="" title={countryValues?.name}/>
                                    <span className="flagName">{val}</span>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            );
                          })}
                        </td>
                        <td className="tableHead-3">
                          {Object.keys(item.name).map((val, index) => {
                            let countryValues = languages.find((o) => o.code === val);
                            return item.name[val] !== null && item.name[val] !== "" ? (
                              <span key={index} className="nameTranslated">
                                {countryValues ? item.name[val] : ""}
                              </span>
                            ) : (
                              <span key={index} className="nameTranslated">
                                <img src={error} alt="" className="warningIcon" />
                              </span>
                            );
                          })}
                        </td>
                        <td className="tableHead-3">
                          {Object.keys(item.description).map((val, index) => {
                            let countryValues = languages.find((o) => o.code === val);
                            return item.description[val] !== null && item.description[val] !== "" ? (
                              <span key={index} className="nameTranslated">
                                {countryValues ? item.description[val] : ""}
                              </span>
                            ) : (
                              <span key={index} className="nameTranslated">
                                <img src={error} alt="" className="warningIcon" />
                              </span>
                            );
                          })}
                        </td>
                        <td>{upadtedBy !== "" && upadtedBy !== undefined ? upadtedBy.charAt(0).toUpperCase() + upadtedBy.slice(1) : "-"}</td>
                        <td>{createdBy !== "" && createdBy !== undefined ? createdBy.charAt(0).toUpperCase() + createdBy.slice(1) : "-"}</td>
                        <td className="tableHead-6">{item.slug}</td>
                        <td className="tableHead-9">
                          {translationAccess.permissions.find((o) => o.slug === "update") ? (
                            <NavLink to={`/translation/edit-feature/${item.id}?`+(featureType!==""?"type="+featureType+"&":"")+(countValue!==""?"per_page="+countValue+"&":"")+(page!==1?"page="+page:"")}>
                              <img src={edit} alt="" className="mx-2" role="button" title="Edit" />
                            </NavLink>
                          ) : (
                            ""
                          )}
                          <NavLink to={`/translation/feature/permissions/${item.id}?`+(featureType!==""?"type="+featureType+"&":"")+(countValue!==""?"per_page="+countValue+"&":"")+(page!==1?"page="+page:"")}>
                            <img src={feature} alt="" className="mx-2" role="button" title="Permissions" />
                          </NavLink>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <NoRecord />
                )}
              </tbody>
          }
            </table>
          </div>
            <div>
              {totalPage > 1 ? <Pagination totalPages={totalPage} key={featureType} page={page} onPageClick={(page) => setPage(page + 1)} /> : ""}
            </div>
          
        </div>
      </div>
    </TableWrapper>
  );
};

export default Feature;
