 import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button } from '@mui/material';
import React, { useState } from 'react'

export default function ExpandableText({text}) {
  
    const maxLength = 80;
    const [showFulltext, setShowFulltext] = useState(false); 
    const truncatedtext = text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  
    return ( <>
      <span className="text-cell">
        {showFulltext ? text : truncatedtext}
       
      </span>
       {text.length > maxLength && (
         <>
          {showFulltext ? (
            <Button className="show-button" onClick={() => setShowFulltext(false)}><ExpandLessIcon/></Button>
            ) : (
              <Button className="show-button" onClick={() => setShowFulltext(true)}><ExpandMoreIcon/></Button>
              )}
        </>
      )}
      </>
    );
}
