import React, { useEffect, useState } from 'react'
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { CropperWrapper } from './style';
import reset from '../../../assets/Images/icons/svgIcon/reset.svg'

const CropperBox = ({_cropperPopup,imgSrc,_imageValue,fileUploaded,_fileUploaded,cropperPopup , aspectRatio}) => {

  const [cropper, setCropper] = useState();
  const [key,_key] =useState('')

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      _imageValue({
        type: "base64",
        value: cropper.getCroppedCanvas().toDataURL("image/webp",0.9),
      })
    }
    _cropperPopup(false)
    _fileUploaded(true)
  };


  const resetImg = () =>{
    _key(Math.random())
  }

  return (
    <CropperWrapper>
        <div className="modal fade show custom-modal modal-backdrop"> 
            <div className="modal">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Image Cropper</h5>
                    <img src={reset} alt="" className="mx-4 resetImg" role="button" onClick={()=>resetImg()} title="Reset"/>
                </div>
                <div className="modal-body">
                    <div className='preview'>
                    <Cropper
                        key={key}
                        style={{ height: 400, width: '100%' }}
                        zoomTo={0.5}
                        initialAspectRatio={aspectRatio}
                        aspectRatio={aspectRatio}
                        preview=".img-preview"
                        src={imgSrc}
                        minCropBoxHeight={10}
                        background={true}
                        minCropBoxWidth={10}
                        responsive={true}
                        autoCropArea={0.8}
                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                        onInitialized={(instance) => {
                        setCropper(instance);
                        }}
                        guides={true}
                    />
                    </div>
                </div>
                <div className="d-flex justify-content-end p-3">
                    <button className='btn cancel-btn btn-border' onClick={()=>{
                        _cropperPopup(false)
                        }}>Cancel</button>
                    <button className='btn ms-3' onClick={()=>getCropData()}>Save</button>
                </div>
                </div>
            </div>
            </div>
        </div>
    </CropperWrapper>
  )
}

export default CropperBox