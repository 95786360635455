import React, { useEffect, useState } from "react";
import MainLayout from "../../../assets/Layout/MainLayout";
import { TaxRatesWrapper } from "./style/taxRates";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import edit from "../../../assets/Images/image/edit.png";
import copy from "../../../assets/Images/icons/faLink.svg";
import waste from "../../../assets/Images/image/Waste.png";
import { NavLink, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { APP_URLS } from "../../../api/url";
import API from "../../../api/axios";
import { toast } from "react-toastify";
import Pagination from "../../../assets/SharedComponents/Pagination";
import NoRecord from "../../../assets/SharedComponents/Table/NoRecord";
import { getName } from "../../../core/utils/getName/getName";
import SearchKey from "../../../assets/SharedComponents/Search/SearchKey";
import CopyToClipboard from "react-copy-to-clipboard";
import { Tooltip } from "@mui/material";
import { FaFilterIcon } from "../../../assets/Images/icon";
import { ThreeDots } from "react-loader-spinner";
import RecordCount from "../../../assets/SharedComponents/RecordCount/RecordCount";

const TaxRates = () => {

  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const [searchParams, setSearchParams] = useSearchParams();

  const [data, _data] = useState([]);
  const [countries, setcountries] = useState(JSON.parse(localStorage.getItem("countries")));
  const [dataDeleted, _dataDeleted] = useState(false);

  const [page, setPage] = useState(query.get('page')!==null?parseInt(query.get('page')):1);
  const [totalPage, _totalPage] = useState(0);
  const [total, _total] = useState(0);


  const [searchkeyword, _searchkeyword] = useState(query.get('keyword')!==null?query.get('keyword'):"");
  const [countValue, _countValue] = useState(query.get('per_page')!==null?query.get('per_page'):"");


  const [filters, _filters] = useState(false);
  const [loaded, _loaded] = useState(false);


  const [userAccess,_userAccess] = useState(JSON.parse(localStorage.getItem('permissions')))
  
  let taxRatesAccess = userAccess.find(o=>o.slug ==="tax_rates")

  let navigate = useNavigate();

  useEffect(() => {
    getTaxRates();
    _dataDeleted(false);
  }, [dataDeleted, page ,searchkeyword,countValue]);

  const getTaxRates = () => {
    _loaded(true);
    API.get(APP_URLS.LIST_TAX_RATES + `?page=${page}&per_page=${countValue}&keyword=${searchkeyword}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _data(resp.data.data);
          _total(resp.data.total);
          _totalPage(resp.data.last_page);
          _loaded(false);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loaded(false);
        }
      })
      .catch(function (error) {
        _loaded(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          if(resp.data.data.message){
            error_message = resp.data.data.message
          }else{
            {
              Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
            }
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleSearchChange = (func, searchValue) => {
    func(searchValue);
    if((func===_countValue&&searchValue!==countValue)||(func===_searchkeyword&&searchValue!==searchkeyword)){
      if (searchValue !== "") {
        _totalPage(0)
        setPage(1);
      }
    }
  };

  const clearFilter = () => {
    _filters(true)
    handleSearchChange(_countValue, '');
    handleSearchChange(_searchkeyword, '');
    if (countValue !== '' ||searchkeyword !== "") {
      _totalPage(0)
      setPage(1);
    }
    for(var value of query.keys()) {
      const param = searchParams.get(value);
      if (param) {
        searchParams.delete(value);
        setSearchParams(searchParams);
      }
     }
  };

  return (
    <>
      <TaxRatesWrapper>
        <div className="row">
          <div className="col-lg-12 mt-2">
            <div className="headerContainer">
              <h1>TAX RATES</h1>
            </div>
            <div className="d-flex justify-content-between add-info flex-wrap">
              <div className="add-depertment d-flex justify-content-end mb-2">
                {taxRatesAccess.permissions.find((o) => o.slug === "add") ? (
                <div className="actionContainer">
                    <NavLink to="/create-tax-rates">
                      <button className="btn">+ Add</button>
                    </NavLink>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="justify-content-end mb-2 d-flex gap-2 mb-2 flex-wrap align-items-center">
                <SearchKey
                  onSearchClick={(search) => handleSearchChange(_searchkeyword,search)}
                  id="searchData"
                  filters={filters}
                  val={searchkeyword}
                  _filters={_filters}
                  placeholder="Search by Title"
                />
                <RecordCount
                  onSearchClick={(search) => handleSearchChange(_countValue, search)}
                  id="pageCount"
                  val ={countValue}
                  filters={filters}
                  _filters={_filters} />
                <Tooltip title="Clear Filter" placement="bottom">
                    <FaFilterIcon className="filter-icon" role="button" onClick={clearFilter} />
                </Tooltip>
          </div>
            </div>
            <div className="d-flex align-items-center justify-content-between info-wrap">
              <div className="justify-content-end mt-1 me-2">
                  <span className='text-start'>Total Records : <b>{total}</b></span>
              </div>
            </div>
              <div className="tableContainer">
              <table className="auctionTable">
                <thead className="tableHead">
                  <tr className="table-row">
                    <th>ID</th>
                    <th>Country</th>
                    <th>Display Name</th>
                    <th className="clipboard">Stripe ID</th>
                    <th>
                      <span className="d-block">Tax Type</span>
                      <span>Tax Rate</span>
                    </th>
                    <th>Status</th>
                    <th>Updated by</th>
                    <th>Created by</th>
                    {taxRatesAccess.permissions.find((o) => o.slug === "update") ? 
                    <th>Action</th> 
                    : "" }
                  </tr>
                </thead>
                {
              loaded ? 
              <div className='d-flex justify-content-center three-dot'>              
                  <ThreeDots 
                    height="150" 
                    width="150" 
                    radius="9"
                    color="#637df4" 
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              : 
                <tbody>
                  {data.length > 0 ? (
                    data.map((item, index) => {
                      let flag = countries.find((o) => o.id === item.country_id);
                      let createdBy = getName(item.create_activity_log, item.update_activity_log);
                      let upadtedBy = getName(item.update_activity_log, item.create_activity_log);

                      return (
                        <tr className="table-row" key={index}>
                          <td>{item.id}</td>
                          <td>
                            <img src={flag.flag_thumbnail} title={flag.fullname} alt="" className="countryflag" /> <span className="codeName ms-2">{item.code}</span>
                          </td>
                          <td>{item.display_name}</td>
                          <td className="clipboard">
                            <div className="my-2 d-flex justify-content-center">
                              <span className="copyText">{item.stripe_id}</span>
                              <CopyToClipboard text={item.stripe_id} onCopy={() => toast("Text Copied")}>
                                <img className="ms-2" src={copy} alt=""/>
                              </CopyToClipboard>
                            </div>
                          </td>
                          <td>
                            <span className="d-block">{(item.tax_type.charAt(0).toUpperCase()+item.tax_type.slice(1)).replace("_",' ')}</span>
                            <p>{item.percentage} % <span className="percentage">({item.inclusive===0?'Exclusive':'Inclusive'})</span></p>
                          </td>
                          <td>{item.active === 1 ? <span className="status status-active" title='Active'></span> : <span className="status status-end" title='Inactive'></span>}</td>
                          <td>{upadtedBy !== "" && upadtedBy !== undefined ? upadtedBy.charAt(0).toUpperCase() + upadtedBy.slice(1) : "-"}</td>
                          <td>{createdBy !== "" && createdBy !== undefined ? createdBy.charAt(0).toUpperCase() + createdBy.slice(1) : "-"}</td>
                          {
                          taxRatesAccess.permissions.find((o) => o.slug === "update") ? 
                            <td className="action">
                              {taxRatesAccess.permissions.find((o) => o.slug === "update") ? (
                                <button className="me-2" onClick={() => navigate(`/edit-tax-rates/${item.id}?`+(searchkeyword!==""?"keyword="+searchkeyword+"&":"")+(countValue!==""?"per_page="+countValue+"&":"")+(page!==1?"page="+page:""))}>
                                  <img src={edit} alt="f" />
                                </button>
                              ) : (
                                ""
                              )}
                            </td>
                          :''}
                        </tr>
                      );
                    })
                  ) : (
                    <NoRecord />
                  )}
                </tbody>
            }
              </table>
            </div>
            <div>
              {totalPage > 1 ? <Pagination totalPages={totalPage} key={totalPage} page={page} onPageClick={(page) => setPage(page + 1)} /> : ""}
            </div>
          </div>
        </div>
      </TaxRatesWrapper>
    </>
  );
};

export default TaxRates;
