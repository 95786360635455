import React, { useEffect, useState } from "react";
import { CraeteVehicleWrapper } from "../style/createVehicle";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

let name = {};

const CreateAdRemovalReasons = () => {

  const [languages, _languages] = useState(JSON.parse(localStorage.getItem("languages")));
  const [countries, _countries] = useState(JSON.parse(localStorage.getItem("countries")));

  const [loading,_loading] = useState(false)

  const navigate = useNavigate()

  const submitForm = () => {
    let data = {};
    let flag = false
    if((name["en"]===""||name["en"]===undefined||name["en"]===null) && (name["fr"]===""||name["fr"]===undefined||name["fr"]===null )){
      flag = false
      toast.error("Reason in English and French is mandatory", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else if(name["en"]===""||name["en"]===undefined||name["en"]===null){
      flag = false
      toast.error("Please provide Reason in English", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else if(name["fr"]===""||name["fr"]===undefined||name["fr"]===null){
      flag = false
      toast.error("Please provide Reason in French", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else{
      flag = true
    }
    if(flag===true){
      data["name"] = name;
      data["type"] = 4;
      if(!loading){
        _loading(true)
        API.post(APP_URLS.ADD_SUGGESTION_TEXT, data)
        .then((res) => {
            _loading(false)
            const resp = res.data;
            if (resp.success === true) {
              toast.success("Reason Added Successfully.", {
                position: toast.POSITION.TOP_RIGHT,
              });
              navigate("/translation/ad-removal-reasons");
              resetForm();
            } else {
              toast.error(resp.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
          .catch(function (error) {
            _loading(false)
            const resp = error.response;
            let error_message = "";
            if (resp.data.data !== undefined && resp.data.data !== null) {
              {
                Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
              }
            } else if (resp.data.data?.error !== undefined) {
              error_message = resp.data.data.error;
            } else if (resp.data?.error !== undefined) {
              error_message = resp.data.error;
            } else {
              error_message = resp.data.message;
            }
            toast.error(error_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      }
    }
  };

  useEffect(() => {
    let language = languages;
    let code = "";
    for (let i = 0; i < language.length; i++) {
      code = language[i].code;
      name[code] = "";
    }
  }, []);

  const handleTitle = (val, code) => {
    name[code] = val;
  };

  const resetForm = () => {
    let inputs = document.querySelectorAll(".departmentFields");
    inputs.forEach((input) => {
      input.value = "";
    });
  };
  return (
    <>
      <CraeteVehicleWrapper>
        <div className='mt-2' >
          <div className="backContainer ms-4" onClick={()=>navigate("/translation/ad-removal-reasons")}>
            <span className='backButton'>x</span>
            <span className='px-3 backButton'>|</span>
            <span className='backButton'>Cancel</span>
          </div>
        </div>
        <div className="headerContainer">
          <h1>CREATE AD REMOVAL REASONS</h1>
        </div>
        <div className="d-flex justify-content-between save-btn mt-3">
          <p className="fieldLabel">Title</p>
          <button className={(loading?"disabled":"")+" add-button"} onClick={submitForm} disabled={loading}>Save</button>
        </div>
        <div className="row input-fileds">
          {languages.map((item, index) => {
            let itemCode = item.code;
            let flag = countries.find((o) => o.id === item.country_id)?.flag_thumbnail;
            return (
              <div className="col-md-4" key={index}>
                <div className="col-md-12 mt-3">
                  <label htmlFor="title" className="form-label">
                    <span className="countryIcon px-2">
                      <img src={flag} alt="" className="countryflag" title={item.name}/>
                    </span>
                    <span className="langName">{item.code}</span>
                  </label>
                  <input
                    type="text"
                    id="title"
                    name="title"
                    className="form-control departmentFields"
                    onChange={(e, code = itemCode) => {
                      handleTitle(e.target.value, code);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </CraeteVehicleWrapper>
    </>
  );
};

export default CreateAdRemovalReasons;
