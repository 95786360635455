import React, { useEffect, useState } from "react";
import MainLayout from "../../../assets/Layout/MainLayout";
import { EditVehicleWrapper } from "../style/EditVehicle";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const EditVehicleTyreSizes = () => {

  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const { tireSizeId } = useParams();
  const [productValue, _productValue] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [diameter, setDiameter] = useState("");

  const [loading,_loading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    getTireSize();
  }, []);

  const getTireSize = () => {
    API.get(APP_URLS.SHOW_TIRE_SIZE + `/${tireSizeId}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          document.getElementById("width").value = resp.data.tireSize.width;
          document.getElementById("height").value = resp.data.tireSize.height;
          document.getElementById("diameter").value = resp.data.tireSize.diameter;
          setWidth(resp.data.tireSize.width);
          setHeight(resp.data.tireSize.height);
          setDiameter(resp.data.tireSize.diameter);
          _productValue(resp.data.tireSize.product_category_type.product_type.item_name.en)
          document.getElementById("productType").value = resp.data.tireSize.product_category_type.product_type.item_name.en;
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp?.data?.data !== undefined) {
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        } else if (resp?.data?.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp?.data?.error !== undefined) {
          error_message = resp?.data.error;
        } else {
          error_message = resp?.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const submitForm = () => {
    let data = {};
    data["width"] = width;
    data["height"] = height;
    data["diameter"] = diameter;
      if(productValue==='Car'){
        if (width === "" || width === undefined || width === null) {
          toast.error("Please provide tire width", {
            position: toast.POSITION.TOP_RIGHT
          })
        }
        else if (height === "" || height === undefined || height === null) {
          toast.error("Please provide tire height", {
            position: toast.POSITION.TOP_RIGHT
          })
        }
        else if (diameter === "" || diameter === undefined || diameter === null) {
          toast.error("Please provide tire diameter", {
            position: toast.POSITION.TOP_RIGHT
          })
        }
        else{
          saveData(data)
        }
      }
      else {
        if (diameter === "" || diameter === undefined || diameter === null) {
          toast.error("Please provide tire diameter", {
            position: toast.POSITION.TOP_RIGHT
          })
        }
        else{
          saveData(data)
        }
      }
  };

  const saveData = (data) =>{
    if(!loading){
      _loading(true)
      API.post(APP_URLS.UPDATE_TIRE_SIZE + `/${tireSizeId}?_method=patch`, data)
      .then((res) => {
        _loading(false)
        const resp = res.data;
        if (resp.success === true) {
          toast.success("Tire Size Added Successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate("/vehicles-management/vehicle-tire-size?"+
          (query.get("product_type_id") !== null
            ? "product_type_id=" + query.get("product_type_id") + "&"
            : "") +                    
          (query.get("width") !== null
            ? "width=" + query.get("width") + "&"
            : "") +
          (query.get("height") !== null
            ? "height=" + query.get("height") + "&"
            : "") +
          (query.get("diameter") !== null
            ? "diameter=" + query.get("diameter") + "&"
            : "") +
          (query.get("per_page") !== null 
            ? "per_page=" + query.get("per_page") + "&"
            : "") +
          (query.get("page") !== null
            ? "page=" + query.get("page")
            : "")
        )
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        _loading(false)
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
    }
  }


  return (
    <>
        <EditVehicleWrapper>
        <div className='mt-2' >
            <div className="backContainer ms-4" onClick={()=>navigate("/vehicles-management/vehicle-tire-size?"+
                  (query.get("product_type_id") !== null
                    ? "product_type_id=" + query.get("product_type_id") + "&"
                    : "") +                    
                  (query.get("width") !== null
                    ? "width=" + query.get("width") + "&"
                    : "") +
                  (query.get("height") !== null
                    ? "height=" + query.get("height") + "&"
                    : "") +
                  (query.get("diameter") !== null
                    ? "diameter=" + query.get("diameter") + "&"
                    : "") +
                  (query.get("per_page") !== null 
                    ? "per_page=" + query.get("per_page") + "&"
                    : "") +
                  (query.get("page") !== null
                    ? "page=" + query.get("page")
                    : "")
                )}>
              <span className='backButton'>x</span>
              <span className='px-3 backButton'>|</span>
              <span className='backButton'>Cancel</span>
            </div>
          </div>
          <div className="headerContainer">
            <h1>EDIT TIRE SIZES</h1>
          </div>
            <div className="d-flex justify-content-end save-btn mt-3">
            <button className={(loading?"disabled":"")+" add-button"} onClick={submitForm} disabled={loading}>Save</button>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label htmlFor="title" className="form-label fieldLabel">
                      Width
                    </label>
                    <input
                      type="number"
                      id="width"
                      name="title"
                      className="form-control mt-3 departmentFields"
                      rows="2"
                      onChange={(e) => setWidth(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label htmlFor="title" className="form-label fieldLabel">
                      Height
                    </label>
                    <input
                      type="number"
                      id="height"
                      name="title"
                      className="form-control mt-3 departmentFields"
                      rows="2"
                      onChange={(e) => setHeight(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label htmlFor="title" className="form-label fieldLabel">
                      Diameter
                    </label>
                    <input
                      type="number"
                      id="diameter"
                      name="title"
                      className="form-control mt-3 departmentFields"
                      rows="2"
                      onChange={(e) => setDiameter(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mt-4">
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="productType" className="form-label fieldLabel product-type d-flex justify-content-between">
                      <span>Product Type</span>
                    </label>
                    <input type="text" name="productType" id="productType" className="form-control mt-3 disabled" disabled />
                  </div>
                </div>
              </div>
            </div>
        </EditVehicleWrapper>
    </>
  );
};

export default EditVehicleTyreSizes;
