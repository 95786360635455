import { Tooltip, Typography, tooltipClasses } from '@mui/material';
import React, { useCallback, useEffect, useRef } from 'react'
import { styled } from '@mui/material/styles';
import useWindowDimensions from '../../../../assets/Screen Size'; 
import userPlaceholderImage from '../../../../assets/Images/icons/profile-pic.png'
import { useLocation } from 'react-router-dom';
function SimiliarUsers({ similiarUsers, _similiarUsers,hasMore}) {

  const { height, width } = useWindowDimensions(); 
  const observer = useRef(); 
  const customComponentRef = useRef(null);  
  const search = useLocation().search; 
  const query = new URLSearchParams(search);
 

  // this callback function will hit call for nextpage data when the last element of the list will be scrolled into view , 
  const lastItemElementRef = useCallback(
    (node) => { 
      if (similiarUsers.loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => { 
        if (entries[0].isIntersecting && hasMore) {
            _similiarUsers((prev) => {
            return { ...prev, currentPage: prev.currentPage + 1 };
          });
        }
      });
      if (node) observer.current.observe(node);
    },
    [similiarUsers.loading, hasMore]
  );
 

 // custom tooltip with innerhtml 
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip arrow  placement={width < 768 ? "top" : "left"} {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#fff',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 420,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
    '.MuiTooltip-arrow':{
      color:'lightgray'
    },
    '.custom-tooltip':{
      display:'flex',
      flexDirection:'column',
      fontSize:'15px',
      background:'white',
      gap:'10px',
      width:'20rem'
    } ,
    
  }));
  
  const User = ({item, isLast}) => {
     return  <HtmlTooltip
                title={
                  <div className='custom-tooltip'>
                        {
                          item?.pseudo ? 
                          <span> 
                            <b>Pseudo: </b>{item.pseudo}
                           </span> : null
                        }
                        {
                          item?.email ? 
                          <span> 
                            <b>Email: </b>{item.email}
                           </span> : null
                        } 
                        {
                          item?.phone ? 
                          <span> 
                            <b>Phone: </b>{item.phone}
                           </span> : null
                        } 
                        {
                          item?.limited_address ? <span>
                            <b>Address: </b> {(item.limited_address?.route ? item.limited_address.route+ ", " :'')+
                            (item.limited_address?.street_number ? item.limited_address.street_number+ ", " :'')+
                            (item.limited_address?.sub_locality ? item.limited_address.sub_locality+ ", " :'')+
                            (item.limited_address?.locality ? item.limited_address.locality+ ", " :'')+
                            (item.limited_address?.country ? item.limited_address.country+ ". " :'') }
                          </span> : null
                        } 
                  </div>
                }
              > 
                 <a
                  href={`/detail-member/${item.id}${ query.get("tab") != null ? '?tab='+ query.get("tab"): '' }` } 
                  ref={ isLast ? lastItemElementRef : null}
                  key={item.id}
                  target='_blank'
                  className={` similiar-user ${item.deleted_at ? 'deletedRow': ''}`}
                  >
                    <img src={item?.profile_picture_url || userPlaceholderImage} />
                    {item?.first_name || ''}  {" "} 
                    {item?.last_name || ''}  
                 </a>   
            </HtmlTooltip>
  }
  return <div className='col col-lg-2 col-md-3 col-sm-12 similiar-users  '>
          <div className='heading'>
              <span>Similiar Users</span>
          </div>
          <div ref={customComponentRef} className='user-cont'>
            {
              similiarUsers.data 
              && similiarUsers.data.length > 0 
              && similiarUsers.data.map((item,index)=> <User item={item} isLast={similiarUsers.data.length === index + 1} /> ) 
            }
            {
              similiarUsers.loading && <div>Loading ...</div>
            }
            {
              similiarUsers.error && <div>Error ...</div>
            } 
            {
              !similiarUsers.loading && similiarUsers.data.length ===0 && <div className='no-data'>No data found</div>
            }
              
          </div>
    </div> 
}

export default SimiliarUsers