import React, { useEffect, useState } from "react";
import MainLayout from "../../../../assets/Layout/MainLayout";
import Switch from "react-switch";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import API from "../../../../api/axios";
import { APP_URLS } from "../../../../api/url";
import { toast } from "react-toastify";
import { EditVehicleWrapper } from "../../style/EditVehicle";
import { handleColorImage } from "../../../../core/utils/handleImageEvents/handleColorImage";
import { SelectImageEdit, SelectImageHeading } from "../../../../assets/SharedComponents/SelectImage";

let name = {};

const EditProductType = () => {

  const [languages, _languages] = useState(JSON.parse(localStorage.getItem("languages")));
  const [countries, _countries] = useState(JSON.parse(localStorage.getItem("countries")));

  const [searchParams, setSearchParams] = useSearchParams();

  const [loading,_loading] = useState(false)
  
  const [checked,_checked]= useState(false)

  const [colorImageWeb,_colorImageWeb] = useState('')
  const [colorImageMobile,_colorImageMobile] = useState('')

  const [colorImageValueWeb,_colorImageValueWeb] = useState('')
  const [colorImageValueMobile,_colorImageValueMobile] = useState('')
  
  const [urlColorWeb,_urlColorWeb] = useState('')
  const [urlColorMobile,_urlColorMobile] = useState('')

  const navigate = useNavigate()

  const { productTypeId } = useParams();

  const handleChange = (e) => {
    _checked(e)
  };

  useEffect(() => {
    getProduct()
    let language= languages
    let code = ''
    for( let i=0;i<language.length;i++){
      code = language[i].code
      name[code] =''
    }
  }, [])

  const setValues = (data) => {
    Object.keys(data).map((val, index) => {
      let elem = document.getElementById(val)
      if (elem !== null) {
        elem.value = data[val]
        name[val] = data[val];
      }
    });
  };

  const getProduct =() =>{
    API.get(APP_URLS.SHOW_PRODUCT_TYPES+`/${productTypeId}`)
    .then(res => {
      const resp = res.data;
      if(resp.success===true){
        setValues(resp.data.productType.item_name)
        handleChange(resp.data.productType.active===1?true:false)
        document.getElementById('category').value =resp.data.productType.product_categories[0].name
        if(resp?.data?.productType?.media){
          const img1 = resp.data.productType.media[0].original_url;
          const img2 = resp.data.productType.media[1].original_url
          if(resp.data.productType.media[0].custom_properties.type == 'mobile') {
            _urlColorMobile(img1) 
            _urlColorWeb(img2)
          } else{
            _urlColorMobile(img2) 
             _urlColorWeb(img1)
          }   
        } 
      }
      else{
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })
    .catch(function (error) {  
      const resp = error.response;
      let error_message = '';
      if (resp.data.errors !== undefined) {
        {
          Object.keys(resp.data.errors).map(
            (error, index) => (error_message = resp.data.errors[error][0])
          );
        }
      } else if (resp.data.data?.error !== undefined) {
          error_message =  resp.data.data.error;
      } else if(resp.data?.error!== undefined){
          error_message =  resp.data.error
      } else{
          error_message =  resp.data.message
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT
      })
    });
  }
 
  const saveImage =(imgWeb , imgMobile,respId) =>{
    var formdata = new FormData();
    if(imgWeb) formdata.append("icon[web]",imgWeb)
    if(imgMobile) formdata.append("icon[mobile]",imgMobile) 
    formdata.append("model",6)
    API.post(APP_URLS.ADD_ICON + `/${respId}`,formdata)
    .then(res => {
      const resp = res.data;
      if(resp.success===true){
          _colorImageWeb('')
          _colorImageValueWeb('')
          _colorImageMobile('')
          _colorImageValueMobile('')
          navigate("/translation/vehicle-product-types?"+                  
          (searchParams.get("per_page") !== null 
            ? "per_page=" + searchParams.get("per_page") + "&"
            : "") +
          (searchParams.get("page") !== null
            ? "page=" + searchParams.get("page")
            : "")
        )
      }
      else{
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })
    .catch(function (error) {  
      const resp = error.response;
      let error_message = '';
      if (resp.data.data !== undefined && resp.data.data !== null) {
        {
          Object.keys(resp.data.data).map(
            (error, index) =>(error_message = resp.data.data[error][0])
          );
        }
      } else if (resp.data.data?.error !== undefined) {
          error_message =  resp.data.data.error;
      } else if(resp.data?.error!== undefined){
          error_message =  resp.data.error
      } else{
          error_message =  resp.data.message
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT
      })
    });
}

  const handleSubmit = () =>{
    let data = {}
    data["item_name"] = name
    data["active"] = checked?1:0
    let flag = false
    if (
      (name["en"] === "" || name["en"] === undefined || name["en"] === null) &&
      (name["fr"] === "" || name["fr"] === undefined || name["fr"] === null)
    ) {
      flag = false;
      toast.error("Product Type Title in English and French is mandatory", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if(!(colorImageValueWeb || urlColorWeb ) || !( colorImageValueMobile || urlColorMobile )){
      toast.error("Please provide icons", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else{
      flag = true
    }
    if(flag===true){
      if(!loading){
        _loading(true)
        API.post(APP_URLS.UPDATE_PRODUCT_TYPES+`/${productTypeId}?_method=patch` , data)
        .then(res => {
          _loading(false)
          const resp = res.data;
          if(resp.success===true){
              toast.success("Product Type has been updated successfully.", {
                position: toast.POSITION.TOP_RIGHT
              })
              if(!urlColorWeb || !urlColorMobile){
                saveImage(colorImageValueWeb ,colorImageValueMobile,productTypeId)
              }else{
                navigate("/translation/vehicle-product-types?"+                  
                (searchParams.get("per_page") !== null 
                ? "per_page=" + searchParams.get("per_page") + "&"
                : "") +
                (searchParams.get("page") !== null
                ? "page=" + searchParams.get("page")
                : "")
                )
              }
            }
            else{
              toast.error(resp.message, {
                position: toast.POSITION.TOP_RIGHT
              })
            }
          })
          .catch(function (error) {  
          _loading(false)
            const resp = error.response;
            let error_message = '';
            if (resp.data.data !== undefined && resp.data.data !== null) {
              {
                Object.keys(resp.data.data).map(
                  (error, index) =>(error_message = resp.data.data[error][0])
                );
              }
            } else if (resp.data.data?.error !== undefined) {
                error_message =  resp.data.data.error;
            } else if(resp.data?.error!== undefined){
                error_message =  resp.data.error
            } else{
                error_message =  resp.data.message
            }
            toast.error(error_message, {
              position: toast.POSITION.TOP_RIGHT
            })
        });
      }
    }
  }

  const handleTitle = (val, code) => {
    name[code] = val;
  };


  return (
    <>
      <EditVehicleWrapper>
        <div className='mt-2' >
          <div className="backContainer ms-4" onClick={()=>navigate("/translation/vehicle-product-types?"+                  
            (searchParams.get("per_page") !== null 
              ? "per_page=" + searchParams.get("per_page") + "&"
              : "") +
            (searchParams.get("page") !== null
              ? "page=" + searchParams.get("page")
              : "")
          )}>
            <span className='backButton'>x</span>
            <span className='px-3 backButton'>|</span>
            <span className='backButton'>Cancel</span>
          </div>
        </div>
        <div className="headerContainer">
          <h1>EDIT PRODUCT TYPE</h1>
        </div>
        <div className="row">
            <div className="d-flex justify-content-end save-btn mt-3">
            <button className={(loading?"disabled":"")+" add-button"} onClick={handleSubmit} disabled={loading}>Save</button>
            </div>

            <div className="row input-fileds">
          {languages.map((item, index) => {
            let itemCode = item.code;
            let flag = countries.find((o) => o.id === item.country_id)?.flag_thumbnail;
            return (
              <div className="col-md-4" key={index}>
                <div className="col-md-12 mt-3">
                  <label htmlFor="title" className="form-label">
                    <span className="countryIcon px-2">
                      <img src={flag} alt="" className="countryflag" title={item.name}/>
                    </span>
                    <span className="langName">{item.code}</span>
                  </label>
                  <input
                    type="text"
                    id={itemCode}
                    name={itemCode}
                    className="form-control departmentFields"
                    onChange={(e, code = itemCode) => {
                      handleTitle(e.target.value, code);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>

            <div className="row input-fileds">
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label htmlFor="name" className="form-label fieldLabel">
                      Product Category
                    </label>
                    <input type="text" id="category" name="category" 
                      className="form-control mt-2 disabled"
                      placeholder="Product Category" disabled/>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label htmlFor="title" className="form-label fieldLabel">
                      Is Active?
                    </label>
                    <div>
                      <Switch
                        onChange={(e)=>handleChange(e)}
                        checked={checked}
                        className="react-switch mt-2"
                        uncheckedIcon={false}
                        checkedIcon={false}
                        offColor="#D9D9D9"
                        onColor="#7589EC"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
          <div className="col-md-4">
            <div className="row">
              <SelectImageHeading/>
              <div className="col-md-6 mt-2">
                <label>For Web</label>
                <SelectImageEdit type='web' urlColor={urlColorWeb} _urlColor={_urlColorWeb} colorImage={colorImageWeb} handleColorImage={(e)=>handleColorImage(e,_colorImageWeb,_colorImageValueWeb , 'web')} /> 
              </div>
              <div className="col-md-6 mt-2">
                <label>For Mobile</label> 
                <SelectImageEdit type='mobile' urlColor={urlColorMobile} _urlColor={_urlColorMobile} colorImage={colorImageMobile} handleColorImage={(e)=>handleColorImage(e,_colorImageMobile,_colorImageValueMobile , 'mobile')} />
              </div> 
            </div>
          </div>
        </div>
          <div className="col-md-2"></div>
        </div>
      </EditVehicleWrapper>
    </>
  );
};

export default EditProductType;
