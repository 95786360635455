import styled from "styled-components";

export const CreateAuctionWrapper = styled.div`

.searchContainer{
    display: flex;
    padding: 0 15px 0 0;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 10px;
}
.actionContainer{
    display: flex;
    gap:20px
}
.search{ 
    height: 30px;
    border: none;
    border-radius: 10px;

}
.btn{
    align-self: center;
    background-color: #fbc524;
    border: none;
    height: 35px;
    border-radius: 5px;
    padding: 0 20px;
    color: white;
    font-weight: bold;
}
.formInput{
    display: flex;
    flex-direction: column;
}
 input, select, textarea{
    border: 1px solid rgba(0, 0, 0, .2);
    background-color: white;
}
textarea{
    border: 1px solid rgba(0, 0, 0, .2);
}

.v1 {
    border-left: 1px solid rgba(0,0,0,.2);
    height: 100%;
    margin: 0 auto;
    display: block;
    width: 1px;
}
.label{
    margin: 0 0 10px 0;
}
.inputgroup{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.inputSwitchgroup{
    display: flex;
    flex-direction: row;
    justify-content: space-between;    
}
.leftChild{
    display: flex;
    flex-direction: column;
    width: 40%;
}
.leftSwitchChild{
    display: flex;
    flex-direction: row;
    align-items: center;   
}
.leftSwitchChild .react-switch-bg {
    height: 15px !important;
    width: 43px !important;
}
.leftSwitchChild .react-switch-handle {
    height: 13px !important;
    width: 13px !important;
}
.leftSwitchChild p{
    margin-left: 10px;
    font-size: 13px;
    color: #7c8586;
}
.rightSwitchChild{
    display: flex;
    flex-direction: row;
    align-items:center;
    width:50%
}
.rightSwitchChild input{
    width:100%
}
.rightChild{
    display: flex;
    flex-direction: column;
    width: 40%;
}
.radioOptions{
    display:flex;
    justify-content: space-between;
}
.options{
    display:flex;
    align-items:center;
    width:20%;
}
.options input{
    height:30px;
}
.options label{
    margin-left:10px
}
.uploadFile{
    display: flex;
    justify-content: center;
    align-items:center;
    height:100%;
}
.fileInput{
    display: none;
}
.fileInputbtn{
    font-weight: bold;
    background-color: #fef0fd;
    color: #873da0;
    border: none;
    padding: 10px 30px;
    cursor: pointer;
    height:35px;
    line-height: 9px;
    white-space: nowrap;
}
.status{
    height:15px;
    width:15px;
}
.status-end{
    font-size:9px;
}
.resetFile{
    align-self: flex-end;
    cursor:pointer;
    position: absolute;
    margin-right: -8px;
    margin-top: -5px;
}
.preview{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.previewCard{
    width: 100%;
    align-self: center;
    margin-top: 15px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 6px;
    box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.2);
}
.previewHeader{
    width: 100%;
    margin-bottom: -1px;
}
.previewCardContainer{
    padding: 10px;
    display: flex;
    flex-direction: column;
}
.cardHeader{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
}
.cardImage{
    width: 45%;
    height:172px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}
.cardContent{
    width: 50%;
    display: flex;
    flex-direction: column;
}
.imgTitle{
    padding: 5px 15px;
    border: 1px solid green;
    font-weight: 600;
    border-radius: 5px;
}
.image{
    width: 180px;
    height: 101.25px;
    object-fit: contain;
    margin-bottom: 5px;
}
.imgTitle{
    font-size: 11px;
    color: green;
}
.cardContentHeader{
    display: block;
    height: 10%;
}
.cardContentContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 90%;
    margin-top: 15px;
}
.cardContentText{
    display: flex;
    margin-left: 13px;
    height: 30px;
}
.cardContentTextHead{
    width: 95px;
    font-size: 12px;
}
.cardContentTextContent{
    width: auto;
    font-size: 12px;
}
.cardFooter{
    display: flex;
    width: 90%;
    align-self: center;
    flex-direction: row;
    justify-content: space-between;
    margin-top: -10px;
}
.cardFooterHeading{
    font-weight: bold;
    font-size: 11px;
}
.cardFooterContent{
    font-weight: 100;
}
.tooltipIcon{
    cursor:pointer;
    width:13px;
    margin-left:5px
  }


@media(max-width: 575px){
    .cardImage, .cardContent {
        width: 100%;
        margin-bottom: 10px;
    }
    .cardContentHeader {
        height: auto;
        line-height: 36px;
    }
    .cardContentText {
        height: auto;
    }
}

`