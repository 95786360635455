import React from 'react'

const ProductCard = (props) => {
  return (
    <div className='product-card-box container-border'>
        <img className={(props.type?"stepImg":"")+" mt-2"} src={props.source} alt=""/>
        <span className='mt-2'> {props.title}</span>
    </div>
  )
}

export default ProductCard