export const redirect = (item) => {
    if (item.subsidiary_id) {
      window.open(
        `${process.env.REACT_APP_FRONTOFFICE_URL_HTTPS}/fr/pro/${item?.subsidiary.slug}`,
        "_blank"
      );
      return;
    }
    window.open(
      `${process.env.REACT_APP_FRONTOFFICE_URL_HTTPS}/fr/user/${item?.user.user_name}`,
      "_blank"
    );
  };