// import firebase from "firebase";
import { initializeApp } from 'firebase/app';
import { getToken, onMessage, getMessaging } from 'firebase/messaging';
import { toast } from 'react-toastify';
import { APP_URLS } from '../api/url';
import API from '../api/axios';
import { APP_TYPE } from '../config/constant';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const setTokenToLocal = (params) => {
    localStorage.setItem('deviceToken', JSON.stringify(params));
}  
const addRemoveFunction = (deviceId, remove) => {
    let payload = {}
    payload['app_type'] = APP_TYPE.FRONT_OFFICE
    let url = ''
    if(remove){
        payload['device_id'] = deviceId 
        url = APP_URLS.REMOVE_TOKEN + '?_method=patch'
    } else{
        if(deviceId){
        payload['device_id'] = deviceId
        payload['device_type'] = 'web' 
        } 
        url = APP_URLS.ADD_TOKEN + '?_method=patch'
    } 
    API.post(url, payload)
    .then((res) => {
      const resp = res.data;
      if (resp.success !== true) {  
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    })
    .catch(function (error) {
      const resp = error.response;
      let error_message = "";
      if (resp?.data?.data) { 
        Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0])); 
      } else if (resp?.data?.data?.error) {
        error_message = resp.data.data.error;
      } else if (resp?.data?.error) {
        error_message = resp.data.error;
      } else {
        error_message = resp.data.message;
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    });

  }
 
export const fetchToken = () => {
    const devicTokenLocal = localStorage.getItem('deviceToken'); 
    const notificationToke = devicTokenLocal ? JSON.parse(devicTokenLocal) : ''
    if (window.Notification) {
        Notification.requestPermission().then(function (getperm) {
            if (getperm !== 'granted') {
                if (notificationToke) {
                    addRemoveFunction(notificationToke, true)
                    localStorage.removeItem('deviceToken');
                }
                return;
            } 
            if (getperm === 'granted') {
                if (notificationToke) {
                    addRemoveFunction(notificationToke, false)
                }
            }
        })
    }
      
    getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_APIDKEY })
        .then((currentToken) => {
            if (currentToken) {
                setTokenToLocal(currentToken);
                if (!notificationToke) {
                    addRemoveFunction(currentToken, false) 
                    } 
            } else {
               console.log('An error occurred while retrieving token. '); 
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token.', err);
        }); 
};

export const onMessageListener = () =>
    new Promise(resolve => {
        onMessage(messaging, payload => {
            resolve(payload);
        });
    });