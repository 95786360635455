import React from "react";
import { Chip } from "@mui/material";

const ChipList = ({ chips, onDelete, disabled }) => {
  return (
    <div>
      {chips?.map((value) => (
        <Chip
          key={value}
          disabled={disabled}
          label={value}
          onDelete={() => onDelete(value)}
          style={{ marginRight: 5, marginBottom: 5 }}
        />
      ))}
    </div>
  );
};

export default ChipList;
