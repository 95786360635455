import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import API from '../../../../api/axios';
import { APP_URLS } from '../../../../api/url';
import NoRecord from '../../../../assets/SharedComponents/Table/NoRecord';
import waste from "../../../../assets/Images/image/Waste.png";
import moment from "moment";
import * as Constant from "../../../../config/constant";
import { confirmAlert } from 'react-confirm-alert';
import { useLocation } from 'react-router-dom';


const Behavior = (props) => {

  const [comments, _comments] = useState({})
  const [data, _data] = useState([])
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const [loading, _loading] = useState(false)

  const permissions = JSON.parse(localStorage.getItem('permissions')) || []

  const userAccess = permissions.find(o => o.slug === "users")


  useEffect(() => {
    getBehaviortext()
    getBehavior()
  }, [])

  const date = new Date().getTime()

  const getBehaviortext = () => {
    const allSuggestionType = Object.values(Constant.suggestionText)
      .map((value, index) => `&filter[type][${index}]=${value}`)
      .join('');
    API.get(APP_URLS.LIST_SUGGESTION_TEXT + `?limited_data=true${allSuggestionType}&with_trashed=1`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          let data = {}
          resp.data.items.forEach(element => {
            data[element.id] = element.name
          });
          _comments(data);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getBehavior = () => {
    API.get(APP_URLS.VIEW_USER_CENSORSHIP + `/${props.userId}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _data(resp.data.items);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const removeType = (index) => {
    confirmAlert({
      title: "Confirm to remove",
      message: `Are you sure you want to remove?`,
      closeOnEscape: true,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            _loading(true)
            API.delete(APP_URLS.DELETE_USER_CENSORSHIP + `/${index}`)
              .then((res) => {
                const resp = res.data;
                _loading(false)
                if (resp.success === true) {
                  toast.success("Censorship has been deleted successfully.", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  getBehavior()
                } else {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              })
              .catch(function (error) {
                const resp = error.response;
                _loading(false)
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                  {
                    Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
                  }
                } else if (resp.data.data?.error !== undefined) {
                  error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                  error_message = resp.data.error;
                } else {
                  error_message = resp.data.message;
                }
                toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          },
        },
        {
          label: "No",
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-custom-class-name",
    });
  };

  //createdBy name formation
  const getCreatedBy = (createLog, updateLog) => {
    const log = createLog || updateLog || {};
    const { first_name, last_name, pseudo } = log;
    return (first_name || last_name) ? `${first_name || ''} ${last_name || ''}`.trim() : pseudo || '';
  };

  return (
    <div className="tableContainer">
      <table className="auctionTable">
        <thead className="tableHead">
          <tr className="table-row">
            <th>DATE</th>
            <th className='w20'>REASONS</th>
            <th>SANCTION TYPE APP</th>
            <th>MODULES DISABLED</th>
            <th>DURATION</th>
            <th>CREATED BY</th>
            {userAccess.permissions.find((o) => o.slug === "delete") ? <th>ACTION</th> : ''}
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ?
            data.map((behaviour, index) => {
              const currentDate = new Date().getTime();
              const bannedUntilDate = new Date(behaviour.banned_until).getTime();
              const timeDifference = bannedUntilDate !== null
                ? parseInt((bannedUntilDate - currentDate) / (1000 * 3600)) // time in hours
                : null; // Permanent ban
              const createdBy = getCreatedBy(behaviour.create_activity_log, behaviour.update_activity_log);
              return <tr className={(behaviour.deleted_at ? "deletedRow" : "") + " table-row"} key={index}>
                <td>{moment(behaviour.created_at).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm")}</td>
                <td className='w20'>{behaviour.reason_ids.map((comment, index) => {
                  return <span className='d-flex justify-content-center align-items-center suggestion' key={index}>{comments[comment]}</span>
                })}</td>
                <td>
                  <div className='sanctions'>
                    {bannedUntilDate === null || timeDifference > 0 ?
                      <span className='status status-end' title='Ban In Progress'></span> :
                      <span className='status status-pending' title='Ban Ended'></span>}
                    <span>{Constant.BANNED[behaviour.modules_disabled[0]]}</span>
                  </div>
                </td>
                <td>
                  {behaviour.modules_disabled.map((module, index) => {
                    return <span className='d-block' key={index}>{Constant.MODULES_DISABLED[module]}</span>
                  })}
                </td>
                <td>{Constant.CENSORED_DURATION[behaviour.duration]}</td>
                <td>{createdBy.charAt(0).toUpperCase() + createdBy.slice(1)}</td>
                {userAccess.permissions.find((o) => o.slug === "delete") ?
                  <td>
                    {behaviour.deleted_at === null ?
                      <button className={(loading ? "disabled" : "") + ' deleteBtn'} role="button" disabled={loading} onClick={() => removeType(behaviour.id)}>
                        <img src={waste} alt="" />
                      </button>
                      : ''}
                  </td> : ""}
              </tr>
            }) : <NoRecord />}
        </tbody>
      </table>
    </div>
  )
}

export default Behavior