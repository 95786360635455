import React, { forwardRef, useEffect, useRef, useState } from 'react'
import back from "../../../assets/Images/icons/svgIcon/back-arrow.svg";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import profilePic from "../../../assets/Images/image/profile.png";
import NoRecord from '../../../assets/SharedComponents/Table/NoRecord';
import AddCreditPopup from './AddCreditPopup';
import { CreditHistoryDetailsWrapper } from '../style';
import { APP_URLS } from '../../../api/url';
import API from '../../../api/axios';
import { toast } from 'react-toastify';
import { USER_ACTIVE_STATUS, auctionUserTypes, creditExpensType, userConfig } from '../../../config/constant';
import moment from 'moment';
import { FaCoinsIcon, FaLocation } from '../../../assets/Images/icon';
import Pagination from '../../../assets/SharedComponents/Pagination';
import noImage from "../../../assets/Images/image/noImage.svg"
import userImg from "../../../assets/Images/icons/profile-pic.png";
import { Tooltip } from '@mui/material';

const CreditHistoryDetails = forwardRef((props, ref) => {
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const [data, _data] = useState()
    const navigate = useNavigate()
    const location = useLocation();
    const { creditId } = useParams();
    const [creditTotals, setCreditTotals] = useState({});
    const popupRef = useRef();
    const [popupOpen, setPopupOpen] = useState(false);
    const [total, _total] = useState(0);
    const [totalPage, _totalPage] = useState(0);
    const [details, _details] = useState({});
    const [page, setPage] = useState(1);
    const [creditAddedSuccess, _creditAddedSuccess] = useState(false);

    useEffect(() => {
        const getCreditHistoryDetails = async () => {
            try {
                const res = await API.get(`${APP_URLS.CREDIT_HISTORY_DETAILS}?page=${page}&${location.state.profileType == auctionUserTypes.Dealer ? userConfig.subsidiary.field : userConfig.user.field}=${creditId}`);
                const { data: resp } = res;
                if (resp.success) {
                    _data(resp.data.credits.data)
                    setCreditTotals(resp.data.totals)
                    _totalPage(resp.data.credits.last_page);
                    _total(resp.data.credits.total);
                    _details(resp.data.userOrSubData)
                } else {
                    toast.error(resp.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            } catch (error) {
                const resp = error.response;
                let errorMessage = resp?.data?.message || 'An error occurred';
                if (resp?.data?.errors) {
                    errorMessage = Object.values(resp.data.errors).flat().join(', ');
                } else if (resp?.data?.data?.error) {
                    errorMessage = resp.data.data.error;
                } else if (resp?.data?.error) {
                    errorMessage = resp.data.error;
                }
                toast.error(errorMessage, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        };
        getCreditHistoryDetails()
    }, [page, creditAddedSuccess])

    const handlePopup = (show) => {
        setPopupOpen(show);
    };

    //handles navigation to credit history page with params
    const navigateToCreditHistory = () => {
        const queryParams = [
            query.get("country_id") !== null ? `country_id=${query.get("country_id")}` : "",
            query.get("profile_type") !== null ? `profile_type=${query.get("profile_type")}` : "",
            query.get("user_id") !== null ? `user_id=${query.get("user_id")}` : "",
            query.get("subsidiary_id") !== null ? `subsidiary_id=${query.get("subsidiary_id")}` : "",
            query.get("trashed") !== null ? `trashed=${query.get("trashed")}` : "",
            query.get("per_page") !== null ? `per_page=${query.get("per_page")}` : "",
            query.get("page") !== null ? `page=${query.get("page")}` : "",
            query.get("keyword") !== null ? `keyword=${query.get("keyword")}` : "",
            query.get("sub_keyword") !== null ? `sub_keyword=${query.get("sub_keyword")}` : ""
        ].filter(Boolean).join("&");

        navigate(`/credit-history${queryParams ? `?${queryParams}` : ""}`);
    };

    const address = (addressObj) => {
        const { route, street_number, sub_locality, locality, country, postal_code } = addressObj || {};
        const addressArray = [route, street_number, sub_locality, locality, country, postal_code].filter(Boolean);
        const addressString = addressArray.join(', ') + '.';

        return <span className="text-sm d-flex align-center gap-1" title="Address">
            <FaLocation className='locationSvg' /> {addressString}
        </span>
    }

    const getReasonText = (type) => {
        switch (type) {
            case creditExpensType.EXPIRED:
                return 'Expired Credit';
            case creditExpensType.PURCHASED:
                return 'Purchased Credit'
            case creditExpensType.LICENSESEARCH:
                return 'License Search';
            default:
                return null;
        }
    };

    return (
        <>
            <CreditHistoryDetailsWrapper>
                <div className="auctionMainContainer">
                    <div className="row mt-4">
                        <div className="col-md-4 d-flex align-items-center">
                            <img
                                src={back}
                                className="ms-4 backbtn"
                                onClick={navigateToCreditHistory}
                            />
                        </div>
                        <div className="col-md-4">
                            <div className="heading">
                                <h1>CREDIT HISTORY DETAILS</h1>
                            </div>
                        </div>
                        {!details.deleted_at && <div className="col-md-4 d-flex justify-content-end gap-2">
                            <button className="btn" onClick={() => handlePopup(true)}>Add Credit</button>
                        </div>}
                    </div>
                    <hr className="hr-line" />
                    <div className="container mb-4">
                        <div className="row">
                            <div className="col-md-3 d-flex h-100 justify-content-center align-items-center mt-4">
                                <div className='d-flex'>
                                    <div className="profileImage">
                                        <img src={details.profile_picture_urls || userImg} className="align-self-start profile" />
                                    </div>
                                    {details?.address ? <img className="countryflag" src={details?.address?.flag_thumbnail} title={details?.address?.country} /> : ""}
                                </div>
                                <div className="details-name">
                                    {address(details?.address)}
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="details">
                                    <div className="details-name">
                                        <b>Name</b>
                                        <p>{details?.name}</p>
                                    </div>
                                    <div className="details-name">
                                        <b>Phone number</b>
                                        <p>{`${details?.address?.prefix_call || ''} ${details.phone_no || '-'}`}</p>
                                    </div>
                                    <div className="details-name">
                                        <b>Account Status</b>
                                        <div className='d-flex align-items-center gap-1'>
                                            <span className={`status ${details?.active ? 'online' : 'offline'}`}></span>
                                            <span>{details?.active ? '(Active)' : '(Inactive)'}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="details-name">
                                    <b>Total credit acquired</b>
                                    <p>{creditTotals.totalAcquired}</p>
                                </div>
                                <div className="details-name">
                                    <b>Total credit available</b>
                                    <p>{details?.wallet_balance}</p>
                                </div>
                                {details.profile_type == auctionUserTypes.Private && <div className="details-name">
                                    <b>Pseudo name</b>
                                    <p>{(details?.pseudo || details?.name)}</p>
                                </div>}
                            </div>
                            <div className="col-md-3">
                                <div className="details-name">
                                    <b>Total credit spent</b>
                                    <p>{creditTotals.totalSpent}</p>
                                </div>
                                <div className="details-name">
                                    <b>Total credit expired</b>
                                    <p>{creditTotals.totalExpired}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tables table-color">
                        <div>
                            <div className="features">
                                <b>{`Credit History(${total})`}</b>
                            </div>
                            <div className="tableContainer">
                                <table className="auctionTable">
                                    <thead className="tableHead">
                                        <tr className="table-row">
                                            <th>Date</th>
                                            <th>Transaction</th>
                                            <th className="pricedesc">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.length > 0 ?
                                            data.map((reason) => {
                                                const reasonText = getReasonText(reason.type);
                                                return <tr className={`table-row ${reason?.amount < 0 ? "neg-credit" : ""}`}>
                                                    <td>{moment(reason?.reason?.created_at).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm")}</td>
                                                    <td className='w-400'>
                                                        {reasonText ?
                                                            reasonText :
                                                            reason?.reason_id ?
                                                                <span className='name-title'>{reason?.reason?.name.en}</span> :
                                                                (
                                                                    <a href={`${process.env.REACT_APP_FRONTOFFICE_URL_HTTPS}/fr/hot-deal/${reason?.ad_vehicle?.slug}`} target='_blank'>
                                                                        {reason?.ad_vehicle?.owner_vehicle?.full_title || '-'}
                                                                        {reason?.type === creditExpensType.REFUNDED ? <Tooltip title={'Credit refunded'}>
                                                                            <FaCoinsIcon className='refundedicon' />
                                                                        </Tooltip> : ""}
                                                                    </a>
                                                                )}
                                                    </td>
                                                    <td>{reason?.amount}</td>
                                                </tr>
                                            }) : <NoRecord />}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div>
                        {totalPage > 1 ? <Pagination totalPages={totalPage} page={page} key={totalPage} onPageClick={(page) => setPage(page + 1)} /> : ""}
                    </div>
                </div>
                <AddCreditPopup
                    ref={popupRef}
                    popupName="add"
                    open={popupOpen}
                    _showPopup={handlePopup}
                    hideCustomer={true}
                    _creditAddedSuccess={_creditAddedSuccess}
                />
            </CreditHistoryDetailsWrapper>
        </>
    )
})

export default CreditHistoryDetails