import React from 'react'
import country from "../../../../../assets/Images/icons/country.png";
import clockGreen from "../../../../../assets/Images/image/clock-green.svg";
import calGreen from "../../../../../assets/Images/image/cal-green.svg";
import clockRed from "../../../../../assets/Images/image/clock-red.svg";
import calRed from "../../../../../assets/Images/image/cal-red.svg";

const DesktopAuctionCard = (props) => {
  return (
    <div className="auction-card d-flex flex-row align-items-center my-3 mx-2 py-2 pe-2 ">
        <div className='d-flex justify-content-center'>
            <img className="card-img" src={props.auctionImg} />
        </div>
        <div>
            <div className="auction-status-bar d-flex justify-content-between mt-2">
                <img className="auction-status" src={props.status} />
                <img className="flag" src={country} />
            </div>
            <div className="card-content mt-1">
                <p className="auction-title">Auction event for new Cars 2022</p>
                <p className="content-title">More than <span>100 Ads</span></p>
            </div>
            <div className="card-content mt-1">
                <div className='d-flex timerContainer mb-1'>
                    <p className='timer'>Start :</p>
                    <img className="ms-2" src={calGreen} />
                    <p className="mx-1">27-08-2021</p>
                    <img className="ms-2" src={clockGreen} />
                    <p className="mx-1">12:00</p>
                </div>
                <div className='d-flex timerContainer'>
                    <p className='timer'>End :</p>
                    <img className="ms-2" src={calRed} />
                    <p className="mx-1">06-11-2022</p>
                    <img className="ms-2" src={clockRed} />
                    <p className="mx-1">12:00</p>
                </div>
            </div>
            <div className='card-content mt-1'>
                <p className={`counter-head ${props.class}`}>{props.desc}</p>
                <div className='counter d-flex flex-row justify-content-between'>
                    <div className='d-flex flex-column align-items-center count border-right p-1'>
                        <span>00</span>
                        <span>DAYS</span>
                    </div>
                    <div className='d-flex flex-column align-items-center count border-right p-1'>
                        <span>09</span>
                        <span>HOURS</span>
                    </div>
                    <div className='d-flex flex-column align-items-center count border-right p-1'>
                        <span>23</span>
                        <span>MINUTES</span>
                    </div>
                    <div className='d-flex flex-column align-items-center count p-1'>
                        <span>33</span>
                        <span>SECONDS</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default DesktopAuctionCard