import styled from "styled-components";

export const HotdealStyleWrapper = styled.div`
.date-range-container{
    border-radius:10px;
}
.date-range-container >div:first-child input{
    border-radius:10px 0 0px 10px;
}
.date-range-container >div:last-child input{
    border-radius:0 10px 10px 0;
}
.date-range-container input {
    background:white !important; 
    border:1px solid #ced4da;
    width:10rem;

}
.accept{
    color:#5D78FF;
    border: 1px solid #5D78FF;
    font-weight:700;
    font-size:14px;
    background:white;
    letter-spacing:1px;
    padding: 4px 22px;
    border-radius:3px;
    word-break:normal;
}
    
.reject{
    padding: 4px 22px;
    color:#ff2828;
    font-weight: 700;
    border: 1px solid #ff2828 !important;
    font-size:14px;
    background:white;
    letter-spacing:1px;
    border-radius:3px;
    word-break:normal;
}
.access-btn{
    display:flex;
    flex-direction:column;
    gap:2px;
}
.disabled{
    cursor: not-allowed;
    pointer-events: all !important;
    opacity: 50%;
}
.message-text {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width:150px
}
.dropbtn{
    cursor:pointer;
}
.dropped{
    width:100%;
    text-align:unset;
    margin-left:10px;
}
.open{
    transform: rotate(-90deg);
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
}
.close{
    transform: rotate(90deg);
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
}
`;