import React, { useState } from "react";
import { CreateLanguageWrapper } from "./style/createLanguage";
import Switch from "react-switch";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SearchableDropdown from "../../../assets/SharedComponents/SearchableDropdown/SearchableDropdown";


const CreateLanguage = () => {

  const [languages,_languages] = useState(JSON.parse(localStorage.getItem("languages")))
  const [activeLanguages,_activeLanguages] = useState(localStorage.getItem("languages"))
  const [countries,_countries] = useState(JSON.parse(localStorage.getItem("countries")))

  const [checked,_checked]= useState(false)
  const [loading,_loading]= useState(false)
  
  const [name,_name] = useState({ en : '', fr : ''})
  const [code,_code] = useState('')
  const [country,_country]= useState('')

  const navigate = useNavigate();

  const getLimitedLanguages =() =>{
    API.get(APP_URLS.ADMIN_LANGUAGES+`?limited_data=true`)
    .then(res => {
      _loading(false)
      const resp = res.data;
      if(resp.success===true){
        let languages = resp.data.items
        localStorage.setItem("languages",JSON.stringify(languages))
        setTimeout(()=>{
          navigate("/translation")
        },500)
      }
      else{
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })
    .catch(function (error) {  
      _loading(false)
      const resp = error.response;
      let error_message = '';
      if (resp.data.data !== undefined && resp.data.data !== null) {
        {
          Object.keys(resp.data.data).map(
            (error, index) =>(error_message = resp.data.data[error][0])
          );
        }
      } else if (resp.data.data?.error !== undefined) {
          error_message =  resp.data.data.error;
      } else if(resp.data?.error!== undefined){
          error_message =  resp.data.error
      } else{
          error_message =  resp.data.message
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT
      })
    });
  }

  const handleSubmit = ()=>{
    if(country===""||country===undefined||country===null){
      toast.error("Language Flag is required", {
        position: toast.POSITION.TOP_RIGHT
      })
      return ;
    }else if(code===""||code===undefined||code===null){
      toast.error("Language code is required", {
        position: toast.POSITION.TOP_RIGHT
      })
      return ;
    }else if((name["en"]===""||name["en"]===undefined||name["en"]===null) && (name["fr"]===""||name["fr"]===undefined||name["fr"]===null )){
      toast.error("Language Title in English and French is mandatory", {
        position: toast.POSITION.TOP_RIGHT
      })
      return ;
    }
    else if(name["en"]===""||name["en"]===undefined||name["en"]===null){
      toast.error("Please provide language name in English", {
        position: toast.POSITION.TOP_RIGHT
      })
      return ;
    }
    else if(name["fr"]===""||name["fr"]===undefined||name["fr"]===null){
      toast.error("Please provide language name in French", {
        position: toast.POSITION.TOP_RIGHT
      })
      return ;
    }
    else{
      let data = {}
      data["name"] = name
      data["code"] = code
      data["country_id"] = country
      data["status"] = checked===true?"1":"0"
      if(!loading) {
        _loading(true)
          API.post(APP_URLS.ADD_LANGUAGE, data)
          .then(res => {
              const resp = res.data;
              if(resp.success===true){
                toast.success("Language has been added successfully.", {
                  position: toast.POSITION.TOP_RIGHT
                })
                getLimitedLanguages()
              }
              else{
                toast.error(resp.message, {
                  position: toast.POSITION.TOP_RIGHT
                })
              }
          })
          .catch(function (error) {  
              const resp = error.response;
              _loading(false)
              let error_message = '';
              if (resp.data.data !== undefined && resp.data.data !== null) {
                {
                  Object.keys(resp.data.data).map(
                    (error, index) =>(error_message = resp.data.data[error][0])
                  );
                }
              } else if (resp.data.data?.error !== undefined) {
                  error_message =  resp.data.data.error;
              } else if(resp.data?.error!== undefined){
                  error_message =  resp.data.error
              } else{
                  error_message =  resp.data.message
              }
              toast.error(error_message, {
                position: toast.POSITION.TOP_RIGHT
              })
          });       
      }
    }
  }

  const setChangeValue = (val) =>{
    //Checks if the language for the same country already exists (only active languages)
    let exists = languages.find((o)=>o.country_id ===val)
    if(exists){
      toast.error("Language for this country has already been added", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else{
      // resets the language state as values for new language gets pushed in language state on "code" field change
      if(JSON.stringify(languages)!==activeLanguages){
        _languages(JSON.parse(activeLanguages))
        _code('')
      }
      _country(val)
    }
  }

  const handleTitle = (val, code) => {
    _name(prevState => {return {...prevState, [code]: val}})
  };

  const handleCode = (val) =>{
    _code(val.toLowerCase())
  }

  const handleChange = (e) => {
    _checked(e)
  };

  const handleCountries =()=>{
    let data = [...languages]
    let langExists = data.findIndex((o)=>o.country_id === country)
    if(langExists!==-1){
      if(code!==''){
        //updates language code in language state whenever "Code" field gets updated 
        data[langExists].code = code
      }else{
        //removes language in language state whenever "Code" field gets blank 
        data = data.filter(function(item) {
          return item.country_id !== country
        })
      }
      _languages(data)
    }else{
      //adds language in language state whenever "Code" field gets updated for the first time
      if(code!==''){
        let lang = countries.find((o)=>o.id ===country)
        if(lang){
          let data = {}
          data['code'] = code
          data['country_id'] = lang.id 
          _languages(prevState => {return [...prevState, data]})
        }
      }
    }
  }
  


  return (
    <>
      <CreateLanguageWrapper>
        <div className="mt-2">
          <div
            className="backContainer ms-4"
            onClick={() => navigate("/translation")}
          >
            <span className="backButton">x</span>
            <span className="px-3 backButton">|</span>
            <span className="backButton">Cancel</span>
          </div>
        </div>
        <div className="headerContainer">
          <h1>CREATE LANGUAGE</h1>
        </div>
        <div className="row">
          <div className="d-flex justify-content-end save-btn mt-2">
            <button className={(loading?"disabled":"")+" add-depertment"} disabled={loading} type="submit" onClick={handleSubmit}>
              {loading ? "Saving..." : "Save"}
            </button>
          </div>

          <div className="row input-fileds">
            <div className="col-md-4 mt-3">
              <label htmlFor="country_id" className="form-label fieldLabel">
                Flag
              </label>
              <SearchableDropdown
                name="country_id"
                className="form-control"
                setChangeValue={(val) => (val ? setChangeValue(val) : null)}
                val={country}
                placeholder="Select Country"
                dropdownData={JSON.parse(localStorage.getItem("countries"))}
              />
            </div>
            <div className="col-md-4 mt-3">
              <label htmlFor="code" className="form-label fieldLabel">
                Code
              </label>
              <input
                type="text"
                id="code"
                name="code"
                disabled={country===''}
                className={
                  (country === "" ? "disabled" : "") +
                  " form-control"
                }
                placeholder="Language Code"
                value={code}
                onChange={(e) => handleCode(e.target.value)}
                onBlur={handleCountries}
                maxLength={2}
              />
            </div>
            <div className="col-md-4 mt-3">
              <label htmlFor="title" className="form-label fieldLabel">
                Status
              </label>
              <div>
                <Switch
                  onChange={(e) => handleChange(e)}
                  checked={checked}
                  className="react-switch"
                  uncheckedIcon={false}
                  checkedIcon={false}
                  offColor="#D9D9D9"
                  onColor="#7589EC"
                />
              </div>
            </div>
          </div>
          <div className="row input-fileds">
            {languages.map((item, index) => {
              let itemCode = item.code;
              let flag = countries.find(
                (o) => o.id === item.country_id
              )?.flag_thumbnail;
              return (
                <div className="col-md-4" key={index}>
                  <div className="col-md-12 mt-3">
                    <label htmlFor="title" className="form-label">
                      <span className="countryIcon px-2">
                        <img
                          src={flag}
                          alt=""
                          className="countryflag"
                          title={item.name}
                        />
                      </span>
                      <span className="langName">{item.code}</span>
                    </label>
                    <input
                      type="text"
                      id={item.code}
                      name={item.code}
                      value={name[itemCode]}
                      disabled={code === "" || country === ""}
                      className={
                        (code === "" || country === "" ? "disabled" : "") +
                        " form-control"
                      }
                      onChange={(e, code = itemCode) => {
                        handleTitle(e.target.value, code);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>

        </div>
      </CreateLanguageWrapper>
    </>
  );
};

export default CreateLanguage;
