import * as React from 'react';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import { Popover } from '@mui/material';
import { forumFlagModelType } from '../../config/constant';

const DropdownActions = ({ onUnFlag, onDeleteQuestion, anchorEl, handleClose, id, open, questionTitle, answerKeyWithoutTags, onDeleteAnswer, modelType }) => {
    return (
        <DivWrapper className="" id=''>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{
                    '.MuiPopover-paper': {
                        boxShadow: 'none'
                    }
                }}
            >
                <Box className="" id="pricingMenu" sx={{
                    color: 'black',
                    width: '100%',
                    backgroundColor: '#ffffff',
                    padding: '2px 5px',
                    borderRadius: '5px',
                    boxShadow: '2px 3px 10px rgba(0, 0, 0, 0.2)',
                    border: '1px solid lightgray',
                    cursor: 'pointer',
                }}>
                    {modelType == forumFlagModelType.QUESTION ? (
                        <>
                            <div onClick={onUnFlag} className="auctionLinks actions">
                                Unflag question
                            </div>
                            <div onClick={onDeleteQuestion} className="auctionLinks actions">
                                Delete question
                            </div>
                        </>
                    ) : null}
                    {modelType == forumFlagModelType.ANSWER ? (
                        <>
                            <div onClick={onUnFlag} className="auctionLinks actions">
                                Unflag answer
                            </div>
                            <div onClick={onDeleteAnswer} className="auctionLinks actions">
                                Delete answer
                            </div>
                        </>
                    ) : null}
                </Box>
            </Popover>
        </DivWrapper >
    );
}
export default DropdownActions

const DivWrapper = styled.div`
  svg{
    width: 25px;
    height: 25px;
    fill:#FFFFFF;
    path{
      stroke: #FFFFFF;
    }
    cursor: pointer;
  }
`
