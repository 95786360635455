import React, { useEffect, useState } from "react";
import EN from "../../../assets/Images/image/en.png";
import FR from "../../../assets/Images/image/fr.png";
import ES from "../../../assets/Images/image/es.png";
import DE from "../../../assets/Images/image/de.png";
import NL from "../../../assets/Images/image/nl.png";
import error from "../../../assets/Images/icons/alert-tri.svg";
import jeep from "../../../assets/Images/image/jeep.png";
import edit from "../../../assets/Images/image/edit.png";
import waste from "../../../assets/Images/image/Waste.png";
import { TableWrapper } from "../style/table";
import { NavLink, useNavigate, useParams, useSearchParams } from "react-router-dom";
import SideTranslate from "../components/SideTranslate";
import MainLayout from "../../../assets/Layout/MainLayout";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import NoRecord from "../../../assets/SharedComponents/Table/NoRecord";
import { getName } from "../../../core/utils/getName/getName";


const Permission = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate()

  const [data, _data] = useState([]);
  const [name, _name] = useState('');
  const [countries, setcountries] = useState(JSON.parse(localStorage.getItem("countries")));
  const [languages, setlanguages] = useState(JSON.parse(localStorage.getItem("languages")));

  const [dataDeleted, _dataDeleted] = useState(false);

  const [userAccess,_userAccess] = useState(JSON.parse(localStorage.getItem('permissions')))
  
  let translationAccess = userAccess.find(o=>o.slug ==="translation")

  const { featureId } = useParams();

  useEffect(() => {
    getLanguages();
    _dataDeleted(false);
  }, [dataDeleted]);


  const getLanguages = () => {
    API.get(APP_URLS.FEATURE_PERMISSIONS + `/${featureId}/view_permissions`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _data(resp.data.item.permissions);
          _name(resp.data.item.name['en'])
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map((error, index) => (error_message = resp.data.errors[error][0]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };


  return (
    <>
      <TableWrapper>
        <div className="row">
          <div className="col-lg-2 col-md-3 col-sm-4 mt-3">
            <h4 className="translation text-center mb-3">TRANSLATION</h4>
            <SideTranslate />
          </div>
          <div className="col-lg-10 col-md-9 col-sm-8 mt-2">
            <div className='mt-2' >
              <div className="backContainer ms-4" onClick={()=>navigate("/translation/feature?"+                  
                  (searchParams.get("type") !== null
                    ? "type=" + searchParams.get("type") + "&"
                    : "") +
                  (searchParams.get("per_page") !== null 
                    ? "per_page=" + searchParams.get("per_page") + "&"
                    : "") +
                  (searchParams.get("page") !== null
                    ? "page=" + searchParams.get("page")
                    : "")
                )}>
                <span className='backButton'>x</span>
                <span className='px-3 backButton'>|</span>
                <span className='backButton'>Cancel</span>
              </div>
            </div>
            <div className="headerContainer">
              <h1>{name.toUpperCase().replace("_", " ")} PERMISSIONS</h1>
            </div>
            <div className="d-flex align-items-center justify-content-between info-wrap">
              <div className="justify-content-end mt-1 me-2">
                  <span className='text-start'>Total Records : <b>{data.length}</b></span>
              </div>
          </div>
            <div className="tableContainer">
              <table className="auctionTable">
                <thead className="tableHead">
                  <tr className="table-row">
                    <th className="tableHead-1">ID</th>
                    <th className="tableHead-2">Language</th>
                    <th className="tableHead-3">Name</th>
                    <th className="tableHead-3">Description</th>
                    <th className="tableHead-4">Updated by</th>
                    <th className="tableHead-5">Created by</th>
                    <th className="tableHead-8">Slug</th>
                    {translationAccess.permissions.find((o)=>o.slug==='update')?
                    <th className="tableHead-9">Action</th>
                    :''}
                  </tr>
                </thead>
                <tbody>
                  {data.length>0?
                  data.map((item,index)=>{
                    let createdBy = getName(item.create_activity_log,item.update_activity_log);
                    let upadtedBy = getName(item.update_activity_log,item.create_activity_log);
                    return <tr className="table-row" key={index}>
                    <td className="tableHead-1">{item.id}</td>
                    <td className="tableHead-2">
                        {Object.keys(item.permission_name).map((val, index) => {
                            let country_id
                            let flag
                            let countryValues = languages.find((o) => o.code === val);
                            if(countryValues){
                              country_id = countryValues.country_id
                              flag = countries.find((o) => o.id === country_id)?.flag_thumbnail;
                            }
                            return (
                              <div key={index} className="languageSelected d-flex gap-2">
                                {countryValues?
                                <>
                                  <img src={flag} alt="" title={countryValues?.name}/> 
                                  <span className="flagName">{val}</span>
                                </>:''}
                              </div>
                            );
                          })}
                    </td>
                    <td className="tableHead-3">
                      {Object.keys(item.permission_name).map((val, index) => {
                          let countryValues = languages.find((o) => o.code === val);
                          return item.permission_name[val] !== null && item.permission_name[val] !== "" ? (
                            <span key={index} className="nameTranslated">
                              {countryValues?(item.permission_name[val].charAt(0).toUpperCase() + item.permission_name[val].slice(1)).replace("_", " "):''}
                            </span>
                          ) : (
                            <span className="nameTranslated">
                              <img src={error} alt="" className="warningIcon" />
                            </span>
                          );
                        })}
                    </td>
                    <td className="tableHead-3">
                      {Object.keys(item.description).map((val, index) => {
                        let countryValues = languages.find((o) => o.code === val);
                        return item.description[val] !== null && item.description[val] !== "" ? (
                          <span key={index} className="nameTranslated">
                            {countryValues?(item.description[val].charAt(0).toUpperCase() + item.description[val].slice(1)).replace("_", " "):''}
                          </span>
                        ) : (
                          <span className="nameTranslated">
                            <img src={error} alt="" className="warningIcon" />
                          </span>
                        );
                      })}
                    </td>
                    <td>{upadtedBy !== ""&&upadtedBy !== undefined ? upadtedBy.charAt(0).toUpperCase() + upadtedBy.slice(1) : "-"}</td>
                    <td>{createdBy !== ""&&createdBy !== undefined ? createdBy.charAt(0).toUpperCase() + createdBy.slice(1) : "-"}</td>
                    <td className="tableHead-7">{item.slug}</td>
                    {translationAccess.permissions.find((o)=>o.slug==='update')?
                    <td className="tableHead-9">
                      <NavLink to={`/translation/feature/edit-permission/${item.id}?`+                  
                        (searchParams.get("type") !== null
                          ? "type=" + searchParams.get("type") + "&"
                          : "") +
                        (searchParams.get("per_page") !== null 
                          ? "per_page=" + searchParams.get("per_page") + "&"
                          : "") +
                        (searchParams.get("page") !== null
                          ? "page=" + searchParams.get("page")
                          : "")
                      }>
                        <img src={edit} alt="" className="mx-2" role="button" />
                      </NavLink>
                    </td>
                    :''}
                  </tr>
                  }):<NoRecord />}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </TableWrapper>
    </>
  );
};

export default Permission;
