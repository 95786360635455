import styled from "styled-components";

const MessangerWrapper = styled.div`
.filter-wrapper{
    gap:10px;
    flex-wrap:wrap;
}
.filter-wrapper span{
    min-width:max-content;
}
.date-range-container{
    border-radius:10px;
}
.date-range-container >div:first-child input{
    border-radius:10px 0 0px 10px;
}
.date-range-container >div:last-child input{
    border-radius:0 10px 10px 0;
}
.date-range-container input {
    background:white !important; 
    border:1px solid #ced4da;
    width:10rem;

}
.table-row td{
    width:20%;
}
.time-start {
    min-width: 250px;
  }
  .time-start svg {
    width: 10px;
    height: 15px;
    cursor: pointer;
  }
.table-total{
    display:flex;
    justify-content:start;
}
.filter.block{
    display:block;
}
.filter.none{
    display:none;
}
.link-none{
    text-decoration:none;
}
.extract-report{
    font-weight: 600;
    cursor: pointer;
    text-transform: none;
    color: black; 
    border: 1px solid rgba(0,0,0,.2);
    height: 32px;
    &:hover{
        outline:none;
    }
}
.auctionTable{
    min-width: 1250px;
  }
`

export default MessangerWrapper 