import React, { useEffect, useState } from "react";
import MainLayout from "../../../assets/Layout/MainLayout";
import back from "../../../assets/Images/icons/svgIcon/back-arrow.svg";
import arrowDown from "../../../assets/Images/icons/faChevronDown.svg";
import timeWarn from "../../../assets/Images/icons/alert.svg";
import arrowUp from "../../../assets/Images/icons/faChevronUp.svg";
import { AuctionAdsWrapper } from "./style/auctionAds";
import noImage from "../../../assets/Images/image/noImage.svg";
import Pagination from "../../../assets/SharedComponents/Pagination";
import {
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import moment from "moment";
import NoRecord from "../../../assets/SharedComponents/Table/NoRecord";
import { confirmAlert } from "react-confirm-alert";
import * as Constant from "../../../config/constant";
import SearchKey from "../../../assets/SharedComponents/Search/SearchKey";
import { ThreeDots } from "react-loader-spinner";
import { Button, Tooltip } from "@mui/material";
import {
  FaDownArrow,
  FaFilterIcon,
  FaUpArrow,
} from "../../../assets/Images/icon";
import RecordCount from "../../../assets/SharedComponents/RecordCount/RecordCount";
import Popup from "./Components/Popup";
import download from "downloadjs";
import Sorting from "../../../assets/SharedComponents/Sorting/Sorting";
import { generateQueryString, getAuctionUrl } from "../../../core/utils";
import AdVisitorModal from "../../../assets/SharedComponents/AdVisitorModal/adVisitorModal";

const AuctionAds = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const [searchParams, setSearchParams] = useSearchParams();

  const [trashed, _trashed] = useState(
    searchParams.get("ad_trashed") !== null
      ? searchParams.get("ad_trashed") === "false"
        ? false
        : true
      : true
  );
  const [searchkeyword, _searchkeyword] = useState(
    searchParams.get("ad_keyword") !== null
      ? searchParams.get("ad_keyword")
      : ""
  );
  const [countValue, _countValue] = useState(
    searchParams.get("ad_per_page") !== null
      ? searchParams.get("ad_per_page")
      : ""
  );

  const [userAccess, _userAccess] = useState(
    JSON.parse(localStorage.getItem("permissions"))
  );

  let auctionAccess = userAccess.find((o) => o.slug === "auction_event");

  const [title, _title] = useState("");
  const [count, _count] = useState("");
  const [start, _start] = useState("");
  const [end, _end] = useState("");
  const [auctionEventID, _auctionEventID] = useState("");

  const [page, setPage] = useState(
    searchParams.get("ad_page") !== null
      ? parseInt(searchParams.get("ad_page"))
      : 1
  );
  const [total, _total] = useState(0);
  const [totalPage, _totalPage] = useState(0);
  const [data, _data] = useState([]);

  const [checked, _checked] = useState(false);
  const [action, _action] = useState(false);
  const [filters, _filters] = useState(false);
  const [dataDeleted, _dataDeleted] = useState(false);
  const [loaded, _loaded] = useState(false);
  const [sort, _sort] = useState("");
  const [popup, _popup] = useState(false);

  const { auctionId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    getAuctionEvents();
    _dataDeleted(false);
  }, [page, dataDeleted, searchkeyword, countValue, trashed, sort]);

  useEffect(() => {
    getAuctionDetails();
  }, []);

  const handleSelect = (id) => {
    let newdata = [...data];
    let val = newdata.find((o) => o.id === id);
    if (val !== undefined) {
      val.checked = !val.checked;
    }
    _data(newdata);
  };

  const selectAll = (val) => {
    _checked(!val);
    let newdata = [...data];
    newdata.forEach((element) => {
      if (element.deleted_at === null) {
        element.checked = !val ? true : false;
      }
    });
    _data(newdata);
  };

  const handleDelete = () => {
    let arr = [];
    data.forEach((element) => {
      if (element.checked === true) {
        arr.push(element.id);
      }
    });
    if (arr.length > 0) {
      _popup(true);
    } else {
      toast.error("If you want to delete, select some ads first", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  //filter params
  function getRestUrl() {
    let urlParams = {};
    if (searchkeyword) urlParams["keyword"] = searchkeyword;
    if (countValue) urlParams["per_page"] = countValue;
    if (sort) urlParams["sort"] = sort;
    if (page > 1) urlParams["page"] = page;
    urlParams["with_trashed"] = trashed === true ? 1 : 0;
    const restUrl = new URLSearchParams(urlParams);
    return restUrl;
  }

  const getAuctionEvents = () => {
    const apiurl = getRestUrl();
    _loaded(true);
    API.get(APP_URLS.LIST_AUCTION_ADS + `/${auctionId}?${apiurl}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _data([]);
          _action(false);
          _checked(false);
          resp.data.data.forEach((element) => {
            element.checked = false;
            _data((old) => {
              return [...old, element];
            });
          });
          _totalPage(resp.data.last_page);
          _total(resp.data.total);
          _count(resp.data.total);
          _loaded(false);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loaded(false);
        }
      })
      .catch(function (error) {
        _loaded(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map(
              (error, index) => (error_message = resp.data.errors[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getAuctionDetails = () => {
    API.get(APP_URLS.AUCTION_ADS_DETAIL + `/${auctionId}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _title(
            resp.data.item.auction_event.title[
              Object.keys(resp.data.item.auction_event.title)[0]
            ]
          );
          _start(
            moment(resp.data.item.start)
              .tz("Europe/Amsterdam")
              .format("DD-MM-YYYY HH:mm")
          );
          _end(
            moment(resp.data.item.end)
              .tz("Europe/Amsterdam")
              .format("DD-MM-YYYY HH:mm")
          );
          _auctionEventID(resp.data.item.auction_event_id);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map(
              (error, index) => (error_message = resp.data.errors[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleSearchChange = (func, searchValue) => {
    func(searchValue);
    if (
      (func === _countValue && searchValue !== countValue) ||
      (func === _searchkeyword && searchValue !== searchkeyword)
    ) {
      if (searchValue !== "") {
        searchParams.set(
          func === _countValue
            ? "per_page"
            : func === _searchkeyword
            ? "keyword"
            : null,
          searchValue
        );
        setSearchParams(searchParams);
        _totalPage(0);
        setPage(1);
      }
    }
  };

  const clearFilter = () => {
    _filters(true);
    handleSearchChange(_countValue, "");
    handleSearchChange(_searchkeyword, "");
    _trashed(true);
    if (searchkeyword !== "" || countValue !== "" || trashed !== true) {
      _totalPage(0);
      setPage(1);
    }
    for (var value of query.keys()) {
      const param = searchParams.get(value);
      if (
        param &&
        (value === "ad_keyword" ||
          value === "ad_per_page" ||
          value === "ad_trashed")
      ) {
        searchParams.delete(value);
        setSearchParams(searchParams);
      }
    }
  };
  const handleExtractReport = () => {
    const apiurl = getRestUrl();
    API.get(
      APP_URLS.AUCTION_REPORT + `/${auctionId}/download_excel_file?${apiurl}`,
      { responseType: "blob" }
    )
      .then((res) => {
        const resp = res.data;
        if (resp !== null) {
          toast.success("Downloading. Please wait", {
            position: toast.POSITION.TOP_RIGHT,
          });
          const content = res.headers["content-type"];
          const currentDate = moment().format("YYYY-MM-DD HH-mm-ss");
          const fileName = currentDate + "_" + (title || "") + ".xlsx";
          download(resp, fileName, content);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map(
              (error, index) => (error_message = resp.data.errors[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleSort = (sortType) => {
    _sort(sortType);
    setPage(1);
    _totalPage(0);
  };

  const createParamsObj = (searchParams, auctionId, page) => {
    return {
      a_sort: searchParams.get("a_sort"),
      a_country_id: searchParams.get("a_country_id"),
      a_seller_allowed: searchParams.get("a_seller_allowed"),
      a_display_for: searchParams.get("a_display_for"),
      a_product_type_id: searchParams.get("a_product_type_id"),
      a_status: searchParams.get("a_status"),
      a_keyword: searchParams.get("a_keyword"),
      a_per_page: searchParams.get("a_per_page"),
      a_page: searchParams.get("a_page"),
      auction_id: searchParams.get("auction_id"),
      ae_country_id: searchParams.get("ae_country_id"),
      ae_seller_allowed: searchParams.get("ae_seller_allowed"),
      ae_display_for: searchParams.get("ae_display_for"),
      ae_product_type_id: searchParams.get("ae_product_type_id"),
      ae_recurring: searchParams.get("ae_recurring"),
      ae_start_day: searchParams.get("ae_start_day"),
      ae_end_day: searchParams.get("ae_end_day"),
      ae_sort: searchParams.get("ae_sort"),
      ae_keyword: searchParams.get("ae_keyword"),
      ae_per_page: searchParams.get("ae_per_page"),
      ae_page: searchParams.get("ae_page"),
      auction_page: searchParams.get("auction_page"),
      ad_id: auctionId,
      ad_keyword: searchkeyword !== "" ? searchkeyword : undefined,
      ad_trashed: trashed !== true ? trashed : undefined,
      ad_per_page: countValue !== "" ? countValue : undefined,
      ad_page: page !== 1 ? page : undefined,
    };
  };

  // Function to get the HotDeal Ads URL
  const getHotDealAdsUrl = (itemId, searchParams, auctionId, page) => {
    const paramsObj = createParamsObj(searchParams, auctionId, page);
    const queryString = generateQueryString(paramsObj);
    return `/bid-list/${itemId}?${queryString}`;
  };

  return (
    <>
      <AuctionAdsWrapper>
        {popup === true ? (
          <Popup _popup={_popup} data={data} _dataDeleted={_dataDeleted} />
        ) : (
          ""
        )}
        <div className="auctionMainContainer">
          <div className="row mt-4">
            <div className="col-md-4 d-flex align-items-center">
              <img
                src={back}
                className="ms-4 backbtn"
                onClick={() => navigate(getAuctionUrl(searchParams))}
              />
            </div>
            <div className="col-md-4 mb-2">
              <div className="Cradheading">
                <h1>
                  {title} ({count})
                </h1>
                <div className="startEnd d-flex justify-content-between text-xs mt-2">
                  <span>
                    <b>Start:</b> {start}
                  </span>
                  <span>
                    <b>End:</b> {end}
                  </span>
                </div>
              </div>
            </div>
            {auctionAccess.permissions.find((o) => o.slug === "delete") ? (
              <div className="col-md-4 d-flex justify-content-end align-items-center mb-5">
                {action ? (
                  <button
                    className="completeBtn me-4"
                    onClick={() => {
                      _action(false);
                      selectAll(true);
                    }}
                  >
                    Done
                  </button>
                ) : (
                  ""
                )}
                <div className="actionAuction">
                  <div className={(action ? "btnActive" : "") + " actionBtns"}>
                    <button
                      className="actionBtn"
                      onClick={() => {
                        _action(!action);
                        selectAll(true);
                      }}
                    >
                      Action
                      <img
                        src={arrowUp}
                        alt=""
                        className={(action ? "close" : "open") + " ms-2"}
                      />
                    </button>
                  </div>
                  <div className="DelAction">
                    {action ? (
                      <button
                        className={(action ? "" : "") + " actionBtn"}
                        onClick={handleDelete}
                      >
                        Delete
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex justify-content-end my-2 align-items-center gap-2">
            <Button
              onClick={handleExtractReport}
              className="extract-report"
              variant="outlined"
            >
              {" "}
              Extract report
            </Button>
            <SearchKey
              onSearchClick={(search) =>
                handleSearchChange(_searchkeyword, search)
              }
              id="searchData"
              val={searchkeyword}
              filters={filters}
              _filters={_filters}
              placeholder="Search by Title"
            />
            <RecordCount
              onSearchClick={(search) =>
                handleSearchChange(_countValue, search)
              }
              id="pageCount"
              val={countValue}
              filters={filters}
              _filters={_filters}
            />
            <Tooltip title="Clear Filter" placement="bottom">
              <FaFilterIcon
                className="filter-icon"
                role="button"
                onClick={clearFilter}
              />
            </Tooltip>
          </div>
          <div className="d-flex align-items-center justify-content-between info-wrap">
            <div className="mt-1">
              <span className="text-start">
                Total Records : <b>{total}</b>
              </span>
            </div>
            <div className="mt-1">
              {action ? (
                <>
                  <input
                    type="checkbox"
                    checked={checked}
                    onChange={() => selectAll(checked)}
                  />
                  <span className="ms-2 me-3">Select All</span>
                </>
              ) : (
                ""
              )}
              <input
                type="checkbox"
                checked={!trashed}
                onChange={() => {
                  _totalPage(0);
                  setPage(1);
                  _trashed(!trashed);
                }}
              />
              <span className="ms-2">Hide Deleted Data</span>
            </div>
          </div>

          <div className="tableContainer">
            <table className="auctionTable">
              <thead className="tableHead">
                <tr className="table-row">
                  {auctionAccess.permissions.find(
                    (o) => o.slug === "delete"
                  ) ? (
                    action ? (
                      <th className="deleteAction"></th>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  <th>Title Ads</th>
                  <th>Brand</th>
                  <th>Model</th>
                  <th>Target Price</th>
                  <th>Best Bid</th>
                  <th>
                    Bid
                    <br />
                    <span className="fieldTitle">( Accepted / Rejected )</span>
                  </th>
                  <th>Seller Name Type</th>
                  <th>Number of Bidders</th>
                  <th>Id Ad</th>
                  <th>
                    <div className="time-start">
                      <span>
                        Chat Count
                        <Sorting
                          sortType="chat_count"
                          sort={sort}
                          handleSort={handleSort}
                        />
                      </span>
                    </div>
                  </th>
                  <th className="time-start">
                    <div className="d-flex flex-column align-items-center">
                      <span>
                        No of views
                        <Sorting
                          sortType="click"
                          sort={sort}
                          handleSort={handleSort}
                        />
                      </span>
                    </div>
                  </th>
                  <th className="time-start">
                    <div className="d-flex flex-column align-items-center">
                      <span>
                        No of calls
                        <Sorting
                          sortType="call_count"
                          sort={sort}
                          handleSort={handleSort}
                        />
                      </span>
                    </div>
                  </th>
                </tr>
              </thead>
              {loaded ? (
                <div className="d-flex justify-content-center three-dot">
                  <ThreeDots
                    height="150"
                    width="150"
                    radius="9"
                    color="#637df4"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                <tbody>
                  {data.length > 0 ? (
                    data.map((item, index) => {
                      let amount = "";
                      if (item.best_manual_bid_relation !== null) {
                        amount =
                          (item.best_manual_bid_relation.amount /
                            item.public_price) *
                          100;
                      }
                      let seller = "";
                      let seller_type = "";
                      let detailPageLink = "";
                      if (item.owner_vehicle.current_sub !== null) {
                        if (
                          item.owner_vehicle.current_sub.first_name ||
                          item.owner_vehicle.current_sub.last_name
                        ) {
                          seller_type = "Pro";
                          seller = `${
                            item.owner_vehicle.current_sub.first_name
                              ? item.owner_vehicle.current_sub.first_name
                              : ""
                          } ${
                            item.owner_vehicle.current_sub.last_name
                              ? item.owner_vehicle.current_sub.last_name
                              : ""
                          }`;
                        } else {
                          seller_type = "Pro";
                          seller = item.owner_vehicle.current_sub.name;
                        }
                        detailPageLink = `/company-details/${item.owner_vehicle.current_sub.id}`;
                      } else {
                        if (item.owner_vehicle.current_user !== null) {
                          if (
                            item.owner_vehicle.current_user.first_name ||
                            item.owner_vehicle.current_user.last_name
                          ) {
                            seller_type = "Private";
                            seller = `${
                              item.owner_vehicle.current_user.first_name
                                ? item.owner_vehicle.current_user.first_name
                                : ""
                            } ${
                              item.owner_vehicle.current_user.last_name
                                ? item.owner_vehicle.current_user.last_name
                                : ""
                            }`;
                          } else {
                            seller_type = "Private";
                            seller = item.owner_vehicle.current_user.pseudo;
                          }
                          detailPageLink = `/detail-member/${item.owner_vehicle.current_user.id}`;
                        } else {
                          let seller = "-";
                          let seller_type = "-";
                        }
                      }
                      return (
                        <tr
                          className={
                            (item.deleted_at ? "deletedRow" : "") + " table-row"
                          }
                          key={index}
                        >
                          {action ? (
                            !item.deleted_at ? (
                              <td className="deleteAction">
                                <span className="deleteAuction">
                                  <input
                                    type="checkbox"
                                    className="checkbox-round"
                                    id=""
                                    name="delete"
                                    checked={item.checked}
                                    onChange={(e) => handleSelect(item.id)}
                                  />
                                </span>
                              </td>
                            ) : (
                              <div className="deleteAction"></div>
                            )
                          ) : (
                            ""
                          )}
                          <td>
                            {item.owner_vehicle.title
                              ? item.owner_vehicle.title
                              : "-"}
                          </td>
                          <td>
                            {item.owner_vehicle.model
                              ? item.owner_vehicle.model.brand_car.name
                              : "-"}
                          </td>
                          <td>
                            {item.owner_vehicle.model
                              ? item.owner_vehicle.model.model_name
                              : "-"}
                          </td>
                          <td>€ {item.public_price}</td>
                          <td>
                            <div className="d-flex flex-row justify-content-center align-items-center">
                              <span
                                className={
                                  amount < 30
                                    ? "end-status"
                                    : amount > 30 && amount < 60
                                    ? "progress-status"
                                    : amount > 60
                                    ? "active-status"
                                    : "active-status"
                                }
                              >
                                {item.best_manual_bid_relation !== null
                                  ? "€ " + item.best_manual_bid_relation.amount
                                  : "-"}
                              </span>
                              {item.variation !== null &&
                              item.variation > 35 ? (
                                <img
                                  className="warningIcon ms-2"
                                  src={timeWarn}
                                  title="Bid exceeded 35%"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </td>
                          <td>
                            <span
                              className={
                                "status " +
                                Constant.BID_ACCEPTED[item.bid_accepted]
                              }
                              title={
                                item.bid_accepted === 0
                                  ? "No Action"
                                  : item.bid_accepted === 1
                                  ? "Accepted"
                                  : item.bid_accepted === 2
                                  ? "Rejected"
                                  : "-"
                              }
                            ></span>
                          </td>
                          <td>
                            {seller ? (
                              <a
                                className="link"
                                href={detailPageLink}
                                target="_blank"
                              >
                                {" "}
                                <b>{seller} </b>{" "}
                              </a>
                            ) : (
                              "-"
                            )}
                            <br />
                            <b>{seller_type}</b>
                          </td>
                          <td>
                            <NavLink
                              to={getHotDealAdsUrl(
                                item.id,
                                searchParams,
                                auctionId,
                                page
                              )}
                              className="link"
                            >
                              {item.bid_auctions_count}
                            </NavLink>
                          </td>
                          <td
                            onClick={() =>
                              window.open(
                                `${process.env.REACT_APP_FRONTOFFICE_URL_HTTPS}/en/auction-ads/${item.id}`,
                                "_blank"
                              )
                            }
                            className="link"
                          >
                            {item.id}
                          </td>
                          <td>
                            {item.chat_count ? (
                              <AdVisitorModal
                                count={item.chat_count}
                                title={"Chats"}
                                id={item.id}
                                type={Constant.visitorsMethodsTypes.chat}
                              />
                            ) : (
                              0
                            )}
                          </td>
                          <td className="time-start">
                            {item.click ? (
                              <AdVisitorModal
                                count={item.click}
                                title={"Views"}
                                id={item.id}
                                type={Constant.visitorsMethodsTypes.view}
                              />
                            ) : (
                              0
                            )}
                          </td>
                          <td className="time-start">
                            {item.call_count ? (
                              <AdVisitorModal
                                count={item.call_count}
                                title={"Calls"}
                                id={item.id}
                                type={Constant.visitorsMethodsTypes.call}
                              />
                            ) : (
                              0
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <NoRecord />
                  )}
                </tbody>
              )}
            </table>
          </div>
          <div>
            {totalPage > 1 ? (
              <Pagination
                totalPages={totalPage}
                page={page}
                key={totalPage}
                onPageClick={(page) => setPage(page + 1)}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </AuctionAdsWrapper>
    </>
  );
};

export default AuctionAds;

// deletedRow
