import styled from "styled-components";

export const DashboardWrapper = styled.div`
.headerContainer{
    justify-content:center;
}
.pieChartContainer{
    display:flex;
    flex-direction:row;
    overflow-x: auto;
    margin-top: 30px;
    min-width: 100%;
    padding-bottom: 10px;
    padding-left: 10px;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.pieChartContainer::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.pieChartContainer {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.recharts-surface{
    width:150px !important;
}
.chartBox{
    display:flex;
    flex-direction:row;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 6px;
    box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.2);
    min-width:350px;
    min-height:120px;
}
.chartHead{
    display:flex;
    flex-direction: column;
    min-width :180px
}
.head{
    font-size: 18px;
    font-weight: 500;
}
.desc{
    font-size: 20px;
    color: #808191;
}
.header{
    font-size: 20px;
    font-weight: 600;
}
.tabBtn {
    border: none;
    border-bottom: 1px solid #E9E9E9;
    border-radius: 0px;
}
.tabBtn button {
    padding: 2px 20px;
}
.activeTab {
    border: none !important;
    border-bottom: 2px solid #5d78ff !important;
    border-radius: 0px;
    color: #5d78ff;
}
.barChartValues{
    font-weight:550;
}
.barChartBox{
    width: auto;
    height: 300px;
}
.barChartBox .recharts-wrapper{
    width: 100% !important;
    height: 100% !important;
}
.barChartBox .recharts-wrapper svg {
    width: auto !important;
    height: auto !important;
}
.auctionClr{
    color: #617AF7;
}
.listHeader{
    display:flex;
    justify-content: space-between;
}
.userImage img{
    height: 55px;
    width: 55px;
    border: 1px solid #ffffff;
    border-radius: 50%;
    overflow: hidden;
}
.contentBox{
    min-height:55px
}
.valBox{
    color: #808191;
  }
.ms-2{
    margin-left: 0 !important;
}
.classic-auction{
        /* d-flex justify-content-between */
        display: flex !important;
        justify-content: space-between !important;
        min-width: 500px;
}
.barChartValues2{
        overflow-x: auto;
        padding-bottom: 10px;
    }
  @media (max-width: 1467px) {
    .overview-options {
        display: block !important;
    }
    .overview-options p{
        margin-left: 0 !important;
    }
}
  @media (max-width: 1399px) {
    .commission-amt{
        justify-content: center !important;
    }
    .tabBtn{
        margin-top: 1px;
    }
    .barChartValues2{
        display: block !important;
    }
}
/* 1467 */
`;
