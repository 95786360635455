import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PreviewWrapper } from "./style/style";
import hamburger from '../../../assets/Images/image/hamburger.svg'
import logo from "../../../assets/Images/image/ctbIcon.png";
import shop from "../../../assets/Images/image/shop.svg";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import DOMPurify from "dompurify";
import { ThreeDots } from "react-loader-spinner";


const Preview = () => {

  const [languages,_languages] =useState(JSON.parse(localStorage.getItem('languages')))
  const [language,_language] = useState('en')

  const [content,_content] = useState({})
  const [previewHTML,_previewHTML] = useState('')

  const [loading,_loading] = useState(false)

  const navigate = useNavigate();

  useEffect(() => {
    getRecord()
  }, [])

  useEffect(()=>{
    _loading(true)
    if(content[language]){
      _previewHTML(content[language])
    }
    else{
      _previewHTML('')
    }
    _loading(false)
  },[language,content])

  const { recId } = useParams();

  const getRecord = () =>{
    API.get(APP_URLS.SHOW_LEGAL_DOCUMENTS + `/${recId}`)
    .then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        _content(resp.data.item.content)
      } else {
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    })
    .catch(function (error) {
      const resp = error.response;
      let error_message = "";
      if (resp?.data?.data !== undefined) {
        {
          Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
        }
      } else if (resp?.data?.data?.error !== undefined) {
        error_message = resp.data.data.error;
      } else if (resp?.data?.error !== undefined) {
        error_message = resp?.data.error;
      } else {
        error_message = resp?.data.message;
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    });
  }

  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html)
    }
  }

  return (
    <PreviewWrapper>
      <div>
        <div className="mt-2">
          <div className="backContainer ms-4" onClick={() => navigate(-1)}>
            <span className="backButton">x</span>
            <span className="px-3 backButton">|</span>
            <span className="backButton">Cancel</span>
          </div>
        </div>
        <div>
          <h1>PREVIEW</h1>
        </div>
        <div className="d-flex align-items-center justify-content-end ">
            <div className="justify-content-end mt-2 me-2">
              <select name="language" id="language" className="filterBox" onChange={(e)=>_language(e.target.value)} value={language}>
                <option hidden>Choose Language</option>
                {languages.map((item,index)=>{
                  return <option value={item.code}>{item.name}</option>
                })}
              </select>
            </div>
          </div>
        <div className="row mt-4">
          <div className="preview">
            <div className="d-flex justify-content-between flex-row p-2">
              <div className='header-box'>
                <img src={logo} alt="" className="ctblogo"/>
                <img className='mx-3 header-icon' src={hamburger} alt=""/>
                <div className='search-box ms-5 px-3'>
                  <img className="navigate-icon ms-2 me-4" src={shop} alt=""/>
                  <span className="">|</span>
                  <input type="text" placeholder="Find your dealer"/>
                </div>
              </div>
              <div className="header-box">
                <button className="loginBtn me-2">LOGIN</button>
              </div>
            </div>
            <div className="content">
              {loading ?
                <div className='d-flex justify-content-center three-dot'>
                  <ThreeDots
                    height="150"
                    width="150"
                    radius="9"
                    color="#637df4"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>:
                <div
                  className="col-md-8"
                  dangerouslySetInnerHTML={createMarkup(previewHTML)}>
                </div>
                }
            </div>
            <div className="footer py-3">
              <div className="footerLeft">
                <span className="ms-5">Copyright 2023</span>
              </div>
              <div className="footerRight me-5">
                <span className="me-3">Terms</span>
                <span className="me-3">Privacy Policy</span>
                <span className="me-3">Data Protection</span>
                <span className="me-3">Legal Notice</span>
                <span className="me-3">Legal Documents</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PreviewWrapper>
  );
};

export default Preview;
