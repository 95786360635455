import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { ThreeDots } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import API from '../../../api/axios';
import { APP_URLS } from '../../../api/url';
import close from "../../../assets/Images/icons/Cancel.svg";
import NoRecord from '../../../assets/SharedComponents/Table/NoRecord';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';

const HistoryPopup = (props) => {
  const [data, _data] = useState([])
  const [loaded, _loaded] = useState(false)

  useEffect(() => {
    if (props.popup) {
      getHistory()
    }
  }, [props.popup])

  //fetching credit modifictation history
  const getHistory = () => {
    _loaded(true)
    API.get(`${APP_URLS.HOTDEAL_ADS}/${props.adId}/history`)
      .then(res => {
        _loaded(false)
        const resp = res.data;
        if (resp.success === true) {
          _data(resp.data.items)
        }
        else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT
          })
        }
      })
      .catch(function (error) {
        _loaded(false)
        const resp = error.response;
        let error_message = '';
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map(
              (error, index) => (error_message = resp.data.errors[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error
        } else {
          error_message = resp.data.message
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT
        })
      });
  }

  return (
    <Dialog open={props.popup} onClose={() => props._popup(false)} sx={{
      '.MuiPaper-root': {
        width: '600px',
        padding: '0px 15px 15px',
      },
      '.table-row': {
        'th': {
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        'td': {
          width: '100%',
        }
      }
    }}>
      <DialogTitle textAlign={'center'}>
        <div className="modal-header">
          <h5 className="modal-title">Access History</h5>
          <img src={close} alt="" className="mx-2" role="button" onClick={() => props._popup('')} />
        </div>
      </DialogTitle>
      <DialogContent sx={{
        'tr .table-row th': {
          padding: '0px 12px',
        }
      }}>
        {loaded ?
          <div className='d-flex justify-content-center three-dots'>
            <ThreeDots
              height="150"
              width="150"
              radius="9"
              color="#637df4"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
          :
          <table className='previewTable w-100'>
            <thead className='thead'>
              <tr className='table-row'>
                <th>Name</th>
                <th>Type</th>
                <th>Credit</th>
                <th>Price</th>
                <th>
                  <span>Created At/<br />Updated At</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((item, index) => {
                  return (
                    <tr className='table-row' key={index}>
                      <td>{item.user.user_name.charAt(0).toUpperCase() + item.user.user_name.slice(1)}</td>
                      <td>{(item.type == 1 ? "Created" : "Updated")}</td>
                      <td>{item.properties.credit || '-'}</td>
                      <td>{item?.price || '-'}</td>
                      <td>
                        {item.created_at ? moment(item.created_at).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm") : "-"}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <NoRecord />
              )}
            </tbody>
          </table>
        }
      </DialogContent>
    </Dialog>
  )
}

export default HistoryPopup