import React, { useEffect, useState } from "react";
import MainLayout from "../../../../assets/Layout/MainLayout";
import Switch from "react-switch";
import API from "../../../../api/axios";
import { APP_URLS } from "../../../../api/url";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CraeteVehicleWrapper } from "../../style/createVehicle";
import { handleColorImage } from "../../../../core/utils/handleImageEvents/handleColorImage";
import { SelectImageCreate, SelectImageHeading } from "../../../../assets/SharedComponents/SelectImage";

let name = {};

const CreateProductType = () => {

  const [languages, _languages] = useState(JSON.parse(localStorage.getItem("languages")));
  const [countries, _countries] = useState(JSON.parse(localStorage.getItem("countries")));

  const [loading,_loading] = useState(false)
  
  const [checked,_checked]= useState(false)

  const [colorImageMobile,_colorImageMobile] = useState('')
  const [colorImageValueMobile,_colorImageValueMobile] = useState('')

  const [colorImageWeb,_colorImageWeb] = useState('')
  const [colorImageValueWeb,_colorImageValueWeb] = useState('')

  const[productCategories,_productCategories] = useState([])
  const [productValue, _productValue] = useState("");

  const navigate = useNavigate()

  useEffect(() => {
    getProductCategories()
  }, [])


  const getProductCategories = () => {
    API.get(APP_URLS.LIST_PRODUCT_CATEGORY+"?limited_data=true")
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _productCategories(resp.data.items);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map((error, index) => (error_message = resp.data.errors[error][0]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  

  const saveImage =(imgWeb, imgMobile , respId) =>{
      var formdata = new FormData(); 
      formdata.append("icon[web]",imgWeb)
      formdata.append("icon[mobile]",imgMobile) 
      formdata.append("model",6)
      API.post(APP_URLS.ADD_ICON + `/${respId}`,formdata)
      .then(res => {
        const resp = res.data;
        if(resp.success===true){
            _colorImageWeb('')
            _colorImageValueWeb('')
            _colorImageMobile('')
            _colorImageValueMobile('')
            navigate("/translation/vehicle-product-types");
        }
        else{
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT
          })
        }
      })
      .catch(function (error) {  
        const resp = error.response;
        let error_message = '';
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map(
              (error, index) =>(error_message = resp.data.data[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
            error_message =  resp.data.data.error;
        } else if(resp.data?.error!== undefined){
            error_message =  resp.data.error
        } else{
            error_message =  resp.data.message
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT
        })
      });
  }


  const handleSubmit = () =>{
    let data = {}
    data["item_name"] = name
    data["active"] = checked?1:0
    data["product_category_id"] = productValue
    let flag = false
    if (
      (name["en"] === "" || name["en"] === undefined || name["en"] === null) &&
      (name["fr"] === "" || name["fr"] === undefined || name["fr"] === null)
    ) {
      flag = false;
      toast.error("Product Type Title in English and French is mandatory", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }  else if(!colorImageValueMobile ||  !colorImageValueWeb){
      toast.error("Please provide icons", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else if ((productValue === null || productValue === "")) {
      toast.error("Please provide product category", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else{
      flag = true
    }
    if(flag===true){
      if(!loading){
        _loading(true)
        API.post(APP_URLS.ADD_PRODUCT_TYPES , data)
        .then(res => {
          _loading(false)
          const resp = res.data;
          if(resp.success===true){
            let respId =resp.data.productType.id
            toast.success("Product Type has been added successfully.", {
              position: toast.POSITION.TOP_RIGHT
            })
            saveImage(colorImageValueWeb, colorImageValueMobile,respId) 
            resetForm()
          }
          else{
            toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT
            })
          }
        })
        .catch(function (error) {  
          _loading(false)
            const resp = error.response;
            let error_message = '';
            if (resp.data.data !== undefined && resp.data.data !== null) {
              {
                Object.keys(resp.data.data).map(
                  (error, index) =>(error_message = resp.data.data[error][0])
                );
              }
            } else if (resp.data.data?.error !== undefined) {
                error_message =  resp.data.data.error;
            } else if(resp.data?.error!== undefined){
                error_message =  resp.data.error
            } else{
                error_message =  resp.data.message
            }
            toast.error(error_message, {
              position: toast.POSITION.TOP_RIGHT
            })
        });
      }
    }
  }

  const resetForm =() =>{
    _checked('false')
    let inputs = document.querySelectorAll(".departmentFields");
    inputs.forEach((input) => {
      input.value = "";
    });
    document.getElementById('name').value =''
    handleChange(false)
  }

  const handleChange = (e) => {
    _checked(e)
  };

  const handleTitle = (val, code) => {
    name[code] = val;
  };
  


  return (
    <>
      <CraeteVehicleWrapper>
        <div className='mt-2' >
          <div className="backContainer ms-4" onClick={()=>navigate("/translation/vehicle-product-types")}>
            <span className='backButton'>x</span>
            <span className='px-3 backButton'>|</span>
            <span className='backButton'>Cancel</span>
          </div>
        </div>
        <div className="headerContainer">
          <h1>CREATE PRODUCT TYPE</h1>
        </div>
        <div className="row">
            <div className="d-flex justify-content-end save-btn mt-3">
            <button className={(loading?"disabled":"")+" add-button"} onClick={handleSubmit} disabled={loading}>Save</button>
            </div>
            <div className="row input-fileds">
              {languages.map((item, index) => {
                let itemCode = item.code;
                let flag = countries.find((o) => o.id === item.country_id)?.flag_thumbnail;
                return (
                  <div className="col-md-4" key={index}>
                    <div className="col-md-12 mt-3">
                      <label htmlFor="title" className="form-label">
                        <span className="countryIcon px-2">
                          <img src={flag} alt="" className="countryflag" title={item.name}/>
                        </span>
                        <span className="langName">{item.code}</span>
                      </label>
                      <input
                        type="text"
                        id="title"
                        name="title"
                        className="form-control departmentFields"
                        onChange={(e, code = itemCode) => {
                          handleTitle(e.target.value, code);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="row input-fileds">
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label htmlFor="name" className="form-label fieldLabel">
                      Product Category
                    </label>
                    <select name="productType" id="productType" className="form-control mt-2"
                    onChange={(e) => _productValue(e.target.value)}>
                    <option hidden>Select Product Category</option>
                      {productCategories.map(
                        (item, index) => {
                          return (
                            <option value={item.id} key={index}>
                              {item.name}
                            </option>
                          );
                        }
                      )}
                </select>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label htmlFor="title" className="form-label fieldLabel">
                      Is Active?
                    </label>
                    <div>
                      <Switch
                        onChange={(e)=>handleChange(e)}
                        checked={checked}
                        className="react-switch mt-2"
                        uncheckedIcon={false}
                        checkedIcon={false}
                        offColor="#D9D9D9"
                        onColor="#7589EC"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-4">
                <div className="row">
                  <SelectImageHeading/> 
                  <div className="col-md-6 mt-2">
                    <label>For Web</label>
                    <SelectImageCreate type='web' colorImage={colorImageWeb} handleColorImage={(e) => handleColorImage(e,_colorImageWeb,_colorImageValueWeb, 'web' )}/>   
                  </div>
                  
                  <div className="col-md-6 mt-2">
                    <label>For Mobile</label>
                    <SelectImageCreate type='mobile' colorImage={colorImageMobile} handleColorImage={(e) => handleColorImage(e,_colorImageMobile,_colorImageValueMobile, 'mobile' )}/>  
                  </div>
                </div>
              </div>
        </div>
          <div className="col-md-2"></div>
        </div>
      </CraeteVehicleWrapper>
    </>
  );
};

export default CreateProductType;
