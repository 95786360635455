import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import MainLayout from "../../../assets/Layout/MainLayout";
import { CraeteVehicleWrapper } from "../style/createVehicle";

const CreateVehicleFrameSizes = () => {

  const [products,_products] = useState(JSON.parse(localStorage.getItem("productType")))

  const [name,_name] = useState('')
  const [productValue,_productValue] = useState('')

  const [loading,_loading] = useState(false)

  const navigate = useNavigate()

  const resetForm = () =>{
    _name('')
    _productValue('1')
    document.getElementById("name").value=""
    document.getElementById("productType").value=1
  }


  const submitForm = () =>{
    let data = {}
    let flag = false
    data["name"] = name
    data["product_type_id"] = productValue
    if(name===null||name===""){
      toast.error("Frame size name can't be blank", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else if ((productValue === null || productValue === "")) {
      toast.error("Please provide product type", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else{
      flag =true
    }
    if(flag === true){
      if(!loading){
        _loading(true)
        API.post(APP_URLS.ADD_FRAME_SIZES,data)
        .then(res => {
          _loading(false)
          const resp = res.data;
          if(resp.success===true){
            toast.success("Frame Size Added Successfully.", {
              position: toast.POSITION.TOP_RIGHT
            })
            resetForm();
            navigate("/translation/vehicle-frame-sizes");
          }
          else{
            toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT
            })
          }
        })
        .catch(function (error) {  
          _loading(false)
          const resp = error.response;
          let error_message = '';
          if (resp.data.data !== undefined && resp.data.data !== null) {
            {
              Object.keys(resp.data.data).map(
                (error, index) =>(error_message = resp.data.data[error][0])
              );
            }
          } else if (resp.data.data?.error !== undefined) {
              error_message =  resp.data.data.error;
          } else if(resp.data?.error!== undefined){
              error_message =  resp.data.error
          } else{
              error_message =  resp.data.message
          }
          toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT
          })
        });
      }
    }
  }

  return (
    <>
      <CraeteVehicleWrapper>
      <div className='mt-2' >
          <div className="backContainer ms-4" onClick={()=>navigate("/translation/vehicle-frame-sizes")}>
            <span className='backButton'>x</span>
            <span className='px-3 backButton'>|</span>
            <span className='backButton'>Cancel</span>
          </div>
        </div>
        <div className="headerContainer">
          <h1>CREATE FRAME SIZES</h1>
        </div>
        <div className="d-flex justify-content-between save-btn mt-3">
          <h3 className="mb-2"></h3>
          <button className={(loading?"disabled":"")+" add-button"} onClick={submitForm} disabled={loading}>Save</button>
        </div>
        <div className="row input-fileds">
          <div className="col-md-4 d-flex flex-column">
              <label htmlFor="title" className="form-label fieldLabel">
                Name
              </label>
              <input type="text" placeholder="Name" name="Name" id="name" className="form-control mt-2" onChange={(e)=>_name(e.target.value)}/>
          </div>
          <div className="col-md-4">
            <label htmlFor="productType" className="form-label fieldLabel product-type">
              Product Type
            </label>
            <select name="productType" id="productType" className="form-control mt-2"
              onChange={(e) => _productValue(e.target.value)}>
              <option hidden>Select Product Type</option>
                  {products.map(
                    (item, index) => {
                      return (
                        <option value={item.product_categories[0].pivot.product_type_id} key={index}>
                          {item.item_name.en}
                        </option>
                      );
                    }
                  )}
                </select>
          </div>
          </div>
      </CraeteVehicleWrapper>
    </>
  );
};

export default CreateVehicleFrameSizes;
