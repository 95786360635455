import React, { useEffect } from "react";
import MainLayout from "../../../assets/Layout/MainLayout";
import { UsersWrapper } from "./style/User";
import { useState } from "react";
import Frontoffice from "./Components/FrontOffice/Frontoffice";
import BackOffice from "./Components/BackOffice/BackOffice";
import AdminPanel from "./Components/AdminPanel/AdminPanel";
import { useLocation, useSearchParams } from "react-router-dom";
import ForumTab from "./Components/ForumTab";

const Users = () => {

  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const [searchParams, setSearchParams] = useSearchParams();

  const [tabView, setTabView] = useState(query.get('tab') !== null ? query.get('tab') : "frontOffice");
  const [permission, _permission] = useState(JSON.parse(localStorage.getItem('permissions')))

  let usersAccess = permission.find(o => o.slug === "users")
  let roleAccess = permission.find(o => o.slug === "role_management")

  //managing tabs for frontoffice, nomad , admin and forum
  const tabChange = (tab) => {
    setTabView(tab)
    for (var value of query.keys()) {
      const param = searchParams.get(value);
      if (param) {
        searchParams.delete(value);
        setSearchParams(searchParams);
      }
    }
  }

  return (
    <>
      <UsersWrapper>
        <div className="auctionMainContainer">
          <div className="row mt-4">
            <div className="col-md-4 offset-md-4 d-flex justify-content-center">
              <h1>USERS</h1>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-6 offset-md-3 d-flex justify-content-center">
              <div className="tabBtn d-flex">
                <button className={tabView === "frontOffice" ? "activeTab" : ""} onClick={() => tabChange("frontOffice")}>
                  Front office
                </button>
                <button className={tabView === "adminPanel" ? "activeTab" : " "} onClick={() => tabChange("adminPanel")}>
                  Admin panel
                </button>
                <button className={tabView === "forum" ? "activeTab" : " "} onClick={() => tabChange("forum")}>
                  Forum
                </button>
                <button className={tabView === "backOffice" ? "activeTab" : " "} onClick={() => tabChange("backOffice")}>
                  Nomad
                </button>
              </div>
            </div>
          </div>
          {tabView === "frontOffice" ? <Frontoffice usersAccess={usersAccess} />
            : tabView === "adminPanel" ? <AdminPanel usersAccess={usersAccess} roleAccess={roleAccess} />
              : tabView === "forum" ? <ForumTab usersAccess={usersAccess} roleAccess={roleAccess} />
                : tabView === "backOffice" ? <BackOffice usersAccess={usersAccess} />
                  : <Frontoffice />}
        </div>
      </UsersWrapper>
    </>
  );
};

export default Users;
