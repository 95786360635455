import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ImportWrapper } from "./style/import";
import { toast } from "react-toastify";

const ImportFile = () => {
    
  const [fileType, _fileType] = useState("");
  const [file, _file] = useState("");
  const [loading, _loading] = useState(false);
  const [download, _download] = useState(false);

  const navigate = useNavigate();

  const checkfile = (sender) => {
    var validExts = new Array(".xlsx", ".xls");
    var fileExt = sender.target.value;
    fileExt = fileExt.substring(fileExt.lastIndexOf("."));
    if (validExts.indexOf(fileExt) < 0) {
      toast.error(
        "Invalid file selected, valid files are of " +
          validExts.toString() +
          " types.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      _file("");
    } else {
      _file(sender.target.value);
    }
  };

  const upload = () =>{
    if(fileType===null||fileType===""||fileType===undefined){
        toast.error("Please select file type first", {
            position: toast.POSITION.TOP_RIGHT
        })
    } else if(file===null||file===""||file===undefined){
        toast.error("Please choose a file to upload", {
            position: toast.POSITION.TOP_RIGHT
        })
    }else{
        console.log('submitted')
    }
  }

  const downloadFile =()=>{
    _download(true)
  }

  return (
    <ImportWrapper>
      <div className="mt-2">
        <div className="backContainer ms-4" onClick={() => navigate(-1)}>
          <span className="backButton">x</span>
          <span className="px-3 backButton">|</span>
          <span className="backButton">Cancel</span>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 offset-md-4 ">
          <div className="headerContainer d-flex justify-content-center">
            <h1>IMPORT FILES</h1>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-6 offset-md-3 ">
          <div className="d-flex justify-content-end save-btn mt-2">
            <button className="add-depertment" disabled={loading} onClick={upload}>
              {loading ? "Uploading..." : "Upload"}
            </button>
          </div>
          <div className="row">
            <div className="col-md-12 mt-4">
              <label htmlFor="fileType" className="form-label fieldLabel">
                Choose File
              </label>
              <select
                name="fileType"
                id="fileType"
                className="form-control"
                value={fileType}
                onChange={(e) => _fileType(e.target.value)}
              >
                <option hidden>File</option>
                <option value="brand">Brand</option>
                <option value="model">Model</option>
                <option value="version">Version (Cars)</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mt-4">
              <label htmlFor="file" className="form-label fieldLabel">
                Upload File
              </label>
              <input
                type="file"
                className="form-control"
                accept=".xls,.xlsx"
                onChange={(e) => checkfile(e)}
                value={file}
              />
            </div>
          </div>
          <div className="row d-flex justify-content-center mt-5">
            <button
              className={
                (fileType === ""||download ? "disabled" : "") +
                " add-depertment sampleFile btn-info"
              }
              disabled={fileType !== null && fileType !== "" ? false : true||download}
              onClick={downloadFile}
            >
              {download?'Downloading..':'Download Sample'}
            </button>
          </div>
        </div>
      </div>
    </ImportWrapper>
  );
};

export default ImportFile;
