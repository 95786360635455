import styled from "styled-components";

export const CouponTableWrapper = styled.div`
  .headerContainer div,
  .headerContainer h1 {
    margin-bottom: 10px;
  }
  .searchContainer {
    display: flex;
    padding: 0 15px 0 0;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  .search {
    height: 35px;
    border: none;
    border-radius: 10px;
  }
  .btn {
    align-self: center;
    background-color: #5d78ff;
    border: none;
    height: 35px;
    border-radius: 5px;
    padding: 0 20px;
    color: white;
    font-weight: bold;
  }
  .clipboard{
    width:200px !important;
  }
  .clipboard img{
    cursor:pointer;
  }
  .copyText{
    border: 1px solid black;
    padding:2px 5px;
    border-radius:5px;
    display: inline-block;
    width: 120px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  td a {
    color: #5d78ff;
    text-decoration: none;
    font-weight: bold;
  }
  .description {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  td img {
    margin-left: 3px;
    cursor: pointer;
  }
  .clipboard {
    width: 195px !important;
  }
  .auctionTable {
    min-width: 1200px !important;
  }
  .w-100{
    width: 100px !important;
  }
  .status-name{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .status{
    width:15px;
    height:15px;
  }
  @media (max-width: 490px) {
    .add-info {
      // display: flex !important;
      align-items: center;
      justify-content: center !important;
      flex-wrap: wrap;
    }
    .select-info {
      justify-content: flex-end !important;
    }
  }
  @media (min-width: 457px) and (max-width: 495px) {
    .actionContainer {
      margin-right: 15px;
    }
  }
`;
