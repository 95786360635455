import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { FlaggedReviewWrapper } from "./style/FlaggedReviewWrapper";
import API from "../../api/axios";
import { APP_URLS } from "../../api/url";
import { toast } from "react-toastify";
import RecordCount from "../../assets/SharedComponents/RecordCount/RecordCount";
import { Tooltip } from "@mui/material";
import { FaDownArrow, FaFilterIcon, FaUpArrow } from "../../assets/Images/icon";
import FaStar from "../../assets/Images/icons/star.png";
import waste from "../../assets/Images/image/Waste.png";
import noActionImage from "../../assets/Images/image/noAction.png";
import { ThreeDots } from "react-loader-spinner";
import NoRecord from "../../assets/SharedComponents/Table/NoRecord";
import Pagination from "../../assets/SharedComponents/Pagination";
import ExpandableText from "./Components/ExpandableText";
import { confirmAlert } from "react-confirm-alert";
import SearchableDropDownWithPagination from "../../assets/SharedComponents/SearchableDropdown/SearchableDropDownWithPagination";
import getUniqueObjects from "../../core/utils/getUniqueObjects.js/getUniqueObjects";
import moment from "moment";
import { DEBOUNCE_TIME, FLAGGED_REVIEW_STATUS } from "../../config/constant";
import { redirect } from "../../core/utils/redirect/redirect";
import StaticFilter from "../../assets/SharedComponents/Filters/StaticFilter";
import Sorting from "../../assets/SharedComponents/Sorting/Sorting"; 

const FlagStatusList =   Object.keys(FLAGGED_REVIEW_STATUS).map((key)=> ({ name: key  , value : FLAGGED_REVIEW_STATUS[key] }))  

const FlaggedReview = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchkeyword, _searchkeyword] = useState("");
  const [countValue, _countValue] = useState("");
  const [page, setPage] = useState(1);
  const [reviewedBy, setReviewedBy] = useState(null);
  const [reportedBy, setReportedBy] = useState(null);
  const [sourceReported, setSourceReported] = useState("");
  const [sourceReviewed, setSourceReviewed] = useState("");
  const [loaded, _loaded] = useState(false);
  const [total, _total] = useState(0);
  const [totalPage, _totalPage] = useState(0);
  const [data, _data] = useState([]);
  const [dataDeleted, _dataDeleted] = useState(false);
  const [noAction, _noAction] = useState(false);
  const [filters, _filters] = useState(false);
  const [reporterSearch, setReporterSearch] = useState(null);
  const [reviewerSearch, setReviewerSearch] = useState(null);
  const [sort ,_sort] = useState('-created_at'); 
  const [status , setStatus] = useState(FLAGGED_REVIEW_STATUS.Active); 
  const [reporterList, setReporterList] = useState({
    data: [],
    loading: false,
    error: "",
    totalPage: 1,
    currentPage: 1,
  });
  const [reviewerList, setReviewerList] = useState({
    data: [],
    loading: false,
    error: "",
    totalPage: 1,
    currentPage: 1,
  });
  const searchParamsValues = useMemo(() => {
    const searchkeyword = searchParams.get("a_keyword") || "";
    const countValue = searchParams.get("a_per_page") || "";
    const page =
      searchParams.get("a_page") !== null
        ? parseInt(searchParams.get("a_page"))
        : 1;
    const reviewedBy = searchParams.get("reviewed_by") ||null;
    const reportedBy = searchParams.get("reportedBy") || null;
    const sourceReviewed = searchParams.get("source_reviewed") || "";
    const sourceReported = searchParams.get("source_reported") || "";
    const status = searchParams.get("status")||FLAGGED_REVIEW_STATUS.Active 
    return {
      searchkeyword,
      countValue,
      page,
      reviewedBy,
      reportedBy,
      sourceReported,
      sourceReviewed,
      status
    };
  }, [searchParams]);

  useEffect(() => {
    _searchkeyword(searchParamsValues.searchkeyword);
    _countValue(searchParamsValues.countValue);
    setPage(searchParamsValues.page);
    setReportedBy(searchParamsValues.reportedBy);
    setReviewedBy(searchParamsValues.reviewedBy);
    setSourceReported(searchParamsValues.sourceReported);
    setSourceReviewed(searchParamsValues.sourceReviewed);
    setStatus(searchParamsValues.status)
  }, [searchParamsValues]);

  useEffect(() => {
    getFlaggedReviewList();
  }, [page, countValue, reportedBy, reviewedBy, noAction, dataDeleted, status,sort]);

  useEffect(() => {
    getReporterList();
  }, [noAction, dataDeleted, reporterList?.currentPage]);

  useEffect(() => {
    getReviewerList();
  }, [noAction, dataDeleted, reviewerList?.currentPage]);

  useEffect(() => {
    const getData = setTimeout(() => { 
      if(reviewerSearch !== null)
      getReviewerList(true);
    }, DEBOUNCE_TIME);
    return () => clearTimeout(getData);
  }, [reviewerSearch]);

  useEffect(() => {
    const getData = setTimeout(() => { 
      if(reporterSearch !== null)
      getReporterList(true);
    }, DEBOUNCE_TIME);
    return () => clearTimeout(getData);
  }, [reporterSearch]);

  const getFlaggedReviewList = () => {
    _loaded(true);
    let urlParams = {
      'page': page,
      'per_page': countValue,
      'filter[reported_by]': reportedBy?.id||'',
      'filter[reviewed_by]': reviewedBy?.id||'',
      'filter[status]':status,
      'source_reviewed': sourceReviewed,
      'source_reported': sourceReported, 
      'sort':sort
    }; 
    let restUrl = new URLSearchParams(urlParams);
    API.get(
      APP_URLS.LIST_FLAGGED_REVIEW + `?${restUrl}`
    )
      .then((res) => {
        const resp = res.data;
        if (resp.success) {
          _data(resp.data.data);
          _totalPage(resp.data.last_page);
          _total(resp.data.total);
          _loaded(false);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loaded(false);
        }
      })
      .catch(function (error) {
        _loaded(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map(
              (error, index) => (error_message = resp.data.errors[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }; 

  // get list of reporters (Name of the persons or subsidiaries who has flagged a review.)
  const getReporterList = (isSearching= false ) => {
    setReporterList({ ...reporterList, loading: true });

    API.get(APP_URLS.LIST_OF_REPORTED_BY +`?page=${reporterList?.currentPage }&per_page=${25}&keyword=${reporterSearch ||''}` )
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          const data = resp.data.data;
          let newData = data;
          if (!isSearching)
            newData = getUniqueObjects([...reporterList.data, ...data]);
          setReporterList({
            ...reporterList,
            data: newData,
            currentPage: resp.data.current_page,
            error: "",
            totalPage: resp.data.last_page,
            loading: false,
          });
        } else {
          setReporterList({
            ...reporterList,
            loading: false,
            error: resp.message,
          });
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map(
              (error, index) => (error_message = resp.data.errors[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        setReporterList({
          ...reporterList,
          loading: false,
          error: error_message,
        });
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  // get list of reviewers (Name of the persons or subsidiaries whose review was flagged) 
  const getReviewerList = (isSearching = false ) => {
    setReviewerList({ ...reviewerList, loading: true });
    API.get(APP_URLS.LIST_OF_REVIEWED_BY +`?page=${reviewerList?.currentPage}&per_page=${25}&keyword=${reviewerSearch||''}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          let data = resp.data.data;
          if (!isSearching) {
            data = getUniqueObjects([...reviewerList.data, ...data]);
          }
          setReviewerList({
            ...reviewerList,
            data: data,
            currentPage: resp.data.current_page,
            error: "",
            totalPage: resp.data.last_page,
            loading: false,
          });
        } else {
          setReviewerList({
            ...reviewerList,
            loading: false,
            error: resp.message,
          });
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map(
              (error, index) => (error_message = resp.data.errors[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        setReviewerList({
          ...reviewerList,
          loading: false,
          error: error_message,
        });
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  // handle search and per page data changes
  const handleSearchChange = (func, searchValue) => {
    func(searchValue);
    if (
      (func === _countValue && searchValue !== countValue) ||
      (func === _searchkeyword && searchValue !== searchkeyword)
    ) {
      if (searchValue !== "") {
        _totalPage(0);
        setPage(1);
      }
    }
  };

  const clearFilter = () => {
    _filters(true);
    setReportedBy(() => {});
    setReviewedBy(() => {});
    setReporterSearch("");
    setReviewerSearch("");
    _sort('-created_at')
    setStatus(FLAGGED_REVIEW_STATUS.Active)
    if (countValue !== "" || reportedBy !== "" || reportedBy !== "") {
      _totalPage(0);
      setPage(1);
    }
    for (var value of query.keys()) {
      const param = searchParams.get(value);
      if (param) {
        searchParams.delete(value);
        setSearchParams(searchParams);
      }
    }
  };

  const deleteReview = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: `Are you sure you want to delete?`,
      closeOnEscape: true,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            API.delete(APP_URLS.Delete_Flagged_REVIEW + `/${id}`)
              .then((res) => {
                const resp = res.data;
                if (resp.success === true) {
                  _dataDeleted(true);
                  toast.success(
                    "Flagged Review has been deleted successfully.",
                    {
                      position: toast.POSITION.TOP_RIGHT,
                    }
                  );
                } else {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              })
              .catch(function (error) {
                const resp = error.response;
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                  {
                    Object.keys(resp.data.data).map(
                      (error, index) =>
                        (error_message = resp.data.data[error][0])
                    );
                  }
                } else if (resp.data.data?.error !== undefined) {
                  error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                  error_message = resp.data.error;
                } else {
                  error_message = resp.data.message;
                }
                toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          },
        },
        {
          label: "No",
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-custom-class-name",
    });
  };

  const handleNoAction = (id) => {
    confirmAlert({
      title: "Confirm to take no action",
      message: `Are you sure you want to take no action?`,
      closeOnEscape: true,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            API.post(
              APP_URLS.Delete_Flagged_REVIEW + `/${id}/no_action?_method=patch`
            )
              .then((res) => {
                const resp = res.data;
                if (resp.success === true) {
                  _noAction(true);
                  toast.success("Reviewed successfully.", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                } else {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              })
              .catch(function (error) {
                const resp = error.response;
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                  {
                    Object.keys(resp.data.data).map(
                      (error, index) =>
                        (error_message = resp.data.data[error][0])
                    );
                  }
                } else if (resp.data.data?.error !== undefined) {
                  error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                  error_message = resp.data.error;
                } else {
                  error_message = resp.data.message;
                }
                toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          },
        },
        {
          label: "No",
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-custom-class-name",
    });
  };

  

  const showRatings = (ratingCount) => {
    const ratings = Array.from(
      { length: ratingCount },
      (_, index) => index + 1
    );
    return (
      <>
        {ratings.map(() => (
          <img src={FaStar} />
        ))}
      </>
    );
  }; 
  const handleStatusChange = (type) =>{
    _totalPage(0)
    setPage(1);
    setStatus(type)
  } 
  const setsorting = (val) =>{
    _sort(val);
    if (sort !== val) {
      setPage(1);
      _totalPage(0)
    }
  }

  return (
    <FlaggedReviewWrapper>
      <div className="auctionMainContainer">
        <div className="row mt-4">
          <div className="col-md-4 offset-md-4 mb-2">
            <h1>Flagged Review</h1>
          </div>
        </div>
        <div className="justify-content-end mb-2 d-flex gap-2 mb-2 select-info align-items-center">
          <SearchableDropDownWithPagination
            setPage={setPage}
            search={reporterSearch}
            setSearch={setReporterSearch}
            setSource={setSourceReported}
            placeholder="Reported By"
            data={reporterList?.data || []}
            selectValue={reportedBy}
            setSelectValue={setReportedBy}
            hasMore={reporterList?.totalPage > reporterList?.currentPage}
            loading={reporterList?.loading}
            setData={setReporterList}
            error={reporterList?.error}
          />
          <SearchableDropDownWithPagination
            setPage={setPage}
            search={reviewerSearch}
            setSearch={setReviewerSearch}
            setSource={setSourceReviewed}
            placeholder="Review Given By"
            data={reviewerList?.data || []}
            selectValue={reviewedBy}
            setSelectValue={setReviewedBy}
            hasMore={reviewerList?.totalPage > reviewerList?.currentPage}
            loading={reviewerList?.loading}
            setData={setReviewerList}
            error={reviewerList?.error}
          />
          <div className="select-wrapper"> 
            <RecordCount
              onSearchClick={(search) => handleSearchChange(_countValue, search)}
              id="pageCount"
              val={countValue}
              filters={filters}
              _filters={_filters}         
              /> 
           </div>
          <StaticFilter data={FlagStatusList}  handleChange={(e) => {handleStatusChange(e.target.value)}} value={status}/>   
          <Tooltip title="Clear Filter" placement="bottom">
            <FaFilterIcon
              className="filter-icon"
              role="button"
              onClick={clearFilter}
            />
          </Tooltip>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between info-wrap">
        <div className="justify-content-end mt-2 me-2">
          <span className="text-start">
            Total Records : <b>{total}</b>
          </span>
        </div> 
      </div>
      <div className="tableContainer">
        <table className="auctionTable reviewTable">
          <thead className="tableHead">
            <tr className="table-row">
              <th className="time-start"> Reported By </th>
              <th>Review Given By</th>
              <th className="longText">Review</th>
              <th className="time-start"> Ratings </th>
              <th className="">Flag Reason</th>
              <th className="time-start">
                    Reviewed At 
                    <br /> Flagged At
                    <Sorting sortType='created_at' sort={sort} handleSort={setsorting} />  
                  </th>
              <th>Action</th>
            </tr>
          </thead>
          {loaded ? (
            <div className="d-flex justify-content-center three-dot">
              <ThreeDots
                height="150"
                width="150"
                radius="9"
                color="#637df4"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          ) : (
            <tbody>
              {data.length > 0 ? (
                data.map((item) => {

                  const isDeleted = item?.deleted_at && item.ratings?.deleted_at;
                  const isNoActionDone = item?.deleted_at && (!item.ratings?.deleted_at);
                  return (
                    <tr
                      className={
                        (isDeleted ? "deletedRow " : isNoActionDone ? 'no-action-row ' :'' ) + "table-row" 
                      }
                      key={item.id}
                    >
                      <td
                        className="link"
                        onClick={() => {
                          redirect(item);
                        }}
                      >
                        <span title={item.reviewer}>
                          <b>
                            {item.subsidiary_id
                              ? item.subsidiary.name
                              : item.user.pseudo}
                          </b>
                        </span>
                      </td>
                      <td>
                        <span>
                          {item.ratings?.rated_by_subsidiary_id
                            ? item.ratings?.rated_by_subsidiary.slug
                            : item.ratings?.rated_by_user.pseudo}
                        </span>
                      </td>
                      <td className="longText">
                        <ExpandableText text={item.ratings?.review || "-"} />
                      </td>
                      <td className="rating-stars">
                        {showRatings(item.ratings?.rating)}
                      </td>
                      <td className="">
                        <ExpandableText text={item.comment || "-"} />
                      </td>
                      
                      <td>
                        <span className="d-block">{item.ratings?.created_at ? moment(item.ratings?.created_at).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm") : '-'}</span>
                        <span className="block">{item?.created_at ? moment(item?.created_at ).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm") :'-'}</span> 
                      </td>
                      <td className="w80 ">
                        {!item.deleted_at ? (
                          <>
                            <Tooltip title="Delete Review" placement="top">
                              <img
                                src={waste}
                                alt=""
                                role="button"
                                onClick={() => deleteReview(item.id)}
                              />
                            </Tooltip>
                            <Tooltip title="No Action Required" placement="top">
                              <img
                                className="no-action"
                                src={noActionImage}
                                alt=""
                                role="button"
                                onClick={() => handleNoAction(item.id)}
                              />
                            </Tooltip>
                          </>
                        ) : isDeleted ? (
                          "Deleted Review"
                        ) : (
                          "No Action Taken"
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <NoRecord />
              )}
            </tbody>
          )}
        </table>
      </div>
      <div>
        {totalPage > 1 ? (
          <Pagination
            totalPages={totalPage}
            page={page}
            key={totalPage}
            onPageClick={(page) => setPage(page + 1)}
          />
        ) : (
          ""
        )}
      </div>
    </FlaggedReviewWrapper>
  );
};

export default FlaggedReview;
