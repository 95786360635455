import styled from "styled-components";

export const RoleWrapper = styled.div`
  // .headerContainer {
  //   justify-content: center !important;
  // }
  .role-table-container {
    height: auto;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
  }
  .role-table {
    color: #909294;
    width: 100%;
    min-width: 600px;
  }
  .header {
    background: #b6b6b61f;
  }
  .feature {
    width: 400px;
  }
  .toggle {
    display: flex;
    justify-content: end;
  }
  .permissions {
    width: 100px;
  }
  .role-table td {
    text-align: center;
  }
  .role-table td:first-child {
    text-align: left;
  }
  .role-table th {
    font-weight: 900;
    font-size: 15px;
    line-height: 13px;
    text-align: center;
    color: #909294;
  }
  .checkbox-round {
    width: 0.9em;
    height: 0.9em;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #607afc;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
  }

  .checkbox-round:checked {
    background-color: #607afc;
  }
  .form-check-input:focus {
    border-color: #ccc;
    outline: 0;
    box-shadow: none !important;
  }
  .form-switch {
    padding-left: 2.5em;
    padding-top: 5px;
  }
  .form-switch .form-check-input {
    width: 2.6em;
  }
  .form-check-input:checked {
    background-color: #607afc;
    border-color: #607afc;
  }
  input[type="checkbox"] {
    transform: scale(1.1);
    cursor: pointer;
  }
  .disabledField {
    background-color: rgba(0, 0, 0, 0.25);
    border: none;
    cursor: not-allowed !important;
  }
  .save-btn {
    color: white;
    min-width: 100px;
    padding: 0.5rem !important;
    width: auto;
    border: none;
    background: #fbc524;
    font-weight: bold;
    border-radius: 5px;
  }
  .actionContainer {
    display: flex;
    gap: 20px;
  }

  textarea.form-control {
    min-height: calc(1.5em + (0.75rem + 2px));
    min-height: 38px !important;
  }

  @media (max-width: 480px) {
    td b {
      font-size: 12px;
    }
  }
`;
