 
import Pusher from 'pusher-js';  
const window  = {};
window.Pusher = Pusher;
Pusher.logToConsole = process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test';

let configGration = {}
const pusherKey= process.env.REACT_APP_PUSHER_KEY
configGration['cluster'] = process.env.REACT_APP_PUSHER_CLUSTER

// Public pusher
export const PusherClient = new Pusher(pusherKey, configGration);
 
const user = localStorage.getItem('user')

if (user) {
  PusherClient.subscribe('bid_auctions');
}