import React, { useEffect, useState } from "react";
import MainLayout from "../../../assets/Layout/MainLayout";
import { CraeteVehicleWrapper } from "../style/createVehicle";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const CreateVehicleTireSizes = () => {

  const [products, _products] = useState(JSON.parse(localStorage.getItem("productType")));
  const [productValue, _productValue] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [diameter, setDiameter] = useState("");

  const [loading,_loading] = useState(false)

  const navigate = useNavigate()

  const submitForm = () => {
    let data = {};
    data["product_category_type_id"] = productValue;
    data["width"] = width===""?null:width;
    data["height"] = height===""?null:height;
    data["diameter"] = diameter;
    if (productValue === null || productValue === "") {
      toast.error("Please provide product type", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else{
      if(productValue==='1'){
        if (width === "" || width === undefined || width === null) {
          toast.error("Please provide tire width", {
            position: toast.POSITION.TOP_RIGHT
          })
        }
        else if (height === "" || height === undefined || height === null) {
          toast.error("Please provide tire height", {
            position: toast.POSITION.TOP_RIGHT
          })
        }
        else if (diameter === "" || diameter === undefined || diameter === null) {
          toast.error("Please provide tire diameter", {
            position: toast.POSITION.TOP_RIGHT
          })
        }
        else{
          saveData(data)
        }
      }
      else {
        if (diameter === "" || diameter === undefined || diameter === null) {
          toast.error("Please provide tire diameter", {
            position: toast.POSITION.TOP_RIGHT
          })
        }
        else{
          saveData(data)
        }
      }
    }
  };

  const saveData = (data) =>{
    if(!loading){
      _loading(true)
      API.post(APP_URLS.UPDATE_TIRE_SIZE, data)
      .then((res) => {
        _loading(false)
        const resp = res.data;
        if (resp.success === true) {
          toast.success("Tire Size Added Successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          resetForm();
          navigate("/vehicles-management/vehicle-tire-size");
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        _loading(false)
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
    }
  }

  const resetForm = () => {
    let inputs = document.querySelectorAll(".departmentFields");
    inputs.forEach((input) => {
      input.value = "";
    });
  };

  return (
    <>
        <CraeteVehicleWrapper>
          <div className='mt-2' >
            <div className="backContainer ms-4" onClick={()=>navigate("/vehicles-management/vehicle-tire-size")}>
              <span className='backButton'>x</span>
              <span className='px-3 backButton'>|</span>
              <span className='backButton'>Cancel</span>
            </div>
          </div>
          <div className="headerContainer">
            <h1>CREATE TIRE SIZE</h1>
          </div>
            <div className="d-flex justify-content-end save-btn mt-3">
            <button className={(loading?"disabled":"")+" add-button"} onClick={submitForm} disabled={loading}>Save</button>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label htmlFor="title" className="form-label fieldLabel">
                      Width
                    </label>
                    <input
                      type="number"
                      id="title"
                      name="title"
                      className="form-control mt-2 departmentFields"
                      rows="2"
                      onChange={(e) => setWidth(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label htmlFor="title" className="form-label fieldLabel">
                      Height
                    </label>
                    <input
                      type="number"
                      id="title"
                      name="title"
                      className="form-control mt-2 departmentFields"
                      rows="2"
                      onChange={(e) => setHeight(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label htmlFor="title" className="form-label fieldLabel">
                      Diameter
                    </label>
                    <input
                      type="number"
                      id="title"
                      name="title"
                      className="form-control mt-2 departmentFields"
                      rows="2"
                      onChange={(e) => setDiameter(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mt-3">
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="productType" className="form-label fieldLabel product-type">
                      Product Type
                    </label>
                  <select name="productType" id="productType" className="form-control mt-2" onChange={(e) => _productValue(e.target.value)}>
                    <option hidden>Select Product Type</option>
                      {products.map((item, index) => {
                        return (
                          <option value={item.product_categories[0].pivot.id} key={index}>
                            {item.item_name.en}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
        </CraeteVehicleWrapper>
    </>
  );
};

export default CreateVehicleTireSizes;
