import React from 'react'

const ProductCardDesktop = (props) => {
  return (
    <div className="product-card d-flex align-items-center mx-3">
        <img src={props.image} />
        <div className="shadow-box mt-2"></div>
        <div className="product-card-title mt-4 d-flex justify-content-center p-1">
        <span>{props.title}</span>
        </div>
    </div>
  )
}

export default ProductCardDesktop