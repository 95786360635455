import styled from "styled-components";

export const TagsWrapper = styled.div`
.headerContainer div, .headerContainer h1 {
  margin-bottom: 10px;
}
.searchContainer{
  display: flex;
  padding: 0 15px 0 0;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 10px;
  height: 35px;
}
.search{ 
  height: 33px;
  border: none;
  border-radius: 10px;

}
.searchContainer img {
  cursor: pointer;
  width: 20px;
}
.btn{
  align-self: center;
  background-color: #5D78FF;
  border: none;
  height: 35px;
  border-radius: 5px;
  padding: 0 20px;
  color: white;
  font-weight: bold;
}
.clipboard{
  width:200px !important;
}
.copyText{
  border: 1px solid black;
  padding:2px 5px;
  border-radius:5px;
  display: inline-block;
  width: 120px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
td a{
  color: #5D78FF;
    text-decoration: none;
    font-weight: bold;
}
td img{
  margin-left:3px;
  cursor:pointer;
}
.w80{
  width:80px;
}
.w130{
  width:130px;
}
  .message-text {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width:150px
  }
  .dropbtn{
    cursor:pointer;
  }
  .dropped{
    width:100%;
    text-align:unset;
    margin-left:10px;
  }
  .open{
    transform: rotate(-90deg);
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  .close{
    transform: rotate(90deg);
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  .auctionTable th.longText,
  tr.table-row td.longText {
    width: 30%;
  }
`;
