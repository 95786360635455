import { useEffect } from "react";
import "./App.css";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchToken } from "./firebase/firebase"; 
function App() {

  let authToken = localStorage.getItem("token")
  const permissions = localStorage.getItem('permissions')
  const user = localStorage.getItem('user') 

  useEffect(()=>{
     if(authToken && user && permissions)
       fetchToken()  
  },[])
  
  return (
    <div className="App">
      {authToken && user && permissions ? <PrivateRoute /> : <PublicRoute />}
      <ToastContainer />
    </div>
  );
}

export default App;
