import React, { useEffect, useState } from 'react'
import SearchableObjDropdown from '../SearchableDropdown/SearchableObjDropdown'
import { APP_URLS } from '../../../api/url';
import API from '../../../api/axios';
import { toast } from 'react-toastify';

function ModelFilter({_filters, filters, brandID,modelID,setChangeValue , productValue, className}) {
    const [models, _models] = useState([])

    useEffect(() => {
        setChangeValue('')
        getModelCarId()
    }, [brandID,productValue])

    const getModelCarId = () => {
        API.get(APP_URLS.MODEL_CAR_ITEM_ID + `?filter[brand_id]=${brandID}&limited_data=true${productValue ? '&filter[product_type_id]='+productValue : ''}`)
            .then((res) => {
                const resp = res.data;
                if (resp.success === true) {
                    let data = {}
                    resp.data.items.forEach((element)=>{
                        data[`${element.id}_${element.model_name}`] = element.model_name
                    })
                    _models(data);
                } else {
                    toast.error(resp.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch(function (error) {
                const resp = error.response;
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                    Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
                } else if (resp.data.data?.error !== undefined) {
                    error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                    error_message = resp.data.error;
                } else {
                    error_message = resp.data.message;
                }
                toast.error(error_message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });
    };
 
  return <SearchableObjDropdown  _filters={_filters} filters={filters} name="model" disabled ={brandID?false:true} className={className || 'filterBox brand'}  setChangeValue={(val)=>setChangeValue(val)} val={modelID} placeholder="Select Models" dropdownData={models} />

}

export default ModelFilter
