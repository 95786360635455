export const data = [
  {
    id: 1,
    label: "Version Name",
    code: "version_name",
    type: "text",
  },
  {
    id: 2,
    label: "Year",
    code: "year",
    type: "number",
  },
  {
    id: 5,
    label: "Start Version",
    code: "start_version",
    type: "date",
  },
  {
    id: 6,
    label: "End Version",
    code: "end_version",
    type: "date",
  },
  {
    id: 7,
    label: "Number of Seats",
    code: "number_of_seats",
    type: "number",
  },
  {
    id: 8,
    label: "Number of Doors",
    code: "number_of_doors",
    type: "number",
  },
  {
    id: 9,
    label: "Motor",
    code: "motor",
    type: "text",
  },
  {
    id: 10,
    label: "Cylinder CC",
    code: "cylinder_cc",
    type: "number",
  },
  {
    id: 11,
    label: "Number of Speeds",
    code: "number_of_speeds",
    type: "number",
  },
  {
    id: 12,
    label: "Number of Cylinder",
    code: "number_of_cylinder",
    type: "number",
  },
  {
    id: 13,
    label: "Power Cv",
    code: "power_cv",
    type: "number",
  },
  {
    id: 14,
    label: "Power Kw",
    code: "power_kw",
    type: "number",
  },
  {
    id: 15,
    label: "Power Cv Fiscal",
    code: "power_cv_fiscal",
    type: "number",
  },
  {
    id: 16,
    label: "Couple Nm",
    code: "couple_nm",
    type: "number",
  },
  {
    id: 17,
    label: "Top Speed Km/h",
    code: "top_speed_km_h",
    type: "number",
  },
  {
    id: 18,
    label: "0 To 100 (S)",
    code: "0_100",
    type: "number",
  },
  {
    id: 20,
    label: "CO2 Emission",
    code: "co2_emission",
    type: "number",
  },
  {
    id: 21,
    label: "Fuel Combined",
    code: "fuel_combined",
    type: "number",
  },
  {
    id: 22,
    label: "Fuel Urban Liter",
    code: "fuel_urban_liter",
    type: "number",
  },
  {
    id: 23,
    label: "Fuel Extra Urban Liter",
    code: "fuel_extra_urban_liter",
    type: "number",
  },
  {
    id: 24,
    label: "Ecological Bonus",
    code: "ecological_bonus",
    type: "number",
  },
  {
    id: 28,
    label: "Power Kw Charging",
    code: "power_kw_charging",
    type: "number",
  },
  {
    id: 29,
    label: "Power Kw Charging Max",
    code: "power_kw_charging_max",
    type: "number",
  },
  {
    id: 30,
    label: "Charge Duration",
    code: "charge_duration",
    type: "number",
  },
  {
    id: 31,
    label: "Battery Duration Km City",
    code: "battery_duration_km_city",
    type: "number",
  },
  {
    id: 32,
    label: "Battery Duration Km Highway",
    code: "battery_duration_km_highway",
    type: "number",
  },
  {
    id: 34,
    label: "Engine Power 1",
    code: "engine_power_1",
    type: "number",
  },
  {
    id: 35,
    label: "Engine Power 2",
    code: "engine_power_2",
    type: "number",
  },
  {
    id: 36,
    label: "Engine Power 3",
    code: "engine_power_3",
    type: "number",
  },
  {
    id: 37,
    label: "Total Engine Power",
    code: "total_engine_power",
    type: "number",
  },
  {
    id: 38,
    label: "Number of Motor",
    code: "number_of_motor",
    type: "number",
  },
  {
    id: 39,
    label: "Angle of Attack",
    code: "angle_of_attack",
    type: "number",
  },
  {
    id: 40,
    label: "Departure Angle",
    code: "departure_angle",
    type: "number",
  },
  {
    id: 41,
    label: "Payload Kg",
    code: "payload_kg",
    type: "number",
  },
  {
    id: 42,
    label: "Weight Empty Kg",
    code: "weight_empty_kg",
    type: "number",
  },
  {
    id: 43,
    label: "Weight Gross Kg",
    code: "weight_gross_kg",
    type: "number",
  },
  {
    id: 44,
    label: "Ground Clearance mm",
    code: "ground_clearance_cm",
    type: "number",
  },
  {
    id: 45,
    label: "Wheelbase mm",
    code: "wheelbase_cm",
    type: "number",
  },
  {
    id: 46,
    label: "Height mm",
    code: "height_cm",
    type: "number",
  },
  {
    id: 47,
    label: "Width Number mm",
    code: "width_number_cm",
    type: "number",
  },
  {
    id: 48,
    label: "Length Number mm",
    code: "length_number_cm",
    type: "number",
  },
  {
    id: 49,
    label: "Tank Volume Liter",
    code: "tank_volume_liter",
    type: "number",
  },
  {
    id: 50,
    label: "Trunk Volume Max Liter",
    code: "trunk_volume_max_liter",
    type: "number",
  },
  {
    id: 51,
    label: "Trunk Volume Min Liter",
    code: "trunk_volume_min_liter",
    type: "number",
  },
  {
    id: 52,
    label: "Maintenance Interval Km",
    code: "maintenance_interval_km",
    type: "number",
  },
  {
    id: 53,
    label: "Maintenance Interval Max Month",
    code: "maintenance_interval_max_month",
    type: "number",
  },
  {
    id: 54,
    label: "Catalog Price",
    code: "catalog_price",
    type: "number",
  },
  {
    id: 55,
    label: "Battery Capacity Kwh",
    code: "battery_capacity_kwh",
    type: "number",
  },
  {
    id: 56,
    label: "Charge Duration Speed Plug H",
    code: "charge_duration_speed_plug_h",
    type: "number",
  },
  {
    id: 57,
    label: "Type of Plug",
    code: "type_of_plug",
    type: "number",
  },
];
