import React from "react";
import moment from "moment";
import faAccept from "../../../Images/icons/svgIcon/faAccept.svg";
import profilePic from "../../..//Images/icons/profile-pic.png";
const Invitation = (props) => {
  return (
    <div>
      <div className="item-all mx-3 my-4">
        {props.allNotification.map((item, index) => {
          return (
            <div className="notification-outer position-relative" key={index}>
              <div className="notification-alert" onClick={() => (item.read_at === null ? props.handleSingleRead(item.id) : "")}>
                <span className="d-inline-block notification-time">
                  <span className="me-2">{moment(item.created_at).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm")} </span>
                </span>
                <div className={item.read_at !== null ? "readNotification d-flex mt-4" : "d-flex mt-4"}>
                  <img src={item.image ? item.image : profilePic} alt="user" width="45" height="45" className="align-self-start profile" />
                  <div className="notification-content px-2">
                    <div className="notification-content-text">
                      <p className="mb-0 fw-bold">{item?.datas?.user_name}</p>
                      <p className="mb-0">{item.body}</p>
                    </div>
                    <div className="notification-action pt-2">
                      <div className="d-flex justify-content-end">
                        <span className={item?.status === "refused" ? "text-danger" : item?.status === "accepted" ? "text-success" : ""}>{item?.status ? (item.status.charAt(0).toUpperCase()+item.status.slice(1)) :""}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button className="deletebtn" onClick={() => props.handleDelete(item.id)}>
                Delete
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Invitation;
