import React from 'react'

const AppDownloadCard = (props) => {
  return (
    <div className='product-card'>
        <img className="download-button" src={props.qr} alt="" onClick={()=>window.open(props.url)}/>
        <span className='download-title my-2'> {props.title}</span>
        <img className="download-button" src={props.image} alt="" onClick={()=>window.open(props.url)}/>
    </div>
  )
}

export default AppDownloadCard