import React, { useEffect } from "react";
import MainLayout from "../../../assets/Layout/MainLayout";
import { EditVehicleWrapper } from "../style/EditVehicle";
import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";  
import Toggle from "../components/Toggle";


const EditCountry = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  
  const [fields,_fields] = useState(JSON.parse(localStorage.getItem("languages")))
  const [countries,_countries] = useState(JSON.parse(localStorage.getItem("countries")))

  const [name,_name] = useState({})
  const [vat,_vat] = useState('')
  const [code,_code] = useState('')
  const [continent,_continent] = useState('')
  const [prefix,_prefix] = useState('')
  const [currency,_currency] = useState('')

  const [error,_error] = useState('')

  const [loading,_loading] = useState(false)

  const { countryId } = useParams() 
  const [isBacklink , _isBacklink] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    getCountry()
  }, [])
  

  const getCountry =() =>{
    API.get(APP_URLS.SHOW_COUNTRY + `/${countryId}`)
    .then(res => {
      const resp = res.data;
      if(resp.success===true){
        setValues(resp.data.item)
        _isBacklink(resp.data.item.back_link)
      }
      else{
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })
    .catch(function (error) {  
      const resp = error.response;
      let error_message = '';
      if (resp.data.data !== undefined && resp.data.data !== null) {
        {
          Object.keys(resp.data.data).map(
            (error, index) =>(error_message = resp.data.data[error][0])
          );
        }
      } else if (resp.data.data?.error !== undefined) {
          error_message =  resp.data.data.error;
      } else if(resp.data?.error!== undefined){
          error_message =  resp.data.error
      } else{
          error_message =  resp.data.message
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT
      })
    });
  }

  const setValues = (data) => {
    Object.keys(data.fullname).map((item)=>{
      _name((old)=> {return {...old,[item]:data.fullname[item]}})
    })
    _vat(data.vat_rate)
    _prefix(data.prefix_call)
    _code(data.country_name)
    _currency(data.currency)
    _continent(data.continent.name.en)
  }

  const handleTitle = (val,code) =>{
    _name((old)=> {return {...old,[code]:val}})
  }


  const submitForm = () =>{
    let flag = false
    if((name["en"]===""||name["en"]===undefined||name["en"]===null) && (name["fr"]===""||name["fr"]===undefined||name["fr"]===null)){
      flag = false
      toast.error("Country Name in English and French is mandatory", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else if(name["en"]===""||name["en"]===undefined||name["en"]===null){
      flag = false
      toast.error("Please provide Country Name in English", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else if(name["fr"]===""||name["fr"]===undefined||name["fr"]===null){
      flag = false
      toast.error("Please provide Country Name in French", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else{
      flag = true
    }
    if(flag===true){
      _loading(true)
      let data ={}
      data["fullname"] = name;
      data["back_link"] =  Number(isBacklink)
      if(vat!==''&&vat!==undefined&&vat!==null){
        data["vat_rate"] = vat;
      }
      API.post(APP_URLS.UPDATE_COUNTRY+ `/${countryId}?_method=patch`,data)
      .then(res => {
        _loading(false)
        const resp = res.data;
        if(resp.success===true){
          toast.success("Country Updated Successfully.", {
            position: toast.POSITION.TOP_RIGHT
          })
          navigate("/translation/countries?"+
          (searchParams.get("sort") !== null
          ? "sort=" + searchParams.get("sort") + "&"
          : "") +
          (searchParams.get("name") !== null
          ? "name=" + searchParams.get("name") + "&"
          : "") +
          (searchParams.get("continent") !== null
          ? "continent=" + searchParams.get("continent") + "&"
          : "") +
          (searchParams.get("per_page") !== null 
            ? "per_page=" + searchParams.get("per_page") + "&"
            : "") +
          (searchParams.get("page") !== null
            ? "page=" + searchParams.get("page")
            : "")
        )
        }
        else{
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT
          })
        }
      })
      .catch(function (error) {  
        _loading(false)
        const resp = error.response;
        let error_message = '';
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map(
              (error, index) =>(error_message = resp.data.data[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
            error_message =  resp.data.data.error;
        } else if(resp.data?.error!== undefined){
            error_message =  resp.data.error
        } else{
            error_message =  resp.data.message
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT
        })
      });
    }
  }

  const validate = (val) =>{
    _error('')
    if(Number(val)>99.99){
      _error("VAT rate can't be greater than 99.99")
      _vat(val)
    } else if(Number(val)<0){
      _error("VAT rate can't be smaller than 0")
      _vat(val)
    }
    else{
      let newVal = (val.indexOf(".") >= 0) ? (val.substr(0, val.indexOf(".")) + val.substr(val.indexOf("."), 3)) : val;
      _vat(newVal)
    }
  } 
  return (
    <>
      <EditVehicleWrapper>
        <div className='mt-2' >
          <div className="backContainer ms-4" onClick={()=>navigate("/translation/countries?"+                  
              (searchParams.get("sort") !== null
              ? "sort=" + searchParams.get("sort") + "&"
              : "") +
              (searchParams.get("name") !== null
              ? "name=" + searchParams.get("name") + "&"
              : "") +
              (searchParams.get("continent") !== null
              ? "continent=" + searchParams.get("continent") + "&"
              : "") +
              (searchParams.get("per_page") !== null 
                ? "per_page=" + searchParams.get("per_page") + "&"
                : "") +
              (searchParams.get("page") !== null
                ? "page=" + searchParams.get("page")
                : "")
            )}>
            <span className='backButton'>x</span>
            <span className='px-3 backButton'>|</span>
            <span className='backButton'>Cancel</span>
          </div>
        </div>
        <div className="headerContainer">
          <h1>EDIT COUNTRY</h1>
        </div>
        <div className="d-flex justify-content-between save-btn mt-3">
          <p className="fieldLabel">Title</p>
          <button className={(error!==''||loading?"disabled":"")+" add-button"} type="submit" onClick={submitForm} disabled={error!==''||loading?true:false}>Save</button>
        </div>
        <div className="row input-fileds">
          {fields.map((item,index)=>{
            let itemCode = item.code
            let flag = countries.find(o => o.id === item.country_id)?.flag_thumbnail
            return <div className="col-md-4" key={index}>
              <div className="col-md-12 mt-3">
                <label htmlFor="title" className="form-label">
                  <span className="countryIcon px-2">
                    <img src={flag} alt="" className="countryflag" title={item.name}/>
                  </span>
                  <span className="langName">{item.code}</span>
                </label>
                <input
                  type="text"
                  id={item.code}
                  name={item.code}
                  value={name[itemCode]}
                  className="form-control"
                  onChange={(e,code = itemCode)=>{
                    handleTitle(e.target.value,code)
                  }}
                />
              </div>
          </div>
          })}
        </div>
        <div className="row mt-5 input-fileds">
          <div className="col-md-4">
              <label htmlFor="vatrate" className="form-label product-type fieldLabel">
              VAT Rate {" "}
              <span className="percentage">(%)</span>
              </label>
              <input type='number' placeholder="00.00" className="form-control mt-2" value={vat} onChange={(e)=>validate(e.target.value)}/>
              {error!==''?<div className="error">{error}</div>:null}
          </div>
          <div className="col-md-4">
              <label htmlFor="continent" className="form-label product-type fieldLabel">
              Continent
              </label>
              <input type="text" placeholder="Continent" className="form-control mt-2 disabled" value={continent} disabled onChange={(e)=>_continent(e.target.value)}/>
          </div>
          <div className="col-md-4">
            <label htmlFor="countryCode" className="form-label product-type fieldLabel">
              Country Code
              </label>
              <input type="text" placeholder="Country Code" className="form-control mt-2 disabled" value={code} disabled onChange={(e)=>_code(e.target.value)}/>
          </div>
        </div>
        <div className="row mt-5 input-fileds">
          <div className="col-md-4">
              <label htmlFor="currency" className="form-label product-type fieldLabel">
              Currency
              </label>
              <input type="text" placeholder="Currency" className="form-control mt-2 disabled" value={currency} disabled onChange={(e)=>_currency(e.target.value)}/>
          </div>
          <div className="col-md-4">
              <label htmlFor="prefixCall" className="form-label product-type fieldLabel">
              Prefix Call
              </label>
              <input type="text" placeholder="Prefix Call" className="form-control mt-2 disabled" value={prefix} disabled onChange={(e)=>_prefix(e.target.value)}/>
          </div> 
           <Toggle  label={'Is Backlink?'} checked={isBacklink} handleChecked={(e) => _isBacklink(e)} />   
        </div>
      </EditVehicleWrapper>
    </>
  );
};

export default EditCountry;
