import React, { useEffect, useState } from 'react'
import loginImage from "../../assets/Images/image/login.png";
import logo from "../../assets/Images/icons/c2blogo.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoginWrapper } from "./style/login";
import { APP_URLS } from '../../api/url';
import API from '../../api/axios';
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const ResetPassword = (props) => {

  const passwordRegExp = /^((?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{8,})$/;
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate()

  const { token ,details } = useParams()

  const search = useLocation().search;
  const newUser = new URLSearchParams(search).get('newUser');


  const formik = useFormik({
    initialValues: {
        password: '',
        password_confirmation: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required('Password is Required').matches(passwordRegExp, 'Password must be consist of one uppercase, one lowercase, one number and minimum length at least 8 characters.'),
      password_confirmation: Yup.string().required('Confirm Password is Required').oneOf([Yup.ref('password'), null], 'Passwords must match'),
    }),
    onSubmit: values => {
      setLoading(true)
      let data = {}
      data['email']= details
      data['password']= values.password
      data['password_confirmation']= values.password_confirmation
      data['token']= token
      API.post(APP_URLS.RESET_PASSWORD, data)
      .then(res => {
        const resp = res.data;
        setLoading(false)
        if(resp.success===true){
          toast.success("Password has been updated successfuly. Please login to continue.", {
            position: toast.POSITION.TOP_RIGHT
          })
          formik.resetForm()
          navigate('/login')
        }
        else{
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT
          })
        }
      })
      .catch(function (error) {  
          const resp = error.response;
          setLoading(false)
          let error_message = '';
          if (resp.data.data?.password !== undefined) {
              {Object.keys(resp.data.data.password).map((error, index) => (
                  error_message = resp.data.data.password[error]
              ))}
          } else if (resp.data.data?.error !== undefined) {
              error_message =  resp.data.data.error;
          } else if(resp.data?.error!== undefined){
              error_message =  resp.data.error
          } else{
              error_message =  resp.data.message
          }
          toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT
          })
      }); 
    },
  });

  return (
    <>
      <LoginWrapper>
        <div className='container-fluid login-admin'>
          <div className='header'>
            <div className='headContainer'>
              <img src={logo} alt="" onClick={()=>navigate('/login')}/>
            </div>
          </div>

          <div className="formContainer container-full mt-sm-5">
            
            <div className='col-md-8 offset-md-2'>
              <div className='row'>
                <div className="col-sm-12 col-md-6 offset-md-6 col-lg-4 offset-lg-8">
            </div>
              </div>
            
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-7">
                  <div className="login-img">
                    <img src={loginImage} alt="" />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-5">
                  <div className='formLogin'>
                  <div className='form-title block sm-block md-none'>
                    {newUser==='true'?
                    'SET PASSWORD':
                    'RESET PASSWORD'}
                    </div>
                    <form onSubmit={(e)=> formik.handleSubmit(e)}>
                        <div className="inputField">
                          <input
                            type="password"
                            placeholder="Password"
                            name="password"
                            value={formik.values.password}
                            onBlur={formik.handleBlur}
                            onChange={(e)=>{
                              formik.handleChange(e)
                            }}
                          />
                          <span className='required'>
                            {formik.touched.password && formik.errors.password}
                          </span>
                        </div>
                        <div className="">
                          <input
                            type="password"
                            placeholder="Confirm Password"
                            name="password_confirmation"
                            value={formik.values.password_confirmation}
                            onBlur={formik.handleBlur}
                            onChange={(e)=>{
                              formik.handleChange(e)
                            }}
                          />
                           <span className='required'>
                            {formik.touched.password_confirmation && formik.errors.password_confirmation}
                          </span>
                        </div>
                        <button className="submitBtn actionBtn" type='submit' disabled={loading}>
                          {newUser==='true'?(loading?'Setting...':'Set Password'):loading?"Reseting...":"Reset Password"}
                          </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer>
            <p>All rights reserved - CarToBike.com 2022</p>
          </footer>
        </div>
      </LoginWrapper>
    </>
  );
};

export default ResetPassword