import styled from "styled-components";

export const AddSlideWrapper = styled.div`
  .form-control[readonly] {
    background-color: white;
    opacity: 1;
  }
.form-control:disabled{
    background-color: #e9ecef !important;
    opacity: 1;
  }
  .filed-border input{
    border: 1px solid rgba(0,0,0,0.2)
  }
  .auction-date-start-filed{
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 4px;
  }
  .date-time-picker tfoot{
    position: absolute;
    top: 0;
    right: 85px;
  }
  .save-btn {
    border-radius: 5px;
    font-weight: bold;
    border: none !important;
    color:#607afc;
    background:white !important;
  }
  .cancel-btn {
    background-color: white !important;
    color :#a2a2a2;
    border: 1px solid #a2a2a2;
  }
  .slide-outer {
    height: 215px;
    width: 100%;
  }
  .image{
    border-radius: 5px 5px 0 0;
    max-width:100% !important; 
    width:320px;
    height:auto !important;
  }
  .slide-image{
    width:320px;
    text-align: center;
    -webkit-box-align: center;
    align-items: center;
    border: 1px solid rgb(151, 151, 151);
    border-radius: 6px;
    position: relative;
  }
  .ImagePreviewContent span {
    display: block;
    line-height: 20px;
  }
  .ImagePreviewContent {
      border-top:1px solid #c3c3c3;;
      padding: 2px 5px;
  }
  .resetValue{
    align-self: flex-end;
    cursor: pointer;
    position: absolute;
    right: -5px;
    top: -5px;
  }
  .header{
    font-size:16px
  }
  .content{
    font-size:15px
  }
  .countryheader{
    border-bottom: 1px solid #868686;
  }
  .countriesList{
    max-height:300px;
    overflow-y:auto
  }
  .btn-border{
    line-height: 1px;
    border:1px solid #86868694
  }
  .countries-box{
    height :auto;
    max-height:150px;
    overflow-y:auto;
  }
  .suggestion{
    color: #a7a6a6;
    font-size: 14px;
    font-style: italic;
  }
  @media (max-width: 1248px) {
    .fileds-input{
      display: block !important;
    }
    .countries{
      width: 100%;
    }
  }
  /* 1249 */
`;
