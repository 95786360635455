import React, { useEffect, useState } from 'react'
import MainLayout from '../../../assets/Layout/MainLayout';
import { CreateCensuredWrapper } from './Style/createCensuredWrapper';
import defaultprofile from "../../../assets/Images/icons/profile-pic.png"
import API from '../../../api/axios';
import { APP_URLS } from '../../../api/url';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { suggestionText, USER_TYPE_TEXT } from '../../../config/constant';
import { generateQueryString } from '../../../core/utils';

let modules_disabled = []

const officeType = {
  frontOffice: "2",
  backOffice: "3",
  forum: "4"
}
const admin_disable_modules_values = {
  adminFrontOffice: 0,
  ads: 1,
  bids: 2,
  adminBackOffice: 4,
  forum: 5,
}
const FOREVER = 6
const STATUS = 1
const CreateCensured = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const { userId } = useParams();
  const navigate = useNavigate();
  const [duration, _duration] = useState('')
  const [commentsArr, _commentsArr] = useState([])
  const [loading, _loading] = useState(false)
  const [keyword, _keyword] = useState("")
  const [suggestionTexts, _suggestionTexts] = useState({});
  const [suggestionLoaded, _suggestionLoaded] = useState(false);
  const [name, _name] = useState('')
  const [status, _status] = useState('')
  const [profilePic, _profilePic] = useState('')
  const [country, _country] = useState('')
  const [access, _access] = useState(false);
  const [ads, _ads] = useState(false);
  const [bids, _bids] = useState(false);
  const [deleted, _deleted] = useState('');
  const [showForever, _showForever] = useState('')
  const [appVal, setAppVal] = useState(() => {
    const tab = query.get('tab');
    return officeType[tab] || officeType.frontOffice;
  });

  useEffect(() => {
    getUserDetails()
    modules_disabled = []
  }, [])

  useEffect(() => {
    if (keyword.length > 1) {
      getSuggestiontext()
    }
  }, [keyword])

  //Fetching details of suggestion texts based on their type
  const getSuggestiontext = () => {
    const suggestionType = query.get('tab') === USER_TYPE_TEXT.FORUM ? suggestionText.FORUM_CENSORSHIP : suggestionText.USER_CENSORSHIP
    API.get(APP_URLS.LIST_SUGGESTION_TEXT + `?filter[name]=${keyword}&limited_data=true&filter[type]=${suggestionType}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          let data = {}
          resp.data.items.forEach((element) => {
            data[`${element.id}_${element.name}`] = element.name
          })
          _suggestionTexts(data);
          _suggestionLoaded(true)
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  //Fetching details of user based on their id
  const getUserDetails = () => {
    API.get(APP_URLS.LIST_USERS + `/${userId}`)
      .then(res => {
        const resp = res.data;
        if (resp.success === true) {
          _status(resp.data.user.active)
          if (resp.data.user.first_name || resp.data.user.last_name) {
            _name(`${resp.data.user.first_name !== null ? resp.data.user.first_name.charAt(0).toUpperCase() + resp.data.user.first_name.slice(1) : ""} ${resp.data.user.last_name !== null ? resp.data.user.last_name.charAt(0).toUpperCase() + resp.data.user.last_name.slice(1) : ""}`)
          }
          else {
            _name(resp.data.user.pseudo !== null ? resp.data.user.pseudo.charAt(0).toUpperCase() + resp.data.user.pseudo.slice(1) : "-")
          }
          _deleted(resp.data.user.deleted_at || '')
          _profilePic(resp.data.user.profile_picture_url !== null && resp.data.user.profile_picture_url !== '' ? resp.data.user.profile_picture_url : '')
          _country(resp.data.user.limited_address)
        }
        else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT
          })
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = '';
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map(
              (error, index) => (error_message = resp.data.errors[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error
        } else {
          error_message = resp.data.message
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT
        })
      });
  }

  //pushing comments to comments array (suggestion texts)
  const addcomments = (id) => {
    let flag = false
    for (let i = 0; i < commentsArr.length; i++) {
      if (commentsArr[i].id === id) {
        flag = true
        break;
      }
    }
    if (flag === false) {
      _commentsArr((item) => {
        return [...item, {
          'id': id,
          'comment': suggestionTexts[id]
        }]
      })
    } else {
      toast.error("Comment has been already added", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

  }

  //removing comments from comments array
  const removeComment = (id) => {
    _commentsArr((comment) => {
      return comment.filter((arrItem, index) => {
        return index !== id
      })
    })
  }

  //manage disable checkboxes for censorship based on disable values
  const handleCheck = (val) => {
    let search = modules_disabled.findIndex(x => x === val)
    if (val === admin_disable_modules_values.ads) {
      if (ads === false && search === -1) {
        modules_disabled.push(val)
        _showForever(admin_disable_modules_values.ads)
      }
      else {
        modules_disabled.splice(search, 1)
        _showForever('')
      }
    }
    else if (val === admin_disable_modules_values.bids) {
      if (bids === false && search === -1) {
        modules_disabled.push(val)
        _showForever(admin_disable_modules_values.bids)
      }
      else {
        modules_disabled.splice(search, 1)
        _showForever('')
      }
    }
    else if (val === admin_disable_modules_values.adminFrontOffice) {
      if (access === false && search === -1) {
        _ads(false)
        _bids(false)
        modules_disabled.length = 0;
        modules_disabled.push(val)
        _showForever(admin_disable_modules_values.adminFrontOffice)
      }
      else {
        modules_disabled.splice(search, 1)
        _showForever('')
        _duration('')
      }
    } else if (val === admin_disable_modules_values.adminBackOffice) {
      if (access === false && search === -1) {
        modules_disabled.length = 0;
        modules_disabled.push(val)
        _showForever(admin_disable_modules_values.adminBackOffice)
      }
      else {
        modules_disabled.splice(search, 1)
        _showForever('')
        _duration('')
      }
    } else if (val === admin_disable_modules_values.forum) {
      if (access === false && search === -1) {
        modules_disabled.length = 0;
        modules_disabled.push(val)
        _showForever(admin_disable_modules_values.forum)
      }
      else {
        modules_disabled.splice(search, 1)
        _showForever('')
        _duration('')
      }
    }
  }

  //submit form action
  const save = () => {
    if (modules_disabled.length < 1) {
      toast.error("Please select modules for disabling access", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else if (commentsArr.length < 1) {
      toast.error("Please add some comments", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else if (duration === '' || duration === undefined) {
      toast.error("Please select duration for disabling access")
    }
    else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='react-confirm-alert-body'>
              <h1>Confirm Action</h1>
              <p>Are you sure you want to censor this user? </p>
              <br />
              {appVal == officeType.backOffice && duration == FOREVER ? <p className='warningText'>All existing promo codes for this user will become invalid.</p> : ""}
              <div className='react-confirm-alert-button-group'>
                <button
                  onClick={() => {
                    const newArr = commentsArr.map(comment => parseInt(comment.id));
                    let data = {}
                    data['app_type'] = appVal
                    data['modules_disabled'] = modules_disabled
                    data['duration'] = duration
                    data['reason_ids'] = newArr
                    data['user_id'] = userId
                    _loading(true)
                    API.post(APP_URLS.CENSOR_USER, data)
                      .then(res => {
                        const resp = res.data;
                        _loading(false)
                        if (resp.success === true) {
                          toast.success("Censorship has been created successfully.", {
                            position: toast.POSITION.TOP_RIGHT
                          })
                          navigateToDetailMember(userId);
                        }
                        else {
                          toast.error(resp.message, {
                            position: toast.POSITION.TOP_RIGHT
                          })
                        }
                      })
                      .catch(function (error) {
                        const resp = error.response;
                        _loading(false)
                        let error_message = '';
                        if (resp.data.data !== undefined && resp.data.data !== null) {
                          {
                            Object.keys(resp.data.data).map(
                              (error, index) => (error_message = resp.data.data[error][0])
                            );
                          }
                        } else if (resp.data.data?.error !== undefined) {
                          error_message = resp.data.data.error;
                        } else if (resp.data?.error !== undefined) {
                          error_message = resp.data.error
                        } else {
                          error_message = resp.data.message
                        }
                        toast.error(error_message, {
                          position: toast.POSITION.TOP_RIGHT
                        })
                      });
                    onClose();
                  }}
                >
                  Yes
                </button>
                <button onClick={onClose}>No</button>
              </div>
            </div>
          );
        }
      });
    }
  }

  const changeTarget = () => {
    if (commentsArr.length <= 0) {
      document.getElementById("commentInput").focus()
    }
  }

  //disable user based on Type (officeType) for censorship
  const getDisableValue = () => {
    if (appVal == officeType.frontOffice) return admin_disable_modules_values.adminFrontOffice;
    else if (appVal == officeType.backOffice) return admin_disable_modules_values.adminBackOffice;
    else if (appVal == officeType.forum) return admin_disable_modules_values.forum;
  }

  //creating params object
  const paramsObj = {
    tab: query.get("tab"),
    censorUser: "true",
    status: query.get("status"),
    profile_verification_status: query.get("profile_verification_status"),
    role_id: query.get("role_id"),
    keyword: query.get("keyword"),
    profile: query.get("profile"),
    accountDate: query.get("accountDate"),
    accountDateFrom: query.get("accountDateFrom"),
    accountDateTo: query.get("accountDateTo"),
    trashed: query.get("trashed"),
    sort: query.get("sort"),
    per_page: query.get("per_page"),
    page: query.get("page")
  };

  //Manage parameters and url for detail member.
  function getDetailMemberUrl() {
    const queryString = generateQueryString(paramsObj);
    return `/detail-member/${userId}?${queryString}`;
  };

  //navigating to detail member page
  function navigateToDetailMember() {
    const url = getDetailMemberUrl();
    navigate(url);
  };

  return (
    <CreateCensuredWrapper>
      <div className="auctionMainContainer">
        <div className="mt-2">
          <div className="backContainer ms-4" onClick={() => navigateToDetailMember()}>
            <span className="backButton">x</span>
            <span className="px-3 backButton">|</span>
            <span className="backButton">Cancel</span>
          </div>
        </div>

        <div className="row mt-3 px-2">
          <div className="col-md-4 offset-md-4">
            <h1> CENSOR USER</h1>
          </div>
        </div>

        <div className="row mb-2 mt-3 px-2">
          <div className="col-md-6 d-flex mb-3">
            <div className="details">
              <div className="profile-flag">
                <div className="profileImage">
                  <img src={profilePic ? profilePic : defaultprofile} />
                </div>
                {country ? <img className="flagIcon" src={country.flag_thumbnail} title={country.country} /> : ""}
              </div>
              <div className="detailsContent">
                <span> {name}</span>
                {status === STATUS ? <span className="active-status"> Active</span> : <span className="end-status"> Inactive</span>}
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-end  mb-3">
            <button className={(loading ? "disabled" : "") + " btn"} onClick={save} disabled={loading}>
              SEND
            </button>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="input-section">
                <div className="row formInput">
                  <div className="col-md-6 form-group">
                    <label htmlFor="app" className="form-label">
                      <b>App</b>
                    </label>
                    <select
                      name="app"
                      id="app"
                      className="form-control"
                      value={appVal}
                      onChange={(e) => {
                        modules_disabled.length = 0;
                        _bids(false)
                        _ads(false)
                        _access(false);
                        _duration('')
                        _showForever('')
                        setAppVal(e.target.value);
                      }}
                    >
                      <option value="2">Front Office</option>
                      {deleted == '' ? <option value="3">Nomad</option> : ''}
                      <option value="4">Forum</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="formInput">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-6">
                            <label htmlFor="" className="label">
                              <b className="labelVal">Disable</b>
                            </label>
                            {(appVal === officeType.frontOffice && deleted == '') ? (
                              <>
                                <div className="checkboxOptions">
                                  <div className="options">
                                    <input
                                      type="checkbox"
                                      id="ads"
                                      name="ads"
                                      checked={ads}
                                      disabled={access}
                                      onChange={() => {
                                        _ads(!ads);
                                        handleCheck(admin_disable_modules_values.ads);
                                      }}
                                    />
                                    <label htmlFor="ads"> Ads</label>
                                  </div>
                                </div>
                                <div className="checkboxOptions">
                                  <div className="options">
                                    <input
                                      type="checkbox"
                                      id="bids"
                                      name="bids"
                                      checked={bids}
                                      disabled={access}
                                      onChange={() => {
                                        _bids(!bids);
                                        handleCheck(admin_disable_modules_values.bids);
                                      }}
                                    />
                                    <label htmlFor="bids"> Bids</label>
                                  </div>
                                </div>
                                <div className="checkboxOptions">
                                  <div className="options">
                                    <input
                                      type="checkbox"
                                      id="access"
                                      name="access"
                                      checked={access}
                                      onChange={() => {
                                        _access(!access);
                                        handleCheck(0);
                                      }}
                                    />
                                    <label htmlFor="access">
                                      {" "}
                                      Access<span className="info d-block">Disable for all app!</span>
                                    </label>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <div className="checkboxOptions">
                                <div className="options">
                                  <input
                                    type="checkbox"
                                    id="access"
                                    name="access"
                                    checked={access}
                                    onChange={() => {
                                      handleCheck(getDisableValue());
                                      _access(!access);
                                    }}
                                  />
                                  <label htmlFor="vehicle1"> Disable Access</label>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {appVal == officeType.backOffice ? (
                        <div className="mb-2 mt-4">
                          <span className="info">Deactivate, implicitly means to remove the access so softdelete RUS but we still have to display these sanctions even the one of RUS delete, in case it asks for an access again in the future, we have to know if it has already been member and these sanctions from the past</span>
                        </div>
                      )
                        : ((appVal == officeType.forum) && access) ?
                          <div className="mb-2 mt-4">
                            <span className="info">Access will be disabled for the forum.</span>
                          </div>
                          : (
                            ""
                          )}
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-6 mb-5">
                    <label htmlFor="" className="label d-block">
                      <b>Duration</b>
                    </label>
                    <select
                      name="disableBids"
                      id="disableBids"
                      className="disableBids"
                      onChange={(e) => {
                        _duration(e.target.value);
                      }}
                      value={duration}
                    >
                      <option hidden>None</option>
                      <option value="4">1 Day</option>
                      <option value="5">3 Days</option>
                      <option value="0">1 Week</option>
                      <option value="1">2 Weeks</option>
                      <option value="2">1 Month</option>
                      <option value="3">3 Months</option>
                      {showForever === 0 || showForever === 4 ?
                        <option value="6">Forever</option>
                        : ''}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 offset-lg-2">
              <div className="commentBox p-1" onClick={changeTarget}>
                {commentsArr.length > 0 ? (
                  commentsArr.map((commentObj, index) => {
                    return (
                      <div className="d-flex align-items-center ps-3 py-1" key={commentObj.id}>
                        <span className="pe-2">{commentObj.comment}</span>
                        <span className="removeComment" onClick={() => removeComment(index)}>
                          x
                        </span>
                      </div>
                    );
                  })
                ) : (
                  <div className="d-flex align-items-center ps-3 py-1">
                    <span className="pe-2 suggestion">Please search and add some comments from the field below...</span>
                  </div>
                )}
              </div>
              <div className="my-4">
                <input type="text" id="commentInput" placeholder="Enter keywords to search for comments" className="searchKeyword" onChange={(e) => _keyword(e.target.value)} />
              </div>
              {suggestionLoaded ? (
                <div className="keywords p-1">
                  {Object.keys(suggestionTexts).length > 0 ? (
                    Object.keys(suggestionTexts).map((suggestion, index) => {
                      return (
                        <span className="comments my-2 px-2" key={index} onClick={() => addcomments(suggestion)}>
                          {suggestionTexts[suggestion]}
                        </span>
                      );
                    })
                  ) : (
                    <span className="disabledcomment">No Comment found.</span>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </CreateCensuredWrapper>
  );
}

export default CreateCensured