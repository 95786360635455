import React from 'react'
import hamburger from '../../../../../assets/Images/image/hamburger.svg'
import notification from '../../../../../assets/Images/image/bell.svg'
import shop from '../../../../../assets/Images/image/shop.svg'
import gps from '../../../../../assets/Images/image/gps.png'

const MobileHeader = (props) => {
  return (
    <div className='status-bar p-2'>
    <img src={hamburger} alt="" className='ham-icon'/>
    {props.title?<span>Ads</span>:""}
    <img src={notification} alt=""/>
  </div>
  )
}

export default MobileHeader