import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { RoleListWrapper } from "./style/role";
import copy from "../../assets/Images/icons/faLink.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import API from "../../api/axios";
import { APP_URLS } from "../../api/url";
import { toast } from "react-toastify";
import NoRecord from "../../assets/SharedComponents/Table/NoRecord";
import Pagination from "../../assets/SharedComponents/Pagination";
import SearchKey from "../../assets/SharedComponents/Search/SearchKey";
import { Tooltip } from "@mui/material";
import { FaFilterIcon } from "../../assets/Images/icon";
import { ThreeDots } from "react-loader-spinner";
import RecordCount from "../../assets/SharedComponents/RecordCount/RecordCount";
import SearchableDropdown from "../../assets/SharedComponents/SearchableDropdown/SearchableDropdown";
import faHide from "../../assets/Images/icons/svgIcon/FaHide.svg"
import moment from "moment";
import CountryFilter from "../../assets/SharedComponents/Filters/CountryFilter";
import { userTypeName } from "../../config/constant";

const Partners = () => {

  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const [searchParams, setSearchParams] = useSearchParams();

  const [countries,_countries] = useState(() => JSON.parse(localStorage.getItem('countries')))

  const [data, _data] = useState([]);

  
  const [page, setPage] = useState(1);
  const [total, _total] = useState(0);
  const [totalPage, _totalPage] = useState(0);
  
  const [country,_country] = useState('')
  const [searchkeyword, _searchkeyword] = useState("");
  const [countValue, _countValue] = useState("");
  const [dataDeleted, _dataDeleted] = useState(false);
  
  const [filters, _filters] = useState(false);
  const [ loaded, _loaded] = useState(false);
  
  const [show,_show] = useState(false)

  const setChangeValue = (item) => {
    _country(item);
    _totalPage(0)
    setPage(1);
  }

  const handleDrop =(index) =>{
    if(index == show) _show('-1')
    else _show(index) 
  }

  useEffect(()=>{
    _show('')
    getPartners()
    _dataDeleted(false)
  },[searchkeyword,page,dataDeleted,countValue,country])

  const getPartners = () => {
    _loaded(true)
    API.get(APP_URLS.LIST_PARTNERS  +`?page=${page}&per_page=${countValue}&filter[email]=${searchkeyword}&filter[country_id]=${country}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _data(resp.data.data)
          _total(resp.data.total);
          _totalPage(resp.data.last_page)
          _loaded(false)
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loaded(false)
        }
      })
      .catch(function (error) {
        _loaded(false)
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined&&resp.data.data !== null) {
          {
            Object.keys(resp.data?.data).map(
              (error, index) => (error_message = resp.data.data[error][0])
              );
            }
          } else if (resp.data.data?.error !== undefined) {
            error_message = resp.data.data.error;
          } else if (resp.data?.error !== undefined) {
            error_message = resp.data.error;
          } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleSearchChange = (func, searchValue) => {
    func(searchValue);
    if(((func===_countValue&&searchValue!==countValue)||(func===_searchkeyword&&searchValue!==searchkeyword))&&searchValue !== ""){
        _totalPage(0)
        setPage(1);
    }
  };

  const clearFilter = () => {
    _filters(true)
    handleSearchChange(_countValue, '');
    handleSearchChange(_searchkeyword, '');
    _country('')
    if(countValue !== '' ||searchkeyword!==''||country!==''){
      _totalPage(0)
      setPage(1);
    }
    for(var value of query.keys()) {
      const param = searchParams.get(value);
      if (param) {
        searchParams.delete(value);
        setSearchParams(searchParams);
      }
     }
  };

  return (
    <RoleListWrapper>
      <div className="auctionMainContainer">
        <div className="row mt-4">
          <div className="col-md-4 offset-md-4">
            <h1 className="text-center">PARTNERS</h1>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between add-info">
          <div className="d-flex justify-content-end mb-2 data-button">
          </div>
          <div className="justify-content-end mb-2 d-flex gap-2 mb-2 flex-wrap align-items-center">
            <CountryFilter value={country} handleChange={setChangeValue}/> 
            <SearchKey
              onSearchClick={(search) => handleSearchChange(_searchkeyword,search)}
              id="searchData"
              filters={filters}
              val = {searchkeyword}
              _filters={_filters}
              placeholder="Search by Email"
            />
            <RecordCount
              onSearchClick={(search) => handleSearchChange(_countValue, search)}
              val = {countValue}
              id="pageCount"
              filters={filters}
              _filters={_filters} />
            <Tooltip title="Clear Filter" placement="bottom">
                <FaFilterIcon className="filter-icon" role="button" onClick={clearFilter} />
            </Tooltip>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between info-wrap">
            <div className="justify-content-end mt-1 me-2">
                <span className='text-start'>Total Records : <b>{total}</b></span>
            </div>
        </div>
            <div className="tableContainer">
            <table className="auctionTable">
              <thead className="tableHead">
                <tr className="table-row">
                  <th>ID</th>
                  <th className="clipboard">
                    <span className="d-block">{`${userTypeName.DEALER} Name`}</span>
                    <span>Email</span>
                  </th>
                  <th>Salutation </th>
                  <th className="w-150">Subject </th>
                  <th className="w-150">
                    <span className="d-block">Country</span>
                    <span>Phone</span>
                  </th>
                  <th>Notifications</th>
                  <th>Created At</th>
                  <th className="w-250">Message</th>
                </tr>
              </thead>
          {
            loaded ? 
            <div className='d-flex justify-content-center three-dot'>              
            <ThreeDots 
              height="150" 
              width="150" 
              radius="9"
              color="#637df4" 
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
          :
              <tbody>
                {data.length > 0 ? (
                  data.map((item, index) => {
                    let countryVal = countries.find((o)=>o.id===item.country_id)
                    let salutation = item.partner_options.find((o)=>o.type===1)
                    let subject = item.partner_options.find((o)=>o.type===2)
                    return (
                      <>
                      <tr className="table-row" key={index}>
                        <td>{item.id}</td>
                        <td className="clipboard">
                          <p className="copyText" title={item.company_name ? item.company_name : ""}>{item.company_name}</p>
                          <div className="my-2 d-flex justify-content-center">
                            <span className="copyText" title={item.email ? item.email : ""}>{item.email ? item.email : "-"}</span>
                            <CopyToClipboard text={item.email} onCopy={() => toast("Text Copied")}>
                                <img className="ms-2 cursor-pointer" src={copy} alt=""/>
                              </CopyToClipboard>
                          </div>
                        </td>
                        <td><span>{salutation?salutation.name.en:"-"}</span></td>
                        <td className="w-150">
                          <div className="messageDetails">
                            <span className="name-text" title={subject?subject.name.en:"-"}> {subject?subject.name.en:"-"}</span>
                          </div>
                         </td>
                        <td className="w-150">
                          <span className="d-block">
                            {countryVal?<img src={countryVal.flag_thumbnail} alt="" title={countryVal.fullname} className="countryflag"/>:"-"}
                          </span>
                          <span>{countryVal?countryVal.prefix_call:""}</span><span> {item.phone?" "+item.phone:"-"}</span>
                        </td>
                        <td>{item.event_notifications === 1 ? <span className="status status-active" title="Active"></span> : <span className="status status-end" title="Inactive"></span>}</td>
                        <td>
                          {item.created_at!==null?
                            moment(new Date(item.created_at)).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm")
                          :"-"}
                        </td>
                        <td className="w-250">
                          {item.message?
                          <div className="d-flex flex-row align-items-center">
                            <div className="userDetails">
                              <span className="message-text" title={item.message?item.message:"-"}>{item.message?item.message:"-"}</span>
                            </div>
                            <div>
                              <img src={faHide} alt="show" className={(show===index?"close":"open")+" dropbtn ms-2"} onClick={()=>handleDrop(index)}/>
                            </div>
                          </div>
                          :"-"}
                        </td>
                      </tr>
                      {show===index?
                        <tr className="table-row">
                          <td className='dropped'>
                            {item.message}
                          </td>
                        </tr>
                        :""}
                      </>
                    );
                  })
                ) : (
                  <NoRecord />
                )}
              </tbody>
          }
            </table>
          </div>
            <div>
              {totalPage > 1 ? <Pagination totalPages={totalPage} key={totalPage} page={page} onPageClick={(page) => setPage(page + 1)} /> : ""}
            </div>
      </div>
    </RoleListWrapper>
  );
};

export default Partners;
