import styled from "styled-components";

export const TableWrapper = styled.div`
.draggable{
  position:relative;
  width:30px;
}
  a {
    text-decoration: none;
    color: #000;
  }
  .nameTranslated{
    width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
  }
  .activeMenu {
    color: #0d6efd;
  }
  .active {
    color: rgb(93, 120, 255) !important;
    background: rgba(93, 120, 255, 0.07);
  }
  .activeTabTranslation {
    color: #5d78ff !important;
    background: rgba(93, 120, 255, 0.07);
  }
  .btn:hover {
    color: #fff !important;
  }

  .company p {
    padding: 3px 0px;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.25px;
    cursor: pointer;
  }
  .headerContainer {
    justify-content: center !important;
  }
  .sidebar-section li {
    list-style-type: none;
  }
  .sidebar-section li ul {
    height: auto;
  }
  .sidebar-section li ul li {
    font-size: 14px;
    line-height: 24px;
    color: #5a5a5a;
  }
  .company li {
    font-size: 16px;
    line-height: 26px;
  }
  li {
    cursor: pointer;
  }
  .close {
    display: none;
    cursor: pointer;
  }
  .translation {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
  .sidebar-section li h5 {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    padding: 5px 0 0 0;
    cursor: pointer;
  }

  .add-depertment input {
    border: 1px solid #979797;
    border-radius: 4px;
    margin-top: 10px;
    padding: 10px;
    width: 100%;
  }
  .action button {
    background: white;
    border: none;
    cursor: pointer;
  }

  //------table------

  .table {
    margin-top: 35px;
    width: 100%;
    min-width: 1000px;
  }
  td,
  th {
    text-align: center;
    padding: 15px 5px;
    word-break: break-word;
  }
  .table thead th span {
    color: #979797;
    display: block;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 13px;
  }
  .w-70 {
    width: 70px;
  }
  .tableHead {
    border-radius: 0px !important;
    border-left: none;
    border-right: none;
  }
  .tableHead tr {
    border: none;
  }
  tbody tr {
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  tbody .table-row:first-child {
    margin-top: 0;
  }
  .tableHead-1 {
    min-width: 40px;
  }
  .tableHead-2 {
    min-width: 85px;
  }
  .tableHead-3 {
    min-width: 150px;
  }
  .tableHead-4 {
    min-width: 100px;
  }
  .tableHead-5 {
    min-width: 120px;
  }
  .tableHead-6 {
    min-width: 170px;
  }
  .tableHead-7 {
    min-width: 80px;
    text-align: center;
  }
  .tableHead-8 {
    min-width: 60px;
    text-align: center;
  }
  .tableHead-9 {
    text-align: center;
    min-width: 80px;
  }
  .nameTranslated {
    display: block;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    padding: 12px 0;
  }
  .languageSelected {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 12px 0;
  }
  .languageSelected img {
    width: 22px;
    height: 22px;
    border-radius: 50%;
  }
  .flagName {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    min-width: 15px;
  }
  .edit-background {
    background-color: transparent !important;
  }
  .filterBox,
  .searchBox {
    height: 30px !important;
  }
  .time-start {
    min-width: 165px;
  }
  .time-start svg {
    width: 10px;
    height: 15px;
    cursor: pointer;
  }
  .opacity-40 {
    opacity: 40%;
  }

  @media (min-width: 767px) {
    .add-depertment {
      margin-right: 15px;
    }
  }
  @media (min-width: 420px) and (max-width: 576px) {
    .add-depertment {
      margin-right: 15px;
    }
  }

  @media (max-width: 575px) {
    .add-depertment {
      display: flex !important;
      // justify-content: center !important;
    }
    .sidebar-section li h5 {
      border: 1px solid #ddd;
      margin: 5px 0;
      padding: 3px 14px;
      border-radius: 5px;
    }
    .add-depertment button {
      margin: 10px 0px;
    }
    .headerContainer {
      padding-top: 10px;
    }
    .sidebar-section li ul {
      overflow: auto;
    }
    .sidebar-section li ul {
      max-height: 120px;
    }
    .searchBox input {
      max-width: 150px;
    }
  }

  @media (max-width: 950px) {
    .add-info {
      // display: flex !important;
      align-items: center;
      justify-content: center !important;
      flex-wrap: wrap;
    }
    .select-info {
      justify-content: flex-end !important;
    }
  }
`;
