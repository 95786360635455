import React from 'react'
import { confirmAlert } from 'react-confirm-alert';
import API from '../../../api/axios';
import { toast } from 'react-toastify';
import { APP_URLS } from '../../../api/url';
import { Button } from '@mui/material'; 

function ChangeStatus({id, isActive, _isAssigned}) { 
    const handleClick = () => { 
        let url = `${APP_URLS.ASSIGN}/${id}/status?_method=patch`;
        const formData = new FormData(); 
        formData.append('active', isActive ? 0 : 1);
        API.post( url, formData )
        .then((res) => {
        const resp = res.data;
        _isAssigned(prev => !prev)
        if (resp.success === true) { 
            toast.success(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
            }); 
        }
        })
        .catch(function (error) { 
        const resp = error.response;
        let error_message = "";
        if (resp?.data?.data) { 
            Object.keys(resp.data.data).map(
                (error, index) =>
                (error_message = resp.data.data[error][0])
            ); 
        } else if (resp?.data?.data?.error) {
            error_message = resp.data.data.error;
        } else if (resp?.data?.error) {
            error_message = resp.data.error;
        } else {
            error_message = resp.data.message;
        }
        toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT,
        });
        }); 
    }  
    return <Button 
                onClick={handleClick}
                variant="contained" 
                className={isActive ? 'bg-warning text-dark' : "bg-success"}
            >
                {isActive ? 'Pause' : 'Resume'}
            </Button>
}

export default ChangeStatus
