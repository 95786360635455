import React, { useEffect, useState } from "react";

const RecordCount = (props) => {
  const [search, _search] = useState(props.val?props.val:'');
  function onSearch() {
      props.onSearchClick(search);
  }
  useEffect(()=>{
    onSearch(search)
  },[search])

  useEffect(()=>{
    if(props.filters===true){
      _search('')
      props._filters(false)
    }
  },[props.filters])

  return (
    <select
      className="filterBox"
      onChange={(e)=>_search(e.target.value)}
      value={search}
    >
      <option value="">Per Page</option>
      <option value="10">10</option>
      <option value="25">25</option>
      <option value="50">50</option>
      <option value="100">100</option>
      <option value="250">250</option>
    </select>
  );
};

export default RecordCount;
