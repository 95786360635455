import styled from "styled-components";

export const CouponWrapper = styled.div`
  .business {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .date-time-picker tfoot{
    position: absolute;
    top: 0;
    right: 85px;
  }
  .auction-date-start-filed{
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 4px;
  }
  .businessPrice {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 10px;
  }
  .fieldBox{
    font-size:0.8rem;
    background-color: #fff;
    border: none;
    border-radius: 0.25rem;
  }
  .promoInput{
    font-size:0.8rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  .promoDate{
    width: fit-content;
  }
  .border-left{
    border-left: 1px solid #ced4da;
    border-radius:0 !important;
  }
  .border-right{
    border-right: 1px solid #ced4da;
    border-radius:0 !important;
  }
  .fieldTitle{
    font-size:0.8rem
  }
  .businessPrice p {
    textalign: center;
  }
  .btn {
    background: #fbc524 !important;
  }
  .icon {
    width: 10%;
    text-align: center;
  }
  .card {
    margin-top: 10px;
    border: 1px solid #b7b7b7;
    padding: 5px;
    border-radius: 5px;
  }
  .card p {
    margin-left: 15px;
  }
  .selected{
    color: #607afc;
  }
  .card-2 {
    border-radius: 5px;
    margin-top: 5px;
    border: 1px solid #b7b7b7;
    padding: 5px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor:pointer;
    max-height: 225px;
    overflow-y:auto;
  }
  .businessMonthly {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0px 20px;
    border-bottom: 1px solid #b7b7b7;
  }
  .businessMonthly p:nth-child(1) {
    width: 40%;
  }
  .businessMonthly p:nth-child(2) {
    width: 20%;
    text-align:center;
  }
  .businessMonthly p:nth-child(3) {
    width: 30%;
    text-align:end;
  }
  .cardContainer {
    width: 100%;
    overflow: auto;
  }
  .card-2 {
    min-width: 340px;
  }
  .cardHeader p:nth-child(1) {
    width: 130px;
  }

  .cardHeader {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .cardHeader p:nth-child(3) {
    width: 110px;
  }
  .cardContent {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .cardContent p {
    text-align: center;
  }
  .react-switch-handle {
    height: 18px !important;
    width: 18px !important;
  }

  .react-switch-bg {
    width: 50px !important;
    height: 21px !important;
  }
  .norecord {
    color: #f13e3ecf;
    font-style: italic;
    font-size: 13px;
}

.table-wrapper {
  overflow-x: auto; /* Enable horizontal scrolling if needed */
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th,
.custom-table td {
  padding: 10px;
  border: 1px solid #ddd;
}

.custom-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.custom-table tr.selected {
  background-color: #f8f9fa;
}

.custom-table .norecord {
  text-align: center;
  color: red;
  font-style: italic;
}
.center-text {
  text-align: center;
}

`;
