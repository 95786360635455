import React, { useEffect, useState } from "react";
import MainLayout from "../../../../assets/Layout/MainLayout";
import { useNavigate, useParams } from "react-router-dom";
import API from "../../../../api/axios";
import { APP_URLS } from "../../../../api/url";
import { toast } from "react-toastify";
import { EditVehicleWrapper } from "../../style/EditVehicle";

const EditModalsItems = () => {

  const [products, _products] = useState(JSON.parse(localStorage.getItem("productType")));
  const [productValue, _productValue] = useState("1");
  const [name, _name] = useState('');

  const [showData, _showData] = useState(false)

  const { modalId } = useParams();

  const navigate = useNavigate()

  useEffect(() => {
    getModal()
  }, [])

  const getModal = () => {
    API.get(APP_URLS.SHOW_MODEL_ITEMS + `/${modalId}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _name(resp.data.modelCarItem.name)
          document.getElementById("modelItemName").value = resp.data.modelCarItem.name;
          document.getElementById("productType").value = resp.data.modelCarItem.model_car.product_category_id;
          document.getElementById("modelName").value = resp.data.modelCarItem.model_car.model_name;
          document.getElementById("brandName").value = resp.data.modelCarItem.model_car.brand_car.name;
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp?.data?.data !== undefined) {
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        } else if (resp?.data?.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp?.data?.error !== undefined) {
          error_message = resp?.data.error;
        } else {
          error_message = resp?.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleSubmit = () => {
    let data = {}
    data["name"] = name
    let flag = false
    if ((name === null || name === "")) {
      toast.error("Please provide model item name", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else {
      flag = true
    }
    if (flag === true) {
      API.post(APP_URLS.UPDATE_MODEL_ITEMS + `/${modalId}?_method=patch`, data)
        .then(res => {
          const resp = res.data;
          if (resp.success === true) {
            toast.success("Model Item has been updated successfully.", {
              position: toast.POSITION.TOP_RIGHT,
            });
            navigate("/vehicles-management/vehicle-model-items");
          }
          else {
            toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT
            })
          }
        })
        .catch(function (error) {
          const resp = error.response;
          let error_message = '';
          if (resp.data.data !== undefined && resp.data.data !== null) {
            {
              Object.keys(resp.data.data).map(
                (error, index) => (error_message = resp.data.data[error][0])
              );
            }
          } else if (resp.data.data?.error !== undefined) {
            error_message = resp.data.data.error;
          } else if (resp.data?.error !== undefined) {
            error_message = resp.data.error
          } else {
            error_message = resp.data.message
          }
          toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT
          })
        });
    }
  }

  return (
    <>
      <EditVehicleWrapper>
        <div className='mt-2' >
          <div className="backContainer ms-4" onClick={() => navigate("/vehicles-management/vehicle-model-items")}>
            <span className='backButton'>x</span>
            <span className='px-3 backButton'>|</span>
            <span className='backButton'>Cancel</span>
          </div>
        </div>
        <div className="headerContainer">
          <h1>EDIT MODEL ITEMS</h1>
        </div>
        <div className="row">
          <div className="d-flex justify-content-end save-btn mt-3">
            <button className="add-button" onClick={handleSubmit}>Save</button>
          </div>
          <div className="row input-fileds">
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-12 mt-3">
                  <label htmlFor="name" className="form-label fieldLabel">
                    Product Type
                  </label>
                  <select
                    name="productType"
                    id="productType"
                    className="form-control mt-3"
                    onChange={(e) => {
                      _productValue(e.target.value);
                    }}
                    disabled
                  >
                    {products.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {item.item_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-12 mt-4">
                  <label htmlFor="productType" className="form-label fieldLabel product-type d-flex justify-content-between">
                    <span>Brand</span>
                  </label>
                  <input type="text" className="form-control mt-3 disabled" disabled
                    placeholder="Brand"
                    id="brandName" />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-12 mt-3">
                  <label htmlFor="name" className="form-label fieldLabel">
                    Model
                  </label>
                  <input type="text" id="modelName" name="modelName" disabled
                    className="form-control mt-3"
                    placeholder="Model" onChange={(e) => _name(e.target.value)} />
                </div>
              </div>
            </div>
          </div>
          <div className="row input-fileds">
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-12 mt-3">
                  <label htmlFor="name" className="form-label fieldLabel">
                  Model Item Name
                  </label>
                  <input type="text" id="modelItemName" name="modelItemName"
                    className="form-control mt-3"
                    placeholder="Model Item Name" onChange={(e) => _name(e.target.value)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </EditVehicleWrapper>
    </>
  );
};

export default EditModalsItems;
