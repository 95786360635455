import React, { useEffect, useState } from 'react'

const Popup = (props) => {

  const [countries,_countries] = useState([])
  const [listCountry,_listCountry] = useState([])

  useEffect(()=>{
    const setTemplate = props.countries.map((item)=> {
      item['selected'] = false
      return item
    })
    _countries(setTemplate)
    _listCountry(setTemplate)
    if(props.countryArr.length>0){
      setValues(setTemplate)
    }
  },[])

  const setValues= (value) =>{
    props.countryArr.forEach(element => {
      let val = value.find(o=>o.id===element)
      val.selected=true
    });
  }

  const handleSearch = (value) =>{
    let search1 = value.charAt(0).toLowerCase() + value.slice(1)
    let search2 = value.charAt(0).toUpperCase() + value.slice(1)
    let uniqueArr = [];
    if(value.length>0){
      let newData = [...countries]
      let val = newData.filter(o=>(o.fullname).includes(search1))
      let nval = newData.filter(o=>(o.fullname).includes(search2))
      val = [...val,...nval]
      for(let i of val) {
        if(uniqueArr.indexOf(i) === -1) {
            uniqueArr.push(i);
        }
    }
    let finalval = uniqueArr.sort((a, b) => a.fullname.localeCompare(b.fullname))
    _listCountry(finalval)
    }
    else{
      _listCountry(countries)
    }
  }

  const handleSelect = (id) =>{
    let newdata = [...listCountry]
    let value = newdata.find(o=>o.id===id)
    if(value.selected===false){
      value.selected=true
    }
    else{
      value.selected=false
    }
    _listCountry(newdata)
  }

  const handleSave=()=>{
    let newArr = []
    countries.forEach(element => {
      if(element.selected===true){
        newArr.push(element.id)
      }
    });
    props._countryArr(newArr)
    props._popup(false)
  }
  
  return (
    <div className="modal fade show custom-modal modal-backdrop"> 
        <div className="modal">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                  <h5 className="modal-title">Countries</h5>
              </div>
              <div className="modal-body">
                <div className='preview'>
                  <input type="text" className="form-control" onChange={(e)=>handleSearch(e.target.value)}/>
                  <div className='countriesList p-2 m-4'>
                    {listCountry.map((item,index)=>{
                      return <div className='d-flex align-items-center p-1' key={index}>
                        <input type="checkbox" checked={item.selected} onChange={()=>handleSelect(item.id)}/>
                        <span className='ms-2'>{item.fullname}</span>
                      </div>
                    })}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end p-3">
                <button className='btn cancel-btn btn-border' onClick={()=>props._popup(false)}>Cancel</button>
                <button className='btn ms-3' onClick={()=>handleSave()}>Save</button>
            </div>
            </div>
        </div>
        </div>
    </div>
  )
}

export default Popup