import React from 'react'
import car from "../../../../../assets/Images/image/productCar.png";
import speedometer from "../../../../../assets/Images/image/speedometer.svg";
import gears from "../../../../../assets/Images/image/gears.svg";
import km from "../../../../../assets/Images/image/km.svg";
import flag from "../../../../../assets/Images/image/fr.png";
import user from "../../../../../assets/Images/image/userImage.svg";
import rating from "../../../../../assets/Images/image/rating.svg";
import star from "../../../../../assets/Images/image/star.svg";

const CarCard = () => {
  return (
    <div className='m-2 productCarCard'>
        <img src={car} className="cardImage"/>
        <p className='carTitle'>BMW 520D PACK M full option toit panoramik</p>
        <div className='d-flex flex-row flex-wrap p-1'>
            <div className='specifications py-2 px-2 me-2 my-1'>
             <span className=''><img src={speedometer} alt="" className="me-1"/> 01/2018</span>
            </div>
            <div className='specifications py-2 px-2 me-2 my-1'>
                <span className=''><img src={km} alt="" className="me-1"/> 125 000</span>
            </div>
            <div className='specifications py-2 px-2 me-2 my-1'>
                <span className=''><img src={gears} alt="" className="me-1"/> Auto</span>
            </div>
            <div className='specifications py-2 px-2 me-2 my-1'>
                <span className=''><img src={gears} alt="" className="me-1"/> 81Kw</span>
            </div>
            <div className='specifications py-2 px-2 me-2 my-1'>
                <span className=''><img src={gears} alt="" className="me-1"/> Benzine</span>
            </div>
            <div className='userDetails d-flex align-items-center p-2 my-1'>
                <div className='position-relative userImage'>
                    <img src={user} alt="" />
                    <img src={flag} alt="" className='position-absolute flagImage'/>
                </div>
                <div className='d-flex flex-column userName ps-2'>
                    <span>Mercedes Benz Drogenbos</span>
                    <span> 9000 Malines</span>
                    <span>(4.8)</span>
                </div>
            </div>
            <div className='priceValue d-flex align-items-center px-3 py-3 my-1'>
                <div className='pricing position-relative'>
                    <span className='value'> € 11.000</span>
                    <img src={rating} alt="" className='position-absolute stars'/>
                </div>
                <div className='rating '>
                    <div className='starDiv d-flex align-items-center justify-content-center'>
                        <img src={star} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CarCard