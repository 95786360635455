import styled from "styled-components";

export const BidListWRapper = styled.div`
  .headerContainer div,
  .headerContainer h1 {
    margin-bottom: 10px;
  }
  .searchContainer {
    display: flex;
    padding: 0 15px 0 0;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  .search {
    height: 35px;
    border: none;
    border-radius: 10px;
  }
  .productDetail img {
    width: 60px;
    height: auto !important;
    display: inline-table;
    object-fit: contain;
  }
  .add-button {
    white-space: nowrap;
    border:"none";
    float: right;
    // border: none !important;
    // border-radius: 5px;
    // background: #fbc524 !important;
    // color: #fff !important;
    // font-weight: 500;
    // font-size: 16px;
    // font-weight: bold;
    // line-height: 19px;
    // padding: 0 20px;
    // height: 35px;
    // -webkit-transition: all 0.35s ease-in-out;
    // transition: all 0.35s ease-in-out;
  }
  .actionBtns.btnActive {
    border-radius: 5px 5px 0 0;
}
.actionAuction {
    position: relative;
}
  .actionAuction .DelAction {
    width: 100%;
    position: absolute;
}
.actionAuction .DelAction .actionBtn {
    width: 100%;
    border-top: none;
    border-radius: 0 0 5px 5px;
}
.open{
  transform: rotate(0deg);
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.close{
  transform: rotate(-180deg);
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  left:0px !important;
}
  .add-button1 {
    white-space: nowrap;
    float: right;
    border: 1px solid #c4c4c4;
    border-radius: 8px;
    background: transparent !important;
    color: #aaa7a7;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  .add-button1:hover {
    background: transparent !important;
    color: #aaa7a7;
  }
  .add-button:hover {
    background: #637df4;
    color: white;
  }
  .spanCircle {
    width: 17px;
    height: 17px;
    background: #65a252;
    transform: rotate(89.72deg);
    border-radius: 50%;
  }
  .auctionTable {
    min-width: 1300px;
  }
  .btn:hover {
    background: #637df4;
    color: white;
  }
  .time-start svg {
    width: 10px;
    height: 15px;
    cursor: pointer;
  }
  .opacity-40 {
    opacity: 40%;
  }
  a {
    color: #0d6efd;
    text-decoration: none;
  }
  .autobid-badge{
    font-size: 11px;
    color: white;
    background: green;
    padding: 0 4px;
    font-weight: 600;
    border-radius: 8px;
  }
  .filterBox.brand , .filterBox.model {
    width:11rem !important;
  }
  .extract-report{
    font-weight: 600;
    cursor: pointer;
    text-transform: none;
    color: black; 
    border: 1px solid rgba(0,0,0,.2);
    height: 32px;
    &:hover{
        outline:none;
    }
}
`;
