import moment from "moment";

export const calctime = (e, offset) =>{
    // create Date object for current location
    var d = new Date();

    // convert to msec
    // subtract local time zone offset
    // get UTC time in msec
    var utc = d.getTime() + (d.getTimezoneOffset() * 60000);

    // create new Date object for different city
    // using supplied offset
    var nd = new Date(utc + (3600000*offset));

    if(moment(e).format("hh:mm:ss a")==='12:00:00 am'){
      //Adding Amsterdam time if date changed
      return moment(e).add(nd.toLocaleTimeString()).toDate()
    }
    else{
      //Returning as it is as time is changed
      return moment(e).toDate()
    }

  }

  export const calcTimeWith00SecFormat = (inputTime, offsetHours) => {
    const targetTime = moment(inputTime).utcOffset(offsetHours * 60);
    
    if (targetTime.format('HH:mm:ss') === '00:00:00') {
      return targetTime.startOf('day').add(offsetHours, 'hours').toDate();
    }
    
    return targetTime.startOf('minute').toDate();
  };