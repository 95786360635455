import React, { useCallback, useEffect, useState } from "react";
import { TableWrapper } from "../style/table";
import { useLocation, useSearchParams } from "react-router-dom";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import Pagination from "../../../assets/SharedComponents/Pagination";
import SideTranslate from "../components/SideTranslate";
import { ThreeDots } from "react-loader-spinner";
import arrayMove from "../../../core/utils/draggableRowfunc/arrayMove";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import TableRow from "./components/TableRow";
import { getName } from "../../../core/utils/getName/getName";
import styled from "styled-components";


const MyTableWrapper = styled.div`
  .auctionTable {
    & > tbody {
      cursor: grabbing;
    }
  }
`;


const SortableCont = SortableContainer(({ children }) => {
  return <tbody>{children}</tbody>;
});

const SortableItem = SortableElement((props) => <TableRow {...props} />);



const Continents = () => {

  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const [searchParams, setSearchParams] = useSearchParams();

  const [data, _data] = useState([]);

  const [page, setPage] = useState(searchParams.get('page')!==null?parseInt(searchParams.get('page')):1);
  const [total, _total] = useState(0);
  const [totalPage, _totalPage] = useState(0);

  const [loaded, _loaded] = useState(false);

  const [items,setItems] = useState([])

  const [userAccess,_userAccess] = useState(JSON.parse(localStorage.getItem('permissions')))

  let translationAccess = userAccess.find(o=>o.slug ==="translation")


  useEffect(() => {
    getContinents();
  }, [page]);

  useEffect(()=>{
    if(data.length>0){
      setItems(data)
    }
  },[data])


  const equalsCheck = (a, b) => {
    return JSON.stringify(a) !== JSON.stringify(b);
  }

  useEffect(()=>{
    if(items.length>0 && equalsCheck(items, data)){
      let arr = []
      items.forEach(element => {
        arr.push({
          id:element.id,
          order: items.findIndex(o=>o.id===element.id)+1
        })
      });
      API.post(APP_URLS.UPDATE_CONTINENT+ `/order`,{
        order_sequence:arr
      })
      .then(res => {
        const resp = res.data;
        if(resp.success===true){
          toast.success("Continent Order Successfully.", {
            position: toast.POSITION.TOP_RIGHT
          })
          _data(items)
        }
        else{
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT
          })
        }
      })
      .catch(function (error) {  
        const resp = error.response;
        let error_message = '';
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map(
              (error, index) =>(error_message = resp.data.data[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
            error_message =  resp.data.data.error;
        } else if(resp.data?.error!== undefined){
            error_message =  resp.data.error
        } else{
            error_message =  resp.data.message
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT
        })
      });
    }
  },[items])

  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setItems((oldItems) => arrayMove(oldItems, oldIndex, newIndex));
  }, []);


  const getContinents = () => {
    _loaded(true);
    API.get(APP_URLS.LIST_CONTINENTS + `?page=${page}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _data(resp.data.data);
          _totalPage(resp.data.last_page);
          _loaded(false);
          _total(resp.data.total);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loaded(false);
        }
      })
      .catch(function (error) {
        _loaded(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <TableWrapper>
      <div className="row">
        <div className="col-lg-2 col-md-3 col-sm-4 mt-3">
          <h4 className="translation text-center mb-3">TRANSLATION</h4>
          <SideTranslate />
        </div>
        <div className="col-lg-10 col-md-9 col-sm-8 mt-2">
          <div className="headerContainer">
            <h1>CONTINENTS</h1>
          </div>
          <div className="d-flex align-items-center justify-content-between info-wrap">
            <div className="justify-content-end mt-1 me-2">
                <span className='text-start'>Total Records : <b>{total}</b></span>
            </div>
          </div>

          <div className="tableContainer">
            <MyTableWrapper>
              <table className="auctionTable">
                <thead className="tableHead">
                  <tr className="table-row">
                    <th className="tableHead-1">ID</th>
                    <th className="tableHead-2">Language</th>
                    <th className="tableHead-3">Name</th>
                    <th className="tableHead-3">Code</th>
                    <th className="tableHead-3">Order</th>
                    <th className="tableHead-4">Updated by</th>
                    {translationAccess.permissions.find((o) => o.slug === "update") ?
                      <th className="tableHead-9">Action</th>
                    :""}
                  </tr>
                </thead>
                {
                  loaded ? 
                  <div className='d-flex justify-content-center three-dot'>              
                  <ThreeDots 
                    height="150" 
                    width="150" 
                    radius="9"
                    color="#637df4" 
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>:
                  <SortableCont
                    onSortEnd={onSortEnd}
                    axis="y"
                    lockAxis="y"
                    lockToContainerEdges={true}
                    lockOffset={["30%", "50%"]}
                    helperClass="helperContainerClass"
                    useDragHandle={true}
                  >
                    {items.map((value, index) => {
                      let upadtedBy = getName(value.update_activity_log, value.create_activity_log);
                      return <SortableItem
                        key={`item-${index}`}
                        index={index}
                        rowData={value}
                        upadtedBy={upadtedBy}
                      />

                    })}
                  </SortableCont>
                }
              </table>
            </MyTableWrapper>
          </div>
            <div>
              {totalPage > 1 ? <Pagination totalPages={totalPage} key={totalPage} page={page} onPageClick={(page) => setPage(page + 1)} /> : ""}
            </div>
          
        </div>
      </div>
    </TableWrapper>
  );
};

export default Continents;
