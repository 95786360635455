import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AddWrapper } from "../Style/addWrapper";
import { ContentState, convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import API from "../../../api/axios";
import htmlToDraft from "html-to-draftjs";
import Datetime from "react-datetime";
import { calctime } from "../../../core/utils/getAmsterdamTime/getTime";
import moment from "moment";
import { ThreeDots } from "react-loader-spinner";

const EditLegalNotice = () => {

  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const [languages, _languages] = useState(JSON.parse(localStorage.getItem("languages")));
  const [countries, _countries] = useState(JSON.parse(localStorage.getItem("countries")));
  const [startDate,_startDate] = useState('')
  const [date,_date] = useState('')
  const [values,_values] = useState({})

  const [dataLoaded,_dataLoaded] = useState(false)

  const [loading,setLoading] = useState(false)

  const navigate = useNavigate();

  const { recId } = useParams();

  useEffect(() => {
    getRecord()
  }, [])


  const getRecord = () =>{
    _dataLoaded(true)
    API.get(APP_URLS.SHOW_LEGAL_DOCUMENTS + `/${recId}`)
    .then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        _startDate(moment(resp.data.item.start_date).tz('Europe/Amsterdam').format('YYYY-MM-DD'))
        _date(moment(resp.data.item.start_date).tz('Europe/Amsterdam').format('YYYY-MM-DD'))
        let data ={}
        Object.keys(resp.data.item.content).forEach(element => {
          const blocksFromHtml = htmlToDraft(resp.data.item.content[element])
          const { contentBlocks, entityMap } = blocksFromHtml
          const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
          data={
            ...data,
            [element]:EditorState.createWithContent(contentState)
          }
        });
        _values(data)
      } else {
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    _dataLoaded(false)
  })
  .catch(function (error) {
      _dataLoaded(false)
      const resp = error.response;
      let error_message = "";
      if (resp?.data?.data !== undefined) {
        {
          Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
        }
      } else if (resp?.data?.data?.error !== undefined) {
        error_message = resp.data.data.error;
      } else if (resp?.data?.error !== undefined) {
        error_message = resp?.data.error;
      } else {
        error_message = resp?.data.message;
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    });
  }
  

  const handleTitle = (val, code) => {
    _values({
      ...values,
      [code] : val
    })
  };

  const submitForm = () => {
    let enValues = ''
    let frValues = ''
    if(values['en']!==''&&values['en']!==undefined&&values['en']!==null){
      const blocks = convertToRaw(values['en'].getCurrentContent()).blocks;
      enValues = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n').trim(' ');
    }
    if(values['fr']!==''&&values['fr']!==undefined&&values['fr']!==null){
      const blocks = convertToRaw(values['fr'].getCurrentContent()).blocks;
      frValues = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n').trim(' ');
    }
    if(startDate===''||startDate===undefined||startDate===null){
      toast.error("Start Date is required", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    else if((enValues===''||enValues===undefined||enValues===null)&&(frValues===''||frValues===undefined||frValues===null)){
      toast.error("Content in English and French is required", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    else if(enValues===''||enValues===undefined||enValues===null){
      toast.error("Content in English is required", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    else if(frValues===''||frValues===undefined||frValues===null){
      toast.error("Content in French is required", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    else{
      let data = {}
      if(date!==startDate){
        data['start_date'] = startDate
      }
      data['content'] = {}
      Object.keys(values).map((item)=>{
        data.content[item] = draftToHtml(convertToRaw(values[item].getCurrentContent()))
      })
      setLoading(true);
      API.post(APP_URLS.ADD_LEGAL_DOCUMENTS+`/${recId}?_method=patch`, data)
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) {
              toast.success("Legal Notice Content Updated Successfully.", {
                position: toast.POSITION.TOP_RIGHT,
              });
              setLoading(false);
              navigate("/legal-documents/legal-notice?"+
                (query.get("per_page") !== null 
                  ? "per_page=" + query.get("per_page") + "&"
                  : "") +
                (query.get("trashed") !== null 
                  ? "trashed=" + query.get("trashed") + "&"
                  : "") +
                (query.get("page") !== null
                  ? "page=" + query.get("page")
                  : "")
              )
          } else {
            toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
          }
        })
        .catch(function (error) {
          setLoading(false);
          const resp = error.response;
          let error_message = "";
          if (resp.data.data !== undefined && resp.data.data !== null) {
            {
              Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
            }
          } else if (resp.data.data?.error !== undefined) {
            error_message = resp.data.data.error;
          } else if (resp.data?.error !== undefined) {
            error_message = resp.data.error;
          } else {
            error_message = resp.data.message;
          }
          toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  return (
    <AddWrapper>
      <div className="mt-2">
        <div
          className="backContainer ms-4"
          onClick={() => navigate("/legal-documents/legal-notice?"+
          (query.get("per_page") !== null 
            ? "per_page=" + query.get("per_page") + "&"
            : "") +
          (query.get("trashed") !== null 
            ? "trashed=" + query.get("trashed") + "&"
            : "") +
          (query.get("page") !== null
            ? "page=" + query.get("page")
            : "")
        )}
        >
          <span className="backButton">x</span>
          <span className="px-3 backButton">|</span>
          <span className="backButton">Cancel</span>
        </div>
      </div>
      <div className="headerContainer">
        <h1>EDIT LEGAL NOTICE</h1>
      </div>
      <div className="d-flex justify-content-end save-btn mt-3">
        <button className={(query.get("deleted")||loading?"disabled":"")+" add-button"} onClick={submitForm} disabled={query.get("deleted")||loading?true:false}>
          Save
        </button>
      </div>
      {!dataLoaded?
      <>
        <div className="row">
          <div className="col-md-4 offset-md-2">
            <label htmlFor="startDate" className="form-label product-type fieldLabel">
              Start Date
            </label>
            <input type='date'
              className="form-control mt-2 mb-4"
              name="startDate"
              id="startDate"
              value={startDate}
              min={new Date().toISOString().split('T')[0]}
              onChange={(e)=>_startDate(e.target.value)}
              />
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-md-8">
            {languages.map((item, index) => {
              let itemCode = item.code;
              let flag = countries.find((o) => o.id === item.country_id)?.flag_thumbnail;
              return (
                <div className="col-md-12" key={index}>
                    <label htmlFor="title" className="form-label">
                      <span className="countryIcon px-2">
                        <img src={flag} alt="" className="countryflag" title={item.name}/>
                      </span>
                      <span className="langName">{item.code.toUpperCase()}</span>
                    </label>
                    <Editor
                      readOnly={query.get("deleted")?true:false}
                      wrapperClassName="demo-wrapper"
                      editorClassName={(query.get("deleted")?"disabled":"")+" demo-editor"}
                      localization={{
                        locale: itemCode,
                      }}
                      editorState={values[itemCode]}
                      onEditorStateChange={(e, code = itemCode) => {
                        handleTitle(e, code);
                      }}
                    />
                </div>
              );
            })}
          </div>
        </div>
      </>: 
      <div className='d-flex justify-content-center three-dot'>
        <ThreeDots
          height="150"
          width="150"
          radius="9"
          color="#637df4"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>
    }
    </AddWrapper>
  );
};

export default EditLegalNotice;
