import React from "react";
import MainLayout from "../../../assets/Layout/MainLayout";
import { CraeteVehicleWrapper } from "../style/createVehicle";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";

let name = {}

const CreateService = () => {
  const navigate = useNavigate();

  const [languages] = useState(JSON.parse(localStorage.getItem("languages")))
  const [countries] = useState(JSON.parse(localStorage.getItem("countries")))

  const handleTitle = (val, code) => {
    name[code] = val
  }

  const resetForm = () => {
    let inputs = document.querySelectorAll('.departmentFields');
    inputs.forEach(input => {
      input.value = '';
    });
  }

  const submitForm = () => {
    let flag = false
    if ((name["en"] === "" || name["en"] === undefined || name["en"] === null) && (name["fr"] === "" || name["fr"] === undefined || name["fr"] === null)) {
      flag = false
      toast.error("Service Title in English and French is mandatory", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else if (name["en"] === "" || name["en"] === undefined || name["en"] === null) {
      flag = false
      toast.error("Please provide Service name in English", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else if (name["fr"] === "" || name["fr"] === undefined || name["fr"] === null) {
      flag = false
      toast.error("Please provide Service name in French", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    else {
      flag = true
    }
    if (flag === true) {
      API.post(APP_URLS.ADD_SERVICES, { name })
        .then(res => {
          const resp = res.data;
          if (resp.success === true) {
            toast.success("Service Added Successfully.", {
              position: toast.POSITION.TOP_RIGHT
            })
            resetForm();
            navigate("/translation/services");
          }
          else {
            toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT
            })
          }
        })
        .catch(function (error) {
          const resp = error.response;
          let error_message = '';
          if (resp.data.data !== undefined && resp.data.data !== null) {
            {
              Object.keys(resp.data.data).map(
                (error, index) => (error_message = resp.data.data[error][0])
              );
            }
          } else if (resp.data.data?.error !== undefined) {
            error_message = resp.data.data.error;
          } else if (resp.data?.error !== undefined) {
            error_message = resp.data.error
          } else {
            error_message = resp.data.message
          }
          toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT
          })
        });
    }
  }
  return (
    <>
      <CraeteVehicleWrapper>
        <div className='mt-2' >
          <div className="backContainer ms-4" onClick={() => navigate("/translation/services")}>
            <span className='backButton'>x</span>
            <span className='px-3 backButton'>|</span>
            <span className='backButton'>Cancel</span>
          </div>
        </div>
        <div className="headerContainer">
          <h1>CREATE SERVICE</h1>
        </div>
        <div className="d-flex justify-content-between save-btn mt-3">
          <p className="fieldLabel">Title</p>
          <button className="add-button" onClick={submitForm}>Save</button>
        </div>
        <div className="row input-fileds">
          {languages.map((item, index) => {
            let itemCode = item.code
            let flag = countries.find(o => o.id === item.country_id)?.flag_thumbnail
            return <div className="col-md-4" key={index}>
              <div className="col-md-12 mt-3">
                <label htmlFor="title" className="form-label">
                  <span className="countryIcon px-2">
                    <img src={flag} alt="" className="countryflag" title={item.name}/>
                  </span>
                  <span className="langName">{item.code}</span>
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  className="form-control departmentFields"
                  onChange={(e, code = itemCode) => {
                    handleTitle(e.target.value, code)
                  }}
                />
              </div>
            </div>
          })}
        </div>
      </CraeteVehicleWrapper>
    </>
  );
};

export default CreateService;
