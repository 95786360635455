import React from 'react'
import Switch from "react-switch";

function Toggle({label , checked , handleChecked}) {
  return (
    <div className="col-md-4 col-lg-4 mt-2">
        <label htmlFor="title" className="form-label fieldLabel">
            {label}
        </label>
        <div>
            <Switch
              onChange={handleChecked}
              checked={checked}
              className="react-switch mt-1"
              uncheckedIcon={false}
              checkedIcon={false}
              offColor="#D9D9D9"
              onColor="#7589EC"
            />
        </div>
    </div>
  )
}

export default Toggle
